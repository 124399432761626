//imports
import React, { useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import 'react-tooltip/dist/react-tooltip.css'
import * as ReactTooltip from 'react-tooltip'
import { useTheme } from '@mui/material'
import Card from '../../../components/atoms/v2/card'
import { newColor } from '../../../consts/colors'
import { ReactComponent as APIsIcon } from 'src/assets/API_Icon.svg'

//services
import {
  getExcelObject,
} from '../../../utils/api/queries'
import { importExcel, setObjectToDisplayExcel } from '../../../utils/excels'

//state

//components
import {
  Box,
  Button,
  Typography,
} from '@mui/material'

//assets
import '../../../App.css'
import configListHeaderIcon from '../../../assets/support_header_text_icon.svg'
import PageContainer from '../../../components/atoms/v2/pageContainer'
import EngageUploadCSVModal from 'src/components/popups/EngageUploadCSVModal'
import LoadingComponent from '../../../components/LoadingComponent'

//----------------------------------------------------------------------------------------------------------

export default function EngageUploadCSV() {
  //services
  const theme = useTheme()

  //state

  //modal booleans
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  //table values
  const [columnDefs, setColumnDefs] = useState<any[]>([])
  const [rowData, setRowData] = useState<any[]>()
  const [loadedS3Objects, setLoadedS3Objects] = useState<any[]>([])

  //display
  const [isProcessingConfig, setIsProcessingConfig] = useState<boolean>(false)
  const [, setDisplayTitle] = useState<string>()

  //load config to display
  async function showConfig(config: any, sheetName?: string, columns?: any) {
    try {
      setIsProcessingConfig(true)

      //clear current object
      await setObjectToDisplayExcel(
        undefined,
        setColumnDefs,
        setRowData,
        setDisplayTitle,
        sheetName,
        columns,
      )

      //load cached object or download object if no cached object, then format for display
      let cachedObject = _.find(loadedS3Objects, function (item) {
        return item.key === config?.config_s3_key
      })
      if (cachedObject) {
        await setObjectToDisplayExcel(
          cachedObject.file,
          setColumnDefs,
          setRowData,
          setDisplayTitle,
          sheetName,
          columns,
        )
      } else {
        let object = await getExcelObject(config?.config_s3_key)
        setLoadedS3Objects((prevState: any) => {
          let foundObject = _.find(prevState, function (item) {
            return item.key === config?.config_s3_key
          })
          if (foundObject) {
            return prevState
          } else {
            return prevState.concat({
              file: object,
              key: config?.config_s3_key,
            })
          }
        })
        importExcel(
          object,
          setColumnDefs,
          setRowData,
          setDisplayTitle,
          sheetName,
          columns,
        )
      }

      setIsProcessingConfig(false)
    } catch (e: any) {
      toast.error(e?.message)
    }
  }

  return (
    <PageContainer
      sx={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background: theme.palette.mode === 'dark' ? 'black' : newColor.cultured,
        minHeight: '100vh',
        maxHeight: '100vh',
        minWidth: '60em',
        width: '100%',
        maxWidth: '200em',
        margin: 'auto',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <ReactTooltip.Tooltip id="configHistoryTooltip" />

      <EngageUploadCSVModal
        open={popupOpen}
        setOpen={setPopupOpen}
        onClose={() => {
          setPopupOpen(false)
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        showConfig={showConfig}
      />

      {/* page header */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'space-between',
          marginBottom: '1em',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            padding: '0 1em 0 1em',
            maxWidth: '100%',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              src={configListHeaderIcon}
              style={{ height: '1.5em', margin: 'auto 0 auto 1em' }}
              alt=""
            />
            <Typography
              className="ellipsisWithWrap ellipsisWithWrap"
              sx={{
                color: 'white',
                margin: 'auto 0 auto 0.5em',
                fontSize: '1.5em',
                fontWeight: 700,
              }}
            >
              CSV UPLOAD
            </Typography>
          </span>
        </Box>
      </Box>

      <Box
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Card label="Upload Hook Data" icon={<APIsIcon />}>
          <div style={{ marginTop: '20px' }}></div>
          {
            isProcessingConfig ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '160px',
                }}
              >
                <LoadingComponent />
              </div>
            ) :

              <Button
                onClick={() => {
                  setPopupOpen(true)
                }}
                sx={{
                  margin: 'auto',
                  maxWidth: '20vw',
                  whiteSpace: 'nowrap',
                  padding: '0.2em 2em 0.2em 2em',
                  borderRadius: '1em',
                  color: 'white',
                  background: `linear-gradient(to right,${newColor.jazzberryJam}, ${newColor.byzantium}) !important`,
                }}
              >
                Upload
              </Button>
          }
        </Card>
      </Box>
    </PageContainer>
  )
}
