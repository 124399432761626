//imports
import '../../App.css'
import { Box, useTheme } from '@mui/material';
import UnAuthImage from '../../assets/Portal_UnAuth_Image.png'
import HelikaIcon from '../../assets/Helika_Icon.svg'
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';

//----------------------------------------------------------------------------------------------------------

export default function LandingPage() {

  const theme: any = useTheme();
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const navigate = useNavigate();

  async function handleLogin() {
    if (isAuthenticated) {
      window.location.replace('/analytics');
      return;
    }
    loginWithRedirect()
  }
  async function handleBookDemo() {
    window.location.href = 'https://www.helika.io/contact-us/';
  }

  useEffect(() => {
    const handleKeydown = (e: any) => {
      if (
        (e.ctrlKey || e.keyCode) &&
        (e.keyCode === 61 ||
          e.keyCode === 107 ||
          e.keyCode === 173 ||
          e.keyCode === 109 ||
          e.keyCode === 187 ||
          e.keyCode === 189)
      ) {
        e.preventDefault();
      }
    };

    const handleWheel = (e: any) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/analytics');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <Box
        style={{
          height: '100vh',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: 'auto',
        }}
      >
        <LoadingComponent />
      </Box>
    );
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      className='landingPage'
      style={{
        height: '100vh',
        color: theme.palette.text.primary,
        backgroundSize: 'cover',
        padding: '2em',
        flex: 1,
        overflowY: 'auto'
      }}
      justifyContent={'center'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          margin: 'auto auto',
          justifyContent: 'center',
          height: '100%',
          scrollbarGutter: 'both-sides'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            minWidth: 'fit-content',
            justifyContent: 'center',
            scrollbarGutter: 'both-sides'
          }}
        >

          <div
            style={{
              fontSize: '1.8em',
              width: '12em',
              margin: '0em auto 2em auto',
              whiteSpace: 'pre',
              color: 'white'
            }}
          >
            {`Helika helps Game Studios\nthroughout the entire\nGame Life Cycle`}
          </div>

          <div
            className='landingPageLoginBox'
            style={{
              flexDirection: 'column',
              padding: '4em 3em 4.5em 3em',
              borderRadius: '2em',
              backgroundColor: 'white',
              width: '20em',
              margin: '0 auto 5em auto',
            }}
          >
            <img
              src={HelikaIcon}
              alt='helika icon'
              style={{
                height: '5em',
              }}
            />
            <div
              style={{
                color: 'black',
                fontWeight: 600,
                width: '100%',
                textAlign: 'center',
                fontSize: '1.8em',
                margin: '0 0 1em 0'
              }}
            >
              PORTAL
            </div>
            <button
              onClick={handleLogin}
              style={{
                padding: '0.5em 0',
                borderRadius: '0.5em',
                background: 'linear-gradient(90deg, #9747FF, #EA335F)',
                borderWidth: '0',
                color: 'white',
                fontWeight: 'bold',
                margin: '1em 0 1em 0',
                fontSize: '1.2em',
                cursor: 'pointer'
              }}
              className="  text-black border-white max-w-[75%] mx-auto w-full"
            >
              SIGN IN
            </button>
            <div
              style={{
                height: '2px',
                width: '100%',
                backgroundColor: 'gray',
                margin: '1em 0'
              }}
            />
            <button
              onClick={handleBookDemo}
              style={{
                padding: '0.5em 0',
                borderRadius: '0.5em',
                background: 'linear-gradient(90deg, #8BE1C9, #9747FF)',
                borderWidth: '0',
                color: 'white',
                fontWeight: 'bold',
                margin: '1em 0 1em 0',
                fontSize: '1.2em',
                cursor: 'pointer'
              }}
              className="  text-black border-white max-w-[75%] mx-auto w-full"
            >
              BOOK A DEMO
            </button>
            <div
              style={{
                textAlign: 'center',
                whiteSpace: 'pre',
                fontSize: '1.2em',
                marginTop: '0.5em',
                color: 'black'
              }}
            >
              {`We are onboarding select\nstudios to our platform.`}
            </div>
          </div>

          <div
            id='img-box'
            style={{
              display: 'flex',
              width: '100%',
              padding: 'auto auto 5em auto'
            }}
          >
            <img
              style={{
                maxHeight: '50vh',
                aspectRatio: 1,
                width: '100%',
                maxWidth: '20em',
                minWidth: '10em',
                margin: 'auto',
                marginBottom: '3em'
              }}
              src={UnAuthImage}
              alt='landing_page_welcome'
            />

          </div>

        </div>

        <div
          className='landingPageLoginBoxWide'
          style={{
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            maxWidth: '50%',
            minWidth: 'fit-content',
            minHeight: '20em',
            justifyContent: 'center',
            margin: 'auto auto',
            padding: '5em 2em 5em 2em',
            color: 'black'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '4em 3em 4.5em 3em',
              borderRadius: '2em',
              backgroundColor: 'white',
              minHeight: '25em',
              width: '20em',
              margin: 'auto auto',
              height: 'fit-content'
            }}
          >
            <img
              src={HelikaIcon}
              alt='helika icon'
              style={{
                height: '5em',
              }}
            />
            <div
              style={{
                color: 'black',
                fontWeight: 600,
                width: '100%',
                textAlign: 'center',
                fontSize: '1.8em',
                margin: '0 0 1em 0'
              }}
            >
              PORTAL
            </div>
            <button
              onClick={handleLogin}
              style={{
                padding: '0.5em 0',
                borderRadius: '0.5em',
                background: 'linear-gradient(90deg, #9747FF, #EA335F)',
                borderWidth: '0',
                color: 'white',
                fontWeight: 'bold',
                margin: '1em 0 1em 0',
                fontSize: '1.2em',
                cursor: 'pointer'
              }}
              className="  text-black border-white max-w-[75%] mx-auto w-full"
            >
              SIGN IN
            </button>
            <div
              style={{
                height: '2px',
                width: '100%',
                backgroundColor: 'gray',
                margin: '1em 0'
              }}
            />
            <button
              onClick={handleBookDemo}
              style={{
                padding: '0.5em 0',
                borderRadius: '0.5em',
                background: 'linear-gradient(90deg, #8BE1C9, #9747FF)',
                borderWidth: '0',
                color: 'white',
                fontWeight: 'bold',
                margin: '1em 0 1em 0',
                fontSize: '1.2em',
                cursor: 'pointer'
              }}
              className="  text-black border-white max-w-[75%] mx-auto w-full"
            >
              BOOK A DEMO
            </button>
            <div
              style={{
                textAlign: 'center',
                whiteSpace: 'pre',
                fontSize: '1.2em',
                marginTop: '0.5em'
              }}
            >
              {`We are onboarding select\nstudios to our platform.`}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};