import React from 'react'
import loadingSVG from '../assets/loading.svg'

export interface LoadingComponentProps {
  width?: string
}
export default function LoadingComponent(props: LoadingComponentProps) {
  const { width } = props
  return (
    <div style={{ margin: 'auto', width: width ? width : 'fit-content' }}>
      <img
        className="animate-spin"
        src={loadingSVG}
        alt=""
        width={width ? width : ''}
      />
    </div>
  )
}
