import { Box, useTheme } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { newColor } from '../../../consts/colors'
import { Button, FormControlLabel, Typography } from '@mui/material';
import LoadingComponent from '../../../components/LoadingComponent';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { getActionsList, getEmailDomainList, getEmailMarketingCampaigns, updateAction } from '../../../utils/api/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateNewEmailCampaignModal from '../../../components/popups/CreateNewEmailCampaignModal';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getTeamName } from 'src/utils/user'
import { Android12Switch } from 'src/pages/CommonPages/Profile';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { ReactComponent as PlusIcon } from '../../../assets/plus_icon.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/archive_icon.svg'
import ActionsOptionsMenu from './ActionsOptionsMenu';
import EditEmailCampaignModal from 'src/components/popups/EditEmailCampaignModal';
import DeleteEmailMarketingActionWarningModal from 'src/components/popups/DeleteEmailMarketingActionWarningModal';
import EmailMarketingCampaignOptionsMenu from './EmailMarketingCampaignOptionsMenu';
import EmailCampaignsSortMenu from './EmailCampaignsSortMenu';
import EmailActionsSortMenu from './EmailActionsSortMenu';

export default function EmailCampaignsList() {

    const theme = useTheme()
    const [searchParams,] = useSearchParams();
    const user = useSelector((state: any) => state.user)

    const navigate = useNavigate()
    const [campaigns, setCampaigns] = useState<any[]>([])
    const [actions, setActions] = useState<any[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [domains, setDomains] = useState<any[]>([])
    const [selectedCampaign, setSelectedCampaign] = useState<any>()
    const [menuSelectedCampaign, setMenuSelectedCampaign] = useState<any>()
    const [selectedAction, setSelectedAction] = useState<any>()
    const [isCreateNewEmailCampaignModalOpen, setIsCreateNewEmailCampaignModalOpen] = useState<boolean>(false)
    const [showEditCampaignModal, setShowEditCampaignModal] = useState<boolean>(false)
    const [showDeleteActionWarnignModal, setShowDeleteActionWarnignModal] = useState<boolean>(false)
    const { getWithAccessToken, postWithAccessToken } = useContext(Auth0Context)
    const [showArchived, setShowArchived] = useState<boolean>(false)
    const [sort, setSort] = useState<string>('Date Modified');
    const [order, setOrder] = useState<string>('Newest First');
    const [actionsSort, setActionsSort] = useState<string>('Date Modified');
    const [actionsOrder, setActionsOrder] = useState<string>('Newest First');
    const [campaignNameSearchTerm, setCampaignNameSearchTerm] = useState<string>('');
    const [prevSearchCampaignName, setPrevSearchCampaignName] = useState<string>('');
    const [actionNameSearchTerm, setActionNameSearchTerm] = useState<string>('');
    const [prevSearchActionName, setPrevSearchActionName] = useState<string>('');

    useEffect(() => {

        const getDomainList = async () => {
            try {
                setLoading(true)
                const data = await getEmailDomainList(getWithAccessToken, {})
                if (data?.results) {
                    setDomains(data?.results)
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        getDomainList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //get Email Marketing Campaigns
    useEffect(() => {
        const getCampaignList = async () => {
            try {
                setLoading(true)
                let paramString = ''
                let campaignSortParam = ''
                let campaignSearchTerm = ''
                switch (sort) {
                    case 'Date Created':
                        campaignSortParam = `sort_created_at=${order === 'Newest First' ? 'desc' : 'asc'}`
                        break
                    case 'Title':
                        campaignSortParam = `sort_alphabetically=${order === 'Newest First' ? 'desc' : 'asc'}`
                        break
                    case 'Date Modified':
                    default:
                        campaignSortParam = `sort_modified_at=${order === 'Z - A' ? 'desc' : 'asc'}`
                }
                if (!_.isEmpty(campaignNameSearchTerm?.trim())) {
                    campaignSearchTerm = `name=${campaignNameSearchTerm?.trim()}`
                }
                if (!(_.isEmpty(campaignSortParam?.trim()) && _.isEmpty(campaignNameSearchTerm?.trim()))) {
                    paramString = '?'
                    if (_.isEmpty(campaignSortParam?.trim())) {
                        paramString = paramString.concat(campaignSortParam?.trim())
                        if (!_.isEmpty(campaignNameSearchTerm)) {
                            paramString = paramString?.concat(`&${campaignSearchTerm}`)
                        }
                    } else if (!_.isEmpty(campaignNameSearchTerm?.trim())) {
                        paramString = paramString?.concat(`name=${campaignNameSearchTerm}`)
                    }
                }
                const data = await getEmailMarketingCampaigns(getWithAccessToken, paramString)
                if (data?.results) {
                    setCampaigns(data.results)
                    let campaignId = searchParams.get('campaign-id');
                    let foundCampaign = data.results?.find((campaignItem: any) => campaignItem.id?.toString() === campaignId?.toString())
                    setSelectedCampaign(foundCampaign ? foundCampaign : data.results[0])
                    setSelectedAction(undefined)
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        getCampaignList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, order])

    //search campaigns
    async function searchCampaigns(clearSearch = false) {
        try {
            if (prevSearchCampaignName?.trim() === campaignNameSearchTerm?.trim() && !(clearSearch && !_.isEmpty(prevSearchCampaignName?.trim()))) return
            setLoading(true)
            let paramString = ''
            let campaignSortParam = ''
            let campaignSearchTerm = ''
            switch (sort) {
                case 'Date Created':
                    campaignSortParam = `sort_created_at=${order === 'Newest First' ? 'desc' : 'asc'}`
                    break
                case 'Title':
                    campaignSortParam = `sort_alphabetically=${order === 'Newest First' ? 'desc' : 'asc'}`
                    break
                case 'Date Modified':
                default:
                    campaignSortParam = `sort_modified_at=${order === 'Z - A' ? 'desc' : 'asc'}`
            }
            let noNameSearch = _.isEmpty(campaignNameSearchTerm?.trim()) || clearSearch;
            if (clearSearch) {
                setCampaignNameSearchTerm('')
                setPrevSearchCampaignName('')
            }
            if (!noNameSearch) {
                campaignSearchTerm = `name=${campaignNameSearchTerm?.trim()}`
            }
            if (!(_.isEmpty(campaignSortParam?.trim()) && noNameSearch)) {
                paramString = '?'
                if (_.isEmpty(campaignSortParam?.trim())) {
                    paramString = paramString.concat(campaignSortParam?.trim())
                    if (!_.isEmpty(campaignNameSearchTerm)) {
                        paramString = paramString?.concat(`&${campaignSearchTerm}`)
                    }
                } else if (!noNameSearch) {
                    paramString = paramString?.concat(`name=${campaignNameSearchTerm}`)
                }
            }
            const data = await getEmailMarketingCampaigns(getWithAccessToken, paramString)
            if (data?.results) {
                setPrevSearchActionName('')
                setActionNameSearchTerm('')
                setCampaigns(data.results)
                let campaignId = searchParams.get('campaign-id');
                let foundCampaign = data.results?.find((campaignItem: any) => campaignItem.id?.toString() === campaignId?.toString())
                setSelectedCampaign(foundCampaign ? foundCampaign : data.results[0])
                setSelectedAction(undefined)
            }
        } catch (e: any) {
            toast.error(e?.message)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    //search action
    async function searchActions(clearSearch = false) {
        try {
            if (prevSearchActionName?.trim() === actionNameSearchTerm?.trim() && !(clearSearch && !_.isEmpty(prevSearchActionName?.trim()))) return
            setLoading(true)
            let paramString = ''
            let actionSortParam = ''
            let actionSearchTerm = ''
            switch (sort) {
                case 'Date Created':
                    actionSortParam = `sort_created_at=${order === 'Newest First' ? 'desc' : 'asc'}`
                    break
                case 'Title':
                    actionSortParam = `sort_alphabetically=${order === 'Newest First' ? 'desc' : 'asc'}`
                    break
                case 'Date Modified':
                default:
                    actionSortParam = `sort_modified_at=${order === 'Z - A' ? 'desc' : 'asc'}`
            }
            let noNameSearch = _.isEmpty(actionNameSearchTerm?.trim()) || clearSearch;
            if (clearSearch) {
                setActionNameSearchTerm('')
                setPrevSearchActionName('')
            }
            if (!noNameSearch) {
                actionSearchTerm = `name=${actionNameSearchTerm?.trim()}`
            }
            if (!(_.isEmpty(actionSortParam?.trim()) && actionSearchTerm)) {
                paramString = '&'
                if (_.isEmpty(actionSortParam?.trim())) {
                    paramString = paramString.concat(actionSortParam?.trim())
                    if (!_.isEmpty(actionSearchTerm)) {
                        paramString = paramString?.concat(`&${actionSearchTerm}`)
                    }
                } else if (!noNameSearch) {
                    paramString = paramString?.concat(`name=${actionNameSearchTerm}`)
                }
            }
            const data = await getActionsList(getWithAccessToken, selectedCampaign.id, paramString)
            if (data?.results) {
                setActions(data?.results)
            }
        } catch (e: any) {
            toast.error(e?.message)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    //get actions
    useEffect(() => {
        const getActions = async () => {
            try {
                setLoading(true)
                const data = await getActionsList(getWithAccessToken, selectedCampaign.id, '')
                if (data?.results) {
                    setActions(data?.results)
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        if (!selectedCampaign?.id) {
            setActions([])
            return
        }

        getActions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCampaign])

    async function selectCampaign(campaign: any) {
        setSelectedCampaign(campaign)
        setSelectedAction(undefined)
    }

    async function switchActionActiveHandler(action: any) {
        try {
            setLoading(true)
            let newAction = { ...action }
            newAction.active = !action.active
            const data = await updateAction(postWithAccessToken, newAction)
            setActions((prevState: any) => {
                let newState = prevState.map((actionItem: any) => {
                    if (actionItem.id === data.results.id) return data.results
                    return actionItem
                })
                return newState
            })
        } catch (e: any) {
            toast.error(e?.message)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                overflow: 'hidden',
                minWidth: '60em'
            }}
        >
            <CreateNewEmailCampaignModal
                open={isCreateNewEmailCampaignModalOpen}
                setOpen={setIsCreateNewEmailCampaignModalOpen}
                onCreated={(newCampaign: any) => {
                    setCampaigns((prevState: any[]) => {
                        if (!prevState) return [newCampaign]
                        return prevState.concat(newCampaign)
                    })
                    setSelectedCampaign(newCampaign)
                    setSelectedAction(undefined)
                }}
                domains={domains}
            />
            <EditEmailCampaignModal
                open={showEditCampaignModal}
                setOpen={setShowEditCampaignModal}
                campaign={menuSelectedCampaign}
                onEdited={(newCampaign: any) => {
                    setCampaigns((prevState: any[]) => {
                        return prevState.map(campaignItem => {
                            if (campaignItem?.id === newCampaign?.id) return newCampaign
                            return campaignItem
                        })
                    })
                }}
                domains={domains}
            />
            <DeleteEmailMarketingActionWarningModal
                open={showDeleteActionWarnignModal}
                setOpen={setShowDeleteActionWarnignModal}
                action={selectedAction}
                setActions={setActions}
                title={'Delete Email Marketing Action'}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '15em',
                    borderRight: 1,
                    borderColor: newColor.darkGray,
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: 1,
                        borderColor: newColor.darkGray,
                        padding: '1em'
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '1.2em'
                        }}
                    >
                        Campaigns
                    </Box>
                    <Box
                        sx={{
                            background: 'red',
                            padding: '0 0.4em 0 0.4em !important',
                            borderRadius: '0.2em',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        onClick={() => setIsCreateNewEmailCampaignModalOpen(true)}
                    >
                        <StyledSvg
                            sx={{
                                '& svg, & path': {
                                    fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                height: '0.7rem',
                                margin: 'auto',
                                cursor: 'pointer',
                            }}
                        >
                            <PlusIcon
                                style={{
                                    cursor: 'pointer',
                                }}
                            />
                        </StyledSvg>
                    </Box>
                </Box>
                {
                    // sort box
                }
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            padding: '0.5em 0.5em 0 0.5em',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <input
                            className={
                                theme.palette.mode === 'dark' ? 'helikaInputDark' : 'helikaInput'
                            }
                            type="text"
                            style={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                backgroundColor:
                                    theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                                boxShadow: 'none',
                                borderStyle: 'solid',
                                borderRadius: '0.3em',
                                borderWidth: '1px',
                                marginTop: 'auto',
                                paddingLeft: '2em',
                                padding: '0.55em',
                                width: '100%',
                                marginRight: '1em',
                            }}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    setPrevSearchCampaignName(campaignNameSearchTerm)
                                    await searchCampaigns()
                                }
                            }}
                            value={campaignNameSearchTerm}
                            onChange={(e) => {
                                setCampaignNameSearchTerm(e.currentTarget.value)
                            }}
                            placeholder="SEARCH"
                        />
                        <button
                            onClick={async () => {
                                if (_.isEmpty(campaignNameSearchTerm?.trim())) return
                                setCampaignNameSearchTerm('')
                                await searchCampaigns(true)
                            }}
                            style={{
                                height: '1em !important',
                                maxWidth: '1em !important',
                                margin: 'auto !important',
                                padding: '0 0.75em 0 0.75em',
                                outlineStyle: 'none !important',
                                borderStyle: 'none !important',
                                outline: 'none !important',
                                border: 'none !important',
                                borderRadius: '0.5em',
                                backgroundColor: _.isEmpty(campaignNameSearchTerm?.trim()) ? 'transparent' : 'red',
                                cursor: _.isEmpty(campaignNameSearchTerm?.trim()) ? 'default' : 'pointer',
                                color: _.isEmpty(campaignNameSearchTerm?.trim()) ? 'gray' : theme.palette.text.primary
                            }}
                            disabled={_.isEmpty(campaignNameSearchTerm?.trim())}
                        >
                            X
                        </button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottom: 1,
                            borderColor: newColor.darkGray,
                            padding: '0.5em'
                        }}
                    >
                        <EmailCampaignsSortMenu
                            sort={sort}
                            setSort={setSort}
                            order={order}
                            setOrder={setOrder}
                        />
                        <Button
                            sx={{
                                color: newColor.gray,
                                padding: '1em',
                                border: 'solid',
                                borderColor: showArchived ? newColor.jazzberryJam : newColor.dimGray,
                                borderRadius: '0.5em !important',
                                textTransform: 'none',
                                fontSize: '0.8em',
                            }}
                            onClick={() => {
                                setShowArchived(prevState => {
                                    setSelectedCampaign((prevStateCampaign: any) => {
                                        let list = campaigns?.filter(camp => ((camp.state === 'archived' && !prevState) || (camp.state !== 'archived' && prevState)))
                                        if (_.isEmpty(list)) return undefined
                                        return list[0]
                                    })
                                    return !prevState
                                })
                            }}
                        >
                            {
                                showArchived
                                    ?
                                    <ArchiveIcon
                                        style={{
                                            cursor: 'pointer',
                                            color: 'red !important',
                                            fill: 'red !important',
                                        }}
                                    />
                                    :
                                    <StyledSvg
                                        sx={{
                                            '& svg, & path': {
                                                fill: 'white',
                                            },
                                        }}
                                    >
                                        <ArchiveIcon
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </StyledSvg>
                            }

                            <Box sx={{ marginLeft: '0.5em', color: showArchived ? newColor.jazzberryJam : newColor.gray, }}>Archives</Box>
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                    }}
                >

                    {
                        _.isEmpty(campaigns)
                            ?
                            <Button
                                className='helikaButtonClass'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    margin: '1em auto 0 auto',
                                    borderRadius: '0.5em !important',
                                    background: 'red !important',
                                    padding: '0.5em 2em 0.5em 2em',
                                }}
                                onClick={() => { setIsCreateNewEmailCampaignModalOpen(true) }}
                            >
                                <StyledSvg
                                    sx={{
                                        '& svg, & path': {
                                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                        },
                                    }}
                                >
                                    <PlusIcon
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </StyledSvg>

                                <Box sx={{ marginLeft: '0.5em' }}>Create Campaign</Box>
                            </Button>
                            :
                            <Box
                                sx={{
                                    color: isLoading ? 'gray' : theme.palette.text.primary
                                }}
                            >
                                {
                                    campaigns?.filter(campaign => {
                                        return (showArchived && campaign.state === 'archived') || (!showArchived && campaign.state === 'active')
                                    })?.sort((a: any, b: any) => {
                                        switch (sort) {
                                            case 'Title':
                                                if (order === 'A - Z') {
                                                    return a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
                                                } else if (order === 'Z - A') {
                                                    return a.title?.toLowerCase() > b.title?.toLowerCase() ? -1 : 1
                                                }
                                                return a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
                                            case 'Date Created':
                                                if (order === 'Newest First') {
                                                    return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                                                } else if (order === 'Oldest First') {
                                                    return new Date(a.created_at) >= new Date(b.created_at) ? -1 : 1
                                                }
                                                return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                                            case 'Date Modified':
                                            default:
                                                if (order === 'Newest First') {
                                                    return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                                                } else if (order === 'Oldest First') {
                                                    return new Date(a.updated_at) >= new Date(b.updated_at) ? -1 : 1
                                                }
                                                return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                                        }
                                    }).map(campaign => {
                                        return (
                                            <Box
                                                key={`campaigns_${campaign.id}`}
                                                sx={{
                                                    padding: '0.5em 0.5em 0.5em 1em',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    cursor: isLoading ? 'not-allowed' : 'default'
                                                }}
                                                className={isLoading ? '' : `hoverEmailCampaignList ${campaign.id === selectedCampaign?.id ? 'hoverEmailCampaignListSelected' : ''}`}
                                                onClick={() => {
                                                    selectCampaign(campaign)
                                                }}
                                            >
                                                <Box>
                                                    {campaign?.title}
                                                </Box>
                                                <EmailMarketingCampaignOptionsMenu
                                                    setShowEditModal={setShowEditCampaignModal}
                                                    listSelectedCampaign={selectedCampaign}
                                                    campaign={campaign}
                                                    setCampaigns={setCampaigns}
                                                    setMenuSelectedCampaign={setMenuSelectedCampaign}
                                                    isLoading={isLoading}
                                                    setSelectedCampaign={setSelectedCampaign}
                                                    sort={sort}
                                                    order={order}
                                                />
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                    }
                </Box>
            </Box>
            <Box
                sx={{
                    width: 'calc(100% - 15em)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRight: 1,
                    borderColor: newColor.darkGray,
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0.5em'
                    }}
                >
                    <Box
                        style={{
                            fontSize: '0.8em',
                            color: newColor.gray
                        }}
                    >
                        {getTeamName(user)}
                    </Box>
                    <Box
                        sx={{
                            fontSize: '1.2em',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            style={{
                                margin: 'auto 0 auto 0'
                            }}
                        >
                            Campaign: {selectedCampaign?.title}
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            paddingTop: '0.5em',
                            maxHeight: '8em',
                            overflow: "hidden",
                            textOverflow: 'ellipsis !important',
                        }}
                        className='clamp5'
                    >
                        Domain: {domains?.find(domainItem => domainItem.id === selectedCampaign?.domain_id)?.domain}
                    </Typography>
                    <Typography
                        sx={{
                            paddingTop: '0.5em',
                            maxHeight: '8em',
                            overflow: "hidden",
                            textOverflow: 'ellipsis !important',
                        }}
                        className='clamp5'
                    >
                        Description: {selectedCampaign?.description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0.5em',
                        width: 'calc(100% - 1em)',
                        padding: '0em 0 1em 0',
                        maxHeight: '100%'
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '1.2em'
                        }}
                    >
                        Actions
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100vh - 12em)',
                            padding: '1em',
                            border: 'solid',
                            borderColor: newColor.dimGray,
                            borderRadius: '0.5em'
                        }}
                    >
                        {
                            _.isEmpty(campaigns)
                                ? noCampaignsBox(setIsCreateNewEmailCampaignModalOpen, theme)
                                :
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflowY: 'auto',
                                        height: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: '2.6em',
                                            }}
                                        >
                                            <input
                                                className={
                                                    theme.palette.mode === 'dark' ? 'helikaInputDark' : 'helikaInput'
                                                }
                                                type="text"
                                                style={{
                                                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                                    backgroundColor:
                                                        theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                                                    boxShadow: 'none',
                                                    border: 'solid',
                                                    borderColor: newColor.dimGray,
                                                    borderRadius: '0.3em',
                                                    marginTop: 'auto',
                                                    paddingLeft: '2em',
                                                    padding: '0 !important',
                                                    height: '100%',
                                                    width: '20em',
                                                }}
                                                onKeyDown={async (e) => {
                                                    if (e.key === 'Enter') {
                                                        setPrevSearchActionName(actionNameSearchTerm)
                                                        await searchActions()
                                                    }
                                                }}
                                                value={actionNameSearchTerm}
                                                onChange={(e) => {
                                                    setActionNameSearchTerm(e.currentTarget.value)
                                                }}
                                                placeholder="SEARCH"
                                            />
                                            <button
                                                onClick={async () => {
                                                    if (_.isEmpty(actionNameSearchTerm?.trim())) return
                                                    setActionNameSearchTerm('')
                                                    await searchActions(true)
                                                }}
                                                style={{
                                                    height: '3em',
                                                    maxWidth: '1em !important',
                                                    margin: 'auto !important',
                                                    padding: '0 1em 0 1em',
                                                    outlineStyle: 'none !important',
                                                    borderStyle: 'none !important',
                                                    outline: 'none !important',
                                                    border: 'none !important',
                                                    marginLeft: '1em',
                                                    borderRadius: '0.5em',
                                                    backgroundColor: _.isEmpty(actionNameSearchTerm?.trim()) ? 'transparent' : 'red',
                                                    cursor: _.isEmpty(actionNameSearchTerm?.trim()) ? 'default' : 'pointer',
                                                    color: _.isEmpty(actionNameSearchTerm?.trim()) ? 'gray' : theme.palette.text.primary
                                                }}
                                                disabled={_.isEmpty(actionNameSearchTerm?.trim())}
                                            >
                                                X
                                            </button>
                                        </Box>
                                        <EmailActionsSortMenu
                                            sort={actionsSort}
                                            setSort={setActionsSort}
                                            order={actionsOrder}
                                            setOrder={setActionsOrder}
                                        />
                                        {
                                            !showArchived &&
                                            <Button
                                                className='helikaButtonClass'
                                                sx={{
                                                    margin: '0 0 1em 1em',
                                                    borderRadius: '0.5em !important',
                                                    background: 'red !important',
                                                    padding: '0.5em 2em 0.5em 2em'
                                                }}
                                                onClick={() => {
                                                    navigate(`/game_management/email-marketing/actions?campaign-id=${selectedCampaign?.id}`)
                                                }}
                                            >

                                                <StyledSvg
                                                    sx={{
                                                        '& svg, & path': {
                                                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                                        },
                                                    }}
                                                >
                                                    <PlusIcon
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </StyledSvg>
                                                <Box sx={{ marginLeft: '0.5em' }}>Create Action</Box>
                                            </Button>
                                        }
                                    </Box>
                                    {
                                        isLoading
                                            ? <LoadingComponent />
                                            :
                                            _.isEmpty(actions)
                                                ? noActionsBox(selectedCampaign, navigate)
                                                :
                                                <Box
                                                    id='actionsbox'
                                                    sx={{
                                                        overflowY: 'auto',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 2,
                                                        height: '100%'
                                                    }}
                                                >
                                                    {
                                                        actions?.sort((a: any, b: any) => {
                                                            switch (actionsSort) {
                                                                case 'Title':
                                                                    if (actionsOrder === 'A - Z') {
                                                                        return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                                                                    } else if (actionsOrder === 'Z - A') {
                                                                        return a.name?.toLowerCase() > b.name?.toLowerCase() ? -1 : 1
                                                                    }
                                                                    return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                                                                case 'Date Created':
                                                                    if (actionsOrder === 'Newest First') {
                                                                        return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                                                                    } else if (actionsOrder === 'Oldest First') {
                                                                        return new Date(a.created_at) >= new Date(b.created_at) ? -1 : 1
                                                                    }
                                                                    return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                                                                case 'Date Modified':
                                                                default:
                                                                    if (actionsOrder === 'Newest First') {
                                                                        return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                                                                    } else if (actionsOrder === 'Oldest First') {
                                                                        return new Date(a.updated_at) >= new Date(b.updated_at) ? -1 : 1
                                                                    }
                                                                    return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                                                            }
                                                        })?.map((action: any) => {
                                                            return (
                                                                <Box
                                                                    key={`actions_${action.id}`}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        height: '4em',
                                                                        width: '100%',
                                                                        border: 'solid',
                                                                        borderRadius: '0.5em',
                                                                        padding: '1em',
                                                                        borderColor: newColor.darkGunmetal,
                                                                        justifyContent: 'space-between'
                                                                    }}
                                                                    className='hoverEmailCampaignActionsList'
                                                                    onClick={() => { setSelectedAction(action) }}
                                                                >
                                                                    <Box>
                                                                        {action.name}
                                                                    </Box>
                                                                    {
                                                                        !showArchived &&
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row'
                                                                            }}
                                                                        >
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Android12Switch
                                                                                        checked={action.active}
                                                                                        onClick={() => { switchActionActiveHandler(action) }}
                                                                                    />}
                                                                                label={<div style={{ color: 'white', width: '3em' }}>{action.active ? 'ON' : 'OFF'}</div>}
                                                                            />
                                                                            <ActionsOptionsMenu
                                                                                selectedCampaign={selectedCampaign}
                                                                                action={action}
                                                                                showConfirmDeleteModal={setShowDeleteActionWarnignModal}
                                                                            />
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                    }
                                </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box >
    )

}


export function noActionsBox(campaign: any, navigate: any) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    fontSize: '1.2em',
                    fontWeight: 900
                }}
            >
                No Actions Found
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    color: newColor.gray,
                    padding: '0 2em 0.5em 2em'
                }}
            >
                Create an Action for this Email Campaign to see list here
            </Box>
            <Button
                className='helikaButtonClass'
                sx={{
                    margin: 'auto',
                    borderRadius: '0.5em !important',
                    background: 'red !important',
                    padding: '0.5em 2em 0.5em 2em'
                }}
                onClick={() => {
                    navigate(`/game_management/email-marketing/actions?campaign-id=${campaign?.id}`)
                }}
            >
                Create Action
            </Button>
        </Box>
    )
}

export function noCampaignsBox(setIsCreateNewEmailCampaignModalOpen: any, theme: any) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto'
            }}
        >
            <Box
                sx={{
                    margin: 'auto',
                    fontSize: '1.2em',
                    fontWeight: 900
                }}
            >
                No Campaigns Found
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    color: newColor.gray,
                    padding: '0 2em 0.5em 2em'
                }}
            >
                Create campaign to see start creating and sending templates/emails
            </Box>
            <Button
                className='helikaButtonClass'
                sx={{
                    margin: 'auto',
                    borderRadius: '0.5em !important',
                    background: 'red !important',
                    padding: '0.5em 2em 0.5em 2em'
                }}
                onClick={() => { setIsCreateNewEmailCampaignModalOpen(true) }}
            >

                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                    }}
                >
                    <PlusIcon
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </StyledSvg>
                <Box sx={{ marginLeft: '0.5em' }}>Create Campaign</Box>
            </Button>
        </Box>
    )
}