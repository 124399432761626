import React, { useState, useContext } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Box, Button, useTheme, styled as muiStyled, Grid } from '@mui/material'
import { postProjectBuilder } from '../../utils/api/queries'
import { toast } from 'react-toastify'
import { Auth0Context } from '../../contexts/Auth0Context'

export interface ModalProps {
  open: boolean
  onClose: () => void
  organization_id: string
  showCanvasComponent: (projectId: number) => void
}

// Styled component for input fields
const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#2E2E2E' : '#e2e2e2',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#373737' : '#e9e9e9',
  color: theme.palette.text.primary,
  padding: '4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    borderColor: '#ea335f',
  },
}))

const CreateProjectModal: React.FC<ModalProps> = ({
  open,
  onClose,
  organization_id,
  showCanvasComponent,
}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const { postWithAccessToken } = useContext(Auth0Context)
  const theme = useTheme()

  // Function to handle project creation
  const createProject = async (params: { [key: string]: any }) => {
    try {
      const response = await postProjectBuilder(postWithAccessToken, params)
      toast.success('Successfully created a project!')
      showCanvasComponent(response.result.id)
      onClose()
    } catch (error: any) {
      toast.error(error.message || 'Error creating project')
    }
  }

  // Function to validate and save project
  const handleSave = () => {
    if (!name.trim() || !description.trim()) {
      toast.error('Please provide a project name and description')
      return
    }

    // Preparing data for the API call
    const projectData = {
      organization_id,
      priority: 1,
      active: true,
      name,
      description,
    }

    createProject(projectData)
    setName('')
    setDescription('')
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableRestoreFocus
      fullScreen
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? '#181818' : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="32px">
        <Box
          display="flex"
          flexDirection="row"
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${
              theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              Create New Project
            </div>
          </DialogTitle>
        </Box>

        <Box p={2}>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            display="flex"
            alignContent="center"
            flexDirection="row"
            paddingLeft="35px"
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '150px' }}
            >
              <div style={{ fontSize: '14px', width: 'max-content' }}>
                PROJECT NAME:
              </div>
            </Grid>
            <Grid style={{ width: '300px' }}>
              <Input
                id="name"
                placeholder="Project Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            display="flex"
            alignContent="center"
            flexDirection="row"
            paddingLeft="35px"
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '150px' }}
            >
              <div style={{ fontSize: '14px', width: 'max-content' }}>
                PROJECT DESCRIPTION:
              </div>
            </Grid>
            <Grid style={{ width: '300px' }}>
              <Input
                id="description"
                placeholder="Project Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={onClose}
              sx={{ width: '15em' }}
              className="helikaButtonClass"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              sx={{ width: '15em', marginLeft: '1em' }}
              className="helikaButtonClass"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default CreateProjectModal
