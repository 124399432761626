//imports
import React from "react"
import { useTheme } from '@mui/material';


//contexts

//services

//components
import { ReactComponent as SaveIcon } from 'src/assets/explore_save_icon.svg'
import { ReactComponent as CaratDownIcon } from 'src/assets/carat_down_icon.svg'
import { newColor } from "src/consts/colors";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { StyledSvg } from "./organisms/v2/tabs/styled";
//---------------------------------------------------------------------------------

function DashboardDesignerSaveMenu({
  selectedDashboard,
  updateDashboardHandler,
  showSaveDashboardModal,
  showDeleteDashboardModal,
  showPublishDashboardModal,
  showUnpublishDashboardModal,
}: {
  selectedDashboard: any,
  updateDashboardHandler: any,
  showSaveDashboardModal: any,
  showDeleteDashboardModal: any,
  showPublishDashboardModal: any,
  showUnpublishDashboardModal: any,
}) {

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function menuItemDiv(name: string) {
    return (
      <div style={{ fontSize: '0.8em', paddingBottom: '0.5em', paddingTop: '0.5em', textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
        {name.toUpperCase()}
      </div>
    );
  }

  return (
    <div
      style={{
        width: 'fit-content'
      }}
    >
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          padding: '0.2em 0.5em 0.2em 0.5em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: theme.palette.text.primary,
          marginLeft: '0.5em',
          fontSize: '0.8em',
          fontWeight: '600',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <StyledSvg>
          <SaveIcon />
        </StyledSvg>
        <div
          style={{
            margin: '0.1em 0 0 0.5em'
          }}
        >
          SAVE
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 0 0 1em',
            padding: '0.5em 0 0.5em 0.5em',
            borderLeft: 'solid',
            borderColor: newColor.darkGunmetal
          }}
        >
          <StyledSvg
          >
            <CaratDownIcon />
          </StyledSvg>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
        sx={
          {
            marginTop: '1em',
            marginLeft: '0em',
            "& .MuiPaper-root":
              { background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke, borderRadius: '0.5em', border: 'solid', borderColor: newColor.darkGunmetal },
            "& .MuiList-root":
              { background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke, borderWidth: '1px', borderRadius: '0.5em', borderColor: theme.palette.mode === 'dark' ? newColor.whiteSmoke : 'black', paddingLeft: '0.5em', paddingRight: '0.5em', color: theme.palette.mode === 'dark' ? 'white' : 'black' },
            "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
              borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#3D3D3D', width: '100%'
            },
            ".MuiButtonBase-root:hover": {
              background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke,
              color: newColor.jazzberryJam
            },
          }
        }
      >
        <MenuItem
          disabled={!selectedDashboard?.id}
          onClick={updateDashboardHandler}
        >
          {menuItemDiv('SAVE')}
        </MenuItem>
        <MenuItem onClick={() => showSaveDashboardModal(true)}>{menuItemDiv('SAVE AS NEW')}</MenuItem>
        <MenuItem
          onClick={() => showPublishDashboardModal(true)}
          disabled={!selectedDashboard?.id}
        >
          {menuItemDiv('PUBLISH')}
        </MenuItem>
        <MenuItem
          onClick={() => showUnpublishDashboardModal(true)}
          disabled={!selectedDashboard?.id || !selectedDashboard?.published}
        >
          {menuItemDiv('UNPUBLISH')}
        </MenuItem>
        <MenuItem
          onClick={() => showDeleteDashboardModal(true)}
          disabled={!selectedDashboard?.id}
        >
          {menuItemDiv('DELETE')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default DashboardDesignerSaveMenu;