import { useContext, useEffect, useState } from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { createKochvaAppId, getOrgAPIKeys } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SmartIcon from '../../assets/Smart_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import SmartIconDark from '../../assets/Smart_Icon_dark.svg'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'
import { ApiKey, Environment } from '../../types/apiKey'

const StyledTextarea = muiStyled('textarea')(({ theme }) => ({
  width: '100%',
  height: '80px',
  borderRadius: '10px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
}

export default function CreateKochavaAppIdOrg({ orgs }: { orgs: Org[] }) {
  const theme = useTheme()
  const { getWithAccessToken, patchWithAccessToken } = useContext(Auth0Context)
  const [org, setOrg] = useState<number | null>(null)
  const [apiKeyId, setApiKeyId] = useState<number | null>(null)
  const [apiKeys, setApiKeys] = useState<ApiKey[]>();
  const [kochavaAppId, setKochavaAppId] = useState('')
  const [loading, setLoading] = useState(false)
  const [resultPanelData, setResultPanelData] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg(orgs[0].value)
  }, [orgs])

  useEffect(() => {
    getApiKeys()
  }, [org])

  useEffect(() => {
    if(apiKeys && apiKeys.length > 0)
      setApiKeyId(apiKeys[0].id)
    else
      setApiKeyId(null)
  }, [apiKeys])

  const getApiKeys = async () => {
    if (org) {
        const envs: Environment[] =
            process.env.REACT_APP_ENVIRONMENT === 'PROD'
                ? [Environment.staging, Environment.prod]
                : [Environment.staging]

        const data = await getOrgAPIKeys(getWithAccessToken, envs, org);
        if (!data || !data.results) return
        setApiKeys(_.orderBy(data.results, 'created_at', 'desc'))
    } else {
        setApiKeys([]);
    }
  }

  const createKochavaAppId = async () => {
    if (kochavaAppId && apiKeyId) {
      setLoading(true)
      await createKochvaAppId(patchWithAccessToken, {api_key_id: apiKeyId, kochava_app_id: kochavaAppId, env: apiKeys?.find(apiKey => apiKey.id == apiKeyId)?.env})
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success('Successful create Kochava App ID')
            setResultPanelData(
              `Created API KEY: ${res.results}`,
            )
          } else {
            toast.warning(res.status)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
      setLoading(false)
    } else {
      toast.warning('Please enter Kochava App ID and select an Api key.')
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? SmartIcon : SmartIconDark}
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;CREATE KOCHAVA APP ID FOR ORGANIZATION</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <InputGroup>
              <Typography>Choose organization</Typography>
              <SelectCategory value={org ?? undefined} onChange={(e) => {setOrg(+e.target.value)}}>
                {orgs
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  ?.map((org) => (
                    <option key={org.value} value={org.value}>
                      {org.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Choose API key</Typography>
              <SelectCategory value={apiKeyId ?? undefined} onChange={(e) => {setApiKeyId(+e.target.value)}}>
                {apiKeys
                  ?.map((apiKey) => (
                    <option key={apiKey.key} value={apiKey.id}>
                      {apiKey.key}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Enter Kochava App ID</Typography>
              <Input
                placeholder="Please enter Kochava App ID"
                value={kochavaAppId}
                type="text"
                required
                onChange={(e) => {setKochavaAppId(e.target.value.trim())}}
              />
            </InputGroup>
          </Grid>
        </Grid>
        <Button
          onClick={createKochavaAppId}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Create Kochava App ID</StyledTxt>
        </Button>
        <div
          style={{
            marginLeft: '28px',
            marginRight: '28px',
          }}
        >
          <StyledTextarea value={resultPanelData} readOnly />
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
