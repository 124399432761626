import { CSSObject, styled, Theme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import styledComps from 'styled-components'
import { newColor } from '../consts/colors'
import { closeDrawerWidth, drawerWidth, minDrawerWidth } from 'src/consts/sizes'
import { TextField } from '@mui/material'

export const openedMixin = (theme: Theme): CSSObject => ({
  //backgroundColor: palette.bg14,
  //color: palette.font,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
  width: drawerWidth,
  // minWidth: minDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  maxHeight: '100vh',
  scrollbarGutter: 'auto'
})

export const closedMixin = (theme: Theme): CSSObject => ({
  //backgroundColor: palette.bg14,
  //color: palette.font,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  width: closeDrawerWidth,
  [theme.breakpoints.up('sm')]: {
    width: closeDrawerWidth
  },
  maxHeight: '100vh'
})

export const DrawerHeader: any = styled('div')(({ theme }) => ({
  top: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  scrollbarGutter: 'auto'
  //minHeight:'7em !important',
  //padding: theme.spacing(0, 1.5),
  // necessary for content to be below app bar
  //...theme.mixins.toolbar,
}))

export const ScrollBlock: any = styledComps.div`
  display: flex;
  flex-direction: column;
  height:100%;
  overflow-y: auto;
  overflow-x:clip;
`

export const FullContainer: any = styledComps.div`
  display: flex;
  flex-direction: column;
  height:100%;
  background-color: white;
`

export const GradientSpan: any = styledComps.span`
  color: black;
`

export const AdminSpan: any = styledComps.a`
  color: black;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5em;
  margin-right: 0.5em;
`

export const SpanGradient: any = styledComps.span`
  background: linear-gradient(${newColor.jazzberryJam}, ${newColor.byzantium});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.3em;
`

export const IconGradient: any = styledComps.i`
  background: linear-gradient(${newColor.jazzberryJam}, ${newColor.byzantium});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.3em;
`

export const Textarea: any = styled(TextField)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  background: ${theme.palette.mode === 'dark' ? `${newColor.midnight}` : `${newColor.white}`};
  border: 1px solid red;
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ?  `${newColor.gunmetalGray}` : `${newColor.lightOffWhite}`};
  
  &:focus {
    border-color: ${newColor.richElectricBlue};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ?  `${newColor.lightOffWhite}` :  `${newColor.lightOffWhite}`};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  .css-18ax2bx-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    color: ${newColor.darkGrayishGray} !important;
  }
`,
)

export const Drawer: any = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  minWidth: minDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}))
