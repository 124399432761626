import React from 'react'
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'

// Modal container style
const ModalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  height: 240,
  backgroundColor: '#141414',
  borderRadius: '8px',
  boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
  padding: 0,
})

// Modal header style
const ModalHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '16px 24px',
  backgroundColor: '#141414',
  borderRadius: '8px 8px 0px 0px',
})

// Modal content style
const ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  gap: '16px',
  backgroundColor: '#141414',
  width: '100%',
})

// Field container for form inputs
const FieldContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
})

// Modal footer (buttons)
const ModalFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '16px 24px',
  backgroundColor: '#141414',
  borderRadius: '0px 0px 8px 8px',
})

// Button styles
const SaveButton = styled(Button)({
  padding: '8px 16px',
  borderRadius: '4px',
  backgroundColor: '#EA335F',
  color: '#FFFFFF',
  height: '36px',
  fontFamily: 'Open Sans, sans-serif',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
})

const CancelButton = styled(Button)({
  padding: '8px 16px',
  borderRadius: '4px',
  backgroundColor: 'transparent',
  color: '#FFFFFF',
  height: '36px',
  border: 'none',
  fontFamily: 'Open Sans, sans-serif',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

// EditUsageLimitModal component
const EditUsageLimitModal: React.FC<{
  open: boolean
  handleClose: () => void
  usageLimit: number
  setUsageLimit: (limit: number) => void
  handleSave: () => void
}> = ({ open, handleClose, usageLimit, setUsageLimit, handleSave }) => {
  // Helper function to handle value change, ensuring it's greater than 0
  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value > 0) {
      setUsageLimit(value)
    } else {
      setUsageLimit(1)
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        {/* Modal header with title and close button */}
        <ModalHeader>
          <Typography
            variant="h6"
            sx={{
              color: '#FFFFFF',
              fontSize: '20px',
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            Edit Code
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#D2D2D3' }} />
          </IconButton>
        </ModalHeader>

        {/* Modal content (usage limit input field) */}
        <ModalContent>
          <FieldContainer>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '14px',
                fontFamily: 'Open Sans, sans-serif',
                marginBottom: '10px'
              }}
            >
              Usage limit
            </Typography>
            <TextField
              type="number"
              value={usageLimit}
              onChange={handleLimitChange}
              fullWidth
              sx={{
                backgroundColor: '#1C2025',
                borderRadius: '4px',
                height: '36px',
                input: {
                  padding: '8px 12px',
                  color: '#FFFFFF',
                  fontFamily: 'Open Sans, sans-serif',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#434D5A',
                  },
                  '&:hover fieldset': {
                    borderColor: '#434D5A',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#434D5A',
                  },
                },
              }}
              InputProps={{
                style: {
                  color: '#FFFFFF',
                  fontFamily: 'Open Sans, sans-serif',
                },
              }}
            />
          </FieldContainer>
        </ModalContent>

        {/* Modal footer (buttons) */}
        <ModalFooter>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <SaveButton onClick={handleSave} sx={{ marginLeft: '8px' }}>
            Save
          </SaveButton>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

export default EditUsageLimitModal
