import ActionIcon from 'src/assets/action.svg'

import { createContext, useContext, useState } from 'react'
import { Box, Button, Popover } from '@mui/material'
import styled from 'styled-components'
import EditIcon from '@mui/icons-material/Edit'
import { useIsEditing } from './IsEditingContext'

const ActionPopoverContext = createContext<{
  isEdit: boolean
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const useActionPopover = () => {
  const context = useContext(ActionPopoverContext)
  if (!context) {
    throw new Error('Context have to be put under ActionPropperProvider')
  }

  return context
}

function ActionPopoverProvider({ children }: { children: React.ReactNode }) {
  const [isEdit, setIsEdit] = useState(false)

  return (
    <ActionPopoverContext.Provider value={{ isEdit, setIsEdit }}>
      {children}
    </ActionPopoverContext.Provider>
  )
}

function ToggleActionButton({ handleClose }: { handleClose: VoidFunction }) {
  const { setIsEdit } = useActionPopover()
  const { setIsEditing } = useIsEditing()
  return (
    <Button
      onClick={() => {
        setIsEdit(true)
        handleClose()
        setIsEditing(true)
      }}
      sx={{ color: 'text.primary' }}
    >
      <EditIcon sx={{ width: '18px', mr: '4px' }} />
      Edit
    </Button>
  )
}

const ActionButton: any = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  background: none;
  border: 0;
  cursor: pointer;
`

export default function ProfileEditToggle({
  children,
}: {
  children: React.ReactNode
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <ActionPopoverProvider>
      <Box sx={{ position: 'relative' }}>
        <ActionButton aria-describedby={id} onClick={handleClick}>
          <img src={ActionIcon} alt="edit" />
        </ActionButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ToggleActionButton handleClose={handleClose} />
        </Popover>
        {children}
      </Box>
    </ActionPopoverProvider>
  )
}
