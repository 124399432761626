import { newColor } from 'src/consts/colors';
import { Grid, useTheme } from '@mui/material';
import { EChartsOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';

import styles from 'src/components/atoms/v2/eChart/index.module.scss';

import { ErrorAlert } from 'src/components/atoms/v2/ErrorAlert';
import { idleOption, loadingOption } from 'src/utils/echarts';

type IEChartProps = { options?: EChartsOption; loading: boolean; error?: Error, fullWidth?: boolean };

export const VisualDesignerEchart = ({ options, loading, error, fullWidth }: IEChartProps) => {

  const theme: any = useTheme();

  return (
    <Grid
      id='echart-grid-content'
      item
      width={'100%'}
      sx={{
        height: '100%',
        width: '100%',
        padding: '1em',
      }}
    >
      <ErrorAlert error={error} />
      <ReactEcharts
        className={styles.echartBorder}
        showLoading={!options}
        loadingOption={loading ? loadingOption : idleOption}
        option={!!options ? options : idleOption}
        style={{
          background: theme.palette.palette.background.default,
          display: 'flex',
          minHeight: '20em',
          height: '100%',
          maxWidth: fullWidth ? '100%' : '90%',
          margin: 'auto',
          width: '100%',
          border: 'solid',
          borderColor: newColor.dimGray
        }}
        notMerge={true}
      />
    </Grid>
  );
};
