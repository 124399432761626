import { useContext } from 'react'
import useSWRMutation from 'swr/mutation'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { post } from 'src/utils/api/frontend'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { getUserData } from 'src/utils/api/queries'
import { actionCreators } from 'src/state'
import { bindActionCreators } from 'redux'
import type { TabData } from './type'
import { useActionSave } from './ActionContext'

export const useUpdateTabOrder = () => {
  const { getTokenIfNecessary, logoutAuth0 } = useContext(Auth0Context)
  const dispatch = useDispatch()
  const { setUserData } = bindActionCreators(actionCreators, dispatch)
  const { setIsLoading } = useActionSave()

  async function updateTabOrder(
    url: string,
    { arg }: { arg: { tabs: TabData } },
  ) {
    setIsLoading(true)
    const accessToken = await getTokenIfNecessary()

    await post(`${process.env.REACT_APP_HELIKA_API_URL}${url}`, {
      body: JSON.stringify(arg),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((resp) => {
        if (resp) {
          toast.success('Saved successfully!')
        }
      })
      .catch((error) => {
        console.error('Error updating tab order:', error)
        toast.error('Error updating tab order:', error)
      })

    // TODO: fetch data using swr instead
    let user_data = await getUserData(getTokenIfNecessary, logoutAuth0)
    if (!user_data) {
      return;
    }
    setIsLoading(false)
    setUserData({
      type: 'SET_USER_DATA',
      payload: user_data,
    })
  }

  return useSWRMutation('/v1/tabs/reorder', updateTabOrder)
}

export const useUpdateOrgTabOrder = () => {
  const { getTokenIfNecessary } = useContext(Auth0Context)
  const { setIsLoading } = useActionSave()

  async function updateOrgTabOrder(
    url: string,
    { arg }: { arg: { tabs: TabData, organizationId: number } },
  ) {
    setIsLoading(true)
    const accessToken = await getTokenIfNecessary()

    await post(`${process.env.REACT_APP_HELIKA_API_URL}${url}${arg.organizationId}`, {
      body: JSON.stringify(arg),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((resp) => {
        if (resp) {
          toast.success('Saved successfully!')
        }
      })
      .catch((error) => {
        console.error('Error updating tab order:', error)
        toast.error('Error updating tab order:', error)
      })

    setIsLoading(false)
  }

  return useSWRMutation(`/v1/tabs/org-reorder/`, updateOrgTabOrder)
}

export const useUpdateGlobalTabsOrder = () => {
  const { getTokenIfNecessary } = useContext(Auth0Context)
  const { setIsLoading } = useActionSave()

  async function updateGlobalTabsOrder(
    url: string,
    { arg }: { arg: { tabs: TabData } },
  ) {
    setIsLoading(true)
    const accessToken = await getTokenIfNecessary()

    await post(`${process.env.REACT_APP_HELIKA_API_URL}${url}`, {
      body: JSON.stringify(arg),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((resp) => {
        if (resp) {
          toast.success('Saved successfully!')
        }
      })
      .catch((error) => {
        console.error('Error updating tab order:', error)
        toast.error('Error updating tab order:', error)
      })

    setIsLoading(false)
  }

  return useSWRMutation(`/v1/tabs/reorder`, updateGlobalTabsOrder)
}
