import React from 'react'
import { getIcon } from '../../utils/string'
import { styled as muiStyled } from '@mui/material'
import { newColor } from '../../consts/colors'

export const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& path[fill]': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },

  '& path[stroke]': {
    stroke: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))

export function SupportDataGridHeaderComponent(props: any) {
  let icon = getIcon(
    props.colDef.headerName?.toLowerCase(),
    props.theme?.palette.mode,
  )

  return (
    <div style={{ margin: 'auto', width: 'fit-content' }}>
      <div
        className="supportHeaderParent"
        style={{
          color: props.theme?.palette.text.primary,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'start',
          margin: 'auto',
          width: 'fit-content',
        }}
      >
        <div
          style={{
            marginRight: '1em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <StyledSvg sx={{ display: 'flex' }}>{icon}</StyledSvg>
        </div>
        <div className="customHeaderLabel" style={{ margin: 'auto' }}>
          {props.colDef.headerName}
        </div>
      </div>
    </div>
  )
}
