import { ChangeEvent, useContext, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { RS_CHAIN_SCHEMAS } from '../../consts/backend'
import { toast } from 'react-toastify'
import { linkCollectionToOrgApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SmartIcon from '../../assets/Smart_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import SmartIconDark from '../../assets/Smart_Icon_dark.svg'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const ResponsiveGrid = muiStyled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: '0 0 40px 30px',
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))
const DescirptionArea = muiStyled('div')(() => ({
  width: '277.663px',
  height: '133px',
  flexShrink: '0',
  borderRadius: '12px',
  background: `linear-gradient(246deg, ${newColor.cosmicCobalt} -10.05%, ${newColor.jazzberryJam} 101.22%)`,
  padding: '39px 25px 42px 30px',
  color: 'white',
  float: 'right',
  marginRight: '20px',
}))
interface Org {
  label: string
  value: number
}

enum Access {
  is_internal = 'true',
  is_external = 'false',
}

export default function AssociateContractToOrg({ orgs }: { orgs: any[] }) {
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)

  const [address, setAddress] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [access, setAccess] = useState<Access>(Access.is_internal)
  const [chain, setChain] = useState('')
  const [loading, setLoading] = useState(false)

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
    }
  }

  const onChangeAddress = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setAddress(e?.target.value)
    }
  }

  const onChangeChain = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setChain(e?.target.value)
    }
  }
  const onChangeAccess = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setAccess(e?.target.value as Access)
    }
  }

  const linkCollectionToOrg = async () => {
    if (address && org && chain) {
      setLoading(true)
      await linkCollectionToOrgApi(
        postWithAccessToken,
        address,
        org.label,
        org.value,
        chain,
        access,
      )
      setLoading(false)
    } else {
      toast.warning(
        `Please enter collection address and choose chain and organization`,
      )
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? SmartIcon : SmartIconDark}
          alt="SocialOrganization"
        />
        <Typography>
          &nbsp;&nbsp;ASSOCIATE SMART CONTRACT ADDRESS WITH ORGANIZATION
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <InputGroup>
              <Typography>Enter contract address starting from 0x</Typography>
              <Input
                placeholder="contract address"
                value={address}
                type="text"
                required
                onChange={onChangeAddress}
              />
            </InputGroup>
            <InputGroup>
              <Typography>Choose chain</Typography>
              <SelectCategory required value={chain} onChange={onChangeChain}>
                <option disabled value="">
                  {' '}
                  -- select an option --{' '}
                </option>
                {Object.entries(RS_CHAIN_SCHEMAS).map(([chain, schema]) => (
                  <option key={schema} value={schema}>
                    {chain}
                  </option>
                ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Choose organization</Typography>
              <SelectCategory value={org?.value} onChange={setSelectedOrg}>
                {orgs
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  ?.map((org) => (
                    <option key={org.value} value={org.value}>
                      {org.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Access</Typography>
              <SelectCategory value={access} onChange={onChangeAccess}>
                <option key={Access.is_internal} value={Access.is_internal}>
                  is_internal
                </option>
                <option key={Access.is_external} value={Access.is_external}>
                  is_external
                </option>
              </SelectCategory>
            </InputGroup>
          </Grid>
          <ResponsiveGrid item md={4} style={{ alignSelf: 'center' }}>
            <DescirptionArea>
              Adds entry with (name, contract_address) into
              CHAIN.organization_access
            </DescirptionArea>
          </ResponsiveGrid>
        </Grid>
        <Button
          onClick={linkCollectionToOrg}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Link Collection To Org</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
