//imports
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//contexts

//services
import { canSeeExplore, isHelikaAdmin, isHelikaSuperAdmin } from 'src/utils/user';

//components
import { newColor } from 'src/consts/colors';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { ReactComponent as ExploreIconHeader } from 'src/assets/explore_icon.svg'
import { ReactComponent as VisualDesignerIcon } from 'src/assets/visual_designer_icon.svg'
import { ReactComponent as DashboardDesignerIcon } from 'src/assets/dashboard_designer_icon.svg'
import { useSelector } from 'react-redux';

export const ExplorerHeader = () => {

  const theme: any = useTheme();
  const navigate = useNavigate()
  const USER_DATA = useSelector((state: any) => state.user)

  const current_location = window.location.pathname
  let exploreType = null
  if (current_location?.startsWith('/analytics/explorer/queries')) {
    exploreType = 'SQL EXPLORER'
  } else if (current_location?.startsWith('/analytics/designer/visuals')) {
    exploreType = 'VISUAL DESIGNER'
  } else if (current_location?.startsWith('/analytics/designer/dashboards')) {
    exploreType = 'DASHBOARD DESIGNER'
  }

  let hasHelikaAdminPrivileges =
    isHelikaAdmin(USER_DATA) || isHelikaSuperAdmin(USER_DATA)

  return (
    <div
      style={{
        width: '100%',
        height: '4.5em',
        padding: '0.5em 0.5em 0 0.5em',
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          border: 'solid',
          borderColor: newColor.darkGunmetal,
          borderRadius: '1em',
          borderWidth: '0.5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
        }}
      >
        <div
          style={{
            margin: 'auto 1em auto 1em',
            padding: '0.5rem 1rem 0.5rem 1rem',
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            borderRadius: '0.5rem',
            background: exploreType === 'SQL EXPLORER' ? theme.palette.mode === 'dark' ? '#252525' : 'lightgrey' : undefined
          }}
          className='hoverHighlightWithIcon'
          onClick={() => navigate('/analytics/explorer/queries')}
        >
          <StyledSvg
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ExploreIconHeader
              style={{ margin: 'auto' }}
            />
          </StyledSvg>
          <div
            style={{
              marginLeft: '0.5em'
            }}
          >
            SQL Explorer
          </div>
        </div>
        <div
          style={{
            margin: 'auto 1em auto 1em',
            padding: '0.5rem 1rem 0.5rem 1rem',
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            borderRadius: '0.5rem',
            background: exploreType === 'VISUAL DESIGNER' ? theme.palette.mode === 'dark' ? '#252525' : 'lightgrey' : undefined
          }}
          className='hoverHighlightWithIcon'
          onClick={() => navigate('/analytics/designer/visuals')}
        >
          <StyledSvg
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <VisualDesignerIcon
              style={{ margin: 'auto' }}
            />
          </StyledSvg>
          <div
            style={{
              marginLeft: '0.5em'
            }}
          >
            Visual Designer
          </div>
        </div>
        {
          hasHelikaAdminPrivileges &&
          canSeeExplore(USER_DATA) &&
          process.env.REACT_APP_DEV_MODE !== 'PROD' &&
          <div
            style={{
              margin: 'auto 1em auto 1em',
              padding: '0.5rem 1rem 0.5rem 1rem',
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              borderRadius: '0.5rem',
              background: exploreType === 'DASHBOARD DESIGNER' ? theme.palette.mode === 'dark' ? '#252525' : 'lightgrey' : undefined
            }}
            className='hoverHighlightWithIcon'
            onClick={() => navigate('/analytics/designer/dashboards')}
          >
            <StyledSvg
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DashboardDesignerIcon
                style={{ margin: 'auto' }}
              />
            </StyledSvg>
            <div
              style={{
                marginLeft: '0.5em'
              }}
            >
              Dashboard Builder
            </div>
          </div>
        }
      </div>
    </div>
  );
};
