import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Typography, useTheme } from '@mui/material';
import { newColor } from '../../consts/colors';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { deleteEmailMarketingAction } from 'src/utils/api/queries';
import { toast } from 'react-toastify';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  title?: string,
  action: any,
  setActions: any
}


export default function DeleteEmailMarketingActionWarningModal(props: SimpleDialogProps) {
  const { open, setOpen, title, action, setActions } = props;
  const theme = useTheme();
  const { deleteWithAccessToken } = useContext(Auth0Context)

  const handleClose = () => {
    setOpen(false);
  };

  async function deleteEmailMarketingActionApi() {
    if (!action?.id) {
      toast.error('Error finding action')
      return
    }
    const data = await deleteEmailMarketingAction(deleteWithAccessToken, action?.id)
    if (data?.results) {
      setActions((prevState: any) => {
        if (!prevState) return []
        return prevState?.filter((campaignItem: any) => campaignItem.id !== data.results.id)
      })
      setOpen(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }} onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className='circleBackground' sx={{ justifyContent: 'center', minWidth: '20em', margin: '1em' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`}>{title}</DialogTitle>
      </Box>
      <Box
        display='flex'
        flexDirection={'column'}
        sx={{ paddingLeft: 2, paddingRight: 2, marginBottom: 2, width: '100%', justifyContent: 'center' }}
      >
        <Typography
          sx={{ color: 'red', textAlign: 'center', marginBottom: '1em' }}
        >
          WARNING! <br /> You are about to delete an Email Marketing Action. This is not a reversible action and you may lose data.
        </Typography>
        <Button
          className='helikaButtonClass'
          onClick={() => deleteEmailMarketingActionApi()}
          sx={{ width: '100%' }}
        >
          DELETE
        </Button>
      </Box>
    </Dialog>
  );
}
