import React from 'react'
import { Box, styled as muiStyled, Pagination, Button } from '@mui/material'
import loadingSVG from '../../assets/loading.svg'

// Styled select component with custom styles
const StyledSelect = muiStyled('select')(({ theme }) => ({
  width: '60px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? '#2E2E2E' : '#e2e2e2',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#373737' : '#e9e9e9'}`,
  color: theme.palette.text.primary,
  padding: '0 5px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f',
  },
}))

// Interface for project details
interface Project {
  organization_id: number
  priority: number
  active: boolean
  name: string
  description: string
  created_at: string
  updated_at: string
  id: number
}

// Props interface for ProjectList component
interface ProjectListProps {
  projects: Project[] // List of projects to display
  onProjectClick: (projectId: number) => void // Function to call when a project is clicked
  page: number // Current page number
  pageSize: number // Number of projects per page
  count: number // Total number of projects
  handlePaginationChange: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => void // Function to handle page change
  handlePageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void // Function to handle change in page size
  loading: boolean // Loading state to show loading spinner
}

// ProjectList component to display list of projects with pagination
const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  onProjectClick,
  count,
  page,
  pageSize,
  handlePaginationChange,
  handlePageSizeChange,
  loading,
}) => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
    {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          width="100px"
          className="animate-spin"
          src={loadingSVG}
          alt="Loading..."
        />
      </Box>
    ) : (
      projects.map((project) => (
        <Button
          key={project.id}
          className="helikaButtonClass"
          variant="contained"
          size="medium"
          sx={{
            height: '2.5em',
            marginTop: 'auto',
            width: '40vw',
            marginLeft: 2,
          }}
          onClick={() => onProjectClick(project.id)}
        >
          {project.name}
        </Button>
      ))
    )}

    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
      <Pagination
        count={
          isNaN(count / Number(pageSize))
            ? 1
            : Math.ceil(count / Number(pageSize))
        }
        page={page}
        onChange={handlePaginationChange}
      />

      <StyledSelect value={pageSize} onChange={handlePageSizeChange}>
        {[5, 10, 15, 20].map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </StyledSelect>
    </Box>
  </Box>
)

export default ProjectList
