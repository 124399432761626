import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu } from 'helika-ui-sdk'
import _ from 'lodash'
import { color_base } from 'helika-ui-sdk/dist/src/values/Colors';
import { useTheme } from '@mui/material';

export const DefaultActionMenuRenderer = (props: any) => {

  const theme = useTheme()

  let defaultMenuItem = [{}]

  let menuItems = !_.isEmpty(props.colDef?.slotProps?.menuItems)
    ? props.colDef?.slotProps?.menuItems
    : defaultMenuItem

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '30em',
        margin: 'auto',
        cursor: 'pointer',
        height: '36px !important',
        width: '36px !important',
        justifyContent: 'center',
        border: 'none',
      }}
    >
      <Menu
        setOpen={() => { }}
        title={''}
        sxProps={{}}
        disabled={false}
        darkMode={true}
        menuItems={menuItems}
        sxPropsMenu={{
          border: 'none',
        }}
        sxPropsMenuButton={{
          background: 'transparent',
          border: 'none',
        }}
        buttonComponent={
          <MoreVertIcon
            style={{
              margin: 0,
              border: 'none',
              height: '24px',
              width: '24px',
              color: theme.palette.mode === 'dark' ? color_base.white : color_base.black
            }}
          />
        }
      />
    </div>
  )
}
