import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Box,
  Popover,
  styled as muiStyled,
  Typography,
  Stack,
} from '@mui/material'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import _ from 'lodash-es'

import { Auth0Context } from '../../contexts/Auth0Context'
import TabManagement from '../../components/OrganizationManagement/TabManagement'
import { useUpdateGlobalTabsOrder } from '../../components/organisms/v2/tabs/useUpdateTabOrder'
import ActionIcon from 'src/assets/action.svg'
import { ReactComponent as AddTabIcon } from 'src/assets/add_tab.svg'
import {
  getModules as getModulesAPI,
  deleteGlobalTab as deleteGlobalTabAPI,
  getParentTabsApi,
} from '../../utils/api/queries'
import ThemedIcon from '../../components/organisms/v2/themedIcon'
import { newColor } from '../../consts/colors'

const ActionButton = muiStyled('span')(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  outline: 0,
  background: 'none',
  border: 0,
  cursor: 'pointer',
}))

const StyledTypography = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  fontSize: '12px',
}))

export function ToggleActionButton({
  handleClose,
  handleCreateParentTab,
  handleCreateTab,
}: {
  handleClose?: VoidFunction
  handleCreateParentTab?: VoidFunction
  handleCreateTab?: VoidFunction
}) {
  return (
    <Stack alignItems={'flex-start'}>
      <Button
        onClick={() => {
          if (handleCreateParentTab) handleCreateParentTab()
          if (handleClose) handleClose()
        }}
        sx={{ color: 'text.primary' }}
      >
        <ThemedIcon>
          <AddTabIcon
            style={{ width: '12px', marginRight: '4px' }}
            fontSize={'12px'}
          />
        </ThemedIcon>

        <StyledTypography>CREATE A PARENT TAB</StyledTypography>
      </Button>
      <Button
        onClick={() => {
          if (handleCreateTab) handleCreateTab()
          if (handleClose) handleClose()
        }}
        sx={{ color: 'text.primary' }}
      >
        <ThemedIcon>
          <AddTabIcon
            style={{ width: '12px', marginRight: '4px' }}
            fontSize={'12px'}
          />
        </ThemedIcon>
        <StyledTypography>CREATE A NEW TAB</StyledTypography>
      </Button>
    </Stack>
  )
}

interface ActionButtonsProps {
  children?: JSX.Element
}

export function TabManagementActionButtons({ children }: ActionButtonsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box sx={{ position: 'relative', marginLeft: 'auto' }}>
      <ActionButton aria-describedby={id} onClick={handleClick}>
        <img src={ActionIcon} alt="edit" />
      </ActionButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </Box>
  )
}

export default function GlobalTabsManagement({
  orgs,
  distinctTabs,
  onUpdated,
}: {
  orgs: any[]
  onUpdated: () => void
  distinctTabs: any[]
}) {
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const { getWithAccessToken, getTokenIfNecessary, deleteWithAccessToken } =
    useContext(Auth0Context)
  const { trigger } = useUpdateGlobalTabsOrder()

  const [tabsList, setTabsList] = useState<any>(null)
  const [parentTabs, setParentTabs] = useState<any>(null)

  useEffect(() => { }, [getWithAccessToken])

  useEffect(() => {
    const getParentTabs = async () => {
      const data = await getParentTabsApi(getWithAccessToken)
      setParentTabs(data.results)
      return data
    }

    const getModules = async () => {
      return await getModulesAPI(getTokenIfNecessary)
    }

    Promise.all([getModules(), getParentTabs()]).then((results) => {
      const modules = results[0]
      const tabs = distinctTabs
      const parentTabs = results[1].results
      const tabsList = modules.reduce((acc: any, item: any) => {
        const filteredParentTabs = parentTabs.filter(
          ({ module_id }: { module_id: any }) => module_id === item.id,
        )
        acc[item.id] = filteredParentTabs.reduce((fpacc: any, fpitem: any) => {
          fpacc[fpitem.id] = {
            children: [
              ...tabs
                .filter((x: any) => x.parent_tab_id === fpitem.id)
                .map((y: any, index: number) => ({
                  icon: y.icon,
                  id: y.id,
                  name: y.name,
                  priority: y.priority || index++,
                })),
            ],
            icon: null,
            id: fpitem.id,
            module_id: item.id,
            name: fpitem.name,
            priority: fpitem.priority,
          }
          return fpacc
        }, {})
        return acc
      }, {})

      setTabsList(tabsList)
    })
  }, [getTokenIfNecessary, getWithAccessToken, distinctTabs])

  const handleDeleteGlobalTab = async (tabId: number) => {
    try {
      await deleteGlobalTabAPI(deleteWithAccessToken, tabId)
      toast.success('Tab deleted successfully')
      onUpdated()
      if (!_.isEmpty(sdk)) {
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'organization_configuration_change',
            event: {
              event_sub_type: 'portal_configuration_change',
              configuration_type: 'tab_layout',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
              details: {
                tab_identifier: tabId,
                change: 'tab_removed',
              },
            },
          },
        ])
      }
    } catch (err: any) {
      toast.error(err.message)
    }
  }

  return (
    <TabManagement
      tabsData={tabsList || {}}
      enableEditTab
      enableDeleteTab
      orgs={orgs}
      parentTabs={parentTabs}
      onDeleteTab={handleDeleteGlobalTab}
      onUpdated={onUpdated}
      onSave={({ tabs }: any) => {
        trigger({ tabs })
      }}
    />
  )
}
