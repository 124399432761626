import { Box, Button, Icon, TextField, styled } from '@mui/material';
import { ChatController, TextActionRequest } from 'src/utils/chat/src';
import React, { useCallback, useState } from 'react';
import { newColor } from 'src/consts/colors';

const Textarea: any = styled(TextField)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? '#C7D0DD' : '#1C2025'};
  background: ${theme.palette.mode === 'dark' ? '#1C2025' : newColor.white};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#6B7A90' : 'black'};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? '#1C2025' : '#F3F6F9'};

  &:focus {
    border-color: #9747FF;
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? '#F3F6F9' : '#F3F6F9'};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

export function MuiTextInput({
  chatController,
  actionRequest,
}: {
  chatController: ChatController;
  actionRequest: TextActionRequest;
}): React.ReactElement {
  const chatCtl = chatController;
  const [value, setValue] = useState(actionRequest.defaultValue);

  const setResponse = useCallback((): void => {
    if (value) {
      const res: any = { type: 'text', content: [{ content: value, type: 'text' }] };
      chatCtl?.setActionResponse(actionRequest, res);
      setValue('');
    }
  }, [actionRequest, chatCtl, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (e.nativeEvent.isComposing) {
        return;
      }

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setResponse();
      }
    },
    [setResponse],
  );

  const sendButtonText = actionRequest.sendButtonText
    ? actionRequest.sendButtonText
    : 'Send';

  return (
    <Box
      sx={{
        flex: '1 1 auto',
        display: 'flex',
        '& > *': {
          flex: '1 1 auto',
          minWidth: 0,
        },
        '& > * + *': {
          ml: 1,
        },
        '& :last-child': {
          flex: '0 1 auto',
        },
      }}
    >
      <Textarea
        id='aiChatTextArea'
        placeholder={actionRequest.placeholder}
        value={value}
        onChange={(e: any): void => setValue(e.target.value)}
        inputProps={{ onKeyDown: handleKeyDown }}
        multiline
        autoFocus
        maxRows={4}
        sx={{
          borderRadius: '1em',
          "& fieldset": { border: 'none' },
        }}
      />
      <Button
        type="button"
        onClick={setResponse}
        variant="contained"
        color="primary"
        startIcon={<Icon>send</Icon>}
        sx={{
          borderRadius: '100vmax',
          marginLeft: '1em',
          padding: '0.5em 2em 0.5em 2em',
          margin: 'auto 0em auto 0.5em',
          background: 'linear-gradient(90deg, #8BE1C9 17.2%, #9747FF 87.63%) !important',
          cursor: !value ? 'not-allowed' : 'pointer',
          opacity: !value ? 0.6 : 1
        }}
      >
        {sendButtonText}
      </Button>
    </Box>
  );
}