//imports
import { useState } from "react";
import * as ReactTooltip from 'react-tooltip'

//contexts

//services

//components

//assets
import { useTheme } from "@mui/material";
import { StyledSvg } from "../organisms/v2/tabs/styled";
import { ReactComponent as ExpandIcon } from 'src/assets/drop_expand.svg'
import { ReactComponent as ExpandLessIcon } from 'src/assets/drop_less_expand.svg'
import { ReactComponent as TableIcon } from 'src/assets/table.svg'
import { ReactComponent as SchemaIcon } from 'src/assets/schema_icon.svg'
import { ReactComponent as Keep } from 'src/assets/keep.svg'
import { ReactComponent as KeepOff } from 'src/assets/keep_off.svg'
import SearchBar from "../SearchBar";
import LoadingComponent from "../LoadingComponent";
import _ from "lodash";
import { newColor } from "src/consts/colors";
//---------------------------------------------------------------------------------

export default function Sidebar({
  title,
  expanded,
  setExpanded,
  icon,
  searchTerm,
  setSearchTerm,
  isLoading,
  insertAtCursor,
  clickSchemaHandler = () => { },
  clickTableHandler,
  schemas,
  schemaTabState,
  tableTabState,
  loadingText,
  hide = false,
}: {
  title: any,
  expanded: any,
  setExpanded: any,
  icon: any,
  searchTerm: any,
  setSearchTerm: any,
  isLoading: any,
  insertAtCursor: any,
  clickSchemaHandler: any,
  clickTableHandler: any,
  schemas: any,
  schemaTabState: any,
  tableTabState: any,
  loadingText?: any,
  hide?: boolean,
}) {

  const theme: any = useTheme()
  const [expandIsPinned, setExpandIsPinned] = useState<boolean>(false);
  const [schemasShow, setSchemasShow] = useState<boolean>(true);

  function populateSchemaList(schemas: any) {
    if (_.isEmpty(schemas) || _.isNull(schemas)) return null
    return (
      schemas
        .filter((schema: any) => {
          return (
            schema.name.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()) ||
            _.some(schema.tables, (table) => table.table_name.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()))
          )
        })
        .map((schema: any) => {
          return (
            <div
              key={schema.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '0.5em',
                width: 'fit-content',
                maxWidth: expanded ? 'fit-content' : '100%',
                paddingLeft: '1em',
                cursor: 'pointer'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                onClick={(e) => clickSchemaHandler(schema, e)}
              >
                {
                  (schemaTabState[`${schema.name}`] === true)
                    ?
                    <StyledSvg
                      sx={{
                        '& svg, & path': {
                          fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                      }}
                    >
                      <ExpandLessIcon />
                    </StyledSvg>
                    :
                    <StyledSvg
                      sx={{
                        '& svg, & path': {
                          fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                      }}
                    >
                      <ExpandIcon />
                    </StyledSvg>
                }
                <StyledSvg
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <SchemaIcon
                    style={{ margin: 'auto' }}
                  />
                </StyledSvg>
                <div
                  style={{ marginLeft: '0.6em', textAlign: 'left' }}
                  className='hoverHighlight truncate-ellipsis'
                >
                  {schema.name}
                </div>
              </div>
              {
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: expanded ? 'fit-content' : '100%',
                  }}
                  className={`explorer-list-box-schemas ${(schemaTabState[`${schema.name}`] === true) ? '' : 'closed'}`}
                >
                  {
                    schema.tables
                      .filter((table: any) => { return table.table_name.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()) })
                      .map((table: any) => {
                        return (
                          <div
                            key={`${schema.name}_${table.table_name}`}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: 'fit-content',
                              paddingLeft: '1.5em',
                              cursor: 'pointer',
                              maxWidth: expanded ? 'fit-content' : '100%',
                            }}
                          >
                            <div
                              onClick={(e) => clickTableHandler(table, e)}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '0.5em',
                                width: 'fit-content',
                                maxWidth: expanded ? 'fit-content' : '100%',
                                cursor: 'pointer',
                              }}
                              className='hoverHighlight'
                            >
                              {
                                (tableTabState && tableTabState[`${table.table_name}`] === true)
                                  ?
                                  <StyledSvg
                                    sx={{
                                      '& svg, & path': {
                                        fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                      },
                                    }}
                                  >
                                    <ExpandLessIcon />
                                  </StyledSvg>
                                  :
                                  <StyledSvg
                                    sx={{
                                      '& svg, & path': {
                                        fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                      },
                                    }}
                                  >
                                    <ExpandIcon />
                                  </StyledSvg>
                              }
                              <StyledSvg
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <TableIcon
                                  style={{ margin: 'auto' }}
                                />
                              </StyledSvg>
                              <div
                                style={{ marginLeft: '0.6em' }}
                                className='truncate-ellipsis'
                              >
                                {table.table_name}
                              </div>

                            </div>
                            {
                              table.columns.map((column: any) => {
                                return (
                                  <div
                                    key={`${schema.name}_${table.table_name}_${column.column_name}`}
                                    onDoubleClick={() => insertAtCursor(`${column.column_name} `)}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      marginTop: (tableTabState && tableTabState[`${table.table_name}`] === true) ? '0.5em' : 0,
                                      width: 'fit-content',
                                      maxWidth: '100%',
                                      paddingLeft: '1.5em',
                                      cursor: 'pointer',
                                    }}
                                    className={`explorer-list-box-tables ${(tableTabState && tableTabState[`${table.table_name}`] === true) ? '' : 'closed'}`}
                                  >
                                    <div
                                      style={{ marginLeft: '0.6em', paddingLeft: '1.3em' }}
                                      className='truncate-ellipsis'
                                    >
                                      {column.column_name}<sub style={{ fontSize: '0.8em', paddingLeft: '0.3em', color: 'gray' }}>{column.data_type}</sub>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })
                  }
                </div>
              }
            </div>
          );
        })
    )
  }

  let canExpandIcon =
    <div style={{}}>
      <StyledSvg
        sx={{
          '& svg, & path': {
            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
          },
        }}
      >
        <ExpandIcon />
      </StyledSvg>
    </div>

  let cantExpandIcon =
    <div style={{}}>
      <StyledSvg
        sx={{
          '& svg, & path': {
            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
          },
        }}
      >
        <ExpandLessIcon />
      </StyledSvg>
    </div>

  function loadingComp(text: string) {
    return <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
        padding: '1em'
      }}
    >
      <div style={{ textAlign: 'center', paddingBottom: '1em' }}>{text}</div>
      <LoadingComponent />
    </div>
  }

  return (
    <div
      id='exploreSidebar'
      style={{
        minHeight: '100%',
        height: '100%',
        minWidth: hide ? '0em' : '15.5em',
        width: hide ? '0em' : expanded ? '30em' : '15.5em',
        maxWidth: '50vw',
        paddingTop: '0.3em',
        overflow: 'auto',
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
      }}
      onMouseLeave={() => {
        if (!expandIsPinned) {
          setExpanded(false)
        }
      }}
    >
      <div
        style={{
          height: 'calc(100% - 0.5em)',
          padding: '0.7em 0em 0.5em 0.5em',
          flex: 1
        }}
      >
        <div
          style={{
            background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
            borderRadius: '0.5em',
            border: 'solid',
            borderWidth: '0.5px',
            borderColor: newColor.darkGunmetal,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            scrollbarGutter: 'stable both-sides'
          }}
        >
          <div
            style={{
              fontSize: '1.2em',
              display: 'flex',
              flexDirection: 'row',
              padding: '0.5em',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <img
                style={{
                  padding: '0 0.4em 0.1em 0.5em'
                }}
                src={icon} alt='explore'
              />
              <div>
                {title}
              </div>
            </div>
            <div>
              {
                expandIsPinned
                  ?
                  <StyledSvg
                    sx={{
                      '& svg, & path': {
                        fill: 'red',
                      },
                      cursor: 'pointer'
                    }}
                    onClick={() => setExpandIsPinned(prevState => !prevState)}
                  >
                    <Keep />
                  </StyledSvg>
                  :
                  <StyledSvg
                    sx={{
                      '& svg, & path': {
                        fill: newColor.darkGunmetal,
                      },
                      cursor: 'pointer'
                    }}
                    onClick={() => setExpandIsPinned(prevState => !prevState)}
                  >
                    <KeepOff />
                  </StyledSvg>
              }
            </div>
          </div>
          <div
            style={{
              padding: '0em 0em 0.5em 0em'
            }}
          >
            <div
              style={{
                height: '1px',
                backgroundColor: newColor.darkGunmetal,
                opacity: '50%',
                width: '100%',
              }}
            />
          </div>
          <div
            style={{
              padding: '0 0.5em 0 0.5em'
            }}
          >
            <SearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder='SEARCH'
              onEnter={(value: any) => { setSearchTerm(value) }}
              borderColor={theme.palette.mode === 'dark' ? 'grey' : 'black'}
            />
          </div>
          {

            isLoading
              ? loadingComp(loadingText)
              :
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
              >
                <div
                  style={{
                    maxHeight: 'calc(100%)',
                    padding: '1em',
                    overflowX: expanded ? 'auto' : 'clip',
                    overflowY: 'auto',
                    height: '100%',
                    scrollbarGutter: 'stable both-sides'
                  }}
                  onMouseOver={() => {
                    if (!expandIsPinned) {
                      setExpanded(true)
                    }
                  }}
                >
                  {  /* schemas */}
                  {
                    !_.isUndefined(schemas) &&
                    <div
                      style={{
                        width: 'fit-content',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        fontWeight: 900
                      }}
                      onClick={() => setSchemasShow(prevState => !prevState)}
                      className='hoverHighlight'
                    >
                      {
                        schemasShow
                          ? cantExpandIcon
                          : canExpandIcon
                      }
                      Schemas
                    </div>
                  }
                  <ReactTooltip.Tooltip id={`data_column_type`} />
                  {
                    !_.isUndefined(schemas) &&
                    <div className={`explorer-list-box ${schemasShow ? '' : 'closed'}`}>
                      {
                        populateSchemaList(schemas)
                      }
                    </div>
                  }
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}