//imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as ReactTooltip from 'react-tooltip';
import React from "react";
import { Box, Checkbox, useTheme } from '@mui/material';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { newColor } from 'src/consts/colors';
import { ReactComponent as SortByIcon } from 'src/assets/sort_by_icon.svg'

//contexts

//services

//components

//assets
//---------------------------------------------------------------------------------

function EmailCampaignsSortMenu({
    sort,
    setSort,
    order,
    setOrder,
}: {
    sort: any,
    setSort: any,
    order: any,
    setOrder: any,
}) {

    const theme: any = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let options = [
        {
            option: 'Date Created',
            orders: ['Newest First', 'Oldest First']
        },
        {
            option: 'Date Modified',
            orders: ['Newest First', 'Oldest First']
        },
        {
            option: 'Title',
            orders: ['A - Z', 'Z - A']
        },
    ]

    return (
        <div
            className="flex flex-col"
        >
            <ReactTooltip.Tooltip />
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: newColor.gray,
                    padding: '1em 1em 1em 1em',
                    border: 'solid',
                    borderColor: newColor.dimGray,
                    borderRadius: '0.5em !important',
                    textTransform: 'none',
                    fontSize: '0.8em'
                }}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: 'white',
                        },
                        width: '2rem'
                    }}
                >
                    <SortByIcon
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </StyledSvg>
                Sort By
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock={true}
                sx={{
                    "& .MuiList-root": {
                        backgroundColor: theme.palette.background.default, padding: 0
                    },
                    "& .MuiMenu-paper": {
                        backgroundColor: theme.palette.background.default,
                        border: theme.palette.mode === 'dark' ? 'inset' : '',
                        borderColor: newColor.dimGray, marginTop: '1em',
                        borderRadius: '0.5em'
                    },
                    "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                        borderBottom: 'none', borderBottomWidth: '0.5px', borderColor: '#676767'
                    },
                    "& .MuiButtonBase-root.MuiMenuItem-root": {
                        padding: '0.5em 1em 0.5em 1em'
                    },
                }}
            >
                {
                    options.map(option => {
                        return (
                            <MenuItem
                                key={option.option}
                                onClick={() => {
                                    setSort(option.option)
                                    setOrder(option.orders[0])
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Checkbox
                                    onChange={() => {
                                        setSort(option.option)
                                        setOrder(option.orders[0])
                                    }}
                                    checked={option.option === sort}
                                />
                                <Box>{option.option}</Box>
                            </MenuItem>
                        )
                    })
                }
                <Box
                    sx={{
                        padding: '0.5em',
                        color: newColor.gray
                    }}
                >
                    Order
                </Box>

                {
                    options.find((item: any) => item.option === sort)?.orders?.map((orderItem: any) => {
                        return (
                            <MenuItem
                                key={orderItem}
                                onClick={() => {
                                    setOrder(orderItem)
                                    handleClose()
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Checkbox
                                    onChange={() => {
                                        setOrder(orderItem)
                                        handleClose()
                                    }}
                                    checked={order === orderItem}
                                />
                                <Box>{orderItem}</Box>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    )
}

export default EmailCampaignsSortMenu