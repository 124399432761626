import DialogTitle from '@mui/material/DialogTitle';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Dialog, Tab, Tabs } from '@mui/material';
import { baseOptions } from '../../utils/agGrid';
import { useSelector } from 'react-redux';
import { newColor } from '../../consts/colors'

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  columnDefs: any[],
  rowData: any[] | undefined,
  onClose: () => void;
  title?: string,
  showConfig?: any,
  config?: any,
  sheetChangeOverride?: any,
}

export default function ExcelPopupForDownload(props: SimpleDialogProps) {

  const { open, setOpen, columnDefs, rowData, showConfig, config, sheetChangeOverride, onClose, title } = props;
  const SHEET_MAPPINGS = useSelector((state: any) => state.gameMgmtData.SHEET_MAPPINGS);
  const theme = useTheme();

  const [selectedSheet, setSelectedSheet] = useState<string | undefined>((SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) ? SHEET_MAPPINGS[0].sheet_name : '');

  const handleClose = () => {
    if (SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) {
      setSelectedSheet(SHEET_MAPPINGS[0].sheet_name);
      if (sheetChangeOverride) {
        sheetChangeOverride(SHEET_MAPPINGS[0].sheet_name)
      }
    }
    setOpen(false);
    if (onClose) onClose();
  };

  // Optional - for accessing Grid's API
  const gridRefApplied: any = useRef<AgGridReact>(null); // Optional - for accessing Grid's API

  // Each Column Definition results in one Column.
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event: any) => {
    //console.log('cellClicked', event);
  }, []);

  const selectSheet = (event: React.SyntheticEvent, sheet: string) => {
    setSelectedSheet(sheet);
    let sheet_mapping = SHEET_MAPPINGS?.find((mapping: any) => mapping.sheet_name === sheet);
    if (sheetChangeOverride) {
      sheetChangeOverride(sheet)
    } else {
      if (!config) return;
      showConfig(config, sheet, sheet_mapping?.column_aliases?.map((alias: any) => alias.sheet));
    }
  };

  useEffect(() => {
    if (SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) {
      setSelectedSheet(SHEET_MAPPINGS[0].sheet_name);
    }
  }, [SHEET_MAPPINGS, config]);

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      fullScreen={true}
      sx={{ padding: 0, height: '90vh', width: '90vw', margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          overflow: 'auto',

        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className={theme.palette.mode === 'dark' ? 'circleBackground' : 'circleBackgroundLight'} sx={{ padding: '0.5em', margin: '1em 1em 0 1em', justifyContent: 'space-between' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} leftTextNoWrap`}>{title ? title : 'Uploaded File'}</DialogTitle>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginRight={'1em'}
      >
        <Tabs
          value={selectedSheet}
          onChange={selectSheet}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="sheets"
          sx={{
            fontWeight: 900,
            padding: '0 1em 0 1em',
            borderRadius: '0.5em',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark' ? newColor.night : 'white',
            gap: '0.5em '
          }}
        >
          {
            SHEET_MAPPINGS?.map((sheetMapping: any) => {
              return (
                <Tab
                  sx={{
                    fontWeight: 900,
                    marginRight: 1,
                    borderRadius: '0.5em',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    background:
                      theme.palette.mode === 'dark' ? newColor.night : 'white',
                    padding: '0 1em 0 1em',
                  }}
                  key={sheetMapping.sheet_name} label={sheetMapping.sheet_name} value={sheetMapping.sheet_name} />
              );
            })
          }
        </Tabs>
      </Box>
      <Box
        sx={{ height: '100%', padding: '0.5em 1em 0.5em 1em' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', paddingBottom: '0.5em', overflow: 'hidden' }}>

          <div style={{ width: '100%', height: '100%' }} className={`smallExcel ${theme.palette.mode === 'light' ? 'ag-theme-balham' : 'ag-theme-balham-dark'}`}>
            <AgGridReact
              className='customAgGridHover'
              ref={gridRefApplied} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              context={{
                config: config,
                selectedSheet: selectedSheet,
              }}
              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              gridOptions={baseOptions}
            />
          </div>

        </div>
      </Box>
    </Dialog>
  );
}
