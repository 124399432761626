import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// MUI
import { Box, useTheme } from '@mui/material'
// project-import
import NotificationsDropdown from 'src/components/NotificationsDropdown'
import NotificationSVG from 'src/assets/Notification'

const Notification: React.FC = () => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState<boolean>(false)
  //notifications
  const NOTIFICATIONS_LIST = useSelector(
    (state: any) => state.notifications.NOTIFICATIONS_LIST,
  )

  return (
    <NotificationsDropdown options={NOTIFICATIONS_LIST}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          width: '100%',
          justifyContent: 'center',
          cursor: 'pointer',
          marginTop: 'auto',
          marginBottom: 'auto',
          color: 'white',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {NOTIFICATIONS_LIST?.filter(
          (notification: any) => notification?.status !== 'read',
        )?.length > 0 && (
          <p
            style={{
              marginRight: '0.5em',
              color: theme.palette.text.primary,
            }}
          >
            {
              NOTIFICATIONS_LIST?.filter(
                (notification: any) => notification?.status !== 'read',
              ).length
            }
          </p>
        )}
        <NotificationSVG
          on={
            NOTIFICATIONS_LIST?.filter(
              (notification: any) => notification?.status !== 'read',
            )?.length > 0
          }
          hover={isHovered}
        />
      </Box>
    </NotificationsDropdown>
  )
}

export default Notification
