import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useRef, useState } from 'react';
import React from 'react';
import * as XLSX from 'xlsx';
import {
  Input,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Paper, Typography } from '@mui/material';
import uploadIcon from '../../assets/uploadIcon.svg'
import { Auth0Context } from '../../contexts/Auth0Context';
import _ from 'lodash';
import { newColor } from '../../consts/colors'
import ExcelPopupForUploadMinimal from './ExcelPopupForUploadMinimal';
import { uploadEngageCsv } from 'src/utils/api/queries';
import { toast } from 'react-toastify';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  columnDefs: any[],
  rowData: any[] | undefined,
  onClose: (value: string) => void;
  showConfig: any,
}

export default function EngageUploadCSVModal(props: SimpleDialogProps) {

  const { open, setOpen, showConfig } = props;
  const theme = useTheme();

  const { getTokenIfNecessary } = useContext(Auth0Context);

  const [showExcel, setShowExcel] = useState<boolean>(false);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<string>('188');//todo: remove hardcode
  const [hookId, setHookId] = useState<string>('400');//todo: remove hardcode
  const [projectId, setProjectId] = useState<string>('492');//todo: remove hardcode


  async function confirmUpload(file: any) {
    setUploadInProgress(true);
    uploadEngageCsv(getTokenIfNecessary, {
      file: file,
      campaign_id: campaignId,
      hook_id: hookId,
      project_id: projectId,
    }).then((wasPostedSuccessfully: any) => {
      toast.success('Config was uploaded successfully! Page will refresh.');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }).catch(e => {
      toast.error(`Config could not be uploaded successfully. ${e?.message}`)
    });
    setUploadInProgress(false);
  }

  const EXTENSIONS = ['xlsx', 'xls', 'csv'];

  const getExention = (file: any) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }
  const importExcel = (e: any, sheetToImport?: string) => {

    let file = e;
    if ('target' in e) {
      file = e.target.files[0];
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      //parse data

      const bstr = event?.target?.result;
      const workBook = XLSX.read(bstr, { type: "binary" })

      let workSheetName = workBook.SheetNames[0];
      if (sheetToImport) {
        workSheetName = sheetToImport;
      }

      setUploadedFile(file);

      //get first sheet
      if (!(workSheetName in workBook.Sheets)) {
        console.log('Sheet not in file');
        return;
      }
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData: any[] = XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: false })
      const headers: any[] = fileData[0];

      let arrayLength = Array.from(Array(headers.length).keys());

      let tempheaders = arrayLength.map((head: any, index: number) => headers[index]?.length > 0 ? headers[index] : `empty_${index}`).map((head, index) => {
        return {
          headerName: headers[index]?.length > 0 ? headers[index] : ``,
          field: head,
          filter: true,
          flex: 1
        }
      })
      setColumnDefs(tempheaders);

      //removing header
      fileData.splice(0, 1)

      let tempdata = fileData.map((row: any) => {
        let newRowData: any = {};
        row.forEach((cell: any, index: number) => {
          if (tempheaders[index]?.field) {
            newRowData[tempheaders[index]?.field.toString()] = cell;
          }
        })
        return newRowData;
      });
      setRowData(tempdata);

      //setShowExcel(true);
    }
    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      else {
        alert("Invalid file input, Select Excel, CSV file")
      }
    }
  }

  const hiddenFileInputDisplay = useRef<HTMLInputElement>(null);
  const handleClickDisplay = () => {
    if (hiddenFileInputDisplay.current !== null) {
      hiddenFileInputDisplay.current.click();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  let disabled = (uploadInProgress || _.isUndefined(uploadedFile));

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }} onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box
        style={{
          paddingBottom: 0,
          marginBottom: 0,
          minWidth: '20em'
        }}
        display='flex' flexDirection={'row'} className={theme.palette.mode === 'dark' ? 'circleBackground' : 'circleBackgroundLight'} sx={{ justifyContent: 'center' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`}><img src={uploadIcon} alt='' /> UPLOAD CSV</DialogTitle>
      </Box>
      <ExcelPopupForUploadMinimal
        open={showExcel}
        setOpen={setShowExcel}
        onClose={() => { setOpen(false) }}
        columnDefs={columnDefs}
        rowData={rowData}
        title={uploadedFile?.name}
        sheetChangeOverride={(sheetName: any) => {
          if (!uploadedFile) return;
          importExcel(uploadedFile, sheetName);
        }}
        showConfig={showConfig}
      />
      <input ref={hiddenFileInputDisplay} className='hiddenInput' type="file" onChange={importExcel} />
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          width: '100%'
        }}
        id='fieldsetRounded'
      >
        <Box
          display='flex'
          flexDirection={'row'}
          sx={{ width: '100%', justifyContent: 'center', padding: '1em' }}
        >
          <Button className='helikaButtonClass' sx={{ width: 'auto', margin: 'auto', paddingLeft: 5, paddingRight: 5 }} variant='contained' size='small' onClick={handleClickDisplay}>
            Select File
          </Button>
        </Box>
        {
          uploadedFile &&
          <Paper
            sx={{ marginBottom: 2, textAlign: 'center', textOverflow: "ellipsis", overflow: 'hidden', paddingRight: '1em', paddingLeft: '1em', marginTop: 1, padding: 1 }}
          >
            <Typography sx={{ height: 'fit-content' }} noWrap>
              Uploaded File: {uploadedFile?.name}
            </Typography>
            <Box
              display='flex'
              flexDirection={'row'}
              sx={{ marginTop: 1, width: '100%', justifyContent: 'center' }}
            >
              <Button className='helikaButtonClass' sx={{ margin: 'auto', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small' onClick={() => setShowExcel(true)}>
                Preview
              </Button>
              <Button className='helikaButtonClass' sx={{ margin: 'auto', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small' onClick={() => setUploadedFile(undefined)}>
                Remove
              </Button>
            </Box>
          </Paper>
        }
      </Box>
      <Box
        style={{
          padding: '0 1em 1em 1em',
          justifyContent: 'space-between',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <label
          style={{
            marginRight: '1em'
          }}
        >
          Project ID:
        </label>
        <Input
          placeholder="e.g. In-Game Analytics"
          value={projectId}
          type="text"
          required
          onChange={(e: any) => setProjectId(e.currentTarget.value)}
        />
      </Box>
      <Box
        style={{
          padding: '0 1em 1em 1em',
          justifyContent: 'space-between',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <label
          style={{
            marginRight: '1em'
          }}
        >
          Campaign ID:
        </label>
        <Input
          placeholder="e.g. In-Game Analytics"
          value={campaignId}
          type="text"
          required
          onChange={(e: any) => setCampaignId(e.currentTarget.value)}
        />
      </Box>
      <Box
        style={{
          padding: '0 1em 1em 1em',
          justifyContent: 'space-between',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <label
          style={{
            marginRight: '1em'
          }}
        >
          Hook ID:
        </label>
        <Input
          placeholder="e.g. In-Game Analytics"
          value={hookId}
          type="text"
          required
          onChange={(e: any) => setHookId(e.currentTarget.value)}
        />
      </Box>
      <Box
        display='flex'
        flexDirection={'row'}
        sx={{ marginBottom: 2, width: '100%', justifyContent: 'center' }}
      >
        <Button
          className={disabled ? 'helikaButtonClassDisabled' : 'helikaButtonClass'}
          sx={{ width: '15em', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small'
          onClick={() => confirmUpload(uploadedFile)}
          disabled={disabled}
        >
          Upload CSV
        </Button>
      </Box>
    </Dialog>
  );
}
