const initialState = {
  ROLES: [
    {
      label: 'VIEWER',
      value: 'viewer',
    },
    {
      label: 'EDITOR',
      value: 'editor',
    },
    {
      label: 'NONE',
      value: 'none',
    }
  ]
};

export default function ModuleRolesReducer(state = initialState, action){
switch (action.type) {
  default:
    return state;
}
};