//imports
import { useState } from "react";
import _ from "lodash";

//contexts

//services

//components

//assets
import { useTheme } from "@mui/material";
import { StyledSvg } from "../organisms/v2/tabs/styled";
import SearchBar from "../SearchBar";
import { newColor } from "src/consts/colors";
import { ReactComponent as VisualDesignerIcon } from 'src/assets/visual_designer_icon.svg'
import { sortArrayBy } from "src/utils/string";
//---------------------------------------------------------------------------------

export default function VisualsSidebar({
  searchTerm,
  setSearchTerm,
  selectedVisual,
  setSelectedVisual,
  visuals
}: {
  searchTerm: any,
  setSearchTerm: any,
  selectedVisual: any,
  setSelectedVisual: any,
  visuals: any
}) {

  const theme: any = useTheme()
  const [visualsShow, setVisualsShow] = useState<boolean>(true)

  function visualClickHandler(visual: any) {
    setSelectedVisual(visual)
  }

  function populateVisuals(visuals: any) {
    if (_.isEmpty(visuals) || _.isNull(visuals)) return null
    return (
      sortArrayBy(visuals?.filter((visual: any) => visual?.name?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())), 'name')?.map((visual: any) => {
        return (
          <div
            key={visual?.id}
            onClick={() => visualClickHandler(visual)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0.5em',
              width: 'fit-content',
              maxWidth: '100%',
              paddingLeft: '1em',
              cursor: 'pointer',
            }}
            className={`${theme.palette.mode === 'dark' ? 'gradientTextHover' : ''} ${(!_.isUndefined(selectedVisual) && (visual.id === selectedVisual?.id)) ? 'gradientText2' : ''}`}
          >
            <StyledSvg
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <VisualDesignerIcon
                style={{ margin: 'auto' }}
              />
            </StyledSvg>
            <div
              style={{ marginLeft: '0.6em', textAlign: 'left' }}
              className='truncate-ellipsis'
            >
              {visual?.name}
            </div>
          </div>
        );
      })
    )
  }

  return (
    <div
      id='exploreSidebar'
      style={{
        minHeight: '100%',
        height: '100%',
        width: '20em',
        overflow: 'auto',
        marginRight: '1em',
        borderRadius: '1em',
      }}
    >
      <div
        style={{
          height: '100%',
          flex: 1,
          borderRadius: '1em',
        }}
      >
        <div
          style={{
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
            borderRadius: '1em',
            border: 'solid',
            borderColor: newColor.darkGunmetal,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            scrollbarGutter: 'stable both-sides',
            width: '15em',
          }}
        >
          <div
            style={{
              padding: '0.5em 0.5em 0 0.5em'
            }}
          >
            <SearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder='SEARCH'
              onEnter={(value: any) => { setSearchTerm(value) }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              borderRadius: '1em',
            }}
          >
            <div
              style={{
                maxHeight: 'calc(100%)',
                padding: '1em',
                overflowX: 'clip',
                overflowY: 'auto',
                height: '100%',
                scrollbarGutter: 'stable both-sides'
              }}
            >
              {  /* user queries */}
              <div
                style={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 900
                }}
                className='hoverHighlight'
                onClick={() => setVisualsShow((prevState: boolean) => !prevState)}
              >
                Visuals
              </div>
              <div className={`explorer-list-box ${visualsShow ? '' : 'closed'}`}>
                {
                  populateVisuals(visuals)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}