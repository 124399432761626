import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  Stack,
  styled as muiStyled,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Add } from '@mui/icons-material'
// assets
import { newColor } from 'src/consts/colors'
import LoadingSVG from 'src/assets/loading.svg'

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled(Box)`
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 24px;
  width: 300px;
`
const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))

const Input = muiStyled('input')(({ theme }) => ({
  width: '100%',
  height: '30px',
  borderRadius: '15px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface AddNewLabelModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (label: string) => Promise<void>
}

const AddNewLabelModal: React.FC<AddNewLabelModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme()
  const [label, setLabel] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value)
  }

  const handleConfirm = async () => {
    setLoading(true)
    try {
      await onConfirm(label)
      toast.success(`Successfully added new label: ${label}`)
      setLabel('')
      onClose()
    } catch (err) {
      if (err instanceof Error) {
        toast.error(`${err.message}`)
      } else {
        toast.error(`${err}`)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledModal open={open} onClose={onClose}>
      <ModalContent>
        <Stack direction="column" gap={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={
              theme.palette.mode === 'dark'
                ? 'circleBackground'
                : 'circleBackgroundLight'
            }
            gap={0.5}
            sx={{ margin: 0 }}
          >
            <Add sx={{ fontSize: 16 }} />
            <Typography
              className={`${
                theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              }`}
            >
              ADD NEW LABEL
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography sx={{ ml: 1 }}>LABEL:</Typography>
            <Input value={label} onChange={handleChangeLabel} />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Button
              onClick={handleConfirm}
              className="helikaButtonClass"
              disabled={loading}
            >
              {loading && (
                <img
                  width="14px"
                  className="animate-spin"
                  style={{ marginRight: '4px' }}
                  src={LoadingSVG}
                  alt=""
                />
              )}
              <StyledTxt>Add Label</StyledTxt>
            </Button>
            <Button
              onClick={() => {
                onClose()
                setLabel('')
              }}
              className="helikaButtonClass"
              disabled={loading}
            >
              <StyledTxt>Cancel</StyledTxt>
            </Button>
          </Stack>
        </Stack>
      </ModalContent>
    </StyledModal>
  )
}

export default AddNewLabelModal
