import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Box, Typography, styled as muiStyled, Button } from '@mui/material'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import isEmpty from 'lodash/isEmpty'

import bannerLeftBg from '../../../../assets/banner_bg_left_icon.svg'
import bannerRightBg from '../../../../assets/banner_bg_right_icon.svg'
import announcementLeftBg from '../../../../assets/announcement_bg_left_icon.png'
import announcementRightBg from '../../../../assets/announcement_bg_right_icon.png'

import { ReactComponent as AnnouncementIcon } from '../../../../assets/announcement_icon_small.svg'
import { ReactComponent as HelikaLogo } from '../../../../assets/announcement_helika_logo.svg'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { getActiveSystemMessages } from '../../../../utils/api/queries'
import { newColor } from '../../../../consts/colors'

import 'swiper/css'
import 'swiper/css/pagination'
import AnnouncementFullTextModal from '../../../../components/popups/AnnouncementFullTextModal'

export interface AnnouncemenTypeProp {
  announcementType?: 'urgent' | 'good' | 'notice'
}

interface AnnouncementType {
  title: string
  body: string
  type: 'urgent' | 'good' | 'notice'
  created_at: string
  id: number
  onHide: any
}

const Banner = muiStyled('div', {
  shouldForwardProp: (prop) => prop !== 'announcementType',
})<AnnouncemenTypeProp>(({ announcementType }) => {
  let linearGradient = newColor.black
  if (announcementType === 'good') {
    linearGradient = `linear-gradient(90deg, ${newColor.electricPurple} 10%, ${newColor.caribbeanGreen} 100%)`
  } else if (announcementType === 'urgent') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.jazzberryJam} 100%)`
  } else if (announcementType === 'notice') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.lemonGlacier} 100%)`
  }
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    color: 'white',
    borderRadius: '10px',
    minHeight: '160px',
    backgroundImage: `url(${bannerLeftBg}), url(${bannerRightBg}), ${linearGradient}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left, right, right',
  }
})
const Announcement = muiStyled('div', {
  shouldForwardProp: (prop) => prop !== 'announcementType',
})<AnnouncemenTypeProp>(({ announcementType }) => {
  let linearGradient = newColor.black
  if (announcementType === 'good') {
    linearGradient = `linear-gradient(90deg, ${newColor.electricPurple} 10%, ${newColor.caribbeanGreen} 100%)`
  } else if (announcementType === 'urgent') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.jazzberryJam} 100%)`
  } else if (announcementType === 'notice') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.lemonGlacier} 100%)`
  }
  return {
    display: 'flex',
    gap: '1rem',
    color: 'white',
    borderRadius: '10px',
    minHeight: '186px',
    backgroundImage: `url(${announcementLeftBg}), url(${announcementRightBg}), ${linearGradient}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left, right, right',
  }
})

const DateColumn = muiStyled(Box)(() => ({
  display: 'flex',
  width: '15%',
  padding: '10px 20px',
}))

const AnnouncementColumn = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '10px 20px',
  width: '70%',
  paddingLeft: '20px',
  alignItems: 'center',
  justifyContent: 'center',
}))

const LogoColumn = muiStyled('div')(() => ({
  display: 'flex',
  width: '15%',
  alignItems: 'center',
}))

const StyledIcon = muiStyled('span', {
  shouldForwardProp: (prop) => prop !== 'announcementType',
})<AnnouncemenTypeProp>(({ announcementType }) => {
  let fill = newColor.black
  if (announcementType === 'good') {
    fill = newColor.oceanBoatBlue
  } else if (announcementType === 'urgent') {
    fill = newColor.jazzberryJam
  } else if (announcementType === 'notice') {
    fill = newColor.lemonGlacier
  }
  return {
    fontSize: 0,
    '& svg, & path': {
      fill,
    },
  }
})

function BannerComponent({
  announcement,
  handleReadMoreAnnouncement,
}: {
  announcement: AnnouncementType
  handleReadMoreAnnouncement: any
}) {
  return (
    <Announcement
      className="gallery-slide-image"
      announcementType={announcement.type}
    >
      <DateColumn>
        <Typography sx={{ fontSize: '16px', whiteSpace: 'nowrap' }}>
          {moment(announcement.created_at).format('MMM DD, YYYY')}
        </Typography>
      </DateColumn>
      <AnnouncementColumn>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledIcon announcementType={announcement.type}>
              <AnnouncementIcon />
            </StyledIcon>
          </div>
          <Typography sx={{ fontSize: '20px', textTransform: 'uppercase' }}>
            {announcement.title}
          </Typography>
        </div>
        <Typography
          sx={{
            fontSize: '16px',
            textAlign: 'justify',
            lineHeight: '100%',
            whiteSpace: 'pre-line',
            paddingY: 0,
            paddingX: '5px',
            marginBottom: '10px',
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            lineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {announcement.body}
        </Typography>
        <Button
          sx={{
            height: '26px',
            borderRadius: '100px',
            border: '1px solid white',
            color: 'white',
            padding: '2px 40px',
            fontSize: '14px',
            fontWeight: '400',
          }}
          onClick={() => handleReadMoreAnnouncement(announcement)}
        >
          READ MORE
        </Button>
      </AnnouncementColumn>
      <LogoColumn>
        <HelikaLogo />
      </LogoColumn>
    </Announcement>
  )
}

export default function AnnouncementBanner() {
  const userData = useSelector((state: any) => state.user)
  const { isAuthenticated, isLoading, getWithAccessToken } =
    useContext(Auth0Context)
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([])
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementType | null>(null)
  const [showFullAnnouncementModal, setShowFullAnnouncementModal] =
    useState<boolean>(false)
  const handleReadMoreAnnouncement = (announcement: AnnouncementType) => {
    setSelectedAnnouncement(announcement)
    setShowFullAnnouncementModal(true)
  }
  useEffect(() => {
    if (isLoading || !isAuthenticated || isEmpty(userData)) return
    getActiveSystemMessages(getWithAccessToken)
      .then(({ results }) => {
        const filtermessage=results?.messages?.filter((message:any)=>message.show)
        const sorted = filtermessage?.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )
        setAnnouncements(sorted)
      })
      .catch(() => {})
  }, [isLoading, isAuthenticated, userData, getWithAccessToken])

  if (!announcements?.length) {
    return (
      <Box
        sx={{
          maxWidth: '100%',
          paddingX: `2.5rem`,
          borderRadius: '10px',
        }}
      >
        <Banner announcementType="urgent">
          <HelikaLogo />
        </Banner>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        paddingX: `2.5rem`,
        borderRadius: '10px',
      }}
    >
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        loop={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {announcements.map((announcement) => (
          <SwiperSlide key={announcement.id}>
            <BannerComponent
              announcement={announcement}
              handleReadMoreAnnouncement={handleReadMoreAnnouncement}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <AnnouncementFullTextModal
        open={showFullAnnouncementModal}
        setOpen={setShowFullAnnouncementModal}
        announcement={selectedAnnouncement}
      />
    </Box>
  )
}
