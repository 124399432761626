import { combineReducers } from "redux";
import userReducer from "./userReducer";
import domainReducer from "./domainReducer";
import analyticsDataReducer from "./analyticsDataReducer";
import gameMgmtDataReducer from "./gameMgmtDataReducer";
import accountMgmtDataReducer from "./accountMgmtDataReducer";
import supportDataReducer from "./supportDataReducer";
import notificationsReducer from "./notificationsReducer";
import OrgRolesReducer from "./OrgRolesReducer";
import sdkReducer from "./sdkReducer";
import moduleRolesReducer from "./moduleRolesReducer";
import llmReducer from "./llmChatReducer";

const reducers = combineReducers({
  user: userReducer,
  domain: domainReducer,
  notifications: notificationsReducer,
  supportData: supportDataReducer,
  analyticsData: analyticsDataReducer,
  gameMgmtData: gameMgmtDataReducer,
  accountMgmtData: accountMgmtDataReducer,
  OrgRoles: OrgRolesReducer,
  moduleRoles: moduleRolesReducer,
  sdk: sdkReducer,
  llmChat: llmReducer
});

export default reducers;