
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as ReactTooltip from 'react-tooltip';
import { useTheme } from '@mui/material';
import { newColor } from 'src/consts/colors';
import _ from 'lodash';

export function DateInputField({
    title,
    value,
    setValue,
    hint,
    disabled,
    inModal,
    reference,
    minDate,
    maxDate,
    filterTime,
    withTime = true,
    open,
    setOpen,
    showTimezone = true,
    daysOnly = false
}: {
    title?: string,
    value: Date,
    setValue: React.Dispatch<React.SetStateAction<Date>>,
    hint?: string,
    disabled?: boolean,
    inModal?: boolean,
    reference?: React.RefObject<any>,
    minDate?: any,
    maxDate?: any
    filterTime?: any,
    withTime?: boolean,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    showTimezone?: boolean,
    daysOnly?: boolean
}) {

    const theme: any = useTheme();

    const changeDate = (date_value: any) => {
        setValue(date_value);
    }

    const titleClass = 'w-full flex flex-row space-x-2 text-[0.85em] mt-1 mb-[0.55em] ' +
        (
            inModal
                ? 'text-gray-400 '
                : (disabled ? 'text-gray-400 ' : 'text-helikaColor ')
        )

    var hintSymbolDiv = undefined;
    // if (hint) { hintSymbolDiv = <ImInfo data-tooltip-id="dateinput" data-tooltip-html={hint} className='my-auto' />; }

    return (
        <div className='grid grid-cols-1 w-full'>
            <ReactTooltip.Tooltip id="dateinput" />
            <div
                className={titleClass}
                style={{
                    paddingBottom: '0.5em'
                }}
            >
                {title && <div>{title}</div>}
                {hintSymbolDiv}
                {
                    showTimezone && moment.tz.zone(moment.tz.guess())?.abbr((new Date()).getTime()) &&
                    <div
                        style={{ color: disabled ? theme.palette.palette.text.grayedOut : theme.palette.text.primary }}
                    >
                        {`Timezone: ${moment.tz.zone(moment.tz.guess())?.abbr((new Date()).getTime())}`}
                    </div>
                }
            </div>
            <div
                className='dateinputroot'
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
            >
                {
                    withTime
                        ?
                        <DateTimePicker
                            ref={reference ? reference : undefined}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            value={moment(value).isValid() ? moment(value) : null}
                            onChange={(newValue) => changeDate(moment(newValue))}
                            disabled={disabled}
                            minDateTime={filterTime ? moment(value < new Date() ? value : Date.now()).add(-5, 'minutes') : undefined}
                            maxDate={maxDate !== undefined ? moment(maxDate) : undefined}
                            minDate={minDate !== undefined ? moment(minDate) : undefined}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false,
                                },
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                }
                            }}
                            // views={daysOnly ? ['day'] : undefined}
                            sx={
                                {
                                    "& .MuiInputBase-input": { textAlign: 'center', lineHeight: '1rem', fontSize: '0.80rem', padding: '0.5em' },
                                    "& .MuiInputBase-root": { width: 'auto', maxWidth: '100%', outline: '2em', borderRadius: '0.3em' },
                                    // "& .MuiButtonBase-root": { padding: '0px' },
                                    //"& .MuiMenu-paper": 
                                    //{ backgroundColor: '#000000', border:'inset', marginTop:'1em'},
                                    //"& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                                    //  borderBottom:'inset', borderBottomWidth:'0.5px', borderColor: '#676767',
                                    //}
                                    width: '100%',
                                    background: theme.palette.mode === 'dark' ? newColor.black : newColor.white,
                                    borderRadius: '0.3em !important',
                                }
                            }
                        />
                        :
                        <DatePicker
                            ref={reference ? reference : undefined}
                            open={open}
                            onClose={() => setOpen(false)}
                            onOpen={() => setOpen(true)}
                            value={moment(value).isValid() ? moment(value) : null}
                            onChange={(newValue) => changeDate(moment(newValue))}
                            disabled={disabled}
                            maxDate={maxDate !== undefined ? moment(maxDate) : undefined}
                            minDate={minDate !== undefined ? moment(minDate) : undefined}
                            slotProps={{
                                textField: {
                                    size: "small",
                                    error: false,
                                },
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                }
                            }}
                            // views={daysOnly ? ['day'] : undefined}
                            sx={
                                {
                                    "& .MuiInputBase-input": { textAlign: 'center', lineHeight: '1rem', fontSize: '0.80rem', padding: '0.5em' },
                                    "& .MuiInputBase-root": { width: 'auto', maxWidth: '100%', outline: '2em', borderRadius: '0.3em' },
                                    // "& .MuiButtonBase-root": { padding: '0px' },
                                    //"& .MuiMenu-paper": 
                                    //{ backgroundColor: '#000000', border:'inset', marginTop:'1em'},
                                    //"& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                                    //  borderBottom:'inset', borderBottomWidth:'0.5px', borderColor: '#676767',
                                    //}
                                    width: '100%',
                                    background: theme.palette.mode === 'dark' ? newColor.black : newColor.white,
                                    borderRadius: '0.3em !important',
                                }
                            }
                        />
                }
            </div>
        </div>
    );
}