import { useContext, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Box,
  Popover,
  styled as muiStyled,
  Typography,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import _ from 'lodash-es'

import { ReactComponent as TabManagementIcon } from 'src/assets/tab_management_icon.svg'
import { ReactComponent as TabOrderIcon } from 'src/assets/large_text_icon.svg'
import ActionIcon from 'src/assets/action.svg'
import { ReactComponent as AddTabIcon } from 'src/assets/add_tab.svg'
import { Auth0Context } from '../../contexts/Auth0Context'
import TabManagement from '../../components/OrganizationManagement/TabManagement'
import { useUpdateOrgTabOrder } from '../../components/organisms/v2/tabs/useUpdateTabOrder'
import {
  getCurrentUserOrg as getCurrentUserOrgAPI,
  deleteTabFromOrg as deleteTabFromOrgAPI,
  assignTabToOrg,
} from '../../utils/api/queries'
import Card from '../../components/atoms/v2/card'
import ThemedIcon from '../../components/organisms/v2/themedIcon'
import { toast } from 'react-toastify'
import { newColor } from '../../consts/colors'

const ActionButton = muiStyled('span')(() => ({
  position: 'absolute',
  right: 0,
  top: 0,
  outline: 0,
  background: 'none',
  border: 0,
  cursor: 'pointer',
}))

const BlockWrapper = muiStyled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}))

const StyledTypography = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  fontSize: '12px',
}))

const StyledSelect = muiStyled('select')(({ theme }) => ({
  width: '20vw',
  maxWidth: '315px',
  minWidth: '80px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

function ToggleActionButton({
  handleClose,
  handleCreateParentTab,
  handleCreateTab,
}: {
  handleClose: VoidFunction
  handleCreateParentTab?: VoidFunction
  handleCreateTab?: VoidFunction
}) {
  return (
    <Stack alignItems={'flex-start'}>
      <Button
        onClick={() => {
          if (handleCreateParentTab) handleCreateParentTab()
          handleClose()
        }}
        sx={{ color: 'text.primary' }}
      >
        <ThemedIcon>
          <AddTabIcon
            style={{ width: '12px', marginRight: '4px' }}
            fontSize={'12px'}
          />
        </ThemedIcon>

        <StyledTypography>CREATE A PARENT TAB</StyledTypography>
      </Button>
      <Button
        onClick={() => {
          if (handleCreateTab) handleCreateTab()
          handleClose()
        }}
        sx={{ color: 'text.primary' }}
      >
        <ThemedIcon>
          <AddTabIcon
            style={{ width: '12px', marginRight: '4px' }}
            fontSize={'12px'}
          />
        </ThemedIcon>
        <StyledTypography>CREATE A NEW TAB</StyledTypography>
      </Button>
    </Stack>
  )
}

function AddTabToOrgForm({
  selectedTab,
  selectedOrg,
  handleOrgChange,
  handleTabChange,
  orgs,
  distinctTabs,
  onSave,
}: any) {
  return (
    <BlockWrapper style={{ display: 'flex', gap: '1rem' }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        <StyledSelect value={selectedOrg} onChange={handleOrgChange}>
          <option disabled value="">
            SELECT ORGANIZATION
          </option>
          {orgs
            ?.sort((a: any, b: any) =>
              a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
            )
            .map((org: Org) => (
              <option key={org.value} value={`${org.value}`}>
                {org.label}
              </option>
            ))}
        </StyledSelect>
        <StyledSelect value={selectedTab} onChange={handleTabChange}>
          <option disabled value="">
            NEW TAB TO ADD
          </option>
          {distinctTabs
            ?.sort((a: any, b: any) =>
              a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
            )
            .filter((tab: any) => tab.parent_tab_id !== null)
            .map((tab: any) => (
              <option key={tab.id} value={`${tab.id}`}>
                {tab.name}
              </option>
            ))}
        </StyledSelect>
      </div>
      <Button
        onClick={onSave}
        sx={{ pr: 2, height: '26px', my: '14px' }}
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        className="helikaButtonClass"
      >
        ADD TAB
      </Button>
    </BlockWrapper>
  )
}

export function TabManagementActionButtons() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box sx={{ position: 'relative', marginLeft: 'auto' }}>
      <ActionButton aria-describedby={id} onClick={handleClick}>
        <img src={ActionIcon} alt="edit" />
      </ActionButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ToggleActionButton handleClose={handleClose} />
      </Popover>
    </Box>
  )
}

interface Org {
  label: string | null
  value: number | null
}

export default function OrganizationTabsManagement({
  orgs,
  distinctTabs,
}: {
  orgs: Org[]
  distinctTabs: any[]
}) {
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const {
    getWithAccessToken,
    postWithAccessToken,
    isLoading,
    isAuthenticated,
  } = useContext(Auth0Context)
  const { trigger } = useUpdateOrgTabOrder()

  const [tabsList, setTabsList] = useState<any>(null)
  const [orgLoading, setOrgLoading] = useState<boolean>(false)
  const [selectedOrg, setSelectedOrg] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<string>('')

  const getOrg = useCallback(async () => {
    if (!selectedOrg) return

    setOrgLoading(true)
    const orgData = await getCurrentUserOrgAPI(
      getWithAccessToken,
      Number(selectedOrg),
    )
    if (!orgData || !orgData.results) return
    setTabsList(orgData.results.default_tabs)
    setOrgLoading(false)
  }, [getWithAccessToken, selectedOrg])

  useEffect(() => {
    async function getInitialData() {
      try {
        await getOrg()
      } catch (e) {
        console.log(e)
      }
    }

    if (isLoading || !isAuthenticated) return

    getInitialData()
  }, [getWithAccessToken, isAuthenticated, isLoading, getOrg, orgs, userData])

  const handleOrgChange = (event: { target: { value: string } }) => {
    setSelectedOrg(event.target.value)
  }

  const handleTabChange = (event: { target: { value: string } }) => {
    setSelectedTab(event.target.value)
  }

  const onSave = useCallback(
    async ({ tabs }: any) => {
      await trigger({ tabs, organizationId: Number(selectedOrg) })
      getOrg()
      if (!_.isEmpty(sdk)) {
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'organization_configuration_change',
            event: {
              event_sub_type: 'portal_configuration_change',
              configuration_type: 'tab_layout',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
              details: {
                organization_identifier: Number(selectedOrg),
                change: 'tabs_reordered',
              },
            },
          },
        ])
      }
    },
    [getOrg, trigger, selectedOrg, userData, sdk],
  )

  const handleAddTabToOrg = useCallback(async () => {
    if (selectedTab && selectedOrg) {
      try {
        setOrgLoading(true)
        await assignTabToOrg(postWithAccessToken, {
          tab_id: Number(selectedTab),
          org_id: Number(selectedOrg),
        })
        getOrg()
        if (!_.isEmpty(sdk)) {
          await sdk.createUserEvent([
            {
              game_id: 'helika_portal',
              event_type: 'organization_configuration_change',
              event: {
                event_sub_type: 'portal_configuration_change',
                configuration_type: 'tab_layout',
                user_identifier: userData?.id,
                user_email: userData?.email,
                user_org_identifier: userData?.organization_id,
                user_org_name: userData?.organization_name,
                details: {
                  tab_identifier: Number(selectedTab),
                  organization_identifier: Number(selectedOrg),
                  change: 'tab_added_to_org',
                },
              },
            },
          ])
        }
      } catch (err: any) {
        setOrgLoading(false)
        toast.error(err.message)
      }
    }
  }, [postWithAccessToken, selectedTab, selectedOrg, getOrg, userData, sdk])

  const handleRemoveTabFromOrg = async (tabId: number) => {
    try {
      setOrgLoading(true)
      await deleteTabFromOrgAPI(postWithAccessToken, tabId, Number(selectedOrg))
      toast.success('Tab deleted successfully')
      getOrg()
      if (!_.isEmpty(sdk)) {
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'organization_configuration_change',
            event: {
              event_sub_type: 'portal_configuration_change',
              configuration_type: 'tab_layout',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
              details: {
                organization_identifier: Number(selectedOrg),
                change: 'tabs_reordered',
              },
            },
          },
        ])
      }
    } catch (err: any) {
      setOrgLoading(false)
      toast.error(err.message)
    }
  }

  return (
    <>
      <Card
        label="ORGANIZATION TABS"
        icon={<TabManagementIcon />}
        subheaderText="TABS LIST"
        subheaderIcon={<TabOrderIcon />}
        subheaderExtra={
          <AddTabToOrgForm
            orgs={orgs}
            distinctTabs={distinctTabs}
            handleOrgChange={handleOrgChange}
            handleTabChange={handleTabChange}
            onSave={handleAddTabToOrg}
            selectedOrg={selectedOrg}
            selectedTab={selectedTab}
          />
        }
        sx={{ flex: 1 }}
      >
        <>
          {!orgLoading && (
            <TabManagement
              tabsData={tabsList || {}}
              enableDeleteTab
              enableEditTabDefaultParams
              allowReorder
              onSave={onSave}
              onDeleteTab={handleRemoveTabFromOrg}
              selectedOrg={selectedOrg}
            />
          )}
        </>
      </Card>
    </>
  )
}
