import React from 'react'
import { Checkbox } from '@mui/material'
import { useSelector } from 'react-redux'

export function SupportDataGridSelectHeaderComponent(props: any) {
  const SUPPORT_TICKETS = useSelector(
    (state: any) => state.supportData.SUPPORT_TICKETS,
  )
  let func = props.func
    ? props.func
    : props.colDef?.cellRendererParams?.setSelectedReports
      ? props.colDef?.cellRendererParams?.setSelectedReports
      : null
  const selectedReports = props.colDef?.selectedReports
    ? props.colDef?.selectedReports
    : null
  return (
    <div
      style={{
        margin: 'auto',
        width: 'fit-content',
      }}
    >
      <div
        className="supportHeaderParent"
        style={{
          color: props.theme?.palette.text.primary,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'start',
          //   margin: 'auto',
          width: 'fit-content',
          height: '56px',
        }}
      >
        <Checkbox
          onChange={(val) => {
            if (func) {
              func((prevState: any) => {
                let newData: any = Object.assign({}, prevState)
                if (val.target.checked) {
                  newData = SUPPORT_TICKETS.reduce((acc: any, obj: any) => {
                    acc[obj.id] = obj
                    return acc
                  }, {})
                } else {
                  newData = {}
                }
                return newData
              })
            } else {
              console.error('Could not select item')
            }
          }}
          checked={
            SUPPORT_TICKETS.length > 0 &&
            SUPPORT_TICKETS.length === Object.keys(selectedReports).length
          }
        />
      </div>
    </div>
  )
}
