import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState } from 'react';
import React from 'react';
import { toast } from "react-toastify";
import {
  useTheme,
} from '@mui/material'
import { newColor } from '../../consts/colors';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Typography } from '@mui/material';
import applyIcon from '../../assets/applyIcon.svg'
import loadingSVG from '../../assets/loading.svg';
import { Auth0Context } from '../../contexts/Auth0Context';
import { applyConfig } from '../../utils/api/queries';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  config?: any;
  columnDefs: any[],
  rowData: any[] | undefined,
  onClose: () => void;
  loadedS3Objects: any,
  setLoadedS3Objects: any,
  draftDiff: any,
  dbDiscrepancies: any
}

export default function ApplyConfig(props: SimpleDialogProps) {

  const { open, setOpen, config, onClose } = props;
  const theme = useTheme();

  const [applyInProgress, setApplyInProgress] = useState<boolean>(false);

  const { patchWithAccessToken } = useContext(Auth0Context);

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  async function apply(config: any) {
    if (config.bad_commit) {
      toast.error('The Sheet Mappings have been changed since this commit was applied and cannot be re-applied.<br />Please download this sheet, apply the necessary changes, and re-upload to revert to this state.')
      return;
    }
    setApplyInProgress(true);
    let isConfigApplied = await applyConfig(patchWithAccessToken, config);
    if (isConfigApplied) {
      toast.success('Config was applied! Page will refresh.');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    } else {
      toast.error('Config could not be applied.');
    }
    setApplyInProgress(false);
  }

  const handleClose = () => {
    setOpen(false);
    //setIsLoading(true);
    setShowWarning(false);
    if (onClose) onClose();
  };

  //useEffect(()=>{
  //  setIsLoading(true);
  //  if (!dbDiscrepancies || !config) {
  //    setShowWarning(false);
  //    return;
  //  }
  //  if (dbDiscrepancies) {
  //    let hasDiscrepancies = false;
  //    dbDiscrepancies.forEach( (sheet:any) => {
  //      if (sheet?.diff && sheet.diff?.length > 0) {
  //        hasDiscrepancies = true;
  //      }
  //    })
  //    setShowWarning(hasDiscrepancies);
  //    setIsLoading(false);
  //  }
  //},[dbDiscrepancies, config]);

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }} onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className={theme.palette.mode === 'dark' ? 'circleBackground' : 'circleBackgroundLight'} sx={{ justifyContent: 'center', minWidth: '20em', margin: '1em' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`}><img src={applyIcon} alt='' /> APPLY CONFIG</DialogTitle>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ padding: '0 1em 1em' }}
      >
        {
          showWarning &&
          <Typography
            variant='h6'
            sx={{ color: 'red', textAlign: 'left', margin: '0 0 0 0' }}
          >
            WARNING!
          </Typography>
        }
        {
          showWarning
            ?
            <Typography
              variant='body1'
              sx={{ color: 'red', textAlign: 'left', margin: '0 0 1.5em 0' }}
            >
              The values in the database do not match the last applied config. Please check these values before applying the latest config as these changes will be lost.
            </Typography>
            :
            <Typography
              variant='body1'
              sx={{ textAlign: 'left', margin: '0 0 1.5em 0' }}
            >
              Are you sure you want to apply this config? This will update the values in your Database.
            </Typography>
        }
        <Box
          display='flex'
          flexDirection={'row'}
          sx={{ marginBottom: 2, width: '100%', justifyContent: 'center' }}
        >
          {
            isLoading
              ?
              <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                <img className='animate-spin' style={{ maxWidth: '3em', margin: 'auto' }} src={loadingSVG} alt='' />
                <p>Checking for discrepancies in the db...</p>
              </div>
              :
              applyInProgress
                ?
                <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                  <img className='animate-spin' style={{ maxWidth: '3em', margin: 'auto' }} src={loadingSVG} alt='' />
                </div>
                :
                <Button
                  className='helikaButtonClass'
                  sx={{ width: '15em', paddingLeft: 2, paddingRight: 2 }}
                  variant='contained' size='small'
                  disabled={applyInProgress}
                  onClick={() => apply(config)}
                >
                  Confirm
                </Button>
          }
        </Box>
      </Box>
    </Dialog>
  );
}
