import { Divider, Paper, Stack, styled as muiStyled } from '@mui/material'
import styled from 'styled-components'
import { newColor } from '../../../../../consts/colors'

export const DragButton: any = styled.img`
  padding: 0 0.375rem;
  background: ${newColor.raisinBlack};
`

export const TabName: any = styled(Paper)`
  border-radius: 0;
  padding: 0.5rem;
  flex-grow: 1;
`

export const TabContentDivider: any = styled(Divider)`
  background: ${newColor.dimSlate};
`
export const Tab: any = styled(Stack)`
  display: flex;
  border-radius: 0.625rem;
  text-transform: uppercase;
  overflow: hidden;
  border: 1px solid ${newColor.dimSlate};
  width: 280px;
`

export const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& svg, & path': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))
