//imports
import React, { useState } from "react"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Grid, useTheme } from '@mui/material';
import { StyledSvg } from "./organisms/v2/tabs/styled";


//contexts

//services

//components
import { ReactComponent as NewVisual } from 'src/assets/new_visual_icon.svg'
import { newColor } from "src/consts/colors";
import SearchBar from "./SearchBar";
import { ReactComponent as RunIcon } from 'src/assets/run_icon.svg'
import _ from "lodash";
import DontSaveVisualModal from "./popups/DontSaveVisualModal";
import { sortArrayBy } from "src/utils/string";
//---------------------------------------------------------------------------------

function VisualDesignerNewVisualMenu({
  userQueries,
  orgQueries,
  selectedQuery,
  queryOnClickHandler
}: {
  userQueries: any,
  orgQueries: any,
  selectedQuery: any,
  queryOnClickHandler: any
}) {

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [interiorSelectedQuery, setInteriorSelectedQuery] = React.useState<any>();
  const [dontSaveVisualModalBoolean, setDontSaveVisualModalBoolean] = useState<boolean>(false)

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchTerm, setSearchTerm] = useState<string>("")

  function queryClickHandler(query: any) {
    setInteriorSelectedQuery(query)
  }

  function populateQueryList(queryList: any) {
    if (_.isEmpty(queryList) || _.isNull(queryList)) return null
    return (
      sortArrayBy(queryList?.filter((query: any) => query?.name?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())), 'name')?.map((query: any) => {
        return (
          <div
            key={query?.id}
            onClick={() => queryClickHandler(query)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0.5em',
              width: 'fit-content',
              maxWidth: '100%',
              paddingLeft: '1em',
              cursor: 'pointer',
              color: query.id === selectedQuery?.id ? 'linear-gradient(90deg, #9654F0 60%, #8BE1C9 100%)' : theme.palette.text.primary
            }}
            className={`${theme.palette.mode === 'dark' ? 'gradientTextHover' : ''} ${query.id === selectedQuery?.id ? 'gradientText2' : ''}`}
          >
            <StyledSvg
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <RunIcon
                style={{ margin: 'auto' }}
              />
            </StyledSvg>
            <div
              style={{
                marginLeft: '0.6em',
                textAlign: 'left',
                color: query?.id === interiorSelectedQuery?.id ? newColor.jazzberryJam : theme.palette.text.primary
              }}
              className='truncate-ellipsis'
            >
              {query?.name}
            </div>
          </div>
        );
      })
    )
  }

  return (
    <div
      style={{
        width: 'fit-content'
      }}
    >
      <DontSaveVisualModal
        open={dontSaveVisualModalBoolean}
        setOpen={setDontSaveVisualModalBoolean}
        openVisualHandler={() => {
          queryOnClickHandler(interiorSelectedQuery)
          handleClose()
          setDontSaveVisualModalBoolean(false)
        }}
        openItemName={'query to create a new visual'}
        title="CREATE NEW VISUAL"
      />
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          padding: '0.2em 0.5em 0.2em 0.5em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: theme.palette.text.primary,
          marginLeft: '0.5em',
          fontSize: '0.8em',
          fontWeight: '600',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <StyledSvg
          sx={{
            '& svg, & path': {
              stroke: (_.isEmpty(orgQueries) || _.isNull(orgQueries)) ? newColor.darkGunmetal : theme.palette.text.primary,
              fill: (_.isEmpty(orgQueries) || _.isNull(orgQueries)) ? newColor.darkGunmetal : theme.palette.text.primary,
            },
          }}
        >
          <NewVisual />
        </StyledSvg>
        <div
          style={{
            margin: '0.1em 0 0 0.5em',
            color: (_.isEmpty(orgQueries) || _.isNull(orgQueries)) ? newColor.darkGunmetal : theme.palette.text.primary,
          }}
        >
          CREATE NEW VISUAL
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
        sx={
          {
            marginTop: '1em',
            "& .MuiPaper-root":
              { background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke, borderRadius: '0.5em', border: 'solid', borderColor: newColor.darkGunmetal },
            "& .MuiList-root":
            {
              minWidth: '17.5em',
              maxWidth: '20em',
              background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke,
              borderWidth: '1px',
              borderRadius: '0.5em',
              borderColor: theme.palette.mode === 'dark' ? newColor.whiteSmoke : 'black', paddingLeft: '0.5em',
              paddingRight: '0.5em',
              color: theme.palette.mode === 'dark' ? 'white' : 'black'
            },
            "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
              borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#3D3D3D', width: '100%'
            },
            ".MuiButtonBase-root:hover": {
              background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke,
              color: '#ea335f'
            },
          }
        }
      >
        <div
          style={{
            padding: '0.5em 0.5em 1em 0.5em'
          }}
        >
          <SearchBar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder='SEARCH'
            onEnter={(value: any) => { setSearchTerm(value) }}
            borderColor={theme.palette.mode === 'dark' ? 'white' : 'black'}
          />
        </div>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 900
          }}
        >
          User Queries
        </div>
        <div className={`explorer-list-box`}>
          {
            populateQueryList(userQueries)
          }
        </div>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 900
          }}
        >
          Org Queries
        </div>
        <div className={`explorer-list-box`}>
          {
            populateQueryList(orgQueries)
          }
        </div>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 0.2em 0 0.2em',
            justifyContent: 'space-around'
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              borderRadius: '100vmax',
              textAlign: 'center',
              border: 'solid',
              padding: '0 1em 0 1em',
              cursor: 'pointer'
            }}
            onClick={handleClose}
          >
            CANCEL
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderRadius: '100vmax',
              textAlign: 'center',
              padding: '0 1em 0 1em',
              background: `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.jazzberryJam} 100%)`,
              cursor: 'pointer'
            }}
            onClick={() => {
              setDontSaveVisualModalBoolean(true)
            }}
          >
            OPEN
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}

export default VisualDesignerNewVisualMenu;