import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled as muiStyled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { toast } from 'react-toastify'
// project-import
import LoadingComponent from '../LoadingComponent'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { updateAPIKeyStatus as updateAPIKeyStatusAPI } from 'src/utils/api/queries'
// types
import {
  AccessLevelMappings,
  ApiKey,
  EnvironmentMappings,
  PlatformMappings,
  SourceMappings,
} from 'src/types/apiKey'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as ApiKeyIcon } from 'src/assets/api_key_icon_2.svg'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface ModalProps {
  open: boolean
  setOpen: (apiKey: ApiKey | null) => void
  data: ApiKey | null
  refetch: () => void
}

export default function EditApiKeyModal({
  data,
  open,
  setOpen,
  refetch,
}: ModalProps) {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down('md'))
  const initState = {
    data: {
      apiKeyName: '',
      source: '',
      accessLevel: '',
      environment: '',
      platform: '',
    },
    loading: false,
  }
  const [state, setState] = useState<{
    data: {
      apiKeyName: string
      source: string
      accessLevel: string
      environment: string
      platform: string
    }
    loading: boolean
  }>(initState)
  const { patchWithAccessToken } = useContext(Auth0Context)

  useEffect(() => {
    if (!!data) {
      setState((s) => ({
        ...s,
        data: {
          ...s.data,
          apiKeyName: data.name,
          source: data.source,
          accessLevel: data.access_level,
          environment: data.env ?? '',
          platform: data.platform,
        },
      }))
    }
  }, [JSON.stringify(data)])

  const handleClose = () => {
    setOpen(null)
  }

  const getSelectOptions = (
    mappingData: { [key: string]: string },
    uppercase: boolean = true,
  ) =>
    Object.entries(mappingData).map(([value, label]) => ({
      label: uppercase ? label.toUpperCase() : label,
      value: value,
    }))

  const handleChangeFields = (
    field: 'apiKeyName' | 'source' | 'accessLevel' | 'environment' | 'platform',
    value: string,
  ) => {
    setState((s) => ({ ...s, data: { ...s.data, [field]: value } }))
  }

  async function updateApiKey() {
    try {
      setState((s) => ({ ...s, loading: true }))
      const { apiKeyName, source, accessLevel, platform, environment } =
        state.data

      await updateAPIKeyStatusAPI(patchWithAccessToken, {
        ...data,
        name: apiKeyName,
        source: source,
        access_level: accessLevel,
        env: environment,
        platform,
      })

      refetch()
      setState((s) => ({ ...s, loading: false }))
      setOpen(null)
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setState((s) => ({ ...s, loading: false }))
    }
  }

  const { apiKeyName, source, accessLevel, platform, environment } = useMemo(
    () => state.data,
    [JSON.stringify(state.data)],
  )

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '30px 40px 0 40px',
        }}
      >
        <DialogTitle
          className={`${
            theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
          } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ApiKeyIcon style={{ margin: 'auto 0.3em auto 0' }} />{' '}
          <div style={{ lineHeight: '26px', fontSize: '23px' }}>
            EDIT API KEY
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '0 40px 0 58px',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
        gap={'8px'}
      >
        <Typography
          style={{
            fontSize: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '22.5px',
          }}
        >
          API KEY INFORMATION
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              <div
                className="minimizeFontOnSmallWindow"
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  paddingLeft: downMD ? '10px' : '35px',
                }}
              >
                NAME:
              </div>
            </Grid>
            <Grid item xs={7}>
              <Input
                value={apiKeyName}
                placeholder="Name"
                onChange={(e) => {
                  e.preventDefault()
                  handleChangeFields('apiKeyName', e.currentTarget.value)
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              <div
                className="minimizeFontOnSmallWindow"
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  paddingLeft: downMD ? '10px' : '35px',
                }}
              >
                PLATFORM:
              </div>
            </Grid>
            <Grid item xs={7}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  handleChangeFields('platform', event.target.value)
                }
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={platform}
              >
                {getSelectOptions(PlatformMappings, false).map(
                  ({ label, value }) => (
                    <MenuItem
                      value={value}
                      key={value}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              <div
                className="minimizeFontOnSmallWindow"
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  paddingLeft: downMD ? '10px' : '35px',
                }}
              >
                SOURCE:
              </div>
            </Grid>
            <Grid item xs={7}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  handleChangeFields('source', event.target.value)
                }
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={source}
              >
                {getSelectOptions(SourceMappings).map(({ label, value }) => (
                  <MenuItem
                    value={value}
                    key={value}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              <div
                className="minimizeFontOnSmallWindow"
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  paddingLeft: downMD ? '10px' : '35px',
                }}
              >
                ENVIRONMENT:
              </div>
            </Grid>
            <Grid item xs={7}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  handleChangeFields('environment', event.target.value)
                }
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={environment}
                readOnly={true}
              >
                {getSelectOptions(EnvironmentMappings).map(
                  ({ label, value }) => (
                    <MenuItem
                      value={value}
                      key={value}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Grid item xs={5}>
              <div
                className="minimizeFontOnSmallWindow"
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  paddingLeft: downMD ? '10px' : '35px',
                }}
              >
                ACCESS LEVEL:
              </div>
            </Grid>
            <Grid item xs={7}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                size="small"
                onChange={(event: SelectChangeEvent) =>
                  handleChangeFields('accessLevel', event.target.value)
                }
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={accessLevel}
              >
                {getSelectOptions(AccessLevelMappings).map(
                  ({ label, value }) => (
                    <MenuItem
                      value={value}
                      key={value}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {state.loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={state.loading || !data}
            onClick={updateApiKey}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(null)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
