import { newColor } from 'src/consts/colors'
import {
  Box,
  Grid,
  Skeleton,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import React from 'react'

const StyledGrid = muiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  wordBreak: 'break-all',
  lineHeight: '127%',
  fontSize: '15px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  width: '20%',
  alignItems: 'center',
  borderRadius: '16px',
  paddingLeft: '20%',
}))

export default function LoadingTableComponent() {
  const theme = useTheme()
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor:
            theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
          height: 'auto',
          width: '97.5%',
          paddingBottom: '16px',
          margin: '1rem auto auto',
        }}
      >
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor:
            theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
          height: 'auto',
          width: '97.5%',
          paddingBottom: '16px',
          margin: '1rem auto auto',
        }}
      >
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor:
            theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
          height: 'auto',
          width: '97.5%',
          paddingBottom: '16px',
          margin: '1rem auto auto',
        }}
      >
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingX: '30%',
              width: '100%',
              gap: '16px',
            }}
          >
            <Skeleton width={'60%'} />
            <Skeleton width={'20%'} />
            <Skeleton width={'20%'} />
          </Box>
        </StyledGrid>
      </Grid>
    </>
  )
}
