import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from '@mui/material'
import { setSupportTicketStatus } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import { toast } from 'react-toastify'
import { SUPPORT_TICKET_STATUSES } from '../../consts/backend'
import '../../App.css'
import { selectedReportsHeaders } from '../../utils/agGrid'
import { newColor } from '../../consts/colors'
import LoadingComponent from '../LoadingComponent'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../../state'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onClose: (value: string) => void
  selectedReports: any
}

export default function SetReportStatusModal(props: SimpleDialogProps) {
  const dispatch = useDispatch()
  const { setSupportState } = bindActionCreators(actionCreators, dispatch)
  const SUPPORT_TICKETS = useSelector(
    (state: any) => state.supportData.SUPPORT_TICKETS,
  )
  const { open, setOpen, selectedReports } = props
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context)
  const [completeInProgress, setCompleteInProgress] = useState<boolean>(false)
  const [newStatus, setNewStatus] = useState<any>(null)

  const handleClose = () => {
    setNewStatus(undefined)
    setOpen(false)
  }

  const setStatus = async () => {
    if (!newStatus) {
      toast.error('Must select a new status')
      return
    }
    setCompleteInProgress(true)
    let support_ticket_ids = [selectedReports.id]
    let isCompleted = await setSupportTicketStatus(
      patchWithAccessToken,
      newStatus,
      support_ticket_ids,
    )
    if (isCompleted) {
      toast.success(
        `Ticket Status was marked as ${SUPPORT_TICKET_STATUSES[newStatus as keyof typeof SUPPORT_TICKET_STATUSES]}.`,
      )
      setSupportState({
        type: 'SET_SUPPORT_TICKETS',
        payload: SUPPORT_TICKETS.map((item: any) => {
          if (item.id === selectedReports.id) {
            return { ...item, status: newStatus }
          }
          return item
        }),
      })
      setOpen(false)
    } else {
      toast.error('Ticket status could not be updated.')
    }
    setCompleteInProgress(false)
  }

  return (
    <>
      <Dialog
        disableRestoreFocus
        disableScrollLock
        sx={{ margin: 'auto' }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
            borderStyle: 'solid',
            borderColor: newColor.outerSpace,
            borderWidth: '1px',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            color: 'white',
            justifyContent: 'center',
            minWidth: '20em',
            margin: '1em',
          }}
        >
          <DialogTitle
            className={`${
              theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
          >
            SET STATUS
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            marginBottom: 2,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <label>New Status:</label>
          <Select
            required
            sx={{ width: '100%', cursor: 'pointer', marginBottom: 2 }}
            onChange={(e) => {
              setNewStatus(e.target.value)
            }}
            value={newStatus || selectedReports?.status}
          >
            <MenuItem disabled={true} value={undefined}>
              Select a new Status
            </MenuItem>
            {Object.entries(SUPPORT_TICKET_STATUSES).map(([value, name]) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <Button
            onClick={setStatus}
            sx={{
              width: '18em',
              paddingLeft: 2,
              paddingRight: 2,
              margin: 'auto auto 1em auto',
            }}
            variant="contained"
            size="small"
            className="helikaButtonClass"
            disabled={completeInProgress}
          >
            {completeInProgress ? (
              <LoadingComponent width={'16'} />
            ) : (
              'SET STATUS'
            )}
          </Button>
        </Box>
      </Dialog>
    </>
  )
}
