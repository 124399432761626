import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box } from '@mui/system'
import moment from 'moment'
import { newColor } from 'src/consts/colors'
import React, { useContext, useEffect, useState } from 'react'
import LoadingComponent from '../LoadingComponent'
import { Auth0Context } from '../../contexts/Auth0Context'
import { getUserReportLogs } from '../../utils/api/queries'
import { toast } from 'react-toastify'

const YUGA_ORG_ID = 6
export interface ReportLogProps {
  ticket: any
}

export default function ReportLogs(props: ReportLogProps) {
  const { ticket } = props
  const { getTokenIfNecessary } = useContext(Auth0Context)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reportInfo, setReportInfo] = useState<any[]>([])

  useEffect(() => {
    async function getLogs() {
      setIsLoading(true)
      let params: any = {
        game_id: ticket.game_id,
        event_timestamp: ticket.created_at,
        session_id: ticket.session_id,
        delta: 1, //todo: temp: make a dropdown to select this later
      }
      let report_logs = await getUserReportLogs(getTokenIfNecessary, params)
      if (!report_logs) {
        toast.error('Could not load report logs.')
        setIsLoading(false)
        return
      }
      setReportInfo(report_logs)
      setIsLoading(false)
    }
    if (ticket != null && ticket.session_id !== null) getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LoadingComponent />
        </div>
      ) : (
        CollapsibleTable(reportInfo)
      )}
    </>
  )
}
function Row(params: any) {
  const { report } = params
  const [open, setOpen] = useState(false)
  const [sessionID, setSessionID] = useState<string>('')
  const [parsedEvent, setParsedEvent] = useState('')
  const [eventSubType, setEventSubType] = useState('')
  useEffect(() => {
    // Function to safely parse JSON
    const parseJsonSafely = (jsonString: string) => {
      try {
        return JSON.stringify(JSON.parse(jsonString), null, 2)
      } catch (error) {
        // Return a default error message or object
        return 'Invalid or non-parseable JSON'
      }
    }

    // Set parsed event, handling null or undefined cases
    setParsedEvent(
      report?.event ? parseJsonSafely(report.event) : 'No event data',
    )
    const event = JSON.parse(report?.event)
    setSessionID(event?.session_id)
    setEventSubType(event?.event_sub_type)
  }, [report?.event]) // Dependency on report.raw_event

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'unset',
            color: eventSubType === 'cs_ticket' ? 'white' : '',
            background:
              eventSubType === 'cs_ticket' ? newColor.cosmicCobalt : '',
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          {moment(report.created_at).format('YYYY-MM-DD hh:mm:ss A')}
        </TableCell>
        <TableCell align="center">{report.game_id}</TableCell>
        <TableCell align="center">{sessionID}</TableCell>
        <TableCell align="center">{report.event_type}</TableCell>
        <TableCell align="center">{eventSubType}</TableCell>
      </TableRow>
      <TableRow
        sx={{
          color: eventSubType === 'cs_ticket' ? 'white' : '',
          background: eventSubType === 'cs_ticket' ? newColor.cosmicCobalt : '',
        }}
      >
        <TableCell
          style={{ paddingBottom: open ? '1em' : 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Paper sx={{ padding: '1em' }}>
                <div>Raw Event</div>
                <div>
                  <pre className="preStyle">{parsedEvent}</pre>
                </div>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function CollapsibleTable(respData: any) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}></TableCell>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
              Created At
            </TableCell>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
              Game ID
            </TableCell>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
              Session ID
            </TableCell>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
              Event Type
            </TableCell>
            <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
              Sub Type
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {respData.map((report: any, index: number) => (
            <Row key={index} report={report} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
