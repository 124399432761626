import { styled as muiStyled } from '@mui/material'
import { newColor } from '../../../../consts/colors'
interface ComponentProps {
  children?: JSX.Element
  colorOnDark?: string | '#fff'
  colorOnLight?: string | '#000'
}

const ThemedSvg = muiStyled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'colorOnLight' && prop !== 'colorOnDark',
})<ComponentProps>(({ theme, colorOnDark, colorOnLight }) => ({
  fontSize: 0,

  '& svg, & path, & circle': {
    fill: theme.palette.mode === 'dark' ? colorOnDark : colorOnLight,
  },
}))

export default function ThemedIcon({
  children,
  colorOnDark = newColor.white,
  colorOnLight = newColor.black,
}: ComponentProps) {
  return (
    <ThemedSvg colorOnDark={colorOnDark} colorOnLight={colorOnLight}>
      {children || <></>}
    </ThemedSvg>
  )
}
