//imports
import { Routes, Route } from 'react-router-dom'
import { useContext } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'

// state
import { Auth0Context } from '../contexts/Auth0Context'
import ConfigList from '../pages/Modules/GameManagement/ConfigList'

//components
import Dashboards from './Modules/Analytics/Dashboards'
import Audiences from './Modules/Analytics/Audiences'
import Profile from './CommonPages/Profile'
import Admin from './AdminPages/Admin'
import AdminNew from './AdminPages/Admin_new'
import Onboarding from './AdminPages/Onboarding'
import ConfigHistory from '../pages/Modules/GameManagement/ConfigHistory'
import OrganizationManagement from '../pages/Modules/AccountManagement/OrganizationManagement'
import Support from '../pages/Modules/Support/Support'
import Ticket from './Modules/Support/Ticket'
import Unavailable from '../pages/CommonPages/Unavailable'
import LoadingComponent from '../components/LoadingComponent'
import AccessDenied from './CommonPages/AccessDenied'
import { Box } from '@mui/material'
import { hasAccessToModule } from '../utils/access'
import Logout from './CommonPages/Logout'
import IsEditProvider from 'src/components/organisms/v2/profile/IsEditingContext'
import {
  canSeeEmailMarketing,
  canSeeExplore,
  canSeeGDCDemo,
  isHelikaAdmin,
  isHelikaSuperAdmin,
} from '../utils/user'
import StyleGuide from './StyleGuidePages/StyleGuide'
import ActionSaveProvider from 'src/components/organisms/v2/tabs/ActionContext'
import ApiKeyManagement from '../components/apiKeyManagement'
import SocialMediaConnection from '../components/socialMediaManagement'
import EmailUnverified from './CommonPages/EmailUnverified'
import Explore from './Modules/Explore/Explore'

import NotificationsNew from './CommonPages/Notifications_new'
import GameDemo from './Modules/GDC/GameDemo'
import VisualDesigner from './Modules/Explore/VisualDesigner'
import LandingPage from './CommonPages/LandingPage'
import LLMDemo from './CommonPages/LLMDemo'
import CampaignList from './PointsV1'
import GameDemoInternal from './Modules/GDC/GameDemoInternal'
import DashboardDesigner from './Modules/Explore/DashboardDesigner'
import EngageUploadCSV from './Modules/GameManagement/EngageUploadCSV'
import EmailCampaignsList from './Modules/GameManagement/EmailCampaignsList'
import EmailTemplateBuilder from './Modules/GameManagement/EmailTemplateBuilder'
import EmailTemplateBuilderOld from './Modules/GameManagement/EmailTemplateBuilderOld'
import OfferManagement from './Modules/OfferWall/OfferManagement'
import ProjectDetail from './Modules/GameManagement/RedemptionCode/ProjectDetail'
import { ProjectDetail as ProjectBuilderLLM } from './Modules/GameManagement/Projects'
import ProjectBuilder from '../components/reactFlow/index'
import CampaignDetails from './Modules/GameManagement/RedemptionCode/components/CampaignDetails'

//----------------------------------------------------------------------------------------------------------

function Body() {
  const { isLoading, isAuthenticated } = useContext(Auth0Context)
  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)
  const USER_DATA = useSelector((state: any) => state.user)

  // if loading, show loading component
  if (isLoading || _.isEmpty(USER_DATA))
    return (
      <Routes>
        <Route path="/email-unverified" element={<EmailUnverified />} />
        {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
          process.env.REACT_APP_GDC_FLAG && (
            <Route path="/game_demo" element={<GameDemo />} />
          )}
        <Route
          path="/profile"
          element={
            <IsEditProvider>
              <Profile />
            </IsEditProvider>
          }
        />
        <Route path="/" element={<LandingPage />} />
        <Route
          path="*"
          element={
            <Box
              style={{
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
              }}
            >
              <LoadingComponent />
            </Box>
          }
        />
      </Routes>
    )

  //if unauthorized, show access denied page
  if (!isAuthenticated || !hasAccessToModule(DOMAIN, USER_DATA))
    return (
      <Routes>
        <Route path="/email-unverified" element={<EmailUnverified />} />
        {process.env.REACT_APP_ENVIRONMENT !== 'PROD' && (
          <Route path="/game_demo" element={<GameDemo />} />
        )}
        <Route
          path="/profile"
          element={
            <IsEditProvider>
              <Profile />
            </IsEditProvider>
          }
        />
        <Route path="/" element={<LandingPage />} />
        <Route
          path="*"
          element={
            <div>
              <AccessDenied />
            </div>
          }
        />
      </Routes>
    )

  let isOrgAnizationManagementEditor =
    USER_DATA?.module_access_level &&
    '4' in USER_DATA?.module_access_level &&
    USER_DATA?.module_access_level['4'] === 'editor'
  let hasHelikaAdminPrivileges =
    isHelikaAdmin(USER_DATA) || isHelikaSuperAdmin(USER_DATA)

  return (
    <Routes>
      {process.env.REACT_APP_ENVIRONMENT !== 'PROD' && (
        <Route path="/game_demo" element={<GameDemo />} />
      )}
      {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
        process.env.REACT_APP_GDC_FLAG &&
        canSeeGDCDemo(USER_DATA) && (
          <Route path="/game_demo_internal" element={<GameDemoInternal />} />
        )}
      {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
        process.env.REACT_APP_GDC_FLAG &&
        canSeeGDCDemo(USER_DATA) &&
        isHelikaSuperAdmin(USER_DATA) && (
          <Route path="/helika-ai" element={<LLMDemo />} />
        )}
      <Route path="/email-unverified" element={<EmailUnverified />} />
      <Route path="/notifications" element={<NotificationsNew />} />
      {canSeeExplore(USER_DATA) && (
        <Route path="/analytics/explorer/queries" element={<Explore />} />
      )}
      {canSeeExplore(USER_DATA) && (
        <Route
          path="/analytics/designer/visuals"
          element={<VisualDesigner />}
        />
      )}
      {canSeeExplore(USER_DATA) && (
        <Route
          path="/analytics/designer/dashboards"
          element={<DashboardDesigner />}
        />
      )}
      <Route path="/game_management/offer_wall/offers" element={<OfferManagement />} />
      <Route
        path="/analytics/audiences/audiences_csv"
        element={<Audiences />}
      />
      <Route path="/analytics/*" element={<Dashboards />} />
      <Route path="/support/user_reports/reports" element={<Support />} />
      <Route path="/support/user_reports/reports/*" element={<Ticket />} />
      <Route path="/game_management/live_ops/config" element={<ConfigList />} />
      <Route path="/game_management/email_marketing/email_campaigns" element={<EmailCampaignsList />} />
      <Route path="/game_management/email-marketing/actions" element={<EmailTemplateBuilder />} />
      <Route
        path="/game_management/redemption_codes/campaigns"
        element={<ProjectDetail />}
      />
      <Route
        path="/game_management/redemption_codes/campaigns/:campaign_id"
        element={<CampaignDetails />}
      />
      {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
       
        canSeeEmailMarketing(USER_DATA) && (
            <Route
           
            path="/email/template-builder"
           
            element={<EmailTemplateBuilderOld />}
         
          />
          )}
      <Route
        path="/game_management/live_ops/config/*"
        element={<ConfigHistory />}
      />
      <Route
        path="/game_management/points_and_rewards/points"
        element={<CampaignList />}
      />
      <Route path="/game_management/projects/:id" element={<ProjectBuilderLLM />} />
      <Route
        path="/game_management/points_and_rewards/flowchart"
        element={<ProjectBuilder />}
      />

      {hasHelikaAdminPrivileges && (
        <Route
          path="/game_management/engage/csv-upload"
          element={<EngageUploadCSV />}
        />
      )}
      {isOrgAnizationManagementEditor && (
        <>
          <Route
            path="/account_management/*"
            element={
              <ActionSaveProvider>
                <OrganizationManagement />
              </ActionSaveProvider>
            }
          />
          <Route
            path="/account_management/account_management/api_keys"
            element={<ApiKeyManagement />}
          />
          <Route
            path="/account_management/account_management/social_connection_management"
            element={<SocialMediaConnection />}
          />
        </>
      )}
      <Route
        path="/profile"
        element={
          <IsEditProvider>
            <Profile />
          </IsEditProvider>
        }
      />
      {hasHelikaAdminPrivileges && (
        <Route path="/onboarding_panel_old" element={<Onboarding />} />
      )}
      {hasHelikaAdminPrivileges && (
        <Route path="/admin_panel_old" element={<Admin />} />
      )}
      {hasHelikaAdminPrivileges && (
        <Route
          path="/admin_panel"
          element={
            <ActionSaveProvider>
              <AdminNew />
            </ActionSaveProvider>
          }
        />
      )}
      {hasHelikaAdminPrivileges && (
        <Route path="/styleguide" element={<StyleGuide />} />
      )}
      <Route path="/points_v1" element={<CampaignList />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<Unavailable />} />
    </Routes>
  )
}

export default Body
