import React, { useState, useEffect } from 'react'
import {
  Stack,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Divider,
  useTheme,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CustomSwitch from './CustomSwitch'
import { newColor, blackAndWhite } from 'src/consts/colors'
import { RedemptionCode } from 'src/models'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// Styled Save button component with dynamic theme
const StyledSaveButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    height: '36px',
    minWidth: '110px',
    maxHeight: '36px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:
      themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    color: newColor.white,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    },
    fontFamily: 'Open Sans, sans-serif',
  }),
)

// Loading spinner to show within the buttons
const ButtonLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: 'white',
  position: 'absolute',
  width: '24px !important',
  height: '24px !important',
}))

// Cancel button styling with dynamic theme
const StyledCancelButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    padding: '8px 16px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontFamily: 'Open Sans, sans-serif',
  }),
)

// Icon Button styling with dynamic color based on theme mode
const StyledIconButton = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  width: '32px',
  height: '32px',
  minWidth: '32px',
  maxWidth: '32px',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#434D5A' : '#C0C0C0'}`,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

// Back Icon styling with dynamic color based on theme mode
const StyledBackIcon = styled(ArrowBackIosIcon)(({ theme }) => ({
  position: 'relative',
  left: '5px',
  gap: '0px',
  color: theme.palette.mode === 'dark' ? '#D2D2D3' : '#545454',
}))

// Helper text styling
const errorHelperTextStyles = {
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  color: newColor.error,
  marginLeft: 0,
}

const infoHelperTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '140%',
  color: themeMode === 'dark' ? '#D2D2D3' : '#545454',
  marginLeft: 0,
})

// Breadcrumbs container styling
const BreadcrumbsContainer = styled('div')({
  width: '465px',
  height: '40px',
  padding: '16px 32px 4px 0px',
})

interface CreateRedemptionCodePageProps {
  onSave: (redemptionCodeData: any) => void
  onBack: () => void
  onClickBreadCrumbs: () => void
  campaignName: string
  campaignId: number
  existingCodeData?: RedemptionCode | null
}

const CreateRedemptionCodePage: React.FC<CreateRedemptionCodePageProps> = ({
  onSave,
  onBack,
  onClickBreadCrumbs,
  campaignName,
  campaignId,
  existingCodeData,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode

  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [rootUrl, setRootUrl] = useState('')
  const [usageLimit, setUsageLimit] = useState(1)
  const [status, setStatus] = useState(true)
  const [jsonData, setJsonData] = useState('{}')
  const [isReferral, setIsReferral] = useState(true)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [codeError, setCodeError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [usageLimitError, setUsageLimitError] = useState(false)
  const [jsonError, setJsonError] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (existingCodeData) {
      setCode(existingCodeData.code || '')
      setName(existingCodeData.name || '')
      setRootUrl(existingCodeData.baseUrl || '')
      setUsageLimit(existingCodeData.usageLimit || 1)
      setStatus(existingCodeData.status === 'active')
      setJsonData(JSON.stringify(existingCodeData.rewards, null, 2) || '[]')
      setIsReferral(existingCodeData.type === 'referral')
      setStartDate(
        existingCodeData.startDate ? dayjs(existingCodeData.startDate) : null,
      )
      setEndDate(
        existingCodeData.endDate ? dayjs(existingCodeData.endDate) : null,
      )
    }
  }, [existingCodeData])

  const nameRegex = /^[A-Za-z0-9\s\-()[\]]+$/

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (nameRegex.test(newValue) || newValue === '') {
      setName(newValue)
      setNameError(!newValue)
    }
  }

  const handleSave = async () => {
    let isValid = true
    let rewards

    if (!code) {
      setCodeError(true)
      isValid = false
    } else {
      setCodeError(false)
    }

    if (!name) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!usageLimit || isNaN(usageLimit) || usageLimit < 1) {
      setUsageLimitError(true)
      isValid = false
    } else {
      setUsageLimitError(false)
    }

    try {
      rewards = JSON.parse(jsonData)
      if (!Array.isArray(rewards)) {
        rewards = [rewards]
      }
      setJsonError(false)
    } catch (error) {
      setJsonError(true)
      isValid = false
    }

    if (isValid) {
      const newRedemptionCode = {
        campaign_id: campaignId,
        name: name,
        code: code,
        base_url: rootUrl,
        type: isReferral ? 'referral' : 'redeem',
        usage_limit: usageLimit,
        status: status ? 'active' : 'archived',
        rewards: rewards,
        start_date: startDate?.toISOString(),
        end_date: endDate?.toISOString(),
      }

      setIsSaving(true)

      try {
        await onSave(newRedemptionCode)
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        direction="column"
        gap={2}
        sx={{
          maxWidth: { xs: '100%', md: '90%' },
          width: '100%',
          height: '100%',
          position: 'relative',
          margin: '0px auto',
          padding: 3,
          backgroundColor:
            themeMode === 'dark' ? blackAndWhite.grey900 : 'transparent',
        }}
      >
        <Stack
          direction="column"
          sx={{ width: '100%', maxWidth: '600px', alignItems: 'flex-start' }}
        >
          <BreadcrumbsContainer>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: '12px',
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                  }}
                />
              }
              sx={{
                alignItems: 'center',
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '14px',
                color: themeMode === 'dark' ? newColor.white : newColor.black,
                whiteSpace: 'nowrap',
              }}
            >
              <Link
                component="button"
                underline="hover"
                onClick={onClickBreadCrumbs}
                sx={{
                  ...typography.paragraph_small,
                  height: '20px',
                  color: themeMode === 'dark' ? '#D2D2D3' : '#545454',
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    textDecoration: 'underline',
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                  },
                }}
              >
                Redemption Codes
              </Link>
              <Link
                component="button"
                underline="hover"
                onClick={onClickBreadCrumbs}
                sx={{
                  ...typography.paragraph_small,
                  height: '20px',
                  color: themeMode === 'dark' ? '#D2D2D3' : '#545454',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                  },
                }}
              >
                Campaign
              </Link>
              <Typography
                color="text.primary"
                sx={{
                  ...typography.label_small,
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  whiteSpace: 'nowrap',
                }}
              >
                {campaignName}
              </Typography>
            </Breadcrumbs>
          </BreadcrumbsContainer>

          <Typography
            fontSize={35}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            {existingCodeData
              ? 'Edit Redemption Code'
              : 'Create New Redemption Code'}
          </Typography>

          <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            <StyledIconButton onClick={onBack} disabled={isSaving}>
              <StyledBackIcon />
            </StyledIconButton>
            <Typography
              sx={{
                ml: 1,
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '140%',
                color: themeMode === 'dark' ? newColor.white : newColor.black,
                fontFamily: 'Open Sans, sans-serif',
              }}
            >
              Redemption Codes
            </Typography>
          </Stack>
        </Stack>

        <Stack
          spacing={4}
          sx={{ width: '100%', maxWidth: '600px', alignSelf: 'center' }}
        >
          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Code
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
              if (e.target.value) setCodeError(false)
            }}
            error={codeError}
            disabled={isSaving}
            helperText={codeError ? 'Code is required' : ''}
            sx={{ marginTop: '0px !important' }}
            InputProps={{
              sx: {
                width: '552px',
                height: '36px',
                gap: '0px',
                borderRadius: '4px',
                border: '1px solid #434D5A',
                backgroundColor: themeMode === 'dark' ? '#1C2025' : '#FFFFFF',
                fontFamily: 'Open Sans, sans-serif',
                '& fieldset': {
                  border: '1px solid transparent',
                },
                '&:hover fieldset': {
                  borderColor: '#434D5A',
                },
              },
            }}
          />
          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Name
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={name}
            onChange={handleNameChange}
            error={nameError}
            disabled={isSaving}
            helperText={
              nameError ? (
                <Typography sx={errorHelperTextStyles}>
                  Name is required
                </Typography>
              ) : (
                <Typography sx={infoHelperTextStyles(themeMode)}>
                  Add a descriptive name for the code for{' '}
                  <span style={{ fontWeight: 700 }}>internal use</span>.
                </Typography>
              )
            }
            sx={{ marginTop: '0px !important' }}
            InputProps={{
              sx: {
                width: '552px',
                height: '36px',
                gap: '0px',
                borderRadius: '4px',
                border: '1px solid #434D5A',
                backgroundColor: themeMode === 'dark' ? '#1C2025' : '#FFFFFF',
                fontFamily: 'Open Sans, sans-serif',
                '& fieldset': {
                  border: '1px solid transparent',
                },
                '&:hover fieldset': {
                  borderColor: '#434D5A',
                },
              },
            }}
          />
          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Root URL (Optional)
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={rootUrl}
            onChange={(e) => setRootUrl(e.target.value)}
            disabled={isSaving}
            sx={{ marginTop: '0px !important' }}
            InputProps={{
              sx: {
                width: '552px',
                height: '36px',
                gap: '0px',
                borderRadius: '4px',
                border: '1px solid #434D5A',
                backgroundColor: themeMode === 'dark' ? '#1C2025' : '#FFFFFF',
                fontFamily: 'Open Sans, sans-serif',
                '& fieldset': {
                  border: '1px solid transparent',
                },
                '&:hover fieldset': {
                  borderColor: '#434D5A',
                },
              },
            }}
          />
          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Code URL
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={rootUrl ? `${rootUrl}?code=${code}` : ''}
            disabled
            sx={{ marginTop: '0px !important' }}
            InputProps={{
              sx: {
                width: '552px',
                height: '36px',
                gap: '0px',
                borderRadius: '4px',
                backgroundColor:
                  themeMode === 'dark' ? '#141414' : blackAndWhite.grey100,
                fontFamily: 'Open Sans, sans-serif',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .Mui-disabled': {
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                },
              },
              endAdornment: rootUrl && (
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(`${rootUrl}?code=${code}`)
                  }
                >
                  <ContentCopyIcon
                    sx={{
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                    }}
                  />
                </Button>
              ),
            }}
          />

          <Typography
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            Start Date
          </Typography>
          <DateTimePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            format="YYYY-MM-DD hh:mm A"
            ampm
            slotProps={{
              textField: {
                fullWidth: true,
                InputProps: {
                  sx: {
                    height: '36px',
                    width: '552px',
                    backgroundColor:
                      themeMode === 'dark' ? newColor.gunmetalGray : '#FFFFFF',
                    border: `1px solid ${
                      themeMode === 'dark'
                        ? blackAndWhite.grey600
                        : blackAndWhite.grey300
                    }`,
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },
                },
              },
              popper: {
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiPickersYear-yearButton': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiMenuItem-root': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiClock-amButton, & .MuiClock-pmButton': {
                    backgroundColor: newColor.jazzberryJam,
                    color: newColor.white,
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: newColor.jazzberryJam,
                    },
                  },
                  '& .MuiPickersClock-pointer': {
                    backgroundColor: newColor.jazzberryJam,
                  },
                },
              },
            }}
          />

          <Typography
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            End Date
          </Typography>
          <DateTimePicker
            value={endDate}
            onChange={(date) => setEndDate(date)}
            minDate={startDate}
            format="YYYY-MM-DD hh:mm A"
            ampm
            slotProps={{
              textField: {
                fullWidth: true,
                InputProps: {
                  sx: {
                    height: '36px',
                    width: '552px',
                    backgroundColor:
                      themeMode === 'dark' ? newColor.gunmetalGray : '#FFFFFF',
                    border: `1px solid ${
                      themeMode === 'dark'
                        ? blackAndWhite.grey600
                        : blackAndWhite.grey300
                    }`,
                    borderRadius: '4px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  },
                },
              },
              popper: {
                sx: {
                  '& .MuiPickersDay-root': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiPickersYear-yearButton': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiMenuItem-root': {
                    '&.Mui-selected': {
                      backgroundColor: newColor.jazzberryJam,
                      color: newColor.white,
                      borderRadius: '4px',
                      '&:hover': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                  '& .MuiClock-amButton, & .MuiClock-pmButton': {
                    backgroundColor: newColor.jazzberryJam,
                    color: newColor.white,
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: newColor.jazzberryJam,
                    },
                  },
                  '& .MuiPickersClock-pointer': {
                    backgroundColor: newColor.jazzberryJam,
                  },
                },
              },
            }}
          />

          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Usage Limit
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            value={usageLimit}
            onChange={(e) => {
              const value = Number(e.target.value)
              if (!isNaN(value) && value >= 1) {
                setUsageLimit(value)
                setUsageLimitError(false)
              }
            }}
            InputProps={{
              inputProps: {
                min: 1,
                inputMode: 'numeric',
                pattern: '[0-9]*',
                style: {
                  MozAppearance: 'textfield',
                  paddingLeft: '8px',
                },
              },
              endAdornment: (
                <Stack
                  direction="column"
                  spacing={0}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    '& .MuiIconButton-root:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <IconButton
                    size="small"
                    sx={{
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                      padding: '2px',
                      height: '12px',
                      width: '12px',
                    }}
                    onClick={() =>
                      setUsageLimit((prev) => Math.max(1, prev + 1))
                    }
                  >
                    <KeyboardArrowUpIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                      padding: '2px',
                      height: '12px',
                      width: '12px',
                    }}
                    onClick={() =>
                      setUsageLimit((prev) => Math.max(1, prev - 1))
                    }
                  >
                    <KeyboardArrowDownIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              ),
              sx: {
                minWidth: '50px',
                width: `${Math.max(50, String(usageLimit).length * 10 + 30)}px`,
                height: '36px',
                borderRadius: '4px',
                backgroundColor: themeMode === 'dark' ? '#1C2025' : '#FFFFFF',
                fontFamily: 'Open Sans, sans-serif',
                '& fieldset': {
                  border: '1px solid transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
            error={usageLimitError}
            helperText={
              usageLimitError ? (
                <Typography sx={errorHelperTextStyles}>
                  Usage limit must be greater than 0
                </Typography>
              ) : (
                ''
              )
            }
            disabled={isSaving}
          />
          <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
            <CustomSwitch
              checked={status}
              onChange={() => setStatus(!status)}
              disabled={isSaving}
            />
            <Typography
              sx={{
                ml: 2,
                ...typography.paragraph_regular,
                color: themeMode === 'dark' ? newColor.white : newColor.black,
              }}
            >{`Code status is ${status ? 'Active' : 'Inactive'}`}</Typography>
          </Stack>
          <Stack sx={{ mt: 2 }}>
            <Typography
              sx={{
                ...typography.label_regular,
                color: themeMode === 'dark' ? newColor.white : newColor.black,
              }}
            >
              Rewards (JSON Format)
            </Typography>
            <TextField
              variant="outlined"
              multiline
              minRows={8}
              value={jsonData}
              onChange={(e) => {
                setJsonData(e.target.value)
                try {
                  JSON.parse(e.target.value)
                  setJsonError(false)
                } catch {
                  setJsonError(true)
                }
              }}
              helperText={
                jsonError ? (
                  <Typography sx={errorHelperTextStyles}>
                    Invalid JSON format
                  </Typography>
                ) : (
                  ''
                )
              }
              error={jsonError}
              disabled={isSaving}
              InputProps={{
                sx: {
                  backgroundColor: themeMode === 'dark' ? '#1C2025' : '#FFFFFF',
                  borderRadius: '4px',
                  height: 'auto',
                  width: '552px',
                  fontFamily: 'Open Sans, sans-serif',
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #434D5A',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#434D5A',
                  },
                },
              }}
              inputProps={{
                style: {
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                },
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
            <CustomSwitch
              checked={isReferral}
              onChange={() => setIsReferral(!isReferral)}
              disabled={isSaving}
            />
            <Typography
              sx={{
                ml: 2,
                ...typography.paragraph_regular,
                color: themeMode === 'dark' ? newColor.white : newColor.black,
              }}
            >
              Use as referral code
            </Typography>
          </Stack>
          <Divider
            sx={{
              my: 4,
              backgroundColor:
                themeMode === 'dark' ? newColor.gunMetal : newColor.lightGray,
              height: '1px',
            }}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <StyledCancelButton
              onClick={onBack}
              themeMode={themeMode}
              disabled={isSaving}
              sx={{
                minWidth: '110px',
                height: '36px',
                color: themeMode === 'dark' ? newColor.white : newColor.black,
                backgroundColor: 'transparent',
                border: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                marginRight: '8px',
              }}
            >
              Cancel
            </StyledCancelButton>
            <StyledSaveButton
              onClick={handleSave}
              themeMode={themeMode}
              disabled={isSaving}
            >
              {isSaving ? (
                <ButtonLoadingSpinner size={24} />
              ) : (
                <Typography
                  sx={{
                    color: 'var(--Color-Text-Inverse, #FFF)',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '140%',
                    textTransform: 'none',
                  }}
                >
                  Save
                </Typography>
              )}
            </StyledSaveButton>
          </Stack>
        </Stack>
      </Stack>
    </LocalizationProvider>
  )
}

export default CreateRedemptionCodePage
