import { Switch as MuiSwitch, Theme, SwitchProps } from '@mui/material'
import { styled } from '@mui/system'
import {
  color_base,
  colors_semantic,
} from 'helika-ui-sdk/dist/src/values/Colors'

// CustomSwitch component with helika-ui-sdk colors
const CustomSwitch = styled(MuiSwitch, {
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<SwitchProps & { darkMode?: boolean }>(({ darkMode }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 11,
    backgroundColor: darkMode
      ? `${color_base.grey500} !important`
      : `${color_base.grey400} !important`, // Inactive state background color
    opacity: '1 !important', // Force opacity to be 1
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        color_base.white,
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        color_base.grey600,
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: `${color_base.white} !important`, // Thumb color for both active and inactive
    width: 16,
    height: 16,
    margin: 2,
    opacity: '1 !important', // Force thumb opacity
  },
  '& .Mui-checked': {
    '& .MuiSwitch-thumb': {
      backgroundColor: `${color_base.white} !important`, // Thumb color for active state
      opacity: '1 !important',
    },
    '& + .MuiSwitch-track': {
      backgroundColor: `${colors_semantic.background_int_primary} !important`, // Active state background color
      opacity: '1 !important',
    },
  },
  '& .MuiSwitch-switchBase': {
    '&:hover': {
      backgroundColor: 'transparent !important', // Disable hover overlay
    },
    '&:focus': {
      backgroundColor: 'transparent !important', // Disable focus overlay
    },
    '&:active': {
      backgroundColor: 'transparent !important', // Disable active state overlay
    },
  },
}))

export default CustomSwitch
