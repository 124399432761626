import {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'
import { setOrgModuleAccessApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SmartIcon from '../../assets/Smart_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import SmartIconDark from '../../assets/Smart_Icon_dark.svg'
import { useSelector } from 'react-redux'
import { isHelikaAdmin, isHelikaSuperAdmin } from '../../utils/user'
import { USER_ROLES } from '../../consts/backend'
import AnalyticsIcon from '../../assets/analytics_icon.svg'
import SupportIcon from '../../assets/support_header_icon.svg'
import AccountIcon from '../../assets/support_header_ban_game_icon.svg'
import GameEditIcon from '../../assets/account_management_icon.svg'
import OrgIcon from '../../assets/Organization_Icon.svg'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '135px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectListCategory = muiStyled('select')(({ theme }) => ({
  width: '250px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
  modules_access_level?: string[]
}
interface OrgUser {
  label: string
  value: string
  module_access_level?: { [key: string]: string }
}

export default function SetOrgModuleAccess({
  orgs,
  enableOrgDropdown,
  getOrgs
}: {
  orgs: any
  enableOrgDropdown?: boolean,
  getOrgs: any
}) {
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)
  const { organization_id } = useSelector((state: any) => state.user)

  const [analyticsData, setAnalyticsData] = useState('none')
  const [supportData, setSupportData] = useState('none')
  const [accountData, setAccountData] = useState('none')
  const [gameEditData, setGameEditData] = useState('none')
  const [org, setOrg] = useState<Org | null>(null)

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    if (enableOrgDropdown) {
      setOrg({
        label: orgs[0].label,
        value: orgs[0].value,
        modules_access_level: orgs[0].modules_access_level
      })
      if (orgs[0].modules_access_level) {
        setAnalyticsData(orgs[0].modules_access_level['1'] || 'none')
        setSupportData(orgs[0].modules_access_level['2'] || 'none')
        setAccountData(orgs[0].modules_access_level['3'] || 'none')
        setGameEditData(orgs[0].modules_access_level['4'] || 'none')
      } else {
        setAnalyticsData('none')
        setSupportData('none')
        setAccountData('none')
        setGameEditData('none')
      }
    } else {
      const userOrg = orgs.find((org: any) => org.value === organization_id)
      setOrg(
        userOrg
          ? {
              label: userOrg.label,
              value: userOrg.value,
              modules_access_level: userOrg.modules_access_level
            }
          : null,
      )
      if (userOrg.modules_access_level) {
        setAnalyticsData(userOrg.modules_access_level['1'] || 'none')
        setSupportData(userOrg.modules_access_level['2'] || 'none')
        setAccountData(userOrg.modules_access_level['3'] || 'none')
        setGameEditData(userOrg.modules_access_level['4'] || 'none')
      } else {
        setAnalyticsData('none')
        setSupportData('none')
        setAccountData('none')
        setGameEditData('none')
      }
    }
  }, [orgs, enableOrgDropdown, organization_id])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org: Org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
      if (newOption && newOption.modules_access_level) {
        setAnalyticsData(newOption.modules_access_level['1'] || 'none')
        setSupportData(newOption.modules_access_level['2'] || 'none')
        setAccountData(newOption.modules_access_level['3'] || 'none')
        setGameEditData(newOption.modules_access_level['4'] || 'none')
      } else {
        setAnalyticsData('none')
        setSupportData('none')
        setAccountData('none')
        setGameEditData('none')
      }
    }
  }

  let userRolesAvailable: {
    viewer: string
    admin: string
    helika_admin?: string
    helika_super_admin?: string
  } = { ...USER_ROLES }

  if (isHelikaSuperAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
    userRolesAvailable.helika_super_admin = 'helika_super_admin'
  } else if (isHelikaAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
  }

  const onChangeAnalyticsData = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setAnalyticsData(e?.target.value)
    }
  }
  const onChangeSupportData = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setSupportData(e?.target.value)
    }
  }
  const onChangeAccountData = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setAccountData(e?.target.value)
    }
  }
  const onChangeGameEditData = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setGameEditData(e?.target.value)
    }
  }

  const onSaveHandler = async () => {
    if (org?.value) {
      setLoading(true)
      let params = {
        '1': analyticsData,
        '2': supportData,
        '3': accountData,
        '4': gameEditData,
      }
      await setOrgModuleAccessApi(patchWithAccessToken, org?.value, params)
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success('Successful Saved Data')
            getOrgs()
          }
        })
        .catch((err: any) => {
          console.error(err)
        })
      setLoading(false)
    } else {
      toast.warning(`Please select Organization`)
      setLoading(false)
    }
  }

  return (
    <StyledAccordion className="set-module-access-accordion">
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? SmartIcon : SmartIconDark}
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;SET MODULE ACCESS</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center', minWidth: '90em' }}>
        <Grid container spacing={1} sx={{ marginBottom: '8px' }}>
          <Grid item xs={12}>
            <Box
              className="subCircularPageHeading dark"
              sx={{
                marginX: '14px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid item sm={4} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: newColor.white,
                      fontSize: '15px',
                      fontWeight: 600,
                      lineHeight: '19px',
                      textTransform: 'upperCase',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: '8px',
                    }}
                  >
                    <img
                      src={OrgIcon}
                      width={'24px'}
                      height={'24px'}
                      alt="user"
                    />
                    ORGANIZATION
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={2} lg={2}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: newColor.white,
                      fontSize: '15px',
                      fontWeight: 600,
                      lineHeight: '19px',
                      textTransform: 'upperCase',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      gap: '8px',
                    }}
                  >
                    <img
                      src={AnalyticsIcon}
                      width={'24px'}
                      height={'24px'}
                      alt="user"
                    />
                    ANALYTICS
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={2} lg={2}>
                <Typography
                  sx={{
                    color: newColor.white,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    textTransform: 'upperCase',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '8px',
                  }}
                >
                  <img
                    src={GameEditIcon}
                    width={'24px'}
                    height={'24px'}
                    alt="user"
                  />
                  Game management
                </Typography>
              </Grid>
              <Grid item sm={2} lg={2}>
                <Typography
                  sx={{
                    color: newColor.white,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    textTransform: 'upperCase',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '8px',
                  }}
                >
                  <img
                    src={SupportIcon}
                    width={'24px'}
                    height={'24px'}
                    alt="user"
                  />
                  SUPPORT
                </Typography>
              </Grid>
              <Grid item sm={2} lg={2}>
                <Typography
                  sx={{
                    color: newColor.white,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    textTransform: 'upperCase',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '8px',
                  }}
                >
                  <img
                    src={AccountIcon}
                    width={'24px'}
                    height={'24px'}
                    alt="user"
                  />
                  Account management
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                marginX: '14px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: '38px',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.darkSlate
                    : newColor.cosmicLatte,
                paddingY: '4px',
              }}
            >
              <Grid item sm={4} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {enableOrgDropdown && (
                    <SelectListCategory
                      value={org?.value}
                      onChange={setSelectedOrg}
                    >
                      <option disabled value="">
                        {' '}
                        -- select an option --{' '}
                      </option>
                      {orgs
                        ?.sort((a: any, b: any) =>
                          a.label?.toLowerCase() > b.label?.toLowerCase()
                            ? 1
                            : -1,
                        )
                        .map((org: Org) => (
                          <option key={org.value} value={org.value}>
                            {org.label}
                          </option>
                        ))}
                    </SelectListCategory>
                  )}
                </Box>
              </Grid>
              <Grid item sm={2} lg={2}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SelectCategory
                    required
                    value={analyticsData}
                    onChange={onChangeAnalyticsData}
                  >
                    <option defaultValue={'none'} value={'none'}>
                      NONE
                    </option>
                    <option value={'viewer'}>VIEWER</option>
                    <option value={'editor'}>EDITOR</option>
                  </SelectCategory>
                </Box>
              </Grid>
              <Grid item sm={2} lg={2}>
                <SelectCategory
                  required
                  value={supportData}
                  onChange={onChangeSupportData}
                >
                  <option defaultValue={'none'} value={'none'}>
                    NONE
                  </option>
                  <option value={'viewer'}>VIEWER</option>
                  <option value={'editor'}>EDITOR</option>
                </SelectCategory>
              </Grid>
              <Grid item sm={2} lg={2}>
                <SelectCategory
                  required
                  value={accountData}
                  onChange={onChangeAccountData}
                >
                  <option defaultValue={'none'} value={'none'}>
                    NONE
                  </option>
                  <option value={'viewer'}>VIEWER</option>
                  <option value={'editor'}>EDITOR</option>
                </SelectCategory>
              </Grid>
              <Grid item sm={2} lg={2}>
                <SelectCategory
                  required
                  value={gameEditData}
                  onChange={onChangeGameEditData}
                >
                  <option defaultValue={'none'} value={'none'}>
                    NONE
                  </option>
                  <option value={'viewer'}>VIEWER</option>
                  <option value={'editor'}>EDITOR</option>
                </SelectCategory>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Button
          onClick={onSaveHandler}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Save</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
