import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Typography,
  useTheme,
  styled as muiStyled,
  Button,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from '@mui/material'

import { Box } from '@mui/material'
import {
  assignUserApi,
  getOrgUsers,
  getUserReportLogs,
  getUserReportsByID,
  setSupportTicketStatus,
} from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import 'src/App.css'
import ticket_icon from 'src/assets/support_ticket_header_icon.svg'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import PageContainer from 'src/components/atoms/v2/pageContainer'
import { SUPPORT_TICKET_STATUSES } from 'src/consts/backend'
import {
  SupportTicketLogsIcon,
  SupportTicketMessagesIcon,
} from 'src/components/svgCustom/customIcon'
import TicketInformation from 'src/components/supportTicket/TicketInformation'
import MessagingUI from 'src/components/supportTicket/MessagingUI'
import ReportLogs from 'src/components/supportTicket/ReportLogs'
import './custom-toast.style.css'

const TicketCard = muiStyled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
  borderRadius: '0.625rem',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  height: 'calc(100% - 97px)',
}))

const YUGA_ORG_ID = 6
export interface SimpleDialogProps {
  users?: any
}
export interface SupportConfig {
  organization_url: string
  support_email: string
  support_email_header: string
  footer: string
}

export default function Ticket(props: SimpleDialogProps) {
  const theme = useTheme()
  const location = useLocation()

  const { getTokenIfNecessary, getWithAccessToken, patchWithAccessToken } =
    useContext(Auth0Context)

  const [users, setUsers] = useState<any[]>([])
  const [ticket, setTicket] = useState<any>(null)
  const [ticketInfo, setTicketInfo] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingUserReport, setIsLoadingUserReport] = useState<boolean>(false)
  const [isShowMessagesOrLogs, setIsShowMessagesOrLogs] = useState<
    'messages' | 'logs'
  >('messages')
  const [assignedToEmail, setAssignedToEmail] = useState<any>(null)
  const [ticketStatus, setTicketStatus] = useState<any>(null)
  const [filterMessages, setFilterMessages] = useState<string>('all')

  //get users to assgin
  useEffect(() => {
    async function getUsers() {
      let userList = await getOrgUsers(getTokenIfNecessary)
      setUsers(userList)
    }
    try {
      getUsers()
    } catch (e: any) {
      toast.error(`Unable to get users`)
    }
  }, [getTokenIfNecessary])

  //get support ticket by its ID
  useEffect(() => {
    async function getUserReport() {
      const userReportID = location.pathname.split('/').pop()
      setIsLoading(true)
      setIsLoadingUserReport(true)
      const userReport = await getUserReportsByID(
        getWithAccessToken,
        userReportID,
      )
      setTicket(userReport)
      setIsLoadingUserReport(false)
    }
    getUserReport()
  }, [location.pathname])

  //get support ticket logs
  useEffect(() => {
    async function getLogs() {
      let params: any = {
        game_id: ticket.game_id,
        event_timestamp: ticket.created_at,
        wallet_address: ticket.reported_by,
        delta: 1, //todo: temp: make a dropdown to select this later
      }
      let ticket_logs = await getUserReportLogs(getTokenIfNecessary, params)
      if (!ticket_logs) {
        toast.error('Could not load ticket logs.')
        setIsLoading(false)
        return
      }
      setTicketInfo(ticket_logs)

      setIsLoading(false)
    }
    if (ticket != null && ticket.org_id === YUGA_ORG_ID) {
      getLogs()
    }
    if (ticket != null) setTicketStatus(ticket.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket])

  useEffect(() => {
    setAssignedToEmail(ticket?.assigned_to)
  }, [ticket, users])

  const setNewStatusForTicket = async (newStatusForMultiSelect: string) => {
    if (!newStatusForMultiSelect) {
      toast.error('Must select a new status')
      return
    }

    let support_ticket_ids = [ticket?.id]
    let isCompleted = await setSupportTicketStatus(
      patchWithAccessToken,
      newStatusForMultiSelect,
      support_ticket_ids,
    )
    if (isCompleted) {
      toast.success(`Support Ticket status updated successfully.`)
      setTicketStatus(newStatusForMultiSelect)
      return
    } else {
      toast.error('Support Ticket could not be updated.')
    }
  }

  const handleNewAssigneeForTicket = async (newAssignee: number) => {
    if (!newAssignee) {
      toast.error('Must select a new assignee')
      return
    }
    let params = {
      support_ticket_ids: [ticket?.id],
    }
    let isCompleted = await assignUserApi(
      patchWithAccessToken,
      newAssignee,
      params,
    )
    if (isCompleted) {
      toast.success('Support Ticket assigned successfuly.', {
        position: 'top-left',
      })
      setAssignedToEmail(newAssignee)
      return
    } else {
      toast.error('Support Ticket could not be assigned.')
    }
  }
  const renderPageHeader = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          overflowX: 'clip',
          height: 'fit-content',
          margin: 0,
          padding: '15px 40px 15px 15px',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            padding: '0 1em 0 1em',
            maxWidth: '100%',
            height: 'fit-content',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={ticket_icon}
            alt="Logs Icon"
            style={{ margin: 'auto 0.3em auto 0', height: '1.5em' }}
          />
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: 'white',
              margin: 'auto 0 auto 1em',
              fontSize: '1.5em',
              fontWeight: 700,
            }}
          >
            TICKET ID: {location.pathname.split('/').pop()}
          </Typography>
        </Box>
        <Box>
          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p
              style={{
                fontSize: '0.8em',
                margin: 0,
                textAlign: 'left',
                color: 'white',
              }}
            >
              Assignee:
            </p>
            <Select
              required
              value={ticket && assignedToEmail}
              onChange={(e) => {
                handleNewAssigneeForTicket(e.target.value)
              }}
              size="small"
              style={{
                height: '2em',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.black
                    : newColor.white,
              }}
            >
              {users
                ?.sort((a: any, b: any) =>
                  (a?.name && a?.name !== 'None'
                    ? a?.name
                    : a?.email
                  )?.toLowerCase() >
                  (b?.name && b?.name !== 'None'
                    ? b?.name
                    : b.email
                  )?.toLowerCase()
                    ? 1
                    : -1,
                )
                ?.map((user: any) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user?.name && user?.name !== 'None'
                        ? user?.name
                        : user.email}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p
              style={{
                fontSize: '0.8em',
                margin: 0,
                textAlign: 'left',
                color: 'white',
              }}
            >
              Status:
            </p>
            <Select
              required
              value={ticket && ticketStatus}
              size="small"
              style={{
                height: '2em',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.black
                    : newColor.white,
              }}
              onChange={(e) => {
                setNewStatusForTicket(String(e.target.value))
              }}
            >
              <MenuItem disabled={true} value={undefined}>
                Select a new Status
              </MenuItem>
              {Object.entries(SUPPORT_TICKET_STATUSES).map(([value, name]) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }

  const renderTicketRightSideHeader = () => {
    return (
      <Stack
        justifyContent="space-between"
        direction="row"
        paddingBottom={'19px'}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap=""
          alignItems="end"
        >
          <Button onClick={() => setIsShowMessagesOrLogs('messages')}>
            <Box
              sx={{
                margin: '0 0.625em 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SupportTicketMessagesIcon
                color={
                  isShowMessagesOrLogs === 'messages'
                    ? newColor.jazzberryJam
                    : theme.palette.text.primary
                }
              />
            </Box>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color:
                  isShowMessagesOrLogs === 'messages'
                    ? newColor.jazzberryJam
                    : theme.palette.text.primary,
                margin: 'auto 0 auto 0',
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              MESSAGES
            </Typography>
          </Button>
          {ticket && ticket.session_id && (
            <Button onClick={() => setIsShowMessagesOrLogs('logs')}>
              <Box
                sx={{
                  margin: '0 0.625em 0 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SupportTicketLogsIcon
                  color={
                    isShowMessagesOrLogs === 'logs'
                      ? newColor.jazzberryJam
                      : theme.palette.text.primary
                  }
                />
              </Box>
              <Typography
                className="ellipsisWithWrap"
                sx={{
                  color:
                    isShowMessagesOrLogs === 'logs'
                      ? newColor.jazzberryJam
                      : theme.palette.text.primary,
                  margin: 'auto 0 auto 0',
                  fontSize: '0.875em',
                  fontWeight: 400,
                }}
              >
                LOGS
              </Typography>
            </Button>
          )}
        </Stack>
        {isShowMessagesOrLogs === 'logs' ? (
          <Box sx={{ height: '3.2rem' }}></Box>
        ) : (
          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p
              style={{
                fontSize: '0.8em',
                margin: 0,
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
            >
              Show
            </p>
            <Select
              required
              size="small"
              style={{
                height: '2em',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.black
                    : newColor.white,
              }}
              onChange={(e) => {
                setFilterMessages(e.target.value)
              }}
              value={filterMessages}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="emails">Emails</MenuItem>
              <MenuItem value="notes">Internal Notes</MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    )
  }

  return (
    <PageContainer>
      {renderPageHeader()}
      <TicketCard>
        <TicketInformation ticket={ticket} />
        <Box
          sx={{
            padding: '27px 20px 21px 12px ',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {renderTicketRightSideHeader()}
          {isShowMessagesOrLogs === 'messages' ? (
            <MessagingUI
              ticket={ticket}
              filterMessages={filterMessages}
              users={users}
              assignedToEmail={assignedToEmail}
              onChangeAssignee={setAssignedToEmail}
            />
          ) : (
            <ReportLogs ticket={ticket} />
          )}
        </Box>
      </TicketCard>
    </PageContainer>
  )
}
