import React from 'react'
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  Typography,
  Stack,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/WarningAmber'
import { newColor } from 'src/consts/colors'

// Snackbar styles for different variants, using newColor for dynamic color application
const snackbarStyles: {
  [key: string]: { borderColor: string; icon: React.ReactNode }
} = {
  success: {
    borderColor: newColor.shamrock,
    icon: <CheckCircleIcon sx={{ color: newColor.shamrock }} />,
  },
  error: {
    borderColor: newColor.error,
    icon: <ErrorIcon sx={{ color: newColor.error }} />,
  },
  warning: {
    borderColor: newColor.byzantium,
    icon: <WarningIcon sx={{ color: newColor.byzantium }} />,
  },
  default: {
    borderColor: newColor.jet,
    icon: null,
  },
}

interface ReusableSnackbarProps {
  open: boolean
  message: string
  variant: 'success' | 'error' | 'warning' | 'default'
  handleClose: () => void
}

// ReusableSnackbar component
const ReusableSnackbar: React.FC<ReusableSnackbarProps> = ({
  open,
  message,
  variant,
  handleClose,
}) => {
  const { borderColor, icon } =
    snackbarStyles[variant] || snackbarStyles.default

  const theme = useTheme()
  const themeMode = theme.palette.mode

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor:
            themeMode === 'dark' ? newColor.charcoal : newColor.whiteSmoke,
          color: themeMode === 'dark' ? newColor.white : newColor.black,
          borderLeft: `3px solid ${borderColor}`,
          minWidth: '300px',
          padding: '12px 16px',
          fontFamily: 'Open Sans, sans-serif',
        }}
        message={
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ width: '100%' }}
          >
            {icon && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon}
              </div>
            )}
            <Typography
              sx={{
                fontFamily: 'Open Sans, sans-serif',
                color: themeMode === 'dark' ? newColor.white : newColor.black,
                flexGrow: 1,
                textAlign: 'center',
                overflowWrap: 'break-word',
              }}
            >
              {message}
            </Typography>
          </Stack>
        }
        action={
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              alignSelf: 'center',
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  )
}

export default ReusableSnackbar
