import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'

import styled from 'styled-components'

import { USER_ROLES } from '../../../../consts/backend'
import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { isHelikaAdmin, isHelikaSuperAdmin } from '../../../../utils/user'
import { useSelector } from 'react-redux'
import { handleError } from '../../../../utils/api/errors'
import { linkUserToOrgApi } from '../../../../utils/api/queries'

const Title: any = styled.h3`
  text-align: center;
`

const Label: any = styled.label`
  text-align: center;
`

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

interface Org {
  label: string
  value: number
}

export default function LinkUserToOrg({ orgs }: { orgs: Org[] }) {
  const { patchWithAccessToken } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)

  const [org, setOrg] = useState<Org | null>(null)
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const onChangeEmail = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setEmail(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const setAdminRole = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setRole(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const linkUserToOrg = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (email && org && role) {
      try {
        await linkUserToOrgApi(
          patchWithAccessToken,
          email,
          org.label,
          org.value,
          role,
        )
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage(`Please enter user's email and choose organization`)
    }
  }

  let userRolesAvailable: {
    viewer: string
    admin: string
    helika_admin?: string
    helika_super_admin?: string
  } = { ...USER_ROLES }
  if (isHelikaSuperAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
    userRolesAvailable.helika_super_admin = 'helika_super_admin'
  } else if (isHelikaAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
  }

  return (
    <form onSubmit={linkUserToOrg}>
      <Wrapper>
        <Title>
          Associate user to organisation by email and change their roles (by
          editing organization_id and role in public.users)
        </Title>
        <br />
        <br />
        <Label>Enter user email:</Label>
        <Input
          placeholder="example: support@helika.io"
          value={email}
          type="email"
          required
          //pattern="^([a-zA-Z0-9_\-\.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
          title="Valid email"
          onChange={onChangeEmail}
        />
        <Label>Choose organization:</Label>
        <Select value={org?.value} onChange={setSelectedOrg}>
          <option disabled value="">
            {' '}
            -- select an option --{' '}
          </option>
          {orgs.map((org) => (
            <option key={org.value} value={org.value}>
              {org.label}
            </option>
          ))}
        </Select>
        <Label>Choose role:</Label>
        <Select required value={role} onChange={setAdminRole}>
          <option disabled value="">
            {' '}
            -- select an option --{' '}
          </option>
          {Object.values(userRolesAvailable).map((userRole) => (
            <option key={userRole} value={userRole}>
              {userRole}
            </option>
          ))}
        </Select>
        <Button type="submit">Associate User To Org</Button>
        <Message>{message}</Message>
        <Error>{error}</Error>
      </Wrapper>
    </form>
  )
}
