import styled from 'styled-components'
import AddContract from '../../components/organisms/v2/addContract'
import CreateOrg from '../../components/organisms/v2/createOrg'
import CreateApiKey from '../../components/organisms/v2/createApiKey'
import LinkContractToOrg from '../../components/organisms/v2/linkContractToOrg'
import { useContext, useEffect, useState } from 'react'
import { Auth0Context } from '../../contexts/Auth0Context'
import { Wrapper } from '../../utils/muiStyledComponents'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { lowerCaseAndReplaceSpacesAndDashes } from '../../utils/string'
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
} from '../../utils/user'
import { getUserOrgs } from '../../utils/api/queries'

const Title: any = styled.h1`
  padding: 32px;
  margin: 0;
`

const Step: any = styled.h2`
  padding: 16px;
`

interface Org {
  label: string
  value: number
}
interface OrgData {
  name: string
  id: number
}

interface Team {
  label: string
  value: string
}

interface DataTeam {
  name: string
  teamId: string
}

export default function OnboardingPanel() {
  const userData = useSelector((state: any) => state.user)
  const { getWithAccessToken, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [orgs, setOrgs] = useState<Org[]>([])
  const [sigmaTeams, setSigmaTeams] = useState<Team[]>([])
  const [sigmaTeam, setSigmaTeam] = useState<Team | null>(null)

  // isolate the logic of getting the token and refreshing it
  const getSigmaAccessToken = async () => {
    const details = {
      grant_type: 'client_credentials',
      client_id: process.env.REACT_APP_SIGMA_CLIENT_ID!,
      client_secret: process.env.REACT_APP_SIGMA_SECRET!,
    }
    const formBody = []
    for (const [key, value] of Object.entries(details)) {
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(value)
      formBody.push(encodedKey + '=' + encodedValue)
    }
    const form = formBody.join('&')

    const tokenRes = await fetch(
      `${process.env.REACT_APP_SIGMA_URL}/v2/auth/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: form,
      },
    )

    const tokens = await tokenRes.json()

    return tokens
  }

  //get organizations table
  useEffect(() => {
    const getOrphanTeams = async (orgs: any[]) => {
      const tokens = await getSigmaAccessToken()

      const teamsRes = await fetch(
        `${process.env.REACT_APP_SIGMA_URL}/v2/teams`,
        {
          method: 'GET',
          headers: {
            authorization: `Bearer ${tokens.access_token}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )

      if (!teamsRes) return
      const teams = await teamsRes.json()
      const dbOrgsSet = new Set(orgs.map((dbOrg: any) => dbOrg.label))

      let orphanTeams = teams.filter(
        (team: any) =>
          !dbOrgsSet.has(lowerCaseAndReplaceSpacesAndDashes(team.name)),
      )

      if (orphanTeams.length > 0) {
        setSigmaTeams(
          orphanTeams.map((team: DataTeam) => ({
            label: team.name,
            value: team.teamId,
          })),
        )
        setSigmaTeam({
          label: orphanTeams[0].name,
          value: orphanTeams[0].teamId,
        })
      }
    }
    const getOrgs = async () => {
      try {
        await getUserOrgs(
          getWithAccessToken,
          userData,
          isHelikaAdmin,
          isHelikaSuperAdmin,
        )
          .then((data) => {
            if (!data || !data.results) return
            let newOrgs = data.results.map((org: OrgData) => ({
              label: org.name,
              value: org.id,
            }))
            setOrgs(newOrgs)
            getOrphanTeams(newOrgs)
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (e) {
        console.log(e)
      }
    }

    if (isLoading || !isAuthenticated || _.isEmpty(userData) || orgs.length > 0)
      return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getOrgs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWithAccessToken, isAuthenticated, isLoading, orgs, userData])

  return (
    <Wrapper>
      <Title>Onboarding Process</Title>
      <Step>
        1. Create sigma user attribute and organization in our database
      </Step>
      <CreateOrg
        sigmaTeam={sigmaTeam}
        setSigmaTeam={setSigmaTeam}
        sigmaTeams={sigmaTeams}
        setSigmaTeams={setSigmaTeams}
      />
      <br />
      <br />
      <br />
      <br />
      <Step>2. Add smart contract address for tracking</Step>
      <AddContract />
      <br />
      <br />
      <br />
      <br />
      <Step>3. Associate smart contract address with organization</Step>
      <LinkContractToOrg orgs={orgs} />
      <br />
      <br />
      <br />
      <br />
      <Step>4. Create API Key for Organization</Step>
      <CreateApiKey orgs={orgs} />
      <br />
      <br />
      <br />
      <br />
    </Wrapper>
  )
}
