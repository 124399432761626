import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import { Box, styled as muiStyled, Button, useTheme, Grid } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import CreateTab_Icon from '../../assets/Create_Tab_Icon.svg'
import { isStringEmpty } from '../../utils/string'
import Analytics from '../../assets/analytics_icon.svg'
import AnalyticsLight from '../../assets/analytics_icon_light.svg'
import { Auth0Context } from '../../contexts/Auth0Context'
import { createParentTabApi } from '../../utils/api/queries'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface SimpleDialogProps {
  onCreated?: any
  open: boolean
  setOpen: any
  modules: any
}

export default function CreateParentTabModal(props: SimpleDialogProps) {
  const { open, setOpen, modules, onCreated } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [moduleValue, setModuleValue] = useState<number>(0)
  const { postWithAccessToken } = useContext(Auth0Context)

  const handleClose = () => {
    setOpen(false)
  }

  const resetFields = () => {
    setName('')
    setIcon('')
  }

  async function createParentTabQuery() {
    try {
      setLoading(true)

      isStringEmpty(name, 'Name required')
      isStringEmpty(icon, 'Tab Icon required')

      if (name && moduleValue && icon) {
        setLoading(true)
        const results = await createParentTabApi(
          postWithAccessToken,
          name.trim(),
          moduleValue,
          icon.trim(),
        )
        resetFields()
        onCreated(moduleValue, results)
        setLoading(false)
      } else {
        toast.warning('Please enter New Tab Name and other fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '30px 40px 0 40px',
        }}
      >
        <DialogTitle
          className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={CreateTab_Icon}
            alt="create_tab"
            style={{ margin: 'auto 0.3em auto 0' }}
          />{' '}
          <div style={{ lineHeight: '26px', fontSize: '23px' }}>
            CREATE A PARENT TAB
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '0 40px 0 58px',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
        gap={'8px'}
      >
        <Typography
          style={{
            fontSize: '20px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '22.5px',
          }}
        >
          <img
            src={theme.palette.mode === 'dark' ? Analytics : AnalyticsLight}
            alt="APIs"
            width="21px"
            height="21px"
          />
          &nbsp;&nbsp;PARENT TAB INFORMATION
        </Typography>
        <Grid
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          display={'flex'}
          alignContent={'center'}
          flexDirection={'row'}
          paddingLeft={'35px'}
        >
          <Grid
            style={{ display: 'flex', alignItems: 'center', width: '200px' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ fontSize: '14px', width: 'max-content' }}
            >
              PARENT TAB NAME:
            </div>
          </Grid>
          <Grid style={{ width: '215px' }}>
            <Input
              value={name}
              placeholder="Name"
              onChange={(e) => {
                setName(e.currentTarget.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          display={'flex'}
          alignContent={'center'}
          flexDirection={'row'}
          paddingLeft={'35px'}
        >
          <Grid
            style={{ display: 'flex', alignItems: 'center', width: '200px' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ fontSize: '14px', width: 'max-content' }}
            >
              MODULE:
            </div>
          </Grid>
          <Grid style={{ width: '215px' }}>
            <Select
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass3'
                  : 'inputFieldClass3Light'
              }
              labelId="search-filter"
              defaultValue={''}
              size="small"
              onChange={(event: SelectChangeEvent) => {
                setModuleValue(Number(event.target.value))
              }}
              sx={{
                height: '2em',
                width: '100%',
                padding: '0',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
              value={moduleValue ? moduleValue.toString() : ''}
            >
              {modules &&
                modules.map((p: { id: number; name: string }) => (
                  <MenuItem
                    value={p.id}
                    key={p.id}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Grid
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          display={'flex'}
          alignContent={'center'}
          flexDirection={'row'}
          paddingLeft={'35px'}
        >
          <Grid
            style={{ display: 'flex', alignItems: 'center', width: '200px' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ fontSize: '14px', width: 'max-content' }}
            >
              TAB ICON:
            </div>
          </Grid>
          <Grid style={{ width: '215px' }}>
            <Input
              value={icon}
              placeholder="ICON"
              onChange={(e) => {
                setIcon(e.currentTarget.value)
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={createParentTabQuery}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
