import styled from 'styled-components'
import { useSelector } from 'react-redux'
import AddSocialConnection from '../../components/organisms/v2/addSocialConnection'
import LinkUserToOrg from '../../components/organisms/v2/linkUserToOrg'
import AddTab from '../../components/organisms/v2/addTab'
import { useContext, useEffect, useState } from 'react'
import { Auth0Context } from '../../contexts/Auth0Context'
import AssignTab from '../../components/organisms/v2/assignTab'
import RemoveTab from '../../components/organisms/v2/removeTab'
import _ from 'lodash'
import {
  canAccessTabManagement,
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
} from '../../utils/user'
import BustCache from '../../components/organisms/v2/bustCache'
import { useTheme } from '@mui/material'
import {
  getDistinctTabs,
  getParentTabsApi,
  getUserOrgs,
} from '../../utils/api/queries'
import { newColor } from '../../consts/colors'

interface Org {
  label: string
  value: number
}
interface OrgData {
  name: string
  id: number
}

const Title: any = styled.h1`
  padding: 32px;
  margin: 0;
`

export default function AdminPanel() {
  const theme = useTheme()
  const userData = useSelector((state: any) => state.user)
  const { getWithAccessToken, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [orgs, setOrgs] = useState<Org[]>([])
  const [tabs, setTabs] = useState<any[]>([])
  const [parentTabs, setParentTabs] = useState<any[]>([])

  //get organizations table
  useEffect(() => {
    const getOrgs = async () => {
      try {
        await getUserOrgs(
          getWithAccessToken,
          userData,
          isHelikaAdmin,
          isHelikaSuperAdmin,
        )
          .then((data) => {
            if (!data || !data.results) return
            setOrgs(
              data.results.map((org: OrgData) => ({
                label: org.name,
                value: org.id,
              })),
            )
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (e) {
        console.log(e)
      }
    }

    if (isLoading || !isAuthenticated || _.isEmpty(userData) || orgs.length > 0)
      return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getOrgs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWithAccessToken, isAuthenticated, isLoading, orgs, userData])

  //get parent tabs
  useEffect(() => {
    const getParentTabs = async () => {
      await getParentTabsApi(getWithAccessToken)
        .then((resp: any) => {
          if (!resp.results || resp.results.length === 0) return
          setParentTabs(resp.results)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }

    getParentTabs()
  }, [getWithAccessToken])

  //get organizations table
  useEffect(() => {
    const getTabs = async () => {
      try {
        await getDistinctTabs(getWithAccessToken)
          .then((data) => {
            if (!data || !data.results) return
            setTabs(data.results)
          })
          .catch((err) => {
            console.log(err)
          })
      } catch (e) {
        console.log(e)
      }
    }

    if (isLoading || !isAuthenticated || _.isEmpty(userData) || orgs.length > 0)
      return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getTabs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWithAccessToken, isAuthenticated, isLoading, orgs, userData])

  return (
    <div
      style={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
      }}
    >
      <Title>Admin Panel</Title>
      <LinkUserToOrg orgs={orgs} />
      <br />
      <br />
      <br />
      <br />
      <AddSocialConnection orgs={orgs} />
      <br />
      <br />
      <br />
      <br />
      {canAccessTabManagement(userData) && (
        <AddTab parentTabs={parentTabs} orgs={orgs} />
      )}
      {canAccessTabManagement(userData) && (
        <AssignTab orgs={orgs} tabs={tabs} parentTabs={parentTabs} />
      )}
      {canAccessTabManagement(userData) && (
        <RemoveTab orgs={orgs} tabs={tabs} parentTabs={parentTabs} />
      )}
      {canAccessTabManagement(userData) && <BustCache orgs={orgs} />}
    </div>
  )
}
