import { AdminSpan } from "../../../../components/StyledSimpleComponents";
import adminIcon from '../../../../assets/admin_icon.svg';
import adminIconHover from '../../../../assets/admin_icon_hover.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as ReactTooltip from 'react-tooltip';
import { useNavigate } from "react-router-dom";


export const AdminPanelIcon = () => {

  const navigate = useNavigate();

  return (
    <AdminSpan
      key='admin icon'
      href={`/admin_panel`}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault();
        navigate('/admin_panel')
      }}
      data-tooltip-id="admin_panel_icon" data-tooltip-content={'Admin Panel'}
      id="adminIcon"
    >
      <ReactTooltip.Tooltip id="admin_panel_icon" />
      <img id="adminIconNonHover" src={adminIcon} alt='adminIcon' />
      <img id="adminIconHover" src={adminIconHover} alt='adminIcon' />
    </AdminSpan>
  )
}