import styled from 'styled-components';

import { palette } from '../../../consts/colors';
import { fontSizes } from '../../../consts/sizes';

export const Button: any = styled.button`
  background-color: ${palette.primary};
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: ${fontSizes.regular};
  cursor: pointer;
  &:hover {
    background-color: ${palette.primaryLight};
  }
  &:active {
    color: ${palette.bg};
    background-color: ${palette.primaryLight};
  }
`;
