import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined' // Updated to outlined icon
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined' // Updated to outlined icon
import { ICampaign } from 'src/types/engage'
import { styled, useTheme } from '@mui/system'
import { newColor } from 'src/consts/colors'

// Function to dynamically apply common text styles based on theme
const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
})

// Styled menu item with consistent appearance
const MenuItemWrapper = styled(MenuItem)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    width: '200px',
    height: '36px',
    background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      background:
        themeMode === 'dark' ? newColor.darkGunmetal : newColor.lightGray,
    },
  }),
)

// Styled menu container for consistent appearance
const MenuContainer = styled(Menu)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    '.MuiPaper-root': {
      background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
      boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      zIndex: 1300,
    },
  }),
)

interface HookActionsMenuProps {
  hook: ICampaign
  onEdit: (hook: ICampaign) => void
  onArchive: (hook: ICampaign) => Promise<void>
  refreshTable: () => void
}

const HookActionsMenu: React.FC<HookActionsMenuProps> = ({
  hook,
  onEdit,
  onArchive,
  refreshTable,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const theme = useTheme()
  const themeMode = theme.palette.mode

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Trigger the edit action
  const handleEdit = () => {
    onEdit(hook)
    handleClose()
  }

  // Trigger the archive action and refresh the table after archiving
  const handleArchive = async () => {
    try {
      setIsLoading(true)
      await onArchive(hook)
      refreshTable()
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <IconButton
        aria-controls="hook-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isLoading}
        sx={{ zIndex: 1 }}
      >
        <MoreVertIcon />
      </IconButton>
      <MenuContainer
        id="hook-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        themeMode={themeMode}
      >
        {/* Edit Menu Item */}
        <MenuItemWrapper
          onClick={handleEdit}
          disabled={isLoading}
          themeMode={themeMode}
        >
          <EditOutlinedIcon
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          />
          <Typography sx={commonTextStyles(themeMode)}>Edit</Typography>
        </MenuItemWrapper>

        {/* Archive Menu Item */}
        <MenuItemWrapper
          onClick={handleArchive}
          disabled={isLoading}
          themeMode={themeMode}
        >
          <ArchiveOutlinedIcon
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          />
          <Typography sx={commonTextStyles(themeMode)}>Archive</Typography>
        </MenuItemWrapper>
      </MenuContainer>
    </>
  )
}

export default HookActionsMenu
