import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'

import {
  Box,
  Button,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { newColor } from 'src/consts/colors'
import { StyledSvg } from '../organisms/v2/tabs/styled'
import { ReactComponent as ModalCloseIcon } from 'src/assets/modal_close_icon.svg'
import { ReactComponent as VisualDesignerIcon } from 'src/assets/visual_designer_icon.svg'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any,
  openVisualHandler: any,
  warning: any,
  title: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const DontSaveDashboardModal = (props: SimpleDialogProps) => {
  const { open, setOpen, openVisualHandler, warning, title } = props
  const theme = useTheme()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >
        <StyledSvg
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ModalCloseIcon
            style={{ margin: 'auto', cursor: 'pointer' }}
            onClick={handleClose}
          />
        </StyledSvg>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '-30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <StyledSvg
              sx={{
                margin: '0.2rem 0 auto 0'
              }}
            >
              <VisualDesignerIcon />
            </StyledSvg>
            <div style={{ lineHeight: '26px', fontSize: '23px', marginLeft: '0.5em' }}>
              {title}
            </div>
          </DialogTitle>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{ padding: '0 2em 0 2em', width: '100%', textAlign: 'center', justifyContent: 'center' }}
        >
          {warning}
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        sx={{ padding: '0 2em 2em 2em' }}
      >
        <Button
          sx={{ width: '15em' }}
          onClick={() => setOpen(false)}
          className="helikaButtonClass"
        >
          Cancel
        </Button>
        <Button
          sx={{ width: '15em', marginLeft: '1em' }}
          onClick={openVisualHandler}
          className="helikaButtonClass"
        >
          CREATE NEW DASHBOARD
        </Button>
      </Box>
    </Dialog>
  )
}

export default DontSaveDashboardModal
