import React, { createContext, useContext, useState } from 'react'

const IsEditingContext = createContext<{
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const useIsEditing = () => {
  const context = useContext(IsEditingContext)
  if (!context) {
    throw new Error('Context have to be put under ActionSaveProvider')
  }

  return context
}

export default function IsEditProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <IsEditingContext.Provider value={{ isEditing, setIsEditing }}>
      {children}
    </IsEditingContext.Provider>
  )
}
