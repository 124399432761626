import { ReactComponent as APIKeysIcon } from 'src/assets/api_keys_icon.svg'
import OrgImg from '../../assets/Org_Management_Icon.svg'
import PageContainer from '../atoms/v2/pageContainer'
import CircularPageHeader from '../atoms/v2/circularPageHeader'
import Card from '../atoms/v2/card'
import ApiKeys from '../OrganizationManagement/ApiKeys'

export default function ApiKeyManagement() {
  return (
    <PageContainer>
      <CircularPageHeader text="API Key MANAGEMENT" icon={OrgImg} />
      <Card
        label="API KEYS"
        icon={<APIKeysIcon />}
        description="Below are your secret API keys, which you’ll use when making requests to the Transpose APIs. 
      Each key has its own rate limit, isolated from other API keys. Never share these API keys with anyone outside of your team."
        customExtra="X/20 Remaining"
        sx={{
          minWidth: '70em'
        }}
      >
        <ApiKeys />
      </Card>
    </PageContainer>
  )
}
