import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  Box,
  Button,
  Grid,
  Divider,
  TextField,
  useTheme,
  styled as muiStyled,
  FormControlLabel,
  Switch,
  SwitchProps,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth0Context } from '../../contexts/Auth0Context'
import {
  getProjectList,
  getProjectDetails,
  getProjectHooks,
  updateProjectHooks,
} from '../../utils/api/queries'
import ProjectList from './ProjectList'
import CreateProjectModal from './CreateProjectModal'
import loadingSVG from '../../assets/loading.svg'
import { newColor } from '../../consts/colors'
import { toast } from 'react-toastify'

interface Project {
  organization_id: number
  priority: number
  active: boolean
  name: string
  description: string
  created_at: string
  updated_at: string
  id: number
}

interface DateTimeOptions {
  day?: '2-digit' | 'numeric'
  month?: '2-digit' | 'numeric'
  year?: '2-digit' | 'numeric'
  hour?: '2-digit' | 'numeric'
  minute?: '2-digit' | 'numeric'
  second?: '2-digit' | 'numeric'
  fractionalSecondDigits?: number
}

interface RootState {
  user: {
    organization_id: number
  }
}

// Custom switch component with iOS-like styling
const IOSSwitch = muiStyled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: newColor.white,
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? newColor.shamrock : newColor.shamrock,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: newColor.malachite,
      border: `6px solid ${newColor.white}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === 'light'
        ? newColor.jazzberryJam
        : newColor.jazzberryJam,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const ProjectBuilder: React.FC = () => {
  const { getWithAccessToken, patchWithAccessToken } = useContext(Auth0Context)
  const [projects, setProjects] = useState<Project[]>([])
  const [selectedProjectDetails, setSelectedProjectDetails] =
    useState<Project | null>(null)
  const [selectedProjectHooks, setSelectedProjectHooks] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [detailsLoading, setDetailsLoading] = useState<boolean>(false)
  const [showCanvas, setShowCanvas] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { organization_id } = useSelector((state: RootState) => state.user)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(5)
  const [count, setCount] = useState<number>(0)
  const [projectListLoading, setProjectListLoading] = useState<boolean>(false)
  const [canvasProjectId, setCanvasProjectId] = useState<number | null>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editableDetails, setEditableDetails] = useState<Partial<Project>>({})
  const [prevEditableDetails, setPrevEditableDetails] = useState<
    Partial<Project>
  >({})
  const theme = useTheme()
  const navigate = useNavigate()

  const options: DateTimeOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
  }

  // Helper function to format date and time
  const formatDateTime = (
    dateTimeString: string,
    options: DateTimeOptions,
  ): string => {
    const { fractionalSecondDigits, ...dateTimeOptions } = options
    return new Date(dateTimeString).toLocaleString(undefined, dateTimeOptions)
  }

  // Fetch the list of projects
  const getList = useCallback(async () => {
    try {
      setProjectListLoading(true)
      const data = await getProjectList(getWithAccessToken, page, pageSize)
      if (data && data.results) {
        setProjects(data.results)
        setCount(data.count)
      }
      setLoading(false)
      setProjectListLoading(false)
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error('Error fetching project list: ' + e.message)
      } else {
        toast.error('Unexpected error fetching project list')
      }
      setLoading(false)
      setProjectListLoading(false)
    }
  }, [getWithAccessToken, page, pageSize])

  // Fetch the details and hooks for a selected project
  const handleProjectClick = async (projectId: number) => {
    setDetailsLoading(true)
    try {
      const [projectDetails, projectHooks] = await Promise.all([
        getProjectDetails(getWithAccessToken, projectId),
        getProjectHooks(getWithAccessToken, projectId),
      ])

      if (projectDetails && projectDetails.result) {
        setSelectedProjectDetails(projectDetails.result)
        setEditableDetails(projectDetails.result)
      }

      if (projectHooks && projectHooks.results) {
        setSelectedProjectHooks(projectHooks.results)
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error('Error fetching project details or hooks: ' + e.message)
      } else {
        toast.error('Unexpected error fetching project details or hooks')
      }
    } finally {
      setDetailsLoading(false)
    }
  }

  // Open the modal to create a new project
  const onCreateProjectClick = () => {
    setModalOpen(true)
  }

  const handleHookListClick = () => {
    if (selectedProjectDetails?.id) {
      navigate(`/game_management/projects/${selectedProjectDetails.id}`)
    }
  }

  useEffect(() => {
    getList()
  }, [getList])

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <img
          width="100px"
          className="animate-spin"
          src={loadingSVG}
          alt="Loading..."
        />
      </div>
    )
  }

  // Update the page size for pagination
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPageSize(+event.target.value)
  }

  // Handle pagination page change
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  // Show the canvas component for the selected project
  const showCanvasComponent = (projectId: number) => {
    setCanvasProjectId(projectId)
    setShowCanvas(true)
  }

  // Toggle edit mode and save changes if editing
  const handleEditClick = async () => {
    if (isEditing && selectedProjectDetails) {
      try {
        const response = await updateProjectHooks(
          patchWithAccessToken,
          editableDetails,
          selectedProjectDetails.id,
        )
        toast.success('Successfully updated project hooks!')
        setSelectedProjectDetails(response.result)
        setEditableDetails(response.result)
        getList() // Refresh the project list
      } catch (e: any) {
        toast.error(e?.message)
      }
    } else {
      setPrevEditableDetails(editableDetails)
    }
    setIsEditing(!isEditing)
  }

  // Handle changes to project details
  const handleDetailChange = (field: keyof Project, value: any) => {
    setEditableDetails({
      ...editableDetails,
      [field]: value,
    })
  }

  if (showCanvas && canvasProjectId !== null) {
    // Add chatbox here
  }

  return (
    <div className="container-fluid pt-3">
      <CreateProjectModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        organization_id={organization_id.toString()} // Convert organization_id to string
        showCanvasComponent={showCanvasComponent}
      />
      <div className="row" style={{ display: 'flex', margin: '40px' }}>
        <div
          className="col-6 d-flex justify-content-center align-items-center"
          style={{
            border: '2px solid transparent',
            borderRadius: '20px',
            borderImage:
              'linear-gradient(to right, rgba(234,51,95,.5), rgba(151,71,255,.5)) 1',
            padding: '20px',
            overflow: 'auto',
            flex: 1,
          }}
        >
          <div style={{ width: '90%' }}>
            <h2>Details</h2>
            {detailsLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <img
                  width="100px"
                  className="animate-spin"
                  src={loadingSVG}
                  alt="Loading..."
                />
              </div>
            ) : selectedProjectDetails ? (
              <div>
                <Box
                  sx={{
                    textAlign: 'left',
                    marginBottom: '1em',
                    padding: '1em',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    background:
                      theme.palette.mode === 'dark'
                        ? newColor.midnight
                        : 'white',
                    borderStyle: 'solid',
                    borderColor: newColor.outerSpace,
                    borderRadius: '0.5em',
                    borderWidth: '1px',
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>Name:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isEditing ? (
                        <TextField
                          value={editableDetails.name || ''}
                          onChange={(e) =>
                            handleDetailChange('name', e.target.value)
                          }
                          fullWidth
                        />
                      ) : (
                        selectedProjectDetails.name
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>
                          Description:
                        </div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isEditing ? (
                        <TextField
                          value={editableDetails.description || ''}
                          onChange={(e) =>
                            handleDetailChange('description', e.target.value)
                          }
                          fullWidth
                        />
                      ) : (
                        selectedProjectDetails.description
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>Priority:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isEditing ? (
                        <TextField
                          value={
                            editableDetails.priority !== undefined
                              ? editableDetails.priority
                              : ''
                          }
                          onChange={(e) =>
                            handleDetailChange('priority', e.target.value)
                          }
                          fullWidth
                        />
                      ) : (
                        selectedProjectDetails.priority
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>Active:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isEditing ? (
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          label=" "
                          checked={editableDetails.active}
                          onChange={(e: any) => {
                            handleDetailChange('active', e.target.checked)
                          }}
                        />
                      ) : selectedProjectDetails.active ? (
                        'Yes'
                      ) : (
                        'No'
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>
                          Created At:
                        </div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {formatDateTime(
                        selectedProjectDetails.created_at,
                        options,
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: 'auto 0 auto 0' }}>
                          Updated At:
                        </div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {formatDateTime(
                        selectedProjectDetails.updated_at,
                        options,
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    className="linearGradientCircle"
                    variant="contained"
                    size="medium"
                    sx={{
                      height: '2.5em',
                      marginTop: '5vh',
                      width: '25vw',
                      marginLeft: 2,
                    }}
                    onClick={handleHookListClick}
                  >
                    Hook List
                  </Button>
                  <Button
                    className="linearGradientCircle"
                    variant="contained"
                    size="medium"
                    sx={{
                      height: '2.5em',
                      marginTop: '5vh',
                      width: '25vw',
                      marginLeft: 2,
                    }}
                    onClick={handleEditClick}
                  >
                    {isEditing ? 'Save' : 'Edit'}
                  </Button>
                  {isEditing && (
                    <Button
                      className="linearGradientCircle"
                      variant="contained"
                      size="medium"
                      sx={{
                        height: '2.5em',
                        marginTop: '5vh',
                        width: '25vw',
                        marginLeft: 2,
                      }}
                      onClick={() => {
                        setEditableDetails({
                          ...prevEditableDetails,
                        })
                        setIsEditing(false)
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <p>Select a project to see details</p>
            )}
          </div>
        </div>
        <div
          className="col-6 d-flex justify-content-center align-items-center"
          style={{
            border: '2px solid transparent',
            borderRadius: '20px',
            borderImage:
              'linear-gradient(to right, rgba(234,51,95,.5), rgba(151,71,255,.5)) 1',
            padding: '20px',
            overflowY: 'auto',
            flex: 1,
            marginLeft: '10px',
          }}
        >
          <div style={{ width: '90%' }}>
            <h2>Projects</h2>
            <Box
              sx={{
                textAlign: 'left',
                marginBottom: '1em',
                padding: '1em',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                background:
                  theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                borderStyle: 'solid',
                borderColor: newColor.outerSpace,
                borderRadius: '0.5em',
                borderWidth: '1px',
                width: '110%',
                maxHeight: '350px',
                overflow: 'auto',
              }}
            >
              <ProjectList
                projects={projects}
                onProjectClick={handleProjectClick}
                count={count}
                page={page}
                pageSize={pageSize}
                handlePaginationChange={handlePaginationChange}
                handlePageSizeChange={handlePageSizeChange}
                loading={projectListLoading}
              />
            </Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Button
                className="helikaButtonClass"
                variant="contained"
                size="medium"
                startIcon={<AddIcon />}
                sx={{
                  height: '2.5em',
                  marginTop: '30px',
                  width: '25vw',
                  marginLeft: 2,
                }}
                onClick={onCreateProjectClick}
              >
                Create Project
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectBuilder
