import React, { createContext, useContext, useState } from 'react'

const ActionSaveContext = createContext<{
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const useActionSave = () => {
  const context = useContext(ActionSaveContext)
  if (!context) {
    throw new Error('Context have to be put under ActionSaveProvider')
  }

  return context
}

export default function ActionSaveProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ActionSaveContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </ActionSaveContext.Provider>
  )
}
