import React from 'react'
import { styled as muiStyled } from '@mui/material'
import {
  Accordion as MuiAccodion,
  AccordionSummary as MuiAccordionSummary,
  TableCell as MuiTableCell,
  Pagination as MuiPagination,
} from '@mui/material'
import styled from 'styled-components'
import { newColor } from '../../../../consts/colors'

export const HeaderImageWrapper = muiStyled('a')(() => ({
  textDecoration: 'none',
  backgroundColor: 'transparent',
  color: '#EA335F',
  cursor: 'pointer',
}))

export const HeaderImage = muiStyled('img')({
  display: 'block',
  borderStyle: 'solid',
  borderRadius: '8px',
  borderWidth: 0,
  maxWidth: 'initial',
  height: '176px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  objectFit: 'cover',
})

export const Accordion = styled(MuiAccodion)`
  &.MuiAccordion-root {
    overflow: hidden;

    &:first-of-type {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  align-items: flex-start;

  .MuiAccordionSummary-expandIconWrapper {
    margin-top: 16px;
  }
`

export const TableCell = styled(MuiTableCell).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['divider', 'header', 'left', 'right', 'first', 'last'].includes(prop) &&
    defaultValidatorFn(prop),
})<{
  divider?: boolean
  header?: boolean
  left?: boolean
  right?: boolean
  first?: boolean
  last?: boolean
}>(
  ({
    theme,
    divider = false,
    header = false,
    left = false,
    right = false,
    first = false,
    last = false,
  }) => ({
    position: 'relative',
    borderBottom: 'none',
    padding: left ? '0 0 0 16px' : right ? '0 16px 0 0' : '16px',
    paddingTop: first ? '8px' : header ? '16px' : 0,
    paddingBottom: last ? '8px' : header ? '16px' : 0,
    '&:after': divider
      ? {
          content: '""',
          position: 'absolute',
          top: '20%',
          bottom: 0,
          right: 0,
          borderRight: `1px solid ${newColor.darkGunmetal}`,
          height: '60%',
        }
      : {},
  }),
)

export const TableCellContent = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['left', 'right', 'odd'].includes(prop) && defaultValidatorFn(prop),
})<{
  left?: boolean
  right?: boolean
  odd?: boolean
  children?: React.ReactNode
}>(({ theme, left = false, right = false, odd = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  height: '48px',
  paddingLeft: '48px',
  borderTopLeftRadius: left ? '50px' : 'inherit',
  borderBottomLeftRadius: left ? '50px' : 'inherit',
  borderTopRightRadius: right ? '50px' : 'inherit',
  borderBottomRightRadius: right ? '50px' : 'inherit',
  backgroundColor: odd ? newColor.darkGunmetal : 'inherit',

  '@media (max-width: 767px)': {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}))

export const Pagination = styled(MuiPagination)`
  .MuiButtonBase-root {
    &.MuiPaginationItem-root {
      background-color: ${newColor.tertiary};
      &.Mui-selected {
        background: linear-gradient(to right, #ea335f, #612ea6);
      }
      &.Mui-disabled {
        background-color: ${newColor.tertiary};
        opacity: 50%;
      }
    }
  }
`
