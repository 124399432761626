import DragButtonIcon from 'src/assets/drag_button.svg'

import styled from 'styled-components'
import { Droppable, type DraggableStyle, Draggable } from '@hello-pangea/dnd'

import { type TabData, TabLevel } from './type'
import { ToggleTab } from './ToggleTab'
import { DragButton, Tab, TabContentDivider, TabName } from './styled'
import ChildTabs from './ChildTab'
import { useActionPopover } from '../ProfileEditToggle'
import { Box, Typography } from '@mui/material'
import { palette, newColor } from 'src/consts/colors'
import { stringToIcon } from '../../../../../utils/string'

const ParentTabStyled: any = styled(Tab)`
  border-color: ${newColor.babyBlueEyes};
`

const ParentTabName: any = styled(TabName)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${newColor.raisinBlack};
`

const getParentTabStyle = (
  isDragging: boolean,
  draggableStyle?: DraggableStyle,
) => {
  return {
    userSelect: 'none',
    marginLeft: '1.75rem',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'fit-content',
    ...draggableStyle,
  } as const
}

export default function ParentTabs({
  moduleTabId,
  tabData,
}: {
  moduleTabId: string
  tabData: TabData
}) {
  const { isEdit } = useActionPopover()

  const parentTabs = tabData[moduleTabId]
  const sortedParentTabs = Object.keys(parentTabs)
    .map((lv2TabId) => parentTabs[lv2TabId])
    .sort((a, b) => a.priority - b.priority)

  return (
    <Droppable
      droppableId={`${TabLevel.Parent}-${moduleTabId}`}
      type={`${TabLevel.Parent}-${moduleTabId}`}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{
            width: 'fit-content',
          }}
        >
          {sortedParentTabs?.map(({ id, name, children, icon }, index) => {
            return (
              <Draggable
                key={`${TabLevel.Parent}-${name}`}
                draggableId={`${TabLevel.Parent}-${name}`}
                index={index}
                isDragDisabled={!isEdit}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getParentTabStyle(
                      snapshot.isDraggingOver,
                      provided.draggableProps.style,
                    )}
                  >
                    <ToggleTab>
                      <ToggleTab.ParentTab>
                        <ParentTabStyled
                          direction="row"
                          divider={
                            <TabContentDivider
                              orientation="vertical"
                              flexItem
                            />
                          }
                        >
                          <DragButton
                            src={DragButtonIcon}
                            alt=""
                            {...provided.dragHandleProps}
                          />
                          <ParentTabName>
                            <Box sx={{ display: 'flex', gap: '6px' }}>
                              {stringToIcon(icon)}
                              <Typography sx={{ color: palette.font }}>
                                {name}
                              </Typography>
                            </Box>
                            <ToggleTab.ToggleButton />
                          </ParentTabName>
                        </ParentTabStyled>
                      </ToggleTab.ParentTab>
                      <ToggleTab.ChildTab>
                        <ChildTabs
                          childTabs={children}
                          parentTabId={id}
                          moduleTabId={moduleTabId}
                        />
                      </ToggleTab.ChildTab>
                    </ToggleTab>
                  </div>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
