import { Pagination, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import _, { debounce } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button } from 'helika-ui-sdk'
import { ReactComponent as InboxEmpty } from '../../../assets/inbox_empty.svg'
import { StyledSvg } from "src/components/agGrid/SupportDataGridHeaderComponent";
import { lightPalette, newColor } from "src/consts/colors";
import React from "react";
import { offersManagementHeaders } from "src/utils/agGrid";
import {
    DataGridPro,
} from '@mui/x-data-grid-pro'
import {
    styled as muiStyled,
} from '@mui/material'
import HelikaSearchBar from "src/components/HelikaSearchBar";
import CreateOfferModal from "src/components/popups/CreateOfferModal";
import { editOffer, getOffers } from "src/utils/api/queries";
import { Auth0Context } from "src/contexts/Auth0Context";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditOfferModal from "src/components/popups/EditOfferModal";
import LoadingComponent from "src/components/LoadingComponent";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import ReusableSnackbar from "../GameManagement/RedemptionCode/components/ReusableSnackbar";

export default function OfferManagement() {

    const theme = useTheme()
    const { getWithAccessToken, getTokenIfNecessary } = useContext(Auth0Context)
    const { organization_id } = useSelector((state: any) => state.user)
    const [offers, setOffers] = useState<any[]>([])
    const [filteredOffers, setFilteredOffers] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [openCreateOfferModal, setOpenCreateOfferModal] = useState<boolean>(false)
    const [openEditOfferModal, setOpenEditOfferModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [editLoading, setEditLoading] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')
    const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
        'success',
    )
    // const [sort, setSort] = useState<string>('Created At Desc')
    // pagination
    const [pageSize, setPageSize] = useState<string>('10')
    const [pageNumber, setPageNumber] = useState<number>(1)
    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setPageNumber(page)
    }

    const handlePageSizeChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setPageSize(event.target.value)
    }

    const [selectedOffer, setSelectedOffer] = useState<any>({})

    async function toggleOfferStatus(offer: any) {

        if (!offer?.id) {
            console.log('Could not find offer')
        }

        try {
            if (loading || editLoading) return
            setEditLoading(true)

            let updatedOffer: any = {
                ...offer,
                active: !(offer.active),
                updated_at: new Date(),
            }

            await editOffer(getTokenIfNecessary, {
                offer: updatedOffer
            }).then((resp: any) => {
                if (!_.isEmpty(resp?.results?.offers)) {
                    onEditOffer(resp?.results?.offers)
                    setSnackbarMessage('Offer updated')
                    setSnackbarVariant('success')
                    setSnackbarOpen(true)
                    setEditLoading(false)
                } else {
                    throw new Error('Could not edit offer')
                }
            })
        } catch (e: any) {
            setSnackbarMessage(e?.message)
            setSnackbarVariant('error')
            setSnackbarOpen(true)
            setEditLoading(false)
        }

    }

    const debouncedClickHandler = debounce(toggleOfferStatus, 200, { maxWait: 500 })

    const [columnDefs, setColumnDefs] = useState<any>(
        offersManagementHeaders(
            offers,
            setSelectedOffer,
            selectedOffer,
            theme,
            setOpenEditOfferModal,
            debouncedClickHandler
        ),
    )

    // show select column
    useEffect(() => {
        setColumnDefs(
            offersManagementHeaders(
                offers,
                setSelectedOffer,
                selectedOffer,
                theme,
                setOpenEditOfferModal,
                debouncedClickHandler
            ),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers, selectedOffer, setSelectedOffer, theme])

    function CustomPagination(hasPageSizeSelector: boolean = false) {

        const SelectCustom = muiStyled('select')(({ theme }) => ({
            width: '60px',
            borderRadius: '3px',
            backgroundColor: theme.palette.mode === 'dark' ? colors_semantic.background_primary : 'white',
            border: '1px solid ',
            borderColor: colors_semantic.border_primary,
            color: colors_semantic.text_primary,
            padding: '0 5px',
            '&:focus, &:hover': {
                outline: newColor.indigo,
                borderWidth: '1px',
                BorderStyle: 'solid',
                borderColor: newColor.gunmetalGray
            },
        }))

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '15px 25px 0 0',
                    gap: '8px',
                    fontFamily: 'Open Sans, sans-serif'
                }}
            >
                <Pagination
                    count={
                        isNaN(offers?.length / Number(pageSize))
                            ? 1
                            : Math.ceil(offers?.length / Number(pageSize))
                    }
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    shape="rounded"
                    sx={{
                        ul: {
                            '& .MuiPaginationItem-root': {
                                backgroundColor:
                                    theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
                                fontFamily: 'Open Sans, sans-serif'
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                background: theme.palette.mode === 'dark' ? newColor.gunmetalGray : color_base.grey200,
                            },
                            '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                                height: '32px!important',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'end',
                                alignItems: 'center',
                                borderRadius: '5px!important',
                            },
                        },
                    }}
                />
                {
                    hasPageSizeSelector &&
                    <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
                        <option defaultChecked value={5}>
                            5
                        </option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </SelectCustom>
                }
            </Box>
        );
    }

    //get offers
    useEffect(() => {
        async function getOffersApi() {
            setLoading(true)
            const data = await getOffers(getWithAccessToken, organization_id).catch((e: any) => {
                toast.error(e)
                setLoading(false)
            })
            if (data?.results?.offers) {
                setOffers(data?.results?.offers)
            }
            setLoading(false)
        }
        getOffersApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        let updatedList = offers
        if (!_.isEmpty(searchTerm?.trim())) {
            updatedList = offers?.filter(offer => offer?.title?.toLocaleLowerCase()?.trim()?.includes(searchTerm?.toLocaleLowerCase()?.trim()))
        }
        // if (!_.isEmpty(updatedList)) {
        //     switch (sort) {
        //         case 'Created At Asc':
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at > b.created_at ? 1 : -1,
        //             )
        //             break;
        //         case 'Created At Desc':
        //         default:
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at < b.created_at ? 1 : -1,
        //             )

        //     }
        // }

        //sort by updated_ay date
        let newUpdatedList = [...updatedList]
        if (!_.isEmpty(updatedList)) {
            newUpdatedList = [...updatedList]?.sort((a: any, b: any) =>
                a?.updated_at?.toString() < b?.updated_at?.toString() ? 1 : -1,
            )
        }

        setFilteredOffers(newUpdatedList)
        setLoading(false)
    }, [offers, pageNumber, pageSize, searchTerm])

    async function onCreateOffer(newOffer: any) {
        setOffers((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [newOffer]
            return prevState.concat(newOffer)
        })
        setSnackbarMessage('Offer created')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    async function onEditOffer(editedOffer: any) {
        setOffers((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [editedOffer]
            return prevState.map(offer => {
                if (offer.id === editedOffer.id) return editedOffer
                return offer
            })
        })
        setSnackbarMessage('Offer edited')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    const NoOffersCTA =
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10em auto auto auto',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                        width: 'fit-content',
                        cursor: 'pointer',
                        margin: 'auto'
                    }}
                >
                    <InboxEmpty
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </StyledSvg>
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '150%', /* 24px */
                    }}
                >
                    Create and manage offers
                </Box>
                <div
                    style={{
                        height: '8px'
                    }}
                />
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%', /* 16.8px */
                    }}
                >
                    Let's start creating your first offer!
                </Box>
                <div
                    style={{
                        height: '16px'
                    }}
                />
                <Button
                    type="Primary"
                    handleClick={() => setOpenCreateOfferModal(true)}
                    icon="Plus"
                    iconPosition="Left"
                >
                    Create New Offer
                </Button>
            </Box>
        </Box >

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'fit-content',
                height: 'fit-content',
                maxHeight: 'calc(100vh - 11em)',
                padding: '2em',
                gap: '1em',
                background: theme.palette.mode === 'dark' ? colors_semantic.background_primary : lightPalette.bgPage,
            }}
            className="open-sans-fontFam400"
        >
            <Box
                id='OfferWallManagementHeader'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        fontSize: '24px !important',
                        lineHeight: '130%',
                        fontWeight: 600,
                        margin: 'auto 0 auto 0'
                    }}
                    className="open-sans-fontFam400"
                >
                    Manage Offers
                </Box>
                {(loading || editLoading) && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10000,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingComponent />
                    </Stack>
                )}
                <ReusableSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    variant={snackbarVariant}
                    handleClose={() => setSnackbarOpen(false)}
                />
                <CreateOfferModal
                    open={openCreateOfferModal}
                    setOpen={setOpenCreateOfferModal}
                    onCreateOffer={onCreateOffer}
                />
                <EditOfferModal
                    open={openEditOfferModal}
                    setOpen={setOpenEditOfferModal}
                    onEditOffer={onEditOffer}
                    selectedOffer={selectedOffer}
                />
                <Button
                    type="Primary"
                    handleClick={() => setOpenCreateOfferModal(true)}
                    icon="Plus"
                    iconPosition="Left"
                >
                    Create New
                </Button>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >

                    {
                        //search and filters
                    }
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1em'
                        }}
                    >
                        <Box
                            sx={{
                                width: '20em',
                                maxWidth: '40%',
                                margin: 'auto 0 auto 0'
                            }}
                        >
                            <HelikaSearchBar
                                value={searchTerm}
                                setValue={setSearchTerm}
                                placeholder={'Search'}
                            />
                        </Box>
                    </Box>

                    {
                        // offers
                    }
                    {
                        loading
                            ? <Box sx={{ height: 'fit-content', width: 'fit-content', margin: 'auto' }}><LoadingComponent /></Box>
                            : _.isEmpty(filteredOffers)
                                ? NoOffersCTA
                                :
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '2em',
                                        height: '100%',
                                        maxHeight: '100%'
                                    }}
                                >
                                    <DataGridPro
                                        autoHeight={false}
                                        rows={filteredOffers}
                                        columns={columnDefs}
                                        getRowId={(row: any) => {
                                            return row.id
                                        }}
                                        onCellClick={(item) => { setSelectedOffer(item.row) }}
                                        sx={{
                                            border: 'none',
                                            "& .MuiDataGrid-columnHeaders": {
                                                minHeight: 'fit-content !important',
                                            },
                                            // Neutralize the hover colour (causing a flash)
                                            "& .MuiDataGrid-row.Mui-hovered": {
                                                backgroundColor: "transparent",
                                            },
                                            // Take out the hover colour
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: "transparent",
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderTop: 1,
                                                borderColor: colors_semantic.border_tertiary,
                                                borderBottom: 'none',
                                                paddingLeft: '12px !important',
                                                paddingRight: '12px !important',
                                                display: 'flex !important',
                                                flexDirection: 'row !important',
                                                justifyContent: 'center !important',
                                            },
                                            "& .MuiDataGrid-cellContent": {
                                                width: '100% !important',
                                                textAlign: 'left'
                                            },
                                            "& .MuiDataGrid-virtualScrollerContent": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-main": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                borderColor: colors_semantic.border_primary,
                                            },
                                        }}
                                        pageSizeOptions={[Number(pageSize)]}
                                        paginationModel={{
                                            pageSize: 10,
                                            page: pageNumber - 1
                                        }}
                                        paginationMode='client'
                                        pagination
                                        slots={{
                                            pagination: () => CustomPagination()
                                        }}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                    }
                </Box>

            </Box>
        </Box >
    )
}