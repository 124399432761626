import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
} from '@mui/material'
import { Interweave } from 'interweave';

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { newColor } from '../../consts/colors'
import { deleteEmailTemplate } from 'src/utils/api/queries';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { sampleDesign, sampleTemplate } from 'src/utils/email';


export interface SimpleDialogProps {
  onCreated?: any
  open: boolean
  setOpen: any,
  setApplyTemplate: any,
  templates: any,
  setTemplates: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

export default function ChooseExistingTemplateModal(props: SimpleDialogProps) {

  const { open, setOpen, setApplyTemplate, templates, setTemplates } = props
  const theme = useTheme()
  const { deleteWithAccessToken } = useContext(Auth0Context)
  const [chosenTemplate, setChosenTemplate] = useState<any>({ html: sampleTemplate, design: sampleDesign })

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          minWidth: '90vw',
          minHeight: '90vh'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '2em',
          height: 'fit-content',
        }}
      >
        <Box
          sx={{
            fontSize: '2em',
            fontWeight: 900,
          }}
        >
          CHOOSE EXISTING TEMPLATE
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={() => { setOpen(false) }}>X</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          maxHeight: 'calc(90vh - 4em - 10em)'
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: 'calc(100% - 25em)',
            minHeight: '100%',
            overflowY: 'auto',
            padding: '0 1em 0 1em',
            color: 'initial',
            cursor: 'default !imporant'
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Interweave content={chosenTemplate?.html} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '25em',
            height: '100%',
            padding: '1em',
            overflowY: 'auto',
            gap: 2
          }}
        >
          {
            templates?.map((template: any) => {
              return (
                <Box
                  key={template?.id}
                  style={{
                    border: 'solid',
                    borderRadius: '1em',
                    padding: '1em',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setChosenTemplate(template)
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: '0.5em'
                    }}
                  >
                    {template.name}
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      height: '15em',
                      overflow: 'hidden',
                      color: 'initial',
                    }}
                  >
                    <Interweave content={template.html} />
                  </Box>
                </Box>
              )
            })
          }
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          padding: '2em'
        }}
      >
        <Button
          className='helikaButtonClass'
          sx={{
            width: '15em',
            borderRadius: '0.5em !important',
            background: 'transparent !important',
            padding: '0.5em 2em 0.5em 2em',
            color: `${theme.palette.text.primary} !important`
          }}
          onClick={() => { setOpen(false) }}
        >
          CANCEL
        </Button>
        <Button
          className='helikaButtonClass'
          sx={{
            width: '15em',
            borderRadius: '0.5em !important',
            background: 'transparent !important',
            padding: '0.5em 2em 0.5em 2em',
            margin: '0 0.5em 0 0.5em',
            border: 'solid !important',
            borderColor: 'red !important',
            color: `${theme.palette.text.primary} !important`
          }}
          onClick={async () => {
            const data = await deleteEmailTemplate(deleteWithAccessToken, chosenTemplate?.id)
            if (data?.results) {
              setTemplates((prevState: any) => {
                if (!prevState) return []
                return prevState?.filter((template: any) => template.id !== data.results.id)
              })
            }
          }}
        >
          DELETE
        </Button>
        <Button
          className='helikaButtonClass'
          sx={{
            width: '15em',
            borderRadius: '0.5em !important',
            background: 'red !important',
            padding: '0.5em 2em 0.5em 2em'
          }}
          onClick={() => {
            setApplyTemplate(chosenTemplate)
            setOpen(false)
          }}
        >
          CHOOSE
        </Button>
      </Box>
    </Dialog >
  )
}