import React from 'react'
import { Box } from '@mui/material'
import assignedIcon from '../../assets/assigned_icon.svg'
import { newColor } from '../../consts/colors'

export function AssignUserButton(props: any) {
  let readProps: any = props
  let users = readProps?.colDef?.slotProps?.users

  let userObj = users?.find((user: any) => user.id === props.value)

  let displayName =
    userObj?.name && userObj?.name !== 'None' ? userObj?.name : userObj?.email

  return (
    <Box
      key={props.id}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'start'}
      alignItems={'center'}
      sx={{ height: '100%', width: '100%' }}
    >
      {typeof props.value === 'number' ? (
        <Box
          sx={{
            display: 'flex',
            gap: '7px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            borderRadius: '18px',
            padding: '4px 10px',
            background:
              props.theme?.palette.mode === 'dark'
                ? newColor.tertiary
                : newColor.cultured,
            cursor: 'pointer',
          }}
        >
          {displayName ? displayName : 'User'}
        </Box>
      ) : (
        <button
          className="helikaButtonClass"
          style={{
            flexWrap: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            height: 'fit-content',
            width: 'fit-content',
            cursor: 'pointer',
            fontWeight: '700',
            padding: '0.3em 1em 0.3em 1em',
          }}
        >
          <img src={assignedIcon} alt="" />
          <span style={{ paddingLeft: '0.5em' }}>ASSIGN USER</span>
        </button>
      )}
    </Box>
  )
}
