//module imports
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// mui
import { Button, useTheme } from '@mui/material'

// services
import { canSeeExplore } from 'src/utils/user'
import ExplorerButton from './Explorer'

// components
import { Drawer } from 'src/components/StyledSimpleComponents'
import { SubTabsScrollbox } from './domainDrawers/SubTabsScrollbox'

// utils
import { newColor } from 'src/consts/colors'
import { setData, useGetDrawer, useGetInitialStateData } from 'src/utils/drawer'

// types
import { DOMAIN_TYPES } from 'src/state/action-types'


export const BaseDrawer = () => {
  const theme = useTheme()
  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)
  const USER_DATA = useSelector((state: any) => state.user)
  const dispatch = useDispatch()

  const isDrawerOpen = useGetDrawer()

  // initial state of tabs
  let initialStateAnalytics = useGetInitialStateData(DOMAIN_TYPES.ANALYTICS)
  let initialStateGameManagement = useGetInitialStateData(
    DOMAIN_TYPES.GAME_MANAGEMENT,
  )
  let initialStateSupport = useGetInitialStateData(DOMAIN_TYPES.SUPPORT)
  let initialStateAccountManagement = useGetInitialStateData(
    DOMAIN_TYPES.ACCOUNT_MANAGEMENT,
  )

  async function openDrawerOnHover(drawerState: boolean) {
    setData(
      DOMAIN,
      {
        MAIN_DRAWER_OPEN: drawerState,
      },
      dispatch,
    )
  }

  async function clickDrawer() {
    let subTabsInitialState = initialStateAnalytics
    switch (DOMAIN) {
      case DOMAIN_TYPES.ANALYTICS: {
        subTabsInitialState = initialStateAnalytics
        break
      }
      case DOMAIN_TYPES.GAME_MANAGEMENT: {
        subTabsInitialState = initialStateGameManagement
        break
      }
      case DOMAIN_TYPES.SUPPORT: {
        subTabsInitialState = initialStateSupport
        break
      }
      case DOMAIN_TYPES.ACCOUNT_MANAGEMENT: {
        subTabsInitialState = initialStateAccountManagement
        break
      }
    }

    setData(
      DOMAIN,
      {
        MAIN_DRAWER_OPEN: isDrawerOpen ? false : true,
        subTabs: subTabsInitialState,
      },
      dispatch,
    )
  }

  let options: any = [...Object.values(DOMAIN_TYPES)]
  if (['demo', 'demo_japanese'].includes(USER_DATA?.organization_name)) {
    options = options.concat('A/B Testing (Coming Soon)')
    options = options.concat('AI Models (Coming Soon)')
  }

  let disabledOptions = [false, false, false, true, true, true]
  if (
    USER_DATA?.module_access_level &&
    '4' in USER_DATA?.module_access_level &&
    USER_DATA?.module_access_level['4'] === 'editor'
  ) {
    disabledOptions[3] = false
  }

  return (
    <Drawer
      onMouseEnter={() => openDrawerOnHover(true)}
      onMouseLeave={() => openDrawerOnHover(false)}
      variant="permanent"
      open={isDrawerOpen}
      sx={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background:
          theme.palette.mode === 'dark'
            ? newColor.midnight
            : newColor.cultured,
      }}
    >
      {/* <DrawerHeader
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        sx={{
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
        }}
      >
        <DrawerLogo onClick={() => setShowAnnouncementModal(true)} />
      </DrawerHeader>

      <Divider /> */}

      <SubTabsScrollbox />

      {DOMAIN === DOMAIN_TYPES.ANALYTICS &&
        canSeeExplore(USER_DATA) &&
        window.location.pathname?.startsWith('/analytics') && (
          <ExplorerButton />
        )}
    </Drawer>
  )
}
