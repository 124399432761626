import { useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material'
import CreateOrganization from '../../components/admin_new/createorganization'
import AddSocialConnection from '../../components/admin_new/addSocialConnection'
import SetUserOrgRole from '../../components/admin_new/setUserOrgRole'
import AddContract from '../../components/admin_new/addContract'
import AssociateContractToOrg from '../../components/admin_new/associateContractToOrg'
import AssociateUserToOrg from '../../components/admin_new/associateUserToOrg'
import CreateApiKeyOrg from '../../components/admin_new/createApiKeyOrg'
import BustUserCache from '../../components/admin_new/bustUserCache'
import BustApiKeysCache from '../../components/admin_new/bustApiKeysCache'
import BustAllCache from '../../components/admin_new/bustAllCache'
import CreateOrg from '../../components/admin_new/createSigmaUser'
import CircularPageHeader from '../../components/atoms/v2/circularPageHeader'
import PageContainer from '../../components/atoms/v2/pageContainer'
import { useContext, useEffect, useState, useCallback } from 'react'
import CreateParentTabModal from '../../components/popups/CreateParentTabModal'
import CreateNewTabModal from '../../components/popups/CreateNewTabModal'
import { Auth0Context } from '../../contexts/Auth0Context'
import { lowerCaseAndReplaceSpacesAndDashes } from '../../utils/string'
import {
  getOrgList,
  getSigmaAccessToken,
  getTeamsRef,
  getDistinctTabs as getDistinctTabsAPI,
  getParentTabsApi,
} from '../../utils/api/queries'
import _ from 'lodash'
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
} from '../../utils/user'
import { newColor } from '../../consts/colors'
import APIsSVG from '../../assets/API_Icon.svg'
import APIIconDark from '../../assets/API_Icon_dark.svg'
import OrgImg from '../../assets/Org_Management_Icon.svg'
import OnboardImg from '../../assets/Admin_Onboarding_Icon.svg'
import Card from '../../components/atoms/v2/card'
import { ReactComponent as TabManagementIcon } from 'src/assets/tab_management_icon.svg'
import { ReactComponent as TabOrderIcon } from 'src/assets/large_text_icon.svg'
import GlobalTabsManagement, {
  TabManagementActionButtons,
  ToggleActionButton,
} from '../../components/admin_new/GlobalTabsManagement'
import OrganizationTabsManagementCard from '../../components/admin_new/OrgTabsManagement'
import AnnouncementManagement from '../../components/announcementManagement'
import { useModules } from '../../components/organisms/v2/profile/useGetModules'
import SetModuleAccess from '../../components/admin_new/setModuleAccess'
import SetSigmaRole from 'src/components/admin_new/SetSigmaRole'
import SetOrgModuleAccess from '../../components/admin_new/setOrgModuleAccess'
import CreateMailgunSubAccount from '../../components/admin_new/createMailgunSubAccount'
import SyncDomains from '../../components/admin_new/SyncDomains'
import AssociateERC20ContractToOrg from '../../components/admin_new/associateERC20ContractToOrg'
import CreateKochavaAppIdOrg from '../../components/admin_new/createKochavaAppIdOrg'
import DowngradeOrganization from '../../components/admin_new/downgradeOrganization'

interface Org {
  label: string
  value: number
}
interface OrgData {
  name: string
  id: number
  modules_access_level?: string[]
}
interface Team {
  label: string
  value: string
}

interface DataTeam {
  name: string
  teamId: string
}

export default function AdminNew() {
  const theme = useTheme()
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const { modules } = useModules()

  const { getWithAccessToken, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [orgs, setOrgs] = useState<Org[]>([])
  const [sigmaTeams, setSigmaTeams] = useState<Team[]>([])
  const [sigmaTeam, setSigmaTeam] = useState<Team | null>(null)
  const [distinctTabs, setDistinctTabs] = useState([])
  const [parentTabs, setParentTabs] = useState([])
  const [showCreateParentTabModal, setShowCreateParentTabModal] =
    useState<boolean>(false)
  const [showCreateNewTabModal, setShowCreateNewTabModal] =
    useState<boolean>(false)

  const handleLogTabEvent = useCallback(
    async ({ tabId, parentTabId, moduleId, changeType }: any) => {
      if (!_.isEmpty(sdk)) {
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'organization_configuration_change',
            event: {
              configuration_type: 'tab_layout',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
              details: {
                parent_tab_identifier: parentTabId,
                tab_identifier: tabId,
                module_identifier: moduleId,
                change: changeType,
              },
            },
          },
        ])
      }
    },
    [sdk, userData],
  )

  const getDistinctTabs = useCallback(async () => {
    const data = await getDistinctTabsAPI(getWithAccessToken)
    setDistinctTabs(data?.results)
  }, [getWithAccessToken])

  const getParentTabs = useCallback(async () => {
    const data = await getParentTabsApi(getWithAccessToken)
    setParentTabs(data?.results)
  }, [getWithAccessToken])

  const handleCreateParentTab = async () => {
    setShowCreateParentTabModal(true)
  }

  const handleCreateTab = async () => {
    setShowCreateNewTabModal(true)
  }

  const getOrphanTeams = async (orgs: any[]) => {
    const tokens = await getSigmaAccessToken()

    const teamsRes = await getTeamsRef(tokens)

    if (!teamsRes) return
    const teams = await teamsRes.json()
    const dbOrgsSet = new Set(orgs.map((dbOrg: any) => dbOrg.label))

    let orphanTeams = teams.filter(
      (team: any) =>
        !dbOrgsSet.has(lowerCaseAndReplaceSpacesAndDashes(team.name)),
    )

    if (orphanTeams.length > 0) {
      setSigmaTeams(
        orphanTeams.map((team: DataTeam) => ({
          label: team.name,
          value: team.teamId,
        })),
      )
      setSigmaTeam({
        label: orphanTeams[0].name,
        value: orphanTeams[0].teamId,
      })
    }
  }

  const getOrgs = useCallback(() => {
    try {
      getOrgList(
        getWithAccessToken,
        (isHelikaAdmin(userData) || isHelikaSuperAdmin(userData)).toString(),
      )
        .then((resp: any) => {
          if (!resp || !resp.results) return
          let newOrgs = resp.results.map((org: OrgData) => ({
            label: org.name,
            value: org.id,
            modules_access_level: org.modules_access_level,
          }))
          setOrgs(newOrgs)
          getOrphanTeams(newOrgs)
        })
        .catch((err: any) => {
          console.log(err)
        })
    } catch (e) {
      console.log(e)
    }
  }, [getWithAccessToken, userData])

  // isolate the logic of getting the token and refreshing it
  //get organizations table
  useEffect(() => {
    if (isLoading || !isAuthenticated || _.isEmpty(userData) || orgs.length > 0)
      return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData)
    ) {
      window.location.replace('/profile')
    }
    getOrgs()
    getDistinctTabs()
    getParentTabs()
  }, [
    getWithAccessToken,
    isAuthenticated,
    isLoading,
    orgs,
    userData,
    getDistinctTabs,
    getParentTabs,
    getOrgs,
  ])

  return (
    <PageContainer>
      <CircularPageHeader
        text="ADMIN PANEL  |  ONBOARDING PANEL"
        icon={OnboardImg}
        sx={{ marginX: '1rem' }}
      />
      <Box
        sx={{
          margin: '20px',
          border: '1px solid',
          borderColor:
            theme.palette.mode === 'dark'
              ? newColor.darkGunmetal
              : newColor.platinum,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? newColor.charcoal
              : newColor.cultured,
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'left',
          marginBottom: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '112.5 %',
            marginBottom: '15px',
          }}
        >
          <img
            src={theme.palette.mode === 'dark' ? APIsSVG : APIIconDark}
            alt="APIs"
          />
          &nbsp;&nbsp;APIs
        </Typography>
        <CreateOrg
          sigmaTeam={sigmaTeam}
          setSigmaTeam={setSigmaTeam}
          sigmaTeams={sigmaTeams}
          setSigmaTeams={setSigmaTeams}
        />
        <AddContract />
        <AssociateContractToOrg orgs={orgs} />
        <AssociateERC20ContractToOrg orgs={orgs} />
        <AssociateUserToOrg orgs={orgs} />
        <CreateApiKeyOrg orgs={orgs} />
        <CreateKochavaAppIdOrg orgs={orgs} />
        <CreateMailgunSubAccount orgs={orgs} />
        <SyncDomains orgs={orgs} />
        <SetModuleAccess orgs={orgs} enableOrgDropdown />
      </Box>
      <CircularPageHeader
        text="ORGANIZATION MANAGEMENT"
        icon={OrgImg}
        sx={{ marginX: '1rem' }}
      />
      <Box
        sx={{
          margin: '20px',
          border: '1px solid',
          borderColor:
            theme.palette.mode === 'dark'
              ? newColor.darkGunmetal
              : newColor.platinum,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? newColor.charcoal
              : newColor.cultured,
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'left',
          marginBottom: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '112.5 %',
            marginBottom: '15px',
          }}
        >
          <img
            src={theme.palette.mode === 'dark' ? APIsSVG : APIIconDark}
            alt="APIs"
          />
          &nbsp;&nbsp;APIs
        </Typography>
        <CreateOrganization getOrgs={getOrgs} />
        <AddSocialConnection orgs={orgs} enableOrgDropdown />
        <SetUserOrgRole orgs={orgs} enableOrgDropdown />
        <SetSigmaRole orgs={orgs} enableOrgDropdown />
        <SetOrgModuleAccess orgs={orgs} enableOrgDropdown getOrgs={getOrgs} />
      </Box>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          flexWrap: 'wrap',
          margin: '0 1.25em 0 1.25em',
        }}
      >
        <Card
          label="TAB MANAGEMENT"
          icon={<TabManagementIcon />}
          actions={
            <TabManagementActionButtons>
              <ToggleActionButton
                handleCreateParentTab={handleCreateParentTab}
                handleCreateTab={handleCreateTab}
              />
            </TabManagementActionButtons>
          }
          subheaderText="TABS LIST"
          subheaderIcon={<TabOrderIcon />}
          sx={{ width: '40%', minWidth: 'fit-content' }}
        >
          <>
            <GlobalTabsManagement
              orgs={orgs}
              distinctTabs={distinctTabs}
              onUpdated={getDistinctTabs}
            />
          </>
        </Card>
        <OrganizationTabsManagementCard
          distinctTabs={distinctTabs}
          orgs={orgs}
        />
      </div>
      <CircularPageHeader
        text="CACHE MANAGEMENT"
        icon={OrgImg}
        sx={{ marginX: '1rem' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          flexWrap: 'wrap',
          margin: '0 1.25em 0 1.25em',
        }}
      >
        <Card
          label="USERS"
          sx={{ width: '33%', minWidth: 'fit-content', padding: '1rem' }}
        >
          <BustUserCache orgs={orgs} />
        </Card>
        <Card
          label="API KEYS"
          sx={{ width: '33%', minWidth: 'fit-content', padding: '1rem' }}
        >
          <BustApiKeysCache />
        </Card>
        <Card
          label="ALL"
          sx={{ width: '33%', minWidth: 'fit-content', padding: '1rem' }}
        >
          <BustAllCache />
        </Card>
      </div>
      <CircularPageHeader
        text="ORGANIZATION MANAGEMENT"
        icon={OrgImg}
        sx={{ marginX: '1rem' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          flexWrap: 'wrap',
          margin: '0 1.25em 0 1.25em',
        }}
      >
        <Card
          label="DOWNGRADE ORGANIZATION"
          sx={{ width: '33%', minWidth: 'fit-content', padding: '1rem' }}
        >
          <DowngradeOrganization orgs={orgs} />
        </Card>
      </div>
      <Box
        sx={{
          margin: '20px',
          border: '1px solid',
          borderColor:
            theme.palette.mode === 'dark'
              ? newColor.darkGunmetal
              : newColor.platinum,
          backgroundColor:
            theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'left',
          marginBottom: '20px',
        }}
      >
        <AnnouncementManagement />
      </Box>
      <CreateParentTabModal
        open={showCreateParentTabModal}
        setOpen={setShowCreateParentTabModal}
        modules={modules}
        onCreated={async (moduleId: any, tab: any) => {
          const { results } = tab
          await getDistinctTabs()
          setShowCreateParentTabModal(false)
          handleLogTabEvent({
            changeType: 'parent_tab_created',
            tabId: results.id,
            moduleId,
          })
        }}
      />
      <CreateNewTabModal
        open={showCreateNewTabModal}
        setOpen={setShowCreateNewTabModal}
        parentTabs={parentTabs}
        onCreated={async (parentTabId: any, tab: any) => {
          const { results } = tab
          await getParentTabs()
          await getDistinctTabs()
          setShowCreateNewTabModal(false)
          handleLogTabEvent({
            changeType: 'tab_created',
            tabId: results[0].id,
            parentTabId,
          })
        }}
      />
    </PageContainer>
  )
}
