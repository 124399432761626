//imports
import { v4 as uuidv4 } from 'uuid';
var CryptoJS = require('crypto-js');

export default function getSigmaURL(embedPath: string, userData: any, defaultFilters?: any[], theme?: any) {

  function hmacSha256Hex(secret: string, message: any): string {
    let hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
    hmac.update(message);
    return CryptoJS.enc.Hex.stringify(hmac.finalize());
  }

  const MODE = 'userbacked';
  const user_email = userData?.email?.replaceAll('@helika.io', '+embed@helika.io');
  let teams = userData.sigma_team;
  let account_type = userData.sigma_role;
  const user_id = userData.organization_id;
  const ATTRIBUTE_NAME = 'organization';
  const ATTRIBUTE_VALUE = 'thisworks';
  const SESSION_LENGTH = 3600 * 10;
  const TIME_NOW = Math.floor(new Date().getTime() / 1000) - 60;
  const EMBED_SECRET = '4elSWRdmVCAGzK76Eu4PO';

  // :nonce - Any random string you like but cannot be repeated within the hour.
  let searchParams = `?:nonce=${uuidv4()}`;

  // :mode - set to "userbacked"
  searchParams += `&:mode=${MODE}`;

  // :user_email - The email address that will be associated with the user's account
  searchParams += `&:email=${user_email}`;

  // :external_user_team -  The name of your embedded users' team(s). (e.g. Team%20A%2C%20Team%20B)
  searchParams += `&:external_user_team=${encodeURIComponent(teams)},competitive_team,social_team,ecosystem_team`;

  // :ua_<user_attribute> - [optional] - A user attribute to be applied for RLS; for each independent attribute, use a :ua_<ATTRIBUTE_NAME> parameter
  searchParams += `&:ua_${ATTRIBUTE_NAME}=${encodeURIComponent(userData.organization_name) || ATTRIBUTE_VALUE}`;

  // :account_type - [optional] By default, all embed users are granted Explorer permissions. Format lowercase (e.g. "explorer", "creator")
  searchParams += `&:account_type=${account_type}`;

  // :session_length - The number of seconds the user should be allowed to view the embed
  searchParams += `&:session_length=${SESSION_LENGTH}`;

  // :session_length - The number of seconds the user should be allowed to view the embed
  if (theme.palette.mode === 'light') {
    searchParams += `&:theme=Helika_Light`;
  }

  // :lng - language localization
  if (userData.organization_name === 'demo_japanese') {
    searchParams += `&:lng=ja`;
  }

  //add a default filter depending on organization
  if (defaultFilters) {
    defaultFilters.forEach((filter: any) => {
      if (!filter?.value || !filter?.key) return;
      searchParams += `&${encodeURIComponent(filter.key)}=${encodeURIComponent(filter.value)}`;
    })
  }

  // :time - Current Time as UNIX Timestamp
  searchParams += `&:time=${TIME_NOW}`;

  // :external_user_id - a unique JSON string identifying the viewer
  searchParams += `&:external_user_id=${user_id}`;

  const urlWithSearch = embedPath + searchParams;
  const signature = hmacSha256Hex(EMBED_SECRET, urlWithSearch);

  return `${urlWithSearch}&:signature=${signature}`;
}