import { useContext, useState } from 'react'
import { styled as muiStyled, Typography, Button, Box } from '@mui/material'
import { toast } from 'react-toastify'

import { Auth0Context } from '../../contexts/Auth0Context'
import loadingSVG from '../../assets/loading.svg'
import { bustAllCacheApi } from '../../utils/api/queries'

const StyledTxt = muiStyled(Typography)(() => ({
  color: 'white',
  fontSize: '14px',
}))

const BustCacheRow = muiStyled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}))

export default function BustAllCache() {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [loading, setLoading] = useState(false)

  async function bustAllCache() {
    try {
      setLoading(true)
      bustAllCacheApi(postWithAccessToken)
        .then((resp: any) => {
          setLoading(false)
          toast.success('all cache busted')
        })
    } catch (e: any) {
      setLoading(false)
      toast.error(e?.message)
    }
  }

  return (
    <Box
      sx={{
        margin: '1rem 0.5rem 0.5rem 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <BustCacheRow>
        <label>All Cache:</label>
        <Button
          onClick={bustAllCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </Button>
      </BustCacheRow>
    </Box>
  )
}
