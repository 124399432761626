import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
} from '@mui/material'
import { useSelector } from 'react-redux'

import { Auth0Context } from '../../contexts/Auth0Context'

import ExpandIcon from '../../assets/expand.svg'
import SetUserRoleImg from '../../assets/User_Role_Organization_Icon.svg'
import SetUserRoleImg_dark from '../../assets/User_Role_Organization_Icon_dark.svg'
import loadingSVG from '../../assets/loading.svg'
import { getOrgUsersApi, setUserOrgRoleApi } from '../../utils/api/queries'
import { StyledAccordion } from '../AccordionGroup'
import { toast } from 'react-toastify'
import { newColor } from '../../consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const StyledTextarea = muiStyled('textarea')(({ theme }) => ({
  width: '100%',
  height: '80px',
  borderRadius: '10px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))
const Container = muiStyled('div')(({ theme }) => ({
  alignSelf: 'center',
  marginLeft: '28px',
  marginRight: '28px',
}))
const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '20vw',
  maxWidth: '315px',
  minWidth: '80px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
}
interface OrgUser {
  label: string
  value: string
}
interface UserData {
  id: number
  email: string
  name: string | null
  org_role: string
  organization_id: string
}
export default function SetUserOrgRole({
  orgs,
  enableOrgDropdown,
}: {
  orgs: any
  enableOrgDropdown?: boolean
}) {
  const theme = useTheme()
  const orgRoleData = useSelector((state: any) => state.OrgRoles.ORG_ROLE)
  const { organization_id } = useSelector((state: any) => state.user)
  const { patchWithAccessToken, getWithAccessToken } = useContext(Auth0Context)
  const [role, setRole] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [orgUsers, setOrgUsers] = useState<OrgUser[]>([])
  const [orgUser, setOrgUser] = useState<OrgUser | null>(null)
  const [resData, setResData] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const [loading, setLoading] = useState(false)
  let userRolesAvailable: {
    admin: string
    member: string
  } = orgRoleData
  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    if (enableOrgDropdown) {
      setOrg({
        label: orgs[0].label,
        value: orgs[0].value,
      })
    } else {
      const userOrg = orgs.find((org: any) => org.value === organization_id)
      setOrg(
        userOrg
          ? {
              label: userOrg.label,
              value: userOrg.value,
            }
          : null,
      )
    }
  }, [orgs, enableOrgDropdown, organization_id])
  useEffect(() => {
    if (!orgUsers || orgUsers.length < 1) return
    setOrgUser({
      label: orgUsers[0].label,
      value: orgUsers[0].value,
    })
  }, [orgUsers])

  const getOrgUsers = useCallback(async () => {
    if (org === null) return
    setIsDisabled(true)
    getOrgUsersApi(getWithAccessToken, Number(org?.value))
      .then((res) => {
        if (!res || !res.results) return
        setOrgUsers(
          res.results.map((orgUser: UserData) => ({
            label: orgUser.name !== null ? orgUser.name : orgUser.email,
            value: orgUser.email,
          })),
        )
        setIsDisabled(false)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken, org])

  useEffect(() => {
    getOrgUsers()
  }, [org, getWithAccessToken, getOrgUsers])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org: Org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
    }
  }
  const setSelectedOrgUser = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgUsers.find(
        (orgUser: OrgUser) => orgUser.value === e?.target.value,
      )
      setOrgUser(newOption || null)
    }
  }

  const setAdminRole = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setRole(e?.target.value)
    }
  }

  const setUserOrgRole = async () => {
    if (role === '') {
      setResData('Please select Organization role.')
      return
    }
    setLoading(true)
    try {
      setResData('')
      await setUserOrgRoleApi(patchWithAccessToken, orgUser?.value, role)
        .then((res) => {
          if (res.status === 'ok') {
            toast.success(
              `Successful ${orgUser?.value} user got ${role} role in "${org?.label}" organization`,
            )
            setResData(
              `Updated: ${orgUser?.value} user got ${role} role in "${org?.label}" organization`,
            )
          } else {
            toast.error(`Failed: ${res.message}`)
            setResData(res?.message)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={
            theme.palette.mode === 'dark' ? SetUserRoleImg : SetUserRoleImg_dark
          }
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;SET USER’S ORGANIZATION ROLE</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {enableOrgDropdown && (
              <InputGroup>
                <StyledTxt>Choose organization</StyledTxt>
                <SelectCategory value={org?.value} onChange={setSelectedOrg}>
                  <option disabled value="">
                    {' '}
                    -- select an option --{' '}
                  </option>
                  {orgs
                    ?.sort((a: any, b: any) =>
                      a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                    )
                    .map((org: Org) => (
                      <option key={org.value} value={org.value}>
                        {org.label}
                      </option>
                    ))}
                </SelectCategory>
              </InputGroup>
            )}
            <InputGroup>
              <Typography>Select User</Typography>
              <SelectCategory
                required
                value={orgUser?.value}
                onChange={setSelectedOrgUser}
                disabled={isDisabled}
              >
                <option disabled value="">
                  {' '}
                  -- select an option --{' '}
                </option>
                {orgUsers
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  .map((orgUser: OrgUser) => (
                    <option key={orgUser.value} value={orgUser.value}>
                      {orgUser.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Organization Role</Typography>
              <SelectCategory required value={role} onChange={setAdminRole}>
                <option disabled value="">
                  {' '}
                  -- select an option --{' '}
                </option>
                {Object.values(userRolesAvailable).map((userRole) => (
                  <option key={userRole} value={userRole}>
                    {userRole}
                  </option>
                ))}
              </SelectCategory>
            </InputGroup>

            <Button
              onClick={setUserOrgRole}
              className="helikaButtonClass"
              style={{ height: 28, marginTop: 24 }}
              disabled={loading}
            >
              {loading && (
                <img
                  width="14px"
                  className="animate-spin"
                  style={{ marginRight: '2px' }}
                  src={loadingSVG}
                  alt=""
                />
              )}
              <StyledTxt>Submit</StyledTxt>
            </Button>
          </div>
          <div style={{ margin: 'auto' }}>
            <StyledTextarea value={resData} readOnly />
          </div>
        </Container>
      </AccordionDetails>
    </StyledAccordion>
  )
}
