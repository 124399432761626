import OrgImg from '../../assets/Org_Management_Icon.svg'
import PageContainer from '../atoms/v2/pageContainer'
import CircularPageHeader from '../atoms/v2/circularPageHeader'
import Card from '../atoms/v2/card'
import LinkSocialMedia from '../OrganizationManagement/LinkSocialMedia'

export default function SocialMediaConnection() {
  return (
    <PageContainer>
      <CircularPageHeader text="Social Connection Management" icon={OrgImg} />
      <Card
        label="Authorize First Party API"
        sx={{
          minWidth: '70em'
        }}
      >
        <LinkSocialMedia />
      </Card>
    </PageContainer>
  )
}
