import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  AccordionDetails,
} from '@mui/material'
import dayjs from 'dayjs'
import {
  getCustomQueryRuns,
  getCustomQueryCategories,
  getPreSignedS3Url,
} from '../../../../utils/api/queries'
import { Auth0Context } from '../../../../contexts/Auth0Context'
// component
import LoadingComponent from '../../../../components/LoadingComponent'
// redux
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../../../state'
// types
import {
  ICustomQueryRun,
  ICustomQueryCategory,
  IPaginationMeta,
} from '@/types/audience'
// style
import {
  HeaderImage,
  HeaderImageWrapper,
  Accordion,
  AccordionSummary,
  TableCell,
  TableCellContent,
  Pagination,
} from './Audiences.styled'
import { newColor } from '../../../../consts/colors'
// assets
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CategoryManagementSvg from '../../../../assets/category_management.svg'
import DateHeaderSvg from '../../../../assets/notification_date_icon.svg'
import CsvHeaderSvg from '../../../../assets/csv.svg'
import DateRowSvg from '../../../../assets/date_icon.svg'
import TimeRowSvg from '../../../../assets/time_icon.svg'
import DownloadSvg from '../../../../assets/download_white.svg'

const CategoryCSV: React.FC<{
  customQueryCategory: ICustomQueryCategory
  onSelect: (category: ICustomQueryCategory | null) => void
  expanded?: boolean
}> = ({ customQueryCategory, expanded = false, onSelect }) => {
  const dispatch = useDispatch()
  const { setNotifications } = bindActionCreators(actionCreators, dispatch)
  const { getWithAccessToken } = useContext(Auth0Context)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<IPaginationMeta>({
    total_count: 0,
    page: 1,
    page_size: 5,
  })
  const [customQueryRuns, setCustomQueryRuns] = useState<ICustomQueryRun[]>([])

  const fetchCSVs = async ({
    categoryId,
    page = 1,
    pageSize = 5,
  }: {
    categoryId: string
    page?: number
    pageSize?: number
  }) => {
    try {
      setLoading(true)
      const data = await getCustomQueryRuns(getWithAccessToken, {
        category_id: categoryId,
        page,
        page_size: pageSize,
      })
      if (data?.results) {
        setCustomQueryRuns(data.results)
      }
      if (data?.meta) {
        setPagination(data.meta)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCSVs({
      categoryId: customQueryCategory.id,
    })
  }, [customQueryCategory])

  const renderTable = useMemo(() => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPagination((prevState) => ({ ...prevState, page: value }))
      fetchCSVs({
        categoryId: customQueryCategory.id,
        page: value,
      })
    }

    const handleDownloadCSV = async (row: ICustomQueryRun) => {
      try {
        const data = await getPreSignedS3Url(getWithAccessToken, row.id)

        window.open(data.results.url, '_blank')
      } catch (error) {
        console.error('Error downloading CSV:', error)
        setNotifications({
          type: 'READ_NOTIFICATIONS',
          payload: `Error downloading CSV: ${error}`,
        })
      }
    }

    return (
      <>
        <TableContainer
          component={Paper}
          sx={{
            border: 'solid',
            borderWidth: 1,
            borderRadius: '10px',
            borderColor: newColor.darkGunmetal,
          }}
        >
          <Table aria-label={`${customQueryCategory.name} csv table`}>
            <TableHead
              sx={{
                borderBottom: 'solid',
                borderWidth: 1,
                borderRadius: '10px',
                borderColor: newColor.darkGunmetal,
              }}
            >
              <TableRow>
                <TableCell divider={true} header={true}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ paddingLeft: '48px' }}
                  >
                    <img src={DateHeaderSvg} alt="" />
                    <Typography
                      sx={{ fontSize: 12, fontWeight: 400, mt: '2px' }}
                    >
                      DATE
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell header={true}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ paddingLeft: '48px' }}
                  >
                    <img src={CsvHeaderSvg} alt="" />
                    <Typography
                      sx={{ fontSize: 12, fontWeight: 400, mt: '2px' }}
                    >
                      DOWNLOAD
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customQueryRuns.length ? (
                customQueryRuns.map((row, index) => (
                  <TableRow key={`${customQueryCategory.name}-${index}`}>
                    <TableCell
                      // component="th"
                      scope="row"
                      left={true}
                      first={index == 0}
                      last={index == customQueryRuns.length - 1}
                    >
                      <TableCellContent left={true} odd={!(index % 2)}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <img src={DateRowSvg} alt="" />
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              textWrap: 'nowrap',
                              mt: '2px',
                            }}
                          >
                            {dayjs(row.uploaded_at).format('YYYY-MM-DD')}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <img src={TimeRowSvg} alt="" />
                          <Typography
                            sx={{ fontSize: 14, fontWeight: 400, mt: '2px' }}
                          >
                            {dayjs(row.uploaded_at).format('HH:mm:ss')}
                          </Typography>
                        </Stack>
                      </TableCellContent>
                    </TableCell>
                    <TableCell
                      right={true}
                      first={index == 0}
                      last={index == customQueryRuns.length - 1}
                    >
                      <TableCellContent right={true} odd={!(index % 2)}>
                        <a
                          className="helikaButtonClass"
                          style={{
                            padding: '4px 12px',
                            textDecoration: 'none',
                          }}
                          onClick={() => handleDownloadCSV(row)}
                        >
                          <img
                            src={DownloadSvg}
                            alt=""
                            style={{ marginRight: '8px' }}
                          />
                          DOWNLOAD.CSV
                        </a>
                      </TableCellContent>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} header={true}>
                    <Typography textAlign="center">No Data</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {pagination.total_count > 5 && (
          <Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
            <Pagination
              shape="rounded"
              count={Math.ceil(pagination.total_count / pagination.page_size)}
              page={pagination.page}
              onChange={handleChange}
            />
          </Stack>
        )}
      </>
    )
  }, [customQueryCategory, customQueryRuns, pagination])

  return (
    <Accordion
      sx={{
        border: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: newColor.jet,
      }}
      expanded={expanded}
      onChange={(_, expanded) => {
        if (expanded) onSelect(customQueryCategory)
        else onSelect(null)
      }}
    >
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        <Stack direction="column" gap={2}>
          <Typography style={{ fontSize: '16px', fontWeight: 400 }}>
            {customQueryCategory.name}
          </Typography>

          <Typography style={{ fontSize: '14px', fontWeight: 400 }}>
            {customQueryCategory.description}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? <LoadingComponent /> : renderTable}
      </AccordionDetails>
    </Accordion>
  )
}

const Audiences: React.FC = () => {
  const { getWithAccessToken } = useContext(Auth0Context)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<ICustomQueryCategory[]>([])

  const [activeCategory, setActiveCategory] =
    useState<ICustomQueryCategory | null>(null)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true)
        const data = await getCustomQueryCategories(getWithAccessToken, {})
        if (data?.results) {
          setCategories(data.results)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    fetchCategories()
  }, [])

  const handleSelect = useCallback(
    (category: ICustomQueryCategory | null) => {
      setActiveCategory(category)
    },
    [activeCategory, setActiveCategory],
  )

  const renderCategoryList = useMemo(() => {
    return (
      <>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          categories.map((category) => (
            <CategoryCSV
              key={category.id}
              customQueryCategory={category}
              onSelect={handleSelect}
            />
          ))
        )}
      </>
    )
  }, [isLoading, categories, activeCategory, setActiveCategory])

  const renderActiveCategory = useMemo(() => {
    return (
      <>
        {activeCategory && (
          <CategoryCSV
            customQueryCategory={activeCategory}
            expanded={true}
            onSelect={handleSelect}
          />
        )}
      </>
    )
  }, [isLoading, categories, activeCategory, setActiveCategory])

  return (
    <Stack sx={{ minHeight: '100%' }}>
      <Stack
        direction="column"
        gap={2}
        sx={{
          maxWidth: { xs: '100%', md: '90%' },
          width: '100%',
          height: '100%',
          position: 'relative',
          margin: '0px auto',
          padding: 2,
        }}
      >
        <HeaderImageWrapper>
          <HeaderImage
            src="https://img-c1.sigmacomputing.com/c2fd0412-75b7-4698-a930-5277adc78305/de9e4d52-c229-4161-b434-2684ff96aa71"
            alt=""
          />
        </HeaderImageWrapper>

        <Paper
          sx={{
            border: 'solid',
            borderWidth: 1,
            borderRadius: '10px',
            borderColor: newColor.darkGunmetal,
            p: 3,
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ mb: 2 }} gap={1}>
            <img
              src={CategoryManagementSvg}
              alt="Category Management Image"
              style={{ marginBottom: '4px' }}
            />
            <Typography variant="h6">Categories</Typography>
          </Stack>
          <Stack
            direction="column"
            gap={2}
            sx={{
              transition: 'all 0.5s',
            }}
          >
            {!!activeCategory ? renderActiveCategory : renderCategoryList}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  )
}

export default Audiences
