import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid,
  TextField,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import CreateTab_Icon from '../../assets/Create_Tab_Icon.svg'
import Dashboard_Icon from '../../assets/Dashboard_Icon.svg'
import Dashboard_Icon_Light from '../../assets/Dashboard_Icon_Light.svg'
import { isStringEmpty } from '../../utils/string'
import Analytics from '../../assets/analytics_icon.svg'
import Add_Icon from '../../assets/add_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import Delete_Icon from '../../assets/delete_button.svg'
import { Auth0Context } from '../../contexts/Auth0Context'
import { createNewTabApi } from '../../utils/api/queries'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface SimpleDialogProps {
  onCreated?: any
  open: boolean
  setOpen: any
  parentTabs: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

export default function CreateNewTabModal(props: SimpleDialogProps) {
  const { open, setOpen, parentTabs, onCreated } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const { postWithAccessToken } = useContext(Auth0Context)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [parentTabId, setParentTabId] = useState<number>(0)
  const [dashboardMappings, setDashboardMappings] = useState<
    Dashboard_mapping[]
  >([])

  const handleClose = () => {
    setOpen(false)
  }

  const resetFields = () => {
    setName('')
    setIcon('')
    setDescription('')
    setDashboardMappings([])
  }

  async function createNewTabQuery() {
    try {
      setLoading(true)

      isStringEmpty(name, 'Name required')
      isStringEmpty(description, 'Description required')
      isStringEmpty(icon, 'Tab Icon required')

      if (name && description && icon && parentTabId) {
        setLoading(true)
        const results = await createNewTabApi(
          postWithAccessToken,
          name.trim(),
          description,
          icon.trim(),
          parentTabId,
          dashboardMappings,
        )
        resetFields()
        onCreated(parentTabId, results)
        setLoading(false)
      } else {
        toast.warning('Please enter New Tab Name and other fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={CreateTab_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              CREATE A NEW TAB
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;TAB INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TAB NAME:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Input
                value={name}
                placeholder="Name"
                onChange={(e) => {
                  setName(e.currentTarget.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                PARENT TAB:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                defaultValue={''}
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setParentTabId(Number(event.target.value))
                }}
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={parentTabId ? parentTabId.toString() : ''}
              >
                {parentTabs &&
                  parentTabs.map((p: { id: number; name: string }) => (
                    <MenuItem
                      value={p.id}
                      key={p.id}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {p.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TAB ICON:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Input
                value={icon}
                placeholder="ICON"
                onChange={(e) => {
                  setIcon(e.currentTarget.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'start', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                DESCRIPTION:
              </div>
            </Grid>
            <Grid item xs={9}>
              <TextField
                className={
                  theme.palette.mode === 'dark'
                    ? 'textFieldClass'
                    : 'textFieldClassLight'
                }
                value={description}
                onChange={(e) => {
                  setDescription(e.currentTarget.value)
                }}
                size={'small'}
                multiline
                rows={4}
                placeholder="Tab Description"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          marginTop={'8px'}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'dark'
                  ? Dashboard_Icon
                  : Dashboard_Icon_Light
              }
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;DASHBOARDS
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            paddingLeft={'35px'}
            marginTop={'16px'}
          >
            {dashboardMappings?.map(
              (dashboard_mapping: Dashboard_mapping, index: number) => {
                return (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    key={index}
                    gap={'24px'}
                  >
                    <Input
                      value={dashboard_mapping.name}
                      placeholder="DASHBOARD NAME"
                      onChange={(e) => {
                        setDashboardMappings((prevState: any[]) => {
                          let previousValues = [...prevState]
                          if (previousValues[index]) {
                            previousValues[index].name = e.target.value
                            return previousValues
                          }
                          return previousValues
                        })
                      }}
                    />
                    <Input
                      value={dashboard_mapping.iframe_link}
                      placeholder="SIGMA URL"
                      onChange={(e) => {
                        setDashboardMappings((prevState: any[]) => {
                          let previousValues = [...prevState]
                          if (previousValues[index]) {
                            previousValues[index].iframe_link =
                              e.target.value?.trim()
                            return previousValues
                          }
                          return previousValues
                        })
                      }}
                      sx={{
                        width: '316px',
                      }}
                    />
                    <div className="flexrow">
                      <img
                        src={Delete_Icon}
                        style={{ marginRight: '0.5em', cursor: 'pointer' }}
                        alt=""
                        onClick={() => {
                          setDashboardMappings((prevState: any[]) => {
                            let newValues = [...prevState]?.filter(
                              (
                                dashboard_mapping: Dashboard_mapping,
                                mappingIndex: number,
                              ) => index !== mappingIndex,
                            )
                            return newValues
                          })
                        }}
                      />
                    </div>
                  </Box>
                )
              },
            )}
            <Box>
              <Button
                className="helikaButtonClass"
                onClick={() =>
                  setDashboardMappings((prevState: Dashboard_mapping[]) => {
                    return prevState.concat([
                      {
                        name: '',
                        iframe_link: '',
                      },
                    ])
                  })
                }
                sx={{
                  width: '220px',
                }}
              >
                <img src={Add_Icon} alt="Add" />
                &nbsp;ADD NEW DASHBOARD
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={createNewTabQuery}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
