import DialogTitle from '@mui/material/DialogTitle'
import { useContext, useEffect, useState } from 'react'
import React from 'react'
import { toast } from 'react-toastify'
import { useTheme } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Auth0Context } from '../../contexts/Auth0Context'
import { assignUserApi, unAssignReportApi } from '../../utils/api/queries'
import { newColor } from '../../consts/colors'
import LoadingComponent from '../LoadingComponent'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../state'
export interface SimpleDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose: any
  title?: string
  users?: any[]
  ticket: any
}

export default function AssignUser(props: SimpleDialogProps) {
  const { open, onClose, setOpen, title, users, ticket } = props

  const dispatch = useDispatch()
  const { setSupportState } = bindActionCreators(actionCreators, dispatch)
  const SUPPORT_TICKETS = useSelector(
    (state: any) => state.supportData.SUPPORT_TICKETS,
  )

  const theme = useTheme()

  const { patchWithAccessToken } = useContext(Auth0Context)

  let initialUser = users?.find(
    (userItem) => userItem.id === ticket?.assigned_to,
  )
  const [user, setUser] = useState<any>('')
  const [assignInProgress, setAssignInProgress] = useState<boolean>(false)

  function handleClose() {
    setOpen(!open)
    if (onClose) onClose()
  }

  async function setSelectedUser(event: SelectChangeEvent) {
    setUser(event.target.value as string)
  }

  //set intial user
  useEffect(() => {
    if (initialUser) {
      setUser(initialUser?.id?.toString())
    } else {
      setUser('')
    }
  }, [initialUser])

  const assignUserFunction = async (user_id: string) => {
    setAssignInProgress(true)
    const params = {
      support_ticket_ids: [ticket?.id],
    }
    await assignUserApi(patchWithAccessToken, Number(user_id), params).then(
      (res: any) => {
        if (res) {
          toast.success('User was assigned! Refreshing list.')
          const updatedTickets = SUPPORT_TICKETS.map((item: any) => {
            if (item.id === ticket.id) {
              return { ...item, assigned_to: user_id }
            }
            return item
          })
          setSupportState({
            type: 'SET_SUPPORT_TICKETS',
            payload: updatedTickets,
          })
          // loadUserReports(loadPage > 0 ? loadPage - 1 : 1)
          setOpen(false)
          return
        } else {
          toast.error('User could not be assigned to issue')
        }
      },
    )
    setAssignInProgress(false)
  }

  const unAssignReport = async () => {
    setAssignInProgress(true)
    await unAssignReportApi(patchWithAccessToken, ticket?.id).then((res) => {
      if (res) {
        toast.success('Ticket unassigned from user')
        setSupportState({
          type: 'SET_SUPPORT_TICKETS',
          payload: SUPPORT_TICKETS.map((item: any) => {
            if (item.id === ticket.id) {
              return { ...item, assigned_to: null }
            }
            return item
          }),
        })
        setOpen(false)
        return
      } else {
        toast.error('User could not be unassigned from ticket')
      }
    })
    setAssignInProgress(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          color: 'white',
          justifyContent: 'center',
          minWidth: '20em',
          margin: '1em',
        }}
      >
        <DialogTitle
          className={`${
            theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
          } centertext`}
        >
          {title}
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          marginBottom: 2,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <label>Select user to assign:</label>
        <Select
          required
          value={user}
          onChange={setSelectedUser}
          sx={{ marginBottom: 2 }}
        >
          {users
            ?.sort((a: any, b: any) =>
              (a?.name && a?.name !== 'None'
                ? a?.name
                : a?.email
              )?.toLowerCase() >
              (b?.name && b?.name !== 'None' ? b?.name : b.email)?.toLowerCase()
                ? 1
                : -1,
            )
            ?.map((user: any) => {
              return (
                <MenuItem key={user.id} value={user.id}>
                  {user?.name && user?.name !== 'None'
                    ? user?.name
                    : user.email}
                </MenuItem>
              )
            })}
        </Select>
        <Button
          onClick={() => {
            assignUserFunction(user)
          }}
          sx={{
            width: '18em',
            paddingLeft: 2,
            paddingRight: 2,
            margin: 'auto auto 1em auto',
          }}
          variant="contained"
          size="small"
          className="helikaButtonClass"
          disabled={assignInProgress}
        >
          {assignInProgress ? <LoadingComponent width="16" /> : 'Assign'}
        </Button>
        {initialUser && (
          <Button
            onClick={unAssignReport}
            sx={{ width: '18em', margin: 'auto' }}
            variant="contained"
            size="small"
            className="helikaButtonClass"
            disabled={assignInProgress}
          >
            {assignInProgress ? (
              <LoadingComponent width="16" />
            ) : (
              'Remove Assigned User'
            )}
          </Button>
        )}
      </Box>
    </Dialog>
  )
}
