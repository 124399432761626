const initialState = {};

export default function userReducer(state = initialState, action){
  switch (action.type) {
    case "CLEAR_DATA":
      return initialState;
    case "SET_USER_DATA":
      return action.payload;
    default:
      return state;
  }
};