import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Typography, useTheme } from '@mui/material';
import { newColor } from '../../consts/colors';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  onConfirm: any;
  org: any;
}


export default function DowngradeOrgModalWarning(props: SimpleDialogProps) {
  const { open, setOpen, onConfirm, org } = props;
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }} onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className='circleBackground' sx={{ justifyContent: 'center', minWidth: '20em', margin: '1em' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`}>{'Downgrade Organization'}</DialogTitle>
      </Box>
      <Box
        display='flex'
        flexDirection={'column'}
        sx={{ paddingLeft: 2, paddingRight: 2, marginBottom: 2, width: '100%', justifyContent: 'center' }}
      >
        <Typography
          variant='h4'
          sx={{ color: 'red', textAlign: 'center' }}
        >
          WARNING! <br />This action is permanent and will affect all users in that organization.
        </Typography>
        <Box sx={{ margin: 'auto' }}>
          Organization: {org}
        </Box>
        <Button
          className='helikaButtonClass'
          onClick={onConfirm}
          sx={{ margin: '1em 0 1em 0' }}
        >
          CONFIRM
        </Button>
      </Box>
    </Dialog>
  );
}
