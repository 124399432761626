//imports
import React from 'react'
import '../../../../App.css'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import { useTheme, Box, BoxProps, Typography } from '@mui/material'
//----------------------------------------------------------------------------------------------------------

interface CircularPageHeaderProps extends BoxProps {
  icon?: string
  text: string
  children?: JSX.Element | JSX.Element[]
}

export default function CircularPageHeader({
  text,
  icon,
  children,
  sx,
}: CircularPageHeaderProps) {
  const theme = useTheme()
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      flexWrap={'nowrap'}
      sx={sx}
      className={
        theme.palette.mode === 'dark'
          ? 'circularPageHeading dark'
          : 'circularPageHeading light'
      }
    >
      <Box display="flex" alignItems="center" sx={{ color: 'white' }}>
        {
          icon &&
          <img
            src={icon}
            alt="account-management-icon"
            className="circularPageHeadingIcon"
          />
        }
        <Typography className="ellipsisWithWrap" sx={{ fontSize: '20px' }}>
          {text}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}
