import styled from "styled-components";

import {
  styled as muiStyled,
} from '@mui/material'
import { newColor } from "src/consts/colors";

export const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 32px 0 32px;
  overflow: auto;
`;

export const Column: any = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;
  margin: auto;
`;


export const DisabledModalInput = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: newColor.dimGray,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  cursor: 'not-allowed'
}))