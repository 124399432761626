import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'
import { linkUserToOrgApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SmartIcon from '../../assets/Smart_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import SmartIconDark from '../../assets/Smart_Icon_dark.svg'
import { useSelector } from 'react-redux'
import { isHelikaAdmin, isHelikaSuperAdmin } from '../../utils/user'
import { USER_ROLES } from '../../consts/backend'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
}

export default function AssociateUserToOrg({ orgs }: { orgs: Org[] }) {
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)

  const [org, setOrg] = useState<Org | null>(null)
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [loading, setLoading] = useState(false)

  let userRolesAvailable: {
    viewer: string
    admin: string
    helika_admin?: string
    helika_super_admin?: string
  } = { ...USER_ROLES }

  if (isHelikaSuperAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
    userRolesAvailable.helika_super_admin = 'helika_super_admin'
  } else if (isHelikaAdmin(userData)) {
    userRolesAvailable.helika_admin = 'helika_admin'
  }

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
    }
  }

  const onChangeEmail = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setEmail(e?.target.value)
    }
  }

  const setAdminRole = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setRole(e?.target.value)
    }
  }

  const linkUserToOrg = async () => {
    if (email && org?.label && org?.value && role) {
      setLoading(true)
      await linkUserToOrgApi(
        patchWithAccessToken,
        email,
        org.label,
        org.value,
        role,
      )
      setLoading(false)
    } else {
      toast.warning(`Please enter user's email and choose organization`)
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? SmartIcon : SmartIconDark}
          alt="SocialOrganization"
        />
        <Typography>
          &nbsp;&nbsp;ASSOCIATE USER TO ORGANIZATION AND CHANGE THEIR ROLES
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <InputGroup>
              <Typography>Enter User Email</Typography>
              <Input
                placeholder="example: support@helika.io"
                value={email}
                type="email"
                required
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$"
                onChange={onChangeEmail}
              />
            </InputGroup>
            <InputGroup>
              <Typography>Choose organization</Typography>
              <SelectCategory value={org?.value} onChange={setSelectedOrg}>
                {orgs
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  ?.map((org) => (
                    <option key={org.value} value={org.value}>
                      {org.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>Choose role</Typography>
              <SelectCategory value={role} required onChange={setAdminRole}>
                <option disabled value="">
                  {' '}
                  -- select an option --{' '}
                </option>
                {Object.values(userRolesAvailable).map((userRole) => (
                  <option key={userRole} value={userRole}>
                    {userRole}
                  </option>
                ))}
              </SelectCategory>
            </InputGroup>
          </Grid>
        </Grid>
        <Button
          onClick={linkUserToOrg}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Link User to Org</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
