import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
  Stack,
  Typography,
  Breadcrumbs,
  Link,
  TableCell,
  Button,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { newColor, blackAndWhite } from 'src/consts/colors'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { debounce } from 'lodash'
import {
  getCampaignDetails,
  postNewCode,
  updateCode,
  updateBulkCodes,
} from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { RedemptionCode } from 'src/models'
import CustomSwitch from './CustomSwitch'
import ReusableSnackbar from './ReusableSnackbar'
import NoDataAvailable from './NoDataAvailable'
import FilterButton from './FilterButton'
import SortButton from './SortButton'
import ReusableTable from './ReusableTable'
import CreateRedemptionCodePage from './CreateRedemptionCodePage'
import EditUsageLimitModal from './EditUsageLimitModal'
import LoadingComponent from 'src/components/LoadingComponent'
import SearchInput from './SearchInput'
import dayjs from 'dayjs'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'

// Styled button for creating a new redemption code with dynamic theme
const StyledButton = styled(Button)(({ theme }) => {
  const themeMode = theme.palette.mode
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px 8px 8px',
    gap: '8px',
    width: '173px',
    height: '36px',
    backgroundColor:
      themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    borderRadius: '4px',
    color: newColor.white,
    fontSize: '14px',
    lineHeight: '140%',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    },
  }
})

// Breadcrumbs container styling
const BreadcrumbsContainer = styled('div')({
  width: '465px',
  height: '40px',
  padding: '16px 32px 4px 0px',
})

const CampaignDetails: React.FC = () => {
  const theme = useTheme()
  const themeMode = theme.palette.mode

  const {
    getWithAccessToken,
    postWithAccessToken,
    putWithAccessToken,
    patchWithAccessToken,
  } = useContext(Auth0Context)
  const { campaign_id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  // Access the campaignName from the state (sent from ProjectDetail)
  const { campaignName } = location.state || {
    campaignName: 'Unknown Campaign',
  }

  // Snackbar state management
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  // State management for redemption codes, pagination, loading, and other controls
  const [redemptionCodes, setRedemptionCodes] = useState<RedemptionCode[]>([])
  const [pagination, setPagination] = useState({ page: 1, limit: 10, count: 0 })
  const [sortField, setSortField] = useState<string>('updated_at')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [filters, setFilters] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isCreating, setIsCreating] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedUsageLimit, setSelectedUsageLimit] = useState<number>(1)
  const [editingCode, setEditingCode] = useState<RedemptionCode | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedCodesForUpdate, setSelectedCodesForUpdate] = useState<
    number[]
  >([])

  // Fetch redemption codes from the API
  const fetchRedemptionCodes = async (
    page: number,
    limit: number,
    appliedFilters = filters,
    searchName = searchTerm,
    field = sortField,
    order = sortOrder,
  ) => {
    try {
      setLoading(true)
      const data = await getCampaignDetails(
        getWithAccessToken,
        page,
        limit,
        field,
        order,
        Number(campaign_id),
        searchName,
        appliedFilters,
      )

      if (data && data.results && data.results.codes) {
        const receivedCodes = data.results.codes.map((code: any) => ({
          id: code.id,
          code: code.code,
          codeUrl: `${code.base_url}?code=${code.code}`,
          type: code.type.toLowerCase(),
          usageLimit: code.usage_limit,
          status: code.status,
          name: code.name,
          baseUrl: code.base_url,
          rewards: code.rewards || [],
          startDate: code.start_date,
          endDate: code.end_date,
        }))

        setRedemptionCodes(receivedCodes)
        setPagination((prev) => ({
          ...prev,
          count: data.results.count || 0,
        }))
      }
    } catch (e: any) {
      console.error('Error fetching campaign details:', e)
    } finally {
      setLoading(false)
    }
  }

  // Fetch initial redemption codes when component mounts
  useEffect(() => {
    fetchRedemptionCodes(pagination.page, pagination.limit)
  }, [pagination.page, pagination.limit])

  // Debounced search logic to reduce API calls on search input
  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setPagination((prev) => ({ ...prev, page: 1 }))
      fetchRedemptionCodes(1, pagination.limit, filters, term)
    }, 500),
    [pagination.limit, filters],
  )

  // Handle search logic (called from SearchInput)
  const handleSearch = (term: string) => {
    setSearchTerm(term)
    debouncedSearch(term)
  }

  // Handle sorting (called from SortButton)
  const handleSortChange = (field: string, order: 'asc' | 'desc') => {
    setSortField(field)
    setSortOrder(order)
    // Use the updated field and order directly in the fetch call
    fetchRedemptionCodes(
      pagination.page,
      pagination.limit,
      filters,
      searchTerm,
      field,
      order,
    )
  }

  // Handle filter logic (called from FilterButton)
  const handleApplyFilters = (appliedFilters: any[]) => {
    setFilters(appliedFilters)
    fetchRedemptionCodes(
      pagination.page,
      pagination.limit,
      appliedFilters,
      searchTerm,
    )
  }

  // Handle pagination change (ReusableTable)
  const handlePaginationChange = (page: number, limit: number) => {
    setPagination((prev) => ({ ...prev, page, limit }))
    fetchRedemptionCodes(page, limit, filters, searchTerm)
  }

  // Handle status change for a specific redemption code
  const handleStatusChange = async (codeId: number, newStatus: boolean) => {
    const code = redemptionCodes.find((c) => c.id === codeId)
    if (!code) return

    const updatedStatus = newStatus ? 'active' : 'archived'
    const originalStatus = code.status

    const updatedRedemptionCodes = redemptionCodes.map((c) =>
      c.id === codeId ? { ...c, status: updatedStatus } : c,
    )
    setRedemptionCodes(updatedRedemptionCodes)

    try {
      const updatedCodeData = {
        campaign_id: Number(campaign_id),
        code: code.code,
        base_url: code.baseUrl,
        name: code.name,
        type: code.type.toLowerCase(),
        usage_limit: code.usageLimit,
        status: updatedStatus,
        rewards: code.rewards ?? [],
        start_date: code.startDate,
        end_date: code.endDate,
      }
      setLoading(true)
      await updateCode(putWithAccessToken, code.id, updatedCodeData)
      setLoading(false)
      setSnackbarMessage(`Code status updated to ${updatedStatus}`)
      setSnackbarVariant('success')
      setSnackbarOpen(true)

      // Fetch the updated list after update API succeeds
      await fetchRedemptionCodes(pagination.page, pagination.limit)
    } catch (error) {
      setSnackbarMessage('Failed to update code status, reverting the change')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      const revertedRedemptionCodes = redemptionCodes.map((c) =>
        c.id === code.id ? { ...c, status: originalStatus } : c,
      )
      setRedemptionCodes(revertedRedemptionCodes)
      setLoading(false)
    }
  }

  // Archive a specific code
  const handleArchive = async (codeId: number) => {
    await handleStatusChange(codeId, false)
  }

  // Bulk archive selected codes
  const handleBulkArchive = async (selectedCodeIds: number[]) => {
    try {
      const payload = {
        ids: selectedCodeIds,
        status: 'archived',
      }
      setLoading(true)
      await updateBulkCodes(patchWithAccessToken, payload)
      setLoading(false)
      setSnackbarMessage('Selected codes archived successfully!')
      setSnackbarVariant('success')
      setSnackbarOpen(true)

      // Fetch the updated list after bulk archive API succeeds
      await fetchRedemptionCodes(pagination.page, pagination.limit)
    } catch (error: any) {
      setSnackbarMessage('Failed to archive selected codes')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }
  }

  // Save or create new redemption code
  const handleSaveNewCode = async (newCodeData: any) => {
    try {
      setLoading(true)
      if (editingCode) {
        await updateCode(putWithAccessToken, editingCode.id, newCodeData)
        setSnackbarMessage('Redemption code updated successfully!')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
      } else {
        await postNewCode(postWithAccessToken, newCodeData)
        setSnackbarMessage('Redemption code created successfully!')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
      }
      setLoading(false)
      setIsCreating(false)
      setEditingCode(null)

      // Fetch the updated list after code creation or update
      await fetchRedemptionCodes(pagination.page, pagination.limit)
    } catch (e: any) {
      setSnackbarMessage(e?.message || 'Failed to save redemption code')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }
  }

  // Open modal for bulk updating usage limits
  const handleOpenEditModalForBulk = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setSnackbarMessage('No codes selected for updating usage limit.')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      return
    }
    setSelectedCodesForUpdate(selectedIds)
    setIsEditModalOpen(true)
  }

  // Save updated usage limit for selected codes
  const handleSaveUsageLimit = async () => {
    try {
      const payload = {
        ids: selectedCodesForUpdate,
        usage_limit: selectedUsageLimit,
      }
      setLoading(true)
      await updateBulkCodes(patchWithAccessToken, payload)
      setLoading(false)
      setSnackbarMessage('Usage limit updated successfully!')
      setSnackbarVariant('success')
      setSnackbarOpen(true)
      setIsEditModalOpen(false)

      // Fetch the updated list after updating usage limit
      await fetchRedemptionCodes(pagination.page, pagination.limit)
    } catch (error) {
      setSnackbarMessage('Failed to update usage limit')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }
  }

  return (
    <Stack
      sx={{
        backgroundColor:
          themeMode === 'dark' ? blackAndWhite.grey900 : 'transparent',
      }}
    >
      {loading && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingComponent />
        </Stack>
      )}

      {!isCreating && !editingCode ? (
        <Stack
          direction="column"
          gap={1}
          sx={{
            maxWidth: { xs: '100%', md: '90%' },
            width: '100%',
            height: '100%',
            position: 'relative',
            margin: '0px auto',
            padding: 2,
          }}
        >
          <Stack direction="column" sx={{ py: 2 }}>
            <BreadcrumbsContainer>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: '12px',
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                    }}
                  />
                }
                sx={{
                  alignItems: 'center',
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '14px',
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  whiteSpace: 'nowrap',
                }}
              >
                <Link
                  component="button"
                  onClick={() => navigate(-1)}
                  sx={{
                    ...typography.paragraph_small,
                    height: '20px',
                    color: themeMode === 'dark' ? '#D2D2D3' : '#545454',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      textDecoration: 'underline',
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                    },
                  }}
                >
                  Redemption Codes
                </Link>
                <Link
                  component="button"
                  onClick={() => navigate(-1)}
                  sx={{
                    ...typography.paragraph_small,
                    height: '20px',
                    color: themeMode === 'dark' ? '#D2D2D3' : '#545454',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                      color:
                        themeMode === 'dark' ? newColor.white : newColor.black,
                    },
                  }}
                >
                  Campaign
                </Link>
                <Typography
                  color="text.primary"
                  sx={{
                    ...typography.label_small,
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {campaignName}
                </Typography>
              </Breadcrumbs>
            </BreadcrumbsContainer>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Typography
                fontSize={24}
                fontWeight={600}
                lineHeight={'130%'}
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  fontFamily: 'Open Sans, sans-serif',
                  letterSpacing: '0.5px',
                  textTransform: 'none',
                }}
              >
                {campaignName} - Redemption Codes
              </Typography>
              <StyledButton onClick={() => setIsCreating(true)}>
                <AddIcon />
                <span
                  style={{
                    fontFamily: 'Open Sans, sans-serif',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '140%',
                    color: newColor.white,
                  }}
                >
                  Create New Code
                </span>
              </StyledButton>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Stack direction="row" gap={2} alignItems="center">
                <SearchInput onSearch={handleSearch} />

                <FilterButton
                  showAddFilterButton
                  filterOptions={{
                    categories: ['Type', 'Status'],
                    conditions: ['is', 'is not'],
                    valuesByCategory: {
                      Type: ['Referral', 'Redeem'],
                      Status: ['Active', 'Archived'],
                    },
                  }}
                  onApplyFilter={handleApplyFilters}
                />

                <SortButton
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortChange={(field, order) =>
                    handleSortChange(field, order)
                  }
                />
              </Stack>
            </Stack>

            {redemptionCodes.length === 0 ? (
              <NoDataAvailable
                title="All redemption codes in one place"
                description="Start creating a code for this campaign."
                buttonText="Create New Code"
                onCreateNew={() => setIsCreating(true)}
              />
            ) : (
              <ReusableTable
                hooks={redemptionCodes}
                headers={[
                  'Code',
                  'Code URL',
                  'Start Date',
                  'End Date',
                  'Type',
                  'Usage Limit',
                  'Status',
                ]}
                pagination={pagination}
                renderRow={(code: RedemptionCode) => [
                  <TableCell
                    key="code"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {code.code}
                  </TableCell>,
                  <TableCell
                    key="codeUrl"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {code.codeUrl}
                  </TableCell>,
                  <TableCell
                    key="startDate"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {dayjs(code.startDate).format('YYYY-MM-DD hh:mm A')}
                  </TableCell>,
                  <TableCell
                    key="endDate"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {dayjs(code.endDate).format('YYYY-MM-DD hh:mm A')}
                  </TableCell>,
                  <TableCell
                    key="type"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {code.type}
                  </TableCell>,
                  <TableCell
                    key="usageLimit"
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                  >
                    {code.usageLimit}
                  </TableCell>,
                  <TableCell key="status">
                    <CustomSwitch
                      checked={code.status === 'active'}
                      onChange={() => {
                        handleStatusChange(code.id, code.status !== 'active')
                      }}
                    />
                  </TableCell>,
                ]}
                onChangePagination={({ page, limit }) =>
                  handlePaginationChange(page, limit)
                }
                refreshTable={() =>
                  fetchRedemptionCodes(pagination.page, pagination.limit)
                }
                goToEditHook={(code: RedemptionCode) =>
                  setEditingCode({
                    ...code,
                  })
                }
                onArchiveHook={handleArchive}
                onBulkArchive={handleBulkArchive}
                showUpdateButton={true}
                handleUpdateUsageLimit={handleOpenEditModalForBulk}
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <CreateRedemptionCodePage
          onSave={handleSaveNewCode}
          onBack={() => {
            setIsCreating(false)
            setEditingCode(null)
          }}
          onClickBreadCrumbs={() => navigate(-1)}
          campaignName={campaignName}
          campaignId={Number(campaign_id)}
          existingCodeData={editingCode}
        />
      )}

      <EditUsageLimitModal
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        usageLimit={selectedUsageLimit}
        setUsageLimit={setSelectedUsageLimit}
        handleSave={handleSaveUsageLimit}
      />

      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Stack>
  )
}

export default CampaignDetails
