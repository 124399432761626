import { ChangeEvent, FormEvent, useContext, useState } from 'react'

import styled from 'styled-components'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { lowerCaseAndReplaceSpacesAndDashes } from '../../../../utils/string'
import { handleError } from '../../../../utils/api/errors'
import { addOrgToDBApi } from '../../../../utils/api/queries'

const Title: any = styled.h3`
  text-align: center;
`

const Label: any = styled.label`
  text-align: center;
`

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

interface Team {
  label: string
  value: string
}

export default function CreateOrg({
  sigmaTeams,
  setSigmaTeams,
  sigmaTeam,
  setSigmaTeam,
}: {
  sigmaTeams: Team[]
  setSigmaTeams: React.Dispatch<React.SetStateAction<Team[]>>
  sigmaTeam: Team | null
  setSigmaTeam: React.Dispatch<React.SetStateAction<Team | null>>
}) {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [domain, setDomain] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')

  const setSelectedTeam = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = sigmaTeams.find(
        (team) => team.value === e?.target.value,
      )
      if (newOption) {
        setSigmaTeam({
          label: newOption.label,
          value: newOption.value,
        })
        setMessage('')
        setError('')
      }
    }
  }

  const onChangeDomain = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setDomain(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const onChangeEmail = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setEmail(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const addOrganizationToTheDB = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (domain && sigmaTeam && email) {
      try {
        await addOrgToDBApi(
          postWithAccessToken,
          domain,
          sigmaTeam.value,
          sigmaTeam.label,
          email,
          lowerCaseAndReplaceSpacesAndDashes(sigmaTeam.label),
        )
          .then((data: any) => {
            if (!data) {
              setError('Error: "undefined" response from server.')
              return
            }
            if (!data?.org) {
              setError(data.status)
              return
            }
            const org = data.org
            setError('')
            setMessage(
              `User Attribute "${org.sigma_team} - ${org.name}" created. 
            Organization "${org.name}" with email domain "${org.email_domain}" and sigma_team "${org.sigma_team}" added to the database.`,
            )
            const newTeams = sigmaTeams.filter(
              (team) => team.label !== org.sigma_team,
            )
            setDomain('')
            setEmail('')
            setSigmaTeams(newTeams)
            setSigmaTeam(
              newTeams[0]
                ? {
                  label: newTeams[0].label,
                  value: newTeams[0].value,
                }
                : null,
            )
          })
          .catch((error: any) => {
            handleError(error, setError, setMessage)
          })
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage(`Please enter domain and choose organization from select`)
      setError('')
    }
  }

  return (
    <form onSubmit={addOrganizationToTheDB}>
      <Wrapper>
        <Title>
          Creates sigma Organization user attribute and adds entry with (name,
          email_domain, sigma_team) into public.organization
        </Title>
        <br />
        <br />
        <Label>
          Choose what organization from sigma dashboard you want to onboard
          (which is not yet added to the public.organization table):
        </Label>
        <Select required value={sigmaTeam?.value} onChange={setSelectedTeam}>
          {sigmaTeams
            ?.sort((a: any, b: any) =>
              a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
            )
            .map((team) => (
              <option key={team.value} value={team.value}>
                {team.label}
              </option>
            ))}
        </Select>
        <Label>
          Enter domain of client organization. It is important that this value
          matches the domain of the email address of the client employees as we
          use that to match client employees to organization when they sign up.
          It can&#39;t be any public email domain like gmail.com, yahoo.com,
          etc. Also it should never be equal to domain of any other organization
          in the database.
        </Label>
        <Input
          placeholder="example: helika.io"
          value={domain}
          type="text"
          pattern="[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})"
          title="Valid domain name is required"
          required
          onChange={onChangeDomain}
        />
        <Label>
          Enter admin email of the client. The person responsible for sending
          invites
        </Label>
        <Input
          placeholder="example: support@helika.io"
          value={email}
          type="email"
          required
          pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$"
          title="Valid email"
          onChange={onChangeEmail}
        />
        <Button type="submit">Create Sigma Attribute and Org in the db</Button>
        <Message>{message}</Message>
        <Error>{error}</Error>
      </Wrapper>
    </form>
  )
}
