//module imports
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, useTheme } from '@mui/material'

//style
import { newColor } from 'src/consts/colors'

//components
import {
  Drawer,
  DrawerHeader,
  ScrollBlock,
} from 'src/components/StyledSimpleComponents'
import { DrawerLogo } from '../drawerLogo'
import { DOMAIN_TYPES } from 'src/state/action-types'

export const DefaultDrawer = () => {
  const theme = useTheme()
  const userData = useSelector((state: any) => state.user)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  async function clickDrawer() {
    setIsOpen((prevState) => !prevState)
  }

  let options: any = [...Object.values(DOMAIN_TYPES)]
  if (['demo', 'demo_japanese'].includes(userData?.organization_name)) {
    options = options.concat('A/B Testing (Coming Soon)')
    options = options.concat('AI Models (Coming Soon)')
  }

  return (
    <Drawer
      onClick={clickDrawer}
      variant="permanent"
      open={isOpen}
      sx={{
        background: theme.palette.mode === 'dark' ? 'black' : newColor.cultured,
      }}
    >
      <DrawerHeader
        onClick={(e: any) => {
          e.stopPropagation()
        }}
      >
        <DrawerLogo onClick={clickDrawer} />
      </DrawerHeader>
      <Divider />
      <ScrollBlock
        style={{
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          flex: 1,
        }}
      />
    </Drawer>
  )
}
