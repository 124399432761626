import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useEffect, useState } from 'react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'
import { Auth0Context } from '../../contexts/Auth0Context'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { useSelector } from 'react-redux'
import { editConnection, getConnection } from '../../utils/api/queries'
import connection_icon from '../../assets/connectionIcon.svg'
import TextIcon from '../../assets/large_text_icon.svg'
import _ from 'lodash'
import 'react-tooltip/dist/react-tooltip.css'
import * as ReactTooltip from 'react-tooltip'
import { CONFIG_NAME_REGEX } from '../../consts/regex'
import { isStringEmpty } from '../../utils/string'
import { newColor } from '../../consts/colors'
export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  db_connections_list: any[]
}

export default function EditConnectionModal(props: SimpleDialogProps) {
  const { open, setOpen, db_connections_list } = props
  const theme = useTheme()
  const { patchWithAccessToken, getWithAccessToken } = useContext(Auth0Context)
  const USER_DATA = useSelector((state: any) => state.user)
  const [createInProgress, setCreateInProgress] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [dialect, setDialect] = useState<string>('')
  const [host, setHost] = useState<string>('')
  const [port, setPort] = useState<number>(5432)
  const [dbName, setDbName] = useState<string>('')
  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [selectedConnection, setSelectedConnection] = useState<any>()
  const [savedValues, setSavedValues] = useState<any>()

  function resetValues() {
    try {
      setCreateInProgress(false)
      setName(savedValues?.aws_secret_name ? savedValues.aws_secret_name : '')
      setDbName(savedValues?.db_name ? savedValues.db_name : '')
      setUser(savedValues?.user ? savedValues.user : '')
      setDescription(savedValues?.description ? savedValues.description : '')
      setHost(savedValues?.host ? savedValues.host : '')
      setDialect(savedValues?.dialect ? savedValues.dialect : '')
      setPort(savedValues?.port ? savedValues.port : 5432)
      setPassword('')
    } catch (e) {
      console.error(e)
    }
  }

  const handleClose = () => {
    setOpen(false)
    resetValues()
  }

  useEffect(() => {
    if (_.isEmpty(db_connections_list)) return
    setSelectedConnection(db_connections_list[0].id)
  }, [db_connections_list])

  const editConnectionCall = async () => {
    try {
      setCreateInProgress(true)

      if (!selectedConnection) {
        throw new Error('No connection selected')
      }

      isStringEmpty(name, 'Name required')

      if (!name.match(CONFIG_NAME_REGEX)) {
        throw new Error(
          'Name must only contain letters, numbers, spaces, hyphens, and/or underscores',
        )
      }

      isStringEmpty(dialect, 'Dialect required')
      isStringEmpty(host, 'Host required')
      isStringEmpty(dbName, 'DB Name required')
      isStringEmpty(user, 'User required')

      if (!user.match(CONFIG_NAME_REGEX)) {
        throw new Error(
          'Name must only contain letters, numbers, spaces, hyphens, and/or underscores',
        )
      }

      let foundConnection = db_connections_list?.find(
        (conn) => conn.id === selectedConnection,
      )
      if (!foundConnection) {
        toast.error('Could not find connnection to edit')
        setCreateInProgress(false)
        return
      }

      let params: any = {
        id: foundConnection.id,
        org_id: USER_DATA.organization_id,
        user: user.trim(),
        host: host.trim(),
        port: port,
        dialect: dialect.trim(),
        name: name.trim(),
        description: description.trim(),
        db_name: dbName.trim(),
      }

      if (password && password.trim().length > 0) params.pswd = password.trim()

      await editConnection(patchWithAccessToken, selectedConnection, params)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not edit Connection')
            setCreateInProgress(false)
            return
          }
          toast.success('Connection edited, page will refresh')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
          return
        })
        .catch((error: any) => {
          toast.error('Could not edit Connection')
          setCreateInProgress(false)
        })
    } catch (e: any) {
      console.error(e)
      toast.error(e.message)
      setCreateInProgress(false)
    }
  }

  useEffect(() => {
    async function updateValues() {
      let selected_connection = db_connections_list?.find(
        (connection) => connection.id === selectedConnection,
      )
      if (!_.isEmpty(selected_connection)) {
        let secretValues: any = null
        try {
          secretValues = await getConnection(
            getWithAccessToken,
            selected_connection.id,
          )
        } catch (e: any) {
          toast.error(e.message)
        }
        let fullValues = Object.assign({}, secretValues, selected_connection)
        setName(fullValues?.aws_secret_name ? fullValues.aws_secret_name : '')
        setDbName(fullValues?.db_name ? fullValues.db_name : '')
        setUser(fullValues?.user ? fullValues.user : '')
        setDescription(fullValues?.description ? fullValues.description : '')
        setHost(fullValues?.host ? fullValues.host : '')
        setDialect(fullValues?.dialect ? fullValues.dialect : '')
        setPort(fullValues?.port ? fullValues.port : 5432)
        setPassword('')
        setSavedValues(fullValues)
        if (!secretValues) {
          toast.error('Could not load secret values')
        }
      } else {
        resetValues()
      }
    }

    updateValues()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db_connections_list, getWithAccessToken, selectedConnection])

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '1em 2em 0 2em',
        }}
      >
        <DialogTitle
          className={`${
            theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
          } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            src={connection_icon}
            alt=""
            style={{ margin: 'auto 0.3em auto 0' }}
          />{' '}
          <div style={{ lineHeight: '80%', margin: '0.4em 0 0.3em 0' }}>
            EDIT CONNECTION
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '0 4em 2em 2em',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
      >
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <div style={{ margin: '1em auto 0 0' }}>
            <div className="flexrow" style={{ marginBottom: '0.3em' }}>
              <img src={TextIcon} style={{ marginRight: '1em' }} alt="" />
              <div style={{ fontSize: '120%', fontWeight: 400 }}>
                SELECT CONNECTION TO EDIT:
              </div>
            </div>
          </div>
          <Select
            className={
              theme.palette.mode === 'dark'
                ? 'inputFieldClass3'
                : 'inputFieldClass3Light'
            }
            defaultValue={''}
            //label="Search By"
            size="small"
            sx={{ height: '2em' }}
            onChange={(event: SelectChangeEvent) => {
              setSelectedConnection(event.target.value as string)
            }}
            value={selectedConnection}
          >
            {db_connections_list?.map((connection: any) => {
              return (
                <MenuItem key={connection.id} value={connection.id}>
                  {connection.aws_secret_name}
                </MenuItem>
              )
            })}
          </Select>
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              NAME:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value)
              }}
              size={'small'}
              placeholder="e.g. Connection1"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DESCRIPTION:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              value={description}
              onChange={(e) => {
                setDescription(e.currentTarget.value)
              }}
              size={'small'}
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass2'
                  : 'inputFieldClass2Light'
              }
              multiline
              rows={4}
              placeholder="Describe the new connection"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DIALECT:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <Select
              labelId="search-filter"
              defaultValue={''}
              //label="Search By"
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass3'
                  : 'inputFieldClass3Light'
              }
              size="small"
              onChange={(event: SelectChangeEvent) => {
                setDialect(event.target.value as string)
              }}
              sx={{ height: '2em', width: '100%' }}
              value={dialect}
            >
              <MenuItem value={'postgresql'}>postgresql</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              HOST:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={host}
              onChange={(e) => {
                setHost(e.currentTarget.value)
              }}
              size={'small'}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              PORT:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              type="number"
              value={port}
              onChange={(e) => {
                setPort(Number(e.currentTarget.value))
              }}
              size={'small'}
              placeholder="e.g. 8080"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DB NAME:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={dbName}
              onChange={(e) => {
                setDbName(e.currentTarget.value)
              }}
              size={'small'}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              USER:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={user}
              onChange={(e) => {
                setUser(e.currentTarget.value)
              }}
              size={'small'}
              placeholder="e.g. dbRootUsername"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              PASSWORD:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <ReactTooltip.Tooltip id="password_tooltip" />
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              data-tooltip-id="password_tooltip"
              data-tooltip-content={'Leave as blank to keep the same password'}
              id="edit-connection-password"
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value)
              }}
              type="password"
              size={'small'}
              placeholder="∗∗∗∗∗∗∗∗∗∗"
            />
          </Grid>
        </Grid>
      </Box>
      {createInProgress ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={createInProgress}
            onClick={editConnectionCall}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => resetValues()}
            className="helikaButtonClass"
          >
            Reset
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
