/*eslint-disable no-throw-literal*/
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useState } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Grid, MenuItem, Paper, Radio, Select, SelectChangeEvent, TextField, useTheme } from '@mui/material';
import { Auth0Context } from '../../contexts/Auth0Context';
import { toast } from 'react-toastify';
import LoadingComponent from '../LoadingComponent';
import { createParentConfig } from '../../utils/api/queries';
import { useSelector } from 'react-redux';
import config_icon from '../../assets/config_icon.svg';
import sheet_mapping_icon from '../../assets/sheet_mapping_icon.svg';
import remove_button from '../../assets/remove_button.svg';
import expand_icon from '../../assets/expand_icon.svg';
import minimize_icon from '../../assets/minimize_icon.svg';
import TextIcon from '../../assets/support_header_text_icon.svg';
import remove_circle_icon from '../../assets/remove_circle_icon.svg';
import _ from 'lodash';
import { CONFIG_KEY_SHEET_REGEX, CONFIG_KEY_TABLE_REGEX, CONFIG_NAME_REGEX } from '../../consts/regex';
import { isStringEmpty } from '../../utils/string';
import { newColor } from '../../consts/colors';

export interface SimpleDialogProps {
  open: boolean,
  setOpen: any,
  db_connections_list?: any[]
}

export interface sheet_mapping {
  sheet_name: string,
  table_name: string,
  schema_name: string,
  db_connection: string,
  column_aliases: any[],
  key_alias: any
}


export default function CreateConfigModal(props: SimpleDialogProps) {
  const { open, setOpen, db_connections_list } = props;
  const theme = useTheme();
  const { postWithAccessToken } = useContext(Auth0Context);
  const USER_DATA = useSelector((state: any) => state.user);
  const [createInProgress, setCreateInProgress] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [environment, setEnvironment] = useState<string>('prod');
  const [tags, setTags] = useState<string>('');
  const [sheetMappings, setSheetMappings] = useState<sheet_mapping[]>([]);
  const [expandSheetMappings, setExpandSheetMappings] = useState<boolean[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  async function createConnection() {

    try {

      setCreateInProgress(true);

      isStringEmpty(name, 'Name required');
      isStringEmpty(environment, 'Environment required');

      if (!(name.match(CONFIG_NAME_REGEX))) {
        throw ('Name must only contain letters, numbers, spaces, hyphens, and/or underscores');
      }

      if (_.isEmpty(sheetMappings)) {
        throw ('Config requires at least one sheet mapping');
      }

      let newSheetMappings = sheetMappings.map((mapping: any) => {
        let newMapping = Object.assign({}, mapping);
        newMapping.connection_id = mapping.db_connection?.id;
        delete newMapping.db_connection;
        return newMapping;
      })

      let key_matches = newSheetMappings.every(sheetMap => sheetMap.column_aliases?.every((keyPair: any) => keyPair.sheet?.trim().match(CONFIG_KEY_SHEET_REGEX) && keyPair.table?.trim().match(CONFIG_KEY_TABLE_REGEX)));
      if (!key_matches) {
        throw ('Keys in the aliases must only have letters, numbers, or underscores');
      }

      let sheetMappingComplete = newSheetMappings.every(sheetMap => {
        if (
          _.isEmpty(sheetMap.sheet_name) ||
          _.isEmpty(sheetMap.table_name) ||
          _.isEmpty(sheetMap.schema_name) ||
          !(_.isInteger(sheetMap.connection_id) && sheetMap.connection_id > 0) ||
          _.isEmpty(sheetMap.key_alias) ||
          _.isEmpty(sheetMap.column_aliases)
        ) {
          return false;
        }
        return true;
      })
      if (!sheetMappingComplete) {
        throw ('One of the Sheet Mappings is missing a value');
      }

      //check if key_alias is included in each sheet mapping
      let key_aliases_included = newSheetMappings.every(sheet_mapping => {
        if (_.isEmpty(sheet_mapping.key_alias)) return false;
        let index = _.findIndex(sheet_mapping.column_aliases, sheet_mapping.key_alias);
        return index !== -1;
      });
      if (!key_aliases_included) {
        throw ('Key Alias is missing from Column Aliases');
      }

      //check if connection ids are valid
      if (db_connections_list) {
        let connection_ids_list = db_connections_list.map(connection => connection.id);
        let connectionIdsValid = newSheetMappings.every(mapping => {
          return connection_ids_list.includes(mapping.connection_id);
        });
        if (!connectionIdsValid) {
          throw ('A Sheet Mapping has an invalid connection, please select a valid DB Connection for each sheet mapping');
        }
      } else {
        throw ('Could not retrieve connections data');
      }

      let params = {
        name: name,
        description: description,
        org_id: USER_DATA.organization_id,
        sheet_mappings: newSheetMappings,
        environment: environment,
        tags: tags?.split(',').map(tag => tag?.trim()),
        sheets_mapping_ids: [],
        created_by: USER_DATA.id
      }

      createParentConfig(postWithAccessToken, params)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not create config');
            setCreateInProgress(false);
            return;
          }
          toast.success('Config created, page will refresh');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          return;
        }).catch((error: any) => {
          toast.error('Could not create Config');
          setCreateInProgress(false);
        })
    } catch (e: any) {
      console.error(e);
      toast.error(e?.message)
      setCreateInProgress(false);
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className='defaultModalStyle'
      onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className={theme.palette.mode === 'dark' ? 'circleBackground' : 'circleBackgroundLight'} sx={{ justifyContent: 'center', minWidth: '20em', margin: '1em 2em 0 2em' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`} style={{ color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><img src={config_icon} alt='' style={{ margin: 'auto 0.3em auto 0' }} /> <div style={{ lineHeight: '80%', margin: '0.4em 0 0.3em 0' }}>CREATE CONFIG</div></DialogTitle>
      </Box>
      <Box
        display='flex'
        flexDirection={'column'}
        sx={{ padding: '0 2em 2em 2em', width: '100%', justifyContent: 'center', minWidth: '60vw' }}
      >
        <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>NAME:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              size={'small'}
              placeholder='e.g. Config Name'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>DESCRIPTION:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={theme.palette.mode === 'dark' ? 'inputFieldClass2' : 'inputFieldClass2Light'}
              value={description}
              onChange={(e) => {
                setDescription(e.currentTarget.value);
              }}
              size={'small'}
              multiline
              rows={4}
              placeholder='Describe your config file'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>TAGS:</div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={theme.palette.mode === 'dark' ? 'inputFieldClass2' : 'inputFieldClass2Light'}
              value={tags}
              onChange={(e) => {
                setTags(e.currentTarget.value);
              }}
              size={'small'}
              multiline
              rows={4}
              placeholder='Add tags seperated by comma(s)'
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 1em 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
          <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>ENVIRONMENT:</div>
          </Grid>
          <Grid item xs={9}>
            <Select
              labelId="search-filter"
              className={theme.palette.mode === 'dark' ? 'inputFieldClass3' : 'inputFieldClass3Light'}
              defaultValue={'prod'}
              //label="Search By"
              size='small'
              onChange={(event: SelectChangeEvent) => {
                setEnvironment(event.target.value as string);
              }}
              sx={{ height: '2em', width: '100%' }}
              value={environment}
            >
              <MenuItem value={'prod'}>Production</MenuItem>
              <MenuItem value={'staging'}>Staging</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {
          sheetMappings?.map((sheet_mapping: any, index: number) => {
            return (
              <div
                key={index}
                style={{ margin: '0.5em 0 0.5em 0' }}
              >
                <div className='linearGradientCircle flexrow' style={{ width: '100%', padding: '0.3em 1em 0.3em 1em', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      width: '100%',
                      marginRight: '1em',
                      maxWidth: '60vw'
                    }}
                    className='truncate'
                  >
                    {sheet_mapping?.sheet_name ? sheet_mapping.sheet_name : `Sheet Mapping #${index + 1}`}
                  </div>
                  <div className='flexrow'>
                    <img
                      src={remove_button}
                      style={{ marginRight: '0.5em', cursor: 'pointer' }}
                      alt=''
                      onClick={() => {
                        setSheetMappings((prevState: any[]) => {
                          let newValues = [...prevState]?.filter((sheet_mapping: any, mappingIndex: number) => index !== mappingIndex);
                          return newValues;
                        })
                        setExpandSheetMappings(prevState => {
                          let newState = [...prevState];
                          delete newState[index];
                          return newState;
                        })
                      }}
                    />
                    {
                      expandSheetMappings[index]
                        ?
                        <img
                          src={minimize_icon}
                          alt=''
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setExpandSheetMappings(prevState => {
                              let newState = [...prevState];
                              newState[index] = false;
                              return newState;
                            })
                          }}
                        />
                        :
                        <img
                          src={expand_icon}
                          alt=''
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setExpandSheetMappings(prevState => {
                              let newState = [...prevState];
                              newState[index] = true;
                              return newState;
                            })
                          }}
                        />
                    }

                  </div>
                </div>
                {
                  expandSheetMappings[index] &&
                  <Paper
                    className='flexcol'
                    sx={{ padding: '1em', marginTop: '1em', background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.silver }}
                  >
                    <div className='flexrow' style={{ height: 'fit-content', width: '100%' }}>
                      <div className='flexcol' style={{ width: 'fit-content', margin: '0 0.5em 0 0' }}><img src={TextIcon} alt='' style={{ height: '1.3em', margin: 'auto 0 auto 0' }} /></div><div style={{ fontSize: '1.3em' }}>BASIC INFO</div>
                    </div>
                    <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
                      <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>SHEETNAME:</div>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
                          value={sheet_mapping.sheet_name}
                          onChange={(e) => {
                            setSheetMappings((prevState: any[]) => {
                              let previousValues = [...prevState];
                              if (previousValues[index]) {
                                previousValues[index].sheet_name = e.target.value;
                              }
                              return previousValues;
                            });
                          }}
                          size={'small'}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
                      <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>TABLENAME:</div>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
                          value={sheet_mapping.table_name}
                          onChange={(e) => {
                            setSheetMappings((prevState: any[]) => {
                              let previousValues = [...prevState];
                              if (previousValues[index]) {
                                previousValues[index].table_name = e.target.value;
                              }
                              return previousValues;
                            });
                          }}
                          size={'small'}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
                      <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>DB SCHEMA:</div>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
                          value={sheet_mapping.schema_name}
                          onChange={(e) => {
                            setSheetMappings((prevState: any[]) => {
                              let previousValues = [...prevState];
                              if (previousValues[index]) {
                                previousValues[index].schema_name = e.target.value;
                              }
                              return previousValues;
                            });
                          }}
                          size={'small'}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={1} rowSpacing={2} style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }} justifyContent={'space-evenly'}>
                      <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='minimizeFontOnSmallWindow' style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}>DB CONNECTION:</div>
                      </Grid>
                      <Grid item xs={9}>
                        <Select
                          labelId="search-filter"
                          className={theme.palette.mode === 'dark' ? 'inputFieldClass3' : 'inputFieldClass3Light'}
                          defaultValue={sheet_mapping.db_connection}
                          //label="Search By"
                          size='small'
                          onChange={(event: SelectChangeEvent) =>
                            setSheetMappings((prevState: any[]) => {
                              let previousValues = [...prevState];
                              if (previousValues[index]) {
                                previousValues[index].db_connection = (event.target.value as string)
                              }
                              return previousValues;
                            })}
                          sx={{ height: '2em', width: '100%' }}
                          value={sheet_mapping.db_connection}
                        >
                          {
                            db_connections_list?.map((connection: any) => <MenuItem key={connection.id} value={connection}>{connection?.aws_secret_name}</MenuItem>)
                          }
                        </Select>
                      </Grid>
                    </Grid>
                    <div className='flexrow' style={{ height: 'fit-content', width: '100%', marginTop: '2em' }}>
                      <div className='flexcol' style={{ width: 'fit-content', margin: '0 0.5em 0 0' }}><img src={TextIcon} alt='' style={{ height: '1.3em', margin: 'auto 0 auto 0' }} /></div><div style={{ fontSize: '1.3em' }}>TABLE ALIASING INFO</div>
                    </div>
                    <Grid container style={{ margin: '0.5em 0 0 0', maxWidth: '100%', textAlign: 'center' }} justifyContent={'space-evenly'}>
                      <Grid item xs={1.5} style={{ display: 'flex', flexDirection: 'column' }} />
                      <Grid item xs={4.5} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>SHEET COLUMN NAME</div>
                      </Grid>
                      <Grid item xs={4.5}>
                        <div>TABLE COLUMN NAME</div>
                      </Grid>
                      <Grid item xs={1.5}>
                      </Grid>
                    </Grid>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0.5em 0 0.5em 0' }}>
                      <div className='minimizeFontOnSmallWindow' style={{ marginRight: '1em' }}>KEY COLUMN</div>
                    </div>
                    {
                      sheet_mapping.column_aliases?.map((column: any, column_index: number) =>
                        <Grid container key={`column_aliases_${column_index}_${index}`} style={{ height: '3.3em', maxWidth: '100%', margin: '0 0 1em 0' }} justifyContent={'space-evenly'}>
                          <Grid item xs={1.5} style={{ display: 'flex', width: 'min-content', flexDirection: 'column', margin: 'auto auto auto 0' }}>
                            {(_.isEqual(sheet_mapping.key_alias, column)) && <div style={{ color: newColor.jazzberryJam, fontSize: '0.6em' }}>Key Alias</div>}
                            <Radio
                              onChange={() => {
                                setSheetMappings((prevState: any[]) => {
                                  let previousValues = [...prevState];
                                  previousValues[index].key_alias = previousValues[index].column_aliases[column_index];
                                  return previousValues;
                                });
                              }}
                              size='small'
                              checked={_.isEqual(sheet_mapping.key_alias, column)}
                              sx={{ width: 'min-content' }}
                            />
                          </Grid>
                          <Grid item xs={4.5} style={{ display: 'flex', flexDirection: 'column', margin: 'auto 0 auto 0' }}>
                            <div style={{ padding: '0 0.3em 0 0.3em' }}>
                              <TextField
                                className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
                                value={column.sheet}
                                onChange={(e) => {
                                  setSheetMappings((prevState: any[]) => {
                                    let previousValues = [...prevState];
                                    if (previousValues[index]) {
                                      let isCurrentKeyAlias = _.isEqual(sheet_mapping.key_alias, previousValues[index].column_aliases[column_index]);
                                      previousValues[index].column_aliases[column_index].sheet = e.target.value?.trim();
                                      if (isCurrentKeyAlias) {
                                        previousValues[index].key_alias = Object.assign({}, previousValues[index].column_aliases[column_index]);
                                      }
                                      return previousValues;
                                    }
                                    return previousValues;
                                  });
                                }}
                                size={'small'}
                                sx={{ width: '100%' }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4.5} style={{ display: 'flex', flexDirection: 'column', margin: 'auto 0 auto 0' }}>
                            <div style={{ padding: '0 0.3em 0 0.3em' }}>
                              <TextField
                                className={theme.palette.mode === 'dark' ? 'inputFieldClass1' : 'inputFieldClass1Light'}
                                value={column.table}
                                onChange={(e) => {
                                  setSheetMappings((prevState: any[]) => {
                                    let previousValues = [...prevState];
                                    if (previousValues[index]) {
                                      let isCurrentKeyAlias = _.isEqual(sheet_mapping.key_alias, previousValues[index].column_aliases[column_index]);
                                      previousValues[index].column_aliases[column_index].table = e.target.value?.trim();
                                      if (isCurrentKeyAlias) {
                                        previousValues[index].key_alias = Object.assign({}, previousValues[index].column_aliases[column_index]);
                                      }
                                      return previousValues;
                                    }
                                    return previousValues;
                                  });
                                }}
                                size={'small'}
                                sx={{ width: '100%' }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={1.5} className='flexrow' style={{ justifyContent: 'center', margin: 'auto 0 auto 0' }}>
                            <img
                              src={remove_circle_icon}
                              alt=''
                              style={{ height: '1.3em', margin: 'auto 0.5em auto 0', cursor: 'pointer' }}
                              onClick={() => {
                                setSheetMappings((prevState: any[]) => {
                                  let newValues = [...prevState];
                                  let isCurrentKeyAlias = _.isEqual(sheet_mapping.key_alias, newValues[index].column_aliases[column_index]);
                                  if (isCurrentKeyAlias) {
                                    newValues[index].key_alias = [];
                                  }
                                  if (column_index === 0) {
                                    newValues[index].column_aliases[0] = {
                                      sheet: '',
                                      table: ''
                                    }
                                  } else {
                                    let newColumns = newValues[index].column_aliases.filter((column: any, filter_column_index: number) => filter_column_index !== column_index);
                                    newValues[index].column_aliases = newColumns;
                                  }
                                  return newValues;
                                });
                              }}
                            />
                            <img
                              src={remove_circle_icon}
                              alt=''
                              style={{ height: '1.3em', margin: 'auto 0 auto 0', transform: 'rotate(45deg)', cursor: 'pointer' }}
                              onClick={() => {
                                setSheetMappings((prevState: any[]) => {
                                  let newValues = [...prevState];
                                  let columnsData = newValues[index].column_aliases;
                                  newValues[index].column_aliases = [
                                    ...columnsData.slice(0, column_index + 1),
                                    {
                                      sheet: '',
                                      table: ''
                                    },
                                    ...columnsData.slice(column_index + 1)
                                  ];
                                  return newValues;
                                });
                              }}
                            />
                          </Grid>
                        </Grid>)
                    }
                  </Paper>
                }
              </div>
            )
          })
        }
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row', padding: '1em 1em 0 1em' }}>
          <Button
            className='helikaButtonClass'
            sx={{ width: '20em' }}
            onClick={() => {
              setSheetMappings((prevState: sheet_mapping[]) => {
                return prevState.concat([{
                  sheet_name: '',
                  table_name: '',
                  schema_name: '',
                  db_connection: '',
                  column_aliases: [{
                    sheet: '',
                    table: ''
                  }],
                  key_alias: {
                    sheet: '',
                    table: ''
                  }
                }]);
              });
              setExpandSheetMappings(prevState => {
                return prevState.concat(true);
              })
            }}
          ><img src={sheet_mapping_icon} alt='' style={{ margin: 'auto 0.3em auto 0' }} /> Add Sheet Mapping</Button>
        </div>
      </Box>
      {
        createInProgress
          ?
          <div className='w-full'><LoadingComponent /></div>
          :
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{ padding: '0 2em 2em 2em' }}
          >
            <Button sx={{ width: '15em' }} disabled={createInProgress} onClick={createConnection} className='helikaButtonClass'>Create</Button>
            <Button sx={{ width: '15em', marginLeft: '1em' }} onClick={() => setOpen(false)} className='helikaButtonClass'>Cancel</Button>
          </Box>
      }
    </Dialog>
  );
}
