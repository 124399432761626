import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
  Grid
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import Analytics from '../../assets/analytics_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import { Auth0Context } from '../../contexts/Auth0Context'
import { deleteVisualization } from '../../utils/api/queries'
import { useSelector } from 'react-redux'
import { newColor } from 'src/consts/colors'
import { DisabledModalInput } from 'src/utils/muiStyledComponents'

const DeleteVisualModal = (props: any) => {
  const { open, setOpen, selectedVisual, updateQueryListAndSchemaList, newVisual, setSelectedVisual, setSelectedQuery } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const { deleteWithAccessToken } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)

  const handleClose = () => {
    setOpen(false)
  }

  async function deleteHandler() {
    setLoading(true)
    if (!selectedVisual?.id) {
      toast.error('Query not found')
      setLoading(false)
      return
    }
    if (
      selectedVisual?.private && // is org query
      (selectedVisual?.created_by !== userData?.id) && // user doesn't own query
      (userData?.org_role?.localeCompare('admin') !== 0) //  user is not an org admin
    ) {
      toast.error('Only Org Admins can delete Visuals that they did not create')
      setLoading(false)
      return
    }
    await deleteVisualization(deleteWithAccessToken, selectedVisual).then(async () => {
      setSelectedQuery(undefined)
      setSelectedVisual(undefined)
      await updateQueryListAndSchemaList().catch((e: any) => {
        toast.error('Could not reload Query and Visuals List, please refresh')
        setLoading(false)
        return
      })
      setOpen(false)
      setLoading(false)
      toast.success('Visual was deleted')
      //todo: refresh list
    }).catch(e => {
      toast.error(e)
      setLoading(false)
    })
  }

  if (!selectedVisual?.id) return null

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              DELETE VISUAL
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;VISUAL INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                VISUAL NAME:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <DisabledModalInput
                disabled={true}
                value={selectedVisual?.name}
                placeholder="e.g. Visual Title"
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          {
            newVisual
          }
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '5em',
          width: '90%',
          margin: 'auto',
          scrollbarGutter: 'both-sides',
          overflow: 'auto'
        }}
      >
        {loading ? (
          <div
            style={{
              scrollbarGutter: 'both-sides'
            }}
          >
            <LoadingComponent />
          </div>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{
              padding: '0 2em 2em 2em',
              scrollbarGutter: 'both-sides'
            }}
          >
            <Button
              sx={{ width: '15em' }}
              disabled={loading}
              onClick={deleteHandler}
              className="helikaButtonClass"
            >
              Delete
            </Button>
            <Button
              sx={{ width: '15em', marginLeft: '1em' }}
              onClick={() => setOpen(false)}
              className="helikaButtonClass"
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default DeleteVisualModal
