//imports
import { GridStack } from "gridstack"
import { createRef, useEffect, useRef, useState } from "react"
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/gridstack-extra.min.css'

//components
import { DashboardDesignerEchart } from "src/components/Explore/DashboardDesignerEchart"
import { useTheme } from "@mui/material"
import { newColor } from "src/consts/colors"

export function DashboardGridstack({
  dashboard_id,
  items,
  setItems,
  visuals,
  queries,
  gridRef,
  isLoadingQueries,
  inEditMode,
  show = true,
  maxRows,
  setMaxRows
}: {
  dashboard_id: any,
  items: any[],
  setItems: any,
  visuals: any[],
  queries: any,
  gridRef: any,
  isLoadingQueries: boolean,
  inEditMode: boolean,
  show?: boolean,
  maxRows?: any,
  setMaxRows?: any
}) {

  const theme = useTheme()
  const refs: any = useRef({})
  const [isLoading, setIsLoading] = useState<boolean>(true);

  if (Object.keys(refs.current).length !== items.length) {
    items.forEach(({ id }: any) => {
      refs.current[id] = refs.current[id] || createRef()
    })
  }

  async function itemResized(resizedItem: any) {
    setItems((prevState: any) => {
      return prevState.map((prevItem: any) => {
        if (resizedItem.id !== prevItem.id) return prevItem
        return Object.assign({}, prevItem, {
          h: resizedItem.h,
          w: resizedItem.w,
          x: resizedItem.x,
          y: resizedItem.y,
        })
      })
    })
  }

  async function addRows() {
    setMaxRows((prevState: any) => {
      let currentGrid = gridRef.current
      let nodes = currentGrid?.engine?.nodes;
      let newMax = 8;
      if (nodes) {
        nodes.forEach((node: any) => {
          let nodeHigh = Number(node.y) + Number(node.h) + 8;
          if (nodeHigh > newMax) {
            newMax = nodeHigh;
          }
        })
      }
      GridStack.init({
        resizable: {
          handles: 'e,se,s,sw,w,nw,n,ne'
        },
        float: true,
        cellHeight: '10em',
        column: 5,
        minRow: 8,
        maxRow: newMax
      }, '.controlled')
      currentGrid.engine.maxRow = newMax
      currentGrid.opts.maxRow = newMax
      return newMax
    })
  }

  useEffect(() => {

    setIsLoading(true)
    gridRef.current = gridRef.current ||
      GridStack.init({
        resizable: {
          handles: 'e,se,s,sw,w,nw,n,ne'
        },
        float: true,
        cellHeight: '10em',
        column: 5,
        minRow: 8,
        maxRow: maxRows
      }, '.controlled')

    const grid = gridRef.current
    if (inEditMode) {
      grid?.on('resizestop', (event: any, element: any) => {
        itemResized(event.target.gridstackNode)
      })
      grid?.on('dragstart', (event: any, element: any) => {
        addRows()
      })
    }

    grid.removeAll(false)
    items.forEach(({ id }: any) => grid.makeWidget(refs.current[id].current))
    grid.batchUpdate(false)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, items, dashboard_id])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
        flexGrow: 'grow',
        borderRadius: '1em',
        border: inEditMode ? 'solid' : undefined,
        borderColor: newColor.darkGray,
        borderWidth: '0.5px'
      }}
      gs-max-row={maxRows}
      className={`${theme.palette.mode}-background grid-stack controlled dashboardGridstack ${inEditMode ? 'inEditMode' : ''}`}
    >
      {!isLoading && items.map((item: any, i: number) => {
        return (
          <div
            key={item.id}
            gs-id={item.id}
            ref={refs.current[item.id]}
            style={{
              padding: '1em',
              height: `${item.h * 10}em`,
              display: show ? 'flex' : 'none'
            }}
            gs-x={item.x} gs-y={item.y} gs-h={item.h} gs-w={item.w}
            gs-no-move={inEditMode ? 'no' : 'yes'}
            className={`grid-stack-item ${inEditMode ? '' : 'display locked noResize noMove'}`}
          >
            <DashboardDesignerEchart
              key={item.id}
              item={item}
              setItems={setItems}
              loading={isLoadingQueries || isLoading}
              data={visuals}
              visuals={visuals}
              queries={queries}
              inEditMode={inEditMode}
            />
          </div>
        )
      })}
    </div>
  )
}