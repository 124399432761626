import { postToPhyllo } from '../../utils/api/queries'
import { toast } from 'react-toastify'

export const PhylloSDK = (
  workPlatformId: string,
  sdkConfig: any,
  postWithAccessToken: any,
  getSocialData: any,
  createHelikaEvent: any,
) => {
  const config = {
    clientDisplayName: 'Helika',
    environment:
      process.env.REACT_APP_ENVIRONMENT === 'PROD' ? 'production' : 'staging',
    userId: sdkConfig.phyllo_user_id,
    token: sdkConfig.token,
    redirect: false,
    workPlatformId: workPlatformId,
  }

  const postSocialConnection = async (params: {}, platformId: string) => {
    try {
      const data = await postToPhyllo(postWithAccessToken, params)
      createHelikaEvent(
        'account_connected',
        platformId,
        data?.results?.type,
        data?.results?.platform_username,
        data?.results?.account_id,
      )
      await getSocialData()
    } catch (e: any) {
      console.log(e)
      toast.error(e?.message)
    }
  }

  const phylloConnect = (window as any).PhylloConnect.initialize(config)
  const handleAccountConnected = (
    accountId: any,
    workplatformId: any,
    userId: any,
  ) => {
    console.log(`Account connected: ${accountId}, ${workplatformId}, ${userId}`)
    toast.success(
      `Account connected: ${accountId}, ${workplatformId}, ${userId}`,
    )
    const params = {
      account_id: accountId,
    }
    postSocialConnection(params, workplatformId)
  }

  const handleAccountDisconnected = (
    accountId: any,
    workplatformId: any,
    userId: any,
  ) => {
    console.log(
      `Account disconnected: ${accountId}, ${workplatformId}, ${userId}`,
    )
    toast.success(
      `Account disconnected: ${accountId}, ${workplatformId}, ${userId}`,
    )
  }

  const handleTokenExpired = (userId: any) => {
    console.log(`Token expired for user: ${userId}`)
    toast.error(`Token expired for user: ${userId}`)
  }

  const handleExit = (reason: any, userId: any) => {
    console.log(`SDK exited: ${reason}, ${userId}`)
  }

  const handleConnectionFailure = (
    reason: any,
    workplatformId: any,
    userId: any,
  ) => {
    console.log(`Connection failure: ${reason}, ${workplatformId}, ${userId}`)
    toast.error(`Connection failure: ${reason}, ${workplatformId}, ${userId}`)
  }

  phylloConnect.on('accountConnected', handleAccountConnected)
  phylloConnect.on('accountDisconnected', handleAccountDisconnected)
  phylloConnect.on('tokenExpired', handleTokenExpired)
  phylloConnect.on('exit', handleExit)
  phylloConnect.on('connectionFailure', handleConnectionFailure)

  phylloConnect.open()
}
