//imports
import { useEffect, useState } from "react";
import _ from "lodash";

//contexts

//services

//components

//assets
import { Box, Checkbox, ListItemText, MenuItem, Select, useTheme } from "@mui/material";
import { capitaliseFirstLetter, visualTypes } from "src/utils/string";
import { newColor } from "src/consts/colors";
//---------------------------------------------------------------------------------

export default function LineChartSettingsBox({
  selectedVisual,
  settings,
  setSettings,
  datasetColumns,
  selectedQuery
}: {
  selectedVisual: any,
  settings: any,
  setSettings: any,
  datasetColumns: any,
  selectedQuery: any
}) {

  const theme: any = useTheme();
  const [isStacked, setIsStacked] = useState<string>('Stacked');
  const [showingLegend, setShowingLegend] = useState<string>('Show');
  const [visualType, setVisualType] = useState<any>((settings && settings.visualType) ? settings.visualType : visualTypes.line);
  const [xAxis, setXAxis] = useState<any>((settings && settings.xAxis) ? settings.xAxis : '');
  const [xAxisTicks, setXAxisTicks] = useState<any>((settings && settings.xAxisTicks === 'All') ? 'All' : 'Minimal');

  const [checkedSeries, setCheckedSeries] = useState<string[]>([]);

  useEffect(() => {
    if (visualType === visualTypes.waterfall) {
      setIsStacked('Stacked');
    }
  }, [visualType])

  function resetSettings() {
    setVisualType(visualTypes.line);
    setXAxis('');
    setXAxisTicks('Minimal');
    setIsStacked('Stacked')
    setCheckedSeries([])
    setShowingLegend('Show')
  }

  useEffect(() => {
    if (_.isUndefined(selectedVisual)) {
      resetSettings()
      return
    }
    if (!selectedVisual?.id) return
    setVisualType(selectedVisual.type)
    setXAxisTicks(selectedVisual.x_axis_ticks === 'All' ? 'All' : 'Minimal')
    setIsStacked(selectedVisual.stacked ? 'Stacked' : 'Non-Stacked')
    setShowingLegend(selectedVisual.show_legend ? 'Show' : 'Hide')
    if (selectedVisual?.query_id === selectedQuery?.id) {
      setXAxis(selectedVisual.x_axis);
      setCheckedSeries(selectedVisual.series)
    } else {
      setXAxis('');
      setCheckedSeries([])
    }
  }, [selectedVisual, selectedQuery])

  //reset settings if choosing new query
  useEffect(() => {
    if (!selectedQuery || (selectedVisual && selectedVisual?.query_id !== selectedQuery?.id)) {
      resetSettings()
    }
  }, [selectedQuery, selectedVisual])

  useEffect(() => {
    setSettings({
      visualType: visualType,
      xAxis: xAxis,
      setXAxis: setXAxis,
      xAxisTicks: xAxisTicks,
      stackType: isStacked,
      checkedSeries: checkedSeries,
      showingLegend: showingLegend
    })
  }, [visualType, checkedSeries, setXAxis, setSettings, isStacked, xAxis, showingLegend, xAxisTicks])

  return (
    <Box
      id='settingsbox'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        columnGap: '0.5em',
        width: '15em',
        overflowX: 'auto',
        padding: '0.5em 0em 0.5em 0em',
        height: '100%',
        scrollbarGutter: 'stable both-sides',
        justifyContent: 'start'
      }}
      gap={3}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          padding: '1em',
          border: 'solid',
          borderRadius: '0.5em',
          borderColor: newColor.darkGunmetal,
          borderWidth: '0.5px',
          gap: '1em',
          height: 'fit-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: theme.palette.text.primary,
              textAlign: 'left',
            }}
          >
            Visual Type
          </div>
          <Select
            displayEmpty
            value={visualType}
            onChange={(e) => {
              setVisualType(e.target.value);
              if (e.target.value === visualTypes.funnel) {
                setCheckedSeries(prevState => {
                  if (!prevState) return []
                  return prevState.slice(0, 1)
                })
                setXAxis('')
                setXAxisTicks('All')
              } else if (![visualTypes.line, visualTypes.bar].includes(e.target.value)) {
                setIsStacked(e.target.value === visualTypes.waterfall ? 'Stacked' : 'Non-Stacked');
              }
            }}
            sx={{
              width: '100%',
              height: '1.7em',
              textAlign: 'center',
              background: theme.palette.mode === 'dark' ? 'black' : 'white'
            }}
            disabled={_.isEmpty(datasetColumns)}
          >
            {Object.keys(visualTypes).map((type) => (
              <MenuItem key={type} value={type}>
                {capitaliseFirstLetter(type) + ' Chart'}
              </MenuItem>
            ))}
          </Select>
        </div>
        {
          [visualTypes.line, visualTypes.bar].includes(visualType) &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                color: theme.palette.text.primary,
                textAlign: 'left',
              }}
            >
              Stack Type
            </div>
            <Select
              displayEmpty
              value={isStacked}
              onChange={(e) => {
                setIsStacked(e.target.value);
              }}
              sx={{
                width: '100%',
                height: '1.7em',
                textAlign: 'center',
                background: theme.palette.mode === 'dark' ? 'black' : 'white'
              }}
              disabled={_.isEmpty(datasetColumns) || [visualTypes.waterfall, visualTypes.pie, visualTypes.funnel].includes(visualType)}
            >
              {['Non-Stacked', 'Stacked'].map((type) => (
                <MenuItem key={type} value={type}>
                  {capitaliseFirstLetter(type)}
                </MenuItem>
              ))}
            </Select>
          </div>
        }
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          padding: '1em',
          border: 'solid',
          borderRadius: '0.5em',
          borderColor: newColor.darkGunmetal,
          borderWidth: '0.5px',
          gap: '1em',
          height: 'fit-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: theme.palette.text.primary,
              textAlign: 'left',
            }}
          >
            Series
          </div>
          <Select
            displayEmpty
            value={checkedSeries}
            onChange={(e) => {
              setCheckedSeries(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,);
            }}
            sx={{
              width: '100%',
              height: '1.7em',
              textAlign: 'center',
              background: theme.palette.mode === 'dark' ? 'black' : 'white'
            }}
            multiple
            disabled={_.isEmpty(datasetColumns)}
            renderValue={(selected) => selected.join(', ')}
          >
            {datasetColumns?.map((column: string) => (
              <MenuItem key={column} value={column}
                disabled={
                  (visualType === visualTypes.waterfall && checkedSeries.length >= 2 && !checkedSeries.includes(column)) ||
                  _.isEmpty(datasetColumns) ||
                  (visualType === visualTypes.funnel && checkedSeries.length >= 1 && !checkedSeries.includes(column))
                }
              >
                <Checkbox checked={checkedSeries.indexOf(column) > -1} />
                <ListItemText primary={column} />
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: theme.palette.text.primary,
              textAlign: 'left',
            }}
          >
            Legend
          </div>
          <Select
            displayEmpty
            value={showingLegend}
            onChange={(e) => {
              setShowingLegend(e.target.value);
            }}
            sx={{
              width: '100%',
              height: '1.7em',
              textAlign: 'center',
              background: theme.palette.mode === 'dark' ? 'black' : 'white'
            }}
            disabled={_.isEmpty(datasetColumns)}
          >
            <MenuItem key={'Show'} value={'Show'}>
              {capitaliseFirstLetter('Show')}
            </MenuItem>
            <MenuItem key={'Hide'} value={'Hide'}>
              {capitaliseFirstLetter('Hide')}
            </MenuItem>
          </Select>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          padding: '1em',
          border: 'solid',
          borderRadius: '0.5em',
          borderColor: newColor.darkGunmetal,
          borderWidth: '0.5px',
          gap: '1em',
          height: 'fit-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              color: theme.palette.text.primary,
              textAlign: 'left',
            }}
          >
            X-Axis Series
          </div>
          <Select
            displayEmpty
            value={xAxis}
            onChange={(e) => {
              setXAxis(e.target.value);
            }}
            sx={{
              width: '100%',
              height: '1.7em',
              textAlign: 'center',
              background: theme.palette.mode === 'dark' ? 'black' : 'white'
            }}
            disabled={_.isEmpty(datasetColumns)}
          >
            <MenuItem key={'HelikaRowNo.'} value={''}>
              {capitaliseFirstLetter('RowNo.')}
            </MenuItem>
            {datasetColumns?.map((column: string) => (
              <MenuItem key={column} value={column}>
                {capitaliseFirstLetter(column)}
              </MenuItem>
            ))}
          </Select>
        </div>
        {
          visualType !== visualTypes.pie &&
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                color: theme.palette.text.primary,
                textAlign: 'left',
              }}
            >
              X-Axis Ticks
            </div>
            <Select
              displayEmpty
              value={xAxisTicks}
              onChange={(e) => {
                setXAxisTicks(e.target.value);
              }}
              sx={{
                width: '100%',
                height: '1.7em',
                textAlign: 'center',
                background: theme.palette.mode === 'dark' ? 'black' : 'white'
              }}
              disabled={_.isEmpty(datasetColumns) || visualType === visualTypes.funnel}
            >
              <MenuItem key={'All'} value={'All'}>
                {capitaliseFirstLetter('All')}
              </MenuItem>
              <MenuItem key={'Minimal'} value={'Minimal'}>
                {capitaliseFirstLetter('Minimal')}
              </MenuItem>
            </Select>
          </div>
        }
      </Box>
    </Box >
  )
}