import {
  useState,
  ChangeEvent,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { Typography, useTheme } from '@mui/material'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import {
  Box,
  Button,
  styled as muiStyled,
  IconButton,
  Pagination,
} from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { ReactComponent as StatusIcon } from 'src/assets/notification_status_icon.svg'
import { ReactComponent as TitleIcon } from 'src/assets/notification_title_icon.svg'
import { ReactComponent as DescriptionIcon } from 'src/assets/notification_description_icon.svg'
import { ReactComponent as CreatedIcon } from 'src/assets/notification_created_icon.svg'
import { ReactComponent as OpenIcon } from 'src/assets/notification_open_icon.svg'
import { ReactComponent as OpenLinkIcon } from 'src/assets/notification_open_link_icon.svg'
import { ReactComponent as CreatedAtIcon } from 'src/assets/notification_createdat_icon.svg'
import { ReactComponent as DateIcon } from 'src/assets/notification_date_icon.svg'
import { ReactComponent as ItemStatusIcon } from 'src/assets/notification_item_status.svg'
import { ReactComponent as HeaderIcon } from 'src/assets/notifications_header_icon.svg'
import loadingSVG from '../../assets/loading.svg'

import NotificationsIcon from '../../assets/notifications_page_icon.svg'
import PageContainer from '../../components/atoms/v2/pageContainer'
import CircularPageHeader from '../../components/atoms/v2/circularPageHeader'
import ThemedIcon from '../../components/organisms/v2/themedIcon'
import { Auth0Context } from '../../contexts/Auth0Context'
import {
  getNotifications,
  setAllNotificationsReadApi,
} from '../../utils/api/queries'
import LoadingComponent from '../../components/LoadingComponent'
import { actionCreators } from '../../state'
import { DOMAIN_TYPES } from '../../state/action-types'

const Container = muiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
  borderRadius: '0.625rem',
  padding: '1.5rem',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  flex: '1 1 auto',
  overflowX: 'auto',
}))

const Label = muiStyled('span')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 400,
  marginLeft: '10px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
}))

const Header = muiStyled('div')(() => ({
  display: 'flex',
}))

const SelectType = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCustom = muiStyled('select')(({ theme }) => ({
  width: '60px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 5px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface ButtonProps {
  isActive: boolean
  children?: JSX.Element
}

const StatusFilterButton = muiStyled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ButtonProps>(({ theme, isActive }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  color: theme.palette.text.primary,
  borderRadius: '6px',
  width: '109.406px',
  height: '26px',
  flexShrink: '0',
  border: `1px solid ${isActive ? newColor.jazzberryJam : newColor.darkGunmetal}`,
  cursor: 'pointer',
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
}))

const TableHeader = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  width: '100%',
  borderTop: `1px solid ${newColor.jet}`,
  marginTop: '1rem',
  paddingTop: '14px',
}))

const HeaderCell = muiStyled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? newColor.white : 'rgba(0, 0, 0, 0.87)',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  fontSize: '15px',
  justifyContent: 'center',
  borderRightWidth: '1px',
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.cultured,
}))

const Cell = muiStyled(Box)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? newColor.boulder : 'rgba(0, 0, 0, 0.87)',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  fontSize: '15px',
  padding: '0 1rem',
  borderRightWidth: '1px',
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.cultured,
}))

const TableRow = muiStyled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: ' 1px solid',
  borderColor: newColor.jet,
  marginBottom: '0.75rem',
  height: '50.023px',
  background: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.cultured,
  display: 'flex',
  marginTop: '1rem',
}))

const DateCell = muiStyled('div')(({ theme }) => ({
  fontSize: '14.5px',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
}))

const notificationTypes = [
  {
    value: 'all',
    label: 'ALL',
  },
  {
    value: 'general',
    label: 'GENERAL',
  },
  {
    value: 'system',
    label: 'SYSTEM',
  },
  {
    value: 'game_management',
    label: 'GAME MANAGEMENT',
  },
  {
    value: 'support',
    label: 'SUPPORT',
  },
  {
    value: 'analytics',
    label: 'ANALYTICS',
  },
  {
    value: 'ua',
    label: 'UA',
  },
]

export default function NotificationsNew() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const userData = useSelector((state: any) => state.user)
  const navigate = useNavigate()
  const { getTokenIfNecessary, postWithAccessToken } = useContext(Auth0Context)
  const { setNotifications: setNotificationsStore, setDomain } =
    bindActionCreators(actionCreators, dispatch)

  const [type, setType] = useState<string>('all')
  const [readActive, setReadActive] = useState<boolean>(false)
  const [unreadActive, setUnreadActive] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [markReadLoading, setMarkReadLoading] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const onChangeType = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setType(e?.target.value)
    }
  }

  const onOpenReport = useCallback(
    async (reportId: number, status: string, notifId: number) => {
      if (reportId && status === 'unread') {
        await postWithAccessToken(
          `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/mark-as-read/${notifId}`,
        )
        setNotificationsStore({
          type: 'READ_NOTIFICATIONS',
          payload: notifId,
        })
        setDomain({
          type: 'SET_DOMAIN',
          payload: DOMAIN_TYPES.SUPPORT,
        })
      }
      navigate('/support/user_reports/reports', {
        state: { notification_read_report_id: reportId },
      })
    },
    [postWithAccessToken, setNotificationsStore, setDomain, navigate],
  )

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newSize = +event.target.value
    if (newSize >= totalCount) {
      setPage(1)
    }
    setPageSize(+event.target.value)
  }

  const toggleButtonStatus = (button: string) => {
    if (button === 'read') {
      const newStatus = !readActive
      setReadActive(newStatus)
      if (newStatus && unreadActive) {
        setUnreadActive(!unreadActive)
      }
    } else {
      const newStatus = !unreadActive
      setUnreadActive(newStatus)
      if (newStatus && readActive) {
        setReadActive(!readActive)
      }
    }
  }

  const markAllAsRead = async () => {
    setMarkReadLoading(true)
    await setAllNotificationsReadApi(postWithAccessToken)
    toast.success('All Notifications Marked as Read')
    setMarkReadLoading(false)
    loadNotifications(1)
  }

  useEffect(() => {
    let status = readActive ? 'read' : undefined
    status = unreadActive ? 'unread' : status
    const notificationType = type === 'all' ? undefined : type

    loadNotifications(page, status, notificationType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readActive, unreadActive, type, page, pageSize])

  //search configs given search term and filter
  async function loadNotifications(
    page?: number,
    notification_status?: string,
    notification_type?: string,
  ) {
    setIsLoading(true)

    let params = {
      page_size: pageSize,
      page: page,
      notification_status,
      notification_type,
    }
    let notifs = await getNotifications(
      userData.id,
      getTokenIfNecessary,
      params,
    )
    setTotalCount(notifs?.count)
    setNotifications(notifs?.notifications || [])
    setIsLoading(false)
  }

  const bgColor = theme.palette.mode === 'dark' ? newColor.black : newColor.cultured

  return (
    <PageContainer bgColor={bgColor}>
      <CircularPageHeader text="notifications" icon={NotificationsIcon} />
      <Container>
        <Box sx={{ minWidth: '96em' }}>
          <Header>
            <Box alignItems="center" display={'flex'} sx={{ flex: 1 }}>
              <ThemedIcon>
                <HeaderIcon />
              </ThemedIcon>
              <Label>
                {type === 'all' ? 'all notifications' : type.replace('_', ' ')}
              </Label>
            </Box>
            <Box display={'flex'} sx={{ gap: '13.25px' }}>
              <StatusFilterButton
                isActive={readActive}
                onClick={() => toggleButtonStatus('read')}
              >
                <>
                  {/* To confirm, same hex code for dark and light */}
                  <ThemedIcon colorOnDark={newColor.caribbeanGreen} colorOnLight={newColor.caribbeanGreen}>
                    <ItemStatusIcon />
                  </ThemedIcon>
                  <span>READ</span>
                </>
              </StatusFilterButton>
              <StatusFilterButton
                isActive={unreadActive}
                onClick={() => toggleButtonStatus('unread')}
              >
                <>
                  <ThemedIcon colorOnDark={newColor.jazzberryJam} colorOnLight={newColor.jazzberryJam}>
                    <ItemStatusIcon />
                  </ThemedIcon>
                  <span>UNREAD</span>
                </>
              </StatusFilterButton>
              <SelectType value={type} onChange={onChangeType}>
                {notificationTypes.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </SelectType>
            </Box>
          </Header>
          <TableHeader>
            <HeaderCell sx={{ width: '10%' }}>
              <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                <StatusIcon />
              </ThemedIcon>
              STATUS
            </HeaderCell>
            <HeaderCell sx={{ width: '15%' }}>
              <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                <TitleIcon />
              </ThemedIcon>
              TITLE
            </HeaderCell>
            <HeaderCell sx={{ width: '45%' }}>
              <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                <DescriptionIcon />
              </ThemedIcon>
              DESCRIPTION
            </HeaderCell>
            <HeaderCell sx={{ flex: 1 }}>
              <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                <CreatedIcon />
              </ThemedIcon>
              CREATED
            </HeaderCell>
            <HeaderCell sx={{ flex: 1 }}>
              <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                <OpenIcon />
              </ThemedIcon>
              OPEN
            </HeaderCell>
          </TableHeader>
          <div>
            {isLoading ? (
              <>
                <br /> <LoadingComponent />
              </>
            ) : (
              <></>
            )}
            {!isLoading &&
              notifications.map((notif: any) => {
                const statusColor =
                  notif.status === 'read' ? newColor.caribbeanGreen : newColor.jazzberryJam
                return (
                  <TableRow key={notif.id}>
                    <Cell sx={{ width: '10%', justifyContent: 'center' }}>
                      <ThemedIcon
                        colorOnDark={statusColor}
                        colorOnLight={statusColor}
                      >
                        <ItemStatusIcon />
                      </ThemedIcon>
                    </Cell>
                    <Cell sx={{ width: '15%', textAlign: 'left' }}>
                      <ThemedIcon colorOnDark="transparent">
                        <TitleIcon stroke={newColor.white} />
                      </ThemedIcon>
                      <p className="ellipsisWithNoWrap">{notif.title}</p>
                    </Cell>
                    <Cell sx={{ width: '45%', textAlign: 'left' }}>
                      <ThemedIcon colorOnDark="transparent">
                        <DescriptionIcon stroke={newColor.white} />
                      </ThemedIcon>
                      <p className="ellipsisWithNoWrap">{notif.description}</p>
                    </Cell>
                    <Cell
                      sx={{
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 0,
                      }}
                    >
                      <DateCell>
                        <ThemedIcon colorOnDark={newColor.white}>
                          <DateIcon />
                        </ThemedIcon>{' '}
                        {moment(notif.created_at).format('MMM DD, YYYY')}
                      </DateCell>
                      <DateCell>
                        <ThemedIcon colorOnDark={newColor.white}>
                          <CreatedAtIcon />
                        </ThemedIcon>{' '}
                        {moment(notif.created_at).format('hh:mm A')}
                      </DateCell>
                    </Cell>
                    <Cell sx={{ flex: 1, justifyContent: 'center' }}>
                      {notif.data?.report_id && (
                        <IconButton
                          onClick={() =>
                            onOpenReport(
                              notif.data.report_id,
                              notif.status,
                              notif.id,
                            )
                          }
                        >
                          <ThemedIcon colorOnDark={newColor.jazzberryJam}>
                            <OpenLinkIcon />
                          </ThemedIcon>
                        </IconButton>
                      )}
                    </Cell>
                  </TableRow>
                )
              })}
            {!isLoading && notifications?.length ? (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-around',
                  }}
                >
                  <Button
                    onClick={markAllAsRead}
                    className="helikaButtonClass"
                    disabled={markReadLoading}
                    sx={{ left: '6em' }}
                  >
                    {markReadLoading && (
                      <img
                        width="14px"
                        className="animate-spin"
                        style={{ marginRight: '2px' }}
                        src={loadingSVG}
                        alt=""
                      />
                    )}
                    <Typography>Mark All As Read</Typography>
                  </Button>
                </div>
                <div style={{ display: 'flex' }}>
                  <Pagination
                    count={
                      isNaN(totalCount / Number(pageSize))
                        ? 1
                        : Math.ceil(totalCount / Number(pageSize))
                    }
                    page={page}
                    onChange={handlePaginationChange}
                  />
                  <SelectCustom
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option defaultChecked value={5}>
                      5
                    </option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </SelectCustom>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Container>
    </PageContainer>
  )
}
