import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'

import {
  Box,
  Button,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import { newColor } from 'src/consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any,
  loadQueryHandler: any,
  queryToLoad: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const LoadQueryModal = (props: SimpleDialogProps) => {
  const { open, setOpen, loadQueryHandler, queryToLoad } = props
  const theme = useTheme()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="load_query"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              LOAD QUERY
            </div>
          </DialogTitle>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'row'}
          textAlign={'left'}
          sx={{ padding: '0 2em 0 2em' }}
        >
          <div>{`Load Query: ${queryToLoad?.name}? You'll lose the progress on the current query if you don't save first.`}</div>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        sx={{ padding: '0 2em 2em 2em' }}
      >
        <Button
          sx={{ width: '15em' }}
          onClick={loadQueryHandler}
          className="helikaButtonClass"
        >
          Load Query
        </Button>
        <Button
          sx={{ width: '15em', marginLeft: '1em' }}
          onClick={() => setOpen(false)}
          className="helikaButtonClass"
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  )
}

export default LoadQueryModal
