import DragButtonIcon from 'src/assets/drag_button.svg'
import CustomExtractIcon from 'src/assets/icons/custom_extract_solid.svg'
import { ReactComponent as EditTabIcon } from 'src/assets/edit_tab_icon.svg'
import { ReactComponent as EditDefaultParamsIcon } from 'src/assets/edit_tab_default_params_icon.svg'
import { ReactComponent as RemoveTabIcon } from 'src/assets/remove_tab_icon.svg'
import icon_list from 'src/assets/google_icons_list.json'

import symbols from '@iconify-json/material-symbols/icons.json'
import { Typography, styled as muiStyled, IconButton } from '@mui/material'
import styled from 'styled-components'
import { DragButton, Tab, TabContentDivider, TabName } from './styled'
import { Draggable, DraggableStyle, Droppable } from '@hello-pangea/dnd'

import { ChildTab, TabLevel } from './type'
import { subTabs } from 'src/consts/tabs'
import { newColor } from '../../../../consts/colors'

const Lv3DragButton: any = styled(DragButton)`
  background: #333;
`

const Lv3TabName: any = styled(TabName)`
  background: #333;
  display: flex;
  gap: 6px;
  align-items: center;
`
const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& svg, & path': {
    fill: newColor.jazzberryJam,
  },
}))

const TabActionsContainer = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  padding: '5px',
  backgroundColor: 'rgb(51, 51, 51)',
  alignItems: 'center',
}))

const getChildTabStyle = (
  isDragging: boolean,
  draggableStyle?: DraggableStyle,
) => {
  return {
    userSelect: 'none',
    width: 'fit-content',
    paddingTop: '10px',
    ...draggableStyle,
  } as const
}

export default function ChildTabs({
  moduleTabId,
  parentTabId,
  childTabs,
  enableEditTab,
  enableDeleteTab,
  onDeleteTab,
  onEditParams,
  onEditTab,
  enableEditTabDefaultParams,
}: {
  moduleTabId: string
  parentTabId: number
  enableEditTab?: boolean
  enableDeleteTab?: boolean
  onDeleteTab?: any
  onEditParams?: any
  onEditTab?: any
  enableEditTabDefaultParams?: boolean
  childTabs: Array<ChildTab>
}) {
  const sortedChildTabs = [...childTabs].sort((a, b) => a.priority - b.priority)

  return (
    <Droppable
      droppableId={`${TabLevel.Child}-${moduleTabId}-${parentTabId}`}
      type={`${TabLevel.Child}-${moduleTabId}-${parentTabId}`}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{
            width: 'fit-content',
            marginLeft: '1.75rem',
          }}
        >
          {sortedChildTabs?.map(({ name, id, icon }, index) => (
            <Draggable
              key={`${TabLevel.Child}-${moduleTabId}-${parentTabId}-${id}`}
              draggableId={`${TabLevel.Child}-${moduleTabId}-${parentTabId}-${id}`}
              index={index}
            // isDragDisabled={!isEdit}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getChildTabStyle(
                    snapshot.isDraggingOver,
                    provided.draggableProps.style,
                  )}
                >
                  <Tab
                    direction="row"
                    divider={
                      <TabContentDivider orientation="vertical" flexItem />
                    }
                    style={{ width: '400px' }}
                  >
                    <Lv3DragButton
                      src={DragButtonIcon}
                      alt=""
                      {...provided.dragHandleProps}
                    />
                    <Lv3TabName>
                      {getChildTabIcon(name, icon)}
                      <Typography
                        sx={{
                          color: newColor.white,
                          textAlign: 'left',
                          wordBreak: 'break-word',
                        }}
                      >
                        {name}
                      </Typography>
                    </Lv3TabName>
                    {(enableEditTab ||
                      enableDeleteTab ||
                      enableEditTabDefaultParams) && (
                        <TabActionsContainer>
                          {enableEditTabDefaultParams && (
                            <IconButton
                              sx={{ height: 'fit-content' }}
                              size="small"
                              onClick={() => {
                                onEditParams(id)
                              }}
                            >
                              <StyledSvg>
                                <EditDefaultParamsIcon fontSize="inherit" />
                              </StyledSvg>
                            </IconButton>
                          )}
                          {enableEditTab && (
                            <IconButton
                              sx={{ height: 'fit-content' }}
                              size="small"
                              onClick={() => {
                                onEditTab(id)
                              }}
                            >
                              <StyledSvg>
                                <EditTabIcon fontSize="inherit" />
                              </StyledSvg>
                            </IconButton>
                          )}
                          {enableDeleteTab && (
                            <IconButton
                              sx={{ height: 'fit-content' }}
                              size="small"
                              onClick={() => {
                                onDeleteTab(id)
                              }}
                            >
                              <StyledSvg>
                                <RemoveTabIcon fontSize="inherit" />
                              </StyledSvg>
                            </IconButton>
                          )}
                        </TabActionsContainer>
                      )}
                  </Tab>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

function getChildTabIcon(tabName: string, icon: string) {
  let iconSubTab = <div style={{ flex: 1 }} />

  switch (tabName) {
    case subTabs.CUSTOM_EXTRACTS:
      iconSubTab = <img src={CustomExtractIcon} alt="Custom Extract" />
      break

    default:
      iconSubTab = (
        <span style={{ display: 'inline-flex' }}>
          {stringToChildIcon(icon)}
        </span>
      )
  }
  return <>{iconSubTab}</>
}

const symbols_list: any = symbols

export const ChildIcon = muiStyled('i')(({ theme }) => ({
  background: newColor.white,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
}))

export function stringToChildIcon(text: string, defaultIcon?: any) {
  let iconTag = 'dns'
  if (text && text !== '' && text !== null && text !== undefined) {
    iconTag = text
  } else if (
    defaultIcon &&
    defaultIcon !== '' &&
    defaultIcon !== null &&
    defaultIcon !== undefined
  ) {
    iconTag = defaultIcon
  }

  //use google symbols if necessary
  if (
    !icon_list.icons.map((icon) => icon.name).includes(text) &&
    symbols_list?.icons[text?.replace('_', '-')]
  )
    return <span className="material-symbols-outlined">{text}</span>

  if (text && !icon_list.icons.map((icon) => icon.name).includes(text))
    iconTag = 'dns'
  return (
    <ChildIcon className="material-icons" sx={{ fontSize: '16px' }}>
      {iconTag}
    </ChildIcon>
  )
}
