const initialState = {
  MAIN_DRAWER_OPEN: false,
  TAB_STATE: [{
    name: 'User Reports',
    icon: 'person',
    subTabs: [{
      name: 'Reports',
      icon: 'person'
    }]
  }],
  SUBTABS_STATE: {},
  SUPPORT_TICKETS: [],
};

export default function drawerReducer(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case "CLEAR_DATA":
      return initialState;
    case 'OPEN_DRAWER':
      newState.MAIN_DRAWER_OPEN = true;
      return newState;
    case 'CLOSE_DRAWER':
      newState.MAIN_DRAWER_OPEN = false;
      return newState;
    case "LOAD_MORE_SUPPORT_TICKETS":
      let updated_user_reports = newState.SUPPORT_TICKETS?.concat(action.payload);
      return Object.assign({}, state, { SUPPORT_TICKETS: updated_user_reports });
    case "SET_SUPPORT_DATA":
      if ('MAIN_DRAWER_OPEN' in action.payload) {
        newState.MAIN_DRAWER_OPEN = action.payload.MAIN_DRAWER_OPEN
      }
      if ('TAB_STATE' in action.payload) {
        newState.TAB_STATE = action.payload.TAB_STATE;
      }
      if ('subTabs' in action.payload) {
        let newSubtabState = state.SUBTABS_STATE;
        if (typeof action.payload.subTabs === 'object') {
          newSubtabState = action.payload.subTabs;
        } else {
          newSubtabState[action.payload.subTabs] = !state.SUBTABS_STATE[action.payload.subTabs]
        }
        newState.SUBTABS_STATE = newSubtabState;
      }
      return newState;
    case "SET_SUPPORT_TICKETS":
      return Object.assign({}, state, { SUPPORT_TICKETS: action.payload });
    default:
      return state;
  }
};