import React from 'react'
import { Box, Button, styled as muiStyled } from '@mui/material'
import '../../../../App.css'
import ThemedIcon from '../../../organisms/v2/themedIcon'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import { useSelector } from 'react-redux'
import InviteIcon from 'src/assets/Invite Email Icon.svg'

const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const Label = muiStyled('span')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 400,
  marginLeft: '10px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
}))

const Container = muiStyled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
  borderRadius: '0.625rem',
  padding: '1.5rem',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  flex: '0 1 auto',
}))

const Header = muiStyled('div')(() => ({
  display: 'flex',
}))

const SubHeader = muiStyled('h3')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '15px',
  lineHeight: 1.27,
  textTransform: 'uppercase',
  marginLeft: '2rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
}))

const Description = muiStyled('span')(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  marginLeft: '1.5rem',
  lineHeight: '127%',
  textAlign: 'left',
  width: 'auto',
  color: theme.palette.text.primary,
}))

const CustomExtra = muiStyled('span')(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  marginLeft: '1.5rem',
  lineHeight: '127%',
  textAlign: 'left',
  marginRight: '5%',
  color: newColor.jazzberryJam,
}))

const ButtonStyled = muiStyled(Button)(() => ({
  fontFamily: 'Paralucent Medium',
  background: `linear-gradient(to right,${newColor.jazzberryJam}, ${newColor.byzantium}) !important`,
  color: 'white !important',
  borderRadius: '100vmax !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  border: 'none !important',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '100px',
  height: '26px',
  cursor: 'pointer',
  lineHeight: '127%',
  textTransform: 'uppercase',
}))

export default function Card({
  label,
  actions,
  children,
  icon,
  subheaderIcon,
  subheaderText,
  subheaderExtra,
  description,
  customExtra,
  invite,
  access,
  onSearch,
  sx,
  onInviteClick,
}: any) {
  const StatusUpdateButton = () => {
    const style = {
      background: `linear-gradient(90deg, ${newColor.caribbeanGreen} 17.2%, ${newColor.richElectricBlue} 87.63%) !important`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      '&:hover': {
        background: `linear-gradient(90deg, ${newColor.richElectricBlue} 17.2%, ${newColor.caribbeanGreen} 87.63%) !important`,
      },
    } as any

    return (
      <ButtonStyled sx={style} onClick={onInviteClick}>
        <img src={InviteIcon} alt="invite_users" />
        INVITE USERS
      </ButtonStyled>
    )
  }

  const OrgID = useSelector((state: any) => state.user.organization_id)

  return (
    <Container sx={sx}>
      <Box>
        <Header>
          <Box alignItems="center" display={'flex'}>
            <ThemedIcon>{icon}</ThemedIcon>
            <Label>{label}</Label>
          </Box>
          {actions}
        </Header>
      </Box>
      {(subheaderText || subheaderIcon) && (
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <SubHeader>
            {subheaderIcon && <ThemedIcon>{subheaderIcon}</ThemedIcon>}
            {subheaderText}
          </SubHeader>
          {access && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '5%',
              }}
            >
              <Input
                placeholder="Search user name & email"
                type="input"
                onChange={onSearch}
              />
            </Box>
          )}
          {subheaderExtra}
          {invite && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
                marginRight: '5%',
              }}
            >
              <StatusUpdateButton />
            </Box>
          )}
        </div>
      )}
      {OrgID !== 5 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginY: '18px',
            gap: '20px',
          }}
        >
          {description && <Description>{description}</Description>}
          {customExtra && <CustomExtra>{customExtra}</CustomExtra>}
        </Box>
      ) : (
        <Box
          sx={{
            marginY: '18px',
          }}
        >
          {description && <Description>{description}</Description>}
        </Box>
      )}
      {children}
    </Container>
  )
}
