export const _validFileExtensions = [
  '.jpg',
  '.jpeg',
  '.bmp',
  '.png',
  '.tiff',
  '.jfif',
  '.webp',
  '.svgz',
  '.svg',
  '.ico',
  '.xbm',
  '.dib',
  '.pjp',
  '.apng',
  '.tif',
  '.pjpeg',
  '.avif',
]
