import { GridOptions } from 'ag-grid-community'
import { TimeCellButton } from '../components/agGrid/TimeCellButton'
import { AssignUserButton } from '../components/agGrid/AssignUserButton'
import { ReportedByRenderer } from '../components/agGrid/ReportedByRenderer'
import { DefaultActionMenuRenderer } from 'src/components/agGrid/DefaultActionMenuRenderer'
import { SelectSupportTicketRenderer } from '../components/agGrid/SelectSupportTicketRenderer'
import { SupportDataGridHeaderComponent } from '../components/agGrid/SupportDataGridHeaderComponent'
import { SUPPORT_TICKET_STATUSES } from '../consts/backend'
import { OpenNotificationButton } from '../components/agGrid/OpenNotificationButton'
import { ExperimentsDashboardRenderer } from '../components/agGrid/ExperimentsDashboardRenderer'
import { ExperimentsCreatedAtRenderer } from '../components/agGrid/ExperimentsCreatedAtRenderer'
import _ from 'lodash'
import { SupportDataGridSelectHeaderComponent } from '../components/agGrid/SupportDataGridSelectHeaderComponent'
import { StatusRenderer } from '../components/agGrid/StatusRenderer'
import { IdRenderer } from '../components/agGrid/IdRenderer'
import { OfferStatusRenderer } from 'src/components/agGrid/OfferStatusRenderer'
import { HelikaDataGridDefaultHeaderComponent } from '../components/agGrid/HelikaDataGridDefaultHeaderComponent'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const baseOptions: GridOptions = {
  rowData: [],
  overlayLoadingTemplate: 'Loading...',
  overlayNoRowsTemplate: 'No data to display',
  defaultColDef: {
    flex: 1,
    resizable: true,
    sortable: true,
    filter: true,
    minWidth: 30,
  },
  suppressColumnVirtualisation: true,
  alwaysShowHorizontalScroll: true,
  alwaysShowVerticalScroll: true,
  animateRows: true, // Optional - set to 'true' to have rows animate when sorted
  //rowSelection:'multiple' // Options - allows click selection of rows
}

export const defaultSupportPageTableHeaders = (users: any[], theme: any) => [
  {
    headerName: 'ID',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'id',
    flex: 1,
    minWidth: 100,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'GAME ID',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'game_id',
    flex: 1,
    minWidth: 150,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'REPORTED BY',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'reported_by',
    renderCell: ReportedByRenderer,
    minWidth: 350,
    flex: 1,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'REASON',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'title',
    minWidth: 200,
    flex: 2,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'TEXT',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'description',
    minWidth: 200,
    flex: 2,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'CREATED AT',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'created_at',
    renderCell: TimeCellButton,
    minWidth: 140,
    flex: 1,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'ASSIGNED',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'assigned_to',
    minWidth: 200,
    renderCell: AssignUserButton,
    slotProps: {
      users: users,
    },
    flex: 1.5,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'STATUS',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'status',
    minWidth: 100,
    align: 'center',
    renderCell: (params: any) => {
      let statuses = Object.entries(SUPPORT_TICKET_STATUSES)
      let value = statuses.find((item) => item[0] === params.value)
      if (value) {
        return value[1]
      } else {
        return ''
      }
    },
    flex: 1,
    headerAlign: 'center',
    theme: theme,
  },
]

export const selectedReportsHeaders = (theme: any) => [
  {
    headerName: 'GAME ID',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'game_id',
    flex: 1,
    filter: true,
    autoHeight: true,
    minWidth: 150,
    theme: theme,
  },
  {
    headerName: 'REPORTED BY',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'reported_by',
    cellRenderer: ReportedByRenderer,
    flex: 1,
    filter: true,
    autoHeight: true,
    minWidth: 400,
    resizable: true,
    theme: theme,
  },
  {
    headerName: 'REASON',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'title',
    filter: true,
    flex: 2,
    autoHeight: true,
    wrapText: true,
    minWidth: 200,
    theme: theme,
  },
  {
    headerName: 'TEXT',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'description',
    filter: true,
    flex: 2,
    autoHeight: true,
    wrapText: true,
    minWidth: 200,
    theme: theme,
  },
  {
    headerName: 'STATUS',
    renderHeader: (props: any) =>
      SupportDataGridHeaderComponent({ theme: theme, ...props }),
    field: 'status',
    flex: 0.5,
    autoHeight: true,
    filter: true,
    minWidth: 150,
    valueFormatter: (params: any) => {
      let value = params.value as keyof typeof SUPPORT_TICKET_STATUSES
      return SUPPORT_TICKET_STATUSES[value]
    },
    theme: theme,
  },
]

export const supportPageTableHeadersWithSelect = (
  users: any[],
  setSelectedReports: any,
  selectedReports: any,
  theme: any,
) => [
    {
      headerName: 'SELECT',
      renderHeader: SupportDataGridSelectHeaderComponent,
      renderCell: SelectSupportTicketRenderer,
      field: 'selected',
      flex: 1,
      minWidth: 120,

      cellRendererParams: {
        setSelectedReports: setSelectedReports,
      },
      selectedReports: selectedReports,
      theme: theme,
    },
    {
      headerName: 'ID',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      renderCell: IdRenderer,
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerAlign: 'start',
      align: 'start',
      theme: theme,
    },
    //Hide SLA column ENG-1035
    // {
    //   headerName: 'SLA',
    //   renderHeader: (props: any) =>
    //     SupportDataGridHeaderComponent({ theme: theme, ...props }),
    //   field: 'sla',
    //   flex: 1,
    //   minWidth: 100,
    //   headerAlign: 'center',
    //   align: 'center',
    //   theme: theme,
    //   renderCell: (props: any) => {
    //     const givenDate = new Date(props.row.created_at)
    //     let currentDate = new Date(props.row.closed_at)
    //     if (props.row.closed_at === null) currentDate = new Date()
    //     const differenceInMilliseconds = Number(currentDate) - Number(givenDate)
    //     const differenceInDays = Math.floor(
    //       differenceInMilliseconds / 1000 / 60 / 60 / 24,
    //     )
    //     const differenceInHours = Math.floor(
    //       (differenceInMilliseconds / 1000 / 60 / 60) % 24,
    //     )
    //     const differenceInMinutes = Math.floor(
    //       (differenceInMilliseconds / 1000 / 60) % 60,
    //     )

    //     return differenceInDays === 0
    //       ? ` ${differenceInHours} h ${differenceInMinutes} m`
    //       : `${differenceInDays} d ${differenceInHours} h ${differenceInMinutes} m`
    //   },
    // },
    {
      headerName: 'STATUS',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'status',
      minWidth: 100,
      align: 'start',
      renderCell: (props: any) => StatusRenderer({ theme: theme, ...props }),
      flex: 1,
      headerAlign: 'start',
      theme: theme,
    },
    {
      headerName: 'ASSIGNED',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'assigned_to',
      minWidth: 200,
      renderCell: (props: any) => AssignUserButton({ theme: theme, ...props }),
      slotProps: {
        users: users,
      },
      flex: 1.5,
      headerAlign: 'start',
      align: 'start',
      theme: theme,
    },
    {
      headerName: 'TYPE',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'type',
      flex: 1,
      minWidth: 100,
      headerAlign: 'start',
      align: 'start',
      theme: theme,
    },
    {
      headerName: 'TEXT',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'description',
      minWidth: 200,
      flex: 2,
      headerAlign: 'start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      theme: theme,
    },
    {
      headerName: 'REPORTER',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'reported_by',
      renderCell: ReportedByRenderer,
      width: 150,
      minWidth: 150,
      flex: 1,
      headerAlign: 'start',
      theme: theme,
    },

    {
      headerName: 'CREATED AT',
      renderHeader: (props: any) =>
        SupportDataGridHeaderComponent({ theme: theme, ...props }),
      field: 'created_at',
      renderCell: TimeCellButton,
      minWidth: 250,
      flex: 1,
      headerAlign: 'start',

      theme: theme,
    },
  ]

export const experimentsTableHeaders = (theme: any, setSgs: any) => [
  {
    headerName: 'Title',
    field: 'title',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Type',
    field: 'type',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Status',
    field: 'status',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Audience',
    field: 'audience',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Traffic',
    field: 'traffic',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Observations',
    field: 'observations',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
  },
  {
    headerName: 'Created',
    field: 'created',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
    renderCell: ExperimentsCreatedAtRenderer,
  },
  {
    headerName: 'Summary',
    field: 'summary',
    flex: 1,
    minWidth: 50,
    headerAlign: 'center',
    theme: theme,
    renderCell: (props: any) =>
      ExperimentsDashboardRenderer({ theme: theme, setSgs: setSgs }),
  },
]

export const defaultNotificationsPageTableHeaders = () => [
  {
    headerName: 'Status',
    field: 'status',
    flex: 0.5,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    sortable: false,
  },
  {
    headerName: 'TITLE',
    field: 'Title',
    flex: 1,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    sortable: false,
  },
  {
    headerName: 'DESCRIPTION',
    field: 'description',
    flex: 2,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    sortable: false,
  },
  {
    headerName: 'CREATED AT',
    field: 'created_at',
    flex: 1,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    sortable: false,
  },
  {
    headerName: 'OPEN',
    flex: 1,
    cellRenderer: OpenNotificationButton,
    minWidth: 50,
    sortable: false,
  },
]

let nullSpaceHandlingSortComparator = (
  v1: any,
  v2: any,
  cellParams1: any,
  cellParams2: any,
) => {
  const sortModel = cellParams1.api.getSortModel()
  const sortColumn = sortModel.find((sm: any) => sm.field === cellParams1.field)
  if (sortColumn && sortColumn.sort === 'desc') {
    if ((v1 === null && v2 === null) || (v1 === '' && v2 === '')) {
      return -1
    }
    if (v1 === null || v1 === '') {
      return -1
    }
    if (v2 === null || v2 === '') {
      return 1
    }
    return v1 - v2
  } else {
    if ((v1 === null && v2 === null) || (v1 === '' && v2 === '')) {
      return 0
    }
    if (v1 === null || v1 === '') {
      return 1
    }
    if (v2 === null || v2 === '') {
      return -1
    }
    return v1 - v2
  }
}

export function createHeadersFromColumnList(columns: any) {
  let headers = columns.map((column: any) => {
    return {
      headerName: column,
      field: column,
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
      sortable: true,
      resizable: true,
      valueGetter: (params: any) => {
        if (_.isNull(params.value)) {
          return 'NULL'
        }
        // Convert the decimal value to a percentage
        return params.value
      },
      sortComparator: nullSpaceHandlingSortComparator,
    }
  })

  let newRows = [
    {
      headerName: 'RowNo.',
      field: 'helika_row_id',
      flex: 0,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
  ]
  return newRows.concat([...headers])
}


export const offersManagementHeaders = (
  offers: any[],
  setSelectedOffer: any,
  selectedOffer: any,
  theme: any,
  setOpenEditOfferModal?: any,
  debouncedClickHandler?: any
) => [
    // {
    //   headerName: '',
    //   renderHeader: (props: any) =>
    //     HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_helika_selector' }),
    //   renderCell: SelectOfferRenderer,
    //   field: 'select',
    //   cellRendererParams: {
    //     offers: offers,
    //     selectedOffer: selectedOffer,
    //     setSelectedOffer: setSelectedOffer,
    //   },
    //   minWidth: 20,
    //   selectedOffer: selectedOffer,
    //   theme: theme,
    //   sortable: false,
    //   disableColumnMenu: true // This will hide it only for the Actions column
    // },
    {
      headerName: 'Title',
      renderHeader: (props: any) =>
        HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_title' }),
      field: 'title',
      flex: 3,
      minWidth: 200,
      headerAlign: 'start',
      align: 'left',
      theme: theme,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: 'Button URL',
      renderHeader: (props: any) =>
        HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_url' }),
      field: 'cta_url',
      minWidth: 200,
      align: 'left',
      flex: 3,
      headerAlign: 'start',
      theme: theme,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: 'Active',
      renderHeader: (props: any) =>
        HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_status' }),
      field: 'active',
      renderCell: OfferStatusRenderer,
      slotProps: {
        setSelectedOffer: setSelectedOffer,
        debouncedClickHandler,
      },
      minWidth: 100,
      align: 'left',
      flex: 1.5,
      headerAlign: 'start',
      theme: theme,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: 'Created',
      renderHeader: (props: any) =>
        HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_created' }),
      field: 'created_at',
      align: 'left',
      minWidth: 100,
      flex: 1.5,
      valueGetter: (params: any) => {
        const { row } = params
        return moment.tz(row.created_at, "UTC").tz(moment.tz.guess(true))?.format('DD/MM/YYYY');
      },
      headerAlign: 'start',
      theme: theme,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      renderHeader: (props: any) =>
        HelikaDataGridDefaultHeaderComponent({ theme: theme, ...props, key: 'offersManagementHeaders_actions' }),
      renderCell: DefaultActionMenuRenderer,
      field: 'action_menu',
      minWidth: 1,
      align: 'left',
      headerAlign: 'start',
      theme: theme,
      slotProps: {
        setSelectedOffer: setSelectedOffer,
        setOpenEditOfferModal: setOpenEditOfferModal,
        menuItems: [
          {
            value: 'Edit',
            onClick: () => {
              setOpenEditOfferModal(true)
            },
            icon: EditOutlinedIcon
          },
        ]
      },
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    }
  ]