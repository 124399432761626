import { ChangeEvent, useContext, useState } from 'react'

import styled from 'styled-components'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { handleError } from '../../../../utils/api/errors'
import { createAPIKeyApi } from '../../../../utils/api/queries'

const Title: any = styled.h3``

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

interface Org {
  label: string
  value: number
}

export default function LinkContractToOrg({ orgs }: { orgs: any[] }) {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [apiKeyName, setApiKeyName] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [org, setOrg] = useState<Org | null>(null)

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )
      setApiKey('')
      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const onChangeApiKeyName = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setApiKey('')
      setApiKeyName(e?.target.value.trim())
      setMessage('')
      setError('')
    }
  }

  const createNewApiKeyApi = async (
    apiKeyName: string,
    organization_id: number,
  ) => {
    if (apiKeyName && organization_id) {
      try {
        await createAPIKeyApi(postWithAccessToken, apiKeyName, organization_id)
          .then((resp: any) => {
            if (resp) {
              setApiKey(resp.results.key)
            }
          })
          .catch((err) => {
            handleError(err, setError, setMessage)
          })
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage('Please enter API Key Name and select organization.')
    }
  }

  const createNewApiKey = () => {
    setApiKey('')
    if (apiKeyName && org) {
      createNewApiKeyApi(apiKeyName, org.value)
    } else {
      setMessage('Please enter API Key Name and select an organization.')
    }
  }

  return (
    <Wrapper>
      <Title>Adds new API key</Title>
      <br />
      <label>Enter API Key name</label>
      <Input
        placeholder="Please enter API Key name"
        value={apiKeyName}
        type="text"
        required
        onChange={onChangeApiKeyName}
      />
      <label>Choose organization:</label>
      <Select value={org?.value} onChange={setSelectedOrg}>
        <option value=""></option>
        {orgs
          ?.sort((a: any, b: any) =>
            a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
          )
          ?.map((org) => (
            <option key={org.value} value={org.value}>
              {org.label}
            </option>
          ))}
      </Select>
      <Button onClick={createNewApiKey}>Create New API Key</Button>
      {apiKey && (
        <Message>
          <p>API Key created successfully</p>
          <p>Name: {apiKeyName}</p>
          <p>Key: {apiKey} </p>
        </Message>
      )}
      <Message>{message}</Message>
      <Error>{error}</Error>
    </Wrapper>
  )
}
