import React from 'react'
import styled, { css } from 'styled-components'

const linearGradient = (
  direction: 'left' | 'right' | 'top' | 'bottom',
  gradientColors: string[],
) => `linear-gradient(to ${direction}, ${gradientColors.join(',')})`

export const RoundGradientWrapper = styled.div.withConfig({
  shouldForwardProp: (prop: any) =>
    // eslint-disable-next-line eqeqeq
    prop != 'direction' && prop != 'gradientColors' && prop != 'borderWidth',
}) <{
  direction?: 'left' | 'right' | 'top' | 'bottom'
  gradientColors?: string[]
  borderWidth?: number
  children?: React.ReactNode
}>`
  ${({
  theme,
  direction = 'top',
  gradientColors = ['#EA335F', '#6926AD'],
  borderWidth = 3,
}) => css`
    position: relative;
    background: ${linearGradient(direction, gradientColors)};
    border-radius: 50px;
    padding: ${borderWidth}px;
  `}
`
