//imports
import LogoutButton from '../../components/LogoutButton';
import '../../App.css'

import { Box, Typography, useTheme } from '@mui/material';

//----------------------------------------------------------------------------------------------------------

export default function EmailVerified() {

  const theme = useTheme()

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      style={{ height: '100vh' }}
      justifyContent={'center'}
    >
      <Typography
        style={{
          color: theme.palette.text.primary,
          margin: '0 auto 2em auto',
          padding: '0 2em 0 2em',
          maxWidth: '40em'
        }}
        variant="h5"
      >
        Looks like you haven't verified your email yet. Please login after verifying with the verification email sent to you when you registered with Helika.
      </Typography>
      <div
        style={{
          border: 'solid',
          borderWidth: '2px',
          borderColor: theme.palette.text.primary,
          width: 'fit-content',
          margin: '0 auto 0 auto',
          borderRadius: '100vmax',
          padding: '0 1em 0 1em',
        }}
      >
        <LogoutButton />
      </div>
    </Box>
  );
};