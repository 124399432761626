import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { Dialog, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import Delete_Icon from '../../assets/delete_button.svg'
import Add_Icon from '../../assets/add_icon.svg'
import Default_Param_Icon from '../../assets/Default_Parameters_Icon.svg'
import Default_Param_Icon_Light from '../../assets/Default_Parameters_Icon_Light.svg'
import Header_Text_Icon from '../../assets/support_header_text_icon.svg'
import Header_Text_Icon_Light from '../../assets/support_header_text_icon_dark.svg'
import Organization_Tab_Icon from '../../assets/Organization_Tab_Icon.svg'
import {
  editOrganizationParamApi,
  getDashBoardParentTabApi,
} from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import _ from 'lodash'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  tabId?: number
  selectedOrg?: any
}

export interface Dashboard_tabId {
  prm_collection: string
  chain_id: string
}

export interface OrgTabDashboard {
  id: number | null
  name: string
  iframe_link: string
  parent_tab_id: number | null
  default_params: any
}

export interface DefaultParam {
  key: string
  value: string
}

export default function OrganizationTabModal(props: SimpleDialogProps) {
  const { open, setOpen, tabId, selectedOrg } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const { getWithAccessToken } = useContext(Auth0Context)
  const [dashboardDefaultParamList, setDashboardDefaultParamList] = useState<DefaultParam[]>([])
  const [tabDashboardList, setTabDashboardList] = useState<any[]>([])
  const { postWithAccessToken } = useContext(Auth0Context)

  const [orgTabDashboard, setOrgTabDashboard] = useState<OrgTabDashboard>({
    id: null,
    name: '',
    iframe_link: '',
    parent_tab_id: null,
    default_params: null,
  })

  const getDashboardList = useCallback(() => {
    if (!tabId) return
    getDashBoardParentTabApi(getWithAccessToken, tabId, selectedOrg)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setTabDashboardList(resp.results);
        let selectedDash = _.find(resp.results, { id: orgTabDashboard?.id })
        setOrgTabDashboard((prev) => {
          let hasNewVal = !prev || !prev?.parent_tab_id || prev.parent_tab_id !== tabId;
          if (hasNewVal) return resp.results[0];
          return prev;
        })

        let def_params = selectedDash?.default_params;
        if (_.isEmpty(def_params) || _.isEmpty(JSON.parse(def_params))) {
          setDashboardDefaultParamList([]);
          return;
        }
        setDashboardDefaultParamList(Object.entries(JSON.parse(def_params)).map(
          (item) => {
            return {
              key: item[0],
              value: item[1] ? item[1].toString() : ''
            }
          }
        ))
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken, selectedOrg, tabId, orgTabDashboard?.id])

  useEffect(() => {
    getDashboardList()
  }, [getWithAccessToken, getDashboardList])

  const handleClose = () => {
    setOpen(false)
  }

  const handlerOrgDashboard = (event: SelectChangeEvent) => {
    let selectedDash = _.find(tabDashboardList, { id: event.target.value });
    setOrgTabDashboard(selectedDash);

    let def_params = selectedDash?.default_params;
    if (_.isEmpty(def_params) || _.isEmpty(JSON.parse(def_params))) {
      setDashboardDefaultParamList([]);
      return;
    }
    setDashboardDefaultParamList(Object.entries(JSON.parse(def_params)).map(
      (item) => {
        return {
          key: item[0],
          value: item[1] ? item[1].toString() : ''
        }
      }
    ))
  }

  const editDashboardTabQuery = async () => {
    try {
      if (!tabId) return

      setLoading(true)
      let param: object = {}
      dashboardDefaultParamList.map((item) =>
        Object.assign(param, { [item.key]: item.value }),
      )

      let params = {
        dashboard_id: orgTabDashboard.id,
        default_params: JSON.stringify(param),
      }

      await editOrganizationParamApi(postWithAccessToken, selectedOrg, params)
        .then(() => {
          toast.success('Successful Edit Tab Params')
          setLoading(false)
        })
        .catch((err: any) => {
          toast.error(err)
          console.log(err)
          setLoading(false)
        })
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Organization_Tab_Icon}
              width={'24px'}
              height={'24px'}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              ORGANIZATION TAB
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          marginTop={'8px'}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'dark'
                  ? Default_Param_Icon
                  : Default_Param_Icon_Light
              }
              alt="APIs"
              width="24px"
              height="24px"
            />
            &nbsp;DEFAULT PARAMETERS
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            paddingLeft={'35px'}
            marginTop={'16px'}
          >
            <Grid
              columnSpacing={1}
              rowSpacing={2}
              display={'flex'}
              alignContent={'center'}
              flexDirection={'row'}
              gap={'24px'}
            >
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '215px',
                }}
              >
                <img
                  src={
                    theme.palette.mode === 'dark'
                      ? Header_Text_Icon
                      : Header_Text_Icon_Light
                  }
                  alt="create_tab"
                  style={{ margin: 'auto 0.3em auto 0' }}
                />{' '}
                <div
                  className="minimizeFontOnSmallWindow"
                  style={{ fontSize: '14px', width: 'max-content' }}
                >
                  SELECT DASHBOARD:
                </div>
              </Grid>
              <Grid style={{ width: '215px' }}>
                <Select
                  className={
                    theme.palette.mode === 'dark'
                      ? 'inputFieldClass3'
                      : 'inputFieldClass3Light'
                  }
                  labelId="search-filter"
                  size="small"
                  onChange={handlerOrgDashboard}
                  sx={{
                    height: '2em',
                    width: '100%',
                    padding: '0',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                  value={orgTabDashboard?.id?.toString() || ''}
                >
                  {tabDashboardList &&
                    tabDashboardList
                      .map(
                        (p: { id: number; name: string }, index: number) => (
                          <MenuItem
                            value={p.id}
                            key={index}
                            sx={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                          >
                            {p.name}
                          </MenuItem>
                        ),
                      )}
                </Select>
              </Grid>
            </Grid>

            {dashboardDefaultParamList &&
              dashboardDefaultParamList.map((item, index) => (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  key={index}
                  gap={'24px'}
                >
                  <Input
                    value={item.key}
                    key={index}
                    placeholder="<New Param>"
                    onChange={(e) => {
                      setDashboardDefaultParamList(
                        dashboardDefaultParamList.map((item, idx) =>
                          idx === index
                            ? { key: e.target.value, value: item.value }
                            : item,
                        ),
                      )
                    }}
                  />
                  <Input
                    value={item.value}
                    placeholder="<Param Value>"
                    onChange={(e) => {
                      setDashboardDefaultParamList(
                        dashboardDefaultParamList.map((item, idx) =>
                          idx === index
                            ? { value: e.target.value, key: item.key }
                            : item,
                        ),
                      )
                    }}
                    sx={{
                      width: '316px',
                    }}
                  />
                  <div className="flexrow">
                    <img
                      src={Delete_Icon}
                      style={{
                        marginRight: '0.5em',
                        cursor: 'pointer',
                      }}
                      alt=""
                      onClick={() => {
                        setDashboardDefaultParamList((prevState: any[]) => {
                          let newValues = [...prevState]?.filter(
                            (
                              dashboardDefaultParamList: Object,
                              mappingIndex: number,
                            ) => index !== mappingIndex,
                          )
                          return newValues
                        })
                      }}
                    />
                  </div>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
          gap={'14px'}
        >
          <Button
            className="helikaButtonClass"
            onClick={() =>
              setDashboardDefaultParamList((prevState: any) => {
                return prevState.concat([
                  {
                    key: '',
                    value: '',
                  },
                ])
              })
            }
            sx={{
              width: '220px',
            }}
          >
            <img src={Add_Icon} alt="Add" />
            &nbsp;ADD NEW PARAM
          </Button>
          <Button
            sx={{ width: '220px' }}
            disabled={loading}
            onClick={editDashboardTabQuery}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '220px' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
