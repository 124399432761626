import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import styled from 'styled-components'

import { LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES } from '../../../../consts/backend'
import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { handleError } from '../../../../utils/api/errors'
import _ from 'lodash'
import {
  addSocialConnectionApi,
  getSocialsApi,
} from '../../../../utils/api/queries'

const Title: any = styled.h3``

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

interface Org {
  label: string
  value: number
}

export default function AddSocialConnection({ orgs }: { orgs: any }) {
  const {
    isAuthenticated,
    getWithAccessToken,
    postWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [socialId, setSocialId] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [platforms, setPlatforms] = useState<string[]>([])
  const [platform, setPlatform] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const getSocials = useCallback(async () => {
    await getSocialsApi(getWithAccessToken)
      .then((res: any) => {
        if (!res.results || res.results.length === 0) return
        setPlatforms(() => {
          let uniquePlatforms: string[] = []
          res.results.forEach((platform: any) => {
            if (uniquePlatforms.indexOf(platform.platform) === -1) {
              uniquePlatforms.push(platform.platform)
            }
          })
          return uniquePlatforms
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken])

  useEffect(() => {
    if (
      isLoading ||
      !isAuthenticated ||
      _.isEmpty(orgs) ||
      !_.isEmpty(platforms)
    )
      return
    getSocials()
  }, [
    orgs.length,
    isLoading,
    isAuthenticated,
    orgs,
    platforms,
    getWithAccessToken,
    getSocials,
  ])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org: Org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const onChangeSocialId = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setSocialId(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const onChangeSocialPlatform = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setPlatform(e?.target.value)
    }
  }

  const addSocialConnection = async () => {
    if (socialId && org && platform) {
      try {
        await addSocialConnectionApi(
          postWithAccessToken,
          socialId,
          org?.value,
          platform,
        )
          .then((res) => {
            if (
              res.status ===
              LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES.SOCIAL_CONNECTION_IS_LINKED_TO_ORG
            ) {
              setMessage(
                `${socialId} ${platform} was succefully linked to organization ${org.label}`,
              )
              setError('')
              setSocialId('')
            } else if (
              res.status ===
              LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES.SOCIAL_CONNECTION_IS_ALREADY_LINKED_TO_ORG
            ) {
              setError('')
              setMessage(
                `${LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES.SOCIAL_CONNECTION_IS_ALREADY_LINKED_TO_ORG}`,
              )
            } else {
              setError('')
              setMessage(res.status)
            }
          })
          .catch((err) => console.log(err))
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage(
        `Please enter collection address and choose chain and organization`,
      )
    }
  }

  return (
    <Wrapper>
      <Title>
        Adds social connection to org in socials.organization_access
      </Title>
      <br />
      <label>Enter social platform id:</label>
      <Input
        placeholder="social platform id"
        value={socialId}
        type="text"
        required
        onChange={onChangeSocialId}
      />
      <label>Choose social platform:</label>
      <Select required value={platform} onChange={onChangeSocialPlatform}>
        <option disabled value="">
          {' '}
          -- select an option --{' '}
        </option>
        {platforms.map((platform) => (
          <option key={platform} value={platform}>
            {platform}
          </option>
        ))}
      </Select>
      <label>Choose organization:</label>
      <Select value={org?.value} onChange={setSelectedOrg}>
        <option disabled value="">
          {' '}
          -- select an option --{' '}
        </option>
        {orgs
          ?.sort((a: any, b: any) =>
            a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
          )
          .map((org: Org) => (
            <option key={org.value} value={org.value}>
              {org.label}
            </option>
          ))}
      </Select>
      <Button onClick={addSocialConnection}>
        Add Social Connection To Org
      </Button>
      <Message>{message}</Message>
      <Error>{error}</Error>
    </Wrapper>
  )
}
