import React, { useEffect, useState } from 'react'
import { Typography, Button, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import InboxIcon from '@mui/icons-material/Inbox'
import { newColor } from 'src/consts/colors'

// Common text styles for consistency
const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
})

const FloatingActionBarWrapper = styled('div')<{
  isVisible: boolean
  themeMode: 'dark' | 'light'
}>(({ isVisible, themeMode }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 20px',
  position: 'fixed',
  bottom: isVisible ? '20px' : '-100px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: '850px',
  height: '68px',
  backgroundColor:
    themeMode === 'dark' ? newColor.charcoal : newColor.whiteSmoke,
  border: `1px solid ${
    themeMode === 'dark' ? newColor.darkGunmetal : newColor.lightGray
  }`,
  boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  zIndex: 1000,
  transition: 'bottom 0.3s ease, opacity 0.3s ease',
  opacity: isVisible ? 1 : 0,
  pointerEvents: isVisible ? 'auto' : 'none',
}))

// Typography for displaying the selected item count
const SelectedText = styled(Typography)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    fontSize: '16px',
    lineHeight: '150%',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    flexGrow: 1,
    textAlign: 'left',
    fontFamily: 'Open Sans, sans-serif',
  }),
)

// Action buttons container
const ActionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
})

// Archive button styling
const ArchiveButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    border: `1px solid ${themeMode === 'dark' ? newColor.gunMetal : newColor.lightGray}`,
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.dimGray : newColor.gainsboro,
    },
  }),
)

// Update button styling
const UpdateButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    backgroundColor: themeMode === 'dark' ? newColor.jazzberryJam : '#EA335F',
    color: '#FFFFFF',
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor: themeMode === 'dark' ? '#E91E63' : '#D81B60',
    },
  }),
)

// FloatingActionBarComponent with bulk actions and visibility control
const FloatingActionBarComponent: React.FC<{
  selectedCodesLength: number
  handleBulkArchive: () => void
  handleUnselectAll: () => void
  isVisible: boolean
  isLoading: boolean
  showUpdateButton?: boolean
  handleUpdateUsageLimit?: () => void
}> = ({
  selectedCodesLength,
  handleBulkArchive,
  handleUnselectAll,
  isVisible,
  isLoading,
  showUpdateButton,
  handleUpdateUsageLimit,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode
  const [displayCount, setDisplayCount] = useState(selectedCodesLength)

  // Update display count only when the number of selected items changes
  useEffect(() => {
    setDisplayCount(selectedCodesLength)
  }, [selectedCodesLength])

  return (
    <FloatingActionBarWrapper isVisible={isVisible} themeMode={themeMode}>
      <SelectedText themeMode={themeMode}>
        {`${displayCount} items selected`}
      </SelectedText>
      <ActionsWrapper>
        <Typography
          onClick={handleUnselectAll}
          sx={{
            cursor: 'pointer',
            ...commonTextStyles(themeMode),
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Unselect all
        </Typography>
        <ArchiveButton
          themeMode={themeMode}
          onClick={handleBulkArchive}
          disabled={isLoading}
        >
          <InboxIcon sx={{ marginRight: '8px' }} />
          {isLoading ? 'Archiving...' : 'Archive'}
        </ArchiveButton>
        {showUpdateButton && (
          <UpdateButton themeMode={themeMode} onClick={handleUpdateUsageLimit}>
            Update usage limit
          </UpdateButton>
        )}
      </ActionsWrapper>
    </FloatingActionBarWrapper>
  )
}

export default React.memo(FloatingActionBarComponent)
