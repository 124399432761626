import React from 'react'
import { Box, styled as muiStyled, Grid, MenuItem } from '@mui/material'
import { newColor } from '../../../../consts/colors'
import { DOMAIN_TYPES } from 'src/state/action-types'
import { setDomain } from 'src/state/action-creators'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
  lowerCaseAndReplaceSpacesAndDashes,
  replaceSpacesAndDashes,
} from '../../../../utils/string'
import { hasAccessToModule } from '../../../../utils/access'
import NavigationBackroundImage from '../../../../assets/navigation_baground-image.png'
import { canSeeGDCDemo, canSeeHelikaAi } from '../../../../utils/user'
import { PRODUCT_DESCRIPTION } from '../../../../consts/backend'

interface NavagationItemTypeProp {
  navigationItemType?: 'enabled' | 'disabled'
}

const NavigationItem = muiStyled('div', {
  shouldForwardProp: (prop) => prop !== 'navigationItemType',
})<NavagationItemTypeProp>(({ navigationItemType }) => {
  const linearGradient = `linear-gradient(259deg, ${newColor.indigo} 24.99%, ${newColor.jazzberryJam} 100%)`
  return {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
    gap: '1rem',
    color: 'white',
    borderRadius: '10px',
    minHeight: '164px',
    width: '100%',
    backgroundImage: `${navigationItemType === 'enabled' ? `${linearGradient}` : ''}`,
    backgroundColor: '#191919',
    border:
      navigationItemType === 'disabled'
        ? `1px solid ${newColor.darkGunmetal}`
        : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    padding: '26px',
    position: 'relative',
    overflow: 'hidden',
    '& > div.domainName': {
      transform: 'translateY(30px)',
      transition: 'all .5s',
      '& > div.domainBlock > div.domainDescription': {
        transition: 'all .5s',
      },
    },
    '& > div.backgroundImage': {
      transition: 'all .5s',
    },
    '&:hover > div.domainName': {
      transform: 'translateY(-10px)',
      '& > div.domainBlock > div.domainDescription': {
        opacity: '1!important',
      },
    },
    '&:hover > div.backgroundImage': {
      transform: 'translateY(105px) rotate(133.338deg) ',
    },
  }
})

const NavigationBackround = muiStyled('div')(() => ({
  width: '314px',
  height: '315px',
  transform: 'rotate(133.338deg)',
  opacity: 0.4,
  backgroundImage: `url(${NavigationBackroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'lighten',
  position: 'absolute',
  top: '-120px',
  right: '-82px',
}))

export default function DomainNavigationList({
  options,
  disabled,
  hints,
  clickDrawer,
  drawerOpen,
  icons,
  handleClose,
}: {
  options: DOMAIN_TYPES[]
  disabled?: boolean[]
  hints?: (string | undefined)[]
  clickDrawer?: any
  drawerOpen: boolean
  icons: any[]
  handleClose: any
}) {
  const USER_DATA = useSelector((state: any) => state.user)
  const navigate = useNavigate()

  async function changeDomain(option: DOMAIN_TYPES) {
    setDomain({
      type: 'SET_DOMAIN',
      payload: option,
    })
    // handleClose()
    if (_.isEmpty(USER_DATA?.tabs)) {
      toast.error(`Could not load user's tabs`)
    }
    let tabNumber
    switch (option) {
      case DOMAIN_TYPES.GAME_MANAGEMENT:
        tabNumber = '2'
        break
      case DOMAIN_TYPES.SUPPORT:
        tabNumber = '3'
        break
      case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
        tabNumber = '4'
        break
      case DOMAIN_TYPES.ANALYTICS:
      default:
        tabNumber = '1'
    }
    if (
      !(tabNumber in USER_DATA.tabs) ||
      _.isEmpty(USER_DATA.tabs[tabNumber])
    ) {
      navigate(`/${option.toLocaleLowerCase()}`)
      return
    }
    let tabChildren = Object.entries(USER_DATA.tabs[tabNumber])
    tabChildren = _.orderBy(tabChildren, ['1.priority'], ['asc'])
    let firstTab: any = tabChildren[0][1]
    if (
      !_.isEmpty(tabChildren) &&
      !_.isEmpty(firstTab) &&
      !_.isEmpty(firstTab.children)
    ) {
      navigate(
        `/${option.toLocaleLowerCase()}/${lowerCaseAndReplaceSpacesAndDashes(firstTab.name)}/${lowerCaseAndReplaceSpacesAndDashes(firstTab.children[0].name)}`,
      )
    } else {
      navigate(`/${option.toLocaleLowerCase()}`)
    }
  }
  const domainList = options.map((option: DOMAIN_TYPES, index: number) => (
    <Grid item xs={12} sm={6} lg={4} key={index}>
      <MenuItem
        id="menuItemDomainDropdown"
        key={option + '_' + index}
        onClick={() => {
          changeDomain(option)
          handleClose()
          clickDrawer()
        }}
        disabled={
          !hasAccessToModule(option, USER_DATA) || (disabled && disabled[index])
        }
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: 0,
          borderRadius: '10px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <NavigationItem
          navigationItemType={
            !hasAccessToModule(option, USER_DATA) ||
            (disabled && disabled[index])
              ? 'disabled'
              : 'enabled'
          }
        >
          <div
            id={replaceSpacesAndDashes(option + index)}
            className="domainName"
          >
            <div
              data-tip={hints && hints[index] ? hints[index] : ''}
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              className="domainBlock"
            >
              {icons &&
                icons.length > index &&
                icons[index] !== null &&
                icons[index]}{' '}
              {option?.replaceAll('_', ' ')?.toUpperCase()}
              <div
                className="domainDescription"
                style={{
                  opacity: 0,
                  fontSize: '15px',
                  lineHeight: '100%',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {PRODUCT_DESCRIPTION[option]}
              </div>
            </div>
          </div>
          <NavigationBackround className="backgroundImage" />
        </NavigationItem>
      </MenuItem>
    </Grid>
  ))
  return (
    <Box sx={{ paddingX: `2.5rem`, paddingBottom: '2.5rem' }}>
      <Grid container spacing={4}>
        {domainList}

        {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
          process.env.REACT_APP_GDC_FLAG &&
          canSeeGDCDemo(USER_DATA) && (
            <Grid item xs={12} sm={6} lg={4}>
              <MenuItem
                onClick={() => {
                  window.location.href = '/game_demo'
                  handleClose()
                  clickDrawer()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  padding: 0,
                  borderRadius: '10px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <NavigationItem navigationItemType={'enabled'}>
                  <div className="domainName">
                    <div
                      className="domainBlock"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      {icons && icons[1]} GAME DEMO
                      <div
                        className="domainDescription"
                        style={{
                          opacity: 0,
                          fontSize: '15px',
                          lineHeight: '100%',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {PRODUCT_DESCRIPTION['GAME_DEMO']}
                      </div>
                    </div>
                  </div>
                  <NavigationBackround className="backgroundImage" />
                </NavigationItem>
              </MenuItem>
            </Grid>
          )}
        {process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
          process.env.REACT_APP_GDC_FLAG &&
          canSeeHelikaAi(USER_DATA) && (
            <Grid item xs={12} sm={6} lg={4}>
              <MenuItem
                id="menuItemDomainDropdown"
                onClick={() => {
                  window.location.href = '/helika-ai'
                  handleClose()
                  clickDrawer()
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  padding: 0,
                  borderRadius: '10px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <NavigationItem navigationItemType={'enabled'}>
                  <div className="domainName">
                    <div
                      className="domainBlock"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      {icons && icons[1]} HELIKA AI
                      <div
                        className="domainDescription"
                        style={{
                          opacity: 0,
                          fontSize: '15px',
                          lineHeight: '100%',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {PRODUCT_DESCRIPTION['HELIKA_AI']}
                      </div>
                    </div>
                  </div>
                  <NavigationBackround className="backgroundImage" />
                </NavigationItem>
              </MenuItem>
            </Grid>
          )}
      </Grid>
    </Box>
  )
}
