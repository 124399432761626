import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import styled, { css } from 'styled-components'
import { Stack, StackProps, Typography } from '@mui/material'

// utils
import { useGetDrawer } from 'src/utils/drawer'

// assets
import { ReactComponent as ExplorerSVG } from 'src/assets/explorer.svg'

interface ExplorerButtonWrapperProps extends StackProps {
  isDrawerOpen: boolean
  children?: React.ReactNode
}

const ExplorerButtonWrapper = styled.div.withConfig({
  shouldForwardProp: (prop: any) => prop !== 'isDrawerOpen',
}) <ExplorerButtonWrapperProps>`
  ${({ theme, isDrawerOpen }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 48px;
    width: ${isDrawerOpen ? `200px` : `48px`};
    background: ${isDrawerOpen
      ? `conic-gradient(from -1.03deg at 43.2% 55.21%, #6926AD -17.57deg, #141414 62.5deg, #8BE1C9 162deg, #EA335F 259.2deg, #6926AD 342.43deg, #141414 422.5deg)`
      : `linear-gradient(258.13deg, #8BE1C9 -11.61%, #6926AD 38.23%, #141414 97.37%)`};
    transition: all 0.5s ease;
    border-radius: 5px;

    &:hover {
      background: ${isDrawerOpen
      ? `conic-gradient(from -1.03deg at 43.2% 55.21%, #EA335F -17.57deg, #6926AD 62.5deg, #141414 162deg, #8BE1C9 259.2deg, #EA335F 342.43deg, #6926AD 422.5deg)`
      : `linear-gradient(258.13deg, #6926AD -11.61%, #141414 38.23%, #8BE1C9 97.37%)`};
    }
  `}
`

const ExplorerButton: FC = () => {
  const navigate = useNavigate()
  const isDrawerOpen = useGetDrawer()

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', mb: 1, cursor: 'pointer' }}
    >
      <ExplorerButtonWrapper
        role="button"
        isDrawerOpen={isDrawerOpen}
        onClick={
          isDrawerOpen
            ? () => navigate('/analytics/explorer/queries')
            : undefined
        }
      >
        <ExplorerSVG style={{ width: '32px', height: '32px' }} />
        {isDrawerOpen && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography
              component="span"
              sx={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              Explore&nbsp;+
            </Typography>
          </Stack>
        )}
      </ExplorerButtonWrapper>
    </Stack>
  )
}

export default ExplorerButton
