import React, { useState, useContext } from 'react'
import { Box, Stack, Button } from '@mui/material'
import EditableAreas from './EditableAreas'
import HeaderSection from './HeaderSection'
import ChatBox from './ChatBox'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { postLlmRequest } from 'src/utils/api/queries'
import { newColor } from 'src/consts/colors' // Ensure this is imported

interface WorkflowEditorProps {
  projectId?: number
  selectedProjectHooks?: any[] | null
}

const WorkflowEditor: React.FC<WorkflowEditorProps> = ({
  projectId,
  selectedProjectHooks,
}) => {
  const [logic, setLogic] = useState<string>('')
  const [eventMap, setEventMap] = useState<string>('')
  const [triggerKey, setTriggerKey] = useState<string>('')
  const { postWithAccessToken } = useContext(Auth0Context)

  // This function will format the logic code into a more readable format
  const beautifyCode = (codeString: string): string => {
    let beautifiedCode = codeString
    beautifiedCode = beautifiedCode.replace(/ \s*/g, '') // Remove extra spaces
    beautifiedCode = beautifiedCode.replace(/(\[|\])/g, '\n$1\n') // Add new lines around brackets

    const lines = beautifiedCode.split('\n')
    let indentLevel = 0
    const indentSize = 4

    const indentedLines = lines.map((line: string) => {
      line = line.trim()
      if (line === ']' || line === ':') indentLevel -= 1
      const currentIndentation = ' '.repeat(indentSize * indentLevel)
      const indentedLine = `${currentIndentation}${line}`
      if (line === '[' || line.endsWith('?')) indentLevel += 1
      return indentedLine
    })

    beautifiedCode = indentedLines
      .join('\n')
      .replace(/\n\s*\n/g, '\n')
      .trim()

    return beautifiedCode
  }

  const handleLlmRequest = async (
    userInput: string,
    addBotResponse: (response: string) => void,
  ) => {
    const requestBody = {
      description: userInput,
      project_id: projectId,
      hooks: selectedProjectHooks,
    }

    try {
      const response = await postLlmRequest(postWithAccessToken, requestBody)

      if (response.status === 'ok') {
        const { results } = response

        if (results.code && results.issue && results.description) {
          addBotResponse(
            `Error ${results.code}: ${results.issue} - ${results.description}`,
          )
        } else {
          const { trigger_key, event_map, logic } = results

          if (trigger_key) setTriggerKey(trigger_key)
          if (event_map) setEventMap(JSON.stringify(event_map, null, 2))

          if (logic) {
            // Re-apply beautifyCode for logic display
            const beautifiedLogic = beautifyCode(logic)
            setLogic(beautifiedLogic)

            // Log the beautified logic to verify
            console.log('Beautified Logic:', beautifiedLogic)
          }

          addBotResponse('Task created successfully!')
        }
      } else {
        addBotResponse('An error occurred. Please try again.')
      }
    } catch (error) {
      console.error('Error:', error)
      addBotResponse('Error processing your request.')
    }
  }

  const onUserInput = (
    userInput: string,
    addBotResponse: (response: string) => void,
  ) => {
    handleLlmRequest(userInput, addBotResponse)
  }

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        flexWrap: 'nowrap',
      }}
    >
      <Box
        sx={{
          flex: '1 1 70%',
          backgroundColor: newColor.black,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: '8px',
          boxSizing: 'border-box',
          overflowY: 'auto',
        }}
      >
        <HeaderSection
          hookTitle="Hook Title"
          hookDescription="Write a description for this hook"
        />
        <EditableAreas
          triggerKey={triggerKey}
          eventMap={eventMap}
          logic={logic}
          setTriggerKey={setTriggerKey}
          setEventMap={setEventMap}
          setLogic={setLogic}
        />
        <Stack direction="row" justifyContent="flex-end" sx={{ padding: 1.5 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: newColor.jazzberryJam,
              color: newColor.white,
              marginRight: '8px',
              '&:hover': {
                backgroundColor: newColor.byzantium,
              },
            }}
          >
            RETRY
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: newColor.darkGunmetal,
              color: newColor.white,
              '&:hover': {
                backgroundColor: newColor.dimGray,
              },
            }}
          >
            APPROVE
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          flex: '1 1 30%',
          backgroundColor: newColor.black,
          height: '100%',
          padding: '8px',
          boxSizing: 'border-box',
          marginRight: 0,
        }}
      >
        <ChatBox onUserInput={onUserInput} />
      </Box>
    </Stack>
  )
}

export default WorkflowEditor
