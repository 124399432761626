import React from 'react'
import { Button, Checkbox } from '@mui/material'
import { Box } from '@mui/material'
import moreInfoIcon from '../../assets/more_info_icon.svg'
import { useLocation, useNavigate } from 'react-router-dom'

export function SelectSupportTicketRenderer(props: any) {
  let func = props.func
    ? props.func
    : props.colDef?.cellRendererParams?.setSelectedReports
      ? props.colDef?.cellRendererParams?.setSelectedReports
      : null
  const selectedReports = props.colDef?.selectedReports
    ? props.colDef?.selectedReports
    : null
  const location = useLocation()
  const ticketInfoURL = `${location.pathname}/${props.row.id}`
  return (
    <>
      <Checkbox
        onChange={(val) => {
          if (func) {
            func((prevState: any) => {
              let newData: any = Object.assign({}, prevState)
              if (val.target.checked) {
                newData[props.row.id?.toString()] = props.row
              } else {
                delete newData[props.row.id?.toString()]
              }
              return newData
            })
          } else {
            console.error('Could not select item')
          }
        }}
        checked={selectedReports && props.row.id in selectedReports}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        sx={{ height: '100%', margin: 'auto', width: '5em' }}
      >
        <Button onClick={() => window.open(`${ticketInfoURL}`, '_blank')}>
          <img
            src={moreInfoIcon}
            style={{
              width: '5em',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              padding: '0.3em',
              height: '3em',
              minWidth: '1em',
            }}
            alt="More Info Icon"
          />
        </Button>
      </Box>
    </>
  )
}
