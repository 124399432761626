import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { styled as muiStyled, Typography, Button, Box } from '@mui/material'
import { toast } from 'react-toastify'

import { Auth0Context } from '../../contexts/Auth0Context'
import loadingSVG from '../../assets/loading.svg'
import {
  bustAllUserCacheApi,
  bustCacheApi,
  bustOrgCacheApi,
} from '../../utils/api/queries'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const Select = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))

const BustCacheRow = muiStyled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}))

const StyledInput = muiStyled(Input)({
  flex: 1,
  minWidth: '55%',
});

const StyledSelect = muiStyled(Select)({
  flex: 1,
  minWidth: '55%',
});

const StyledButton = muiStyled(Button)({
  minWidth: 'fit-content',
});


interface Org {
  label: string
  value: number
}

export default function BustCache({ orgs }: { orgs: Org[] }) {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [org, setOrg] = useState<Org | null>(null)
  const [loading, setLoading] = useState(false)
  const [userToBustCache, setUserToBustCache] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
    }
  }

  const bustUserCache = async () => {
    try {
      if (
        !userToBustCache ||
        userToBustCache.length < 5 ||
        !userToBustCache.includes('@') ||
        !userToBustCache.includes('.')
      ) {
        toast.error('Invalid user email')
      }
      await bustCacheApi(postWithAccessToken, userToBustCache)
        .then((resp: any) => {
          toast.success(`User's cache busted`)
          setUserToBustCache('')
        })
    } catch (e: any) {
      console.log(e)
      toast.error(e?.message)
    }
  }

  async function bustAllUsersCache() {
    try {
      bustAllUserCacheApi(postWithAccessToken)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust cache')
            return
          }
          toast.success('All users cache busted')
        })
        .catch((error: any) => {
          toast.error('Could not bust cache')
        })
    } catch (e) {
      toast.error('Could not bust cache')
    }
  }

  async function bustOrgCache() {
    try {
      if (!org) {
        toast.error('Must select an org')
        return
      }
      setLoading(true)
      bustOrgCacheApi(postWithAccessToken, org.value)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust org cache')
            return
          }
          toast.success(
            `Successfully busted cache for users in org: ${org.label}`,
          )
          setLoading(false)
        })
        .catch((error: any) => {
          setLoading(false)
          toast.error('Could not bust org cache')
        })
    } catch (e) {
      setLoading(false)
      toast.error('Could not bust org cache')
    }
  }

  return (
    <Box
      sx={{
        margin: '1rem 0.5rem 0.5rem 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <BustCacheRow>
        <label>All Users:</label>
        <Button
          onClick={bustAllUsersCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </Button>
      </BustCacheRow>

      <BustCacheRow style={{ gap: '1rem', alignItems: 'center', minWidth: '20rem' }}>
        <label>
          Specific User:
        </label>
        <StyledInput
          placeholder="e.g. user@helika.io"
          value={userToBustCache}
          type="text"
          required
          onChange={(e) => setUserToBustCache(e.currentTarget.value)}
          style={{ minWidth: '20vw' }}
        />
        <StyledButton
          onClick={bustUserCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </StyledButton>
      </BustCacheRow>

      <BustCacheRow style={{ gap: '1rem', alignItems: 'center', minWidth: '20rem' }}>
        <label>Org Users:</label>

        <StyledSelect value={org?.value} onChange={setSelectedOrg} style={{ minWidth: '20vw' }}>
          {orgs
            ?.sort((a: any, b: any) =>
              a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
            )
            .map((org) => (
              <option key={org.value} value={org.value}>
                {org.label}
              </option>
            ))}
        </StyledSelect>
        <StyledButton
          onClick={bustOrgCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </StyledButton>
      </BustCacheRow>
    </Box>
  )
}
