import { ChangeEvent, useContext, useState } from 'react'

import styled from 'styled-components'

import { RS_CHAIN_SCHEMAS } from '../../../../consts/backend'
import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { handleError } from '../../../../utils/api/errors'
import { linkCollectionToOrgApi } from '../../../../utils/api/queries'

const Title: any = styled.h3``

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

interface Org {
  label: string
  value: number
}

enum Access {
  is_internal = 'true',
  is_external = 'false',
}

export default function LinkContractToOrg({ orgs }: { orgs: any[] }) {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [address, setAddress] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [access, setAccess] = useState<Access>(Access.is_internal)
  const [chain, setChain] = useState('')

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const onChangeAddress = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setAddress(e?.target.value)
      setMessage('')
      setError('')
    }
  }

  const onChangeChain = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setChain(e?.target.value)
    }
  }
  const onChangeAccess = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setAccess(e?.target.value as Access)
    }
  }

  const linkCollectionToOrg = async () => {
    if (address && org && chain) {
      try {
        await linkCollectionToOrgApi(
          postWithAccessToken,
          address,
          org.label,
          org.value,
          chain,
          access,
        )
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage(
        `Please enter collection address and choose chain and organization`,
      )
    }
  }

  return (
    <Wrapper>
      <Title>
        Adds entry with (name, contract_address) into CHAIN.organization_access
      </Title>
      <br />
      <label>Enter contract address starting from 0x:</label>
      <Input
        placeholder="Type contract starting with 0x"
        value={address}
        type="text"
        required
        onChange={onChangeAddress}
      />
      <label>Choose chain:</label>
      <Select required value={chain} onChange={onChangeChain}>
        <option disabled value="">
          {' '}
          -- select an option --{' '}
        </option>
        {Object.entries(RS_CHAIN_SCHEMAS).map(([chain, schema]) => (
          <option key={schema} value={schema}>
            {chain}
          </option>
        ))}
      </Select>
      <label>Choose organization:</label>
      <Select value={org?.value} onChange={setSelectedOrg}>
        {orgs
          ?.sort((a: any, b: any) =>
            a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
          )
          ?.map((org) => (
            <option key={org.value} value={org.value}>
              {org.label}
            </option>
          ))}
      </Select>
      <label>Access:</label>
      <Select value={access} onChange={onChangeAccess}>
        <option key={Access.is_internal} value={Access.is_internal}>
          is_internal
        </option>
        <option key={Access.is_external} value={Access.is_external}>
          is_external
        </option>
      </Select>

      <Button onClick={linkCollectionToOrg}>Link Collection To Org</Button>
      <Message>{message}</Message>
      <Error>{error}</Error>
    </Wrapper>
  )
}
