import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import Analytics from '../../assets/analytics_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import { Auth0Context } from '../../contexts/Auth0Context'
import { saveUserVisualization } from '../../utils/api/queries'
import _ from 'lodash'
import { newColor } from 'src/consts/colors'
const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f;',
  },
}))

export interface SimpleDialogProps {
  visualName?: any,
  selectedQuery: any,
  setVisualName: any,
  queryCode: any
  open: boolean
  setOpen: any,
  setLastSaved: any,
  updateQueryListAndSchemaList: any,
  settings: any,
  newVisual: any
}

const SaveVisualModal = (props: SimpleDialogProps) => {
  const { open, setOpen, visualName, setVisualName, setLastSaved, updateQueryListAndSchemaList, selectedQuery, settings, newVisual } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [newVisualName, setNewVisualName] = useState<string>(visualName);
  const { postWithAccessToken } = useContext(Auth0Context)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setNewVisualName(visualName ? visualName : '')
  }, [visualName])

  async function saveHandler() {
    setLoading(true)
    if (_.isEmpty(newVisualName?.trim())) {
      toast.error('Visual name not be empty')
      setLoading(false)
      return
    }

    let args = Object.assign({}, {
      query_id: selectedQuery?.id,
      type: settings.visualType,
      name: visualName,
      series: settings.checkedSeries,
      x_axis: settings.xAxis,
      x_axis_ticks: settings.xAxisTicks,
      stacked: settings.stackType === 'Stacked',
      show_legend: settings.showingLegend === 'Show',
    })

    await saveUserVisualization(postWithAccessToken, args).then(async resp => {
      await updateQueryListAndSchemaList(resp?.results?.id).catch((e: any) => {
        toast.error('Could not reload Visuals List, please refresh')
        setLoading(false)
        return
      })
      setLastSaved(resp.results.updated_at)
      setOpen(false)
      setLoading(false)
      toast.success('Visual was saved')
    }).catch(e => {
      toast.error(e)
      setLoading(false)
    })
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          scrollbarGutter: 'both-sides',
        },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'32px'}
        style={{
          scrollbarGutter: 'both-sides'
        }}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
            scrollbarGutter: 'both-sides'
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              SAVE AS NEW VISUAL
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
            scrollbarGutter: 'both-sides'
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;VISUAL INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                VISUAL NAME:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Input
                value={visualName}
                placeholder="e.g. Weekly Totals Data Set"
                onChange={(e) => {
                  setVisualName(e.currentTarget.value)
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          {
            newVisual
          }
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '5em',
          width: '90%',
          margin: 'auto',
          scrollbarGutter: 'both-sides',
          overflow: 'auto'
        }}
      >
        {loading ? (
          <div
            style={{
              scrollbarGutter: 'both-sides'
            }}
          >
            <LoadingComponent />
          </div>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{
              padding: '0 2em 2em 2em',
              scrollbarGutter: 'both-sides'
            }}
          >
            <Button
              sx={{ width: '15em' }}
              disabled={loading}
              onClick={saveHandler}
              className="helikaButtonClass"
            >
              Save
            </Button>
            <Button
              sx={{ width: '15em', marginLeft: '1em' }}
              onClick={() => setOpen(false)}
              className="helikaButtonClass"
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default SaveVisualModal
