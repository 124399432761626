const initialState = {
    ORG_ROLE:{
        admin: 'admin',
        member: 'member',
    }
};

export default function OrgRolesReducer(state = initialState, action){
  switch (action.type) {
    default:
      return state;
  }
};