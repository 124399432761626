import { ChangeEvent, useContext, useState } from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'
import { Auth0Context } from '../../contexts/Auth0Context'
import { lowerCaseAndReplaceSpacesAndDashes } from '../../utils/string'
import { addOrgToDBApi } from '../../utils/api/queries'

import ExpandIcon from '../../assets/expand.svg'
import EmailIcon from '../../assets/Email_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import EmailIconDark from '../../assets/Email_Icon_dark.svg'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const ResponsiveGrid = muiStyled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(1110)]: {
    marginBottom: '40px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 0 40px 30px',
  },
}))

const ResponsiveGridWrapper = muiStyled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(1110)]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))
const DescriptionArea = muiStyled('div')(() => ({
  width: '277.663px',
  height: '169.52px',
  flexShrink: '0',
  borderRadius: '12px',
  background: `linear-gradient(246deg, ${newColor.cosmicCobalt} -10.05%, ${newColor.jazzberryJam} 101.22%)`,
  padding: '40px 8px 42px 10px',
  fontSize: '15px',
  color: 'white',
  float: 'right',
  marginRight: '20px',
}))
interface Team {
  label: string
  value: string
}

export default function CreateOrg({
  sigmaTeams,
  setSigmaTeams,
  sigmaTeam,
  setSigmaTeam,
}: {
  sigmaTeams: Team[]
  setSigmaTeams: React.Dispatch<React.SetStateAction<Team[]>>
  sigmaTeam: Team | null
  setSigmaTeam: React.Dispatch<React.SetStateAction<Team | null>>
}) {
  const { postWithAccessToken } = useContext(Auth0Context)
  const theme = useTheme()
  const [domain, setDomain] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const setSelectedTeam = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = sigmaTeams.find(
        (team) => team.value === e?.target.value,
      )
      if (newOption) {
        setSigmaTeam({
          label: newOption.label,
          value: newOption.value,
        })
      }
    }
  }

  const onChangeDomain = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setDomain(e?.target.value)
    }
  }

  const onChangeEmail = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setEmail(e?.target.value)
    }
  }

  const addOrganizationToTheDB = async () => {
    if (domain && sigmaTeam && email) {
      setLoading(true)
      const org: any = await addOrgToDBApi(
        postWithAccessToken,
        domain,
        sigmaTeam.value,
        sigmaTeam.label,
        email,
        lowerCaseAndReplaceSpacesAndDashes(sigmaTeam.label),
      )
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success(`Successful Created`)
          } else {
            toast.error(`Failed: ${res.detail}`)
          }
        })
        .catch((err: any) => {
          console.log(err.message)
        })
      const newTeams = sigmaTeams.filter(
        (team) => team.label !== org?.sigma_team,
      )
      setDomain('')
      setEmail('')
      setSigmaTeams(newTeams)
      setSigmaTeam(
        newTeams[0]
          ? {
            label: newTeams[0].label,
            value: newTeams[0].value,
          }
          : null,
      )
      setLoading(false)
    } else {
      toast.warning(`Please enter domain and choose organization from select`)
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? EmailIcon : EmailIconDark}
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;ASSOCIATE EMAIL TO ORGANIZATION</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <ResponsiveGridWrapper
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item md={8}>
            <InputGroup>
              <Typography>
                Choose what organization from sigma dashboard you want to
                onboard (which is not yet added to the public.organization
                table)
              </Typography>
              <SelectCategory
                required
                value={sigmaTeam?.value}
                onChange={setSelectedTeam}
              >
                {sigmaTeams
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  .map((team) => (
                    <option key={team.value} value={team.value}>
                      {team.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>
                Enter domain of client organization. It is important that this
                value matches the domain of the email address of the client
                employees as we use that to match client employees to
                organization when they sign up. It can&#39;t be any public email
                domain like gmail.com, yahoo.com, etc. Also it should never be
                equal to domain of any other organization in the database.
              </Typography>
              <Input
                placeholder="example: helika.io"
                value={domain}
                type="text"
                pattern="[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})"
                title="Valid domain name is required"
                required
                onChange={onChangeDomain}
              />
            </InputGroup>
            <InputGroup>
              <Typography>
                Enter admin email of the client. The person responsible for
                sending invites
              </Typography>
              <Input
                placeholder="example: support@helika.io"
                value={email}
                type="email"
                required
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$"
                title="Valid email"
                onChange={onChangeEmail}
              />
            </InputGroup>
          </Grid>
          <ResponsiveGrid item md={4} style={{ alignSelf: 'center' }}>
            <DescriptionArea>
              Creates sigma Organization user attribute and adds entry with
              (name, email_domain, sigma_team) into public.organization
            </DescriptionArea>
          </ResponsiveGrid>
        </ResponsiveGridWrapper>
        <Button
          onClick={addOrganizationToTheDB}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Create Sigma Attribute and Org in the db</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
