import { AdminSpan } from '../../../StyledSimpleComponents'
import helpIcon from '../../../../assets/help_icon.svg'
import helpIconHover from '../../../../assets/help_icon_hover.svg'

import 'react-tooltip/dist/react-tooltip.css'
import * as ReactTooltip from 'react-tooltip'
import { useState } from 'react'
import ContactSupportModal from '../../../../components/popups/ContactSupportModal'

export const HelpIcon = () => {
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  return (
    <>
    <AdminSpan
      key="help icon"
      data-tooltip-id="help_icon"
      data-tooltip-content={'Help'}
      id="helpIcon"
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setShowHelpModal(true)
      }}
    >
      <ReactTooltip.Tooltip id="help_icon" />
      <img id="helpIconNonHover" src={helpIcon} alt="helpIcon" />
      <img id="helpIconHover" src={helpIconHover} alt="helpIcon" />
    </AdminSpan>
    <ContactSupportModal open={showHelpModal} setOpen={setShowHelpModal}/>
    </>
    
  )
}
