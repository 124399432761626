import DialogTitle from '@mui/material/DialogTitle'
import React, { useState, useContext, useEffect } from 'react'

import { Box, styled as muiStyled, Button, useTheme, Grid } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import CreateTab_Icon from '../../assets/Create_Tab_Icon.svg'
import { ReactComponent as UserConfig } from 'src/assets/support_header_assigned_icon.svg'
import { isStringEmpty } from '../../utils/string'
import { isArrayEmpty, areAllPropertiesEmpty } from '../../utils/validators'
import { ReactComponent as DetailIcon } from 'src/assets/support_header_text_icon.svg'
import Add_Icon from '../../assets/add_icon.svg'
import Delete_Icon from '../../assets/delete_button.svg'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Auth0Context } from '../../contexts/Auth0Context'
import {
  FlightLandIcon,
  FlightLandIconHover,
  FlightLandIconLight,
} from 'src/components/svgCustom/customIcon'
import dayjs, { Dayjs } from 'dayjs'
import {
  setPointCampaign,
  getEventKeys,
  setPointConfig,
} from '../../utils/api/queries'
import { useSelector } from 'react-redux'
import { RewardEvent } from '@/pages/PointsV1'
import { newColor } from 'src/consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f;',
  },
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
}))

const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& svg, & path': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onCreated: () => void
  rewardEvents: RewardEvent[]
}

export interface EventData_mapping {
  configName: string
  userIdKey: string
  eventKeys: any[]
  eventValue: string
  eventKey: string
  pmValue: string
  intValue: number
}

export default function CreateCampaignModal(props: SimpleDialogProps) {
  const { open, setOpen, onCreated, rewardEvents } = props
  const theme = useTheme()
  const { organization_id } = useSelector((state: any) => state.user)
  const [loading, setLoading] = useState<boolean>(false)
  const { postWithAccessToken, getWithAccessToken } = useContext(Auth0Context)
  const [title, setTitle] = useState<string>('')
  const [eventDataMappings, setEventDataMappings] = useState<
    EventData_mapping[]
  >([])
  const [hover, setHover] = useState(false)
  const [hoverEnd, setHoverEnd] = useState(false)
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(null)
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(null)
  // const [rewardEvents, setRewardEvents] = useState([])

  const handleClose = () => {
    setTitle('')
    setEndTimeValue(null)
    setStartTimeValue(null)
    setEventDataMappings([])
    setOpen(false)
  }

  const resetFields = () => {
    setTitle('')

    setEndTimeValue(null)
    setStartTimeValue(null)
    setEventDataMappings([])
  }

  useEffect(() => {
    if (startTimeValue && endTimeValue)
      if (startTimeValue > endTimeValue)
        setEndTimeValue(startTimeValue.add(5, 'minute'))
  }, [startTimeValue, endTimeValue])

  function checkEmptyProperty(filteredConfigures: EventData_mapping[]) {
    setEventDataMappings(filteredConfigures)
    filteredConfigures.forEach((configure: EventData_mapping) => {
      for (const [key, value] of Object.entries(configure)) {
        if (key === 'eventKeys') continue
        if (
          value === null ||
          value === '' ||
          (key === 'intValue' && value === 0)
        ) {
          switch (key) {
            case 'configName':
              // eslint-disable-next-line
              throw 'Configure Name is required'
            case 'userIdKey':
              // eslint-disable-next-line
              throw 'User ID key is required'
            case 'eventValue':
              // eslint-disable-next-line
              throw 'Event is required'
            case 'pmValue':
              // eslint-disable-next-line
              throw 'Multiplier Type is required'
            case 'intValue':
              // eslint-disable-next-line
              throw 'Multiplier value can not be 0'
          }
        }
      }
    })
    return true
  }

  function configurationsValidate(configures: EventData_mapping[]) {
    if (configures.length === 1) return checkEmptyProperty(configures)
    else {
      const filteredConfigures = configures.filter(
        (obj) => !areAllPropertiesEmpty(obj),
      )
      if (filteredConfigures.length === 0) {
        setEventDataMappings([
          {
            configName: '',
            eventValue: '',
            eventKey: '',
            userIdKey: '',
            eventKeys: [],
            pmValue: '',
            intValue: 0,
          },
        ])
        isArrayEmpty(filteredConfigures, 'Configuration required')
        return false
      } else {
        return checkEmptyProperty(filteredConfigures)
      }
    }
  }

  async function createCampaignQuery() {
    try {
      isStringEmpty(title, 'Title required')
      configurationsValidate(eventDataMappings)
      if (title) {
        setLoading(true)
        let params = {
          name: title,
          organization_id: organization_id,
          start: startTimeValue,
          end: endTimeValue,
        }
        setPointCampaign(postWithAccessToken, params)
          .then((resp: any) => {
            if (!resp) {
              toast.error('Could not create Campaign')
              setLoading(false)
              return
            }
            toast.success('Campaign created, page will refresh')
            setOpen(false)
            resetFields()
            loopCreatePointsConfigQuery(resp.results[0].id)
            onCreated()
            return
          })
          .catch((error: any) => {
            toast.error('Could not create Campaign')
            setLoading(false)
          })

        setLoading(false)
      } else {
        toast.warning('Please enter Title and other fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  async function createPointsConfigQuery(
    config: EventData_mapping,
    points_campaign_id: number,
  ) {
    const params = {
      points_campaign_id: points_campaign_id,
      organization_id: organization_id,
      name: config.configName,
      event_map: {
        user_id_key: config.userIdKey,
      },
      data: {
        type: config.pmValue === '+' ? 'INCREMENT' : 'DECREMENT',
        value_multiplier: config.intValue,
        event_name: config.eventValue,
      },
    }

    await new Promise((resolve) => setPointConfig(postWithAccessToken, params))
    console.log('Async function executed')
  }

  async function loopCreatePointsConfigQuery(points_campaign_id: any) {
    const promises = []
    for (let i = 0; i < eventDataMappings.length; i++) {
      promises.push(
        createPointsConfigQuery(eventDataMappings[i], points_campaign_id),
      )
    }
    await Promise.all(promises)
    console.log('All executions completed')
  }

  const pointsConfigTextchangeHandler = (
    event: any,
    index: number,
    cKey: string,
  ) => {
    setEventDataMappings((prevState: any[]) => {
      let previousValues = [...prevState]
      if (previousValues[index]) {
        if (cKey === 'intValue')
          previousValues[index][cKey] = Number(event.target.value)
        else previousValues[index][cKey] = event.target.value
        return previousValues
      }
      return previousValues
    })
  }

  const pointsConfigSelectHandler = (
    event: any,
    index: number,
    cKey: string,
  ) => {
    const newPmValue = event.target.value
    setEventDataMappings((prevState: EventData_mapping[]) => {
      return prevState.map((mapping, mappingIndex) => {
        if (index === mappingIndex) {
          return {
            ...mapping,
            [`${cKey}`]: newPmValue,
          }
        }
        return mapping
      })
    })
  }

  const pointsConfigEventChangeHanlder = async (event: any, index: number, game_id: any, event_name: any) => {
    const newEventValue = `${game_id}-${event_name}`
    const eventKeys = await getEventKeysByEvent(game_id, event_name)
    setEventDataMappings((prevState: EventData_mapping[]) => {
      return prevState.map((mapping, mappingIndex) => {
        if (index === mappingIndex) {
          return {
            ...mapping,
            eventValue: newEventValue,
            eventKeys: eventKeys,
          }
        }
        return mapping
      })
    })
  }

  const getEventKeysByEvent = async (name: any, type: any) => {
    const data = await getEventKeys(getWithAccessToken, name, type)
    if (!data || !data.results) return []
    return data.results
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={CreateTab_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              Create Campaign
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <StyledSvg>
              <DetailIcon width={'21px'} height={'21px'} />
            </StyledSvg>
            &nbsp;&nbsp;DETAIL
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '150px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TITLE:
              </div>
            </Grid>
            <Grid style={{ width: '300px' }}>
              <Input
                value={title}
                placeholder="Title"
                onChange={(e) => {
                  setTitle(e.currentTarget.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          ></Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '150px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                START TIME:
              </div>
            </Grid>
            <Grid style={{ width: '300px' }}>
              <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="dateField"
                    value={startTimeValue}
                    onChange={(newValue) => setStartTimeValue(newValue)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '100px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
                    }}
                    minDateTime={dayjs()}
                    defaultValue={dayjs()}
                    slots={{
                      openPickerIcon: hover
                        ? FlightLandIcon
                        : theme.palette.mode === 'dark'
                          ? FlightLandIconHover
                          : FlightLandIconLight,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '150px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                END TIME:
              </div>
            </Grid>
            <Grid style={{ width: '300px' }}>
              <Box
                onMouseEnter={() => setHoverEnd(true)}
                onMouseLeave={() => setHoverEnd(false)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="dateField"
                    value={endTimeValue}
                    onChange={(newValue) => setEndTimeValue(newValue)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '100px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
                    }}
                    minDateTime={
                      startTimeValue === null
                        ? dayjs().add(5, 'minute')
                        : startTimeValue.add(5, 'minute')
                    }
                    defaultValue={
                      startTimeValue === null
                        ? dayjs().add(5, 'minute')
                        : startTimeValue.add(5, 'minute')
                    }
                    slots={{
                      openPickerIcon: hoverEnd
                        ? FlightLandIcon
                        : theme.palette.mode === 'dark'
                          ? FlightLandIconHover
                          : FlightLandIconLight,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          marginTop={'8px'}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <StyledSvg>
              <UserConfig width={'21px'} height={'21px'} />
            </StyledSvg>
            &nbsp;&nbsp;Configuration
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            paddingLeft={'35px'}
            marginTop={'16px'}
            paddingBottom={'16px'}
            sx={{}}
          >
            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'16px'}
              paddingBottom={'16px'}
              sx={{
                overflowX: 'auto',
                // whiteSpace: 'nowrap',
              }}
            >
              {eventDataMappings?.map(
                (eventData_mapping: EventData_mapping, index: number) => {
                  return (
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      key={index}
                      gap={'24px'}
                    >
                      <Grid style={{ width: '340px' }}>
                        <label>Name:</label>
                        <Input
                          value={eventData_mapping.configName}
                          onChange={(e) => {
                            pointsConfigTextchangeHandler(
                              e,
                              index,
                              'configName',
                            )
                          }}
                          sx={{
                            width: '316px',
                          }}
                        />
                      </Grid>
                      <Grid style={{ width: '235px' }}>
                        <label>Event:</label>
                        <Select
                          className={
                            theme.palette.mode === 'dark'
                              ? 'inputFieldClass3'
                              : 'inputFieldClass3Light'
                          }
                          labelId="search-filter"
                          defaultValue={''}
                          size="small"
                          onChange={(event: SelectChangeEvent) => {
                            const value = event.target.value
                            const [game_id, event_name] = value.split('-')
                            pointsConfigEventChangeHanlder(
                              event,
                              index,
                              game_id,
                              event_name
                            )
                          }}
                          sx={{
                            height: '2em',
                            width: '211px',
                            padding: '0',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                          value={
                            eventData_mapping.eventValue
                              ? eventData_mapping.eventValue.toString()
                              : ''
                          }
                        >
                          {rewardEvents.map((rEvent: any, index: number) => (
                            <MenuItem
                              key={index}
                              sx={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}
                              value={`${rEvent.game_id}-${rEvent.event_name}`}
                            >
                              {rEvent.game_id} | {rEvent.event_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid style={{ width: '215px' }}>
                        <label>User ID Key:</label>
                        <Select
                          className={
                            theme.palette.mode === 'dark'
                              ? 'inputFieldClass3'
                              : 'inputFieldClass3Light'
                          }
                          labelId="search-filter"
                          defaultValue={''}
                          size="small"
                          onChange={(event: SelectChangeEvent) => {
                            pointsConfigSelectHandler(event, index, 'userIdKey')
                          }}
                          sx={{
                            height: '2em',
                            width: '191px',
                            padding: '0',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                          value={
                            eventData_mapping.userIdKey
                              ? eventData_mapping.userIdKey.toString()
                              : ''
                          }
                        >
                          {eventData_mapping.eventKeys.map((rKey: any) => (
                            <MenuItem
                              sx={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}
                              value={rKey}
                            >
                              {rKey}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid style={{ width: '60px' }}>
                        <label>Type</label>
                        <Select
                          className={
                            theme.palette.mode === 'dark'
                              ? 'inputFieldClass3'
                              : 'inputFieldClass3Light'
                          }
                          labelId="search-filter"
                          defaultValue={''}
                          size="small"
                          onChange={(event: SelectChangeEvent) => {
                            pointsConfigSelectHandler(event, index, 'pmValue')
                          }}
                          sx={{
                            height: '2em',
                            width: '60px',
                            padding: '0',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                          value={
                            eventData_mapping.pmValue
                              ? eventData_mapping.pmValue.toString()
                              : ''
                          }
                        >
                          <MenuItem
                            sx={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                            value={'+'}
                          >
                            +
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                            value={'-'}
                          >
                            -
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid style={{ width: '100px' }}>
                        <label>Multiplier:</label>
                        <Input
                          value={eventData_mapping.intValue}
                          onChange={(e) => {
                            pointsConfigTextchangeHandler(e, index, 'intValue')
                          }}
                          sx={{
                            width: '100px',
                          }}
                          type="number"
                          min="0"
                        />
                      </Grid>
                      <div className="flexrow">
                        <img
                          src={Delete_Icon}
                          style={{ marginRight: '0.5em', cursor: 'pointer' }}
                          alt=""
                          onClick={() => {
                            setEventDataMappings((prevState: any[]) => {
                              let newValues = [...prevState]?.filter(
                                (
                                  eventData_mapping: EventData_mapping,
                                  mappingIndex: number,
                                ) => index !== mappingIndex,
                              )
                              return newValues
                            })
                          }}
                        />
                      </div>
                    </Box>
                  )
                },
              )}
            </Box>

            <Box>
              <Button
                className="helikaButtonClass"
                onClick={() => {
                  setEventDataMappings((prevState: EventData_mapping[]) => {
                    return prevState.concat([
                      {
                        configName: '',
                        eventValue: '',
                        eventKey: '',
                        userIdKey: '',
                        eventKeys: [],
                        pmValue: '',
                        intValue: 0,
                      },
                    ])
                  })
                }}
                sx={{
                  width: '220px',
                }}
              >
                <img src={Add_Icon} alt="Add" />
                &nbsp;ADD CONDITION
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={createCampaignQuery}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={handleClose}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
