//imports
import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import { Box, useTheme } from '@mui/material';

//contexts

//services

//components
import { newColor } from "src/consts/colors";
import DontSaveQueryModal from "./popups/DontSaveQueryModal";
//---------------------------------------------------------------------------------

function VisualDesignerShowMenu({
  disabled,
  displayItem,
  datagridExpanded,
  selectedQuery,
  setDisplayItem,
  setDatagridExpanded
}: {
  disabled: boolean,
  displayItem: string,
  datagridExpanded: boolean,
  selectedQuery: any,
  setDisplayItem: any,
  setDatagridExpanded: any
}) {

  const theme = useTheme();
  const navigate = useNavigate()
  const [dontSaveVisualModalBoolean, setDontSaveVisualModalBoolean] = useState<boolean>(false)

  return (
    <Box
      style={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <DontSaveQueryModal
        open={dontSaveVisualModalBoolean}
        setOpen={setDontSaveVisualModalBoolean}
        openQueryHandler={() => {
          navigate('/analytics/explorer/queries', {
            state: { loadQuery: selectedQuery?.id },
          })
          setDontSaveVisualModalBoolean(false)
        }}
      />
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          margin: 'auto 0 auto 1em',
          padding: '0.1em 2em 0.1em 2em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: disabled ? 'dimGrey' : theme.palette.text.primary,
          marginLeft: '0.5em',
          fontWeight: '600',
        }}
        disabled={disabled}
        onClick={() => {
          setDatagridExpanded((prevState: boolean) => !prevState)
        }}
      >
        {datagridExpanded ? 'HIDE ' : 'SHOW '}WINDOW
      </Button>
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          margin: 'auto 0 auto 1em',
          padding: '0.1em 2em 0.1em 2em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: disabled ? 'dimGrey' : theme.palette.text.primary,
          marginLeft: '0.5em',
          fontWeight: '600',
        }}
        disabled={disabled}
        onClick={() => {
          setDatagridExpanded((prevState: boolean) => {
            if (!prevState) {
              return true
            }
            return prevState
          })
          setDisplayItem((prevState: string) => prevState === 'code' ? 'data' : 'code')
        }}
      >
        {`SHOW ${displayItem === 'code' ? 'DATA' : 'CODE'}`}
      </Button>
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          margin: 'auto 0 auto 1em',
          padding: '0.1em 2em 0.1em 2em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: disabled ? 'dimGrey' : theme.palette.text.primary,
          marginLeft: '0.5em',
          fontWeight: '600',
        }}
        disabled={disabled}
        onClick={() => {
          setDontSaveVisualModalBoolean(true)
        }}
      >
        EDIT QUERY
      </Button>
    </Box>
  );
}

export default VisualDesignerShowMenu;