import { Alert, Grid } from '@mui/material';

export const ErrorAlert = ({ error }: { error?: Error }) => {
  return error ? (
    <Grid item>
      <Alert severity="error" variant="outlined" sx={{ my: 2 }}>
        {error.message}
      </Alert>
    </Grid>
  ) : (
    <></>
  );
};
