import React from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { newColor } from 'src/consts/colors'

// Styled button component for the "Create New" action
const StyledButton = styled(Button)(({ theme }) => {
  const themeMode = theme.palette.mode
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '0px',
    width: 'auto',
    height: '36px',
    backgroundColor:
      themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    borderRadius: '4px',
    color: newColor.white,
    fontSize: '14px',
    lineHeight: '140%',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    },
  }
})

// Props interface for CreateHookButton
export type CreateHookButtonProps = {
  onClick?: () => void
}

// Button component for creating a new hook or item
const CreateHookButton: React.FC<CreateHookButtonProps> = ({ onClick }) => {
  return (
    <StyledButton variant="contained" startIcon={<AddIcon />} onClick={onClick}>
      <span
        style={{
          fontFamily: 'Open Sans, sans-serif',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '140%',
          color: newColor.white,
        }}
      >
        Create New
      </span>
    </StyledButton>
  )
}

export default CreateHookButton
