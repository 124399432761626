// use-code-mirror.ts
import { useEffect, useRef, useState } from "react";
import { EditorView, basicSetup } from "codemirror";
import { sql, PostgreSQL } from '@codemirror/lang-sql';
import { Compartment } from "@codemirror/state";
import { keymap } from "@uiw/react-codemirror";

const keyAction = new Compartment();

export default function useCodeMirror(extensions: any, runHandler?: any) {
  const ref: any = useRef();
  const [view, setView] = useState<any>();

  const newKeyMap = () => {
    return keymap.of([
      {
        key: 'Ctrl-Enter',
        run: (target: any) => {
          return true;
        }
      }
    ]);
  };

  useEffect(() => {
    const view = new EditorView({
      extensions: [
        basicSetup,
        /**
         * Check each language package to see what they support,
         * for instance javascript can use typescript and jsx.
         */
        sql({
          dialect: PostgreSQL,
        }),
        EditorView.lineWrapping,
        ...extensions,
        keyAction.of(newKeyMap())
      ],
      parent: ref.current,
    });

    setView(view);

    /**
     * Make sure to destroy the codemirror instance
     * when our components are unmounted.
     */
    return () => {
      view.destroy();
      setView(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ref, view, keyAction };
}
