//imports
import { useCallback, useContext, useEffect, useState } from 'react';
import { DataGridPro, GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';

//auth
import { Auth0Context } from 'src/contexts/Auth0Context';
import { closeDrawerWidth, drawerWidth } from 'src/consts/sizes'

//code mirror
import { StateEffect } from '@codemirror/state';
import { keymap } from "@uiw/react-codemirror";
import { coolGlow } from 'thememirror';
import { PostgreSQL, schemaCompletionSource, sql } from '@codemirror/lang-sql';
import { CodeEditor } from '../../../components/codeMirror/CodeEditor';
import { ReactComponent as RunIcon } from '../../../assets/run_icon.svg'
import { ReactComponent as NewQuery } from '../../../assets/start_new_query.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/explore_download_icon.svg'
import ExploreIcon from '../../../assets/explore_icon.svg'
import { Button } from '@mui/material';
import { StyledSvg } from '../../../components/organisms/v2/tabs/styled';
import { getOrgQueries, getSchemas, getUserQueries, runQuery, updateQuery } from '../../../utils/api/queries';
import { toast } from 'react-toastify';
import SaveQueryModal from '../../../components/popups/SaveQueryModal';
import { downloadCSV } from '../../../utils/excels';
import moment from 'moment';
import NewQueryModal from 'src/components/popups/NewQueryModal';
import SaveMenu from 'src/components/SaveMenu';
import DeleteQueryModal from 'src/components/popups/DeleteQueryModal';
import LoadQueryModal from 'src/components/popups/LoadQueryModal';
import { createHeadersFromColumnList } from 'src/utils/agGrid';
import LoadingComponent from 'src/components/LoadingComponent';
import { useGetDrawer } from 'src/utils/drawer';
import { cmLightTheme } from 'src/utils/api/cmLightTheme';
import Sidebar from 'src/components/Explore/Sidebar';
import { newColor } from 'src/consts/colors';
import { queryTimeout } from '../../../utils/number';
import { ReactComponent as Cancel } from 'src/assets/cancel.svg'
import { ExplorerHeader } from 'src/components/Explore/ExplorerHeader';
import { ReactComponent as OpenFolder } from 'src/assets/Folder_Open.svg'
import SavedQueriesModal from 'src/components/popups/SavedQueriesModal';

export default function Explore() {

  const theme: any = useTheme()
  const location: any = useLocation()
  const isDrawerOpen = useGetDrawer();

  const [controller, setController] = useState<any>(new AbortController());

  const {
    isAuthenticated,
    getWithAccessToken,
    postWithAccessToken,
    patchWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [code, setCode] = useState('SELECT * FROM ');
  const [userQueries, setUserQueries] = useState<any[]>([]);
  const [orgQueries, setOrgQueries] = useState<any[]>([]);
  const [fullQueryList, setFullQueryList] = useState<any[]>([]);
  const [schemas, setSchemas] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [queryName, setQueryName] = useState<string>('New Query');
  const [queryToLoad, setQueryToLoad] = useState<any>(undefined);
  const [isOpenSavedQueriesModal, setIsOpenSavedQueriesModal] = useState<boolean>(false);
  const [isOpenNewQueryModal, setIsOpenNewQueryModal] = useState<boolean>(false);
  const [isOpenSaveQueryModal, setIsOpenSaveQueryModal] = useState<boolean>(false);
  const [isOpenDeleteQueryModal, setIsOpenDeleteQueryModal] = useState<boolean>(false);
  const [isOpenLoadQueryModal, setIsOpenLoadQueryModal] = useState<boolean>(false);
  const [valueWidth, setValueWidth] = useState<number>(0);
  const [selectedQuery, setSelectedQuery] = useState<any>();
  const [lastSaved, setLastSaved] = useState<Date | null>();
  const [schemaTabState, setSchemaTabState] = useState<any>({});
  const [tableTabState, setTableTabState] = useState<any>({});
  const [exploreTabExpanded, setExploreTabExpanded] = useState<boolean>(false);
  const [isRunningQuery, setIsRunningQuery] = useState<boolean>(false);
  const [isLoadingQueriesAndSchemas, setIsLoadingQueriesAndSchemas] = useState<boolean>(false);

  let [dataHeaders, setDataHeaders] = useState<any[]>([]);
  let [displayData, setDisplayData] = useState<any>([]);

  function loadingComp(text: string) {
    return <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: 'auto',
        height: '100%',
        border: 'solid',
        borderRadius: '1em',
        borderColor: newColor.darkGunmetal,
        marginTop: '0.5em',
      }}
    >
      <div style={{ textAlign: 'center', paddingBottom: '1em' }}>{text}</div>
      <div><LoadingComponent /></div>

    </div>
  }

  //get default data
  useEffect(() => {

    if (isLoading || !isAuthenticated) return;

    //set queries and schemas in tab view
    updateQueryListAndSchemaList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    if (
      !_.isNil(location?.state?.loadQuery) &&
      Number(location.state.loadQuery) &&
      (!_.isEmpty(userQueries) || !_.isEmpty(orgQueries))
    ) {
      let allQueries: any = []
      allQueries = allQueries.concat(userQueries)?.concat(orgQueries)
      let foundQuery = allQueries.find((query: any) => query.id === Number(location.state.loadQuery))
      if (foundQuery) {
        setSelectedQuery(foundQuery);
        window.history.replaceState({}, document.title)
      }
    }
  }, [location.state, orgQueries, userQueries])

  useEffect(() => {
    if (selectedQuery?.id) {
      setCode(selectedQuery.query)
      setLastSaved(selectedQuery.updated_at)
      setQueryName(selectedQuery.name)
    } else {
      setCode('SELECT * FROM ')
      setLastSaved(undefined)
      setQueryName('New Query')
    }
  }, [selectedQuery])

  async function loadQueryHandler() {
    setSelectedQuery(queryToLoad)
    setQueryToLoad(undefined)
    setIsOpenLoadQueryModal(false)
  }

  const runHandler = useCallback(async (code: any) => {
    async function errorHandler(e: any) {
      if ('detail' in e) {
        toast.error('Error running query. See results for details.')
        setDisplayData([{ error: e.detail, helika_row_id: '1' }])
        setDataHeaders([
          {
            headerName: 'Error',
            field: 'error',
            wrapText: true,
            autoHeight: true,
            minWidth: 100,
            sortable: false,
            flex: 1,
            headerClassName: 'error-datagrid-header',
          }
        ])
      } else {
        toast.error(e?.message)
      }
      setIsRunningQuery(false)
    }
    try {
      setIsRunningQuery(true)
      let timer = setTimeout(() => controller.abort(), queryTimeout)
      let resp: any = await runQuery(postWithAccessToken, { sql_query: code }, errorHandler, controller?.signal)
      clearTimeout(timer)
      if (_.isEmpty(resp)) return
      let allData = [...resp.data];
      let columns = [...(Object.keys(allData[0]))]
      let headers = createHeadersFromColumnList(columns)
      let rowLabeledData = allData.map((row: any, index: number) => {
        let newProp: any = {};
        newProp[`helika_row_id`] = index;
        return Object.assign({}, row, newProp)
      })
      setDisplayData(rowLabeledData)
      setDataHeaders(headers)
      setIsRunningQuery(false)
    } catch (e: any) {
      toast.error(e?.message)
    }
  }, [controller, postWithAccessToken])

  const cancelQuery = useCallback(async () => {
    const reason = new DOMException('Query was cancelled', '');
    controller.abort(reason);
    setDisplayData([])
    setDataHeaders([])
    setIsRunningQuery(false)
    setController(new AbortController())
  }, [controller])

  async function downloadHandler() {
    if (_.isEmpty(displayData)) {
      toast.error('No data to download', {
        toastId: 'nothing_to_download'
      });
      return;
    }
    downloadCSV(displayData, queryName.concat('.csv'), true);
  }

  async function newQueryHandler() {
    setCode('SELECT * FROM');
    setIsOpenNewQueryModal(false);
    setSelectedQuery(undefined);
    setLastSaved(undefined);
    setQueryName('New Query')
    setDataHeaders([])
    setDisplayData([])
  }

  async function updateQueryHandler(callback?: any) {
    if (_.isUndefined(selectedQuery)) {
      toast.error('Could not find query to save')
      return
    } else if (_.isEmpty(code?.trim()) || _.isEmpty(queryName?.trim())) {
      toast.error('Query name and code must not be empty')
      return
    }
    await updateQuery(patchWithAccessToken, { name: queryName, query: code, id: selectedQuery?.id }).then(async resp => {
      setLastSaved(resp.results.updated_at)
      toast.success('Query was saved')
      try {
        setUserQueries(prevState => {
          if (!prevState) return resp.results;
          return prevState.map(query => {
            if (query.id === resp.results.id) {
              return resp.results;
            }
            return query
          })
        })
        setOrgQueries(prevState => {
          if (!prevState) return resp.results;
          return prevState.map(query => {
            if (query.id === resp.results.id) {
              return resp.results.id;
            }
            return query
          })
        })
        setFullQueryList(prevState => {
          if (!prevState) return resp.results;
          return prevState.map(query => {
            if (query.id === resp.results.id) {
              return resp.results.id;
            }
            return query
          })
        })
        setSelectedQuery((prevState: any) => {
          let newState = JSON.parse(JSON.stringify(prevState))
          newState.query = code
          return newState
        })
        if (typeof callback === 'function') {
          callback()
        }
      } catch (e) {
        await updateQueryListAndSchemaList(resp.results.id).catch((e: any) => {
          toast.error('Could not reload Query List, please refresh')
          return
        })
        if (typeof callback === 'function') {
          callback()
        }
      }
    }).catch(e => {
      toast.error(e?.message)
    })
  }

  let extensions: any = [
    sql({
      dialect: PostgreSQL,
    }),
    theme.palette.mode === 'dark' ? coolGlow : cmLightTheme
  ]
  let { CodeMirrorInstance, EditorView, keyAction } = CodeEditor({
    value: code,
    onChange: (newCode: any) => {
      setCode(newCode);
    },
    extensions: extensions
  })

  //update keymapping
  useEffect(() => {
    if (!EditorView) return
    const newKeyMap = () => {
      return keymap.of([
        {
          key: 'Ctrl-Enter',
          run: (target: any) => {
            runHandler(target.contentDOM.innerText)
            return true;
          }
        }
      ]);
    };
    EditorView.dispatch({
      effects: keyAction.reconfigure(newKeyMap())
    })
  }, [EditorView, code, keyAction, runHandler]);

  function formatSchemas(schemas: any) {
    if (!schemas || _.isEmpty(schemas)) {
      toast.error('No available schemas found');
      return [];
    }
    return schemas.map((schema: any) => {
      let tables = schema.tables?.map((table: any) => {
        return Object.assign({}, table, { schema: schema.schema_name })
      })
      return { name: schema.schema_name, tables: tables }
    })
  }

  const updateQueryListAndSchemaList = useCallback(async (id?: any) => {

    setIsLoadingQueriesAndSchemas(true)

    try {
      let respUserQueries: any = await getUserQueries(getWithAccessToken)
      let respOrgQueries: any = await getOrgQueries(getWithAccessToken)
      let respSchemas: any = await getSchemas(getWithAccessToken)
      respSchemas = formatSchemas(respSchemas.schemas)

      //set schema tab state
      setSchemaTabState({});
      setTableTabState({});

      setUserQueries(respUserQueries);
      setOrgQueries(respOrgQueries);
      setFullQueryList([].concat(...respUserQueries).concat(...respOrgQueries));
      setSchemas(respSchemas)

      let prevSelectedQuery = [...respUserQueries].concat([...respOrgQueries]).find(query => query.id === id)
      if (!_.isUndefined(prevSelectedQuery)) {
        setSelectedQuery(prevSelectedQuery)
      } else {
        setSelectedQuery(undefined)
      }
      setIsLoadingQueriesAndSchemas(false)
    } catch (e: any) {
      setIsLoadingQueriesAndSchemas(false)
    }
  }, [getWithAccessToken])

  const insertAtCursor = useCallback(async (val: string) => {
    const state = EditorView?.state;
    const range = state?.selection.ranges[0];
    if (range) {

      //insert val at position
      EditorView?.dispatch({
        changes: { from: range?.from, to: range?.to, insert: `${val}` }
      })

      //refocus
      EditorView.focus()
      const newPosition = range.from + val.length;
      EditorView.dispatch({
        selection: {
          anchor: newPosition,
          head: newPosition,
        },
      });
    }
  }, [EditorView]);

  //add to autocomplete when new schemas come in
  useEffect(() => {

    function createSchemaCompletionSource(schemas: any) {
      let tempSchemas: any = {}
      schemas?.forEach((schema: any) => {
        schema.tables.forEach((table: any) => {
          tempSchemas[`${schema.name}.${table.table_name}`] = table.columns.map((column: any) => column.column_name)
        })
      })
      return tempSchemas
    }

    let newSchema = createSchemaCompletionSource(schemas)
    EditorView?.dispatch({
      effects: StateEffect.appendConfig.of([
        PostgreSQL.language.data.of({
          autocomplete: schemaCompletionSource({
            schema: newSchema
          })
        }),
      ])
    })
  }, [EditorView, schemas])

  const clickSchemaHandler = useCallback(async (schema: any, event: any) => {

    switch (event.detail) {
      case 1:
        setSchemaTabState((prevState: any) => {
          let newProp: any = {}
          newProp[`${schema?.name}`] = !prevState[`${schema?.name}`]
          return Object.assign({}, prevState, newProp);
        })
        break
      default:
      case 2:
        setSchemaTabState((prevState: any) => {
          let newProp: any = {}
          newProp[`${schema?.name}`] = !prevState[`${schema?.name}`]
          return Object.assign({}, prevState, newProp);
        })
        insertAtCursor(`${schema.name}.`)
        break
    }
  }, [insertAtCursor])


  const clickTableHandler = useCallback(async (table: any, event: any) => {

    switch (event.detail) {
      case 1:
        setTableTabState((prevState: any) => {
          let newProp: any = {}
          newProp[`${table.table_name}`] = !prevState[`${table.table_name}`]
          return Object.assign({}, prevState, newProp);
        })
        break
      default:
      case 2:
        setTableTabState((prevState: any) => {
          let newProp: any = {}
          newProp[`${table.table_name}`] = !prevState[`${table.table_name}`]
          return Object.assign({}, prevState, newProp);
        })
        insertAtCursor(`${table.table_name} `)
        break
    }
  }, [insertAtCursor])

  return (
    <div
      id='explore-box'
      style={{
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
        height: '100%',
        maxHeight: '100%',
        scrollbarGutter: 'stable both-sides',
        minWidth: '70em'
      }}
    >
      <DeleteQueryModal
        selectedQuery={selectedQuery}
        open={isOpenDeleteQueryModal}
        setOpen={setIsOpenDeleteQueryModal}
        updateQueryListAndSchemaList={updateQueryListAndSchemaList}
      />
      <LoadQueryModal
        loadQueryHandler={loadQueryHandler}
        open={isOpenLoadQueryModal}
        setOpen={setIsOpenLoadQueryModal}
        queryToLoad={queryToLoad}
      />
      <NewQueryModal
        newQueryHandler={newQueryHandler}
        open={isOpenNewQueryModal}
        setOpen={setIsOpenNewQueryModal}
        updateQueryHandler={updateQueryHandler}
      />
      <SavedQueriesModal
        newQueryHandler={newQueryHandler}
        open={isOpenSavedQueriesModal}
        setOpen={setIsOpenSavedQueriesModal}
        updateQueryHandler={updateQueryHandler}
        userQueries={userQueries}
        orgQueries={orgQueries}
        setSelectedQuery={setSelectedQuery}
      />
      <SaveQueryModal
        queryName={queryName}
        setQueryName={setQueryName}
        queryCode={code}
        open={isOpenSaveQueryModal}
        setOpen={setIsOpenSaveQueryModal}
        setLastSaved={setLastSaved}
        updateQueryListAndSchemaList={updateQueryListAndSchemaList}
      />
      <ExplorerHeader />
      <div
        style={{
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: 'calc(100% - 4.5em)',
          paddingTop: '0em',
        }}
      >
        <Sidebar
          title={'Explorer'}
          expanded={exploreTabExpanded}
          setExpanded={setExploreTabExpanded}
          icon={ExploreIcon}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isLoading={isLoadingQueriesAndSchemas}
          insertAtCursor={insertAtCursor}
          clickSchemaHandler={clickSchemaHandler}
          clickTableHandler={clickTableHandler}
          schemas={schemas}
          schemaTabState={schemaTabState}
          tableTabState={tableTabState}
        />
        {/* explorer */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '0.5em',
            overflow: 'auto',
            background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
            flex: 1,
            maxHeight: 'calc(100% - 0.5em)',
          }}
        >
          <div
            id='header-box'
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: '100%',
              maxWidth: '100%',
              padding: '1em 0.5em 0.5em 0.5em',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '-0.8em'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: '1',
                maxWidth: '100%',
                fontSize: '1.2em',
                marginTop: '0.5em'
              }}
            >
              <div
                style={{
                  color: newColor.jazzberryJam,
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0.5em',
                  lineHeight: 1,
                  width: '8em',
                  textAlign: 'left'
                }}
              >
                Query Explorer
              </div>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0',
                  lineHeight: 1,
                  textAlign: 'left',
                  fontFamily: 'open sans'
                }}
              >
                {`>`}
              </div>
              <div
                id='outer-input-div'
                style={{
                  display: 'flex',
                  color: 'lightgray',
                  flex: 1,
                  textAlign: 'left',
                  width: 'fit-content',
                  maxWidth: 'calc(100% - 9em)',
                  paddingLeft: '0.5em',
                  margin: 0
                }}
              >
                <input
                  style={{
                    background: newColor.transparent,
                    outline: 'none',
                    boxShadow: 'none',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                    marginTop: '-0.1em',
                    width: valueWidth + 'ch',
                    minWidth: '8.7em',
                    lineHeight: 1,
                    fontSize: '0.9em',
                    marginRight: '0.5em',
                    borderRadius: '0.1em',
                    backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : 'white',
                    border: '1px solid ',
                    borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
                    color: theme.palette.text.primary,
                    padding: '0 1em 0 1em',
                  }}
                  type='text'
                  placeholder={'Enter Query Name here...'}
                  value={queryName}
                  onChange={(e) => {
                    setQueryName(e.target.value);
                    setValueWidth(e.target.value?.length);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur()
                    }
                  }}
                />
              </div>
            </div>
            <div
              id='top-buttons'
              style={{
                display: 'flex',
                marginTop: '0.5em',
                flexWrap: 'nowrap'
              }}
            >
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.3em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: _.isEmpty(fullQueryList) ? newColor.darkGunmetal : theme.palette.text.primary,
                  fontSize: '0.8em',
                  fontWeight: '600',
                  cursor: _.isEmpty(fullQueryList) ? 'not-allowed' : 'pointer',
                  marginRight: '0.5em'
                }}
                onClick={() => {
                  if (_.isEmpty(fullQueryList)) return
                  setIsOpenSavedQueriesModal(true)
                }}
              >
                <StyledSvg
                  sx={{
                    '& svg, & path': {
                      stroke: _.isEmpty(fullQueryList) ? newColor.darkGunmetal : theme.palette.text.primary,
                      fill: _.isEmpty(fullQueryList) ? newColor.darkGunmetal : theme.palette.text.primary,
                    },
                  }}
                >
                  <OpenFolder />
                </StyledSvg>
                <div
                  style={{
                    margin: '0.1em 0 0 0.5em'
                  }}
                  className='truncate-ellipsis'
                >
                  SAVED QUERIES
                </div>
              </Button>
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.3em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: !selectedQuery?.id ? newColor.darkGunmetal : theme.palette.text.primary,
                  fontSize: '0.8em',
                  fontWeight: '600',
                  cursor: selectedQuery?.id ? 'pointer' : 'not-allowed'
                }}
                onClick={() => {
                  if (!selectedQuery?.id) return
                  setIsOpenNewQueryModal(true)
                }}
              >
                <StyledSvg
                  sx={{
                    '& svg, & path': {
                      stroke: !selectedQuery?.id ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                    },
                  }}
                >
                  <NewQuery />
                </StyledSvg>
                <div
                  style={{
                    margin: '0.1em 0 0 0.5em'
                  }}
                  className='truncate-ellipsis'
                >
                  START NEW QUERY
                </div>
              </Button>
              <SaveMenu
                selectedQuery={selectedQuery}
                showSaveQueryModal={setIsOpenSaveQueryModal}
                updateQueryHandler={updateQueryHandler}
                showDeleteQueryModal={setIsOpenDeleteQueryModal}
              />
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '0.9em',
                margin: `0.5em 0 -0.5em 0.7em`,
                color: 'gray',
                height: 'fit-content'
              }}
            >
              {`${selectedQuery
                ? `${selectedQuery.query?.trim() === code?.trim() ? 'Saved' : 'Draft'}`
                : ''
                } ${(selectedQuery && lastSaved) ? `, Last Saved: ${moment(lastSaved).format('YYYY-MM-DD hh:mm:ss A')} UTC` : ''}`}
            </div>
          </div>
          <div
            id={`explorediv`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              height: '100%',
              padding: '0 0.5em 0 0.5em',
              maxHeight: 'calc(100% - 3.2em)'
            }}
            className={`explorediv-${theme.palette.mode} ${exploreTabExpanded ? 'isExploreTabExpanded' : ''}`}
          >
            {
              CodeMirrorInstance
            }
            <div
              style={{
                margin: '0.3em 0 0 0',
                minHeight: '2em',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'end'
              }}
            >
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.75em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: _.isEmpty(displayData) ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.oceanBoatBlue : theme.palette.text.primary),
                  cursor: _.isEmpty(displayData) ? 'not-allowed' : 'pointer',
                  marginLeft: '0.5em',
                  fontSize: '0.8em',
                  fontWeight: '600',
                }}
                onClick={downloadHandler}
              >
                <div
                  style={{
                    height: '0.3em',
                    margin: '0 0.5em 1em 0'
                  }}
                >
                  <StyledSvg
                    sx={{
                      '& svg, & path': {
                        fill: _.isEmpty(displayData) ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                      },
                    }}
                  >
                    <DownloadIcon />
                  </StyledSvg>
                </div>
                <div>
                  DOWNLOAD
                </div>
              </Button>
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderRadius: '100vmax',
                  padding: '0.2em 2em 0.2em 2em',
                  background:
                    isRunningQuery
                      ? newColor.darkGray
                      : theme.palette.mode === 'dark' ? `linear-gradient(to right, ${newColor.caribbeanGreen}, ${newColor.richElectricBlue})` : theme.palette.background.default,
                  cursor:
                    isRunningQuery
                      ? 'not-allowed'
                      : 'pointer',
                  borderColor: newColor.darkGunmetal,
                  color: isRunningQuery ? 'black' : (theme.palette.mode === 'dark' ? newColor.oceanBoatBlue : theme.palette.text.primary),
                  margin: '0 0.5em 0 0.5em',
                  fontSize: '0.8em',
                  fontWeight: '600',

                }}
                disabled={isRunningQuery}
                onClick={() => runHandler(code)}
              >
                <StyledSvg>
                  <RunIcon />
                </StyledSvg>
                <div style={{ margin: '0.1em 0 0 0.5em' }}>RUN</div>
              </Button>
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderRadius: '100vmax',
                  padding: '0.2em 1em 0.2em 1em',
                  background: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  cursor:
                    !isRunningQuery
                      ? 'not-allowed'
                      : 'pointer',
                  borderColor: newColor.darkGunmetal,
                  color: !isRunningQuery ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.oceanBoatBlue : theme.palette.text.primary),
                  margin: '0 0.5em 0 0',
                  fontSize: '0.8em',
                  fontWeight: '600',

                }}
                disabled={!isRunningQuery}
                onClick={() => cancelQuery()}
              >
                <StyledSvg>
                  <Cancel />
                </StyledSvg>
                <div style={{ margin: '0.1em 0 0 0.5em' }}>CANCEL</div>
              </Button>
            </div>
            <div
              id='data_outer'
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                minWidth: `calc(100% - ${isDrawerOpen ? drawerWidth : closeDrawerWidth} + 4em)`,
                maxHeight: `calc(100% - ${selectedQuery && lastSaved ? 25.5 : 24.2}em)`,
                maxWidth: isDrawerOpen ? `calc(100vw - 16em - ${drawerWidth}${exploreTabExpanded ? ' - 15em' : ''}` : `calc(100vw - 16em - ${closeDrawerWidth}${exploreTabExpanded ? ' - 15em' : ''}`,
              }}
            >
              {
                isRunningQuery
                  ?
                  loadingComp('Running Query...')
                  :
                  <DataGridPro
                    disableColumnMenu
                    rows={displayData}
                    columns={dataHeaders}
                    pagination //todo: add pagination to all datagrid
                    getRowId={(row: any) => {
                      return row.helika_row_id
                    }}
                    pinnedColumns={{ left: ['helika_row_id'] }}
                    autosizeOnMount={true}
                    getRowHeight={() => 'auto'}
                    sx={{
                      background: theme.palette.mode === 'dark' ? 'black' : 'white',
                      '& .MuiDataGrid-columnHeaders': {
                        display: _.isEmpty(dataHeaders) ? 'none' : 'inline'
                      },
                      marginTop: '0.5em',
                      padding: '0',
                      minHeight: '10em',
                      border: 'solid',
                      borderRadius: '1em',
                      borderColor: newColor.darkGunmetal,
                    }}
                    style={{
                      padding: 0,
                      height: '1em !important'
                    }}
                    slots={{
                      footer:
                        () =>
                          <GridFooterContainer
                            sx={{
                              justifyContent: 'space-between'
                            }}
                          >
                            {
                              displayData?.length >= 2000
                                ?
                                <div
                                  style={{
                                    margin: 'auto 1em auto 0',
                                    paddingLeft: '0.2em',
                                    color: theme.palette.mode === 'dark' ? 'yellow' : 'red',
                                    minWidth: '2em'
                                  }}
                                  className='truncate-ellipsis'
                                >
                                  * Results truncated to 2000 rows
                                </div>
                                : <div />
                            }
                            <GridFooter sx={{
                              justifySelf: 'end',
                              border: 'none', // To delete double border.
                            }} />
                          </GridFooterContainer>
                    }}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};