//imports
import React, { useRef, useState } from 'react'

//components
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  useTheme,
  styled as muiStyled,
  SelectChangeEvent,
  Select,
  MenuItem,
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { newColor } from '../../consts/colors'

//assets
import help_icon_white from '../../assets/help_icon_white.svg'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { useSelector } from 'react-redux'
import {
  SUPPORT_PRIORITY,
  SUPPORT_PRIORITY_COLORS,
  SUPPORT_TYPE,
} from '../../consts/backend'
import { ColorDotIcon } from '../svgCustom/customIcon'
import { validateEmail } from '../../utils/string'
import _ from 'lodash'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
}

export default function ContactSupportModal(props: SimpleDialogProps) {
  const { open, setOpen } = props
  const theme: any = useTheme()
  const [supportType, setSupportType] = useState<string>('')
  const [supportPriority, setSupportPriority] = useState<string>('')
  const [supportDescription, setSupportDescription] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [supportTitle, setSupportTitle] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const userInfo = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const emailRef = useRef<HTMLInputElement>(null)
  const titleRef = useRef<HTMLInputElement>(null)
  const descRef = useRef<HTMLDivElement>(null)
  const typeRef = useRef<HTMLInputElement>(null)
  const priorityRef = useRef<HTMLInputElement>(null)

  const handleChangeType = (event: SelectChangeEvent) => {
    setSupportType(event.target.value)
  }

  const handleChangePriority = (event: SelectChangeEvent) => {
    setSupportPriority(event.target.value)
  }

  const handleResetForm = () => {
    setSupportDescription('')
    setSupportPriority('')
    setSupportType('')
    setEmail('')
    setSupportTitle('')
  }

  const validateValues = () => {
    if (typeRef.current && supportType === '') {
      typeRef.current.focus()
      toast.error('Support Type is required')
      return true
    } else if (priorityRef.current && supportPriority === '') {
      priorityRef.current.focus()
      toast.error('Support Priority is required')
      return true
    } else if (emailRef.current && email !== '' && !validateEmail(email)) {
      emailRef.current.focus()
      toast.error('Please input valid email.')
      return true
    } else if (titleRef.current && supportTitle === '') {
      titleRef.current.focus()
      toast.error('Title is required.')
      return true
    } else if (descRef.current && supportDescription === '') {
      descRef.current.focus()
      toast.error('Description is required.')
      return true
    } else return false
  }
  async function handleSubmitClick() {
    if (validateValues() || _.isEmpty(sdk)) return
    try {
      setIsSubmiting(true)
      await sdk.createUserEvent([
        {
          created_at: new Date().toISOString(),
          game_id: 'portal-v2',
          event_type: 'support',
          event: {
            cs_version: '2.0',
            event_sub_type: 'cs_ticket',
            session_id: sdk.sessionID,
            player_id: userInfo.id,
            device_details: {
              platform_id: '',
              app_version: '',
            },
            user_details: {
              wallet_id: '',
              name: userInfo.name,
              email: userInfo.email ? userInfo.email : email,
            },
            event_details: {
              event_label: supportType,
              subject: supportTitle,
              text: supportDescription,
              custom: {
                url: window.location.href,
                priority: supportPriority,
              },
            },
          },
        },
      ])
      setIsSubmiting(false)
      toast.success(`Support sent successfully.`)
      handleResetForm()
      setOpen(false)
    } catch (e) {
      console.log(e)
      toast.error('Could not send support event')
    }
  }

  const handleClose = () => {
    setIsSubmiting(false)
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '1em 2em 0 2em',
        }}
      >
        <DialogTitle
          className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            src={help_icon_white}
            alt=" Config Icon"
            style={{ margin: 'auto 0.3em auto 0' }}
          />{' '}
          <div style={{ lineHeight: '80%', margin: '0.4em 0 0.3em 0' }}>
            CONTACT SUPPORT
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '2em 2em 2em 2em',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
      >
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              TYPE:
            </div>
          </Grid>
          <Grid item xs={9}>
            <Select
              inputRef={typeRef}
              value={supportType ? supportType : ''}
              onChange={handleChangeType}
              sx={{
                borderRadius: '999px',
                backgroundColor:
                  theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
                fontSize: '14px',
                width: '100%',
                paddingInline: '14px',
                border:
                  theme.palette.mode === 'dark' ? '1px solid #373737' : 'none',
                '& fieldset': { border: 'none' },
              }}
              size={'small'}
              placeholder="Select priority"
              MenuProps={{
                MenuListProps: {
                  sx: {
                    bgcolor:
                      theme.palette.mode === 'dark'
                        ? newColor.tertiary
                        : '#B6B7B9',
                    '& .MuiMenuItem-root & .Mui-selected': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                    '& .Mui-selected': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                    '& :hover': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                  },
                },
              }}
            >
              {Object.entries(SUPPORT_TYPE).map(([key, value]) => (
                <MenuItem
                  key={key}
                  sx={{ width: '100%', color: 'text-white' }}
                  value={key}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              PRIORITY:
            </div>
          </Grid>
          <Grid item xs={9}>
            <Select
              inputRef={priorityRef}
              value={supportPriority ? supportPriority : ''}
              onChange={handleChangePriority}
              sx={{
                borderRadius: '999px',
                backgroundColor:
                  theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
                fontSize: '14px',
                width: '100%',
                paddingInline: '14px',
                border:
                  theme.palette.mode === 'dark' ? '1px solid #373737' : 'none',
                '& fieldset': { border: 'none' },
              }}
              size={'small'}
              placeholder="Select priority"
              MenuProps={{
                MenuListProps: {
                  sx: {
                    bgcolor:
                      theme.palette.mode === 'dark'
                        ? newColor.tertiary
                        : '#B6B7B9',
                    '& .MuiMenuItem-root & .Mui-selected': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                    '& .Mui-selected': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                    '& :hover': {
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? newColor.tertiary
                          : '#B6B7B9',
                    },
                  },
                },
              }}
            >
              {Object.entries(SUPPORT_PRIORITY).map(([key, value]) => (
                <MenuItem
                  key={key}
                  sx={{ width: '100%', color: 'text-white' }}
                  value={key}
                >
                  <Box
                    display="flex"
                    justifyContent="start"
                    gap="10px"
                    alignItems="center"
                  >
                    <ColorDotIcon
                      color={
                        SUPPORT_PRIORITY_COLORS[
                        key as keyof typeof SUPPORT_PRIORITY_COLORS
                        ]
                      }
                    />
                    {value}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Email:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              ref={emailRef}
              value={userInfo.email ? userInfo.email : email}
              onChange={(e) => {
                setEmail(e.currentTarget.value)
              }}
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFiledClassRounded'
                  : 'inputFiledClassRoundedLight'
              }
              size={'small'}
              placeholder="Enter Email"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Title:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              ref={titleRef}
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFiledClassRounded'
                  : 'inputFiledClassRoundedLight'
              }
              value={supportTitle}
              onChange={(e) => {
                setSupportTitle(e.currentTarget.value)
              }}
              size={'small'}
              placeholder="Enter Title"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DESCRIPTION:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              ref={descRef}
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFiledClassRounded'
                  : 'inputFiledClassRoundedLight'
              }
              multiline
              rows={5}
              value={supportDescription}
              onChange={(e) => {
                setSupportDescription(e.currentTarget.value)
              }}
              placeholder="Write Description"
            />
          </Grid>
        </Grid>
      </Box>
      {isSubmiting ? (
        <div className="w-full" style={{ padding: '0 2em 2em 2em' }}>
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={isSubmiting}
            onClick={handleSubmitClick}
            className="helikaButtonClass"
          >
            SUBMIT
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
