import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { KeyedObject, useRequests } from './index'

export const useList = ({
  queryKey,
  resource,
  message = '',
  meta,
  version = 'v1',
  autoFetchAllow = true,
  showNotification = false,
}: {
  queryKey: string
  resource: string
  message?: string
  meta: KeyedObject
  version?: string
  autoFetchAllow?: boolean
  showNotification?: boolean
}) => {
  const { getList } = useRequests()

  const query = useQuery({
    queryKey: [queryKey, { resource, version, meta }],
    queryFn: () => getList({ resource, version, meta }),
    enabled: autoFetchAllow,
  })

  useEffect(() => {
    if (!showNotification) return
    if (query.isSuccess) {
      toast.success(`Successfully fetched ${message}`)
    }
    if (query.isError && query.error instanceof Error) {
      toast.error(`${query.error.message}`)
    }
  }, [
    query.isSuccess,
    query.isError,
    query.error,
    resource,
    message,
    showNotification,
  ])

  return { ...query }
}

export const useOne = ({
  queryKey,
  resource,
  message = '',
  id,
  version = 'v1',
  retry = 0,
  autoFetchAllow = true,
  showNotification = false,
}: {
  queryKey: string
  resource: string
  message?: string
  id?: string | number
  version?: string
  retry?: number
  autoFetchAllow?: boolean
  showNotification?: boolean
}) => {
  const { getOne } = useRequests()

  const query = useQuery({
    queryKey: [queryKey, { resource, version, id }],
    queryFn: () => getOne({ resource, version, id }),
    enabled: autoFetchAllow,
    retry: retry,
  })

  useEffect(() => {
    if (!showNotification) return
    if (query.isSuccess) {
      toast.success(`Successfully fetched ${message}`)
    }
    if (query.isError && query.error instanceof Error) {
      toast.error(`${query.error.message}`)
    }
  }, [
    query.isSuccess,
    query.isError,
    query.error,
    resource,
    message,
    showNotification,
  ])

  return { ...query }
}
