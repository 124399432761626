//imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as ReactTooltip from 'react-tooltip';
import React, { useContext, useState } from "react";
import { useTheme } from '@mui/material';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { ReactComponent as VerticalEllipsis } from 'src/assets/vertical_ellipsis_icon.svg'
import { newColor } from 'src/consts/colors';
import { toast } from 'react-toastify';
import { archiveEmailMarketingCampaign, unarchiveEmailMarketingCampaign } from 'src/utils/api/queries';
import { Auth0Context } from 'src/contexts/Auth0Context';

//contexts

//services

//components

//assets
//---------------------------------------------------------------------------------

function EmailMarketingCampaignOptionsMenu({
    setShowEditModal,
    listSelectedCampaign,
    campaign,
    setCampaigns,
    setMenuSelectedCampaign,
    isLoading = false,
    setSelectedCampaign,
    sort,
    order
}: {
    setShowEditModal: any,
    listSelectedCampaign: any,
    campaign: any,
    setCampaigns: any,
    setMenuSelectedCampaign: any,
    isLoading: boolean,
    setSelectedCampaign: any,
    sort: any,
    order: any
}) {

    const theme: any = useTheme();
    const { postWithAccessToken } = useContext(Auth0Context)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState<boolean>(false)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function changeArchiveState() {
        try {
            setLoading(true)

            const oldCampState = campaign.state
            let func = oldCampState === 'active' ? archiveEmailMarketingCampaign : unarchiveEmailMarketingCampaign

            const results = await func(
                postWithAccessToken,
                campaign.id
            )
            if (!results.results) {
                throw new Error('Could not edit Email Marketing Campaign')
            }
            setCampaigns((prevState: any) => {
                if (!prevState) return []
                let newList = prevState.map((campaignItem: any) => {
                    if (campaignItem.id !== campaign.id) return campaignItem
                    let newState = campaignItem.state
                    if (campaignItem.state === 'active') newState = 'archived'
                    if (campaignItem.state === 'archived') newState = 'active'
                    return Object.assign({}, campaignItem, { state: newState })
                })
                if (listSelectedCampaign?.id === campaign?.id) {
                    setSelectedCampaign(newList?.filter((item: any) => item.state === oldCampState).sort((a: any, b: any) => {
                        switch (sort) {
                            case 'Title':
                                if (order === 'A - Z') {
                                    return a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
                                } else if (order === 'Z - A') {
                                    return a.title?.toLowerCase() > b.title?.toLowerCase() ? -1 : 1
                                }
                                return a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
                            case 'Date Created':
                                if (order === 'Newest First') {
                                    return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                                } else if (order === 'Oldest First') {
                                    return new Date(a.created_at) >= new Date(b.created_at) ? -1 : 1
                                }
                                return new Date(a.created_at) >= new Date(b.created_at) ? 1 : -1
                            case 'Date Modified':
                            default:
                                if (order === 'Newest First') {
                                    return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                                } else if (order === 'Oldest First') {
                                    return new Date(a.updated_at) >= new Date(b.updated_at) ? -1 : 1
                                }
                                return new Date(a.updated_at) >= new Date(b.updated_at) ? 1 : -1
                        }
                    })[0])
                }
                return newList
            })
            setLoading(false)
            handleClose()
        } catch (e: any) {
            console.error(e)
            toast.error(e?.message)
            setLoading(false)
        }
    }

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
                setMenuSelectedCampaign(campaign)
            }}
            className="flex flex-col"
            style={{
                width: '2em'
            }}
        >
            <ReactTooltip.Tooltip />
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    marginTop: '-0.7em',
                    width: '2em !important',
                    minWidth: 'fit-content !important',
                    paddingX: '0'
                }}
                disabled={isLoading}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                    }}
                >
                    <VerticalEllipsis
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </StyledSvg>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock={true}
                sx={{
                    "& .MuiList-root":
                        { backgroundColor: theme.palette.background.default, padding: 0 },
                    "& .MuiMenu-paper":
                        { backgroundColor: theme.palette.background.default, border: theme.palette.mode === 'dark' ? 'inset' : '', borderColor: newColor.gray, marginTop: '1em' },
                    "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                        borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#676767',
                    }
                }}
            >
                <MenuItem
                    onClick={() => {
                        setShowEditModal(true)
                        handleClose()
                    }}
                    disabled={loading}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={changeArchiveState}
                    disabled={loading}
                >
                    {
                        campaign.state === 'active' ? 'Archive' : 'Unarchive'
                    }
                </MenuItem>
            </Menu>
        </div>
    )
}

export default EmailMarketingCampaignOptionsMenu