import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { useContext, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { toast } from 'react-toastify'
import { useTheme } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { editConfig } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import editIcon from '../../assets/editIcon.svg'
import { newColor } from '../../consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  config?: any
  columnDefs: any[]
  rowData: any[] | undefined
  onClose: (value: string) => void
  loadedS3Objects: any
  setLoadedS3Objects: any
  draftDiff: any
}

export default function EditConfig(props: SimpleDialogProps) {
  const { open, setOpen, config } = props
  const theme = useTheme()

  const { patchWithAccessToken } = useContext(Auth0Context)
  const [newTag, setNewTag] = useState<string>('')
  const [tagList, setTagList] = useState<string[]>([])
  const [editInProgress, setEditInProgress] = useState<boolean>(false)

  useEffect(() => {
    setTagList(config?.tags ? config?.tags : [])
  }, [config])

  const handleClose = () => {
    setOpen(false)
    setTagList(config?.tags ? config?.tags : [])
  }

  async function removeFromTagList(index: number) {
    setTagList((prevState) => {
      if (!prevState) return prevState
      let newState = [...prevState]
      newState.splice(index, 1)
      return newState
    })
  }

  const edit = async (config: any, tagList: string[] | undefined) => {
    setEditInProgress(true)
    let newConfig = Object.assign({}, config)
    newConfig.tags = tagList
    let isConfigApplied = await editConfig(patchWithAccessToken, newConfig)
    if (isConfigApplied) {
      toast.success('Config was edited successfully! Page will refresh.')
      setTimeout(() => {
        window.location.reload()
      }, 3000)
      return
    } else {
      toast.error('Config could not be edited')
    }
    setEditInProgress(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{ justifyContent: 'center', margin: '1em' }}
      >
        <DialogTitle
          className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
        >
          <img src={editIcon} alt="" /> EDIT CONFIG
        </DialogTitle>
      </Box>
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          width: '100%',
        }}
        id="fieldsetRounded"
      >
        <TextField
          id="standard-basic"
          value={newTag}
          onChange={(e) => {
            setNewTag(e.currentTarget.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && newTag.trim().length > 0) {
              setTagList((prevState) => {
                if (!prevState) return [newTag?.trim()]
                return [...prevState].concat(newTag?.trim())
              })
              setNewTag('')
            }
          }}
          InputProps={{
            endAdornment: (
              <Button
                sx={{
                  marginLeft: 2,
                  whiteSpace: 'nowrap',
                  borderRadius: '100vmax',
                }}
                variant="contained"
                disabled={newTag?.trim().length === 0}
                className="helikaButtonClass"
                onClick={() => {
                  if (newTag.trim().length > 0) {
                    setTagList((prevState) => {
                      if (!prevState) return [newTag?.trim()]
                      return [...prevState].concat(newTag?.trim())
                    })
                    setNewTag('')
                  }
                }}
              >
                Add Tag
              </Button>
            ),
          }}
          helperText="Type in a new tag and press enter to add to the list"
          sx={{
            width: '100%',
            marginTop: 1,
            borderRadius: '3em',
          }}
          placeholder="Tag"
        />
      </Box>
      <Box
        sx={{
          padding: 3,
          width: '100%',
        }}
      >
        TAGS:
        <Box display="flex" flexDirection={'row'} flexWrap={'wrap'}>
          {tagList
            ?.map((tag: any) =>
              tag.length > 30 ? tag.slice(0, 30).concat('...') : tag,
            )
            .map((tag: string, index: number) => {
              return (
                <span key={index + tag} className="gradientWrapper">
                  <span
                    key={`${index}_${tag.substring(0, 10)}`}
                    style={{
                      color: theme.palette.mode === 'dark' ? 'white' : 'black',
                      background:
                        theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
                      borderRadius: '100vmax',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '0.3em',
                      paddingBottom: 1,
                      paddingLeft: '1em',
                      paddingRight: 2,
                      width: 'fit-content',
                    }}
                  >
                    <Typography
                      classes={{ noWrap: 'true' }}
                      noWrap
                      variant="body1"
                      gutterBottom
                    >
                      {tag}
                    </Typography>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{
                        paddingLeft: 1,
                        paddingRight: '1em',
                        height: 'fit-content',
                      }}
                    >
                      <CloseIcon
                        onClick={() => {
                          removeFromTagList(index)
                        }}
                        sx={{
                          cursor: 'pointer',
                          margin: 'auto',
                          height: 'fit-content',
                        }}
                      />
                    </Box>
                  </span>
                </span>
              )
            })}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={'row'}
        sx={{ marginBottom: 2, width: '100%', justifyContent: 'center' }}
      >
        <Button
          className="helikaButtonClass"
          sx={{ width: '15em', paddingLeft: 2, paddingRight: 2 }}
          variant="contained"
          size="small"
          onClick={() => edit(config, tagList)}
          disabled={editInProgress}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  )
}
