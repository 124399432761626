//imports
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-tooltip/dist/react-tooltip.css'
import * as ReactTooltip from 'react-tooltip'
import { useTheme } from '@mui/material'
import { newColor } from '../../../consts/colors'

//services
import {
  getConfig,
  getConfigSheetDiff,
  getConnections,
  getExcelObject,
  searchConfigHistory,
} from '../../../utils/api/queries'
import { importExcel, setObjectToDisplayExcel } from '../../../utils/excels'

//state
import { Auth0Context } from '../../../contexts/Auth0Context'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'

//components
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import ExcelPopup from '../../../components/popups/ExcelPopup'
import UploadExcel from '../../../components/popups/UploadExcel'
import LoadingComponent from '../../../components/LoadingComponent'
import EditParentConfig from '../../../components/popups/EditParentConfig'
import EditConfig from '../../../components/popups/EditConfig'

//assets
import '../../../App.css'
import display_icon from '../../../assets/display_icon.svg'
import display_icon_disabled from '../../../assets/display_icon_disabled.svg'
import date_icon from '../../../assets/date_icon.svg'
import time_icon from '../../../assets/time_icon.svg'
import creator_icon from '../../../assets/creator_icon.svg'
import download_icon from '../../../assets/download_icon.svg'
import configListHeaderIcon from '../../../assets/support_header_text_icon.svg'
import { hasDuplicates } from '../../../utils/string'
import ErrorComponent from '../../../pages/CommonPages/ErrorComponent'
import { useIsDisabled } from 'src/utils/disableDemo'
import PageContainer from 'src/components/atoms/v2/pageContainer'

//----------------------------------------------------------------------------------------------------------

export default function ConfigHistory() {
  //services
  const dispatch = useDispatch()
  const location = useLocation()
  const path = location.pathname?.split('/')
  const parent_id = Number(path[4])
  const navigate = useNavigate()
  const theme = useTheme()

  //state
  const userData = useSelector((state: any) => state.user)
  const { getTokenIfNecessary } = useContext(Auth0Context)
  const { setGameManagementState } = bindActionCreators(
    actionCreators,
    dispatch,
  )
  const CONFIG_HISTORY = useSelector(
    (state: any) => state.gameMgmtData.CONFIG_HISTORY,
  )
  const [connectionsList, setConnectionsList] = useState<any[]>([])

  //modal booleans
  const [showExcel, setShowExcel] = useState<boolean>(false)
  const [hasConnectionsListError, setHasConnectionsListError] =
    useState<boolean>(false)
  const [showExcelHasError, setShowExcelHasError] = useState<boolean>(false)
  const [showHistoryListHasError, setShowHistoryListHasError] =
    useState<boolean>(false)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [showEditParentConfigModal, setShowEditParentConfigModal] =
    useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)

  //table values
  const [columnDefs, setColumnDefs] = useState<any[]>([])
  const [rowData, setRowData] = useState<any[]>()
  const [, setDbDiscrepancies] = useState<any>()
  const [selectedConfig, setSelectedConfig] = useState<any>()
  const [loadedS3Objects, setLoadedS3Objects] = useState<any[]>([])
  const [draftDiff, setDraftDiff] = useState<any | undefined>()

  //loadPage
  const [loadPage, setLoadPage] = useState<number>(2)
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false)

  //display
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isProcessingConfig, setIsProcessingConfig] = useState<boolean>(true)
  const [configList, setConfigList] = useState<any>()
  const [filter, setFilter] = useState<string>('hash_id')
  const [displayTitle, setDisplayTitle] = useState<string>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [currentlySearchedTerm, setCurrentlySearchedTerm] = useState<string>('')
  const [parentConfig, setParentConfig] = useState<any>()

  const isDisabled = useIsDisabled()

  const pageSize = 10

  useEffect(() => {
    async function getAllConnections() {
      let connections = await getConnections(getTokenIfNecessary)
      if (!_.isEmpty(connections?.results)) {
        setConnectionsList(connections?.results)
      }
    }

    try {
      setHasConnectionsListError(false)
      getAllConnections()
    } catch (e: any) {
      toast.error(e?.message)
      setHasConnectionsListError(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //get config data, and history
  useEffect(() => {
    async function getParentConfig() {
      setShowHistoryListHasError(false)
      let config = await getConfig(getTokenIfNecessary, parent_id)
      if (!config) {
        console.log('No config found')
        return
      }
      setParentConfig(config)
      setGameManagementState({
        type: 'SET_SHEET_MAPPINGS',
        payload: config.sheet_mappings,
      })
      return config
    }

    getParentConfig()
      .catch((e: any) => {
        toast.error('Error loading config.')
        console.log(e)
      })
      .then((resp: any) => {
        if (resp) {
          search(filter, '', 1)
        }
      })
      .catch((e: any) => {
        setShowHistoryListHasError(true)
        toast.error('Error loading config.')
        console.log(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function editConfigOnClick(config: any, firstSheetName: any) {
    if (!config.bad_commit) {
      showConfig(
        config,
        firstSheetName,
        parentConfig.sheet_mappings[0].column_aliases?.map(
          (alias: any) => alias.sheet,
        ),
      )
    }
  }

  //load config to display
  async function showConfig(config: any, sheetName?: string, columns?: any) {
    try {
      setIsProcessingConfig(true)
      setShowExcelHasError(false)

      //show modal
      setShowExcel(true)

      //clear current object
      await setObjectToDisplayExcel(
        undefined,
        setColumnDefs,
        setRowData,
        setDisplayTitle,
        sheetName,
        columns,
      )

      //load cached object or download object if no cached object, then format for display
      let cachedObject = _.find(loadedS3Objects, function (item) {
        return item.key === config?.config_s3_key
      })
      if (cachedObject) {
        await setObjectToDisplayExcel(
          cachedObject.file,
          setColumnDefs,
          setRowData,
          setDisplayTitle,
          sheetName,
          columns,
        )
      } else {
        let object = await getExcelObject(config?.config_s3_key)
        setLoadedS3Objects((prevState: any) => {
          let foundObject = _.find(prevState, function (item) {
            return item.key === config?.config_s3_key
          })
          if (foundObject) {
            return prevState
          } else {
            return prevState.concat({
              file: object,
              key: config?.config_s3_key,
            })
          }
        })
        importExcel(
          object,
          setColumnDefs,
          setRowData,
          setDisplayTitle,
          sheetName,
          columns,
        )
      }

      //get the diff and discrepancies to load
      let diff = await getConfigSheetDiff(
        getTokenIfNecessary,
        config,
        sheetName,
      )

      if (_.isUndefined(diff)) {
        throw new Error('Could not load config diff')
      }

      if ('status' in diff && diff.status === 'error') {
        setShowExcelHasError(true)
        return;
      }

      let hasDupes = false
      if (!_.isEmpty(diff) && !_.isEmpty(diff[0]) && !_.isEmpty(diff[0].diff)) {
        let temp_diff = diff[0].diff
        let keys = temp_diff.map((diffObject: any) => {
          let keys = Object.keys(diffObject)
          if (!_.isEmpty(keys)) {
            return keys[0]
          }
          return undefined
        })
        hasDupes = hasDuplicates(
          keys?.filter((item: any) => item !== undefined),
        )
      }

      if (hasDupes) {
        setShowExcelHasError(true)
        toast.error(
          `Key field is invalid - Key field must have unique values. Please edit the config and choose a key field that doesn't have duplicate values.`,
        )
        setIsProcessingConfig(false)
        return
      }

      //let x = await getDiff(getTokenIfNecessary,config)
      setDraftDiff(diff)
      //let discrepancies  = await getDiscrepancies(getTokenIfNecessary,parentConfig?.id);
      //setDbDiscrepancies(discrepancies);

      setIsProcessingConfig(false)
    } catch (e: any) {
      toast.error(e?.message)
      setShowExcelHasError(true)
    }
  }

  //projectLoading
  useEffect(() => {
    setTimeout(function () {
      if (_.isEmpty(userData?.tabs)) setShowExcelHasError(true)
    }, 15000)
  }, [userData])

  //change search filter
  async function handleFilterChange(event: SelectChangeEvent) {
    setFilter(event.target.value as string)
  }

  //search configs given search term and filter
  async function search(filter: string, search_term: string, page?: number) {
    try {
      //save searched term
      setCurrentlySearchedTerm(search_term)

      setIsLoading(true)

      let params: any = {
        page_size: pageSize,
        page: page,
      }

      //specific term search
      if (!_.isEmpty(search_term)) {
        params[filter] = search_term
      }
      let configHistory = await searchConfigHistory(
        getTokenIfNecessary,
        parent_id,
        params,
      )
      setLoadPage(page ? page + 1 : 1)
      setCanLoadMore(configHistory && configHistory.length === pageSize)
      if (page === 1) {
        setGameManagementState({
          type: 'SET_CONFIG_HISTORY',
          payload: configHistory,
        })
      } else {
        setGameManagementState({
          type: 'LOAD_MORE_CONFIG_HISTORY',
          payload: configHistory,
        })
      }
      setIsLoading(false)
    } catch (e: any) {
      toast.error(e?.message)
      setShowHistoryListHasError(true)
    }
  }

  //set config list display
  useEffect(() => {
    if (
      !parentConfig ||
      _.isEmpty(parentConfig.sheet_mappings) ||
      !CONFIG_HISTORY
    )
      return
    try {
      setConfigList(
        CONFIG_HISTORY?.map((config: any) => {
          let firstSheetName = parentConfig.sheet_mappings[0].sheet_name
          return (
            <Grid
              sx={{ padding: '0.3em 1em 0.3em 1em', marginBottom: '1em' }}
              container
              spacing={2}
              key={config?.hash_id}
              wrap="nowrap"
            >
              <Grid item xs={48 / 5}>
                <Grid
                  container
                  wrap="nowrap"
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                    borderStyle: 'solid',
                    borderColor: config?.bad_commit ? 'red' : newColor.outerSpace,
                    borderRadius: '0.5em',
                    borderWidth: '1px',
                  }}
                >
                  <Grid
                    sx={{
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0.5em',
                    }}
                    item
                    xs={4}
                  >
                    <Typography
                      sx={{
                        margin: 'auto',
                        maxWidth: '100%',
                        fontWeight: 400,
                        height: 'fit-content',
                      }}
                      noWrap
                      variant="body1"
                    >
                      {config.hash_id}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    flexDirection={'column'}
                    className="notifications"
                    sx={{ padding: '0.5em' }}
                    item
                    xs={3}
                  >
                    <div className="notifications" style={{ margin: 'auto' }}>
                      {config?.tags
                        ?.map((tag: any) =>
                          tag.length > 20
                            ? tag.slice(0, 20).concat('...')
                            : tag,
                        )
                        ?.toString()
                        .replaceAll(',', ', ')}
                    </div>
                  </Grid>
                  <Grid
                    sx={{
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                      padding: '0.5em',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    item
                    xs={2}
                  >
                    {config.applied_at ? (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{ width: 'fit-content', margin: 'auto' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontWeight: 400,
                            height: 'fit-content',
                            whiteSpace: 'pre',
                          }}
                        >
                          <Box
                            sx={{
                              paddingRight: '0.5em',
                              width: 'fit-content',
                              margin: 0,
                            }}
                            display="flex"
                            flexDirection={'column'}
                          >
                            <img
                              src={date_icon}
                              alt=""
                              style={{ margin: 'auto' }}
                            />
                          </Box>
                          {moment(config.applied_at).format('YYYY-MM-DD')}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontWeight: 400,
                            height: 'fit-content',
                            whiteSpace: 'pre',
                          }}
                        >
                          <Box
                            sx={{
                              paddingRight: '0.5em',
                              width: 'fit-content',
                              margin: 0,
                            }}
                            display="flex"
                            flexDirection={'column'}
                          >
                            <img
                              src={time_icon}
                              alt=""
                              style={{ margin: 'auto' }}
                            />
                          </Box>
                          {moment
                            .tz(config.applied_at, 'UTC')
                            .tz(moment.tz.guess(true))
                            .format('hh:mm:ss A')}
                        </Box>
                      </Box>
                    ) : (
                      <div
                        style={{
                          height: '3em',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        N/A
                      </div>
                    )}
                  </Grid>
                  {config.applied_by_email ? (
                    <Grid
                      sx={{
                        textOverflow: 'ellipsis',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0.5em',
                      }}
                      item
                      xs={3}
                    >
                      <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                      >
                        <Box
                          sx={{
                            paddingRight: '0.5em',
                            width: 'fit-content',
                            margin: 0,
                          }}
                          display="flex"
                          flexDirection={'column'}
                        >
                          <img
                            src={creator_icon}
                            alt=""
                            style={{ margin: 'auto' }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            margin: 'auto 0 auto 0',
                            fontWeight: 400,
                            width: 'fit-content',
                          }}
                          noWrap
                          variant="body1"
                          className="notifications"
                        >
                          {config.applied_by_email}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <div
                      style={{
                        margin: 'auto',
                        height: '3em',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      DRAFT
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12 / 5} display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  sx={{
                    width: '100%',
                    margin: 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={
                      config.bad_commit ? display_icon_disabled : display_icon
                    }
                    alt=""
                    style={{ marginRight: '0.5em', cursor: config.bad_commit ? 'not-allowed' : 'pointer' }}
                    onClick={() => {
                      setSelectedConfig(config)
                      editConfigOnClick(config, firstSheetName)
                    }}
                    data-tooltip-id="configHistoryTooltip"
                    data-tooltip-html={
                      config.bad_commit
                        ? 'The Sheet Mappings have been changed since this commit was applied and cannot be re-applied.<br />Please download this sheet, apply the necessary changes, and re-upload to revert to this state.'
                        : 'Display Config'
                    }
                  />
                  <a
                    style={{ display: 'flex', flexDirection: 'column' }}
                    href={`https://liveops-config.s3.us-east-2.amazonaws.com/${config.config_s3_key}`}
                    data-tooltip-id="configHistoryTooltip"
                    data-tooltip-content={'Download Config'}
                  >
                    <img
                      src={download_icon}
                      alt=""
                      style={{ margin: 'auto 0.5em auto 0', cursor: 'pointer' }}
                    />
                  </a>
                  <Button
                    className="helikaButtonClass"
                    style={{
                      width: 'fit-content',
                      padding: '0.3em 2em 0.3em 2em',
                    }}
                    onClick={() => {
                      setSelectedConfig(config)
                      setShowEdit(true)
                    }}
                    data-tooltip-id="configHistoryTooltip"
                    data-tooltip-content={'Edit Tags'}
                    disabled={isDisabled}
                  >
                    EDIT
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )
        }),
      )
    } catch (e: any) {
      toast.error(e?.message)
      setShowHistoryListHasError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CONFIG_HISTORY, draftDiff, parentConfig?.sheet_mappings, loadedS3Objects])

  useEffect(() => {
    setGameManagementState({
      type: 'SET_CONFIG_HISTORY',
      payload: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent_id])

  let environment: any = null
  if (parentConfig && parentConfig.environment) {
    switch (parentConfig.environment) {
      case 'staging':
        environment = 'Staging'
        break
      case 'prod':
        environment = 'Production'
        break
      default: {
        environment = null
      }
    }
  }

  return (
    <PageContainer
      sx={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background: theme.palette.mode === 'dark' ? 'black' : newColor.cultured,
        minHeight: '100vh',
        maxHeight: '100vh',
        minWidth: '60em',
        width: '100%',
        maxWidth: '200em',
        margin: 'auto',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <ReactTooltip.Tooltip id="configHistoryTooltip" />

      {/* modals */}
      <ExcelPopup
        open={showExcel}
        setOpen={setShowExcel}
        onClose={() => {
          setPopupOpen(false)
          setSelectedConfig(undefined)
          setDraftDiff(undefined)
          setRowData(undefined)
          setDbDiscrepancies(undefined)
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        title={displayTitle}
        showConfig={showConfig}
        config={selectedConfig}
        draftDiff={draftDiff}
        isProcessingConfig={isProcessingConfig}
        hasError={showExcelHasError}
      />
      <UploadExcel
        open={popupOpen}
        setOpen={setPopupOpen}
        onClose={() => {
          setPopupOpen(false)
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        showConfig={showConfig}
        parent_id={parentConfig?.id}
        parentConfig={parentConfig}
      />

      <EditParentConfig
        open={showEditParentConfigModal}
        setOpen={setShowEditParentConfigModal}
        selected_config={parentConfig}
        db_connections_list={connectionsList}
        getOriginalConfigData={() => {
          return parentConfig
        }}
      />
      <EditConfig
        config={selectedConfig}
        open={showEdit}
        setOpen={setShowEdit}
        onClose={() => {
          setShowEdit(false)
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        loadedS3Objects={loadedS3Objects}
        setLoadedS3Objects={setLoadedS3Objects}
        draftDiff={draftDiff}
      />

      {/* page header */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'space-between',
          marginBottom: '1em',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            padding: '0 1em 0 1em',
            maxWidth: '100%',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              src={configListHeaderIcon}
              style={{ height: '1.5em', margin: 'auto 0 auto 1em' }}
              alt=""
            />
            <Typography
              className="ellipsisWithWrap ellipsisWithWrap"
              sx={{
                color: 'white',
                margin: 'auto 0 auto 0.5em',
                fontSize: '1.5em',
                fontWeight: 700,
              }}
            >
              CONFIG HISTORY - {parentConfig?.name}
            </Typography>
          </span>
          <Button
            onClick={() => {
              navigate('/game_management/live_ops/config')
            }}
            className="helikaButtonClass ellipsisWithWrap"
            sx={{
              height: '2.5em',
              padding: '0 2em 0 2em',
              margin: 'auto 0 auto 0.5em',
              fontSize: '0.8em',
            }}
          >
            Back to List
          </Button>
        </Box>
      </Box>

      {/* search */}
      <Box
        sx={{ padding: '0 1em 1em 1em', justifyContent: 'space-between' }}
        display={'flex'}
        flexDirection={'row'}
      >
        <input
          className={
            theme.palette.mode === 'dark' ? 'helikaInputDark' : 'helikaInput'
          }
          type="text"
          style={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            backgroundColor:
              theme.palette.mode === 'dark' ? newColor.midnight : 'white',
            boxShadow: 'none',
            borderStyle: 'solid',
            borderRadius: '0.3em',
            borderWidth: '1px',
            marginTop: 'auto',
            paddingLeft: '2em',
            padding: '0.55em',
            width: '100%',
            marginRight: '1em',
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await search(filter, searchTerm, 1)
            }
          }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.currentTarget.value)
          }}
          placeholder="SEARCH"
        />
        <FormControl
          size="small"
          sx={{ width: '12em', marginTop: 'auto', marginBottom: 'auto' }}
        >
          <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
            Search By:
          </p>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            //label="Search By"
            size="small"
            onChange={handleFilterChange}
            style={{ height: '2em' }}
          >
            <MenuItem value={'hash_id'}>ID</MenuItem>
            <MenuItem value={'tag'}>Tag</MenuItem>
            <MenuItem value={'applied_by'}>Applied By</MenuItem>
          </Select>
        </FormControl>
        <Button
          style={{ height: '2.5em', marginTop: 'auto' }}
          className="helikaButtonClass"
          variant="contained"
          size="small"
          sx={{ width: '14em', marginLeft: 2 }}
          onClick={async () => await search(filter, searchTerm, 1)}
        >
          Search
        </Button>
      </Box>

      {/* import button */}
      <Box display={'flex'} flexDirection={'row'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{ width: '80%', justifyContent: 'center', margin: 'auto' }}
        >
          <Button
            sx={{
              width: 'auto',
              margin: '0.2em',
              paddingLeft: 5,
              paddingRight: 5,
            }}
            size="small"
            className="helikaButtonClass"
            onClick={() => setPopupOpen(true)}
            disabled={isDisabled}
          >
            Import Excel
          </Button>
          <Button
            sx={{
              width: 'auto',
              margin: '0.2em',
              paddingLeft: 5,
              paddingRight: 5,
            }}
            size="small"
            className="helikaButtonClass"
            onClick={() => setShowEditParentConfigModal(true)}
            disabled={isDisabled}
          >
            Edit Config
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{ margin: '1em 2em 0 2em', padding: 1 }}
      >
        <p
          className="linearGradientCircle"
          style={{ margin: '0', padding: '0 1em 0 1em' }}
        >{`Environment: ${environment}`}</p>
      </Box>

      {/* main display */}
      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          margin: '1em',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        }}
      >
        {/* headers */}
        {!isLoading && (
          <Grid sx={{ padding: '1em' }} container spacing={2}>
            <Grid item xs={48 / 5}>
              <Grid
                sx={{ padding: '0.3em 0 0.3em 0' }}
                className="linearGradientCircle"
                container
              >
                <Grid
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '100%',
                  }}
                  item
                  xs={4}
                >
                  <Typography
                    sx={{ fontWeight: 900, height: 'fit-content', display: 'flex', justifyContent: 'center' }}
                    noWrap
                    variant="body1"
                  >
                    # ID
                  </Typography>
                </Grid>
                <Grid
                  sx={{ textOverflow: 'ellipsis', maxWidth: '100%' }}
                  item
                  xs={3}
                >
                  <Typography
                    sx={{ fontWeight: 900, height: 'fit-content', display: 'flex', justifyContent: 'center' }}
                    noWrap
                    variant="body1"
                  >
                    TAGS
                  </Typography>
                </Grid>
                <Grid
                  sx={{ textOverflow: 'ellipsis', maxWidth: '100%', display: 'flex', justifyContent: 'center' }}
                  item
                  xs={2}
                >
                  <Typography
                    sx={{ fontWeight: 900, height: 'fit-content' }}
                    noWrap
                    variant="body1"
                  >
                    APPLIED AT
                  </Typography>
                </Grid>
                <Grid
                  sx={{ textOverflow: 'ellipsis', maxWidth: '100%', display: 'flex', justifyContent: 'center' }}
                  item
                  xs={3}
                >
                  <Typography
                    sx={{ fontWeight: 900, height: 'fit-content' }}
                    noWrap
                    variant="body1"
                  >
                    APPLIED BY
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* config list */}
        {configList}

        {/* load more button */}
        {hasConnectionsListError || showHistoryListHasError ? (
          <ErrorComponent />
        ) : isLoading ? (
          <LoadingComponent />
        ) : (
          canLoadMore && (
            <Button
              className="helikaButtonClass"
              sx={{ width: '10em', margin: '1em auto 2em auto' }}
              onClick={() => search(filter, currentlySearchedTerm, loadPage)}
            >
              Load More
            </Button>
          )
        )}
      </Box>
    </PageContainer>
  )
}
