import React from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import { newColor } from 'src/consts/colors'

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.jazzberryJam,
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '5px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
}))

export type CreateHookButtonProps = {
  onClick?: () => void
}

const CreateHookButton = ({ onClick }: CreateHookButtonProps) => {
  return (
    <StyledButton variant="contained" startIcon={<AddIcon />} onClick={onClick}>
      Create Hook
    </StyledButton>
  )
}

export default CreateHookButton
