//imports
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';

//contexts
import { Auth0Context } from 'src/contexts/Auth0Context';

//services
import { runQuery } from 'src/utils/api/queries';
import { downloadCSV } from 'src/utils/excels';

//components
import { ErrorAlert } from 'src/components/atoms/v2/ErrorAlert';
import { baseOptions } from 'src/utils/echarts';
import SelectVisualModal from '../popups/SelectVisualModal';
import { createHeadersFromColumnList } from 'src/utils/agGrid';
import { visualTypes } from 'src/utils/string';
import { VisualDesignerEchart } from './VisualDesignerEchart';
import LoadingComponent from '../LoadingComponent';
import DatasetIcon from 'src/assets/explore_icon.svg'
import { newColor } from 'src/consts/colors';

type IEChartProps = { item: any; loading: boolean; error?: Error, data?: any, visuals: any[], queries: any[], setItems: any, inEditMode: boolean };

export const DashboardDesignerEchart = ({ item, loading, error, data, visuals, queries, setItems, inEditMode }: IEChartProps) => {

  const theme: any = useTheme();
  const [selectVisualModalOpen, setSelectVisualModalOpen] = useState<boolean>(false)
  const [displayItem, setDisplayItem] = useState<any>(item);
  const { postWithAccessToken } = useContext(Auth0Context)

  const [newVisual, setNewVisual] = useState<any>();
  let [dataHeaders, setDataHeaders] = useState<any[]>([]);
  let [displayData, setDisplayData] = useState<any>([]);
  let [visualLoading, setVisualLoading] = useState<boolean>(false);
  let [handledVisual, setHandledVisual] = useState<any>(undefined);

  //reset loading visual process when new item is fed into component
  useEffect(() => {
    setHandledVisual(undefined)
    setDisplayItem(item)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.visual_id])

  useEffect(() => {
    let series: any = []
    let foundVisual = _.find(visuals, (item: any) => item?.id === displayItem.visual_id)
    if (!foundVisual) return;
    if (!_.isEmpty(dataHeaders) && !(dataHeaders.length === 1 && dataHeaders[0].field === 'error')) {
      series = foundVisual.series?.map((serie: any, index: number) => {
        let opts: any = {
          name: serie,
          type: foundVisual.type,
          stack: foundVisual.stacked ? 'Total' : undefined,
          data: foundVisual.type === visualTypes.pie
            ? displayData?.map((data: any) => {
              return {
                value: data[serie],
                name: _.isEmpty(foundVisual.x_axis) ? data['helika_row_id'] : data[foundVisual.x_axis]
              }
            })
            : displayData?.map((data: any) => data[serie])
        }
        if (foundVisual.type === visualTypes.funnel) {
          opts.width = '80%';
          opts.sort = 'descending';
          opts.label = {
            show: foundVisual.show_legend,
            position: 'inside'
          }
          opts.gap = 2;
          opts.data = displayData?.map((data: any) => {
            return {
              value: data[serie],
              name: (_.isEmpty(foundVisual.type) && _.isEmpty(data[foundVisual.type])) ? data['helika_row_id'] : data[foundVisual.type]
            }
          })
        }
        return opts;
      })
    }

    async function downloadHandler() {
      if (_.isEmpty(displayData)) {
        toast.error('No data to download', {
          toastId: 'nothing_to_download'
        });
        return;
      }
      let newTitle = foundVisual.name ? foundVisual.name : 'dataset'
      downloadCSV(displayData, newTitle.concat('.csv'), true);
    }

    let options: any = {
      ...baseOptions,
      title: {
        ...baseOptions.title,
        text: foundVisual.name,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
      backgroundColor: theme.palette.background.default,
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        ...baseOptions.grid,
        left: '3%',
        right: '3%',
        top: _.isEmpty(foundVisual?.series)
          ? 50
          : 90
        ,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
          myFeature: {
            show: !_.isEmpty(displayData),
            title: 'Download Dataset',
            icon: `image://${DatasetIcon}`,
            onclick: downloadHandler
          }
        }
      },
      xAxis:
        (_.isEmpty(series) || series.type === visualTypes.funnel)
          ? {}
          :
          {
            type: 'category',
            boundaryGap: true,
            data: _.isEmpty(foundVisual.x_axis) ? displayData['helika_row_id'] : displayData.map((item: any) => item[foundVisual.x_axis])
          },
      yAxis: {
        type: 'value',
        alignTicks: true,
      },
      series: series
    }
    if (foundVisual.show_legend) {
      options.legend = {
        data: series.name,
        textStyle: {
          color: theme.palette.text.primary
        },
        top: 40,
        type: 'scroll',
      }
    } else {
      delete options.legend
    }

    if (![visualTypes.pie, visualTypes.funnel].includes(foundVisual.type)) {
      if (!_.isEmpty(series.x_axis)) {
        let newXAxis = displayData?.map((data: any) => data[series.x_axis]);
        options.xAxis.data = newXAxis
      }

      if (foundVisual.type === visualTypes.waterfall) {
        options.series = options.series?.slice(0, 2).map((serie: any) => {
          return Object.assign({}, serie, {
            type: visualTypes.bar,
            stack: 'Total'
          })
        })
        options.series[1] = Object.assign({}, options.series[1], {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          stack: 'Total'
        })
      }

      if (foundVisual.x_axis_ticks === 'All') {
        options.xAxis.axisLabel = {
          interval: 0,
          rotate: 45
        }
      }
    } else {
      delete options.xAxis
      delete options.yAxis
      delete options.legend
    }

    setDisplayItem(((prevState: any) => {
      let newDisplayItem = Object.assign({}, prevState, { options: options });
      return newDisplayItem
    }))

    //set echart
    setNewVisual(
      <div
        id='newVis'
        style={{
          margin: 'auto',
          height: inEditMode ? '100%' : '90%',
          maxWidth: '100%',
          overflow: 'auto',
          width: '100%'
        }}
      >
        <VisualDesignerEchart
          loading={false}
          options={options}
          fullWidth={!inEditMode}
        />
      </div>
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataHeaders, displayData, displayItem?.id, theme.palette.text.primary, visuals])

  //run query
  const runHandler = useCallback(async (code: any) => {
    async function errorHandler(e: any) {
      if ('detail' in e) {
        toast.error('Error running query. See results for details.')
        setDisplayData([{ error: e.detail, helika_row_id: '1' }])
        setDataHeaders([
          {
            headerName: 'Error',
            field: 'error',
            wrapText: true,
            autoHeight: true,
            minWidth: 100,
            sortable: false,
            flex: 1,
            headerClassName: 'error-datagrid-header',
          }
        ])
      } else {
        toast.error(e)
      }
      setVisualLoading(false)
    }
    try {
      setVisualLoading(true)
      let resp: any = await runQuery(postWithAccessToken, { sql_query: code }, errorHandler)
      if (_.isEmpty(resp)) return
      let allData = [...resp.data];
      let columns = [...(Object.keys(allData[0]))]
      let headers = createHeadersFromColumnList(columns)
      let rowLabeledData = allData.map((row: any, index: number) => {
        let newProp: any = {};
        newProp[`helika_row_id`] = index;
        return Object.assign({}, row, newProp)
      })
      setDisplayData(rowLabeledData)
      setDataHeaders(headers)
      setVisualLoading(false)

    } catch (e) {
      errorHandler(e);
    }
  }, [postWithAccessToken])

  useEffect(() => {
    if (!displayItem.visual_id || visualLoading || handledVisual) return
    let chosenVisual = _.find(visuals, (visualItem: any) => visualItem?.id === displayItem.visual_id)
    let chosenQuery = _.find(queries, (queryItem: any) => queryItem.id === chosenVisual?.query_id)
    if (!chosenQuery?.query) return;
    setHandledVisual(displayItem.visual_id)
    runHandler(chosenQuery?.query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayItem.visual_id, visualLoading, visuals, queries])

  return (
    <Grid
      id='echart-grid-content'
      item
      height={'100%'}
      width={'100%'}
      sx={{
        flex: 1,
        flexGrow: 1,
        padding: inEditMode ? 0 : '',
        display: 'flex',
        flexDirection: 'column',
        '&:focus, &:hover': {
          borderColor: inEditMode ? 'none' : `red`,
        },
      }}
    >
      <ErrorAlert error={error} />
      {
        inEditMode &&
        <SelectVisualModal
          open={selectVisualModalOpen}
          setOpen={setSelectVisualModalOpen}
          setLastSaved={''}
          data={data}
          item={displayItem}
          visuals={visuals}
          queries={queries}
          dataLoading={loading}
          setItems={setItems}
        />
      }
      {

        (loading || visualLoading || !inEditMode)
          ? null
          : !_.isNil(displayItem.options)
            ?
            <div
              style={{
                width: '100%',
                height: '2.5em',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '1em',
                padding: '0 1em 0 1em'
              }}
            >
              <Box
                style={{
                  height: 'fit-content',
                  padding: '0.3em 2em',
                  borderRadius: '100vmax',
                  textAlign: 'center',
                  border: 'solid',
                  width: '15em',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setSelectVisualModalOpen(true)
                }}
                className='truncate-ellipsis secondaryTruncate'
              >
                CHOOSE VISUAL
              </Box>
              <Box
                style={{
                  height: 'fit-content',
                  padding: '0.3em 2em',
                  borderRadius: '100vmax',
                  textAlign: 'center',
                  border: 'solid',
                  width: '15em',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setDisplayItem((prevState: any) => {
                    return Object.assign({}, prevState, { options: undefined, visual_id: undefined })
                  })
                  setItems((prevState: any) => {
                    return prevState?.map((itemObject: any) => {
                      if (itemObject.id !== item.id) {
                        return itemObject
                      }
                      return Object.assign({}, itemObject, {
                        visual_id: undefined,
                      })
                    })
                  })
                }}
                className='truncate-ellipsis secondaryTruncate'
              >
                CLEAR
              </Box>
            </div>
            :
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                margin: 'auto',
                flexWrap: 'wrap',
                maxWidth: '100%',
                padding: '1em'
              }}
              spacing={2}
              container
            >
              <Grid item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Box
                  style={{
                    padding: '0.5em 2em',
                    margin: '0 auto 0 auto',
                    borderRadius: '100vmax',
                    textAlign: 'center',
                    background: `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.jazzberryJam} 100%)`,
                    width: '15em',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setSelectVisualModalOpen(true)
                  }}
                  className='truncate-ellipsis secondaryTruncate'
                >
                  CHOOSE VISUAL
                </Box>
              </Grid>
              <Grid item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Box
                  style={{
                    padding: '0.5em 2em',
                    borderRadius: '100vmax',
                    textAlign: 'center',
                    border: 'solid',
                    width: '15em',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setItems((prevState: any) => {
                      return prevState?.filter((itemObject: any) => itemObject.id !== item.id)
                    })
                  }}
                  className='truncate-ellipsis secondaryTruncate'
                >
                  REMOVE
                </Box>
              </Grid>
            </Grid>
      }
      {
        (loading || visualLoading)
          ?
          <div
            style={{
              scrollbarGutter: 'both-sides',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <LoadingComponent />
          </div>
          : (!_.isNil(displayItem.options) && newVisual)
      }
    </Grid>
  );
};
