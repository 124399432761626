import React from 'react'
import { toast } from 'react-toastify'
import copyToClipBoard from '../../assets/copy_to_clipboard_icon.svg'
import { newColor } from '../../consts/colors'
import { Button } from '@mui/material'
export const ReportedByRenderer = (props: any) => {
  const cellValue = props.props?.reported_by
    ? props.props?.reported_by
    : props.valueFormatted
      ? props.valueFormatted
      : props.value

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '30em',
        margin: 'auto',
        gap: '5px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'block',
          margin: 'auto',
          width: '90%',
          textOverflow: 'ellipsis',
        }}
        className="secondaryTruncate"
      >
        {cellValue}
      </div>
      <Button
        style={{
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          background: newColor.tertiary,
          borderRadius: '1rem',
          padding: '3px',
          minWidth: '20px',
        }}
        onClick={() => {
          navigator.clipboard.writeText(cellValue)
          toast.success('Address copied to clipboard')
        }}
      >
        <img
          src={copyToClipBoard}
          alt="Copy To Clipboard Icon"
          width={14}
          height={14}
        />
      </Button>
    </div>
  )
}
