//imports
import React from 'react'
import '../../../../App.css'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import { Box, BoxProps, useTheme } from '@mui/material'
import { useGetDrawer } from 'src/utils/drawer'
import { closeDrawerWidth, drawerWidth } from 'src/consts/sizes'
//----------------------------------------------------------------------------------------------------------

interface PageContainerProps extends BoxProps {
  children: JSX.Element | JSX.Element[]
  bgColor?: string
}

export default function PageContainer({
  children,
  bgColor,
}: PageContainerProps) {

  const theme = useTheme()
  const isDrawerOpen = useGetDrawer();

  return (
    <Box
      style={{
        display: 'flex',
        minWidth: '425px',
        gap: '1rem',
        flexDirection: 'column',
        flex: 1,
        padding: '1em',
        overflow: 'auto',
        backgroundColor: bgColor,
        width: isDrawerOpen ? `calc(100vw - ${drawerWidth})` : `calc(100vw - ${closeDrawerWidth})`,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      {children}
    </Box>
  )
}
