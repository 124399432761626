//imports
import { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useTheme } from '@emotion/react'
import { PostgreSQL, sql } from '@codemirror/lang-sql'
import { coolGlow, tomorrow } from 'thememirror';
import moment from 'moment'
import { toast } from 'react-toastify'

//context

//services
import { getOrgQueries, getUserQueries, getVisualizations, runQuery, updateVisualization } from '../../../utils/api/queries'

//components
import { Box, Button } from '@mui/material'
import { ReactComponent as OpenFolder } from 'src/assets/Folder_Open.svg'
import LineChartSettingsBox from 'src/components/Explore/LineChartSettingsBox'
import { DataGridPro, GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { createHeadersFromColumnList } from 'src/utils/agGrid'
import { baseOptions } from 'src/utils/echarts';
import { VisualDesignerEchart } from 'src/components/Explore/VisualDesignerEchart'
import { closeDrawerWidth, drawerWidth } from 'src/consts/sizes'
import LoadingComponent from 'src/components/LoadingComponent'
import { useGetDrawer } from 'src/utils/drawer'
import { newColor } from 'src/consts/colors'
import VisualDesignerShowMenu from 'src/components/VisualDesignerShowMenu';
import VisualDesignerSaveMenu from 'src/components/VisualDesignerSaveMenu'
import SaveVisualModal from 'src/components/popups/SaveVisualModal'
import DeleteVisualModal from 'src/components/popups/DeleteVisualModal'
import { visualTypes } from 'src/utils/string'
import { CodeEditor } from 'src/components/codeMirror/CodeEditor';
import { EditorView } from 'codemirror';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { downloadCSV } from 'src/utils/excels';
import DatasetIcon from '../../../assets/explore_icon.svg'
import { ExplorerHeader } from 'src/components/Explore/ExplorerHeader';
import SavedVisualsModal from 'src/components/popups/SavedVisualsModal';
import VisualDesignerNewVisualMenu from 'src/components/VisualDesignerNewVisualMenu';
import { useLocation } from 'react-router-dom';

export default function VisualDesigner() {

  const theme: any = useTheme()
  const isDrawerOpen = useGetDrawer();
  const location: any = useLocation()

  const {
    isAuthenticated,
    getWithAccessToken,
    postWithAccessToken,
    patchWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [code,] = useState<any>(null);
  const [designName, setDesignName] = useState<string>('New Visual');
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [displayItem, setDisplayItem] = useState<string>('data');
  const [valueWidth, setValueWidth] = useState<number>(0);
  const [selectedQuery, setSelectedQuery] = useState<any>();
  const [selectedVisual, setSelectedVisual] = useState<any>();
  const [visuals, setVisuals] = useState<any[]>([]);
  const [lastSaved, setLastSaved] = useState<Date | null>();
  const [settings, setSettings] = useState<any>({});
  const [newVisual, setNewVisual] = useState<any>();
  const [isOpenSavedVisualsModal, setIsOpenSavedVisualsModal] = useState<boolean>(false);
  const [isLoadingQueries, setIsLoadingQueries] = useState<boolean>(false);
  const [isRunningQuery, setIsRunningQuery] = useState<boolean>(false);
  const [datagridExpanded, setDatagridExpanded] = useState<boolean>(false);
  const [datasetColumns, setDatasetColumns] = useState<any[]>([]);
  const [showSaveVisualModal, setShowSaveVisualModal] = useState<boolean>(false);
  const [showDeleteVisualModal, setShowDeleteVisualModal] = useState<boolean>(false);

  const [userQueries, setUserQueries] = useState<any[]>([]);
  const [orgQueries, setOrgQueries] = useState<any[]>([]);
  let [dataHeaders, setDataHeaders] = useState<any[]>([]);
  let [displayData, setDisplayData] = useState<any>([]);


  useEffect(() => {
    if (
      !_.isNil(location?.state?.loadQuery) &&
      Number(location.state.loadQuery) &&
      (!_.isEmpty(userQueries) || !_.isEmpty(orgQueries))
    ) {
      let allQueries: any = []
      allQueries = allQueries.concat(userQueries)?.concat(orgQueries)
      let foundQuery = allQueries.find((query: any) => query.id === Number(location.state.loadQuery))
      if (foundQuery) {
        setSelectedQuery(foundQuery);
        window.history.replaceState({}, document.title)
      }
    }
  }, [location.state, orgQueries, userQueries])

  async function downloadHandler() {
    if (_.isEmpty(displayData)) {
      toast.error('No data to download', {
        toastId: 'nothing_to_download'
      });
      return;
    }
    let newTitle = selectedQuery?.name ? selectedQuery?.name : 'dataset'
    downloadCSV(displayData, newTitle.concat('.csv'), true);
  }

  //update visual api call
  async function updateVisualizationHandler(callback?: any) {
    if (_.isUndefined(selectedQuery)) {
      toast.error('Could not find query to save')
      return
    } else if (_.isEmpty(designName?.trim())) {
      toast.error('Query name and code must not be empty')
      return
    }

    let args = Object.assign({}, {
      query_id: selectedQuery?.id,
      type: settings.visualType,
      name: designName,
      id: selectedVisual?.id,
      series: settings.checkedSeries,
      x_axis: settings.xAxis,
      x_axis_ticks: settings.xAxisTicks,
      stacked: settings.stackType === 'Stacked',
      show_legend: settings.showingLegend === 'Show',
    })
    await updateVisualization(patchWithAccessToken, args).then(async resp => {
      setLastSaved(resp.results.updated_at)
      toast.success('Visual was saved')
      try {
        setVisuals(prevState => {
          if (!prevState) return resp.results;
          return prevState.map(visual => {
            if (visual.id === resp.results.id) {
              return resp.results;
            }
            return visual
          })
        })
        if (typeof callback === 'function') {
          callback()
        }
      } catch (e) {
        await updateQueryList(resp.results.id).catch((e: any) => {
          toast.error('Could not reload Queries and Visuals List, please refresh')
          return
        })
        if (typeof callback === 'function') {
          callback()
        }
      }
    }).catch(e => {
      toast.error('Could not save visual')
      toast.error(e)
    })
  }

  //update query list and visual list
  const updateQueryList = useCallback(async (id?: any) => {

    if (isLoadingQueries) return

    setIsLoadingQueries(true)

    try {
      let respUserQueries: any = await getUserQueries(getWithAccessToken)
      let respOrgQueries: any = await getOrgQueries(getWithAccessToken)
      let respVisualizations: any = await getVisualizations(getWithAccessToken)

      setOrgQueries(respOrgQueries)
      setUserQueries(respUserQueries)
      setVisuals(respVisualizations)

      let prevSelectedVisual = [...respVisualizations].find(visual => visual.id === id)
      if (!_.isUndefined(prevSelectedVisual)) {
        setSelectedVisual(prevSelectedVisual)
        setSelectedQuery(prevSelectedVisual.query_id)
        setDesignName(prevSelectedVisual.name)
      } else {
        if (

          !_.isNil(location?.state?.loadQuery) &&
          Number(location.state.loadQuery) &&
          (!_.isEmpty(respOrgQueries) || !_.isEmpty(respUserQueries))
        ) {
          let fullQueries = respOrgQueries?.concat(respUserQueries)
          let foundQuery = fullQueries?.find((query: any) => query.id === location?.state?.loadQuery)
          setSelectedQuery(foundQuery ? foundQuery : undefined)
        } else {
          setSelectedQuery(undefined)
        }
      }
      setIsLoadingQueries(false)
    } catch (e: any) {
      setIsLoadingQueries(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWithAccessToken])

  //when selectedVisual is changed, change selected query and last saved value
  useEffect(() => {
    let fullQueriesList: any = orgQueries.concat([...userQueries])
    if (!selectedVisual?.query_id || _.isEmpty(fullQueriesList)) return
    let newSelectQuery = fullQueriesList?.find((query: any) => query.id === selectedVisual.query_id)
    setSelectedQuery(newSelectQuery)
    setLastSaved(selectedVisual?.updated_at)
    if (!_.isEmpty(selectedVisual)) {
      setDesignName(selectedVisual?.name)
    } else if (!_.isEmpty(selectedQuery)) {
      setDesignName(selectedQuery?.name)
    }
  }, [orgQueries, userQueries, selectedVisual, selectedQuery])

  //initial get queries
  useEffect(() => {

    async function getQueries() {
      if (!isAuthenticated || isLoading) return
      await updateQueryList();
    }

    if (!isAuthenticated || isLoading) return
    getQueries()
  }, [isAuthenticated, isLoading, updateQueryList])

  const runHandler = useCallback(async (code: any) => {
    async function errorHandler(e: any) {
      if ('detail' in e) {
        toast.error('Error running query. See results for details.')
        setDisplayData([{ error: e.detail, helika_row_id: '1' }])
        setDataHeaders([
          {
            headerName: 'Error',
            field: 'error',
            wrapText: true,
            autoHeight: true,
            minWidth: 100,
            sortable: false,
            flex: 1,
            headerClassName: 'error-datagrid-header',
          }
        ])
        setDisplayItem('data')
        setDatagridExpanded(true)
      } else {
        toast.error(e?.message)
      }
      setIsRunningQuery(false)
    }
    try {
      setIsRunningQuery(true)
      let resp: any = await runQuery(postWithAccessToken, { sql_query: code }, errorHandler)
      if (_.isEmpty(resp)) return
      let allData = [...resp.data];
      let columns = [...(Object.keys(allData[0]))]
      setDatasetColumns(columns)
      let headers = createHeadersFromColumnList(columns)
      let rowLabeledData = allData.map((row: any, index: number) => {
        let newProp: any = {};
        newProp[`helika_row_id`] = index;
        return Object.assign({}, row, newProp)
      })
      setDisplayData(rowLabeledData)
      setDataHeaders(headers)
      setIsRunningQuery(false)

    } catch (e) {
      errorHandler(e);
    }
  }, [postWithAccessToken])

  let extensions: any = [
    sql({
      dialect: PostgreSQL,
    }),
    theme.palette.mode === 'dark' ? coolGlow : tomorrow,
    EditorView.editable.of(false)
  ]

  let { CodeMirrorInstance } = CodeEditor({
    value: selectedCode,
    onChange: () => { },
    extensions: extensions
  })

  //when new selected visual or query, run query to get data
  useEffect(() => {

    async function runSelectedQuery() {
      if (_.isEmpty(selectedQuery.query)) return
      runHandler(selectedQuery.query)
      setSelectedCode(selectedQuery.query)
    }

    if (selectedQuery?.id) {
      if (selectedVisual?.id) {
        setDesignName(selectedVisual?.name)
      } else {
        setDesignName(selectedQuery?.name)
      }
      runSelectedQuery()
    } else {
      setDesignName(selectedVisual?.name ? selectedVisual?.name : 'New Visual')
    }
  }, [postWithAccessToken, runHandler, selectedQuery, selectedVisual, updateQueryList])

  //set selected visual
  async function visualClickHandler(visual: any) {
    setSelectedVisual(visual)
    setLastSaved(selectedVisual?.updated_at)
    setDesignName(visual?.name)
    let fullListQueries = orgQueries?.concat([...userQueries])
    if (!visual?.query_id || _.isEmpty(fullListQueries)) return
    let newSelectQuery = fullListQueries?.find(query => query.id === visual.query_id)
    setSelectedQuery(newSelectQuery)
  }

  function loadingComp(text: string) {
    return <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: 'auto'
      }}
    >
      <div style={{ textAlign: 'center', paddingBottom: '1em' }}>{text}</div>
      <LoadingComponent />
    </div>
  }

  useEffect(() => {
    let series: any = []
    if (!_.isEmpty(dataHeaders) && !(dataHeaders.length === 1 && dataHeaders[0].field === 'error')) {
      series = settings.checkedSeries?.map((serie: any, index: number) => {
        let opts: any = {
          name: serie,
          type: settings.visualType,
          stack: settings.stackType === 'Stacked' ? 'Total' : undefined,
          data: settings.visualType === visualTypes.pie
            ? displayData?.map((data: any) => {
              return {
                value: data[serie],
                name: _.isEmpty(settings.xAxis) ? data['helika_row_id'] : data[settings.xAxis]
              }
            })
            : displayData?.map((data: any) => data[serie])
        }
        if (settings.visualType === visualTypes.funnel) {
          opts.width = '80%';
          opts.sort = 'descending';
          opts.label = {
            show: settings.showingLegend === 'Show',
            position: 'inside'
          }
          opts.gap = 2;
          opts.data = displayData?.map((data: any) => {
            return {
              value: data[serie],
              name: (_.isEmpty(settings.xAxis) && _.isEmpty(data[settings.xAxis])) ? data['helika_row_id'] : data[settings.xAxis]
            }
          })
        }
        return opts;
      })?.filter((seriesOption: any) => settings.checkedSeries?.includes(seriesOption.name))
    }

    let options: any = {
      ...baseOptions,
      title: {
        ...baseOptions.title,
        text: designName?.trim() ? designName?.trim() : selectedVisual?.name,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
      backgroundColor: theme.palette.background.default,
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        feature: {
          saveAsImage: {},
          myFeature: {
            show: !_.isEmpty(displayData),
            title: 'Download Dataset',
            icon: `image://${DatasetIcon}`,
            onclick: downloadHandler
          }
        }
      },
      grid: {
        ...baseOptions.grid,
        left: '3%',
        right: '3%',
        top: _.isEmpty(series)
          ? 50
          : 90
        ,
      },
      xAxis:
        (_.isEmpty(series) || settings.visualType === visualTypes.funnel)
          ? {}
          :
          {
            type: 'category',
            boundaryGap: true,
            data: _.isEmpty(settings.x_axis) ? displayData['helika_row_id'] : displayData.map((item: any) => item[settings.x_axis])
          },
      yAxis: {
        type: 'value',
        alignTicks: true,
      },
      series: series
    }

    if (settings.showingLegend === 'Show') {
      options.legend = {
        data: settings.checkedSeries,
        textStyle: {
          color: theme.palette.text.primary
        },
        top: 40,
        type: 'scroll',
      }
    }

    if (![visualTypes.pie, visualTypes.funnel].includes(settings.visualType)) {
      if (!_.isEmpty(settings.xAxis)) {
        let newXAxis = displayData?.map((data: any) => data[settings.xAxis]);
        options.xAxis.data = newXAxis
      }

      if (settings.visualType === visualTypes.waterfall) {
        options.series = options.series?.slice(0, 2).map((serie: any) => {
          return Object.assign({}, serie, {
            type: visualTypes.bar,
            stack: 'Total'
          })
        })
        options.series[1] = Object.assign({}, options.series[1], {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          stack: 'Total'
        })
      }

      if (settings?.xAxisTicks === 'All') {
        options.xAxis.axisLabel = {
          interval: 0,
          rotate: 45
        }
      }
    } else {
      delete options.xAxis
      delete options.yAxis
      delete options.legend
    }

    //set echart
    setNewVisual(
      <div
        id='newVis'
        style={{
          margin: 'auto',
          height: '90%',
          maxWidth: '100%',
          overflow: 'auto',
          width: '100%'
        }}
      >
        <VisualDesignerEchart
          loading={false}
          options={options}
        />
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designName, dataHeaders, displayData, selectedQuery, settings, theme.palette.text.primary])

  return (
    <div
      id='explore-box'
      style={{
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
        height: '100%',
        maxHeight: '100%',
        scrollbarGutter: 'stable both-sides',
        minWidth: '70em',
      }}
    >
      <SavedVisualsModal
        open={isOpenSavedVisualsModal}
        setOpen={setIsOpenSavedVisualsModal}
        visuals={visuals}
        queries={userQueries?.concat([...orgQueries])}
        visualClickHandler={visualClickHandler}
      />
      <SaveVisualModal
        visualName={designName}
        setVisualName={setDesignName}
        queryCode={code}
        open={showSaveVisualModal}
        setOpen={setShowSaveVisualModal}
        setLastSaved={setLastSaved}
        updateQueryListAndSchemaList={updateQueryList}
        selectedQuery={selectedQuery}
        settings={settings}
        newVisual={newVisual}
      />
      <DeleteVisualModal
        selectedVisual={selectedVisual}
        open={showDeleteVisualModal}
        setOpen={setShowDeleteVisualModal}
        updateQueryListAndSchemaList={updateQueryList}
        newVisual={newVisual}
        setSelectedQuery={setSelectedQuery}
        setSelectedVisual={setSelectedVisual}
      />

      <ExplorerHeader />
      <div
        style={{
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: 'calc(100% - 4.5em)',
        }}
      >
        <Box
          sx={{
            padding: '0.5em 0 0.5em 0.5em'
          }}
        >
          <LineChartSettingsBox
            selectedVisual={selectedVisual}
            settings={settings}
            setSettings={setSettings}
            datasetColumns={datasetColumns}
            selectedQuery={selectedQuery}
          />
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 'calc(100% - 15em)',
            paddingBottom: '0.5em',
            flex: 1
          }}
        >
          <div
            id='header-box'
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: '100%',
              maxWidth: '100%',
              padding: '1em 0.5em 0.5em 0.5em',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '-0.8em'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: '1',
                maxWidth: '100%',
                fontSize: '1.2em',
                marginTop: '0.5em'
              }}
            >
              <div
                style={{
                  color: newColor.jazzberryJam,
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0.5em',
                  lineHeight: 1,
                  width: '8em',
                  textAlign: 'left'
                }}
              >
                Visual Designer
              </div>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0',
                  lineHeight: 1,
                  textAlign: 'left',
                  fontFamily: 'open sans'
                }}
              >
                {`>`}
              </div>
              <div
                id='outer-input-div'
                style={{
                  display: 'flex',
                  color: 'lightgray',
                  flex: 1,
                  textAlign: 'left',
                  width: 'fit-content',
                  maxWidth: 'calc(100% - 9em)',
                  paddingLeft: '0.5em',
                  margin: 0
                }}
              >
                <input
                  style={{
                    background: newColor.transparent,
                    outline: 'none',
                    boxShadow: 'none',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                    marginTop: '-0.1em',
                    width: valueWidth + 'ch',
                    minWidth: '8.7em',
                    lineHeight: 1,
                    fontSize: '0.9em',
                    marginRight: '0.5em',
                    borderRadius: '0.1em',
                    backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : 'white',
                    border: '1px solid ',
                    borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
                    color: theme.palette.text.primary,
                    padding: '0 1em 0 1em',
                  }}
                  type='text'
                  placeholder={'Enter Query Name here...'}
                  value={designName}
                  onChange={(e) => {
                    setDesignName(e.target.value);
                    setValueWidth(e.target.value?.length);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur()
                    }
                  }}
                />
              </div>
            </div>
            <div
              id='top-buttons'
              style={{
                display: 'flex',
                marginTop: '0.5em',
                flexWrap: 'nowrap'
              }}
            >
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.3em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: _.isEmpty(visuals) ? newColor.darkGunmetal : theme.palette.text.primary,
                  fontSize: '0.8em',
                  fontWeight: '600',
                  cursor: _.isEmpty(visuals) ? 'not-allowed' : 'pointer',
                  marginRight: '0.5em'
                }}
                onClick={() => {
                  if (_.isEmpty(visuals)) return
                  setIsOpenSavedVisualsModal(true)
                }}
              >
                <StyledSvg
                  sx={{
                    '& svg, & path': {
                      fill: _.isEmpty(visuals) ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                      stroke: _.isEmpty(visuals) ? newColor.darkGunmetal : (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                    },
                  }}
                >
                  <OpenFolder />
                </StyledSvg>
                <div
                  style={{
                    margin: '0.1em 0 0 0.5em'
                  }}
                  className='truncate-ellipsis'
                >
                  SAVED VISUALS
                </div>
              </Button>
              <VisualDesignerNewVisualMenu
                userQueries={userQueries}
                orgQueries={orgQueries}
                selectedQuery={selectedQuery}
                queryOnClickHandler={(query: any) => {
                  setSelectedQuery(query)
                  setSelectedVisual(undefined)
                  setDesignName(query.name)
                }}
              />
              <VisualDesignerSaveMenu
                selectedVisual={selectedVisual}
                showSaveVisualModal={setShowSaveVisualModal}
                updateVisualHandler={updateVisualizationHandler}
                showDeleteVisualModal={setShowDeleteVisualModal}
                selectedQuery={selectedQuery}
              />
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '0.9em',
                margin: `0.5em 0 ${!_.isEmpty(lastSaved) ? '-0.5em' : '-1em'} 0`,
                color: 'gray',
                height: !_.isEmpty(lastSaved) ? 'fit-content' : '0em'
              }}
            >
              {selectedQuery && lastSaved && `Last Saved: ${moment(lastSaved).format('YYYY-MM-DD hh:mm:ss A')} UTC`}
            </div>
          </div>
          <div
            id={`explorediv`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              height: '50%',
              padding: '0 0.5em 0 0.5em',
              maxHeight: 'calc(100% - 3.2em)'
            }}
            className={`explorediv-${theme.palette.mode}`}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.mode === 'dark' ? 'black' : 'white',
                marginTop: '0.5em',
                padding: '0',
                minHeight: '10em',
                border: 'solid',
                borderRadius: '1em',
                borderColor: newColor.darkGunmetal,
                height: `calc(100% - ${datagridExpanded ? '10.7em' : '2.5em'})`
              }}
            >
              {
                isRunningQuery
                  ?
                  <LoadingComponent />
                  :
                  newVisual
                    ? newVisual
                    : <div style={{ margin: 'auto' }}>No visual to show</div>
              }
            </Box>
            <div
              id='data_outer'
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '40%',
                width: '100%',
                minWidth: `calc(100% - ${isDrawerOpen ? drawerWidth : closeDrawerWidth} + 4em)`,
                maxHeight: datagridExpanded ? `calc(100% - 29.5em)` : '1.5em',
                maxWidth: `calc(100vw - 16em)`
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '2.5em',
                  fontSize: '0.8em',
                  justifyContent: 'end',
                  padding: '0.3em 0 0.7em 0',
                }}
              >
                <VisualDesignerShowMenu
                  disabled={!selectedQuery?.id}
                  displayItem={displayItem}
                  datagridExpanded={datagridExpanded}
                  selectedQuery={selectedQuery}
                  setDisplayItem={setDisplayItem}
                  setDatagridExpanded={setDatagridExpanded}
                />
              </div>
              {
                (datagridExpanded && displayItem !== 'code')
                  ? isRunningQuery
                    ?
                    loadingComp('Running Query...')
                    :
                    <DataGridPro
                      disableColumnMenu
                      rows={displayData}
                      columns={dataHeaders}
                      pagination //todo: add pagination to all datagrid
                      getRowId={(row: any) => {
                        return row.helika_row_id
                      }}
                      pinnedColumns={{ left: ['helika_row_id'] }}
                      autosizeOnMount={true}
                      getRowHeight={() => 'auto'}
                      sx={{
                        background: theme.palette.mode === 'dark' ? 'black' : 'white',
                        '& .MuiDataGrid-columnHeaders': {
                          display: _.isEmpty(dataHeaders) ? 'none' : 'inline'
                        },
                        marginTop: '0.5em',
                        padding: '0',
                        minHeight: '9em',
                        border: 'solid',
                        borderRadius: '1em',
                        borderColor: newColor.darkGunmetal,
                      }}
                      style={{
                        padding: 0,
                        width: `calc(100vw - 25em)`
                      }}
                      slots={{
                        footer:
                          () =>
                            <GridFooterContainer
                              sx={{
                                justifyContent: 'space-between'
                              }}
                            >
                              {
                                displayData?.length >= 2000
                                  ?
                                  <div
                                    style={{
                                      margin: 'auto 1em auto 0',
                                      paddingLeft: '0.2em',
                                      color: theme.palette.mode === 'dark' ? 'yellow' : 'red',
                                      minWidth: '2em'
                                    }}
                                    className='truncate-ellipsis'
                                  >
                                    * Results truncated to 2000 rows
                                  </div>
                                  : <div />
                              }
                              <GridFooter sx={{
                                justifySelf: 'end',
                                border: 'none', // To delete double border.
                              }} />
                            </GridFooterContainer>
                      }}
                    />
                  : null
              }
              <div
                id='visual-designer-codemirror'
                style={{
                  display: (datagridExpanded && displayItem === 'code') ? 'flex' : 'none',
                  width: '100%',
                  minHeight: '3em',
                  maxHeight: `calc(100% - 2.4em)`,
                  marginTop: '0.2em',
                  height: '100%'
                }}
              >{CodeMirrorInstance}</div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};