//imports
import React from 'react'

//components
import { Dialog, useTheme, DialogTitle, Divider } from '@mui/material'
import { newColor } from '../../consts/colors'

//assets
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import AnalyticsIcon from '../../assets/analytics_icon.svg'
import GameManagementIcon from '../../assets/game_management_icon.svg'
import SupportIcon from '../../assets/support_icon.svg'
import AccountManagementIcon from '../../assets/account_management_icon.svg'

import _ from 'lodash'
import { Box } from '@mui/system'
import AnnouncementBanner from '../organisms/v2/AnnouncementPanel/AnnouncementBanner'
import { GridCloseIcon } from '@mui/x-data-grid'
import { DOMAIN_TYPES } from 'src/state/action-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  setData,
  useGetDrawer,
  useGetInitialStateData,
} from '../../utils/drawer'
import DomainNavigationList from '../organisms/v2/AnnouncementPanel/DomainNavigationList'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
}

export default function AnnouncementModal(props: SimpleDialogProps) {
  const { open, setOpen } = props
  const theme: any = useTheme()
  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)
  const USER_DATA = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const isDrawerOpen = useGetDrawer()
  //initial state of tabs
  let initialStateAnalytics = useGetInitialStateData(DOMAIN_TYPES.ANALYTICS)
  let initialStateGameManagement = useGetInitialStateData(
    DOMAIN_TYPES.GAME_MANAGEMENT,
  )
  let initialStateSupport = useGetInitialStateData(DOMAIN_TYPES.SUPPORT)
  let initialStateAccountManagement = useGetInitialStateData(
    DOMAIN_TYPES.ACCOUNT_MANAGEMENT,
  )

  async function clickDrawer() {
    let subTabsInitialState = initialStateAnalytics
    switch (DOMAIN) {
      case DOMAIN_TYPES.ANALYTICS: {
        subTabsInitialState = initialStateAnalytics
        break
      }
      case DOMAIN_TYPES.GAME_MANAGEMENT: {
        subTabsInitialState = initialStateGameManagement
        break
      }
      case DOMAIN_TYPES.SUPPORT: {
        subTabsInitialState = initialStateSupport
        break
      }
      case DOMAIN_TYPES.ACCOUNT_MANAGEMENT: {
        subTabsInitialState = initialStateAccountManagement
        break
      }
    }
    setData(
      DOMAIN,
      {
        MAIN_DRAWER_OPEN: isDrawerOpen ? false : true,
        subTabs: subTabsInitialState,
      },
      dispatch,
    )
  }

  let options: any = [...Object.values(DOMAIN_TYPES)]
  // Ask Me: We will implement later this A/B Testing & AI
  // if (['demo', 'demo_japanese'].includes(USER_DATA?.organization_name)) {
  //   options = options.concat('A/B Testing (Coming Soon)')
  //   options = options.concat('AI Models (Coming Soon)')
  // }

  let disabledOptions = [false, false, false, true, true, true]
  if (
    USER_DATA?.module_access_level &&
    '4' in USER_DATA?.module_access_level &&
    USER_DATA?.module_access_level['4'] === 'editor'
  ) {
    disabledOptions[3] = false
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          width: '80vw',
        },
      }}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', padding: '0px' }}
      >
        <Box
          sx={{
            ml: 'auto',
            paddingBottom: '0px',
            cursor: 'pointer',
            paddingTop: '1rem',
            paddingRight: '1.5rem',
          }}
          onClick={handleClose}
        >
          <GridCloseIcon />
        </Box>
      </DialogTitle>
      <Box>
        <AnnouncementBanner />
        <Divider sx={{ marginY: '2.5rem' }} />
        <DomainNavigationList
          options={options}
          clickDrawer={clickDrawer}
          drawerOpen={isDrawerOpen}
          handleClose={handleClose}
          icons={[
            <Box
              component="img"
              sx={{
                marginRight: '0.7em',
              }}
              alt=""
              width={26}
              src={AnalyticsIcon}
            />,
            <Box
              component="img"
              sx={{
                marginRight: '0.5em',
              }}
              alt=""
              width={26}
              src={GameManagementIcon}
            />,
            <Box
              component="img"
              sx={{
                marginRight: '0.6em',
              }}
              alt=""
              width={26}
              src={SupportIcon}
            />,
            <Box
              component="img"
              sx={{
                marginRight: '0.7em',
              }}
              alt=""
              width={26}
              src={AccountManagementIcon}
            />,
            <Box
              component="img"
              sx={{
                marginRight: '0.5em',
              }}
              alt=""
              width={26}
              src={GameManagementIcon}
            />,
            <Box
              component="img"
              sx={{
                marginRight: '0.7em',
              }}
              alt=""
              width={26}
              src={AnalyticsIcon}
            />,
          ]}
          disabled={disabledOptions}
        ></DomainNavigationList>
      </Box>
    </Dialog>
  )
}
