import React, { useContext, useState } from 'react'
import {
  Stack,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import { updateCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import dayjs from 'dayjs'
import ReusableSnackbar from './ReusableSnackbar'

// Status indicator dot styling
const StatusDot = styled('div')<{ color: string }>(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '6px',
}))

// Mapping of statuses to corresponding colors and labels
const statusColors: { [key: string]: string } = {
  not_started: newColor.oceanBlue,
  active: newColor.green,
  finished: newColor.electricPurple,
  archived: newColor.jet,
}

const statusLabels: { [key: string]: string } = {
  not_started: 'Not Started',
  active: 'Active',
  finished: 'Finished',
  archived: 'Archived',
}

// Common styles for text elements with dynamic theming
const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
})

const StyledSelect = styled(Select)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    // Select field styling
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    width: '300px',
    height: '36px',
    backgroundColor: themeMode === 'dark' ? '#1C2025' : newColor.whiteSmoke,
    border: '1px solid #434D5A',
    borderRadius: '4px',
    paddingLeft: '8px',
    '& fieldset': {
      border: 'none',
    },
    '.MuiSelect-select': {
      paddingLeft: '8px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

interface StatusRowProps {
  campaign: {
    id: number
    name: string
    startDate: string
    endDate: string
    status: string
  }
  onChangeStatus: (newStatus: string) => void
  setTableLoading: (loading: boolean) => void
}

const StatusRow: React.FC<StatusRowProps> = ({
  campaign,
  onChangeStatus,
  setTableLoading,
}) => {
  const { putWithAccessToken } = useContext(Auth0Context)
  const theme = useTheme()
  const themeMode = theme.palette.mode

  // State to manage Snackbar visibility and message
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  // Format date to ISO 8601
  const formatDateTime = (date: string) => dayjs(date).toISOString()

  const handleStatusChange = async (event: SelectChangeEvent<unknown>) => {
    const newStatus = event.target.value as string

    try {
      const payload = {
        name: campaign.name,
        status: newStatus,
        start_date: formatDateTime(campaign.startDate),
        end_date: formatDateTime(campaign.endDate),
      }

      setTableLoading(true)
      // API call to update campaign status
      await updateCampaign(putWithAccessToken, campaign.id, payload)
      setTableLoading(false)

      // Show success message using the ReusableSnackbar
      setSnackbarMessage('Campaign updated successfully!')
      setSnackbarVariant('success')
      onChangeStatus(newStatus)

      setSnackbarOpen(true)
    } catch (error: any) {
      console.error('Complete error object:', error)

      // Set general error message
      let errorMessageToShow = 'An error occurred while updating the campaign.'

      if (error?.response?.data?.detail) {
        errorMessageToShow = 'Please check the details and try again.'
      }

      setSnackbarMessage(errorMessageToShow)
      setSnackbarVariant('error')
      setSnackbarOpen(true)

      setTableLoading(false)
    }
  }

  return (
    <>
      {/* Select dropdown for status */}
      <StyledSelect
        value={campaign.status}
        onChange={handleStatusChange}
        themeMode={themeMode}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiList-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
              },
            },
          },
        }}
      >
        {Object.keys(statusColors).map((statusKey) => (
          <MenuItem
            value={statusKey}
            key={statusKey}
            sx={{
              padding: '8px 16px',
              backgroundColor:
                themeMode === 'dark' ? newColor.darkSlate : newColor.whiteSmoke,
              '&:hover': {
                backgroundColor:
                  themeMode === 'dark' ? newColor.gunMetal : newColor.lightGray,
              },
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            <Stack direction="row" alignItems="center">
              <StatusDot color={statusColors[statusKey]} />
              <Typography
                sx={{ ...commonTextStyles(themeMode), paddingLeft: '8px' }}
              >
                {statusLabels[statusKey]}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </StyledSelect>

      {/* ReusableSnackbar component */}
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </>
  )
}

export default StatusRow
