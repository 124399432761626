export const tabs = {
    MY_ECOSYSTEM: "My Ecosystem",
    IN_GAME_ANALYTICS: "In-Game Analytics",
    SOCIAL_ANALYTICS: "Social Analytics",
    CUSTOM: "Custom",
    COMPETITIVE_ANALYTICS:"Competitive Analytics"
}

export const subTabs ={
    HOLDER_INSIGHTS: "Holder's Insights",
    FINANCIAL_HEALTH: "Financial Health",
    CUSTOM_EXTRACTS: "Custom Extracts",
    IN_GAME_ANALYTICS: "In-Game Analytics",
    SOCIAL_ANALYTICS: "Social Analytics",
    AD_HOC_ANALYSIS: "Ad-Hoc Analysis",
    COMPARATIVE_ANALYTICS: "Comparative Analytics",
    OVERLAP_ANALYSIS: "Overlap Analysis",
}