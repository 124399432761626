import React, { useState } from 'react'
import {
  Button,
  Typography,
  Popover,
  Stack,
  MenuItem,
  Select,
  IconButton,
  Badge,
  useTheme,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'
import { color_base } from 'helika-ui-sdk/dist/src/values/Colors'

// Styled button, icons, and popover elements
const FilterButtonWrapper = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '110px',
    minWidth: '110px',
    height: '36px',
    border: '1px solid #434D5A',
    borderRadius: '4px',
    textTransform: 'none',
    background: themeMode === 'dark' ? newColor.charcoal : newColor.transparent,
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    gap: '8px',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.darkGunmetal : newColor.lightGray,
    },
    ...typography.label_regular, // Apply label_regular typography
  }),
)

// Styled popover container with dynamic theme
const PopoverContainer = styled(Popover)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    '.MuiPopover-paper': {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 16px',
      background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
      boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      border: 'none',
      minWidth: 'auto',
      maxWidth: 'auto',
    },
  }),
)

// Filter container with dynamic theme
const FilterContainer = styled(Stack)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
    background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
  }),
)

// Fixed width for category dropdown (141px)
const CategorySelectField = styled(Select)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
    width: '150px',
    height: '36px',
    background: themeMode === 'dark' ? '#1C2025' : newColor.white,
    border: `1px solid ${themeMode === 'dark' ? '#434D5A' : '#C0C0C0'}`,
    borderRadius: '4px',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    fontFamily: 'Open Sans, sans-serif',
    '& fieldset': {
      border: 'none',
    },
    '.MuiSelect-select': {
      padding: '8px 12px 8px 12px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

// Fixed width for condition dropdown (90px)
const ConditionSelectField = styled(Select)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
    width: '106px',
    height: '36px',
    background: themeMode === 'dark' ? '#1C2025' : newColor.white,
    border: `1px solid ${themeMode === 'dark' ? '#434D5A' : '#C0C0C0'}`,
    borderRadius: '4px',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    fontFamily: 'Open Sans, sans-serif',
    '& fieldset': {
      border: 'none',
    },
    '.MuiSelect-select': {
      padding: '8px 12px 8px 12px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

// Fixed width for value dropdown (141px)
const ValueSelectField = styled(Select)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
    width: '150px',
    height: '36px',
    background: themeMode === 'dark' ? '#1C2025' : newColor.white,
    border: `1px solid ${themeMode === 'dark' ? '#434D5A' : '#C0C0C0'}`,
    borderRadius: '4px',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    fontFamily: 'Open Sans, sans-serif',
    '& fieldset': {
      border: 'none',
    },
    '.MuiSelect-select': {
      padding: '8px 12px 8px 12px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

// Styled "Clear All" button with dynamic theme
const ClearAllButton = styled(Typography)<{
  themeMode: 'dark' | 'light'
  disabled: boolean
}>(({ themeMode, disabled }) => ({
  minWidth: '110px',
  fontSize: '14px',
  color: disabled
    ? themeMode === 'dark'
      ? newColor.darkGunmetal
      : newColor.lightGray
    : themeMode === 'dark'
      ? newColor.white
      : newColor.black,
  cursor: disabled ? 'not-allowed' : 'pointer',
  textAlign: 'center',
  pointerEvents: disabled ? 'none' : 'auto',
}))

// Styled "Add Filter" button with dynamic theme
const AddFilterTextButton = styled(Typography)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    minWidth: '110px',
    fontSize: '14px',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    cursor: 'pointer',
    textAlign: 'center',
    display: 'inline-flex',
    gap: '4px',
    '&::before': {
      content: '"+"',
      display: 'inline-block',
      color: themeMode === 'dark' ? newColor.white : newColor.black,
    },
  }),
)

interface FilterButtonProps {
  filterOptions: {
    categories?: string[]
    conditions: string[]
    values?: string[]
    valuesByCategory?: { [category: string]: string[] }
  }
  showAddFilterButton?: boolean
  onApplyFilter?: (filters: any) => void
}

const FilterButton: React.FC<FilterButtonProps> = ({
  filterOptions: { categories = [], conditions, values, valuesByCategory },
  showAddFilterButton = false,
  onApplyFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filters, setFilters] = useState([
    {
      selectedFilterBy: '',
      selectedCondition: 'is',
      selectedValue: '',
    },
  ])

  const theme = useTheme()
  const themeMode = theme.palette.mode

  // Open and close popover
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null)
  const open = Boolean(anchorEl)

  // Count how many filters are applied
  const appliedFilterCount = filters.filter(
    (filter) =>
      filter.selectedFilterBy !== '' &&
      filter.selectedValue !== '' &&
      filter.selectedCondition !== '',
  ).length

  // Apply filters whenever a dropdown changes
  const handleFilterChange = (
    index: number,
    key: 'selectedFilterBy' | 'selectedCondition' | 'selectedValue',
    value: string,
  ) => {
    const updatedFilters = [...filters]

    // If the user changes the category (selectedFilterBy), reset the condition and value with default options
    if (key === 'selectedFilterBy') {
      const defaultValue =
        valuesByCategory && valuesByCategory[value]
          ? valuesByCategory[value][0]
          : values?.[0] || ''

      updatedFilters[index] = {
        selectedFilterBy: value,
        selectedCondition: conditions[0],
        selectedValue: defaultValue,
      }
    } else {
      updatedFilters[index][key] = value
    }

    setFilters(updatedFilters)

    // Call the onApplyFilter callback directly after change
    if (onApplyFilter) {
      const filterPayload = updatedFilters.map((filter) => ({
        by: filter.selectedFilterBy,
        operator: filter.selectedCondition,
        value: filter.selectedValue.replace(/\s+/g, '_').toLowerCase(),
      }))
      onApplyFilter(filterPayload)
    }
  }

  // Add a new filter row
  const handleAddFilter = () => {
    setFilters([
      ...filters,
      {
        selectedFilterBy: '',
        selectedCondition: 'is',
        selectedValue: '',
      },
    ])
  }

  // Remove a filter or reset its values if it's the last filter
  const handleRemoveFilter = (index: number) => {
    if (filters.length === 1) {
      setFilters([
        {
          selectedFilterBy: '',
          selectedCondition: 'is',
          selectedValue: '',
        },
      ])
      if (onApplyFilter) onApplyFilter([])
    } else {
      // Remove the filter at the specified index
      const updatedFilters = filters.filter((_, i) => i !== index)
      setFilters(updatedFilters)

      // Trigger API call with updated filters
      if (onApplyFilter) {
        const filterPayload = updatedFilters.map((filter) => ({
          by: filter.selectedFilterBy,
          operator: filter.selectedCondition,
          value: filter.selectedValue.replace(/\s+/g, '_').toLowerCase(),
        }))
        onApplyFilter(filterPayload)
      }
    }
  }

  // Clear all filters and notify parent
  const handleClearAll = () => {
    setFilters([
      {
        selectedFilterBy: '',
        selectedCondition: 'is',
        selectedValue: '',
      },
    ])
    if (onApplyFilter) onApplyFilter([])
  }

  // Disable Clear All when filters are at default values
  const isClearAllDisabled =
    filters.length === 1 &&
    filters[0].selectedValue === '' &&
    filters[0].selectedFilterBy === '' &&
    filters[0].selectedCondition === 'is'

  return (
    <>
      {/* Filter button with badge for the applied filters count */}
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: color_base.brand_primary_solid,
            color: newColor.white, // Assuming you want the badge text in white
          },
        }}
        badgeContent={appliedFilterCount > 0 ? appliedFilterCount : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FilterButtonWrapper onClick={handleClick} themeMode={themeMode}>
          <FilterListIcon
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          />
          <Typography
            sx={{
              ...typography.label_regular,
              textAlign: 'center',
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            Filter
          </Typography>
        </FilterButtonWrapper>
      </Badge>

      {/* Popover with filter options */}
      <PopoverContainer
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        themeMode={themeMode}
      >
        <FilterContainer themeMode={themeMode}>
          <Typography
            sx={{
              ...typography.label_regular,
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              whiteSpace: 'nowrap',
            }}
          >
            Filter by
          </Typography>

          {/* Render all the dynamic filters */}
          {filters.map((filter, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              {/* Category dropdown with fixed width */}
              <CategorySelectField
                value={filter.selectedFilterBy}
                onChange={(e) =>
                  handleFilterChange(
                    index,
                    'selectedFilterBy',
                    e.target.value as string,
                  )
                }
                themeMode={themeMode}
                displayEmpty
                renderValue={(selected) =>
                  selected === '' ? 'Select' : (selected as string)
                }
              >
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </CategorySelectField>

              {/* Condition dropdown with fixed width */}
              <ConditionSelectField
                value={filter.selectedCondition}
                onChange={(e) =>
                  handleFilterChange(
                    index,
                    'selectedCondition',
                    e.target.value as string,
                  )
                }
                themeMode={themeMode}
              >
                {conditions.map((condition) => (
                  <MenuItem key={condition} value={condition}>
                    {condition}
                  </MenuItem>
                ))}
              </ConditionSelectField>

              {/* Value dropdown with fixed width */}
              <ValueSelectField
                value={filter.selectedValue}
                onChange={(e) =>
                  handleFilterChange(
                    index,
                    'selectedValue',
                    e.target.value as string,
                  )
                }
                themeMode={themeMode}
                displayEmpty
                renderValue={(selected) =>
                  selected === '' ? 'Select value' : (selected as string)
                }
              >
                {valuesByCategory && filter.selectedFilterBy !== ''
                  ? valuesByCategory[filter.selectedFilterBy].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))
                  : null}
              </ValueSelectField>

              {/* Remove filter button */}
              <IconButton onClick={() => handleRemoveFilter(index)}>
                <CloseIcon
                  sx={{
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                  }}
                />
              </IconButton>
            </Stack>
          ))}

          {/* Bottom action buttons */}
          <Stack
            direction="row"
            justifyContent={showAddFilterButton ? 'space-between' : 'center'}
            alignItems="center"
            sx={{ width: '100%', mt: 1, mb: 1, px: 3 }}
          >
            {/* Add filter button */}
            {showAddFilterButton && (
              <AddFilterTextButton
                onClick={handleAddFilter}
                themeMode={themeMode}
              >
                <Typography
                  sx={{
                    ...typography.label_regular,
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Add Filter
                </Typography>
              </AddFilterTextButton>
            )}
            {/* Clear all button */}
            <ClearAllButton
              onClick={handleClearAll}
              disabled={isClearAllDisabled}
              themeMode={themeMode}
            >
              <Typography
                sx={{
                  ...typography.label_regular,
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  whiteSpace: 'nowrap',
                }}
              >
                Clear All
              </Typography>
            </ClearAllButton>
          </Stack>
        </FilterContainer>
      </PopoverContainer>
    </>
  )
}

export default FilterButton
