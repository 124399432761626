import ArrowDownIcon from 'src/assets/arrow_down_icon.svg'

import { createContext, useContext, useState } from 'react'
import { Box, Collapse } from '@mui/material'
import styled from 'styled-components'

const ToggleTabContext = createContext<{
  isCollapsed: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

const useToggleTab = () => {
  try {
    const context = useContext(ToggleTabContext)
    if (!context) {
      throw new Error('Context have to be put under ToogleTabProvider')
    }

    return context
  } catch (err) {
    console.log(err)
    return {
      isCollapsed: false,
      toggle: () => (state: any) => state,
    }
  }
}

export function ToggleTab({ children }: { children: React.ReactNode }) {
  const [isCollapsed, toggle] = useState(false)

  return (
    <ToggleTabContext.Provider value={{ isCollapsed, toggle }}>
      {children}
    </ToggleTabContext.Provider>
  )
}

function ToggleButton() {
  const { toggle, isCollapsed } = useToggleTab()

  return (
    <div onClick={() => toggle((prev) => !prev)}>
      <img
        src={ArrowDownIcon}
        alt="toggle"
        style={{ rotate: isCollapsed ? '180deg' : '0deg' }}
      />
    </div>
  )
}

const ParentTabWrapper: any = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

function ParentTab({ children }: { children: React.ReactNode }) {
  return <ParentTabWrapper>{children}</ParentTabWrapper>
}

function ChildTab({ children }: { children: React.ReactNode }) {
  const { isCollapsed } = useToggleTab()
  return <Collapse in={isCollapsed}>{children}</Collapse>
}

ToggleTab.ParentTab = ParentTab
ToggleTab.ToggleButton = ToggleButton
ToggleTab.ChildTab = ChildTab
