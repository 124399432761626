const initialState = {
  MAIN_DRAWER_OPEN: false,
  TAB_STATE: [],
  SUBTABS_STATE: {}
};

export default function drawerReducer(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case "CLEAR_DATA":
      return initialState;
    case 'OPEN_DRAWER':
      newState.MAIN_DRAWER_OPEN = true;
      return newState;
    case 'CLOSE_DRAWER':
      newState.MAIN_DRAWER_OPEN = false;
      return newState;
    case "SET_ANALYTICS_DATA":
      if ('MAIN_DRAWER_OPEN' in action.payload) {
        newState.MAIN_DRAWER_OPEN = action.payload.MAIN_DRAWER_OPEN
      }
      if ('TAB_STATE' in action.payload) {
        newState.TAB_STATE = action.payload.TAB_STATE;
      }
      if ('subTabs' in action.payload) {
        let newSubtabState = Object.assign({}, state.SUBTABS_STATE);
        if (typeof action.payload.subTabs === 'object') {
          newSubtabState = action.payload.subTabs;
        } else {
          if (action.payload.subTabs in newSubtabState) {
            newSubtabState[action.payload.subTabs] = !state.SUBTABS_STATE[action.payload.subTabs]
          } else {
            newSubtabState[action.payload.subTabs] = true;
          }
        }
        newState.SUBTABS_STATE = newSubtabState;
      }
      return Object.assign({}, state, newState)
    default:
      return state;
  }
};