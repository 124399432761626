import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import AuthContextProvider from './contexts/Auth0Context'
import 'react-tooltip/dist/react-tooltip.css'
import { Provider } from 'react-redux'
import { store } from './state'
import { LicenseInfo } from '@mui/x-license-pro'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE!)

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
  //init sentry
  Sentry.init({
    dsn: 'https://35f3880ee3d16d8f2e821f49673b3955@o4505233606246400.ingest.sentry.io/4506236305604608',
    maxBreadcrumbs: 50,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/helika\.io/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tunnel: `${process.env.REACT_APP_HELIKA_API_URL}/v1/sentry/tunnel`,
  })
  Sentry.setTag('environment_tag', process.env.REACT_APP_DEV_MODE!)
}

root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        scope: 'openid email profile',
      }}
    >
      <Provider store={store}>
        <AuthContextProvider>
          <BrowserRouter>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              //rtl={false}
              //pauseOnFocusLoss
              //draggable
              pauseOnHover={false}
              style={{ padding: '0px' }}
              theme={'colored'}
            />
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </Provider>
    </Auth0Provider>
  </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
