import React, { useState } from 'react'
import { Stack, useTheme } from '@mui/material'
import { useGetDrawer } from 'src/utils/drawer'
import { newColor } from 'src/consts/colors'
import { RoundGradientWrapper } from './DrawerLogo.styled'
// assets
import HelikaIconButton from 'src/assets/helikaIconButton.svg'
import HelikaIconButtonHover from 'src/assets/helikaIconButtonHover.svg'
import HelikaTextLogo from 'src/assets/text_logo.svg'

export interface DrawerLogoProps {
  onClick: () => void
}

const DrawerLogo: React.FC<DrawerLogoProps> = ({ onClick }) => {
  const theme = useTheme()
  const isDrawerOpen = useGetDrawer()
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <Stack
      id="drawer-logo"
      direction="row"
      alignItems="center"
      justifyContent={isDrawerOpen ? 'flex-start' : 'center'}
      sx={{
        width: '100%',
        background:
          theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        cursor: 'pointer',
        my: 2,
        ...(isDrawerOpen && { mx: 1, px: '20px' }),
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <RoundGradientWrapper>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
            p: isDrawerOpen ? '8px 12px' : '8px',
            borderRadius: '50px',
          }}
        >
          {isHovered ? (
            <img src={HelikaIconButtonHover} alt="" />
          ) : (
            <img src={HelikaIconButton} alt="" />
          )}
          {isDrawerOpen && <img src={HelikaTextLogo} alt="" />}
        </Stack>
      </RoundGradientWrapper>
    </Stack>
  )
}

export default DrawerLogo
