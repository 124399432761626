import _ from 'lodash';
import * as XLSX from 'xlsx';
const EXTENSIONS = ['xlsx', 'xls', 'csv'];


export const importExcel = (e: any, setColumns: any, setRows: any, setTitle: any, sheetName?: string, columns?: any) => {

  let file = e;
  if (file && 'target' in file) {
    file = e.target.files[0];
  }

  setObjectToDisplayExcel(file, setColumns, setRows, setTitle, sheetName, columns);
}

export async function setObjectToDisplayExcel(file: any, setColumns: any, setRows: any, setTitle: any, sheetName?: string, columns?: any) {

  if (!file) {
    setTitle('')
    setColumns([]);
    setRows([]);
    return;
  }

  const reader = new FileReader()
  reader.onload = (event) => {
    //parse data

    const bstr = event?.target?.result;
    const workBook = XLSX.read(bstr, { type: "binary" })

    //get first sheet
    const workSheetName = sheetName ? sheetName : workBook.SheetNames[0];
    const workSheet = workBook.Sheets[workSheetName]
    setTitle(workSheetName)
    //convert to array
    const fileData: any[] = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
    const headers: any[] = fileData[0];

    let arrayLength = Array.from(Array(headers.length).keys());

    let tempheaders = arrayLength.map((head: any, index: number) => headers[index]?.length > 0 ? headers[index] : `empty_${index}`).map((head, index) => {
      return {
        headerName: headers[index]?.length > 0 ? head : ``,
        field: head,
        filter: true,
        flex: 1
      }
    });

    // filter out headers not in the sheet mappings columns array
    let filteredOutHeaders = tempheaders;
    if (!_.isEmpty(columns)) {
      filteredOutHeaders = tempheaders.filter((header: any) => columns.includes(header.field));
    }

    setColumns(filteredOutHeaders);

    //removing header
    fileData.splice(0, 1)

    let tempData: any = [];

    fileData.forEach((row: any) => {
      let newRowData: any = {};
      tempheaders.forEach((header: any, index: number) => {

        //filter out columns that aren't in sheet mapping
        if (!filteredOutHeaders.includes(header)) return;

        let formattedCell = row[index] !== undefined ? row[index] : null;
        if (typeof formattedCell === 'string' && formattedCell.trim().length === 0 && !(formattedCell.length > 0 && formattedCell.trim().length === 0)) formattedCell = null;
        newRowData[header.field.toString()] = formattedCell;
      })
      if (!_.isEmpty(row)) tempData.push(newRowData);
    });
    setRows(tempData);
  }
  if (file) {
    if (getExention(file)) {
      reader.readAsBinaryString(file)
    } else {
      alert("Invalid file input, Select Excel, CSV file")
    }
  }
}


const getExention = (file: any) => {
  const parts = file.name.split('.')
  const extension = parts[parts.length - 1]
  return EXTENSIONS.includes(extension) // return boolean
}


//const convertToJson = (headers:any[], data:any[]) => {
//  const rows:any[] = []
//  data.forEach((row:any) => {
//    let rowData:any[] = [];
//    row.forEach((element:any, index:number) => {
//      rowData[headers[index]] = element
//    })
//    rows.push(rowData)

//  });
//  return rows
//}

export function getNameFromS3Key(config: any) {
  if (!config) return '';
  let filename = config?.config_s3_key;
  let filename_parts = filename.split('/');
  if (filename_parts.length > 0) {
    filename = filename_parts[filename_parts.length - 1];
  }
  return filename;
}

export function parseSheetMappingAliases(column_aliases: any[]) {
  let response: any = {
    sheet_columns: [],
    table_columns: []
  };
  column_aliases.forEach((pair: any, index: number) => {
    response.sheet_columns[index] = pair.sheet;
    response.table_columns[index] = pair.table;
  });
  return response;
}

export const downloadCSV = (JSONData: any, ReportTitle: string, ShowLabel: boolean) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData =
    typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ",";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var rowLoop1 = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (var indexLoop1 in arrData[i]) {
      rowLoop1 += '"' + arrData[i][indexLoop1] + '",';
    }

    rowLoop1.slice(0, rowLoop1.length - 1);

    //add a line break after each row
    CSV += rowLoop1 + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.setAttribute('style', 'visibility:hidden');
  link.download = ReportTitle;

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};