import { useTheme } from "@mui/material";
import { useCodeEditor } from "./use-code-editor";

export function CodeEditor({ value, onChange, extensions }: { value: any, onChange: any, extensions: any }) {
  const { ref, view, keyAction } = useCodeEditor({ value, onChange, extensions });
  const theme: any = useTheme()

  const EditorView = view;

  const CodeMirrorInstance =
    <div
      ref={ref}
      style={{
        // color: 'black',
        textAlign: 'left',
        height: '22em',
        maxHeight: '48.6vh'
      }}
      className={`cm-${theme.palette.mode}`}
    />

  return { CodeMirrorInstance, EditorView, keyAction }
}