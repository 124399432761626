import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  styled as muiStyled,
  TextField,
} from '@mui/material'
import { Auth0Context } from 'src/contexts/Auth0Context'
import {
  assignUserApi,
  getSupportConfig,
  getSupportEmails,
  getSupportInternalNotes,
  sendSupportEmail,
  sendSupportInternalNote,
  sendSupportResponseEmail,
} from 'src/utils/api/queries'
import { formatDateString } from 'src/utils/number'
import { validateEmail } from 'src/utils/string'
import LoadingComponent from '../LoadingComponent'

// assets
import { lightPalette, palette, newColor } from 'src/consts/colors'
import email_icon from 'src/assets/support_ticket_email_icon.svg'

const HELIKA_REPLY_EMAIL = 'reply@mg.helika.io'

const TicketCardMessagesBody = muiStyled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
}))

const ChatBody = muiStyled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.black : newColor.cultured,
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  flex: 1,
  width: '100%',
  maxHeight: 'calc(100vh - 33rem)',
  overflowY: 'scroll',
  padding: '25px 45px',
  borderRadius: '0 0 5px 5px',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderLeft: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
}))

const EmailOrInternalNoteSelectPanel = muiStyled('div')(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  padding: '3px',
  borderRadius: '23px',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  width: 'fit-content',
}))

const Chatbubble = muiStyled('pre')(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  fontSize: '14px',
  margin: 0,
  fontFamily: 'Paralucent Medium',
  fontWeight: 400,
}))
export interface SupportConfig {
  organization_url: string
  support_email: string
  support_email_header: string
  footer: string
}
export interface MessagingUIProps {
  ticket: any
  filterMessages: string
  users: any
  assignedToEmail: string
  onChangeAssignee: (assigneeId: number) => void
}

export default function MessagingUI(props: MessagingUIProps) {
  const { ticket, filterMessages, users, assignedToEmail, onChangeAssignee } =
    props
  const theme = useTheme()
  const { getWithAccessToken } = useContext(Auth0Context)
  const [supportConfig, setSupportConfig] = useState<SupportConfig | null>(null)
  const [emails, setEmails] = useState<any[]>([])
  const [internalNotes, setInternalNotes] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<string>('Unknown Reporter')

  useEffect(() => {
    if (_.isNil(ticket)) return
    let name =
      ticket?.data?.user_details?.name ||
      ticket?.data?.user_details?.player_id ||
      ticket?.data?.user_details?.wallet_id ||
      ticket?.data?.user_details?.email ||
      'Unknown Reporter'
    setUserInfo(name)
  }, [ticket])

  const chatBodyRef = useRef<HTMLDivElement>(null)

  const arrMessages = useMemo(() => {
    let TempMessages = emails.concat(internalNotes)
    let filtered = TempMessages
    if (filterMessages === 'emails') {
      filtered = TempMessages.filter((message) => message.is_email === true)
    } else if (filterMessages === 'notes') {
      filtered = TempMessages.filter((message) => message.is_email === false)
    }
    return filtered.sort(
      (a, b) =>
        new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
    )
  }, [emails, internalNotes, filterMessages])

  useEffect(() => {
    loadSupportConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //get support emails & internal notes
  useEffect(() => {
    if (_.isNil(ticket)) return
    loadSupportEmails()
    loadInternalNotes()
  }, [ticket])

  async function loadSupportConfig() {
    setIsLoading(true)
    try {
      let data = await getSupportConfig(getWithAccessToken)
      if (_.isEmpty(data.results)) {
        setSupportConfig({
          organization_url: '',
          support_email: '',
          support_email_header: '',
          footer: '',
        })
      } else {
        setSupportConfig(data.results)
        setIsLoading(false)
      }
    } catch {
      toast.error('Unable to get Support Config data', {
        position: 'top-left',
      })
      setIsLoading(false)
    }
  }

  async function loadSupportEmails() {
    setIsLoading(true)
    try {
      const params = {
        ticket_id: Number(ticket.id),
      }
      const emails = await getSupportEmails(getWithAccessToken, params)
      const emailTempArray: any[] = []

      if (ticket.description) {
        const isFirstMessage = emailTempArray.length === 0
        emailTempArray.push({
          body: ticket.description,
          created_at: ticket.created_at,
          formatted_date: formatDateString(ticket.created_at),
          sender: ticket.email ? ticket.email : 'Unknown Reporter',
          receiver: '',
          is_email: true,
          isFirstMessage: isFirstMessage,
        })
      }

      emails.results.map((value: any) => {
        const tempEmail = {
          body: value.body,
          created_at: value.created_at,
          formatted_date: formatDateString(value.created_at),
          sender: value.sender,
          receiver: value.receiver,
          is_email: true,
        }
        emailTempArray.push(tempEmail)
      })

      setEmails(emailTempArray)

      setIsLoading(false)
    } catch {
      toast.error('Unable to Load Messages', {
        position: 'top-left',
      })
      setIsLoading(false)
    }
  }
  async function loadInternalNotes() {
    try {
      const params = {
        ticket_id: Number(ticket.id),
      }
      const notes = await getSupportInternalNotes(getWithAccessToken, params)
      const notesTempArray: any[] = []
      notes.results.map((value: any) => {
        const tempNote = {
          body: value.body,
          created_at: value.created_at,
          formatted_date: formatDateString(value.created_at),
          sender: value.created_by,
          is_email: false,
        }
        notesTempArray.push(tempNote)
      })
      setInternalNotes(notesTempArray)
    } catch {
      toast.error('Unable to Load Internal Notes', {
        position: 'top-left',
      })
    }
  }
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop =
        chatBodyRef.current.scrollHeight - chatBodyRef.current.clientHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [emails, internalNotes])

  const renderCardEmail = (message: any) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent:
            message.receiver === HELIKA_REPLY_EMAIL || message.isFirstMessage
              ? 'flex-start'
              : 'flex-end',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '8px',
            maxWidth: '40%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              justifyContent:
                message.receiver === HELIKA_REPLY_EMAIL ||
                message.isFirstMessage
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <img src={email_icon} alt="Email Icon" style={{ height: '14px' }} />
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {message.sender}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              padding: '10px 10px 10px 15px',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor:
                message.receiver === HELIKA_REPLY_EMAIL ||
                message.isFirstMessage
                  ? newColor.tertiary
                  : newColor.byzantium,
              borderRadius:
                message.receiver === HELIKA_REPLY_EMAIL ||
                message.isFirstMessage
                  ? '10px 10px 10px 0px'
                  : '10px 10px 0px 10px',

              minWidth: '11.75rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: newColor.white,
              }}
            >
              <Chatbubble>{message.body}</Chatbubble>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent:
                message.receiver === HELIKA_REPLY_EMAIL ||
                message.isFirstMessage
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <Typography
              sx={{
                color: newColor.boulder,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {message.formatted_date}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  const renderCardInternalNote = (message: any) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <img src={email_icon} alt="Email Icon" style={{ height: '14px' }} />
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {users.map((user: any) => {
                if (user.id === message.sender)
                  return user?.name && user?.name !== 'None'
                    ? user?.name
                    : user.email
              })}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              padding: '10px 10px 10px 25px',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: '#13142F',
              borderRadius: '10px',
              border: '1px solid',
              borderColor: newColor.raisinBlack,
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: newColor.white,
              }}
            >
              <Chatbubble>{message.body}</Chatbubble>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              sx={{
                color: newColor.boulder,
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {message.formatted_date}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <TicketCardMessagesBody>
        <Box
          sx={{
            border: '1px solid',
            borderColor:
              theme.palette.mode === 'dark'
                ? palette.primaryBorder
                : lightPalette.primaryBorder,
            padding: '1.25rem',
            height: '3.75rem',
            borderRadius: '5px 5px 0 0',
          }}
        >
          <Typography
            className="truncate"
            sx={{
              fontSize: '1.25em',
              width: '100%',
              height: 'fit-content',
              margin: 'auto 0 auto 0',
            }}
          >
            Conversation with {userInfo}
          </Typography>
        </Box>
        <ChatBody ref={chatBodyRef}>
          {isLoading ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingComponent />
            </Box>
          ) : (
            arrMessages.map((message) => {
              return (
                <>
                  {message.is_email
                    ? renderCardEmail(message)
                    : renderCardInternalNote(message)}
                </>
              )
            })
          )}
        </ChatBody>
      </TicketCardMessagesBody>
      <InputMessagePanel
        ticket={ticket}
        supportConfig={supportConfig}
        loadSupportEmails={loadSupportEmails}
        loadInternalNotes={loadInternalNotes}
        theme={theme}
        assignedToEmail={assignedToEmail}
        emailLength={emails.length}
        onChangeAssignee={onChangeAssignee}
      />
    </>
  )
}

function InputMessagePanel(props: any) {
  const {
    supportConfig,
    ticket,
    loadSupportEmails,
    loadInternalNotes,
    theme,
    assignedToEmail,
    emailLength,
    onChangeAssignee,
  } = props
  const { postWithAccessToken, patchWithAccessToken } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)
  const [isEmailOrInternalNote, setIsEmailOrInternalNote] = useState<
    'email' | 'internal'
  >('email')
  const [supportEmailContent, setSupportEmailContent] = useState('')
  const [supportInternalNote, setSupportInternalNote] = useState('')
  const [isCorresponding, setIsCorresponding] = useState(false)
  const [isShowEmailInput, setIsShowEmailInput] = useState(true)

  useEffect(() => {
    if (_.isNil(ticket)) return
    if (ticket?.email && validateEmail(ticket?.email)) setIsShowEmailInput(true)
    else {
      setIsShowEmailInput(false)
      setIsEmailOrInternalNote('internal')
    }
  }, [ticket])

  const handleSendSupportResponse = async () => {
    if (_.isNil(assignedToEmail)) {
      if (
        !(
          userData?.role?.includes('helika_admin') ||
          userData?.role?.includes('helika_super_admin')
        )
      ) {
        toast.error(
          "Cann't support this ticket.\nThe user's permission is not allowed",
          {
            position: 'top-left',
          },
        )
        return
      }

      try {
        setIsCorresponding(true)
        const isCompleted = await assignUserApi(
          patchWithAccessToken,
          userData.id,
          {
            support_ticket_ids: [ticket?.id],
          },
        )
        if (isCompleted) {
          setIsCorresponding(false)
          toast.success('Support Ticket assigned successfuly.', {
            position: 'top-left',
          })
          onChangeAssignee(userData.id)
        } else {
          toast.error('Support Ticket could not be assigned.', {
            position: 'top-left',
          })
        }
      } catch (e) {
        toast.error('Support Ticket could not be assigned.', {
          position: 'top-left',
        })
      }
    }

    if (isEmailOrInternalNote === 'email') {
      if (_.isEmpty(supportEmailContent)) {
        toast.error('Email content is required', {
          position: 'top-left',
        })
        return
      }

      setIsCorresponding(true)
      const params = {
        organization_url: supportConfig?.organization_url,
        support_email_header_url: supportConfig?.support_email_header,
        support_ticket_title: ticket.title,
        ticket_id: ticket.id,
        support_body: supportEmailContent,
        support_name: assignedToEmail ?? userData.id,
        user_name: ticket.reported_by,
        ticket_body: ticket.description,
        org_name: 'helika',
        footer: supportConfig?.footer,
        receiver: ticket?.email,
        subject: 'Test Support Email Subject',
        response_thread_body: '',
      }

      try {
        if (emailLength > 0) {
          await sendSupportResponseEmail(postWithAccessToken, params)
        } else {
          await sendSupportEmail(postWithAccessToken, params)
        }
        setSupportEmailContent('')
        toast.success('Sent support email successfully', {
          position: 'top-left',
        })
        setIsCorresponding(false)
        loadSupportEmails()
      } catch {
        toast.error('Unable to send support email', {
          position: 'top-left',
        })
        setIsCorresponding(false)
      }
    } else {
      if (_.isEmpty(supportInternalNote)) {
        toast.error('Internal Note is required', {
          position: 'top-left',
        })
        return
      }
      setIsCorresponding(true)
      const params = {
        ticket_id: Number(ticket.id),
        body: supportInternalNote,
        created_by: ticket.assigned_to ?? userData?.id,
      }
      try {
        await sendSupportInternalNote(postWithAccessToken, params)
        setSupportInternalNote('')
        toast.success('Sent Internal Note successfully', {
          position: 'top-left',
        })
        setIsCorresponding(false)
        loadInternalNotes()
      } catch {
        toast.error('Unable to send internal note', {
          position: 'top-left',
        })
        setIsCorresponding(false)
      }
    }
  }

  return (
    <Stack direction="column" spacing="10px" paddingTop="11px">
      <EmailOrInternalNoteSelectPanel>
        <Button
          sx={{
            color: newColor.white,
            padding: '5px 15px',
            fontSize: '0.75em',
            borderRadius: '23px',
            backgroundColor:
              isEmailOrInternalNote === 'email'
                ? newColor.jazzberryJam
                : 'none',
          }}
          onClick={() => setIsEmailOrInternalNote('email')}
        >
          Email
        </Button>

        <Button
          sx={{
            color: newColor.white,
            padding: '5px 15px',
            fontSize: '0.75em',
            borderRadius: '23px',
            backgroundColor:
              isEmailOrInternalNote === 'internal'
                ? newColor.jazzberryJam
                : 'none',
          }}
          onClick={() => setIsEmailOrInternalNote('internal')}
        >
          Internal Note
        </Button>
      </EmailOrInternalNoteSelectPanel>
      {isEmailOrInternalNote === 'email' ? (
        <EmailInputPanel
          supportEmailContent={supportEmailContent}
          setSupportEmailContent={setSupportEmailContent}
          isShowEmailInput={isShowEmailInput}
        />
      ) : (
        <InternalNoteInputPanel
          supportInternalNote={supportInternalNote}
          setSupportInternalNote={setSupportInternalNote}
        />
      )}
      <Stack justifyContent="flex-end" direction="row">
        {isCorresponding ? (
          <Box
            sx={{
              padding: '4px 40px',
              width: 'fit-content',
            }}
          >
            <LoadingComponent width={'16px'} />
          </Box>
        ) : (
          <Button
            sx={{
              background:
                isEmailOrInternalNote === 'email' && !isShowEmailInput
                  ? theme.palette.mode === 'dark'
                    ? newColor.darkGunmetal
                    : newColor.cultured
                  : `linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%)`,
              borderRadius: '17px',
              fontSize: '14px',
              fontWeight: 400,
              color: newColor.white,
              padding: '4px 40px',
              width: 'fit-content',
              fontFamily: 'Paralucent Bold',
            }}
            onClick={() => handleSendSupportResponse()}
            disabled={isEmailOrInternalNote === 'email' && !isShowEmailInput}
          >
            SEND
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

function EmailInputPanel(props: any) {
  const theme = useTheme()
  const { supportEmailContent, setSupportEmailContent, isShowEmailInput } =
    props
  return (
    <TextField
      id="standard-basic"
      multiline
      rows={4}
      value={supportEmailContent}
      onChange={(e) => {
        setSupportEmailContent(e.currentTarget.value)
      }}
      size={'small'}
      sx={{
        width: '100%',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? newColor.darkGunmetal
            : newColor.cultured,
      }}
      placeholder={
        isShowEmailInput
          ? 'Type your message...'
          : 'No email address found for ticket reporter. Sending emails is disabled.'
      }
      disabled={!isShowEmailInput}
    />
  )
}

function InternalNoteInputPanel(props: any) {
  const theme = useTheme()
  const { supportInternalNote, setSupportInternalNote } = props
  return (
    <TextField
      id="standard-basic"
      multiline
      rows={4}
      value={supportInternalNote}
      onChange={(e) => {
        setSupportInternalNote(e.currentTarget.value)
      }}
      size={'small'}
      sx={{
        width: '100%',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? newColor.darkGunmetal
            : newColor.cultured,
      }}
      placeholder="Type your message..."
    />
  )
}
