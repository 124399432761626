import { ChangeEvent, useContext, useEffect, useState } from 'react'
import icon_list from '../../../../assets/google_icons_list.json'
import symbols from '@iconify-json/material-symbols/icons.json'

import styled from 'styled-components'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { Column } from '../../../../utils/muiStyledComponents'
import { handleError } from '../../../../utils/api/errors'
import _ from 'lodash'
import { assignTabToOrg, getTabOrgs } from '../../../../utils/api/queries'
import { TextareaAutosize } from '@mui/material'

const Title: any = styled.h3``

const FlexRow: any = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`

const TextWithIcon: any = styled.p`
  padding: 0;
  margin: auto;
  padding-right: 1em;
  font-size: 0.9em;
`

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Select: any = styled.select`
  width: 100%;
  padding: 10px;
`

const RowDiv: any = styled.div`
  display: flex;
  width: 100%;
  justify-items: center;
`

const ColumnDiv: any = styled.div`
  width: 50%;
  padding: 0px 20px;
`

interface Org {
  label: string
  value: number
}

export default function AssignTab({
  orgs,
  tabs,
  parentTabs,
}: {
  orgs: Org[]
  tabs: any[]
  parentTabs: any
}) {
  const { getTokenIfNecessary, postWithAccessToken } = useContext(Auth0Context)

  const symbols_list: any = symbols

  const [org, setOrg] = useState<Org | null>(null)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [tab, setTab] = useState<any>(null)
  const [tabOrgs, setTabOrgs] = useState<any[]>([])

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  async function getOrgsForTab() {
    if (!tab?.id || !_.isEmpty(tabOrgs)) return
    let tabOrgList = await getTabOrgs(getTokenIfNecessary, tab.id)
    if (_.isEmpty(tabOrgList)) return
    setTabOrgs(tabOrgList)
  }

  const setSelectedTab = (e?: ChangeEvent<HTMLSelectElement>) => {
    setTabOrgs([])
    if (e?.target) {
      const newOption = tabs.find(
        (tab: any) => tab.id === Number(e?.target.value),
      )
      setTab(newOption || null)
      setMessage('')
      setError('')
    }
  }

  useEffect(() => {
    getOrgsForTab()
    // eslint-disable-next-line
  }, [tab])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  useEffect(() => {
    if (!tabs || _.isEmpty(tabs)) return
    setTab(tabs[0])
  }, [tabs])

  const assignTab = async () => {
    try {
      if (!org) {
        setMessage('Must select an organization.')
        return
      }

      let params: any = {
        tab_id: tab.id,
        org_id: org.value,
      }

      await assignTabToOrg(postWithAccessToken, params)
        .then(() => {
          setMessage('Successfully assigned tab.')
        })
        .catch((error: any) => {
          handleError(error, setError, setMessage)
        })
    } catch (error: any) {
      handleError(error, setError, setMessage)
    }
  }

  return (
    <Column>
      <Title>Assign Tab</Title>

      <RowDiv>
        <ColumnDiv>
          <label>Choose Organization:</label>
          <br />
          <br />
          <Select value={org?.value} onChange={setSelectedOrg}>
            {orgs
              ?.sort((a: any, b: any) =>
                a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
              )
              .map((org) => (
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              ))}
          </Select>
        </ColumnDiv>
        <ColumnDiv>
          <label>Choose Tab:</label>
          <br />
          <br />
          <Select value={tab?.value} onChange={setSelectedTab}>
            {tabs
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((tab: any) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
          </Select>
        </ColumnDiv>
      </RowDiv>
      <div style={{ marginTop: '0.5em' }}>Current Orgs with Tab Assigned:</div>
      <TextareaAutosize
        style={{ marginTop: '0.5em', width: '100%' }}
        value={tabOrgs?.map((org) => org.name)?.toString()}
      />
      <p>
        Parent Tab:{' '}
        {parentTabs &&
          parentTabs.length > 0 &&
          parentTabs.find(
            (parentTab: any) => parentTab.id === tab?.parent_tab_id,
          )?.name}
      </p>
      {org &&
        tab?.icon &&
        (icon_list.icons.map((icon) => icon.name).includes(tab?.icon) ||
          (symbols_list && symbols_list?.icons[tab?.icon?.replace('_', '-')])) ? (
        icon_list.icons.map((icon) => icon.name).includes(tab?.icon) ? (
          <FlexRow>
            <TextWithIcon>Current Icon:</TextWithIcon>
            <i className="material-icons">{tab?.icon}</i>
          </FlexRow>
        ) : (
          <FlexRow>
            <TextWithIcon>Chosen Icon:</TextWithIcon>
            <span className="material-symbols-outlined">{tab?.icon}</span>
          </FlexRow>
        )
      ) : (
        <FlexRow>
          <TextWithIcon>Error: Can't find Icon or custom Icon.</TextWithIcon>
        </FlexRow>
      )}

      <br />
      <Button onClick={assignTab}>Assign Tab</Button>
      <Message>{message}</Message>
      <Error>{error}</Error>

      <br />
      <br />
      <br />
      <br />
    </Column>
  )
}
