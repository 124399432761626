import DragButtonIcon from 'src/assets/drag_button.svg'
import MyEcosystem from 'src/assets/icons/my_ecosystem_solid.svg'
import IngameAnalytics from 'src/assets/icons/ingame_analytics_solid.svg'
import SocialAnalytics from 'src/assets/icons/social_analytics_solid.svg'
import CompetitiveAnalytics from 'src/assets/icons/competitive_analytics_solid.svg'
import TabImage from 'src/assets/icons/live_ops_solid.svg'
import UserReports from 'src/assets/icons/user_reports_solid.svg'
import AccountManagementIcon from 'src/assets/account_management_icon.svg'
import { ReactComponent as AddTabIcon } from 'src/assets/add_tab.svg'

import styled from 'styled-components'
import { Droppable, type DraggableStyle, Draggable } from '@hello-pangea/dnd'

import { type TabData, TabLevel } from './type'
import { ToggleTab } from './ToggleTab'
import { DragButton, Tab, TabContentDivider, TabName } from './styled'
import ChildTabs, { stringToChildIcon } from './ChildTab'
import ThemedIcon from '../themedIcon'
// import { useActionPopover } from '../ProfileEditToggle'
import { Box, Typography, IconButton } from '@mui/material'
import { palette, newColor } from 'src/consts/colors'

const ParentTabStyled: any = styled(Tab)`
  border-color: ${newColor.babyBlueEyes};
  width: 350px;
`

const ParentTabName: any = styled(TabName)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${newColor.raisinBlack};
`

const getParentTabStyle = (
  isDragging: boolean,
  draggableStyle?: DraggableStyle,
) => {
  return {
    userSelect: 'none',
    marginLeft: '1.75rem',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'fit-content',
    ...draggableStyle,
  } as const
}

export default function ParentTabs({
  moduleTabId,
  tabData,
  enableAddTab,
  enableEditTab,
  enableDeleteTab,
  onDeleteTab,
  onEditTab,
  onEditParams,
  enableEditTabDefaultParams,
}: {
  moduleTabId: string
  tabData: TabData
  enableAddTab?: boolean
  enableEditTab?: boolean
  enableDeleteTab?: boolean
  onDeleteTab?: any
  onEditTab?: any
  onEditParams?: any
  enableDeleteParentTab?: boolean
  enableEditTabDefaultParams?: boolean
}) {
  // const { isEdit } = useActionPopover()

  const parentTabs = tabData[moduleTabId]
  const sortedParentTabs = Object.keys(parentTabs)
    .map((lv2TabId) => parentTabs[lv2TabId])
    .sort((a, b) => a.priority - b.priority)

  return (
    <Droppable
      droppableId={`${TabLevel.Parent}-${moduleTabId}`}
      type={`${TabLevel.Parent}-${moduleTabId}`}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{
            width: 'fit-content',
          }}
        >
          {sortedParentTabs?.map(({ id, name, children, icon }, index) => {
            return (
              <Draggable
                key={`${TabLevel.Parent}-${id}`}
                draggableId={`${TabLevel.Parent}-${id}`}
                index={index}
                isDragDisabled={false}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getParentTabStyle(
                      snapshot.isDraggingOver,
                      provided.draggableProps.style,
                    )}
                  >
                    <ToggleTab>
                      <ToggleTab.ParentTab>
                        <div style={{ display: 'flex' }}>
                          <ParentTabStyled
                            direction="row"
                            divider={
                              <TabContentDivider
                                orientation="vertical"
                                flexItem
                              />
                            }
                          >
                            <DragButton
                              src={DragButtonIcon}
                              alt=""
                              {...provided.dragHandleProps}
                            />
                            <ParentTabName>
                              <Box sx={{ display: 'flex', gap: '6px' }}>
                                {getParentTabIcon(name, icon)}
                                <Typography sx={{ color: palette.font }}>
                                  {name}
                                </Typography>
                              </Box>
                              <ToggleTab.ToggleButton />
                            </ParentTabName>
                          </ParentTabStyled>
                          {enableAddTab && (
                            <div style={{ display: 'flex', padding: '5px' }}>
                              <IconButton
                                onClick={() => {
                                  console.log('add tab for parent tab id', id)
                                }}
                              >
                                <ThemedIcon>
                                  <AddTabIcon fontSize="inherit" />
                                </ThemedIcon>
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </ToggleTab.ParentTab>
                      <ToggleTab.ChildTab>
                        <ChildTabs
                          childTabs={children}
                          parentTabId={id}
                          moduleTabId={moduleTabId}
                          enableEditTab={enableEditTab}
                          enableDeleteTab={enableDeleteTab}
                          onDeleteTab={onDeleteTab}
                          onEditTab={onEditTab}
                          onEditParams={onEditParams}
                          enableEditTabDefaultParams={
                            enableEditTabDefaultParams
                          }
                        />
                      </ToggleTab.ChildTab>
                    </ToggleTab>
                  </div>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

enum ParentTab {
  MyEcosystem = 'My Ecosystem',
  IngameAnalytics = 'In-Game Analytics',
  SocialAnalytics = 'Social Analytics',
  CompetitiveAnalytics = 'Competitive Analytics',
  Custom = 'Custom',
  LiveOps = 'Live Ops',
  Points = 'Points',
  UserReports = 'User Reports',
  AccountManagement = 'Account Management',
}

export function getParentTabIcon(name: string, icon: string) {
  let tabIcon = <div style={{ flex: 1 }} />

  switch (name) {
    case ParentTab.MyEcosystem:
      tabIcon = <img src={MyEcosystem} alt="my ecosystem"></img>
      break
    case ParentTab.IngameAnalytics:
      tabIcon = <img src={IngameAnalytics} alt="Ingame Analytics"></img>
      break
    case ParentTab.SocialAnalytics:
      tabIcon = <img src={SocialAnalytics} alt="Social Analytics"></img>
      break
    case ParentTab.CompetitiveAnalytics:
      tabIcon = (
        <img src={CompetitiveAnalytics} alt="Competitive Analytics"></img>
      )
      break
    case ParentTab.LiveOps:
      tabIcon = <img src={TabImage} alt="Live Ops"></img>
      break
    case ParentTab.Points:
      tabIcon = <img src={TabImage} alt="Points"></img>
      break
    case ParentTab.UserReports:
      tabIcon = <img src={UserReports} alt="User Reports"></img>
      break
    case ParentTab.AccountManagement:
      tabIcon = <img src={AccountManagementIcon} alt="Account Management"></img>
      break
    default:
      tabIcon = stringToChildIcon(name, icon)
  }
  return <>{tabIcon}</>
}
