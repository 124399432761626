import { Dispatch } from 'redux'
import { Action as DomainActions } from '../actions/DomainActions'
import { Action as UserActions } from '../actions/UserActions'
import { Action as NotificationsActions } from '../actions/NotificationsActions'
import { Action as AnalyticsActions } from '../actions/AnalyticsActions'
import { Action as GameManagementDataActions } from '../actions/GameManagementDataActions'
import { Action as AccountManagementDataActions } from '../actions/AccountManagementDataActions'
import { Action as SupportActions } from '../actions/SupportActions'
import { Action as SDKActions } from '../actions/SdkActions'
import { Action as LlmActions } from '../actions/llmActions'

export const setSDKData = (data: SDKActions) => {
  return (dispatch: Dispatch<SDKActions>) => {
    switch (data.type) {
      case 'SET_SDK':
        dispatch({
          type: 'SET_SDK',
          payload: data.payload,
        })
        break
      case 'UNSET_SDK':
        dispatch({
          type: 'UNSET_SDK',
        })
        break
    }
  }
}

export const setUserData = (data: UserActions) => {
  return (dispatch: Dispatch<UserActions>) => {
    switch (data.type) {
      case 'SET_USER_DATA':
        dispatch({
          type: 'SET_USER_DATA',
          payload: data.payload,
        })
        break
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
    }
  }
}

export const setDomain = (domain: DomainActions) => {
  return (dispatch: Dispatch<DomainActions>) => {
    switch (domain.type) {
      case 'SET_DOMAIN':
        dispatch({
          type: domain.type,
          payload: domain.payload,
        })
        break
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
    }
  }
}

export const setNotifications = (action: NotificationsActions) => {
  return (dispatch: Dispatch<NotificationsActions>) => {
    switch (action.type) {
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
      case 'READ_ALL_NOTIFICATIONS': {
        dispatch({
          type: 'READ_ALL_NOTIFICATIONS',
        })
        break
      }
      case 'READ_NOTIFICATIONS': {
        dispatch({
          type: 'READ_NOTIFICATIONS',
          payload: action.payload,
        })
        break
      }
      case 'LOAD_MORE_USER_NOTIFICATIONS': {
        dispatch({
          type: 'LOAD_MORE_USER_NOTIFICATIONS',
          payload: action.payload,
        })
        break
      }
      case 'OPEN_NOTIFICATIONS': {
        dispatch({
          type: 'OPEN_NOTIFICATIONS',
        })
        break
      }
      case 'CLOSE_NOTIFICATIONS': {
        dispatch({
          type: 'CLOSE_NOTIFICATIONS',
        })
        break
      }
      case 'SET_NOTIFICATIONS_DATA': {
        dispatch({
          type: 'SET_NOTIFICATIONS_DATA',
          payload: action.payload,
        })
      }
    }
  }
}

export const setAnalyticsState = (action: AnalyticsActions) => {
  return (dispatch: Dispatch<AnalyticsActions>) => {
    switch (action.type) {
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
      case 'OPEN_DRAWER': {
        dispatch({
          type: 'OPEN_DRAWER',
        })
        break
      }
      case 'CLOSE_DRAWER': {
        dispatch({
          type: 'CLOSE_DRAWER',
        })
        break
      }
      case 'SET_ANALYTICS_DATA': {
        dispatch({
          type: 'SET_ANALYTICS_DATA',
          payload: action.payload,
        })
      }
    }
  }
}

export const setGameManagementState = (action: GameManagementDataActions) => {
  return (dispatch: Dispatch<GameManagementDataActions>) => {
    switch (action.type) {
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
      case 'SET_SHEET_MAPPINGS': {
        dispatch({
          type: 'SET_SHEET_MAPPINGS',
          payload: action.payload,
        })
        break
      }
      case 'OPEN_DRAWER': {
        dispatch({
          type: 'OPEN_DRAWER',
        })
        break
      }
      case 'CLOSE_DRAWER': {
        dispatch({
          type: 'CLOSE_DRAWER',
        })
        break
      }
      case 'SET_GAME_MANAGEMENT_DATA': {
        dispatch({
          type: 'SET_GAME_MANAGEMENT_DATA',
          payload: action.payload,
        })
        break
      }
      case 'SET_CONFIG_LIST': {
        dispatch({
          type: 'SET_CONFIG_LIST',
          payload: action.payload,
        })
        break
      }
      case 'SET_CONFIG_HISTORY': {
        dispatch({
          type: 'SET_CONFIG_HISTORY',
          payload: action.payload,
        })
        break
      }
      case 'LOAD_MORE_CONFIG_LIST': {
        dispatch({
          type: 'LOAD_MORE_CONFIG_LIST',
          payload: action.payload,
        })
        break
      }
      case 'LOAD_MORE_CONFIG_HISTORY': {
        dispatch({
          type: 'LOAD_MORE_CONFIG_HISTORY',
          payload: action.payload,
        })
      }
    }
  }
}

export const setAccountManagementState = (
  action: AccountManagementDataActions,
) => {
  return (dispatch: Dispatch<AccountManagementDataActions>) => {
    switch (action.type) {
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
      case 'OPEN_DRAWER': {
        dispatch({
          type: 'OPEN_DRAWER',
        })
        break
      }
      case 'CLOSE_DRAWER': {
        dispatch({
          type: 'CLOSE_DRAWER',
        })
        break
      }
      case 'SET_ACCOUNT_MANAGEMENT_DATA': {
        dispatch({
          type: 'SET_ACCOUNT_MANAGEMENT_DATA',
          payload: action.payload,
        })
      }
    }
  }
}

export const setSupportState = (action: SupportActions) => {
  return (dispatch: Dispatch<SupportActions>) => {
    switch (action.type) {
      case 'CLEAR_DATA':
        dispatch({
          type: 'CLEAR_DATA',
        })
        break
      case 'OPEN_DRAWER': {
        dispatch({
          type: 'OPEN_DRAWER',
        })
        break
      }
      case 'CLOSE_DRAWER': {
        dispatch({
          type: 'CLOSE_DRAWER',
        })
        break
      }
      case 'SET_SUPPORT_DATA': {
        dispatch({
          type: 'SET_SUPPORT_DATA',
          payload: action.payload,
        })
        break
      }
      case 'LOAD_MORE_SUPPORT_TICKETS': {
        dispatch({
          type: 'LOAD_MORE_SUPPORT_TICKETS',
          payload: action.payload,
        })
        break
      }
      case 'SET_SUPPORT_TICKETS': {
        dispatch({
          type: 'SET_SUPPORT_TICKETS',
          payload: action.payload,
        })
      }
    }
  }
}

export const setLlmState = (action: LlmActions) => {
  return (dispatch: Dispatch<LlmActions>) => {
    switch (action.type) {
      case 'SET_CONTENT':
        dispatch({
          type: 'SET_CONTENT',
          payload: action.payload,
        })
        break
      case 'SET_CHAT':
        dispatch({
          type: 'SET_CHAT',
          payload: action.payload,
        })
        break
      case 'UNSET_CHAT': {
        dispatch({
          type: 'UNSET_CHAT',
        })
        break
      }
      case 'SET_CHAT_MESSAGES': {
        dispatch({
          type: 'SET_CHAT_MESSAGES',
          payload: action.payload,
        })
        break
      }
      case 'SET_HISTORY_EXPANDED': {
        dispatch({
          type: 'SET_HISTORY_EXPANDED',
        })
        break
      }
      case 'SET_HISTORY_MINIMIZED': {
        dispatch({
          type: 'SET_HISTORY_MINIMIZED',
        })
        break
      }
      case 'SET_EXPANDED': {
        dispatch({
          type: 'SET_EXPANDED',
        })
        break
      }
      case 'SET_MINIMIZED': {
        dispatch({
          type: 'SET_MINIMIZED',
        })
        break
      }
    }
  }
}
