import _ from 'lodash'

export const convertToPercents = (num: string | number) => {
  return Number((Number(num) * 100).toFixed(2))
}

const addCommasToNumber = (num: string | number) => {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

export const roundToDigits = (
  num: string | number | undefined,
  digits: number,
) => {
  if (num !== undefined) {
    const factor = Math.pow(10, digits)
    return addCommasToNumber(
      (Math.round(Number(num) * factor) / factor).toFixed(digits),
    )
  }
  return ''
}

export const formatUSD = (num: string | number) => {
  return `$${num}`
}
export const formatETH = (num: string | number) => {
  return `Ξ${num}`
}

export const queryTimeout = 120000 // 2 minute timeout
export const perLetterDelay = 20
export function randomDelayGenerator(length: string) {
  switch (length) {
    case 'long':
      return _.random(3, 5) * 1000
    case 'short':
    default:
      return _.random(2, 4) * 1000
  }
}

function getTimeDiff() {
  const d = new Date()
  let diff = d.getTimezoneOffset()
  return diff
}
function addTime(date: Date, minute: number) {
  const dateCopy = new Date(date.getTime())
  const minutesToAdd = minute * 60 * 1000
  dateCopy.setTime(date.getTime() - minutesToAdd)
  return dateCopy
}
export const formatDateString = (inputDateString: string): string => {
  let date = new Date(inputDateString)
  const today = new Date()
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  date = addTime(date, getTimeDiff())
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    // If the date is today
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: '2-digit',
      timeZone: userTimeZone,
      hour12: true, // Ensures the use of the 12-hour format
    }
    const formattedTime = date.toLocaleTimeString('en-US', options)
    return `Today at ${formattedTime.toLowerCase()}`
  } else {
    // If the date is not today
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: userTimeZone,
      hour12: true, // Ensures the use of the 12-hour format
    }
    const formattedDate = date.toLocaleString('en-US', options)
    return formattedDate
  }
}
