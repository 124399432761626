const initialState = {
  NOTIFICATIONS_IS_OPEN: false,
  NOTIFICATIONS_LIST: []
};

export default function notificationsReducer(state = initialState, action){
  let newState = state;
  switch (action.type) {
    case "CLEAR_DATA":
      return initialState;
    case 'READ_ALL_NOTIFICATIONS':
      let allRead = newState.NOTIFICATIONS_LIST.map(notification =>{
        return Object.assign({},notification,{status:'read'});
      });
      return Object.assign({},newState,{NOTIFICATIONS_LIST: allRead})
    case 'READ_NOTIFICATIONS':
      let updatedNotificationsList = newState.NOTIFICATIONS_LIST.map(notification =>{
        if (notification.id === action.payload) {
          let updatedNotification = notification;
          updatedNotification.status = 'read';
          return updatedNotification;
        }
        return notification;
      });
      return Object.assign({},newState,{NOTIFICATIONS_LIST: updatedNotificationsList})
    case "LOAD_MORE_USER_NOTIFICATIONS":   
      let updated_notifications_list = newState.NOTIFICATIONS_LIST?.concat(action.payload);
      return Object.assign({}, state, {NOTIFICATIONS_LIST: updated_notifications_list});
    case 'OPEN_NOTIFICATIONS':
      newState.NOTIFICATIONS_IS_OPEN = true;
      return newState;
    case 'CLOSE_NOTIFICATIONS':
      newState.NOTIFICATIONS_IS_OPEN = false;
      return newState;
    case 'SET_NOTIFICATIONS_DATA':
      return Object.assign({},newState,{NOTIFICATIONS_LIST: action.payload})
    default:
      return state;
  }
};