import _ from 'lodash'
import validator from 'validator'

export const isValidOrgName = (name: string) => {
  // Check if name is alphanumeric (including underscores)
  const isAlphanumericWithUnderscore = validator.isAlphanumeric(
    name.replaceAll('_', ''),
    'en-US',
  )

  // Check if name contains any characters other than alphanumeric and underscore
  const containsInvalidChars = validator.contains(name, /[^a-zA-Z0-9_]/)

  return isAlphanumericWithUnderscore && !containsInvalidChars
}

export function isArrayEmpty(input: any[], errMsg: string) {
  if (input.length === 0) throw errMsg
}

export const isValueEmpty = (value: any) => {
  if (Array.isArray(value)) {
    return value.length === 0 // Check for empty array
  } else {
    return value === null || value === '' || value === undefined || value === 0
  }
}
export const areAllPropertiesEmpty = (obj: any) => {
  return Object.values(obj).every(isValueEmpty)
}

export const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const isValidURL = (str: string) => {
  var pattern = new RegExp(/(?:(?:https?|ftp?|http?):\/\/)?[\w-]+(?:\.[\w-]+)+(?:\/[\w-]*)*(?:\?[\w-]+=[\w%]+(?:&[\w-]+=[\w%]+)*)?(?:#[\w-]+)?/);
  return !!pattern.test(str) && !_.isNull(str.match(URL_REGEX)) && validator.isURL(str, { require_protocol: false });
}