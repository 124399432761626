import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import Dashboard_Icon from '../../assets/Dashboard_Icon.svg'
import Dashboard_Icon_Light from '../../assets/Dashboard_Icon_Light.svg'
import { isStringEmpty } from '../../utils/string'
import Analytics from '../../assets/analytics_icon.svg'
import AnalyticsLight from '../../assets/analytics_icon_light.svg'
import Delete_Icon from '../../assets/delete_button.svg'
import Add_Icon from '../../assets/add_icon.svg'
import Edit_Icon from '../../assets/editIcon.svg'
import Actions_Icon from '../../assets/Actions_Icon.svg'
import Actions_Icon_Light from '../../assets/Actions_Icon_Light.svg'
import {
  applyMultiOrganizationApi,
  editTabApi,
  getDashBoardParentTabApi,
  getTabInfoApi,
  removeMultiOrganizationApi,
} from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { newColor } from '../../consts/colors'

const ButtonIcon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface SimpleDialogProps {
  orgs: any
  open: boolean
  setOpen: any
  parentTabs: any
  tabId: number | undefined
  onUpdated?: any
  selectedOrg?: any
}

export interface Dashboard_mapping {
  id: any
  name: string
  iframe_link: string
  parent_tab_id: number
}

export default function EditTabModal(props: SimpleDialogProps) {
  const { open, setOpen, orgs, parentTabs, tabId, onUpdated } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [dashLoading, setDashLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [parentTabId, setParentTabId] = useState<number>(0)
  const { patchWithAccessToken, getWithAccessToken } = useContext(Auth0Context)
  const [dashboardMappings, setDashboardMappings] = useState<
    Dashboard_mapping[]
  >([])
  const [checkedStates, setCheckedStates] = useState<boolean[]>(
    new Array(orgs?.length || 0).fill(false),
  )
  const [description, setDescription] = useState<string>('')
  const [selectAll, setSelectAll] = useState(false)

  const getTabInfoList = useCallback(() => {
    if (!tabId) return
    getTabInfoApi(getWithAccessToken, tabId)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setName(resp.results.name)
        setParentTabId(resp.results.parent_tab_id)
        setIcon(resp.results.icon)
        setDescription(resp.results.description)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken, tabId])

  const getDashboardTabInfoList = useCallback(() => {
    if (!tabId) return
    getDashBoardParentTabApi(getWithAccessToken, tabId)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) {
          setDashboardMappings([])
          return
        }
        const mappedResults = resp.results.map((result: any) => ({
          id: result.id,
          name: result.name,
          iframe_link: result.iframe_link,
          parent_tab_id: result.parent_tab_id,
        }))
        setDashboardMappings(mappedResults)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken, tabId])

  useEffect(() => {
    getTabInfoList()
    getDashboardTabInfoList()
  }, [getWithAccessToken, getTabInfoList, getDashboardTabInfoList])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectAllChange = (event: any) => {
    const checked = event.target.checked
    setSelectAll(checked)
    const newCheckedStates = orgs.map(() => checked)
    setCheckedStates(newCheckedStates)
  }

  const handleAutocompleteChange = (event: any, newValue: any) => {
    const newCheckedStates = orgs.map((option: any) =>
      newValue.includes(option),
    )
    setCheckedStates(newCheckedStates)
  }

  async function editTabQuery() {
    try {
      setLoading(true)

      isStringEmpty(name, 'Name required')
      isStringEmpty(icon, 'Tab Icon required')

      let params = {
        id: tabId,
        name: name,
        parent_tab_id: parentTabId,
        icon: icon,
        dashboards: dashboardMappings,
        description: description,
      }

      if (name && parentTabId && icon && dashboardMappings) {
        await editTabApi(patchWithAccessToken, params)
          .then(() => {
            toast.success('Successful edit the Tab')
            onUpdated()
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
          })
      } else {
        toast.warning('Please check Tab Name and other fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  async function applyOrganizationQuery() {
    try {
      setDashLoading(true)

      const checkedOrgs = orgs.filter(
        (org: any, index: any) => checkedStates[index],
      )

      const selectedOrganizationID = checkedOrgs.map((d: any) => d.value)

      if (!tabId) return

      await applyMultiOrganizationApi(
        patchWithAccessToken,
        tabId,
        selectedOrganizationID,
        selectAll,
      )
        .then(() => {
          toast.success('Successful apply the organization')
          setDashLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setDashLoading(false)
        })
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setDashLoading(false)
    }
  }

  async function removeOrganizationQuery() {
    try {
      setDashLoading(true)

      const checkedOrgs = orgs.filter(
        (org: any, index: any) => checkedStates[index],
      )

      const selectedOrganizationID = checkedOrgs.map((d: any) => d.value)

      if (!tabId) return

      await removeMultiOrganizationApi(
        patchWithAccessToken,
        tabId,
        selectedOrganizationID,
      )
        .then(() => {
          toast.success('Successful remove the organization')
          setDashLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setDashLoading(false)
        })
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setDashLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          maxWidth: '827px',
          paddingBottom: '40px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Edit_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>Edit Tab</div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : AnalyticsLight}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;TAB INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TAB NAME:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Input
                value={name}
                placeholder="Name"
                onChange={(e) => {
                  setName(e.currentTarget.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                PARENT TAB:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                defaultValue={''}
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setParentTabId(Number(event.target.value))
                }}
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                value={parentTabId ? parentTabId.toString() : ''}
              >
                {parentTabs &&
                  parentTabs.map(
                    (p: { id: number; name: string }, index: number) => (
                      <MenuItem
                        value={p.id}
                        key={index}
                        sx={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        {p.name}
                      </MenuItem>
                    ),
                  )}
              </Select>
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TAB ICON:
              </div>
            </Grid>
            <Grid style={{ width: '215px' }}>
              <Input
                value={icon || ''}
                placeholder="ICON"
                onChange={(e) => {
                  setIcon(e.currentTarget.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'start', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                DESCRIPTION:
              </div>
            </Grid>
            <Grid width={'215px'}>
              <TextField
                className={
                  theme.palette.mode === 'dark'
                    ? 'textFieldClass'
                    : 'textFieldClassLight'
                }
                value={description}
                onChange={(e) => {
                  setDescription(e.currentTarget.value)
                }}
                size={'small'}
                multiline
                rows={4}
                placeholder="Tab Description"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
          }}
          marginTop={'8px'}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'dark'
                  ? Dashboard_Icon
                  : Dashboard_Icon_Light
              }
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;DASHBOARDS
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            paddingLeft={'35px'}
            marginTop={'16px'}
          >
            {dashboardMappings?.map(
              (dashboard_mapping: Dashboard_mapping, index: number) => {
                return (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    key={index}
                    gap={'24px'}
                  >
                    <Input
                      value={dashboard_mapping.name}
                      placeholder="DASHBOARD NAME"
                      onChange={(e) => {
                        setDashboardMappings((prevState: any[]) => {
                          let previousValues = [...prevState]
                          if (previousValues[index]) {
                            previousValues[index].name = e.target.value
                            return previousValues
                          }
                          return previousValues
                        })
                      }}
                    />
                    <Input
                      value={dashboard_mapping.iframe_link}
                      placeholder="SIGMA URL"
                      onChange={(e) => {
                        setDashboardMappings((prevState: any[]) => {
                          let previousValues = [...prevState]
                          if (previousValues[index]) {
                            previousValues[index].iframe_link =
                              e.target.value?.trim()
                            return previousValues
                          }
                          return previousValues
                        })
                      }}
                      sx={{
                        width: '260px',
                      }}
                    />
                    <div className="flexrow">
                      <img
                        src={Delete_Icon}
                        style={{ marginRight: '0.5em', cursor: 'pointer' }}
                        alt=""
                        onClick={() => {
                          setDashboardMappings((prevState: any[]) => {
                            let newValues = [...prevState]?.filter(
                              (
                                dashboard_mapping: Dashboard_mapping,
                                mappingIndex: number,
                              ) => index !== mappingIndex,
                            )
                            return newValues
                          })
                        }}
                      />
                    </div>
                  </Box>
                )
              },
            )}
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={'24px'}
            >
              <Button
                className="helikaButtonClass"
                onClick={() => {
                  if (!tabId) return
                  setDashboardMappings((prevState: Dashboard_mapping[]) => {
                    return prevState.concat([
                      {
                        id: null,
                        name: '',
                        iframe_link: '',
                        parent_tab_id: tabId,
                      },
                    ])
                  })
                }}
                sx={{
                  width: '220px',
                }}
              >
                <img src={Add_Icon} alt="Add" />
                &nbsp;ADD NEW DASHBOARD
              </Button>
            </Box>
          </Box>
        </Box>
        {loading ? (
          <div className="w-full">
            <LoadingComponent />
          </div>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{ padding: '0 2em 0 2em' }}
          >
            <Button
              sx={{ width: '15em' }}
              disabled={loading}
              onClick={editTabQuery}
              className="helikaButtonClass"
            >
              Save
            </Button>
            <Button
              sx={{ width: '15em', marginLeft: '1em' }}
              onClick={() => setOpen(false)}
              className="helikaButtonClass"
            >
              Cancel
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
          }}
          marginTop={'8px'}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'dark'
                  ? Actions_Icon
                  : Actions_Icon_Light
              }
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;ACTIONS
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            paddingLeft={'35px'}
            marginTop={'16px'}
          >
            <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    color="primary"
                  />
                }
                sx={{
                  width: 'max-content',
                }}
                label="Select All"
              />
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={orgs || []}
                disableCloseOnSelect
                getOptionLabel={(option: any) => option.label}
                value={
                  orgs
                    ? orgs.filter((_: any, index: any) => checkedStates[index])
                    : []
                }
                onChange={handleAutocompleteChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={ButtonIcon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Checkboxes"
                    placeholder="Favorites"
                  />
                )}
              />
              {dashLoading ? (
                <div className="w-full">
                  <LoadingComponent />
                </div>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    gap: '40px',
                  }}
                >
                  <Button
                    className="helikaButtonClass"
                    sx={{
                      fontSize: '14px',
                      width: '220px',
                    }}
                    onClick={applyOrganizationQuery}
                  >
                    APPLY ORGANIZATIONS
                  </Button>
                  <Button
                    className="helikaButtonClass"
                    sx={{
                      fontSize: '14px',
                      width: '220px',
                    }}
                    onClick={removeOrganizationQuery}
                  >
                    REMOVE ORGANIZATIONS
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
