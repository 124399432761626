//imports
import React from 'react'
import { Box, styled as muiStyled } from '@mui/system'
import { GridCloseIcon } from '@mui/x-data-grid'
import moment from 'moment'
//components
import { Dialog, useTheme, DialogTitle, Typography } from '@mui/material'
import { newColor } from '../../consts/colors'
//assets
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { ReactComponent as AnnouncementIcon } from '../../assets/announcement_icon_small.svg'
import fullAnnouncementLeftBg from '../../assets/full_announcement_left_bg_icon.png'
import FullAnnouncementRightBg from '../../assets/full_announcement_right_bg_icon.png'
import { AnnouncemenTypeProp } from '../organisms/v2/AnnouncementPanel/AnnouncementBanner'

const Announcement = muiStyled('div', {
  shouldForwardProp: (prop) => prop !== 'announcementType',
})<AnnouncemenTypeProp>(({ announcementType }) => {
  let linearGradient = newColor.black
  if (announcementType === 'good') {
    linearGradient = `linear-gradient(90deg, ${newColor.electricPurple} 42.71%, ${newColor.caribbeanGreen} 100%)`
  } else if (announcementType === 'urgent') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 42.71%, ${newColor.jazzberryJam} 100%)`
  } else if (announcementType === 'notice') {
    linearGradient = `linear-gradient(90deg, ${newColor.indigo} 42.71%, ${newColor.lemonGlacier} 100%)`
  }
  return {
    display: 'flex',
    flexDirection: 'column',

    color: 'white',
    borderRadius: '10px',
    height: '150px',
    backgroundImage: `url(${fullAnnouncementLeftBg}), url(${FullAnnouncementRightBg}), ${linearGradient}`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'left, right, right',
  }
})

const DateColumn = muiStyled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '8px 20px',
}))

const AnnouncementColumn = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  padding: '0px 20px 10px 20px',
  width: '100%',
  paddingLeft: '20px',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledIcon = muiStyled('span', {
  shouldForwardProp: (prop) => prop !== 'announcementType',
})<AnnouncemenTypeProp>(({ announcementType }) => {
  let fill = newColor.black
  if (announcementType === 'good') {
    fill = newColor.oceanBoatBlue
  } else if (announcementType === 'urgent') {
    fill = newColor.jazzberryJam
  } else if (announcementType === 'notice') {
    fill = newColor.lemonGlacier
  }
  return {
    fontSize: 0,
    '& svg, & path': {
      fill,
    },
  }
})
interface AnnouncementType {
  title: string
  body: string
  type: 'urgent' | 'good' | 'notice'
  created_at: string
  id: number
  onHide: any
}

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  announcement: AnnouncementType | null
}

export default function AnnouncementFullTextModal(props: SimpleDialogProps) {
  const { open, setOpen, announcement } = props
  const theme: any = useTheme()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          width: '45vw',
        },
      }}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', padding: '0px' }}
      >
        <Box
          sx={{
            ml: 'auto',
            paddingBottom: '0px',
            cursor: 'pointer',
            paddingTop: '1rem',
            paddingRight: '1.5rem',
          }}
          onClick={handleClose}
        >
          <GridCloseIcon />
        </Box>
      </DialogTitle>
      <Box
        sx={{
          maxWidth: '100%',
          paddingX: `2.5rem`,
          paddingBottom: `2.5rem`,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Announcement announcementType={announcement?.type}>
          <DateColumn>
            <Typography
              sx={{
                fontSize: '16px',
                whiteSpace: 'nowrap',
                lineHeight: '100%',
              }}
            >
              {moment(announcement?.created_at).format('MMM DD, YYYY')}
            </Typography>
          </DateColumn>
          <AnnouncementColumn>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StyledIcon announcementType={announcement?.type}>
                  <AnnouncementIcon />
                </StyledIcon>
              </div>
              <Typography
                sx={{
                  fontSize: '20px',
                  textTransform: 'uppercase',
                  lineHeight: '100%',
                }}
              >
                {announcement?.title}
              </Typography>
            </div>
          </AnnouncementColumn>
        </Announcement>
        <Typography
          component="pre"
          sx={{
            fontFamily: 'inherit',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {announcement?.body}
        </Typography>
      </Box>
    </Dialog>
  )
}
