import { ChangeEvent, useContext, useEffect, useState } from 'react'

import styled from 'styled-components'

import { Auth0Context } from '../../../../contexts/Auth0Context'
import { Column } from '../../../../utils/muiStyledComponents'
import { Button } from '../../../atoms/buttons'
import { toast } from 'react-toastify'
import {
  bustAllUserCacheApi,
  bustCacheApi,
  bustOrgCacheApi,
} from '../../../../utils/api/queries'

const Title: any = styled.h3``

const Input: any = styled.input`
  width: 100%;
`

const Select: any = styled.select`
  width: 100%;
  padding: 10px;
`

const ColumnDiv: any = styled.div`
  width: 50%;
  padding: 0px 20px;
`

interface Org {
  label: string
  value: number
}

export default function BustCache({ orgs }: { orgs: Org[] }) {
  const { postWithAccessToken } = useContext(Auth0Context)

  const [org, setOrg] = useState<Org | null>(null)
  const [userToBustCache, setUserToBustCache] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
    }
  }

  const bustUserCache = async () => {
    try {
      if (
        !userToBustCache ||
        userToBustCache.length < 5 ||
        !userToBustCache.includes('@') ||
        !userToBustCache.includes('.')
      ) {
        toast.error('Invalid user email')
      }
      await bustCacheApi(postWithAccessToken, userToBustCache)
        .then((resp: any) => {
          if (resp.status === 'error') return
          toast.success(`User's cache busted`)
          setUserToBustCache('')
        })
    } catch (e) {
      toast.error('Could not bust cache')
    }
  }

  async function bustAllUsersCache() {
    try {
      bustAllUserCacheApi(postWithAccessToken)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust cache')
            return
          }
          toast.success('All users cache busted')
        })
        .catch((error: any) => {
          toast.error('Could not bust cache')
        })
    } catch (e) {
      toast.error('Could not bust cache')
    }
  }

  async function bustOrgCache() {
    try {
      if (!org) {
        toast.error('Must select an org')
        return
      }
      bustOrgCacheApi(postWithAccessToken, org.value)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust org cache')
            return
          }
          toast.success(
            `Successfully busted cache for users in org: ${org.label}`,
          )
        })
        .catch((error: any) => {
          toast.error('Could not bust org cache')
        })
    } catch (e) {
      toast.error('Could not bust org cache')
    }
  }

  return (
    <Column style={{ margin: '0 auto 3em auto' }}>
      <Title>Bust Cache</Title>

      <ColumnDiv>
        <label>All Users:</label>
        <Button style={{ marginLeft: '1em' }} onClick={bustAllUsersCache}>
          Bust Cache
        </Button>
      </ColumnDiv>

      <ColumnDiv style={{ margin: '2em auto 2em auto' }}>
        <label>Specific User:</label>
        <Input
          placeholder="e.g. user@helika.io"
          value={userToBustCache}
          type="text"
          required
          onChange={(e: any) => setUserToBustCache(e.currentTarget.value)}
        />
        <Button style={{ marginTop: '1em' }} onClick={bustUserCache}>
          Bust Cache
        </Button>
      </ColumnDiv>

      <ColumnDiv style={{ margin: '2em auto 2em auto' }}>
        <label>Org Users:</label>

        <Select value={org?.value} onChange={setSelectedOrg}>
          {orgs
            ?.sort((a: any, b: any) =>
              a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
            )
            .map((org) => (
              <option key={org.value} value={org.value}>
                {org.label}
              </option>
            ))}
        </Select>
        <Button style={{ marginTop: '1em' }} onClick={bustOrgCache}>
          Bust Cache
        </Button>
      </ColumnDiv>
    </Column>
  )
}
