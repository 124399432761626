//imports
import '../../App.css'

import { Box } from '@mui/material';

//----------------------------------------------------------------------------------------------------------

export default function Unavailable() {

  return (
    <Box display={'flex'} flexDirection={'column'} style={{ height: '100vh' }} justifyContent={'center'} width={'fit-content'} margin={'auto'}>
      This page is unavailable
    </Box>
  );
};