import * as ReactTooltip from 'react-tooltip'
import {
  Box,
  Button,
  Grid,
  Pagination,
  styled as muiStyled,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AdminSpan } from '../StyledSimpleComponents'
import Announcement_Title_Icon from '../../assets/Announcement_Title_Icon.svg'
import Announcement_Title_Icon_Light from '../../assets/Announcement_Title_Icon_Light.svg'
import { ReactComponent as Announcement_Type_Icon_Red } from '../../assets/Announcement_Type_Icon_Red.svg'
import { ReactComponent as Announcement_Type_Icon_Green } from '../../assets/Announcement_Type_Icon_Green.svg'
import { ReactComponent as Announcement_Type_Icon_Yellow } from '../../assets/Announcement_Type_Icon_Yellow.svg'
import { getMessageApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import AddIcon from '@mui/icons-material/Add'
import Edit_Icon from '../../assets/Edit_Icon_Button.svg'
import Edit_Icon_Hover from '../../assets/Edit_Icon_Button_Hover.svg'
import CreateNewAnnouncementModal from '../popups/CreateNewAnnouncementModal'
import EditAnnouncementModal from '../popups/EditAnnouncementModal'
import LoadingComponent from '../LoadingComponent'
import { newColor } from '../../consts/colors'

const SelectCustom = muiStyled('select')(({ theme }) => ({
  width: '60px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 5px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export default function AnnouncementManagement() {
  const theme = useTheme()
  const { getWithAccessToken } = useContext(Auth0Context)
  const [showCreateNewAnnouncementModal, setShowCreateNewAnnouncementModal] =
    useState<boolean>(false)
  const [showEditAnnouncementModal, setShowEditAnnouncementModal] =
    useState<boolean>(false)
  const [page, setPage] = useState(1)

  const [messagesList, setMessagesList] = useState<any[]>([])
  const [editMessageInfo, setEditMessageInfo] = useState<any[]>([])
  const [messageCount, setMessageCount] = useState<number>(5)
  const [pageSize, setPageSize] = useState<string>('5')
  const [loading, setLoading] = useState(false)

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPageSize(event.target.value)
  }

  const getMessagesList = useCallback(() => {
    setLoading(true)
    getMessageApi(getWithAccessToken, page, Number(pageSize))
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setMessagesList(resp.results.messages)
        setMessageCount(resp.results.count)
      })
      .catch((err: any) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getWithAccessToken, page, pageSize])

  useEffect(() => {
    getMessagesList()
  }, [getWithAccessToken, getMessagesList])

  const handleEditMessageChange = async (message: any) => {
    setEditMessageInfo(message)
    setShowEditAnnouncementModal(true)
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '28px',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '112.5 %',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={
              theme.palette.mode === 'dark'
                ? Announcement_Title_Icon
                : Announcement_Title_Icon_Light
            }
            alt="APIs"
          />
          &nbsp;&nbsp;ANNOUNCEMENT MANAGEMENT
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: '10px',
            gap: '28px',
          }}
        >
          <Button
            onClick={() => setShowCreateNewAnnouncementModal(true)}
            sx={{ pr: 2, height: '26px' }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            className="helikaButtonClass"
          >
            NEW ANNOUNCEMENT
          </Button>
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ marginBottom: '8px' }}>
        <Grid item xs={12}>
          <Box
            sx={{
              marginX: '14px',
              padding: '12px 30px 12px 50px',
              borderRadius: '10px 10px 0 0',
              backgroundColor:
                theme.palette.mode === 'dark' ? newColor.darkSlate : newColor.cosmicLatte,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2} lg={1.5}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    textTransform: 'upperCase',
                    textAlign: 'center',
                  }}
                >
                  TYPE
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={6} lg={7.5}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '19px',
                    textTransform: 'upperCase',
                  }}
                >
                  MESSAGE
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} lg={1}>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                  fontSize: '15px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  textAlign: 'center',
                  textTransform: 'upperCase',
                }}
              >
                Expiry Date
              </Typography>
            </Grid>
            <Grid item sm={1} lg={1}>
              <Box
                sx={{
                  color: theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                  fontSize: '15px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  textAlign: 'center',
                  textTransform: 'upperCase',
                }}
              >
                SHOW
              </Box>
            </Grid>
            <Grid item sm={1} lg={1}>
              <Box
                sx={{
                  color: theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                  fontSize: '15px',
                  fontWeight: 600,
                  lineHeight: '19px',
                  textAlign: 'center',
                  textTransform: 'upperCase',
                }}
              >
                Action
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '160px',
            }}
          >
            <LoadingComponent />
          </div>
        ) : (
          messagesList &&
          messagesList.map((message: any, index: number) => {
            const formattedExpiry = new Date(message.expiry).toLocaleDateString(
              'en-US',
              {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              },
            )
            return (
              <Grid item xs={12} key={index}>
                <Box
                  sx={{
                    marginX: '14px',
                    padding: '12px 30px 12px 50px',
                    borderRadius: '10px',
                    backgroundColor:
                      theme.palette.mode === 'dark' ? newColor.darkSlate : newColor.cosmicLatte,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Grid item sm={2} lg={1.5}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <SvgIcon
                        component={
                          message.type === 'urgent'
                            ? Announcement_Type_Icon_Red
                            : message.type === 'good'
                              ? Announcement_Type_Icon_Green
                              : Announcement_Type_Icon_Yellow
                        }
                      />
                      <Typography
                        color={
                          message.type === 'urgent'
                            ? newColor.jazzberryJam
                            : message.type === 'good'
                              ? newColor.oceanBoatBlue
                              : newColor.lemonGlacier
                        }
                        sx={{
                          textTransform: 'upperCase',
                        }}
                      >
                        {message.type}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6} lg={7.5}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '6px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark'
                                ? newColor.white
                                : newColor.charcoal,
                            fontSize: '15px',
                            fontWeight: 600,
                            lineHeight: '19px',
                            textTransform: 'upperCase',
                          }}
                        >
                          {message.title}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? newColor.boulder
                              : newColor.lead,
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '16px',
                        }}
                      >
                        {message.body}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={2} lg={1}>
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === 'dark' ? newColor.white : newColor.charcoal,
                        fontSize: '15px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        textAlign: 'center',
                        textTransform: 'upperCase',
                      }}
                    >
                      {formattedExpiry}
                    </Typography>
                  </Grid>
                  <Grid item sm={1} lg={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {message.show ? (
                        <span
                          style={{
                            color: newColor.oceanBoatBlue,
                          }}
                        >
                          ON
                        </span>
                      ) : (
                        <span
                          style={{
                            color: newColor.jazzberryJam,
                          }}
                        >
                          OFF
                        </span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={1} lg={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AdminSpan
                        data-tooltip-id="edit-announcement"
                        data-tooltip-content={'Edit Announcement Content'}
                        id="adminIcon"
                      >
                        <ReactTooltip.Tooltip id="edit-announcement" />
                        <img
                          onClick={() => handleEditMessageChange(message)}
                          id="adminIconNonHover"
                          src={Edit_Icon}
                          alt="editIcon"
                        />
                        <img
                          id="adminIconHover"
                          onClick={() => handleEditMessageChange(message)}
                          src={Edit_Icon_Hover}
                          alt="editIcon"
                        />
                      </AdminSpan>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            )
          })
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            margin: '15px 25px 0 0',
            gap: '8px',
          }}
        >
          <Pagination
            count={
              isNaN(messageCount / Number(pageSize))
                ? 1
                : Math.ceil(messageCount / Number(pageSize))
            }
            page={page}
            onChange={handlePaginationChange}
          />
          <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
            <option defaultChecked value={5}>
              5
            </option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={15}>20</option>
          </SelectCustom>
        </Box>
      </Grid>
      <CreateNewAnnouncementModal
        open={showCreateNewAnnouncementModal}
        setOpen={setShowCreateNewAnnouncementModal}
        onCreated={async () => {
          await getMessagesList()
          setShowCreateNewAnnouncementModal(false)
        }}
      />
      <EditAnnouncementModal
        open={showEditAnnouncementModal}
        setOpen={setShowEditAnnouncementModal}
        messagesInfo={editMessageInfo}
        onCreated={async () => {
          await getMessagesList()
          setShowEditAnnouncementModal(false)
        }}
      />
    </>
  )
}
