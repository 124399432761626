//imports
import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

//contexts
import { Auth0Context } from '../../contexts/Auth0Context'

//services
import { getUserData, unpublishExploreDashboard } from 'src/utils/api/queries'
import { actionCreators } from 'src/state'

//components
import DialogTitle from '@mui/material/DialogTitle'
import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid,
  Dialog,
  Typography,
} from '@mui/material'
import LoadingComponent from '../LoadingComponent'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import Analytics from '../../assets/analytics_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import { newColor } from 'src/consts/colors'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f;',
  },
}))

export interface SimpleDialogProps {
  dashboardName?: any,
  open: boolean
  setOpen: any,
  setLastSaved: any,
  updateLists: any,
  selectedDashboard: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const UnpublishDashboardModal = (props: SimpleDialogProps) => {
  const { open, setOpen, dashboardName, setLastSaved, updateLists, selectedDashboard } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [newDashboardName, setNewDashboardName] = useState<string>(dashboardName);
  const { postWithAccessToken, getTokenIfNecessary, logoutAuth0 } = useContext(Auth0Context)
  const dispatch = useDispatch()
  const { setUserData } = bindActionCreators(
    actionCreators,
    dispatch,
  )

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setNewDashboardName(dashboardName ? dashboardName : '')
  }, [dashboardName])

  async function unpublishDashboardHandler() {
    setLoading(true)
    if (_.isEmpty(newDashboardName?.trim())) {
      toast.error('Dashboard name must not be empty')
      setLoading(false)
      return
    }
    await unpublishExploreDashboard(postWithAccessToken, selectedDashboard).then(async (resp: any) => {
      await updateLists(resp.results.id).catch((e: any) => {
        toast.error('Could not reload Dashboard List, please refresh')
        setLoading(false)
        return
      })
      setLastSaved(resp.results.updated_at)

      //update tabs
      let user_data = await getUserData(getTokenIfNecessary, logoutAuth0)
      if (user_data) {
        setUserData({
          type: 'SET_USER_DATA',
          payload: user_data,
        })
      }

      setOpen(false)
      setLoading(false)
      toast.success('Dashboard was unpublished')
      //todo: refresh list
    }).catch((e: any) => {
      toast.error(e?.message)
      setLoading(false)
    })
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="unpublish_dashboard"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              UNPUBLISH DASHBOARD
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;DASHBOARD INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                DASHBOARD NAME:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Input
                value={dashboardName}
                placeholder="e.g. Weekly Totals Data Set"
                disabled
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '5em',
          width: '90%',
          margin: 'auto',
          scrollbarGutter: 'both-sides',
          overflow: 'auto'
        }}
      >
        {loading ? (
          <div
            style={{
              scrollbarGutter: 'both-sides'
            }}
          >
            <LoadingComponent />
          </div>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{
              padding: '0 2em 2em 2em',
              scrollbarGutter: 'both-sides'
            }}
          >
            <Button
              sx={{ width: '15em' }}
              disabled={loading}
              onClick={unpublishDashboardHandler}
              className="helikaButtonClass"
            >
              Unpublish
            </Button>
            <Button
              sx={{ width: '15em', marginLeft: '1em' }}
              onClick={() => setOpen(false)}
              className="helikaButtonClass"
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

export default UnpublishDashboardModal
