import React from 'react';
import { Box } from '@mui/material';
import date_icon from '../../assets/date_icon.svg';
import time_icon from '../../assets/time_icon.svg';
import moment from 'moment-timezone';

export const TimeCellButton = (props: any) => {
  let timeValue = props.props ? props.props?.created_at : props.value;
  let formattedDate = moment.tz(timeValue, "UTC").tz(moment.tz.guess(true));
  let created_at_date = formattedDate.format('YYYY-MM-DD');
  let created_at_time = formattedDate.format('hh:mm:ss A');

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      flexWrap={'wrap'}
      sx={{height:'fit-content', margin:'auto'}}
    >
      <span style={{display:'flex', flexDirection:'row',flexWrap:'nowrap', margin:'0 0.5em 0 0.5em'}}><Box sx={{ paddingRight:'0.5em', width:'fit-content'}} display='flex' flexDirection={'column'}><img src={date_icon} alt='' style={{margin:'auto'}}/></Box>{created_at_date}</span>
      <span style={{display:'flex', flexDirection:'row',flexWrap:'nowrap', margin:'0 0.5em 0 0.5em'}}><Box sx={{ paddingRight:'0.5em', width:'fit-content'}} display='flex' flexDirection={'column'}><img src={time_icon} alt='' style={{margin:'auto'}}/></Box>{created_at_time}</span>
    </Box>
  );
};