//imports
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

//contexts

//services

//assets
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { color_base } from 'helika-ui-sdk/dist/src/values/Colors';
import { newColor } from 'src/consts/colors';
//----------------------------------------------------------------------------------------------------------

export default function HelikaSearchBar({
    value,
    setValue,
    placeholder,
    onEnter,
}: {
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    placeholder?: string,
    onEnter?: any,
}) {

    const theme: any = useTheme();

    return (
        <div
            style={{
                height: '36px',
                width: '300px',
                display: 'flex',
                maxWidth: '100%',
                padding: '8px 8px 8px 12px',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                justifyContent: 'space-between',
                flexDirection: 'row',
                borderStyle: 'solid',
                borderRadius: '4px',
                background: theme.palette.mode === 'dark' ? newColor.gunmetalGray : color_base.white,
                borderColor: '#434D5A',
                borderWidth: '1px',
            }}
        >
            <SearchIcon
                sx={{
                    height: '20px',
                    width: '20px',
                }}
            />
            <input
                style={{
                    fontFamily: 'Open Sans',
                    background: 'transparent',
                    color: theme.palette.text.primary,
                    outline: 'none',
                    boxShadow: 'none',
                    border: 'none',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%', /* 19.6px */
                }}
                type='text'
                placeholder={placeholder ? placeholder : ' SEARCH'}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                onKeyDown={(e) => {
                    const newVal = e.currentTarget.value.trim();
                    if (e.key === 'Enter') {
                        if (onEnter && typeof onEnter === 'function') {
                            onEnter(newVal)
                        }
                    }
                }}
            >
            </input>
            {
                !_.isEmpty(value?.trim()) &&
                <CloseIcon
                    sx={{
                        fontSize: '18px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setValue('');
                    }}
                />
            }
        </div >
    );
}