// imports
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import _ from 'lodash'
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  styled as muiStyled,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Pagination, useTheme } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

// services
import { Auth0Context } from 'src/contexts/Auth0Context'
import {
  defaultSupportPageTableHeaders,
  supportPageTableHeadersWithSelect,
} from 'src/utils/agGrid'
import {
  getUserReports,
  getOrgUsers,
  getSupportConfig,
  setSupportTicketStatus,
  getUserReportsCount,
  assignUserApi,
} from 'src/utils/api/queries'

// state
import { actionCreators } from 'src/state'
import { bindActionCreators } from 'redux'

// components
import { SupportHeaderComponent } from 'src/components/agGrid/SupportHeaderComponent'
import { AssignUserButton } from 'src/components/agGrid/AssignUserButton'
import { ColorDotIcon } from 'src/components/svgCustom/customIcon'
import LoadingTableComponent from 'src/components/LoadingTableComponent'
import PageContainer from 'src/components/atoms/v2/pageContainer'

// Popup Modals
import AssignUser from 'src/components/popups/AssignUser'
import SetReportStatusModal from 'src/components/popups/SetReportStatusModal'
import EditSupportConfig from 'src/components/popups/EditSupportConfig'

// constants
import {
  SUPPORT_TICKET_STATUSES,
  SUPPORT_TICKET_STATUSE_COLORS,
} from 'src/consts/backend'

// assets
import 'src/App.css'
import { newColor } from 'src/consts/colors'
import supportHeaderIcon from 'src/assets/support_header_icon.svg'
import supportConfigIcon from 'src/assets/support_config_icon.svg'
import support_table_config_list from 'src/assets/support_table_config.json'

// types
type UserType = {
  id: number
  email: string
  name: string | null
  org_role: string
  module_access_level: object | null
  last_invite_at: string | null
}

//----------------------------------------------------------------------------------------------------------

const SelectCustom = muiStyled('select')(({ theme }) => ({
  width: '60px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : 'white',
  border: '1px solid ',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 5px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface TableConfig {
  label: string
  event_field: string
  enabled: boolean
  required: boolean
}
export interface SupportConfig {
  organization_url: string
  support_email: string
  support_email_header: string
  footer: string
  table_config: TableConfig[]
}

export default function Support() {
  // context
  const location: any = useLocation()
  const {
    getTokenIfNecessary,
    getWithAccessToken,
    isLoading,
    isAuthenticated,
    patchWithAccessToken,
  } = useContext(Auth0Context)
  const theme = useTheme()
  // state
  const dispatch = useDispatch()
  const { setSupportState } = bindActionCreators(actionCreators, dispatch)
  const SUPPORT_TICKETS = useSelector(
    (state: any) => state.supportData.SUPPORT_TICKETS,
  )
  const userData = useSelector((state: any) => state.user)
  const [users, setUsers] = useState<UserType[]>([])
  const [selectedSupportTicket, setSelectedSupportTicket] = useState<any>(null)
  const [hasError, setHasError] = useState<boolean>(false)
  const [supportConfig, setSupportConfig] = useState<SupportConfig | null>(null)
  const [userReportsCount, setUserReportsCount] = useState<number>(0)

  // modal booleans
  const [showAssignUser, setShowAssignUser] = useState<boolean>(false)
  const [showEditConfigModal, setShowEditConfigModal] = useState<boolean>(false)
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false)

  // display
  const [isLoadingUserReports, setIsLoadingUserReports] =
    useState<boolean>(false)
  const [ticketList, setTicketList] = useState<React.ReactElement | null>(null)
  const [selectedReports, setSelectedReports] = useState<any>({})
  const [filters, setFilters] = useState<{
    status: string
    assigned: boolean
    date: string
  }>({
    status: 'all',
    assigned: false,
    date: 'all',
  })

  // grid data
  const [columnDefs, setColumnDefs] = useState<any>(
    supportPageTableHeadersWithSelect(
      users,
      setSelectedReports,
      selectedReports,
      theme,
    ),
  )

  // pagination
  const [pageSize, setPageSize] = useState<string>('10')
  const [pageNumber, setPageNumber] = useState<number>(1)

  // cell click action
  const cellClickedListener = useCallback((event: any) => {
    setSelectedSupportTicket(event?.row)
    if (event.field === 'assigned_to') {
      setShowAssignUser(true)
    } else if (event.field === 'status') {
      setShowStatusModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoading || !isAuthenticated) return
    loadSupportConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])

  // show select column
  useEffect(() => {
    setColumnDefs(
      supportPageTableHeadersWithSelect(
        users,
        setSelectedReports,
        selectedReports,
        theme,
      ),
    )
  }, [users, theme, selectedReports])

  // open report if brought to this page by notifiation
  useEffect(() => {
    if (!_.isNil(location?.state?.notification_read_report_id)) {
      let foundReport = SUPPORT_TICKETS.find(
        (ticket: any) =>
          ticket.id === location.state.notification_read_report_id,
      )
      if (foundReport) {
        setSelectedSupportTicket(foundReport)
        window.history.replaceState({}, document.title)
      }
    }
  }, [SUPPORT_TICKETS, location.state])

  // get support ticket list
  useEffect(() => {
    if (pageNumber === 1) {
      loadUserReports(1)
    } else {
      setPageNumber(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), pageSize])

  useEffect(() => {
    loadUserReports(pageNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  // projectLoading
  useEffect(() => {
    setTimeout(function () {
      if (_.isEmpty(userData?.tabs)) setHasError(true)
    }, 15000)
  }, [userData])

  // change assign filter
  async function handleIsAssignedToMeChange(event: SelectChangeEvent) {
    setFilters((s) => ({ ...s, assigned: event.target.value === 'true' }))
  }

  async function loadSupportConfig() {
    try {
      let data = await getSupportConfig(getWithAccessToken)
      if (data.results === null) {
        setSupportConfig({
          organization_url: '',
          support_email: '',
          support_email_header: '',
          footer: '',
          table_config: support_table_config_list.table_config,
        })
      } else {
        setSupportConfig(data.results)
      }
    } catch {
      toast.error('Unable to get Support Config data')
    }
  }

  // change date filter
  async function handleDateFilterChange(event: SelectChangeEvent) {
    setFilters((s) => ({ ...s, date: event.target.value }))
  }

  // set list display
  useEffect(() => {
    if (isLoading) return

    try {
      setTicketList(
        <DataGridPro
          autoHeight
          rows={SUPPORT_TICKETS}
          columns={columnDefs}
          getRowId={(row: any) => {
            return row.id
          }}
          onCellClick={cellClickedListener}
          hideFooter={true}
        />,
      )
    } catch (e: any) {
      toast.error(e?.message)
      setHasError(true)
    }
  }, [SUPPORT_TICKETS, cellClickedListener, columnDefs, isLoading, theme])

  // get users to assgin
  useEffect(() => {
    async function getUsers() {
      let userList = await getOrgUsers(getTokenIfNecessary)

      setUsers(userList)
      setColumnDefs((prevState: any[]) => {
        if (!prevState) return []
        let newState = [...prevState]
        let indexAssigned = newState.findIndex(
          (item) => item.headerName === 'ASSIGNED',
        )
        newState[indexAssigned] = {
          headerName: 'ASSIGNED',
          headerComponent: SupportHeaderComponent,
          field: 'assigned_to',
          flex: 2.5,
          filter: true,
          minWidth: 200,
          cellRenderer: AssignUserButton,
          cellRendererParams: {
            users: userList,
          },
        }
        return newState
      })
    }

    if (!userData || !userData.organization_id) return

    try {
      getUsers()
    } catch (e) {
      toast.error('Unable to load Users')
      setHasError(true)
    }
  }, [getTokenIfNecessary, userData])

  // search configs given search term and filter
  async function loadUserReports(page?: number) {
    try {
      setIsLoadingUserReports(true)

      let params: { [key: string]: any } = {
        page_size: pageSize,
        page: page,
        assigned_to_me: filters.assigned,
      }
      if (filters.status !== 'all') params.status = filters.status

      let paramsForGetCount: { [key: string]: any } = {
        status: filters.status === 'all' ? false : filters.status,
        assigned_to_me: filters.assigned,
      }

      if (filters.status !== 'all') paramsForGetCount.status = filters.status

      switch (filters.date) {
        case 'week': {
          let oneWeekAgo = new Date()
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
          params.start_date = moment(oneWeekAgo).format('YYYY-MM-DDTHH:mm:ss')
          paramsForGetCount.start_date = moment(oneWeekAgo).format(
            'YYYY-MM-DDTHH:mm:ss',
          )
          break
        }
        case 'month': {
          let monthAgo = new Date()
          monthAgo.setMonth(monthAgo.getMonth() - 1)
          params.start_date = moment(monthAgo).format('YYYY-MM-DDTHH:mm:ss')
          paramsForGetCount.start_date = moment(monthAgo).format(
            'YYYY-MM-DDTHH:mm:ss',
          )
          break
        }
        case 'all':
        default: {
          //pass nothing
        }
      }
      let ticketsCount = await getUserReportsCount(
        getWithAccessToken,
        paramsForGetCount,
      )
      setUserReportsCount(ticketsCount.count)
      let tickets = await getUserReports(getTokenIfNecessary, params)
      setSupportState({
        type: 'SET_SUPPORT_TICKETS',
        payload: tickets,
      })
      setIsLoadingUserReports(false)
      setSelectedReports({})
    } catch (e) {
      setIsLoadingUserReports(false)
      toast.error('Unable to load Tickets')
      setHasError(true)
    }
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageNumber(page)
  }

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPageSize(event.target.value)
  }

  const setNewStatusForMultiSelect = async (
    newStatusForMultiSelect: string,
  ) => {
    if (!newStatusForMultiSelect) {
      toast.error('Must select a new status')
      return
    }

    let support_ticket_ids = Object.entries(selectedReports).map((item) =>
      Number(item[0]),
    )
    let isCompleted = await setSupportTicketStatus(
      patchWithAccessToken,
      newStatusForMultiSelect,
      support_ticket_ids,
    )
    if (isCompleted) {
      toast.success(`Tickets status updated successfully.`)
      setSupportState({
        type: 'SET_SUPPORT_TICKETS',
        payload: SUPPORT_TICKETS.map((item: any) => {
          if (support_ticket_ids.includes(item.id)) {
            return { ...item, status: newStatusForMultiSelect }
          }
          return item
        }),
      })
      return
    } else {
      toast.error('Tickets could not be updated.')
    }
  }

  const handleNewAssigneeForMultiSelectedTickets = async (
    newAssignee: string,
  ) => {
    if (!newAssignee) {
      toast.error('Must select a new assignee')
      return
    }

    let params = {
      support_ticket_ids: Object.entries(selectedReports).map((item) =>
        Number(item[0]),
      ),
    }
    let isCompleted = await assignUserApi(
      patchWithAccessToken,
      Number(newAssignee),
      params,
    )
    if (isCompleted) {
      toast.success('Tickets assigned successfuly.')
      setSupportState({
        type: 'SET_SUPPORT_TICKETS',
        payload: SUPPORT_TICKETS.map((item: any) => {
          if (params.support_ticket_ids.includes(item.id)) {
            return { ...item, assigned_to: newAssignee }
          }
          return item
        }),
      })
      return
    } else {
      toast.error('Tickets could not be assigned.')
    }
  }

  return (
    <PageContainer>
      {/* page header */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'space-between',
          overflowX: 'clip',
          height: 'fit-content',
          margin: 0,
          alignItems: 'center',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            padding: '0 1em 0 1em',
            maxWidth: '100%',
            height: 'fit-content',
          }}
        >
          <img
            src={supportHeaderIcon}
            style={{ height: '1.5em', margin: 'auto' }}
            alt="Support Header Icon"
          />
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: 'white',
              margin: 'auto 0 auto 1em',
              fontSize: '1.5em',
              fontWeight: 700,
            }}
          >
            TICKETS
          </Typography>
        </Box>
        <Button
          sx={{
            width: 'fit-content',
            borderRadius: '100px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => setShowEditConfigModal(true)}
        >
          <img
            src={supportConfigIcon}
            style={{ height: '1.5em', margin: 'auto' }}
            alt="Support Header Icon"
          />
        </Button>
      </Box>
      <AssignUser
        open={showAssignUser}
        setOpen={setShowAssignUser}
        onClose={() => {
          setShowAssignUser(false)
        }}
        title={'Assign User'}
        users={users}
        ticket={selectedSupportTicket}
      />
      <SetReportStatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        onClose={() => {
          setShowStatusModal(false)
        }}
        selectedReports={selectedSupportTicket}
      />
      <EditSupportConfig
        open={showEditConfigModal}
        setOpen={setShowEditConfigModal}
        supportConfig={supportConfig}
        setSupportConfig={setSupportConfig}
      />

      {/* filters */}
      <Box
        className="rowToMobileColumn"
        sx={{ padding: '0 0 0 0' }}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} justifyContent={'end'}>
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: theme.palette.text.primary,
              fontSize: '0.9em',
              fontWeight: 400,
            }}
          >
            {Object.entries(selectedReports).length > 0
              ? Object.entries(selectedReports).length + ' tickets selected'
              : userReportsCount + ' tickets found'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
              Status:
            </p>
            <Select
              required
              size="small"
              style={{ height: '2em' }}
              onChange={(e) => {
                setFilters((s) => ({ ...s, status: e.target.value }))
              }}
              value={filters.status}
            >
              <MenuItem value={'all'}>All</MenuItem>
              {Object.entries(SUPPORT_TICKET_STATUSES).map(([value, name]) => (
                <MenuItem key={value} value={value}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '7px',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <ColorDotIcon
                      color={
                        SUPPORT_TICKET_STATUSE_COLORS[
                        value as keyof typeof SUPPORT_TICKET_STATUSE_COLORS
                        ]
                      }
                    />
                    <Typography sx={{ fontSize: '14px' }}>{name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
              Assigned To:
            </p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filters.assigned ? 'true' : 'false'}
              size="small"
              onChange={handleIsAssignedToMeChange}
              style={{ height: '2em' }}
            >
              <MenuItem value={'false'}>Any</MenuItem>
              <MenuItem value={'true'}>MySelf</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            size="small"
            sx={{ width: '12em', margin: 'auto 0 0 1em' }}
          >
            <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
              Date:
            </p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filters.date}
              size="small"
              onChange={handleDateFilterChange}
              style={{ height: '2em' }}
            >
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'week'}>Last Week</MenuItem>
              <MenuItem value={'month'}>Last Month</MenuItem>
            </Select>
          </FormControl>

          {Object.entries(selectedReports).length > 0 ? (
            <>
              <FormControl
                size="small"
                sx={{ width: '12em', margin: 'auto 0 0 1em' }}
              >
                <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
                  Multi Assign:
                </p>
                <Select
                  required
                  onChange={(e: SelectChangeEvent<string>) => {
                    handleNewAssigneeForMultiSelectedTickets(e.target.value)
                  }}
                  size="small"
                  style={{ height: '2em' }}
                >
                  {users
                    ?.sort((a: any, b: any) =>
                      (a?.name && a?.name !== 'None'
                        ? a?.name
                        : a?.email
                      )?.toLowerCase() >
                        (b?.name && b?.name !== 'None'
                          ? b?.name
                          : b.email
                        )?.toLowerCase()
                        ? 1
                        : -1,
                    )
                    ?.map((user: any) => {
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user?.name && user?.name !== 'None'
                            ? user?.name
                            : user.email}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>

              <FormControl
                size="small"
                sx={{ width: '12em', margin: 'auto 0 0 1em' }}
              >
                <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
                  Multi Status:
                </p>
                <Select
                  required
                  size="small"
                  style={{ height: '2em' }}
                  onChange={(e) => {
                    setNewStatusForMultiSelect(String(e.target.value))
                  }}
                >
                  <MenuItem disabled={true} value={undefined}>
                    Select a new Status
                  </MenuItem>
                  {Object.entries(SUPPORT_TICKET_STATUSES).map(
                    ([value, name]) => (
                      <MenuItem key={value} value={value}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '7px',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <ColorDotIcon
                            color={
                              SUPPORT_TICKET_STATUSE_COLORS[
                              value as keyof typeof SUPPORT_TICKET_STATUSE_COLORS
                              ]
                            }
                          />
                          <Typography sx={{ fontSize: '14px' }}>
                            {name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>

      {/* user ticket list */}
      <div
        style={{
          width: '100%',
          margin: '0 auto auto auto',
        }}
      >
        {isLoadingUserReports ? (
          <LoadingTableComponent />
        ) : (
          !isLoading && ticketList
        )}
      </div>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '15px 25px 0 0',
          gap: '8px',
        }}
      >
        <Pagination
          count={
            isNaN(userReportsCount / Number(pageSize))
              ? 1
              : Math.ceil(userReportsCount / Number(pageSize))
          }
          page={pageNumber}
          onChange={handlePaginationChange}
          shape="rounded"
          sx={{
            ul: {
              '& .MuiPaginationItem-root': {
                backgroundColor:
                  theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                background: ' -webkit-linear-gradient(#612EA6, #EA335F)',
              },
              '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                height: '32px!important',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'end',
                alignItems: 'center',
                borderRadius: '5px!important',
              },
            },
          }}
        />
        <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
          <option defaultChecked value={10}>
            10
          </option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </SelectCustom>
      </Box>
    </PageContainer>
  )
}
