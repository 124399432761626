import {
  Pagination as MuiPagination,
  Stack,
  styled as muiStyled,
} from '@mui/material'
import styled from 'styled-components'
// colors
import { newColor } from 'src/consts/colors'

export const HeaderImageWrapper = muiStyled('a')(() => ({
  textDecoration: 'none',
  backgroundColor: 'transparent',
  color: '#EA335F',
  cursor: 'pointer',
}))

export const HeaderImage = styled.img.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['height'].includes(prop) && defaultValidatorFn(prop),
})<{
  src: string
  alt: string
  height?: number
}>(({ height = 132 }) => ({
  display: 'block',
  borderStyle: 'solid',
  borderRadius: '8px',
  borderWidth: 0,
  maxWidth: 'initial',
  height: `${height}px`,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  objectFit: 'cover',
}))

export const RotatableButton = styled(Stack).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['isRotated'].includes(prop) && defaultValidatorFn(prop),
})<{
  isRotated?: boolean
}>(({ theme, isRotated = false }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
}))

export const Pagination = styled(MuiPagination)`
  .MuiButtonBase-root {
    &.MuiPaginationItem-root {
      background-color: ${newColor.tertiary};
      &.Mui-selected {
        background: linear-gradient(to right, #ea335f, #612ea6);
      }
      &.Mui-disabled {
        background-color: ${newColor.tertiary};
        opacity: 50%;
      }
    }
  }
`
