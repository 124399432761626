//imports
import '../../App.css'

import { Box, useTheme } from '@mui/material';

//----------------------------------------------------------------------------------------------------------

export default function AccessDenied() {

  const theme = useTheme()

  return (
    <Box
      style={{
        color: theme.palette.text.primary,
        height: '100vh'
      }}
      display={'flex'} flexDirection={'column'}
      justifyContent={'center'}
    >
      You don't have access to this page
    </Box>
  );
};