//module imports
import React from 'react'
import { useTheme } from '@mui/material'

//style
import { Box, Divider } from '@mui/material'

//components
import { Drawer, DrawerHeader } from '../../../StyledSimpleComponents'

//assets
import HelikaIconButton from '../../../../assets/helikaIconButton.svg'
import HelikaIconButtonHover from '../../../../assets/helikaIconButtonHover.svg'
import { useNavigate } from 'react-router-dom'
import { newColor } from 'src/consts/colors'

export const UnauthenticatedSidedrawer = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  let scrollbox = (
    <div
      style={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background:
          theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        flex: 1,
      }}
    />
  )

  async function handleHelikaIconClick() {
    navigate('https://helika.io')
  }

  return (
    <Drawer
      variant="permanent"
      open={false}
      sx={{
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background:
          theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
      }}
    >
      <DrawerHeader
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        sx={{
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
        }}
      >
        <Box
          id="HelikaIconButton"
          sx={{
            width: 'min-content',
            margin: 0,
            marginTop: '0.5em',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
          }}
        >
          <img
            id="HelikaIconButtonNonHover"
            src={HelikaIconButtonHover}
            alt="drawer button"
            onClick={handleHelikaIconClick}
          />
          <img
            id="HelikaIconButtonHover"
            src={HelikaIconButton}
            alt="drawer button"
            onClick={handleHelikaIconClick}
          />
        </Box>
      </DrawerHeader>

      <Divider />

      {scrollbox}
    </Drawer>
  )
}
