export const fontSizes = {
  regular: '16px',
}

export const closeDrawerWidth = '4em'
export const drawerWidth = '18em'
export const minDrawerWidth = 'min-content'

export const HEADER = {
  height: '60px',
}
