import { DOMAIN_TYPES } from "../state/action-types";

export function hasAccessToModule(DOMAIN: DOMAIN_TYPES, USER_DATA: any) {
  if (!USER_DATA || !USER_DATA.module_access_level) return false;
  switch (DOMAIN) {
    case DOMAIN_TYPES.ANALYTICS: {
      if ('1' in USER_DATA?.module_access_level && ['viewer', 'editor'].includes(USER_DATA?.module_access_level['1'])) {
        return true;
      }
      return false;
    }
    case DOMAIN_TYPES.GAME_MANAGEMENT: {
      if ('2' in USER_DATA?.module_access_level && ['viewer', 'editor'].includes(USER_DATA?.module_access_level['2'])) {
        return true;
      }
      return false;
    }
    case DOMAIN_TYPES.SUPPORT: {
      if ('3' in USER_DATA?.module_access_level && ['viewer', 'editor'].includes(USER_DATA?.module_access_level['3'])) {
        return true;
      }
      return false;
    }
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT: {
      if ('4' in USER_DATA?.module_access_level && ['editor'].includes(USER_DATA?.module_access_level['4'])) {
        return true;
      }
      return false;
    }
    default: return false;
  }
}