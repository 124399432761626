import { useSelector } from 'react-redux'

export const useIsDisabled = () => {
  const userData = useSelector((state: any) => state.user)
  const isProd = process.env.REACT_APP_ENVIRONMENT === 'PROD'
  const isDisabled =
    (userData.organization_id === 77 || userData.organization_id === 80) &&
    isProd

  return isDisabled
}
