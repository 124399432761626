//imports
import React, { useContext, useEffect, useState } from 'react'

//services
import { updateSupportConfig } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'

//components
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
  useTheme,
  styled as muiStyled,
  Switch,
  FormControlLabel,
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { newColor } from '../../consts/colors'

//assets
import config_icon from '../../assets/config_icon.svg'
import Delete_Icon from '../../assets/delete_button.svg'
import { ReactComponent as DetailIcon } from 'src/assets/support_header_text_icon.svg'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f;',
  },
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&[type=number]': {
    '-moz-appearance': 'textfield',
  },
}))

const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg, & path': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))

const Android12Switch: any = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .Mui-checked': {
    color: newColor.jazzberryJam,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '&:before': {
    left: 12,
  },
  '&:after': {
    right: 12,
  },
}))

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  supportConfig: SupportConfig | null
  setSupportConfig: any
}

export interface TableConfig {
  label: string
  event_field: string
  enabled: boolean
  required: boolean
}

export interface SupportConfig {
  organization_url: string
  support_email: string
  support_email_header: string
  footer: string
  table_config: TableConfig[]
}

export default function EditSupportConfig(props: SimpleDialogProps) {
  const { open, setOpen, supportConfig, setSupportConfig } = props
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context)
  const [editInProgress, setEditInProgress] = useState<boolean>(false)

  const [tableConfig, setTableConfig] = useState<TableConfig[]>([])
  const [organizationUrl, setOrganizationUrl] = useState<string>('')
  const [emailHeaderImage, setEmailHeaderImage] = useState<string>('')
  const [supportEmail, setSupportEmail] = useState<string>('')
  const [supportEmailFooter, setSupportEmailFooter] = useState<string>('')
  const [customTableConfig, setCustomTableConfig] = useState<TableConfig[]>([])

  useEffect(() => {
    if (supportConfig) {
      setOrganizationUrl(supportConfig?.organization_url)
      setEmailHeaderImage(supportConfig?.support_email_header)
      setSupportEmail(supportConfig?.support_email)
      setSupportEmailFooter(supportConfig?.footer)
      setTableConfig(supportConfig.table_config)
    }
  }, [supportConfig, open])

  const handleClose = () => {
    setOpen(false)
  }

  const editConfig = async () => {
    setEditInProgress(true)
    const params = {
      organization_url: organizationUrl,
      support_email: supportEmail,
      support_email_header: emailHeaderImage,
      footer: supportEmailFooter,
      table_config: tableConfig,
    }
    try {
      let result = await updateSupportConfig(patchWithAccessToken, params)
      if (result.status === 'ok')
        setSupportConfig((prevState: SupportConfig) => {
          return {
            ...prevState,
            organization_url: organizationUrl,
            support_email: supportEmail,
            support_email_header: emailHeaderImage,
            footer: supportEmailFooter,
            table_config: tableConfig,
          }
        })
      toast.success('Updated Support Configuration successfully')
      setOpen(false)
      setEditInProgress(false)
    } catch {
      toast.error('Unable to update the Support Config successfully')
      setEditInProgress(false)
    }
  }
  const tableConfigTextchangeHandler = (
    event: any,
    index: number,
    cKey: string,
  ) => {
    setCustomTableConfig((prevState: any) => {
      let previousValues = [...prevState]
      if (previousValues[index]) {
        previousValues[index][cKey] = event.target.value
        return previousValues
      }
      return previousValues
    })
  }

  const tableConfigSelectHandler = (
    value: boolean,
    index: number,
    cKey: string,
  ) => {
    setTableConfig((prevState: TableConfig[]) => {
      return prevState.map((mapping, mappingIndex) => {
        if (index === mappingIndex) {
          return {
            ...mapping,
            [`${cKey}`]: value,
          }
        }
        return mapping
      })
    })
  }

  const insertTableConfig = (index: number) => {
    if (customTableConfig[index].label === '') {
      toast.error('Label is required')
      return
    } else if (customTableConfig[index].event_field === '') {
      toast.error('Event Field is required')
      return
    }
    setTableConfig((prevState: TableConfig[]) => {
      let previousValues = [...prevState]
      previousValues.push(customTableConfig[index])
      return previousValues
    })
    setCustomTableConfig((prevState: any[]) => {
      let newValues = [...prevState]?.filter(
        (config: TableConfig, mappingIndex: number) => index !== mappingIndex,
      )
      return newValues
    })
  }

  const renderTableConfig = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '22.5px',
            paddingTop: '2em',
          }}
        >
          <StyledSvg>
            <DetailIcon width={'16px'} height={'16px'} />
          </StyledSvg>
          &nbsp;&nbsp;Table Config
        </Typography>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={1}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'start'}
        >
          {tableConfig?.map((config: TableConfig, index: number) => {
            return (
              <Grid
                container
                xs={2}
                columnSpacing={1}
                rowSpacing={1}
                style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
                justifyContent={'space-evenly'}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    className="minimizeFontOnSmallWindow"
                    style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
                  >
                    {config.label}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Android12Switch
                        checked={config.enabled}
                        onClick={() => {
                          if (config.enabled === true) {
                            tableConfigSelectHandler(false, index, 'enabled')
                          } else {
                            tableConfigSelectHandler(true, index, 'enabled')
                          }
                        }}
                      />
                    }
                    label={<div style={{ color: 'white' }}></div>}
                  />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Box
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
            padding: '1em 1em 0 1em',
          }}
        >
          <Button
            className="helikaButtonClass"
            sx={{ width: '20em', marginBottom: '2em' }}
            onClick={() => {
              setCustomTableConfig((prevState: TableConfig[]) => {
                return prevState.concat([
                  {
                    label: '',
                    event_field: '',
                    enabled: true,
                    required: false,
                  },
                ])
              })
            }}
          >
            Add Custom Table Config
          </Button>
        </Box>
        {customTableConfig?.map((config: TableConfig, index: number) => {
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              gap={'24px'}
              padding={'0 0 1em 0'}
            >
              <Grid style={{ width: '340px' }}>
                <label>Label:</label>
                <Input
                  value={config?.label}
                  onChange={(e) => {
                    tableConfigTextchangeHandler(e, index, 'label')
                  }}
                  sx={{
                    width: '316px',
                  }}
                />
              </Grid>

              <Grid style={{ width: '100px' }}>
                <label>Event Field:</label>
                <Input
                  value={config?.event_field}
                  onChange={(e) => {
                    tableConfigTextchangeHandler(e, index, 'event_field')
                  }}
                  sx={{
                    width: '100px',
                  }}
                  type="text"
                />
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  sx={{ width: '5em', height: '2em' }}
                  disabled={editInProgress}
                  onClick={() => {
                    insertTableConfig(index)
                  }}
                  className="helikaButtonClass"
                >
                  add
                </Button>
              </Box>
              <div className="flexrow">
                <img
                  src={Delete_Icon}
                  style={{ marginRight: '0.5em', cursor: 'pointer' }}
                  alt="Delete Icon"
                  onClick={() => {
                    setCustomTableConfig((prevState: any[]) => {
                      let newValues = [...prevState]?.filter(
                        (config: TableConfig, mappingIndex: number) =>
                          index !== mappingIndex,
                      )
                      return newValues
                    })
                  }}
                />
              </div>
            </Box>
          )
        })}
      </>
    )
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '1em 2em 0 2em',
        }}
      >
        <DialogTitle
          className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            src={config_icon}
            alt=" Config Icon"
            style={{ margin: 'auto 0.3em auto 0' }}
          />{' '}
          <div style={{ lineHeight: '80%', margin: '0.4em 0 0.3em 0' }}>
            EDIT CONFIG
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '2em 2em 2em 2em',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
      >
        <Typography
          style={{
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '22.5px',
          }}
        >
          <StyledSvg>
            <DetailIcon width={'16px'} height={'16px'} />
          </StyledSvg>
          &nbsp;&nbsp;Email Response Template
        </Typography>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Organization Url:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              id="standard-basic"
              value={organizationUrl}
              onChange={(e) => {
                setOrganizationUrl(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
              placeholder=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Header Image:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              id="standard-basic"
              value={emailHeaderImage}
              onChange={(e) => {
                setEmailHeaderImage(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
              placeholder=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Support Email:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              id="standard-basic"
              value={supportEmail}
              onChange={(e) => {
                setSupportEmail(e.currentTarget.value)
              }}
              size={'small'}
              placeholder=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0', maxWidth: '100%' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              Support Email Footer:
            </div>
          </Grid>
          <Grid item xs={9}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass2'
                  : 'inputFieldClass2Light'
              }
              id="standard-basic"
              multiline
              rows={4}
              value={supportEmailFooter}
              onChange={(e) => {
                setSupportEmailFooter(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
              placeholder=""
            />
          </Grid>
        </Grid>
      </Box>
      {editInProgress ? (
        <div className="w-full" style={{ padding: '0 2em 2em 2em' }}>
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={editInProgress}
            onClick={editConfig}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => {
              setOpen(false)
            }}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
