import React from 'react'
import { toast } from 'react-toastify'
import copyToClipBoard from '../../assets/copy_to_clipboard_icon.svg'
import { newColor } from '../../consts/colors'
import { Button } from '@mui/material'
export const IdRenderer = (props: any) => {
  const fullURL = `${window.location.href}/${props.value}`
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        gap: '5px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          margin: 'auto',
          width: '100%',
          textOverflow: 'ellipsis',
          justifyContent: 'start',
        }}
        className="secondaryTruncate"
      >
        {props.value}
      </div>
      <div
        style={{
          display: 'flex',
          margin: 'auto',
          width: '100%',
          textOverflow: 'ellipsis',
          justifyContent: 'center',
        }}
      >
        <Button
          style={{
            width: '20px',
            height: '20px',
            cursor: 'pointer',
            background: newColor.tertiary,
            borderRadius: '1rem',
            padding: '3px',
            minWidth: '20px',
          }}
          onClick={() => {
            navigator.clipboard.writeText(fullURL)
            toast.success('ID copied to clipboard')
          }}
        >
          <img
            src={copyToClipBoard}
            alt="Copy To Clipboard Icon"
            width={14}
            height={14}
          />
        </Button>
      </div>
    </div>
  )
}
