import React from 'react'
import { Box, Typography } from '@mui/material'
import {
  SUPPORT_TICKET_STATUSES,
  SUPPORT_TICKET_STATUSE_COLORS,
} from '../../consts/backend'
import { ColorDotIcon } from '../svgCustom/customIcon'
import { newColor } from '../../consts/colors'
export const StatusRenderer = (props: any) => {
  let statuses = Object.entries(SUPPORT_TICKET_STATUSES)
  let value = statuses.find((item) => item[0] === props.value)

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '7px',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '18px',
        padding: '5px 11px',
        background:
          props.theme?.palette.mode === 'dark'
            ? newColor.tertiary
            : newColor.cultured,
        cursor: 'pointer',
      }}
    >
      <ColorDotIcon
        color={
          SUPPORT_TICKET_STATUSE_COLORS[
            props.value as keyof typeof SUPPORT_TICKET_STATUSE_COLORS
          ]
        }
      />
      <Typography
        sx={{ fontSize: '14px', color: props.theme?.palette.text.primary }}
      >
        {value && value[1]}
      </Typography>
    </Box>
  )
}
