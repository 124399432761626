import React, { useCallback, useContext, useEffect, useState } from 'react'
import PageContainer from 'src/components/atoms/v2/pageContainer'
import CircularPageHeader from 'src/components/atoms/v2/circularPageHeader'
import CreateCampaignModal from 'src/components/popups/CreateCampaignModal'
import EditCampaignModal from 'src/components/popups/EditCampaignModal'
import {
  Box,
  Button,
  Grid,
  Pagination,
  Skeleton,
  Typography,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import APIsSVG from 'src/assets/API_Icon.svg'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import {
  getAllCampaigns,
  getOrganizationRewardsEvents,
  patchPointCampaignArchive,
} from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import { toast } from 'react-toastify'
import LoadingComponent from '../../components/LoadingComponent'
import { newColor } from 'src/consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '0.72917vw',
}))

const SelectCustom = muiStyled('select')(({ theme }) => ({
  width: '60px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 5px',
  '&:focus, &:hover': {
    outline: '#6926ad',
    border: '1px solid #ea335f;',
  },
}))

const StyledGrid = muiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  wordBreak: 'break-all',
  lineHeight: '127%',
  fontSize: '15px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  width: '20%',
  alignItems: 'center',
  borderRadius: '16px',
  paddingLeft: '20%',
}))

export interface RewardEvent {
  event_name: string
  event_raw_name: string
  game_id: string
}

export default function CampaignList() {
  const theme = useTheme()
  const {
    getWithAccessToken,
    isLoading,
    isAuthenticated,
    patchWithAccessToken,
  } = useContext(Auth0Context)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isCampaignCreated, setIsCampaignCreated] = useState(false)
  const [campaignList, setCampaignList] = useState<any[]>([])
  const [campaignCount, setCampaignCount] = useState<number>(5)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState<string>('5')
  const [editCampaignInfo, setEditCampaignInfo] = useState<any[] | null>(null)
  const [rewardEvents, setRewardEvents] = useState<RewardEvent[]>([])
  const [isArchiving, setIsArchiving] = useState(false)
  const [archivingCampaignID, setArchivingCampaignID] = useState<number | null>(
    null,
  )
  const handleOpenModal = () => setIsModalOpen(true)

  const getCampaignsList = useCallback(() => {
    setLoading(true)
    getAllCampaigns(getWithAccessToken, page, Number(pageSize))
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setCampaignList(resp.results)
        setCampaignCount(resp.count)
      })
      .catch((err: any) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [getWithAccessToken, page, pageSize, isCampaignCreated])

  useEffect(() => {
    getCampaignsList()
  }, [getWithAccessToken, getCampaignsList])

  useEffect(() => {
    if (isLoading || !isAuthenticated) return
    getOrgEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])

  const getOrgEvents = async () => {
    try {
      const data = await getOrganizationRewardsEvents(getWithAccessToken)
      if (!data || !data.results) return
      setRewardEvents(data.results)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPageSize(event.target.value)
  }

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  const handleOnCreatedCampaign = () => {
    setIsCampaignCreated((prevState) => !prevState)
  }

  const handleEditCampaign = (campaign: any) => {
    setIsEditModalOpen(true)
    setEditCampaignInfo(campaign)
  }

  const handleOnArchive = async (campaignID: number) => {
    setIsArchiving(true)
    setArchivingCampaignID(campaignID)
    patchPointCampaignArchive(patchWithAccessToken, campaignID)
      .then((resp: any) => {
        if (!resp) {
          toast.error('Could not archive Campaign')
          setIsArchiving(false)
          setArchivingCampaignID(null)
          return
        }
        toast.success('Campaign archived successfully!')
        setIsArchiving(false)
        setArchivingCampaignID(null)

        return
      })
      .catch((error: any) => {
        toast.error('Could not archive Campaign')
        setIsArchiving(false)
        setArchivingCampaignID(null)
      })
  }

  return (
    <PageContainer>
      <CircularPageHeader
        text="Points V1 - Campaign List"
        icon={APIsSVG}
        sx={{ marginX: '1rem' }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          flexDirection: 'row',
          marginTop: '20px',
          marginX: '1rem',
        }}
      >
        <Button className="helikaButtonClass" onClick={handleOpenModal}>
          <AddIcon style={{ color: 'white', width: '20px' }} />
          <StyledTxt>Create New Campaign</StyledTxt>
        </Button>
      </Box>
      <Box
        sx={{
          margin: '20px',
          border: '1px solid',
          borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : '#cfbebe',
          backgroundColor:
            theme.palette.mode === 'dark' ? '#141414' : newColor.cultured,
          borderRadius: '10px',
          padding: '20px',
          textAlign: 'left',
          marginBottom: '20px',
        }}
      >
        <Grid container spacing={1}>
          {loading ? (
            <>
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: 'center',
                  borderRadius: '16px',
                  backgroundColor:
                    theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
                  height: 'auto',
                  width: '97.5%',
                  paddingBottom: '16px',
                  margin: '1rem auto auto',
                }}
              >
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: 'center',
                  borderRadius: '16px',
                  backgroundColor:
                    theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
                  height: 'auto',
                  width: '97.5%',
                  paddingBottom: '16px',
                  margin: '1rem auto auto',
                }}
              >
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: 'center',
                  borderRadius: '16px',
                  backgroundColor:
                    theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
                  height: 'auto',
                  width: '97.5%',
                  paddingBottom: '16px',
                  margin: '1rem auto auto',
                }}
              >
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingX: '30%',
                      width: '100%',
                      gap: '16px',
                    }}
                  >
                    <Skeleton width={'60%'} />
                    <Skeleton width={'20%'} />
                    <Skeleton width={'20%'} />
                  </Box>
                </StyledGrid>
              </Grid>
            </>
          ) : (
            campaignList &&
            campaignList.map((campaign: any, index: number) => {
              const formattedStartTime = new Date(
                campaign.start,
              ).toLocaleDateString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              const formattedEndTime = campaign.end
                ? new Date(campaign.end).toLocaleDateString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
                : ''
              return (
                <Grid item md={12} lg={12} xl={12} key={index}>
                  <Box
                    sx={{
                      marginX: '14px',
                      padding: '12px 30px 12px 50px',
                      borderRadius: '10px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? '#222222' : '#F2F2F2',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item md={5} lg={5} xl={5}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '6px',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === 'dark'
                                  ? '#ffffff'
                                  : '#141414',
                              fontSize: '15px',
                              fontWeight: 600,
                              lineHeight: '19px',
                              textTransform: 'upperCase',
                            }}
                          >
                            {campaign.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} lg={3} xl={3}>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? '#ffffff'
                              : '#141414',
                          fontSize: '15px',
                          fontWeight: 400,
                          lineHeight: '19px',
                          textAlign: 'start',
                          textTransform: 'upperCase',
                        }}
                      >
                        {formattedStartTime} - {formattedEndTime}
                      </Typography>
                    </Grid>
                    <Grid item md={2} lg={2} xl={2}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark'
                                ? '#ffffff'
                                : '#141414',
                            fontSize: '15px',
                            fontWeight: 400,
                            lineHeight: '19px',
                            textAlign: 'center',
                            textTransform: 'upperCase',
                          }}
                        >
                          Campaign Metadata
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} lg={2} xl={2}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        {isArchiving && archivingCampaignID == campaign.id ? (
                          <Box
                            sx={{
                              paddingX: '30px',
                            }}
                          >
                            <LoadingComponent width={'36px'} />
                          </Box>
                        ) : (
                          <Button
                            className="helikaButtonClass"
                            sx={{
                              paddingX: '16px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'cetner',
                              gap: '4px',
                            }}
                            onClick={() => handleOnArchive(campaign.id)}
                          >
                            <ArchiveIcon
                              style={{ color: 'white', width: '20px' }}
                            />
                            <StyledTxt>Archive</StyledTxt>
                          </Button>
                        )}
                        <Button
                          className="helikaButtonClass"
                          sx={{
                            paddingX: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'cetner',
                            gap: '4px',
                          }}
                          onClick={() => handleEditCampaign(campaign)}
                        >
                          <EditIcon style={{ color: 'white', width: '20px' }} />
                          <StyledTxt>Edit</StyledTxt>
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              )
            })
          )}

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              margin: '15px 25px 0 0',
              gap: '8px',
            }}
          >
            <Pagination
              count={
                isNaN(campaignCount / Number(pageSize))
                  ? 1
                  : Math.ceil(campaignCount / Number(pageSize))
              }
              page={page}
              onChange={handlePaginationChange}
            />
            <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
              <option defaultChecked value={5}>
                5
              </option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </SelectCustom>
          </Box>
        </Grid>
      </Box>
      <CreateCampaignModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onCreated={handleOnCreatedCampaign}
        rewardEvents={rewardEvents}
      />
      <EditCampaignModal
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        campaignInfo={editCampaignInfo}
        setEditCampaignInfo={setEditCampaignInfo}
        onEdited={handleOnCreatedCampaign}
        rewardEvents={rewardEvents}
      />
    </PageContainer>
  )
}
