//imports
import React, { useState } from "react"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Grid, useTheme } from '@mui/material';
import { StyledSvg } from "./organisms/v2/tabs/styled";


//contexts

//services

//components
import { ReactComponent as OpenFolder } from 'src/assets/Folder_Open.svg'
import { newColor } from "src/consts/colors";
import SearchBar from "./SearchBar";
import { ReactComponent as DashboardDesignerIcon } from 'src/assets/dashboard_designer_icon.svg'
import _ from "lodash";
import DontSaveDashboardModal from "./popups/DontSaveDashboardModal";
import { sortArrayBy } from "src/utils/string";
//---------------------------------------------------------------------------------

function OpenDashboardMenu({
  dashboards,
  dashboardOnClickHandler
}: {
  dashboards: any,
  dashboardOnClickHandler: any
}) {

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [interiorSelectedDashboard, setInteriorSelectedDashboard] = React.useState<any>();
  const [dontSaveDashboardModalBoolean, setDontSaveDashboardModalBoolean] = useState<boolean>(false)

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchTerm, setSearchTerm] = useState<string>("")

  function dashboardClickHandler(query: any) {
    setInteriorSelectedDashboard(query)
  }

  function populateDashboards(dashboards: any) {
    if (_.isEmpty(dashboards) || _.isNull(dashboards)) return null
    return (
      sortArrayBy(dashboards?.filter((dashboard: any) => dashboard?.name?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())), 'name')?.map((dashboard: any) => {
        return (
          <div
            key={dashboard?.id}
            onClick={() => dashboardClickHandler(dashboard)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0.5em',
              width: 'fit-content',
              maxWidth: '100%',
              paddingLeft: '1em',
              cursor: 'pointer',
            }}
            className={`${theme.palette.mode === 'dark' ? 'gradientTextHover' : ''} ${(!_.isUndefined(interiorSelectedDashboard) && (dashboard.id === interiorSelectedDashboard?.id)) ? 'gradientText2' : ''}`}
          >
            <StyledSvg
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <DashboardDesignerIcon
                style={{ margin: 'auto' }}
              />
            </StyledSvg>
            <div
              style={{ marginLeft: '0.6em', textAlign: 'left' }}
              className='truncate-ellipsis'
            >
              {dashboard?.name}
            </div>
          </div>
        );
      })
    )
  }

  return (
    <div
      style={{
        width: 'fit-content'
      }}
    >
      <DontSaveDashboardModal
        open={dontSaveDashboardModalBoolean}
        setOpen={setDontSaveDashboardModalBoolean}
        openVisualHandler={() => {
          dashboardOnClickHandler(interiorSelectedDashboard)
          handleClose()
          setDontSaveDashboardModalBoolean(false)
        }}
        warning={`Current changes won't be saved if you open this dashboard. Continue?`}
        title="OPEN DASHBOARD"
      />
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          padding: '0.2em 0.5em 0.2em 0.5em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: _.isEmpty(dashboards) ? newColor.darkGunmetal : theme.palette.text.primary,
          marginLeft: '0.5em',
          fontSize: '0.8em',
          fontWeight: '600',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <StyledSvg
          sx={{
            '& svg, & path': {
              fill: _.isEmpty(dashboards) ? newColor.darkGunmetal : theme.palette.text.primary,
              stroke: _.isEmpty(dashboards) ? newColor.darkGunmetal : theme.palette.text.primary,
            },
          }}
        >
          <OpenFolder />
        </StyledSvg>
        <div
          style={{
            margin: '0.1em 0 0 0.5em',
          }}
        >
          SAVED DASHBOARDS
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
        sx={
          {
            marginTop: '1em',
            "& .MuiPaper-root":
              { background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke, borderRadius: '0.5em', border: 'solid', borderColor: newColor.darkGunmetal },
            "& .MuiList-root":
            {
              minWidth: '17.5em',
              maxWidth: '20em',
              background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke,
              borderWidth: '1px',
              borderRadius: '0.5em',
              borderColor: theme.palette.mode === 'dark' ? newColor.whiteSmoke : 'black', paddingLeft: '0.5em',
              paddingRight: '0.5em',
              color: theme.palette.mode === 'dark' ? 'white' : 'black'
            },
            "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
              borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#3D3D3D', width: '100%'
            },
            ".MuiButtonBase-root:hover": {
              background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.whiteSmoke,
              color: '#ea335f'
            },
          }
        }
      >
        <div
          style={{
            padding: '0.5em 0.5em 1em 0.5em'
          }}
        >
          <SearchBar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder='SEARCH'
            onEnter={(value: any) => { setSearchTerm(value) }}
            borderColor={theme.palette.mode === 'dark' ? 'white' : 'black'}
          />
        </div>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            fontWeight: 900
          }}
        >
          Dashboards
        </div>
        <div className={`explorer-list-box`}>
          {
            populateDashboards(dashboards)
          }
        </div>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0 0.2em 0 0.2em',
            justifyContent: 'space-around'
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              borderRadius: '100vmax',
              textAlign: 'center',
              border: 'solid',
              padding: '0 1em 0 1em',
              cursor: 'pointer'
            }}
            onClick={handleClose}
          >
            CANCEL
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderRadius: '100vmax',
              textAlign: 'center',
              padding: '0 1em 0 1em',
              background: `linear-gradient(90deg, ${newColor.indigo} 10%, ${newColor.jazzberryJam} 100%)`,
              cursor: 'pointer'
            }}
            onClick={() => {
              setDontSaveDashboardModalBoolean(true)
            }}
          >
            OPEN
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
}

export default OpenDashboardMenu;