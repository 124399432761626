import React, { useState, useContext } from 'react'
import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled as muiStyled,
  Box,
  Button,
  useTheme,
} from '@mui/material'
import { toast } from 'react-toastify'

import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import OrganizationIcon from '../../assets/Organization_Icon.svg'
import OrgIconDark from '../../assets/Organization_Icon_dark.svg'
import loadingSVG from '../../assets/loading.svg'
import { createOrganizationApi } from '../../utils/api/queries'
import { isValidOrgName } from '../../utils/validators'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

export default function CreateOrganization(props: any) {
  const { getOrgs } = props
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)
  const [orgName, setOrgName] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCreate = async () => {
    if (!isValidOrgName(orgName)) {
      toast.error(
        'Organization name can only contain alphanumeric characters and underscores.',
      )
      return
    }
    setLoading(true)
    await createOrganizationApi(postWithAccessToken, orgName)
      .then((data: any) => {
        if (data) toast.success('Successfully created an organization.')
        getOrgs()
        setLoading(false)
        setOrgName('')
      })
      .catch((error: any) => {
        toast.error(error?.message)
        setLoading(false)
      })
  }
  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? OrganizationIcon : OrgIconDark}
          alt="Organization"
        />
        <Typography>&nbsp;&nbsp;CREATE AN ORGANIZATION</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <OrgInputGroup>
          <Typography>Organization Name</Typography>
          <InputName
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            placeholder="example: Helika"
          />
        </OrgInputGroup>
        <Button
          className="helikaButtonClass"
          disabled={loading}
          onClick={handleCreate}
        >
          {loading && (
            <img
              width="14px"
              style={{ marginRight: '2px' }}
              className="animate-spin"
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>CREATE ORGANIZATION</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '0.875rem',
}))
const OrgInputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const InputName = muiStyled('input')(({ theme }) => ({
  width: '19.688rem',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))
