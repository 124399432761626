import { useContext, useState } from 'react'
import { styled as muiStyled, Typography, Button, Box } from '@mui/material'
import { toast } from 'react-toastify'

import { Auth0Context } from '../../contexts/Auth0Context'
import loadingSVG from '../../assets/loading.svg'
import {
  bustAllApiKeysCacheApi,
  bustApiKeyCacheApi,
} from '../../utils/api/queries'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))

const BustCacheRow = muiStyled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}))

const StyledInput = muiStyled(Input)({
  flex: 1,
  minWidth: 0,
});

export default function BustCache() {
  const { postWithAccessToken } = useContext(Auth0Context)
  const [loading, setLoading] = useState(false)
  const [apiKeyToBust, setApiKeyToBust] = useState('')

  const bustApiKeyCache = async () => {
    try {
      if (!apiKeyToBust) {
        toast.error('Enter an API Key')
        return
      }
      setLoading(true)
      await bustApiKeyCacheApi(postWithAccessToken, apiKeyToBust)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust API key cache')
            setLoading(false)
            return
          }
          toast.success('API key busted')
          setLoading(false)
        })
    } catch (e) {
      toast.error('Could not bust API key cache')
      setLoading(false)
    }
  }

  async function bustAllApiKeysCache() {
    try {
      setLoading(true)
      bustAllApiKeysCacheApi(postWithAccessToken)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not bust cache')
            setLoading(true)
            return
          }
          toast.success('All API keys busted')
          setLoading(true)
        })
        .catch((error: any) => {
          toast.error('Could not bust cache')
          setLoading(true)
        })
    } catch (e) {
      toast.error('Could not bust cache')
      setLoading(true)
    }
  }

  return (
    <Box
      sx={{
        margin: '1rem 0.5rem 0.5rem 0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <BustCacheRow>
        <label>All API Keys:</label>
        <Button
          onClick={bustAllApiKeysCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </Button>
      </BustCacheRow>

      <BustCacheRow style={{ gap: '1rem', alignItems: 'center' }}>
        <label>
          Specific API Key:
        </label>
        <StyledInput
          placeholder="enter API key"
          value={apiKeyToBust}
          type="text"
          required
          onChange={(e) => setApiKeyToBust(e.currentTarget.value)}
          style={{ width: '55%' }}
        />
        <Button
          onClick={bustApiKeyCache}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Bust Cache</StyledTxt>
        </Button>
      </BustCacheRow>
    </Box>
  )
}
