import React, { useState } from 'react'
import { InputBase, InputAdornment, Stack } from '@mui/material'
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material'
import styled from 'styled-components'
import { newColor } from 'src/consts/colors'

const SearchInputWrapper = styled.div`
  background-color: ${newColor.charcoal};
  border: 1px solid ${newColor.darkGunmetal};
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
`

const StyledInputBase = styled(InputBase)`
  color: ${newColor.white};
  width: 100%;
  font-size: 14px;

  & .MuiInputBase-input {
    padding: 4px 8px 4px 0;
    paddingleft: calc(1em + 32px);
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export type SearchInputProps = {
  onSearch: (search: string) => void
}

const SearchInput = ({ onSearch }: SearchInputProps) => {
  const [search, setSearch] = useState<string>('')
  const [showClearIcon, setShowClearIcon] = useState<string>('none')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowClearIcon(event.target.value === '' ? 'none' : 'flex')
    setSearch(event.target.value)
  }

  const handleClear = () => {
    setSearch('')
    onSearch('')
    setShowClearIcon('none')
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(search)
    }
  }

  return (
    <SearchInputWrapper>
      <StyledInputBase
        placeholder="Search"
        value={search}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        startAdornment={
          <InputAdornment position="start" onClick={() => onSearch(search)}>
            <Stack sx={{ cursor: 'pointer' }}>
              <SearchIcon style={{ color: newColor.jet }} />
            </Stack>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            style={{ display: showClearIcon }}
            onClick={handleClear}
          >
            <Stack sx={{ cursor: 'pointer' }}>
              <ClearIcon style={{ color: newColor.jet }} />
            </Stack>
          </InputAdornment>
        }
      />
    </SearchInputWrapper>
  )
}

export default SearchInput
