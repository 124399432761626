import {
  Avatar,
  Box,
  Button,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Auth0Context } from 'src/contexts/Auth0Context'
import LogoutButton from 'src/components/LogoutButton'
import PassWordPlaceHolder from 'src/assets/password_placeholder.svg'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { actionCreators } from 'src/state'
import { toast } from 'react-toastify'
import loadingSVG from 'src/assets/loading.svg'
import { useActionPopover } from '../ProfileEditToggle'
import PlaceholderAvatarDark from 'src/assets/place_holder_avatar.svg'
import PlaceholderAvatarLight from 'src/assets/avatar_placeholder_light.svg'
import {
  DeleteAvatar,
  resetPassword,
  saveChangeNameApi,
  uploadAvatar,
} from 'src/utils/api/queries'
import { ReactComponent as EditAvatarIcon } from 'src/assets/edit_avatar_icon.svg'
import DeleteIcon from '@mui/icons-material/Delete'
import { _validFileExtensions } from 'src/consts/extension_image'
import { useIsEditing } from '../IsEditingContext'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import { StyledSvg } from '../tabManagement/styled'

const Row: any = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
`
const WrapAvatar: any = styled.div`
  width: fit-content;
  padding-left: 4rem;
  position: relative;
`

const WrapInfo: any = styled.div`
  margin-left: 3rem;
  display: flex;
`

const WrapText: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 4rem;
`
const Label: any = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: fit-content;
`

const Text = muiStyled('p')(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '17px',
    color: theme.palette.text.primary,
  }
})

const InputName = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',

  '&:focus': {
    outline: 'none',
  },
}))

const ActionButton: any = styled.button`
  width: 97px;
  height: 26px;
  background: linear-gradient(272deg, ${newColor.indigo} 1.38%, ${newColor.jazzberryJam} 97.55%);
  border-radius: 17px;
  border: none;
  color: ${newColor.white};
  text-transform: uppercase;
  margin-right: 14px;
  opacity: ${(props: any) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
    background: linear-gradient(272deg, ${newColor.jazzberryJam} 1.91%, ${newColor.indigo} 98.09%);
  }
`

export default function Information({ userData }: { userData: any }) {
  const theme = useTheme()
  const {
    isAuthenticated,
    isLoading,
    patchWithAccessToken,
    getTokenIfNecessary,
  } = useContext(Auth0Context)
  const dispatch = useDispatch()
  const { setUserData } = bindActionCreators(actionCreators, dispatch)
  const { isEdit, setIsEdit } = useActionPopover()
  const [userName, setUserName] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingResetPwd, setLoadingResetPwd] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [imgUploaded, setImgUploaded] = useState<any>(null)
  const [isRemove, setIsRemove] = useState<boolean>(false)
  const { setIsEditing } = useIsEditing()

  const avatarPlaceholder =
    theme.palette.mode === 'dark'
      ? PlaceholderAvatarDark
      : PlaceholderAvatarLight

  let urlAvatar = useMemo(
    () =>
      imgUploaded
        ? URL.createObjectURL(imgUploaded)
        : userData.avatar
          ? `https://${process.env.REACT_APP_AMAZON_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/${userData.avatar}`
          : avatarPlaceholder,
    [imgUploaded, userData.avatar, avatarPlaceholder],
  )

  const resetStateBehavior = () => {
    setDisable(false)
    setLoadingResetPwd(false)
  }

  //Reset state toggle edit information
  useEffect(() => {
    // setImgUploaded(null)
    setIsRemove(false)
    setUserName(userData?.name ? userData?.name : userData?.email)
    resetStateBehavior()
    setMessage('')
  }, [isEdit, userData?.name, userData?.email])

  const handleSaveChangeName = async () => {
    if (!userName) {
      setMessage('This field is required.')
      return
    }

    if (userName !== userData?.name && userName) {
      try {
        await saveChangeNameApi(
          patchWithAccessToken,
          userName,
          userData?.email,
        ).then((res: any) => {
          setUserName(res.results.name)
          setUserData({
            type: 'SET_USER_DATA',
            payload: {
              ...userData,
              name: res.results.name,
              avatar: null,
            },
          })
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const handleUploadAvatar = async () => {
    if (imgUploaded) {
      try {
        await uploadAvatar(getTokenIfNecessary, imgUploaded).then((res) => {
          setUserData({
            type: 'SET_USER_DATA',
            payload: {
              ...userData,
              avatar: res.avatar,
              name: userName,
            },
          })
        })
      } catch (error) {
        console.log('error', error)
        toast.error('Update fail.')
      }
    }
    if (isRemove) {
      const accessToken = await getTokenIfNecessary()
      const URL = `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/avatar`
      try {
        await DeleteAvatar(URL, accessToken)
        setUserData({
          type: 'SET_USER_DATA',
          payload: {
            ...userData,
            avatar: null,
            name: userName,
          },
        })
      } catch (error) {
        toast.error('Delete fail.')
      }
    }
  }

  const handleSave = async () => {
    setLoading(true)
    await handleSaveChangeName()
    await handleUploadAvatar()
    toast.success('Saved successfully!')
    setIsEdit(false)
    setLoading(false)
    resetStateBehavior()
  }

  const handleCancel = () => {
    setIsEdit(false)
    setUserName('')
    resetStateBehavior()
    setImgUploaded(null)
    setIsRemove(false)
    setIsEditing(false)
  }

  const handleOnChangeName = (value: any) => {
    if (value.length <= 90) {
      setUserName(value)
      resetStateBehavior()
    } else {
      setMessage('The name should be less than 90 characters.')
    }
  }

  const handleResetPassword = async () => {
    setDisable(true)
    setLoadingResetPwd(true)
    const body = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: userData.email,
      connection: 'Username-Password-Authentication',
    }
    try {
      await resetPassword(
        `${process.env.REACT_APP_AUTH0_ISSUER_BASE_URL}/dbconnections/change_password`,
        body,
      ).then((resp) => toast.success(resp))
    } catch (error) {
      console.log(error)
      toast.error('Try again later')
    } finally {
      resetStateBehavior()
    }
  }

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRemove(false)
    const file = event.target.files?.item(0)
    const fileName = file?.name
    if (fileName && fileName.length > 0) {
      let blnValid = false
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j]
        if (
          fileName
            .substr(
              fileName.length - sCurExtension.length,
              sCurExtension.length,
            )
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true
          break
        }
      }

      if (!blnValid) {
        alert(
          'Sorry, ' +
          fileName +
          ' is invalid, allowed extensions are: ' +
          _validFileExtensions.join(', '),
        )
        return false
      } else {
        setImgUploaded(file)
      }
    }
  }

  const handleDeleteAvatar = () => {
    setIsRemove(true)
    setImgUploaded(null)
  }

  if (isLoading) {
    return <div>Loading ...</div>
  }

  return isAuthenticated ? (
    <Box
      style={{
        minHeight: '20em',
      }}
    >
      <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <WrapAvatar>
          <div>
            {isEdit && (
              <input
                type="file"
                accept="image/*"
                id="avatar-upload"
                style={{ display: 'none' }}
                onChange={handleAvatarChange}
              />
            )}
            <Avatar
              sx={{
                width: '194px',
                height: '194px',
                position: 'absolute',
                top: '0',
                opacity: isEdit ? '0.5' : '1',
              }}
              alt="Avatar"
              src={isRemove ? avatarPlaceholder : urlAvatar}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '194px',
                height: '194px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                border: 'solid 1px',
                borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
                opacity: isEdit ? '1' : '0',
                pointerEvents: isEdit ? 'all' : 'none',
                ':hover': {
                  bgcolor: 'transparent',
                  cursor: 'default',
                  pointerEvents: 'all',
                },
              }}
            >
              <label htmlFor="avatar-upload">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    pointerEvents: isEdit ? 'all' : 'none',
                    ':hover': {
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                      pointerEvents: 'all',
                    },
                  }}
                >
                  <StyledSvg>
                    <EditAvatarIcon width={20} />
                  </StyledSvg>
                </Button>
              </label>
              {userData.avatar && (
                <Button
                  sx={{
                    color: newColor.white,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    ':hover': {
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={handleDeleteAvatar}
                >
                  <DeleteIcon sx={{ color: 'text.primary' }} />
                </Button>
              )}
            </Box>
          </div>
        </WrapAvatar>
        <WrapInfo>
          <Label>
            <Text>NAME:</Text>
            <Text style={{ opacity: isEdit ? '0' : '1' }}>EMAIL:</Text>
            <Text>PASSWORD:</Text>
            <Text style={{ opacity: isEdit ? '0' : '1' }}>ORGANIZATION:</Text>
          </Label>
          <WrapText>
            {isEdit ? (
              <div style={{ position: 'relative' }}>
                <InputName
                  value={userName}
                  placeholder="Name"
                  onChange={(e) => handleOnChangeName(e.target.value)}
                />
                <span
                  style={{
                    fontSize: '12',
                    color: 'red',
                    position: 'absolute',
                    bottom: '-12px',
                    left: '0',
                  }}
                >
                  {message}
                </span>
              </div>
            ) : (
              <Text>{userData?.name ? userData?.name : userData?.email}</Text>
            )}
            <Text>{userData?.email}</Text>
            <div
              style={{
                fontWeight: 400,
                fontSize: '17px',
                color: theme.palette.text.primary,
                margin: '1em 0 1em 0',
              }}
            >
              <div>
                <img src={PassWordPlaceHolder} alt="password" />
                {isEdit && (
                  <Button
                    disabled={disable || loading}
                    onClick={handleResetPassword}
                    sx={{
                      bgcolor: 'transparent',
                      color: newColor.jazzberryJam,
                      fontSize: '17px',
                      fontFamily: 'Paralucent Medium',
                      alignItems: 'end',
                      opacity: disable || loading ? '0.5' : '1',
                      '&:hover': {
                        bgcolor: 'transparent',
                      },
                      lineHeight: '0.75',
                    }}
                  >
                    Reset Password
                    {loadingResetPwd && (
                      <img
                        width="16px"
                        style={{ marginLeft: '4px' }}
                        className="animate-spin"
                        src={loadingSVG}
                        alt=""
                      />
                    )}
                  </Button>
                )}
              </div>
            </div>
            <Text>{userData?.organization_name}</Text>
            <Text>
              {userData?.organization_members_count}{' '}
              {userData?.organization_members_count === 1
                ? 'Member'
                : 'Members'}
            </Text>
          </WrapText>
        </WrapInfo>
      </Row>
      {isEdit && (
        <Box textAlign="center">
          <ActionButton disabled={disable || loading} onClick={handleSave}>
            {loading && (
              <img
                width="12px"
                className="animate-spin"
                src={loadingSVG}
                alt=""
              />
            )}{' '}
            Save
          </ActionButton>
          <ActionButton disabled={disable || loading} onClick={handleCancel}>
            Cancel
          </ActionButton>
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <LogoutButton />
      </Box>
    </Box>
  ) : null
}
