import { ChangeEvent, useContext, useEffect, useState } from 'react'
import icon_list from '../../../../assets/google_icons_list.json'
import symbols from '@iconify-json/material-symbols/icons.json'

import styled from 'styled-components'
import { handleError } from '../../../../utils/api/errors'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { Column } from '../../../../utils/muiStyledComponents'
import _ from 'lodash'
import { removeTabApi } from '../../../../utils/api/queries'

const Title: any = styled.h3``

const FlexRow: any = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`

const TextWithIcon: any = styled.p`
  padding: 0;
  margin: auto;
  padding-right: 1em;
  font-size: 0.9em;
`

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Select: any = styled.select`
  width: 100%;
  padding: 10px;
`

const RowDiv: any = styled.div`
  display: flex;
  width: 100%;
  justify-items: center;
`

const ColumnDiv: any = styled.div`
  width: 50%;
  padding: 0px 20px;
`

interface Org {
  label: string
  value: number
}

export default function RemoveTab({
  orgs,
  tabs,
  parentTabs,
}: {
  orgs: Org[]
  tabs: any[]
  parentTabs: any
}) {
  const symbols_list: any = symbols

  const { postWithAccessToken } = useContext(Auth0Context)

  const [message, setMessage] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [error, setError] = useState('')
  const [tab, setTab] = useState<any>(null)

  const setSelectedTab = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = tabs.find(
        (tab: any) => tab.id === Number(e?.target.value),
      )

      setTab(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  useEffect(() => {
    if (!tabs) return
    if (_.isEmpty(tabs)) {
      setTab(null)
    } else {
      setTab(tabs[0])
    }
  }, [tabs])

  const removeTab = async () => {
    try {
      if (!org) {
        setMessage('Must select an organization.')
        return
      }
      await removeTabApi(postWithAccessToken, org.value, tab.id)
        .then(() => {
          setMessage('Successfully removed tab.')
        })
        .catch((error: any) => {
          handleError(error, setError, setMessage)
        })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Column>
      <Title>Remove Tab</Title>

      <RowDiv>
        <ColumnDiv>
          <label>Choose Organization:</label>
          <br />
          <br />
          <Select value={org?.value} onChange={setSelectedOrg}>
            {orgs
              ?.sort((a: any, b: any) =>
                a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
              )
              .map((org) => (
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              ))}
          </Select>
        </ColumnDiv>
        <ColumnDiv>
          <label>Choose Tab:</label>
          <br />
          <br />
          <Select value={tab?.value} onChange={setSelectedTab}>
            {tabs
              ?.sort((a: any, b: any) =>
                a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
              )
              .map((tab: any) => (
                <option key={`${tab.id}_${tab.organization_id}`} value={tab.id}>
                  {tab.name}
                </option>
              ))}
          </Select>
        </ColumnDiv>
      </RowDiv>
      <p>
        Parent Tab:{' '}
        {parentTabs &&
          parentTabs.length > 0 &&
          parentTabs.find(
            (parentTab: any) => parentTab.id === tab?.parent_tab_id,
          )?.name}
      </p>
      {org &&
        tab?.icon &&
        (icon_list.icons.map((icon) => icon.name).includes(tab?.icon) ||
          (symbols_list && symbols_list?.icons[tab?.icon?.replace('_', '-')])) ? (
        icon_list.icons.map((icon) => icon.name).includes(tab?.icon) ? (
          <FlexRow>
            <TextWithIcon>Current Icon:</TextWithIcon>
            <i className="material-icons">{tab?.icon}</i>
          </FlexRow>
        ) : (
          <FlexRow>
            <TextWithIcon>Chosen Icon:</TextWithIcon>
            <span className="material-symbols-outlined">{tab?.icon}</span>
          </FlexRow>
        )
      ) : tab === null ? null : (
        <FlexRow>
          <TextWithIcon>Error: Can't find Icon or custom Icon.</TextWithIcon>
        </FlexRow>
      )}
      <br />
      <Button onClick={removeTab}>Remove Tab</Button>
      <Message>{message}</Message>
      <Error>{error}</Error>
    </Column>
  )
}
