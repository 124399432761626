import React from 'react'
import { Box, Typography, TextField, SxProps } from '@mui/material'
import { newColor } from 'src/consts/colors'

interface EditableCodeAreaProps {
  label: string
  value: string
  onChange: (newValue: string) => void
  sx?: SxProps
}

const EditableCodeArea: React.FC<EditableCodeAreaProps> = ({
  label,
  value,
  onChange,
  sx,
}) => {
  const isEmpty = !value.trim() // Check if the value is empty

  return (
    <Box sx={{ mb: isEmpty ? 1 : 2, ...sx }}>
      {' '}
      {/* Reduced margin if empty */}
      <Typography variant="h6" color={newColor.white} sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <TextField
        fullWidth
        multiline
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        sx={{
          backgroundColor: newColor.darkGunmetal,
          borderRadius: '8px',
          border: `1px solid ${newColor.dimGray}`,
          color: newColor.white,
          fontFamily: 'monospace',
          '& .MuiInputBase-input': {
            color: newColor.white,
            fontFamily: 'monospace',
            padding: '10px',
            height: isEmpty ? '50px' : 'auto', // Set min-height for empty fields
            overflowY: 'auto',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove internal border
          },
        }}
        InputProps={{
          style: {
            minHeight: '50px', // Set a minimum height for the text field when it's empty
            height: isEmpty ? '50px' : 'auto',
          },
        }}
      />
    </Box>
  )
}

export default EditableCodeArea
