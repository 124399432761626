import React from 'react'
import { styled as muiStyled } from '@mui/material'
import { newColor } from '../../consts/colors'
import { Spacing } from 'helika-ui-sdk'

export const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& path[fill]': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },

  '& path[stroke]': {
    stroke: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))

export function HelikaDataGridDefaultHeaderComponent(props: any) {

  return (
    <div
      style={{
        padding: Spacing.Spacing_xs,
        width: 'fit-content',
        height: '100%',
        minWidth: '100%',
      }}
    >
      <div
        style={{
          color: props.theme?.palette.text.primary,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'start',
          margin: 'auto',
          width: 'fit-content',
          height: '100%',
          minWidth: '100%'
        }}
      >
        <div
          style={{
            margin: 'auto',
            height: '100%',
            minWidth: '100%',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '140%'
          }}
        >
          {props.colDef?.headerName}
        </div>
      </div>
    </div>
  )
}
