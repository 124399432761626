import React, { useState } from 'react'
import { InputBase, InputAdornment, Stack, useTheme } from '@mui/material'
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material'
import styled from 'styled-components'
import { newColor } from 'src/consts/colors'

// Consistent input styling based on your specifications
const SearchInputWrapper = styled.div<{ themeMode: 'dark' | 'light' }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  width: 300px;
  height: 36px;
  background-color: ${({ themeMode }) =>
    themeMode === 'dark' ? '#1c2025' : newColor.whiteSmoke};
  border: 1px solid #434d5a;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif !important;

  /* Ensure no double border for the wrapper */
  & fieldset {
    border: none;
  }
`

// Custom styled input base with consistent font styles and dynamic text
const StyledInputBase = styled(InputBase)<{ themeMode: 'dark' | 'light' }>`
  color: ${({ themeMode }) =>
    themeMode === 'dark' ? newColor.white : newColor.black};
  width: 100%;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;

  & .MuiInputBase-input {
    padding: 0px 0px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Open Sans', sans-serif !important;
  }

  & ::placeholder {
    font-family: 'Open Sans', sans-serif !important;
    color: ${({ themeMode }) =>
      themeMode === 'dark' ? newColor.lightGray : newColor.jet};
  }

  /* Ensure no double border for the input base */
  & fieldset {
    border: none;
  }
`

export type SearchInputProps = {
  onSearch: (search: string) => void
}

/**
 * SearchInput component that triggers the search function on Enter key press.
 */
const SearchInput: React.FC<SearchInputProps> = ({ onSearch }) => {
  const [search, setSearch] = useState<string>('')
  const [showClearIcon, setShowClearIcon] = useState<boolean>(false)
  const theme = useTheme()

  // Handles the input change event
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setSearch(value)
    setShowClearIcon(value !== '')
  }

  // Handles the search action on Enter key press
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(search)
    }
  }

  // Clears the search input
  const handleClear = () => {
    setSearch('')
    setShowClearIcon(false)
    onSearch('')
  }

  return (
    <SearchInputWrapper themeMode={theme.palette.mode}>
      <StyledInputBase
        placeholder="Search"
        value={search}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        themeMode={theme.palette.mode}
        startAdornment={
          <InputAdornment position="start">
            <Stack
              sx={{
                cursor: 'pointer',
                fontFamily: 'Open Sans, sans-serif !important',
              }}
            >
              <SearchIcon
                style={{
                  color:
                    theme.palette.mode === 'dark'
                      ? newColor.lightGray
                      : newColor.jet,
                  fontFamily: 'Open Sans, sans-serif !important',
                }}
              />
            </Stack>
          </InputAdornment>
        }
        endAdornment={
          showClearIcon && (
            <InputAdornment position="end" onClick={handleClear}>
              <Stack
                sx={{
                  cursor: 'pointer',
                  fontFamily: 'Open Sans, sans-serif !important',
                }}
              >
                <ClearIcon
                  style={{
                    color:
                      theme.palette.mode === 'dark'
                        ? newColor.lightGray
                        : newColor.jet,
                    fontFamily: 'Open Sans, sans-serif !important',
                  }}
                />
              </Stack>
            </InputAdornment>
          )
        }
      />
    </SearchInputWrapper>
  )
}

export default SearchInput
