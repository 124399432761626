import * as ReactTooltip from 'react-tooltip'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Box,
  Grid,
  styled as muiStyled,
  Skeleton,
  useTheme,
} from '@mui/material'
import { Auth0Context } from '../../contexts/Auth0Context'
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
  isOrgAdmin,
} from '../../utils/user'
import {
  getOrgUsers,
  removeInviteUserApi,
  resendInviteUserApi,
} from '../../utils/api/queries'

import RemoveIcon from 'src/assets/remove_circle_icon.svg'
import RemoveHoverIcon from 'src/assets/remove_circle_icon_hover.svg'
import ReInviteIcon from 'src/assets/re_invite.svg'
import ReInviteHoverIcon from 'src/assets/re_invite_hover.svg'
import { AdminSpan } from '../StyledSimpleComponents'
import { toast } from 'react-toastify'
import { newColor } from '../../consts/colors'

const ListHeader = muiStyled(Grid)(() => ({
  borderRadius: '38px',
  background: `linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%)`,
  height: '33.019px',
  width: '97.5%',
  margin: 'auto',
  alignItems: 'center',
}))

const ListContainer = muiStyled(Grid)(({ theme }) => ({
  borderRadius: '38px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  width: '97.5%',
  margin: 'auto',
  paddingTop: '10px',
  paddingBottom: '10px',
  alignItems: 'center',
}))

const ListHeaderCell = muiStyled(Grid)(() => ({
  color: newColor.white,
  width: '100%',
  fontSize: '14px',
  paddingX: '30%',
}))

const StyledGrid = muiStyled(Grid)(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  wordBreak: 'break-all',
  lineHeight: '127%',
  fontSize: '15px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  width: '20%',
  alignItems: 'center',
  borderRadius: '16px',
  paddingLeft: '20%',
}))

export default function InviteUsers({
  invitedUsers,
  onUserRemoved,
}: {
  invitedUsers: any
  onUserRemoved: any
}) {
  const theme = useTheme()

  const userData = useSelector((state: any) => state.user)

  const { getTokenIfNecessary, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [loading, setLoading] = useState(true)
  const { postWithAccessToken, deleteWithAccessToken } =
    useContext(Auth0Context)

  const getInvitedUsers = useCallback(async () => {
    try {
      await getOrgUsers(getTokenIfNecessary).then((res: any) => {
        if (!res) return
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }, [getTokenIfNecessary])

  useEffect(() => {
    if (isLoading || !isAuthenticated || _.isEmpty(userData)) return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData) &&
      !isOrgAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getInvitedUsers()
  }, [isAuthenticated, isLoading, userData, getInvitedUsers])

  const handleReInivteChange = async (id: number, lastInviteAt: string) => {
    const lastInviteTime = new Date(lastInviteAt).getTime()
    const currentTime = new Date().getTime()
    const hoursDiff = (currentTime - lastInviteTime) / (1000 * 60 * 60)

    if (hoursDiff < 24) {
      toast.info('Re-invite can only be sent after 24 hours.')
      return
    }
    try {
      setLoading(true)
      await resendInviteUserApi(postWithAccessToken, id)
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success(`${res.message}`)
          } else {
            toast.error(`Failed: ${res.message}`)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
      setLoading(false)
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  const handleRemoveChange = async (id: number) => {
    try {
      setLoading(true)
      await removeInviteUserApi(deleteWithAccessToken, id)
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success(`${res.message}`)
            onUserRemoved()
          } else {
            toast.error(`Failed: ${res.message}`)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
      setLoading(false)
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <div style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
      <div style={{ minWidth: '80em', width: '99%' }}>
        <ListHeader container>
          <ListHeaderCell xl={6}>EMAIL</ListHeaderCell>
          <ListHeaderCell xl={6}>ACTIONS</ListHeaderCell>
        </ListHeader>
        {!loading ? (
          <Box
            sx={{
              marginTop: '18px',
              maxHeight: '48vh',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              gap: '18px',
            }}
          >
            {invitedUsers &&
              invitedUsers.map(
                ({ email, id, last_invite_at }: any, index: number) => {
                  const lastInviteTime = new Date(last_invite_at).getTime()
                  const currentTime = new Date().getTime()
                  const hoursDiff =
                    (currentTime - lastInviteTime) / (1000 * 60 * 60)
                  const isReinviteDisabled = hoursDiff < 24
                  return (
                    <ListContainer container key={index}>
                      <Grid
                        xl={6}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Box width={'150px'} textAlign={'left'}>
                          {email}
                        </Box>
                      </Grid>
                      <Grid xl={6}>
                        <AdminSpan
                          data-tooltip-id="re-invite-email"
                          data-tooltip-content={'Re Invite Email'}
                          id="adminIcon"
                        >
                          <ReactTooltip.Tooltip id="re-invite-email" />
                          <img
                            onClick={() =>
                              handleReInivteChange(id, last_invite_at)
                            }
                            id="adminIconNonHover"
                            src={ReInviteIcon}
                            alt="ReInviteIcon"
                            style={{
                              cursor: isReinviteDisabled
                                ? 'not-allowed'
                                : 'pointer',
                              opacity: isReinviteDisabled ? 0.5 : 1,
                            }}
                          />
                          <img
                            id="adminIconHover"
                            onClick={() =>
                              handleReInivteChange(id, last_invite_at)
                            }
                            src={ReInviteHoverIcon}
                            alt="ReInviteIcon"
                            style={{
                              cursor: isReinviteDisabled
                                ? 'not-allowed'
                                : 'pointer',
                              opacity: isReinviteDisabled ? 0.5 : 1,
                            }}
                          />
                        </AdminSpan>
                        <AdminSpan
                          data-tooltip-id="remove-email"
                          data-tooltip-content={'Remove Email'}
                          id="adminIcon"
                        >
                          <ReactTooltip.Tooltip id="remove-email" />
                          <img
                            onClick={() => handleRemoveChange(id)}
                            id="adminIconNonHover"
                            src={RemoveIcon}
                            alt="removeIcon"
                          />
                          <img
                            id="adminIconHover"
                            onClick={() => handleRemoveChange(id)}
                            src={RemoveHoverIcon}
                            alt="removeIcon"
                          />
                        </AdminSpan>
                      </Grid>
                    </ListContainer>
                  )
                },
              )}
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: 'center',
                borderRadius: '16px',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.ebony
                    : newColor.cultured,
                height: 'auto',
                width: '97.5%',
                paddingBottom: '16px',
                margin: '1rem auto auto',
              }}
            >
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: 'center',
                borderRadius: '16px',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.ebony
                    : newColor.cultured,
                height: 'auto',
                width: '97.5%',
                paddingBottom: '16px',
                margin: '1rem auto auto',
              }}
            >
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: 'center',
                borderRadius: '16px',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? newColor.ebony
                    : newColor.cultured,
                height: 'auto',
                width: '97.5%',
                paddingBottom: '16px',
                margin: '1rem auto auto',
              }}
            >
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
              <StyledGrid item xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingX: '30%',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  <Skeleton width={'60%'} />
                  <Skeleton width={'20%'} />
                  <Skeleton width={'20%'} />
                </Box>
              </StyledGrid>
            </Grid>
          </>
        )}
      </div>
    </div>
  )
}
