//imports
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useTheme } from '@mui/material'

//services
import { getConnections, searchConfigList } from '../../../utils/api/queries'

//state
import { Auth0Context } from '../../../contexts/Auth0Context'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../state'

//components
import {
  Box,
  Button,
  Grid,
  Typography,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from '@mui/material'
import LoadingComponent from '../../../components/LoadingComponent'

//assets
import '../../../App.css'
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import configListHeaderIcon from '../../../assets/support_header_text_icon.svg'
import gear_icon from '../../../assets/gear_icon.svg'
import gear_icon_disabled from '../../../assets/gear_icon_disabled.svg'
import GameManagementActionsDropdown from '../../../components/GameManagementActionsDropdown'
import CreateConnectionModal from '../../../components/popups/CreateConnectionModal'
import EditConnectionModal from '../../../components/popups/EditConnectionModal'
import CreateConfigModal from '../../../components/popups/CreateConfigModal'
import EditParentConfig from '../../../components/popups/EditParentConfig'
import OwnerIcon from '../../../assets/icons/config_list_icons/owner_icon.svg'
import CreatedIcon from '../../../assets/icons/config_list_icons/created_icon.svg'
import EnvironmentIcon from '../../../assets/icons/config_list_icons/environment_icon.svg'
import ConnectionIcon from '../../../assets/icons/config_list_icons/connection_icon.svg'
import SchemaIcon from '../../../assets/icons/config_list_icons/schema_icon.svg'
import TagsIcon from '../../../assets/icons/config_list_icons/tags_icon.svg'

import OwnerIconLight from '../../../assets/icons/config_list_icons/owner_icon_light.svg'
import CreatedIconLight from '../../../assets/icons/config_list_icons/created_icon_light.svg'
import EnvironmentIconLight from '../../../assets/icons/config_list_icons/environment_icon_light.svg'
import ConnectionIconLight from '../../../assets/icons/config_list_icons/connection_icon_light.svg'
import SchemaIconLight from '../../../assets/icons/config_list_icons/schema_icon_light.svg'
import TagsIconLight from '../../../assets/icons/config_list_icons/tags_icon_light.svg'
import { getDeepCopy } from '../../../utils/string'
import { useIsDisabled } from 'src/utils/disableDemo'
import { newColor } from '../../../consts/colors'

//----------------------------------------------------------------------------------------------------------

export default function ConfigList() {
  //services
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  //state
  const { getTokenIfNecessary } = useContext(Auth0Context)
  const { setGameManagementState } = bindActionCreators(
    actionCreators,
    dispatch,
  )
  const CONFIG_LIST = useSelector(
    (state: any) => state.gameMgmtData.CONFIG_LIST,
  )

  //loadPage
  const [loadPage, setLoadPage] = useState<number>(2)
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false)

  //display
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [configList, setConfigList] = useState<any>()
  const [filter, setFilter] = useState<string>('name')
  let prevFilter = localStorage.getItem('CONFIG_LIST_ENVIRONMENT_FILTER')
  const [environmentFilter, setEnvironmentFilter] = useState<string>(
    prevFilter ? prevFilter : 'prod',
  )
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [currentlySearchedTerm, setCurrentlySearchedTerm] = useState<string>('')
  const [showCreateConnectionModal, setShowCreateConnectionModal] =
    useState<boolean>(false)
  const [showCreateConfigModal, setShowCreateConfigModal] =
    useState<boolean>(false)
  const [showEditConnectionModal, setShowEditConnectionModal] =
    useState<boolean>(false)
  const [showEditParentConfigModal, setShowEditParentConfigModal] =
    useState<boolean>(false)
  const [selectedConfig, setSelectedConfig] = useState<any>()
  const [connectionsList, setConnectionsList] = useState<any[]>([])

  const isDisabled = useIsDisabled()

  const pageSize = 10

  function getOriginalConfigData(oldId: any) {
    return getDeepCopy(CONFIG_LIST).find((config: any) => config.id === oldId)
  }

  //save environment filter to local storage
  useEffect(() => {
    localStorage.setItem('CONFIG_LIST_ENVIRONMENT_FILTER', environmentFilter)
  }, [environmentFilter])

  useEffect(() => {
    async function getAllConnections() {
      let connections = await getConnections(getTokenIfNecessary)
      if (!_.isEmpty(connections?.results)) {
        setConnectionsList(connections?.results)
      }
    }

    getAllConnections()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //get config data
  useEffect(() => {
    search(filter, '', 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentFilter])

  //search configs given search term and filter
  async function search(
    filter: string,
    search_term: string,
    page?: number,
    preventUnneccessaryCall?: boolean,
  ) {
    // unneccessary call
    if (
      preventUnneccessaryCall &&
      search_term === currentlySearchedTerm &&
      page === 1
    )
      return

    setIsLoading(true)

    let envFilter: any = null
    switch (environmentFilter) {
      case 'staging':
        envFilter = 'staging'
        break
      case 'prod':
        envFilter = 'prod'
        break
      default: {
        envFilter = null
      }
    }

    //save searched term
    setCurrentlySearchedTerm(search_term)

    let params: any = {
      page_size: pageSize,
      page: page,
      environment: envFilter,
    }

    //specific term search
    if (!_.isEmpty(search_term)) {
      params[filter] = search_term
    }
    let configList = await searchConfigList(getTokenIfNecessary, params)
    setLoadPage(page ? page + 1 : 1)
    setCanLoadMore(configList && configList.length === pageSize)
    if (page === 1) {
      setGameManagementState({
        type: 'SET_CONFIG_LIST',
        payload: configList,
      })
    } else {
      setGameManagementState({
        type: 'LOAD_MORE_CONFIG_LIST',
        payload: configList,
      })
    }
    setIsLoading(false)
  }

  //set config list display
  useEffect(() => {
    if (!CONFIG_LIST) return
    setConfigList(
      getDeepCopy(CONFIG_LIST)?.map((config: any) => {
        let connections: any = _.uniqBy(
          config.sheet_mappings,
          (item: any) => item.host,
        ).map((item: any) => item.host)
        if (connections) {
          connections = connections.toString()
        }
        let schemas: any = _.uniqBy(
          config.sheet_mappings,
          (item: any) => item.schema_name,
        ).map((item: any) => item.schema_name)
        if (schemas) {
          schemas = schemas.toString()
        }
        return (
          <Box key={config.id}>
            <Box
              className={
                theme.palette.mode === 'dark'
                  ? 'circleBackgroundLight'
                  : 'circleBackgroundLight'
              }
              sx={{
                textAlign: 'left',
                paddingX: '1em',
                margin: '0 0 0.5em 0',
                color: 'white',
                fontSize: '1.3em',
              }}
            >
              {config.name?.toUpperCase()}
            </Box>
            <Box
              key={config?.id}
              onClick={() => setSelectedConfig(config)}
              sx={{
                textAlign: 'left',
                marginBottom: '1em',
                padding: '1em',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                borderStyle: 'solid',
                borderColor: config?.bad_commit ? 'red' : newColor.outerSpace,
                borderRadius: '0.5em',
                borderWidth: '1px',
              }}
            >
              <Grid container spacing={2} wrap="nowrap">
                <Grid item xs={4}>
                  <div style={{ height: '100%' }} className="column">
                    <div
                      style={{
                        height: '100%',
                        minHeight: '10em',
                        textAlign: 'start',
                        fontWeight: 700,
                      }}
                      className="ellipsisWithWrap"
                    >
                      <div style={{ fontSize: '1em', paddingBottom: '0.3em' }}>
                        DESCRIPTION
                      </div>
                      <div
                        style={{
                          maxHeight: '100%',
                          fontWeight: 400,
                          paddingLeft: '1em',
                        }}
                        className="clamp20"
                      >
                        {config.description}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    borderRadius: '0.5em',
                    width: '100%',
                    background:
                      theme.palette.mode === 'dark' ? newColor.graphite : newColor.gainsboro,
                    marginTop: '1em',
                    padding: '0 1em 1em 0',
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? OwnerIcon
                              : OwnerIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>OWNER:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {config.created_by_email}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? CreatedIcon
                              : CreatedIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>CREATED:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {config.created_at
                        ? moment
                          .tz(config.created_at, 'UTC')
                          .tz(moment.tz.guess(true))
                          .format('YYYY-MM-DD h:mm A')
                        : ''}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? EnvironmentIcon
                              : EnvironmentIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>
                          ENVIRONMENT:
                        </div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {config.environment}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? ConnectionIcon
                              : ConnectionIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>
                          CONNECTION:
                        </div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isDisabled ? '****' : connections}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? SchemaIcon
                              : SchemaIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>SCHEMA:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {isDisabled ? '****' : schemas}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          src={
                            theme.palette.mode === 'dark'
                              ? TagsIcon
                              : TagsIconLight
                          }
                          alt=""
                          style={{ marginRight: '0.5em' }}
                        />
                        <div style={{ margin: 'auto 0 auto 0' }}>TAGS:</div>
                      </Box>
                    </Grid>
                    <Divider
                      orientation="horizontal"
                      role="presentation"
                      sx={{
                        background:
                          theme.palette.mode === 'dark' ? 'white' : 'black',
                        width: '1px',
                        marginRight: '-1px',
                      }}
                    />
                    <Grid
                      item
                      xs={8}
                      sx={{
                        paddingLeft: '1em',
                        marginY: '0.3em',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {config.tags?.toString()}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                      }}
                    >
                      <div
                        className="flexrow"
                        style={{
                          height: 'fit-content',
                          justifyContent: 'center',
                        }}
                      >
                        <div className="flexcol">
                          {isDisabled ? (
                            <img
                              src={gear_icon_disabled}
                              alt=""
                              style={{
                                margin: 'auto 0.3em auto 0',
                                cursor: 'not-allowed',
                              }}
                            />
                          ) : (
                            <img
                              onClick={() => {
                                setSelectedConfig(config)
                                setShowEditParentConfigModal(true)
                              }}
                              src={gear_icon}
                              alt=""
                              style={{
                                margin: 'auto 0.3em auto 0',
                                cursor: 'pointer',
                              }}
                            />
                          )}
                        </div>
                        <Button
                          className="helikaButtonClass"
                          sx={{ paddingTop: 0, paddingBottom: 0 }}
                          onClick={() => {
                            navigate(
                              `/game_management/live_ops/config/${config?.id}`,
                            )
                          }}
                        >
                          See History
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CONFIG_LIST])

  return (
    <Box
      sx={{
        background: theme.palette.mode === 'dark' ? 'black' : newColor.cultured,
        minHeight: '100vh',
        minWidth: '60em',
        maxWidth: '200em',
        margin: 'auto',
      }}
      display={'flex'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'space-between',
          marginBottom: '1em',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{ padding: '0 0 0 0', maxWidth: '100%' }}
        >
          <img
            src={configListHeaderIcon}
            style={{ height: '1.5em', margin: 'auto 0 auto 1em' }}
            alt=""
          />
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: 'white',
              margin: 'auto 0 auto 0.5em',
              fontSize: '1.5em',
              fontWeight: 700,
            }}
          >
            CONFIG LIST
          </Typography>
        </Box>
      </Box>

      {/* search */}
      <Box
        sx={{ padding: '0 1em 1em 1em', justifyContent: 'space-between' }}
        display={'flex'}
        flexDirection={'row'}
      >
        <input
          className={
            theme.palette.mode === 'dark' ? 'helikaInputDark' : 'helikaInput'
          }
          type="text"
          style={{
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            backgroundColor:
              theme.palette.mode === 'dark' ? newColor.midnight : 'white',
            boxShadow: 'none',
            borderStyle: 'solid',
            borderRadius: '0.3em',
            borderWidth: '1px',
            marginTop: 'auto',
            paddingLeft: '2em',
            padding: '0.55em',
            width: '100%',
            marginRight: '1em',
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await search(filter, searchTerm, 1, true)
            }
          }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.currentTarget.value)
          }}
          placeholder="SEARCH"
        />
        <FormControl
          size="small"
          sx={{ width: '15em', margin: 'auto 0.5em auto auto' }}
        >
          <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
            Search By:
          </p>
          <Select
            labelId="search-filter"
            id="search-filter"
            value={filter}
            //label="Search By"
            size="small"
            onChange={(event: SelectChangeEvent) =>
              setFilter(event.target.value as string)
            }
            style={{ height: '2em' }}
          >
            <MenuItem value={'name'}>Name</MenuItem>
            <MenuItem value={'description'}>Description</MenuItem>
            <MenuItem value={'tag'}>Tag</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          size="small"
          sx={{ width: '15em', marginTop: 'auto', marginBottom: 'auto' }}
        >
          <p style={{ fontSize: '0.8em', margin: 0, textAlign: 'left' }}>
            Environment:
          </p>
          <Select
            labelId="environment-filter"
            id="environment-filter"
            value={environmentFilter}
            //label="Search By"
            size="small"
            onChange={(event: SelectChangeEvent) =>
              setEnvironmentFilter(event.target.value as string)
            }
            style={{ height: '2em' }}
          >
            <MenuItem value={'No Filter'}>All</MenuItem>
            <MenuItem value={'staging'}>Staging</MenuItem>
            <MenuItem value={'prod'}>Production</MenuItem>
          </Select>
        </FormControl>
        <Button
          style={{ marginTop: 'auto' }}
          className="helikaButtonClass"
          variant="contained"
          size="small"
          sx={{ width: '14em', marginLeft: 2 }}
          onClick={async () => await search(filter, searchTerm, 1, true)}
        >
          Search
        </Button>
        <GameManagementActionsDropdown
          options={[
            'Create Connection',
            'Edit Connection',
            'Create New Config',
          ]}
          optionValues={[
            () => setShowCreateConnectionModal(true),
            () => setShowEditConnectionModal(true),
            () => setShowCreateConfigModal(true),
          ]}
        />
      </Box>
      <CreateConnectionModal
        open={showCreateConnectionModal}
        setOpen={setShowCreateConnectionModal}
      />
      <EditConnectionModal
        open={showEditConnectionModal}
        setOpen={setShowEditConnectionModal}
        db_connections_list={connectionsList}
      />
      <CreateConfigModal
        open={showCreateConfigModal}
        setOpen={setShowCreateConfigModal}
        db_connections_list={connectionsList}
      />
      <EditParentConfig
        open={showEditParentConfigModal}
        setOpen={setShowEditParentConfigModal}
        selected_config={selectedConfig}
        db_connections_list={connectionsList}
        getOriginalConfigData={getOriginalConfigData}
      />

      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          margin: '1em',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        }}
      >
        {/* config list */}
        <Box style={{ padding: '1em 1em 0 1em', height: '100%' }}>
          {configList}
        </Box>

        {/* load more button */}
        {isLoading ? (
          <LoadingComponent />
        ) : (
          canLoadMore && (
            <Button
              className="helikaButtonClass"
              sx={{ width: '10em', margin: '1em auto 2em auto' }}
              onClick={() => search(filter, currentlySearchedTerm, loadPage)}
            >
              Load More
            </Button>
          )
        )}
      </Box>
    </Box>
  )
}
