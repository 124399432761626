import { ChangeEvent, useContext, useEffect, useState } from 'react'
import icon_list from '../../../../assets/google_icons_list.json'
import icons from '@iconify-json/material-symbols/icons.json'
import _ from 'lodash'

import styled from 'styled-components'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { handleError } from '../../../../utils/api/errors'
import { addTabApi } from '../../../../utils/api/queries'

const Title: any = styled.h3``

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Subtext: any = styled.p`
  font-size: 0.9em;
  padding-left: 20%;
  padding-right: 20%;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`
const FlexRow: any = styled.div`
  display: flex;
  flex-direction: row;
`

const TextWithIcon: any = styled.p`
  padding: 0;
  margin: auto;
  padding-right: 1em;
  font-size: 0.9em;
`

interface Org {
  label: string
  value: number
}

export default function AddTab({
  orgs,
  parentTabs,
}: {
  orgs: Org[]
  parentTabs: any
}) {
  const { postWithAccessToken } = useContext(Auth0Context)
  const symbols_list: any = icons

  const [org, setOrg] = useState<Org | null>(null)
  const [newTabName, setNewTabName] = useState('')
  const [icon, setIcon] = useState('')
  const [description, setDescription] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [parentTab, setParentTab] = useState<any>(0)
  const [newIframeURL, setNewIframeURL] = useState(
    'https://app.sigmacomputing.com/embed/',
  )
  const [newDashboardName, setNewDashboardName] = useState('')
  const [paramChainId, setParamChainId] = useState('')
  const [paramCollection, setParamCollection] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  useEffect(() => {
    if (!parentTabs || parentTabs.length < 1) return
    setParentTab(parentTabs[0].id)
  }, [parentTabs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )

      setOrg(newOption || null)
      setMessage('')
      setError('')
    }
  }

  const dbAddTab = async () => {
    try {
      if (!org || !org.value) {
        setMessage('Must select an organization.')
        return
      }
      if (!newDashboardName) {
        setMessage('Dashboard must have a name.')
        return
      }
      if (newTabName.length > 30) {
        setMessage('Tab name too long, max 30 characters.')
        return
      }
      if (newTabName.length < 3) {
        setMessage('Tab name must be at least 3 characters')
        return
      }
      if (
        newIframeURL.length <= 37 ||
        !newIframeURL.startsWith('https://app.sigmacomputing.com/embed/')
      ) {
        setMessage('Invalid sigma url.')
        return
      }
      if (
        !icon_list.icons.map((icon) => icon.name).includes(icon) ||
        (symbols_list && symbols_list?.icons[icon?.replace('_', '-')])
      ) {
        setMessage(
          'Cannot find icon. Please contact Helika if this is a valid icon you want to use.',
        )
      }

      const create_tab_body: any = {
        name: newTabName,
        icon: icon,
        description: description,
        organization_id: org.value,
        iframe_link: newIframeURL?.trim(),
        parent_tab_id: parentTab,
        priority: 10,
        dashboard_name: newDashboardName,
      }

      let defaultParams: any = {}
      if (!_.isEmpty(paramChainId) || !_.isEmpty(paramCollection)) {
        if (!_.isEmpty(paramChainId)) {
          defaultParams['chain-id'] = paramChainId
        }
        if (!_.isEmpty(paramCollection)) {
          defaultParams['prm-collection'] = paramCollection
        }
      }
      if ('chain-id' in defaultParams || 'prm-collection' in defaultParams) {
        create_tab_body.default_params = JSON.stringify(defaultParams)
      }
      await addTabApi(
        postWithAccessToken,
        newTabName,
        icon,
        description,
        org.value,
        newIframeURL?.trim(),
        parentTab,
        newDashboardName,
      )
        .then(() => {
          setMessage('Successfully created tab.')
        })
        .catch((error: any) => {
          handleError(error, setError, setMessage)
        })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Wrapper>
      <Title>Add Tab</Title>
      <br />

      <label>Enter Tab Name:</label>
      <Input
        placeholder="e.g. In-Game Analytics"
        value={newTabName}
        type="text"
        required
        onChange={(e: any) => setNewTabName(e.currentTarget.value)}
      />

      <label>Enter Tab Description:</label>
      <Input
        placeholder="e.g. New in game analytics tab"
        value={description}
        type="text"
        required
        onChange={(e: any) => setDescription(e.currentTarget.value)}
      />

      <label>Enter Google Fonts Icon Tab:</label>
      <Subtext>
        (You can browse for icons on their{' '}
        <a
          href="https://fonts.google.com/icons"
          target="_blank"
          rel="noreferrer"
        >
          website
        </a>
        . Choose an icon, click the symbol, and then click on 'Android' on the
        right drawer. The displayed text is the tag and should be entered
        below.)
      </Subtext>
      {icon && icon_list.icons.map((icon) => icon.name).includes(icon) ? (
        <FlexRow>
          <TextWithIcon>Chosen Icon:</TextWithIcon>
          <i className="material-icons">{icon?.split(' ')[0]}</i>
        </FlexRow>
      ) : symbols_list && symbols_list?.icons[icon?.replace('_', '-')] ? (
        <FlexRow>
          <TextWithIcon>Chosen Icon:</TextWithIcon>
          <span className="material-symbols-outlined">{icon}</span>
        </FlexRow>
      ) : null}
      <Input
        placeholder="e.g. star"
        value={icon}
        type="text"
        required
        onChange={(e: any) => setIcon(e.currentTarget.value)}
      />

      <label>Choose A Parent Tab:</label>
      <Select
        required
        value={parentTab.name}
        onChange={(e: any) => setParentTab(e.target.value)}
      >
        {parentTabs.map((parentTab: any) => (
          <option key={parentTab.id} value={parentTab.id}>
            {parentTab.name}
          </option>
        ))}
      </Select>

      <label>Choose Organization:</label>
      <Select value={org?.value} onChange={setSelectedOrg}>
        {orgs
          ?.sort((a: any, b: any) =>
            a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
          )
          .map((org) => (
            <option key={org.value} value={org.value}>
              {org.label}
            </option>
          ))}
      </Select>

      <label>Dashboard Name:</label>
      <Input
        placeholder="My First Dashboard"
        value={newDashboardName}
        type="text"
        required
        onChange={(e: any) => setNewDashboardName(e.currentTarget.value)}
      />

      <label>Dashboard URL:</label>
      <Input
        placeholder="e.g. https://app.sigmacomputing..."
        value={newIframeURL}
        type="text"
        required
        onChange={(e: any) => setNewIframeURL(e.currentTarget.value)}
      />

      <label>Default Params Chain Id</label>
      <Input
        placeholder={`e.g. public`}
        value={paramChainId}
        type="text"
        required
        onChange={(e: any) => setParamChainId(e.currentTarget.value)}
      />
      <label>Default Params Collection</label>
      <Input
        placeholder={`e.g. Helika`}
        value={paramCollection}
        type="text"
        required
        onChange={(e: any) => setParamCollection(e.currentTarget.value)}
      />
      <br />

      <Button onClick={dbAddTab}>Add Tab</Button>
      <Message>{message}</Message>
      <Error>{error}</Error>

      <br />
      <br />
      <br />
      <br />
    </Wrapper>
  )
}
