//imports
import React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useTheme } from '@emotion/react'
import { toast } from 'react-toastify';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css';

//context
import { Auth0Context } from 'src/contexts/Auth0Context'

//services
import { getExploreDashboards, getOrgQueries, getUserQueries, getVisualizations, updateExploreDashboards } from '../../../utils/api/queries'

//components
import { Box, Button } from '@mui/material';
import { newColor } from 'src/consts/colors'
import { ReactComponent as AddVisual } from 'src/assets/add_visual_icon.svg'
import { ReactComponent as NewDashboard } from 'src/assets/new_dashboard.svg'
import { DashboardGridstack } from './DashboardGridstack';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled'
import SaveDashboardModal from 'src/components/popups/SaveDashboardModal';
import DeleteDashboardModal from 'src/components/popups/DeleteDashboardModal';
import PublishDashboardModal from 'src/components/popups/PublishDashboardModal';
import DashboardDesignerSaveMenu from 'src/components/DashboardDesignerSaveMenu';
import UnpublishDashboardModal from 'src/components/popups/UnpublishDashboardModal';
import { ExplorerHeader } from 'src/components/Explore/ExplorerHeader';
import OpenDashboardMenu from 'src/components/OpenDashboardMenu';
import DontSaveDashboardModal from 'src/components/popups/DontSaveDashboardModal';

export default function DashboardDesigner() {

  const theme: any = useTheme()

  const {
    isAuthenticated,
    getWithAccessToken,
    patchWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [dashboardName, setDashboardName] = useState<string>('New Dashboard');
  const [valueWidth, setValueWidth] = useState<number>(0);
  const [dashboards, setDashboards] = useState<any[]>([]);
  const [visuals, setVisuals] = useState<any[]>([]);
  const [lastSaved, setLastSaved] = useState<Date | null>();
  const [isLoadingQueries, setIsLoadingQueries] = useState<boolean>(false);
  const [showSaveDashboardModal, setShowSaveDashboardModal] = useState<boolean>(false);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] = useState<boolean>(false);
  const [showPublishDashboardModal, setShowPublishDashboardModal] = useState<boolean>(false);
  const [showUnpublishDashboardModal, setShowUnpublishDashboardModal] = useState<boolean>(false);
  const [addingVisual, setAddingVisual] = useState<boolean>(false);
  const [selectedDashboard, setSelectedDashboard] = useState<any>();
  const [dontSaveDashboardModalBoolean, setDontSaveDashboardModalBoolean] = useState<boolean>(false)
  const gridRef: any = useRef()

  const [userQueries, setUserQueries] = useState<any[]>([]);
  const [orgQueries, setOrgQueries] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([])
  const [maxRows, setMaxRows] = useState<number>(12)

  async function addRows() {
    setMaxRows(prevState => {
      let newState = prevState + 4;
      GridStack.init({
        resizable: {
          handles: 'e,se,s,sw,w,nw,n,ne'
        },
        float: true,
        cellHeight: '10em',
        column: 5,
        minRow: 8,
        maxRow: newState
      }, '.controlled')
      let grid = gridRef.current
      grid.engine.maxRow = newState
      grid.opts.maxRow = newState
      return newState
    })
  }

  //update query list and visual list
  const updateLists = useCallback(async (id?: any) => {

    setIsLoadingQueries(true)

    try {
      let respUserQueries: any = await getUserQueries(getWithAccessToken)
      let respOrgQueries: any = await getOrgQueries(getWithAccessToken)
      let respVisualizations: any = await getVisualizations(getWithAccessToken)
      let respDashboards: any = await getExploreDashboards(getWithAccessToken)

      setUserQueries(respUserQueries?.filter((query: any) => !_.isEmpty(query.query)));
      setOrgQueries(respOrgQueries?.filter((query: any) => !_.isEmpty(query.query)));
      setVisuals(respVisualizations)
      setDashboards(respDashboards)
      setIsLoadingQueries(false)
      let prevSelectedDashboard = respDashboards?.find((dashboardItem: any) => dashboardItem.id === id)
      setSelectedDashboard(prevSelectedDashboard)
    } catch (e: any) {
      setIsLoadingQueries(false)
    }
  }, [getWithAccessToken])

  //initial get queries
  useEffect(() => {

    async function getLists() {
      if (!isAuthenticated || isLoading) return
      await updateLists();
    }

    if (!isAuthenticated || isLoading) return
    getLists()
  }, [isAuthenticated, isLoading, updateLists])

  //set selected dashboard
  async function dashboardClickHandler(dashboard: any) {
    setItems([])
    setSelectedDashboard((prevState: any) => {
      if (dashboard === prevState) {
        setItems(dashboard?.data?.config ? dashboard.data?.config : [])
        return prevState;
      }
      return dashboard
    })
    gridRef.current = gridRef.current ||
      GridStack.init({ float: true, cellHeight: '10em', column: 5, minRow: 8 }, '.controlled')
    const grid = gridRef.current
    grid.removeAll(false)
  }

  useEffect(() => {
    if (_.isNil(selectedDashboard)) {
      setDashboardName('New Dashboard');
      setLastSaved(undefined)
      setItems([])
    } else {
      setDashboardName(selectedDashboard?.name);
      setLastSaved(selectedDashboard?.updated_at)
      setItems(selectedDashboard?.data?.config ? selectedDashboard.data?.config : [])
    }
  }, [selectedDashboard])

  async function updateDashboardHandler(callback: any) {
    if (_.isUndefined(selectedDashboard)) {
      toast.error('Could not find dashboard to save')
      return
    } else if (_.isEmpty(dashboardName?.trim())) {
      toast.error('Dashboard name must not be empty')
      return
    }

    const grid = gridRef.current
    let saved = grid.save()

    let data = items.map((itemObject: any) => {
      let foundCurrent = _.find(saved, (searchObject: any) => searchObject.id === itemObject.id)
      if (foundCurrent) {
        return Object.assign({}, itemObject, foundCurrent)
      }
      return itemObject
    })

    let args = {
      dashboard_id: selectedDashboard?.id,
      private: true,
      published: false,
      data: { config: data },
      name: dashboardName
    }

    await updateExploreDashboards(patchWithAccessToken, args).then(async resp => {
      setLastSaved(resp.results.updated_at)
      toast.success('Dashboard was saved')
      try {
        setDashboards(prevState => {
          if (!prevState) return resp.results;
          return prevState.map(dashboard => {
            if (dashboard.id === resp.results.id) {
              return resp.results;
            }
            return dashboard
          })
        })
        if (typeof callback === 'function') {
          callback()
        }
      } catch (e) {
        toast.error('Could not save dashboard, refresh and try again')
        if (typeof callback === 'function') {
          callback()
        }
      }
    }).catch(e => {
      toast.error('Could not save dashboard')
      toast.error(e)
    })
  }

  return (
    <div
      id='explore-box'
      style={{
        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
        height: '100%',
        maxHeight: '100%',
        scrollbarGutter: 'stable both-sides',
        minWidth: '60em'
      }}
    >
      <DontSaveDashboardModal
        open={dontSaveDashboardModalBoolean}
        setOpen={setDontSaveDashboardModalBoolean}
        openVisualHandler={() => {
          setSelectedDashboard(undefined)
          setItems([])
          setDontSaveDashboardModalBoolean(false)
        }}
        warning={`Current changes won't be saved if you start a new dashboard. Continue?`}
        title="CREATE NEW DASHBOARD"
      />
      <SaveDashboardModal
        dashboardName={dashboardName}
        open={showSaveDashboardModal}
        setOpen={setShowSaveDashboardModal}
        setLastSaved={setLastSaved}
        updateDashboardList={updateLists}
        items={items}
        gridRef={gridRef}
        setDashboardName={setDashboardName}
      />
      <DeleteDashboardModal
        selectedDashboard={selectedDashboard}
        open={showDeleteDashboardModal}
        setOpen={setShowDeleteDashboardModal}
        updateDashboardList={updateLists}
      />
      <PublishDashboardModal
        selectedDashboard={selectedDashboard}
        open={showPublishDashboardModal}
        setOpen={setShowPublishDashboardModal}
        dashboardName={dashboardName}
        setLastSaved={setLastSaved}
        updateLists={updateLists}
      />
      <UnpublishDashboardModal
        selectedDashboard={selectedDashboard}
        open={showUnpublishDashboardModal}
        setOpen={setShowUnpublishDashboardModal}
        dashboardName={dashboardName}
        setLastSaved={setLastSaved}
        updateLists={updateLists}
      />
      <DeleteDashboardModal
        selectedDashboard={selectedDashboard}
        open={showDeleteDashboardModal}
        setOpen={setShowDeleteDashboardModal}
        updateDashboardList={updateLists}
      />
      <ExplorerHeader />
      <div
        style={{
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          minHeight: 'calc(100% - 4.5em)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: `100%`,
            paddingBottom: '0.5em',
            flex: 1,
            maxHeight: 'calc(100vh)',
            overflowY: 'auto'
          }}
        >
          <div
            id='header-box'
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: '100%',
              maxWidth: '100%',
              padding: '1em 0.5em 0.5em 0.5em',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginTop: '-0.8em'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: '1',
                maxWidth: '100%',
                fontSize: '1.2em',
                marginTop: '0.5em'
              }}
            >
              <div
                style={{
                  color: newColor.jazzberryJam,
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0.5em',
                  lineHeight: 1,
                  width: '9.5em',
                  textAlign: 'left'
                }}
              >
                {`Dashboard Builder`}
              </div>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  margin: 'auto 0 auto 0',
                  height: '1em',
                  padding: '0 0 0.3em 0',
                  lineHeight: 1,
                  textAlign: 'left',
                  fontFamily: 'open sans'
                }}
              >
                {`>`}
              </div>
              <div
                id='outer-input-div'
                style={{
                  display: 'flex',
                  color: 'lightgray',
                  flex: 1,
                  textAlign: 'left',
                  width: 'fit-content',
                  maxWidth: 'calc(100% - 9em)',
                  paddingLeft: '0.5em',
                  margin: 0
                }}
              >
                <input
                  style={{
                    background: newColor.transparent,
                    outline: 'none',
                    boxShadow: 'none',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                    marginTop: '-0.1em',
                    width: valueWidth + 'ch',
                    minWidth: '8.7em',
                    lineHeight: 1,
                    fontSize: '0.9em',
                    marginRight: '0.5em',
                    borderRadius: '0.1em',
                    backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : 'white',
                    border: '1px solid ',
                    borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
                    color: theme.palette.text.primary,
                    padding: '0 1em 0 1em',
                  }}
                  type='text'
                  placeholder={'Enter Query Name here...'}
                  value={dashboardName}
                  onChange={(e) => {
                    setDashboardName(e.target.value);
                    setValueWidth(e.target.value?.length);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur()
                    }
                  }}
                />
              </div>
            </div>
            <div
              id='top-buttons'
              style={{
                display: 'flex',
                marginTop: '0.5em',
                flexWrap: 'nowrap'
              }}
            >
              <OpenDashboardMenu
                dashboards={dashboards}
                dashboardOnClickHandler={dashboardClickHandler}
              />
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.3em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: _.isEmpty(visuals) ? newColor.darkGunmetal : theme.palette.text.primary,
                  fontSize: '0.8em',
                  fontWeight: '600',
                  cursor: !_.isEmpty(visuals) ? 'pointer' : 'not-allowed',
                  marginLeft: '0.5em',
                  marginRight: '0.5em'
                }}
                onClick={() => {
                  if (_.isEmpty(visuals) || addingVisual) return
                  setAddingVisual(true)
                  let grid = gridRef.current;
                  if (_.isNil(grid)) {
                    toast.error('Could not find grid');
                    setAddingVisual(false)
                    return;
                  }
                  addRows()
                  setItems((prevState: any) => {
                    let highestId = prevState.length + 1;
                    prevState.forEach((item: any) => {
                      if (item.id >= highestId) {
                        highestId = Number(item.id) + Number(1);
                      }
                    })
                    let newNode = {
                      id: `${highestId}`,
                      h: 4,
                      w: 5,
                      options: undefined
                    }
                    return [
                      ...prevState,
                      newNode
                    ]
                  })
                  setAddingVisual(false)
                }
                }
              >
                <StyledSvg
                  sx={{
                    '& svg, & path': {
                      fill: _.isEmpty(dashboards) ? newColor.darkGunmetal : theme.palette.text.primary,
                      stroke: _.isEmpty(dashboards) ? newColor.darkGunmetal : theme.palette.text.primary,
                    },
                  }}
                >
                  <AddVisual />
                </StyledSvg>
                <div
                  style={{
                    margin: '0.1em 0 0 0.5em'
                  }}
                >
                  ADD VISUAL
                </div>
              </Button>
              <Button
                style={{
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: newColor.darkGunmetal,
                  borderRadius: '0.3em',
                  padding: '0.2em 0.5em 0.2em 0.5em',
                  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
                  color: theme.palette.text.primary,
                  fontSize: '0.8em',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setDontSaveDashboardModalBoolean(true)
                }}
              >
                <StyledSvg>
                  <NewDashboard />
                </StyledSvg>
                <div
                  style={{
                    margin: '0.1em 0 0 0.5em'
                  }}
                >
                  CREATE NEW DASHBOARD
                </div>
              </Button>
              <DashboardDesignerSaveMenu
                selectedDashboard={selectedDashboard}
                showSaveDashboardModal={setShowSaveDashboardModal}
                updateDashboardHandler={updateDashboardHandler}
                showDeleteDashboardModal={setShowDeleteDashboardModal}
                showPublishDashboardModal={setShowPublishDashboardModal}
                showUnpublishDashboardModal={setShowUnpublishDashboardModal}
              />
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '0.9em',
                margin: `0.5em 0 ${!_.isEmpty(lastSaved) ? '-0.5em' : '-1em'} 0`,
                color: 'gray',
                height: !_.isEmpty(lastSaved) ? 'fit-content' : '0em'
              }}
            >
              {selectedDashboard && lastSaved && `Last Saved: ${moment(lastSaved).format('YYYY-MM-DD hh:mm:ss A')} UTC`}
            </div>
          </div>
          <Box
            style={{
              padding: '1em',
              height: '100%',
              width: '100%',
              display: 'flex',
              overflowY: 'auto',
            }}
          >
            <DashboardGridstack
              dashboard_id={selectedDashboard}
              items={items}
              setItems={setItems}
              visuals={visuals}
              queries={[undefined].concat(userQueries).concat(orgQueries)?.filter((item: any) => !_.isNil(item))}
              gridRef={gridRef}
              isLoadingQueries={isLoadingQueries}
              inEditMode={true}
              maxRows={maxRows}
              setMaxRows={setMaxRows}
            />
          </Box>
        </div>
      </div>
    </div >
  );
};