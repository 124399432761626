//imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as ReactTooltip from 'react-tooltip';
import React, { useContext } from "react";
import { useTheme } from '@mui/material';
import { newColor } from 'src/consts/colors';
import { toast } from 'react-toastify';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { duplicateAction } from 'src/utils/api/queries';
import { ReactComponent as DuplicateEmailIcon } from 'src/assets/duplicate_email_icon.svg'

//contexts

//services

//components

//assets
//---------------------------------------------------------------------------------

function DuplicateActionButtonList({
    actions,
    setActions,
    setCurrentAction
}: {
    actions: any,
    setActions: any,
    setCurrentAction: any
}) {

    const theme: any = useTheme();
    const { postWithAccessToken } = useContext(Auth0Context)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="flex flex-col mx-auto"
            style={{
                margin: '0.5em auto 0.5em auto',
            }}
        >
            <ReactTooltip.Tooltip />
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    display: 'flex !important',
                    flexDirection: 'row !important',
                    width: '20em',
                    margin: '0.5em auto 0.5em auto',
                    justifyContent: 'start !important',
                    fontWeight: 900,
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    padding: '1em 2em 1em 2em',
                    textTransform: 'none',
                    fontSize: '1em',
                    outline: 'solid',
                    outlineColor: newColor.darkGunmetal
                }}
                className='templateEditorButtons'
            >
                <DuplicateEmailIcon style={{ marginRight: '0.5em' }} />
                Copy Existing Email
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock={true}
                sx={{
                    "& .MuiList-root":
                        { backgroundColor: theme.palette.background.default, padding: 0, width: '30em', maxHeight: '10em' },
                    "& .MuiMenu-paper":
                        { backgroundColor: theme.palette.background.default, border: 'solid', borderColor: newColor.dimGray, marginTop: '1em', width: '30em' },
                    "& .MuiButtonBase-root": {
                        backgroundColor: theme.palette.background.default,
                    },
                    "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                        borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#676767', width: '30em'
                    },
                    "& .MuiButtonBase-root:hover": {
                        background: newColor.dimGray
                    },
                    overflowY: 'auto',
                }
                }
            >
                {
                    actions?.map((actionItem: any) => {
                        return (
                            <MenuItem
                                key={actionItem?.id}
                                onClick={async () => {
                                    try {
                                        let newActionItem = Object.assign({}, actionItem)
                                        delete newActionItem.id
                                        delete newActionItem.audience_id
                                        newActionItem.name = newActionItem.name?.concat(` (COPY)`)
                                        setCurrentAction(newActionItem)
                                        handleClose()
                                    } catch (e: any) {
                                        toast.error(e?.message)
                                    }
                                }}
                            >
                                <div>
                                    {actionItem?.name}
                                </div>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </div >
    )
}

export default DuplicateActionButtonList