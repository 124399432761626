import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'
import { Auth0Context } from '../../contexts/Auth0Context'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { createConnection } from '../../utils/api/queries'
import { useSelector } from 'react-redux'
import connection_icon from '../../assets/connectionIcon.svg'
import { CONFIG_NAME_REGEX, USERNAME_REGEX } from '../../consts/regex'
import { isStringEmpty } from '../../utils/string'
import { newColor } from '../../consts/colors'
export interface SimpleDialogProps {
  open: boolean
  setOpen: any
}

export default function CreateConnectionModal(props: SimpleDialogProps) {
  const { open, setOpen } = props
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)
  const USER_DATA = useSelector((state: any) => state.user)
  const [createInProgress, setCreateInProgress] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [dialect, setDialect] = useState<string>('postgresql')
  const [host, setHost] = useState<string>('')
  const [port, setPort] = useState<number>(5432)
  const [dbName, setDbName] = useState<string>('')
  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const createConnectionQuery = async () => {
    try {
      setCreateInProgress(true)

      isStringEmpty(name, 'Name required')

      //check name
      if (!name.match(CONFIG_NAME_REGEX)) {
        throw new Error('Name must only contain letters, numbers, spaces, hyphens, and/or underscores');
      }

      isStringEmpty(dialect, 'Dialect required')
      isStringEmpty(host, 'Host required')
      isStringEmpty(dbName, 'DB Name required')
      isStringEmpty(user, 'User required')

      //checks username
      if (!user.trim().match(USERNAME_REGEX)) {
        throw new Error('User must only contain letters, numbers, and/or underscores');
      }

      isStringEmpty(password, 'Password required')

      let params = {
        org_id: USER_DATA.organization_id,
        user: user.trim(),
        pswd: password.trim(),
        host: host.trim(),
        port: port,
        dialect: dialect.trim(),
        name: name.trim(),
        description: description.trim(),
        db_name: dbName.trim(),
      }
      await createConnection(postWithAccessToken, params)
        .then((resp: any) => {
          if (!resp) {
            toast.error('Could not create connection')
            setCreateInProgress(false)
            return
          }
          toast.success('Connection created, page will refresh')
          setTimeout(() => {
            window.location.reload()
          }, 3000)
          return
        })
        .catch((error: any) => {
          toast.error('Could not create connection')
          setCreateInProgress(false)
        })
    } catch (e: any) {
      console.error(e);
      toast.error(e?.message)
      setCreateInProgress(false);
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          justifyContent: 'center',
          minWidth: '20em',
          margin: '1em 2em 0 2em',
        }}
      >
        <DialogTitle
          className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
            } centertext`}
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <img
            src={connection_icon}
            alt=""
            style={{ margin: 'auto 0.3em auto 0' }}
          />{' '}
          <div style={{ lineHeight: '80%', margin: '0.4em 0 0.3em 0' }}>
            CREATE CONNECTION
          </div>
        </DialogTitle>
      </Box>
      <Box
        display="flex"
        flexDirection={'column'}
        sx={{
          padding: '0 4em 2em 2em',
          width: '100%',
          justifyContent: 'center',
          minWidth: '60vw',
        }}
      >
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              NAME:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value)
              }}
              size={'small'}
              placeholder="e.g. Connection1"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DESCRIPTION:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass2'
                  : 'inputFieldClass2Light'
              }
              value={description}
              onChange={(e) => {
                setDescription(e.currentTarget.value)
              }}
              size={'small'}
              multiline
              rows={4}
              placeholder="Describe the new connection"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DIALECT:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <Select
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass3'
                  : 'inputFieldClass3Light'
              }
              labelId="search-filter"
              defaultValue={'prod'}
              //label="Search By"
              size="small"
              onChange={(event: SelectChangeEvent) => {
                setDialect(event.target.value as string)
              }}
              sx={{ height: '2em', width: '100%' }}
              value={dialect}
            >
              <MenuItem value={'postgresql'}>postgresql</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              HOST:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={host}
              onChange={(e) => {
                setHost(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              PORT:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              type="number"
              value={port}
              onChange={(e) => {
                setPort(Number(e.currentTarget.value))
              }}
              size={'small'}
              sx={{ width: '100%' }}
              placeholder="e.g. 8080"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              DB NAME:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={dbName}
              onChange={(e) => {
                setDbName(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              USER:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              id="tableUserName"
              value={user}
              onChange={(e) => {
                setUser(e.currentTarget.value)
              }}
              size={'small'}
              sx={{ width: '100%' }}
              placeholder="e.g. dbRootUsername"
            />
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          style={{ margin: '0.5em 0 0 0' }}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            xs={3}
            lg={2}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              className="minimizeFontOnSmallWindow"
              style={{ margin: 'auto 0 auto 0', height: 'fit-content' }}
            >
              PASSWORD:
            </div>
          </Grid>
          <Grid item xs={9} lg={10}>
            <TextField
              className={
                theme.palette.mode === 'dark'
                  ? 'inputFieldClass1'
                  : 'inputFieldClass1Light'
              }
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value)
              }}
              type="password"
              size={'small'}
              sx={{ width: '100%' }}
              placeholder="e.g. dbRootPassword"
            />
          </Grid>
        </Grid>
      </Box>
      {createInProgress ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={createInProgress}
            onClick={createConnectionQuery}
            className="helikaButtonClass"
          >
            Create
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
