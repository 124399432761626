//imports
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useContext, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, ListItemIcon, Divider } from '@mui/material'
import moment from 'moment'
import { newColor } from 'src/consts/colors'

//redux
import ThemedIcon from '../components/organisms/v2/themedIcon'
import { Auth0Context } from '../contexts/Auth0Context'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../state'
import { bindActionCreators } from 'redux'
import { DOMAIN_TYPES } from '../state/action-types'
import _ from 'lodash'
import {
  loadMoreApi,
  setAllNotificationsReadApi,
  setNotificationReadApi,
} from 'src/utils/api/queries'

//assets
import { ReactComponent as NotificationsIcon } from '../assets/notifications_page_icon.svg'
import { ReactComponent as GameManagementIcon } from '../assets/game_management_icon.svg'
import { ReactComponent as SupportIcon } from '../assets/notifications_support_icon.svg'
import { ReactComponent as AnalyticsIcon } from '../assets/notification_analytics_icon.svg'
import { ReactComponent as AllNotificationsReadIcon } from 'src/assets/icons/notifications_all_read.svg'

//components
const StyledButton: any = styled('button')(({ theme }) => ({
  borderWidth: 0,
  cursor: 'pointer',
}))

const StyledTitle: any = styled('h3')(({ theme }) => ({
  fontSize: '17px',
  textTransform: 'uppercase',
  color: newColor.jazzberryJam,
}))

const StyledText: any = styled('p')(({ theme }) => ({
  textTransform: 'uppercase',
  color: newColor.spanishGray,
  fontSize: '13.5px',
  lineHeight: 1.4,
}))

const StyledHeaderSpan: any = styled('span')(({ theme }) => ({
  flex: 1,
  whiteSpace: 'pre-wrap',
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: '13.5px',
  color: theme.palette.mode === 'dark' ? newColor.jazzberryJam : 'black',
  fontFamily: 'Paralucent Medium',
  fontWeight: 400,
}))

const StyledListItemText: any = styled('span')(({ theme }) => ({
  padding: '5px',
  color: theme.palette.mode === 'dark' ? newColor.white : 'black',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
  textTransform: 'uppercase',
}))

//---------------------------------------------------------------------------------

function NotificationsDropdown({
  options,
  children,
}: {
  options: any
  children: React.ReactNode
}) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { postWithAccessToken, getWithAccessToken } = useContext(Auth0Context)
  const USER_DATA = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const { setNotifications, setDomain } = bindActionCreators(
    actionCreators,
    dispatch,
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [loadPage, setLoadPage] = useState<number>(2)
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false)
  const pageSize = 10
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    let newOptions = options.slice(0, 10)
    setNotifications({
      type: 'SET_NOTIFICATIONS_DATA',
      payload: newOptions,
    })
    setLoadPage(2) //todo: cache at some point?
  }

  const setNotificationRead = async (id: number) => {
    await setNotificationReadApi(postWithAccessToken, id)
  }

  const setAllNotificationsRead = async () => {
    setNotifications({
      type: 'READ_ALL_NOTIFICATIONS',
    })
    await setAllNotificationsReadApi(postWithAccessToken)
  }

  useEffect(() => {
    let initialCanLoadMore =
      options && !_.isEmpty(options) && options.length % 2 === 0
    setCanLoadMore(initialCanLoadMore)
  }, [options])

  const loadMore = async () => {
    await loadMoreApi(
      getWithAccessToken,
      USER_DATA.id,
      pageSize,
      loadPage,
    ).then((res: any) => {
      setLoadPage(loadPage ? loadPage + 1 : 1)
      setCanLoadMore(res?.results && res?.results.length === pageSize)
      setNotifications({
        type: 'LOAD_MORE_USER_NOTIFICATIONS',
        payload: res?.results?.notifications,
      })
    })
  }

  const dropdownList = options?.map((option: any, index: number) => {
    let notifIcon = <NotificationsIcon width="15.6px" />
    if (option.type === 'game_management') {
      notifIcon = <GameManagementIcon width="15.6px" />
    } else if (option.type === 'support') {
      notifIcon = <SupportIcon />
    } else if (option.type === 'analytics') {
      notifIcon = <AnalyticsIcon />
    }
    return (
      <MenuItem
        id="menuItemDomainDropdown"
        key={option?.id + '_' + index}
        sx={{ paddingY: '0.5em' }}
        onClick={async () => {
          setNotificationRead(option.id)
          setNotifications({
            type: 'READ_NOTIFICATIONS',
            payload: option.id,
          })
          let reportData = option.data
          setDomain({
            type: 'SET_DOMAIN',
            payload: DOMAIN_TYPES.SUPPORT,
          })
          navigate(`/support/user_reports/reports/${reportData?.report_id}`, {
            state: { notification_read_report_id: reportData?.report_id },
          })
          setAnchorEl(null)
        }}
        disabled={option.status === 'read'}
      >
        <ListItemIcon style={{ alignSelf: 'center', justifyContent: 'center' }}>
          <ThemedIcon colorOnDark={newColor.white}>{notifIcon}</ThemedIcon>
        </ListItemIcon>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <StyledListItemText
            style={{ fontSize: '12px', fontFamily: 'Paralucent Bold' }}
          >
            {option.type}
          </StyledListItemText>
          <StyledListItemText
            style={{
              fontSize: '13px',
              fontFamily: 'Paralucent Medium',
              maxWidth: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {option.description}
          </StyledListItemText>
        </div>
        <StyledListItemText
          style={{
            fontSize: '11.5px',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignSelf: 'flex-start',
            alignItems: 'flex-end',
            fontFamily: 'Paralucent Medium',
          }}
        >
          {moment(option.created_at).fromNow()}
        </StyledListItemText>
      </MenuItem>
    )
  })

  return (
    <div style={{ display: 'flex', height: 'min-content' }}>
      <StyledButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e: any) => {
          e.stopPropagation()
          handleClick(e)
        }}
        sx={{
          marginTop: 'auto',
          bgcolor: 'transparent',
        }}
      >
        {children}
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onMouseLeave={() => handleClose()}
        disableScrollLock={true}
        onClick={(e) => {
          e.stopPropagation()
        }}
        sx={{
          '& .MuiList-root': {
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
            padding: '0',
          },
          '& .MuiMenu-paper': {
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
            marginTop: '1.4em',
            width: '19.2em',
            // marginLeft: '-10.8em',
          },
        }}
      >
        {dropdownList && dropdownList.length > 0 && (
          <div>
            <MenuItem>
              <div style={{ display: 'flex', flex: '1' }}>
                <StyledHeaderSpan
                  className={`notifications ${
                    theme.palette.mode === 'dark'
                      ? 'readAllHoverDark'
                      : 'readAllHover'
                  }`}
                  style={{
                    textAlign: 'right',
                  }}
                  onClick={setAllNotificationsRead}
                >
                  Mark All as Read
                </StyledHeaderSpan>
              </div>
            </MenuItem>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          </div>
        )}
        {dropdownList}
        {(!dropdownList || dropdownList.length === 0) && (
          <Box>
            <Box
              sx={{
                textAlign: 'center',
                padding: '1em',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                background:
                  theme.palette.mode === 'dark'
                    ? newColor.midnight
                    : newColor.cultured,
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <AllNotificationsReadIcon />
                <StyledTitle className="notifications">
                  You've read
                  <br />
                  everything!
                </StyledTitle>
              </Box>
              <StyledText>No new notifications</StyledText>
            </Box>
          </Box>
        )}
        {canLoadMore && (
          <div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <MenuItem
              id="menuItemDomainDropdown"
              onClick={loadMore}
              sx={{
                textAlign: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <StyledHeaderSpan
                className={`notifications ${
                  theme.palette.mode === 'dark'
                    ? 'readAllHoverDark'
                    : 'readAllHover'
                }`}
              >
                more...
              </StyledHeaderSpan>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  )
}

export default NotificationsDropdown
