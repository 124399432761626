import { ChangeEvent, useContext, useState } from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'

import { RS_CHAIN_SCHEMAS } from '../../consts/backend'
import { addCollectionAPI } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import TrackingIcon from '../../assets/Tracking_Icon.svg'
import TrackingIconDark from '../../assets/Tracking_Icon_dark.svg'
import loadingSVG from '../../assets/loading.svg'
import AddIcon from '@mui/icons-material/Add'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))
const DescirptionArea = muiStyled('div')(() => ({
  width: '277.663px',
  height: '133px',
  flexShrink: '0',
  borderRadius: '12px',
  background: `linear-gradient(246deg, ${newColor.cosmicCobalt} -10.05%, ${newColor.jazzberryJam} 101.22%)`,
  padding: '46px 8px 35px 25px',
  color: 'white',
  float: 'right',
  marginRight: '20px',
}))

const ResponsiveGrid = muiStyled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: '0 0 40px 30px',
  },
}))

export default function AddContract() {
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context)

  const [address, setAddress] = useState('')
  const [chain, setChain] = useState('')
  const [loading, setLoading] = useState(false)

  const onChangeAddress = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setAddress(e?.target.value)
    }
  }

  const onChangeChain = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setChain(e?.target.value)
    }
  }

  const addCollection = async () => {
    if (address && chain) {
      setLoading(true)
      await addCollectionAPI(patchWithAccessToken, address, chain)
      setLoading(false)
    } else {
      toast.warning(`Please enter collection address and choose chain`)
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? TrackingIcon : TrackingIconDark}
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;ADD SMART CONTRACT FOR TRACKING</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <InputGroup>
              <Typography>Enter contract address starting from 0x</Typography>
              <Input
                placeholder="contract address"
                value={address}
                type="text"
                required
                onChange={onChangeAddress}
              />
            </InputGroup>
            <InputGroup>
              <Typography>Choose chain</Typography>
              <SelectCategory required value={chain} onChange={onChangeChain}>
                <option disabled value="">
                  {' '}
                  -- select an option --{' '}
                </option>
                {Object.entries(RS_CHAIN_SCHEMAS).map(([chain, schema]) => (
                  <option key={schema} value={schema}>
                    {chain}
                  </option>
                ))}
              </SelectCategory>
            </InputGroup>
          </Grid>
          <ResponsiveGrid item md={4}>
            <DescirptionArea>
              Updates tracked_at to now() in CHAIN.nft_contracts
            </DescirptionArea>
          </ResponsiveGrid>
        </Grid>
        <Button
          onClick={addCollection}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading ? (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          ) : (
            <AddIcon
              style={
                theme.palette.mode === 'dark'
                  ? { color: 'white', width: '20px' }
                  : { color: 'white', width: '20px' }
              }
            />
          )}
          <StyledTxt>ADD COLLECTION</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
