import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
} from '@mui/material'
import { baseOptions } from 'src/utils/echarts';

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import ExploreIconHeader from '../../assets/explore_icon_wide.svg'
import { newColor } from 'src/consts/colors'
import VisualsSidebar from '../Explore/VisualsSidebar'
import LoadingComponent from '../LoadingComponent'
import _ from 'lodash'
import { visualTypes } from 'src/utils/string'
import { VisualDesignerEchart } from '../Explore/VisualDesignerEchart';
import { toast } from 'react-toastify';
import { runQuery } from 'src/utils/api/queries';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { createHeadersFromColumnList } from 'src/utils/agGrid';
import DontSaveVisualModal from './DontSaveVisualModal';

const SavedVisualsModal = (props: any) => {
  const { open, setOpen, visualClickHandler, visuals, queries } = props
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)
  const [interiorSelectedVisual, setInteriorSelectedVisual] = useState<any>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [newVisual, setNewVisual] = useState<any>();
  let [dataHeaders, setDataHeaders] = useState<any[]>([]);
  let [displayData, setDisplayData] = useState<any>([]);
  let [loading, setLoading] = useState<boolean>(false);
  const [dontSaveVisualModalBoolean, setDontSaveVisualModalBoolean] = useState<boolean>(false)

  const handleClose = () => {
    setOpen(false)
  }

  //run query
  const runHandler = useCallback(async (code: any) => {
    async function errorHandler(e: any) {
      if ('detail' in e) {
        toast.error('Error running query. See results for details.')
        setDisplayData([{ error: e.detail, helika_row_id: '1' }])
        setDataHeaders([
          {
            headerName: 'Error',
            field: 'error',
            wrapText: true,
            autoHeight: true,
            minWidth: 100,
            sortable: false,
            flex: 1,
            headerClassName: 'error-datagrid-header',
          }
        ])
      } else {
        toast.error(e)
      }
      setLoading(false)
    }
    try {
      setLoading(true)
      let resp: any = await runQuery(postWithAccessToken, { sql_query: code }, errorHandler)
      if (_.isEmpty(resp)) return
      let allData = [...resp.data];
      let columns = [...(Object.keys(allData[0]))]
      let headers = createHeadersFromColumnList(columns)
      let rowLabeledData = allData.map((row: any, index: number) => {
        let newProp: any = {};
        newProp[`helika_row_id`] = index;
        return Object.assign({}, row, newProp)
      })
      setDisplayData(rowLabeledData)
      setDataHeaders(headers)
      setLoading(false)

      // setSettings((prevState: any) => {
      //   return Object.assign({}, prevState, {
      //     xAxis: '',
      //   })
      // })
    } catch (e) {
      errorHandler(e);
    }
  }, [postWithAccessToken])

  useEffect(() => {
    if (!interiorSelectedVisual || !open) return
    let chosenVisual = _.find(visuals, (item: any) => item?.id === interiorSelectedVisual?.id)
    let chosenQuery = _.find(queries, (item: any) => item.id === chosenVisual?.query_id)
    if (!chosenQuery?.query) return;
    runHandler(chosenQuery?.query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interiorSelectedVisual, queries, open])

  useEffect(() => {
    let series: any = []
    let foundVisual = _.find(visuals, (item: any) => item?.id === interiorSelectedVisual?.id)

    if (!foundVisual) return;
    if (!_.isEmpty(dataHeaders) && !(dataHeaders.length === 1 && dataHeaders[0].field === 'error')) {
      series = foundVisual.series?.map((serie: any, index: number) => {
        let opts: any = {
          name: serie,
          type: foundVisual.type,
          stack: foundVisual.stacked ? 'Total' : undefined,
          data: foundVisual.type === visualTypes.pie
            ? displayData?.map((data: any) => {
              return {
                value: data[serie],
                name: _.isEmpty(foundVisual.x_axis) ? data['helika_row_id'] : data[foundVisual.x_axis]
              }
            })
            : displayData?.map((data: any) => data[serie])
        }
        if (foundVisual.type === visualTypes.funnel) {
          opts.width = '80%';
          opts.sort = 'descending';
          opts.label = {
            show: foundVisual.show_legend,
            position: 'inside'
          }
          opts.gap = 2;
          opts.data = displayData?.map((data: any) => {
            return {
              value: data[serie],
              name: (_.isEmpty(foundVisual.type) && _.isEmpty(data[foundVisual.type])) ? data['helika_row_id'] : data[foundVisual.type]
            }
          })
        }
        return opts;
      })
    }

    let options: any = {
      ...baseOptions,
      title: {
        ...baseOptions.title,
        text: foundVisual.name,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
      backgroundColor: theme.palette.background.default,
      grid: {
        ...baseOptions.grid,
        left: '3%',
        right: '3%',
        top: _.isEmpty(foundVisual?.series)
          ? 20
          : 100
        ,
      },
      xAxis:
        (_.isEmpty(series) || foundVisual.type === visualTypes.funnel)
          ? {}
          :
          {
            type: 'category',
            boundaryGap: true,
            data: _.isEmpty(foundVisual.x_axis)
              ? displayData['helika_row_id']
              : displayData.map((item: any) => item[foundVisual.x_axis])
          },
      yAxis: {
        type: 'value',
        alignTicks: true,
      },
      series: series
    }

    if (foundVisual.show_legend) {
      options.legend = {
        data: series?.map((serie: any) => serie.name),
        textStyle: {
          color: theme.palette.text.primary
        },
        top: 40,
        type: 'scroll',
      }
    } else {
      delete options.legend
    }

    if (![visualTypes.pie, visualTypes.funnel].includes(foundVisual.type)) {
      if (!_.isEmpty(foundVisual.x_axis)) {
        let newXAxis = displayData?.map((data: any) => data[foundVisual.x_axis]);
        options.xAxis.data = newXAxis
      }

      if (foundVisual.type === visualTypes.waterfall) {
        options.series = options.series?.slice(0, 2).map((serie: any) => {
          return Object.assign({}, serie, {
            type: visualTypes.bar,
            stack: 'Total'
          })
        })
        options.series[1] = Object.assign({}, options.series[1], {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          stack: 'Total'
        })
      }

      if (foundVisual.x_axis_ticks === "All") {
        options.xAxis.axisLabel = {
          interval: 0,
          rotate: 45
        }
      }
    } else {
      delete options.xAxis
      delete options.yAxis
      delete options.legend
    }

    //set echart
    setNewVisual(
      <VisualDesignerEchart
        loading={false}
        options={options}
      />
    )

  }, [dataHeaders, displayData, interiorSelectedVisual, theme.palette.background.default, theme.palette.text.primary, visuals])


  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          minWidth: 'min(80em,90vw)',
          minHeight: 'min(30em,75vh)'
        },
      }}
    >
      <DontSaveVisualModal
        open={dontSaveVisualModalBoolean}
        setOpen={setDontSaveVisualModalBoolean}
        openVisualHandler={() => {
          visualClickHandler(interiorSelectedVisual)
          handleClose()
          setDontSaveVisualModalBoolean(false)
        }}
        openItemName={'visual'}
        title="OPEN VISUAL"
      />
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            width: '100%',
            maxWidth: '25em',
            margin: '30px auto 0 auto',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={ExploreIconHeader}
              alt="saved_visuals"
              style={{ margin: '0 0.6em 0.2em 0', width: '1.2em' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              SAVED VISUALS
            </div>
          </DialogTitle>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: 'calc(100% - 15em)',
          margin: '0 1em 0 1em'
        }}
      >
        <VisualsSidebar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          visuals={visuals}
          selectedVisual={interiorSelectedVisual}
          setSelectedVisual={setInteriorSelectedVisual}
        />

        <div
          id='newVis'
          style={{
            margin: 'auto',
            height: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            width: '100%'
          }}
        >
          {
            loading
              ?
              <div
                style={{
                  scrollbarGutter: 'both-sides',
                  minHeight: '22em',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <LoadingComponent />
              </div>
              : newVisual
          }
        </div>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'end'}
        sx={{ padding: '0 2em 2em 2em' }}
      >
        <Button
          sx={{ width: '15em', marginLeft: '1em' }}
          onClick={handleClose}
          className="helikaButtonClass"
        >
          Cancel
        </Button>
        <Button
          sx={{ width: '15em', marginLeft: '1em' }}
          onClick={() => {
            setDontSaveVisualModalBoolean(true)
          }}
          className="helikaButtonClass"
        >
          SELECT VISUAL
        </Button>
      </Box>
    </Dialog>
  )
}

export default SavedVisualsModal
