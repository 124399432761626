//imports
import React, { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

//contexts
import { Auth0Context } from '../../contexts/Auth0Context'

//services
import { runQuery } from 'src/utils/api/queries'
import { createHeadersFromColumnList } from 'src/utils/agGrid'

//components
import DialogTitle from '@mui/material/DialogTitle'
import {
  Box,
  Button,
  useTheme,
  Grid,
  Dialog,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  SvgIcon,
} from '@mui/material'
import LoadingComponent from '../LoadingComponent'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import Analytics from '../../assets/analytics_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import { visualTypes } from 'src/utils/string'
import { baseOptions } from 'src/utils/echarts';
import { VisualDesignerEchart } from '../Explore/VisualDesignerEchart'
import { ReactComponent as Announcement_Type_Icon_Green } from '../../assets/Announcement_Type_Icon_Green.svg'
import { newColor } from 'src/consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any,
  setLastSaved: any,
  data: any,
  item: any,
  visuals: any[],
  queries: any[],
  dataLoading: any,
  setItems: any
}

const SelectVisualModal = (props: SimpleDialogProps) => {
  const { open, setOpen, data, item, setItems, visuals, queries, dataLoading } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedVisual, setSelectedVisual] = useState<any>(item.visual_id)
  let [dataHeaders, setDataHeaders] = useState<any[]>([]);
  let [displayData, setDisplayData] = useState<any>([]);


  const [newVisual, setNewVisual] = useState<any>();
  const { postWithAccessToken } = useContext(Auth0Context)

  const handleClose = () => {
    setOpen(false)
  }

  async function selectHandler() {
    setLoading(true)
    try {

      setItems((prevState: any) => {
        let updated = prevState?.map((newItem: any) => {
          if (newItem.id !== item.id) return newItem;
          return Object.assign({}, newItem, { visual_id: selectedVisual })
        })
        return updated
      })
      setLoading(false)
      handleClose()
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    let series: any = []
    let foundVisual = _.find(visuals, (item: any) => item?.id === selectedVisual)

    if (!foundVisual) return;
    if (!_.isEmpty(dataHeaders) && !(dataHeaders.length === 1 && dataHeaders[0].field === 'error')) {
      series = foundVisual.series?.map((serie: any, index: number) => {
        let opts: any = {
          name: serie,
          type: foundVisual.type,
          stack: foundVisual.stacked ? 'Total' : undefined,
          data: foundVisual.type === visualTypes.pie
            ? displayData?.map((data: any) => {
              return {
                value: data[serie],
                name: _.isEmpty(foundVisual.x_axis) ? data['helika_row_id'] : data[foundVisual.x_axis]
              }
            })
            : displayData?.map((data: any) => data[serie])
        }
        if (foundVisual.type === visualTypes.funnel) {
          opts.width = '80%';
          opts.sort = 'descending';
          opts.label = {
            show: foundVisual.show_legend,
            position: 'inside'
          }
          opts.gap = 2;
          opts.data = displayData?.map((data: any) => {
            return {
              value: data[serie],
              name: (_.isEmpty(foundVisual.type) && _.isEmpty(data[foundVisual.type])) ? data['helika_row_id'] : data[foundVisual.type]
            }
          })
        }
        return opts;
      })
    }

    let options: any = {
      ...baseOptions,
      title: {
        ...baseOptions.title,
        text: foundVisual.name,
        textStyle: {
          color: theme.palette.text.primary
        }
      },
      backgroundColor: theme.palette.background.default,
      grid: {
        ...baseOptions.grid,
        left: '3%',
        right: '3%',
        top: _.isEmpty(foundVisual?.series)
          ? 20
          : 100
        ,
      },
      xAxis:
        (_.isEmpty(series) || foundVisual.type === visualTypes.funnel)
          ? {}
          :
          {
            type: 'category',
            boundaryGap: true,
            data: _.isEmpty(foundVisual.x_axis)
              ? displayData['helika_row_id']
              : displayData.map((item: any) => item[foundVisual.x_axis])
          },
      yAxis: {
        type: 'value',
        alignTicks: true,
      },
      series: series
    }

    if (foundVisual.show_legend) {
      options.legend = {
        data: series?.map((serie: any) => serie.name),
        textStyle: {
          color: theme.palette.text.primary
        },
        top: 40,
        type: 'scroll',
      }
    } else {
      delete options.legend
    }

    if (![visualTypes.pie, visualTypes.funnel].includes(foundVisual.type)) {
      if (!_.isEmpty(foundVisual.x_axis)) {
        let newXAxis = displayData?.map((data: any) => data[foundVisual.x_axis]);
        options.xAxis.data = newXAxis
      }

      if (foundVisual.type === visualTypes.waterfall) {
        options.series = options.series?.slice(0, 2).map((serie: any) => {
          return Object.assign({}, serie, {
            type: visualTypes.bar,
            stack: 'Total'
          })
        })
        options.series[1] = Object.assign({}, options.series[1], {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          stack: 'Total'
        })
      }

      if (foundVisual.x_axis_ticks === "All") {
        options.xAxis.axisLabel = {
          interval: 0,
          rotate: 45
        }
      }
    } else {
      delete options.xAxis
      delete options.yAxis
      delete options.legend
    }

    //set echart
    setNewVisual(
      <VisualDesignerEchart
        loading={false}
        options={options}
      />
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataHeaders, displayData, selectedVisual, theme.palette.text.primary, visuals])

  //run query
  const runHandler = useCallback(async (code: any) => {
    async function errorHandler(e: any) {
      if ('detail' in e) {
        toast.error('Error running query. See results for details.')
        setDisplayData([{ error: e.detail, helika_row_id: '1' }])
        setDataHeaders([
          {
            headerName: 'Error',
            field: 'error',
            wrapText: true,
            autoHeight: true,
            minWidth: 100,
            sortable: false,
            flex: 1,
            headerClassName: 'error-datagrid-header',
          }
        ])
      } else {
        toast.error(e)
      }
      setLoading(false)
    }
    try {
      setLoading(true)
      let resp: any = await runQuery(postWithAccessToken, { sql_query: code }, errorHandler)
      if (_.isEmpty(resp)) return
      let allData = [...resp.data];
      let columns = [...(Object.keys(allData[0]))]
      let headers = createHeadersFromColumnList(columns)
      let rowLabeledData = allData.map((row: any, index: number) => {
        let newProp: any = {};
        newProp[`helika_row_id`] = index;
        return Object.assign({}, row, newProp)
      })
      setDisplayData(rowLabeledData)
      setDataHeaders(headers)
      setLoading(false)

      // setSettings((prevState: any) => {
      //   return Object.assign({}, prevState, {
      //     xAxis: '',
      //   })
      // })
    } catch (e) {
      errorHandler(e);
    }
  }, [postWithAccessToken])

  useEffect(() => {
    if (!selectedVisual || dataLoading || !open) return
    let chosenVisual = _.find(visuals, (item: any) => item?.id === selectedVisual)
    let chosenQuery = _.find(queries, (item: any) => item.id === chosenVisual?.query_id)
    if (!chosenQuery?.query) return;
    runHandler(chosenQuery?.query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVisual, dataLoading, open])

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          scrollbarGutter: 'both-sides',
        },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'32px'}
        style={{
          scrollbarGutter: 'both-sides'
        }}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
            scrollbarGutter: 'both-sides'
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              SELECT VISUAL FOR DASHBOARD
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
            scrollbarGutter: 'both-sides'
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;VISUAL INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{
              margin: '0.5em 0 0 0',
              scrollbarGutter: 'both-sides'
            }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                SELECT VISUAL:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setSelectedVisual(event.target.value)
                }}
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
                value={selectedVisual ? selectedVisual.toString() : ''}
              >
                {
                  data?.sort((a: any, b: any) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1,
                  )?.map((visual: any) => {
                    return <MenuItem
                      value={visual?.id}
                      key={`${item.id}_${visual.id}`}
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >
                      <SvgIcon component={Announcement_Type_Icon_Green} />
                      <span>{visual.name}</span> <span style={{ color: 'gray', paddingLeft: '1em' }}>({visual.updated_at})</span>
                    </MenuItem>
                  })
                }
              </Select>
            </Grid>
          </Grid>
        </Box>
        <div
          id='newVis'
          style={{
            margin: 'auto',
            height: '90%',
            maxWidth: '100%',
            overflow: 'auto',
            width: '100%'
          }}
        >
          {
            loading
              ?
              <div
                style={{
                  scrollbarGutter: 'both-sides',
                  minHeight: '22em',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <LoadingComponent />
              </div>
              : newVisual}
        </div>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '5em',
          width: '90%',
          margin: 'auto',
          scrollbarGutter: 'both-sides',
          overflow: 'auto'
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{
            padding: '0 2em 2em 2em',
            scrollbarGutter: 'both-sides'
          }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={selectHandler}
            className={loading ? "helikaButtonClassDisabled" : "helikaButtonClass"}
          >
            Select
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className={loading ? "helikaButtonClassDisabled" : "helikaButtonClass"}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SelectVisualModal
