import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { Dialog, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import InviteIcon from 'src/assets/Invite Email Icon.svg'
import OrganizationIcon from 'src/assets/Organization_Icon.svg'
import OrganizationLight from 'src/assets/Organization_Icon_dark.svg'

import { inviteUserApi } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { useSelector } from 'react-redux'
import { newColor } from '../../consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onClose: (value: string) => void
  onInviteSuccess?: (obj: any) => void
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

export default function InviteUsersModal(props: SimpleDialogProps) {
  const { open, setOpen } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [inviteUsersData, setInviteUsersData] = useState<string[]>([])
  const { postWithAccessToken } = useContext(Auth0Context)
  const [inputValue, setInputValue] = useState<string>('')
  const userData = useSelector((state: any) => state.user)

  const handleClose = () => {
    setOpen(false)
  }

  const handleBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const trimmedInputValue = inputValue.trim()
    if (trimmedInputValue && emailRegex.test(trimmedInputValue)) {
      setInviteUsersData([...inviteUsersData, trimmedInputValue])
      setInputValue('')
    } else if (trimmedInputValue) {
      toast.error('Invalid email entered.')
      setInputValue('')
    }
  }

  const resetFields = () => {
    setInviteUsersData([])
  }

  async function onHandleInviteUsers() {
    try {
      handleBlur()
      if (inviteUsersData.length === 0) {
        toast.error('No valid emails to invite.')
        return
      }

      setLoading(true)
      let params = {
        emails: inviteUsersData,
        organization_id: userData.organization_id,
      }
      await inviteUserApi(postWithAccessToken, params)
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success(`Successful invited!`)
            props.onInviteSuccess?.(params)
          } else {
            toast.error(`Failed: ${res.message}`)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
      resetFields()
      setLoading(false)
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle1"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          maxWidth: '80%',
        },
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'32px'}
        width={'100%'}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
            width: 'auto',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={InviteIcon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0', width: '24px' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              INVITE USERS
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: 'auto',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={
                theme.palette.mode === 'dark'
                  ? OrganizationIcon
                  : OrganizationLight
              }
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;ADD ORGANIZATION EMAIL
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Typography>
              Add organization emails to send invites. Press Enter key after
              each email.
            </Typography>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
            gap={'40px'}
            width={'80%'}
          >
            <div className="minimizeFontOnSmallWindow">
              <span
                style={{
                  fontSize: '14px',
                  width: 'max-content',
                  display: 'flex',
                  wordWrap: 'normal',
                }}
              >
                INVITE USERS:
              </span>
            </div>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'15px'}
              width={'100%'}
            >
              <Autocomplete
                multiple
                id="tags-filled"
                freeSolo
                options={[]}
                value={inviteUsersData}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                onChange={(event, newValue) => {
                  const validEmails = newValue.filter((email: string) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return emailRegex.test(email)
                  })
                  if (validEmails.length !== newValue.length) {
                    toast.error('Invalid email entered.')
                  }
                  setInviteUsersData(validEmails)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="E.g. user@helika.io"
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={onHandleInviteUsers}
            className="helikaButtonClass"
          >
            Invite
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
