import DialogTitle from '@mui/material/DialogTitle'
import React, { useState } from 'react'

import { Box, Button, useTheme } from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { Dialog, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import Remove_Icon from '../../assets/remove_button.svg'
import { newColor } from '../../consts/colors'

export interface SimpleDialogProps {
  warningText?: string
  open: boolean
  setOpen: any
  tabId: number | undefined
  onDeleteTab: any
}

export default function DeleteTabModal(props: SimpleDialogProps) {
  const { open, setOpen, tabId, onDeleteTab, warningText } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)

  const handleClose = () => {
    setOpen(false)
  }

  const deleteTabQuery = async () => {
    try {
      if (tabId === undefined) return
      setLoading(true)
      await onDeleteTab(tabId)
      setLoading(false)
      handleClose()
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="deleteModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 45px 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Remove_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              DELETE TAB
            </div>
          </DialogTitle>
        </Box>
        <Typography
          style={{
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '22.5px',
          }}
        >
          ARE YOU SURE YOU WANT TO DELETE THIS TAB?
        </Typography>
        <Typography
          style={{
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            color: newColor.jazzberryJam,
          }}
        >
          {warningText}
        </Typography>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={deleteTabQuery}
            className="helikaButtonClass"
          >
            Confirm
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}
