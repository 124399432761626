import { useContext } from 'react'
import useSWR from 'swr'

import { getModules } from 'src/utils/api/queries'
import { useAppSelector } from 'src/state'
import { Auth0Context } from 'src/contexts/Auth0Context'

export type Module = {
  name: string
  id: number
  module_access_level: string
}

export function useModules() {
  const userData = useAppSelector((state) => state.user)
  const { getTokenIfNecessary } = useContext(Auth0Context)

  const { data, error, isLoading } = useSWR(
    'get-modules',
    async () => await getModules(getTokenIfNecessary),
    {
      revalidateOnFocus: false,
    },
  )

  const mappedData = data?.map((item: any) => ({
    ...item,
    module_access_level: userData.module_access_level[item.id],
  }))

  return {
    modules: mappedData as Array<Module>,
    isLoading,
    isError: error,
  }
}
