import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useContext, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
import {
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { Box, Button, Dialog, Paper, Typography } from '@mui/material';
import uploadIcon from '../../assets/uploadIcon.svg'
import ExcelPopupForDownload from './ExcelPopupForUpload';
import { Auth0Context } from '../../contexts/Auth0Context';
import { postConfig } from '../../utils/api/queries';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { parseSheetMappingAliases } from '../../utils/excels';
import { newColor } from '../../consts/colors'

export interface SimpleDialogProps {
  open: boolean;
  setOpen: any,
  columnDefs: any[],
  rowData: any[] | undefined,
  onClose: (value: string) => void;
  showConfig: any,
  parent_id: number,
  parentConfig?: any
}

export default function UploadExcel(props: SimpleDialogProps) {

  const { open, setOpen, showConfig, parent_id, parentConfig } = props;
  const SHEET_MAPPINGS = useSelector((state: any) => state.gameMgmtData.SHEET_MAPPINGS);
  const theme = useTheme();

  const { getTokenIfNecessary } = useContext(Auth0Context);

  const [showExcel, setShowExcel] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const [tagList, setTagList] = useState<string[]>([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>();
  const [displayTitle, setDisplayTitle] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);


  async function confirmUpload(file: any, tags: string[]) {
    setUploadInProgress(true);
    postConfig(getTokenIfNecessary, {
      file: file,
      tags: tags,
      parent_id: parent_id
    }).then((wasPostedSuccessfully: any) => {
      toast.success('Config was uploaded successfully! Page will refresh.');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return;
    }).catch(e => {
      toast.error(`Config could not be uploaded successfully. ${e?.message}`)
    });
    setUploadInProgress(false);
  }

  const EXTENSIONS = ['xlsx', 'xls', 'csv'];

  const getExention = (file: any) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }
  const importExcel = (e: any, sheetToImport?: string) => {

    let file = e;
    if ('target' in e) {
      file = e.target.files[0];
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      //parse data

      const bstr = event?.target?.result;
      const workBook = XLSX.read(bstr, { type: "binary" })

      let workSheetName = workBook.SheetNames[0];
      if (sheetToImport) {
        workSheetName = sheetToImport;
      } else if (SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) {
        workSheetName = SHEET_MAPPINGS[0].sheet_name;
      }

      if (parentConfig && !_.isEmpty(parentConfig.sheet_mappings)) {
        //check if file has the sheets required in sheet mapping
        let hasSheets = parentConfig.sheet_mappings.map(((sheet_mapping: any) => {
          return sheet_mapping?.sheet_name in workBook.Sheets;
        }));
        if (!hasSheets.every((sheetHasColumn: boolean) => sheetHasColumn)) {
          toast.error('Uploaded file does not have the sheets required for the config');
          return;
        }

        //check if sheets have the columns they need
        let hasColumns = parentConfig.sheet_mappings.map(((sheet_mapping: any) => {

          //check if uploaded file has sheet
          if (!(sheet_mapping?.sheet_name in workBook.Sheets)) return false;

          const workSheet = workBook.Sheets[sheet_mapping?.sheet_name];
          const fileData: any[] = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
          const headers: any[] = fileData[0];
          let column_aliases = parseSheetMappingAliases(sheet_mapping.column_aliases);
          return column_aliases.sheet_columns.every((column: any) => headers.includes(column));
        }));
        if (!hasColumns.every((sheetHasColumn: boolean) => sheetHasColumn)) {
          toast.error('Uploaded file does not have the columns required for the config');
          return;
        }
      }

      setUploadedFile(file);

      //get first sheet
      if (!(workSheetName in workBook.Sheets)) {
        console.log('Sheet not in file');
        return;
      }
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData: any[] = XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: false })
      const headers: any[] = fileData[0];

      let arrayLength = Array.from(Array(headers.length).keys());

      let tempheaders = arrayLength.map((head: any, index: number) => headers[index]?.length > 0 ? headers[index] : `empty_${index}`).map((head, index) => {
        return {
          headerName: headers[index]?.length > 0 ? headers[index] : ``,
          field: head,
          filter: true,
          flex: 1
        }
      })
      setColumnDefs(tempheaders);

      //removing header
      fileData.splice(0, 1)

      let tempdata = fileData.map((row: any) => {
        let newRowData: any = {};
        row.forEach((cell: any, index: number) => {
          if (tempheaders[index]?.field) {
            newRowData[tempheaders[index]?.field.toString()] = cell;
          }
        })
        return newRowData;
      });
      setRowData(tempdata);

      //setShowExcel(true);
    }
    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      }
      else {
        alert("Invalid file input, Select Excel, CSV file")
      }
    }
  }

  const hiddenFileInputDisplay = useRef<HTMLInputElement>(null);
  const handleClickDisplay = () => {
    if (hiddenFileInputDisplay.current !== null) {
      hiddenFileInputDisplay.current.click();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function removeFromTagList(index: number) {
    setTagList(prevState => {
      if (!prevState) return prevState;
      let newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    })
  }

  let disabled = (uploadInProgress || _.isUndefined(uploadedFile));

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      sx={{ margin: 'auto' }} onClose={handleClose} open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px'
        }
      }}
    >
      <Box display='flex' flexDirection={'row'} className={theme.palette.mode === 'dark' ? 'circleBackground' : 'circleBackgroundLight'} sx={{ justifyContent: 'center' }}>
        <DialogTitle className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'} centertext`}><img src={uploadIcon} alt='' /> UPLOAD EXCEL/CSV</DialogTitle>
      </Box>
      <ExcelPopupForDownload
        open={showExcel}
        setOpen={setShowExcel}
        onClose={() => { setOpen(false) }}
        columnDefs={columnDefs}
        rowData={rowData}
        title={displayTitle}
        sheetChangeOverride={(sheetName: any) => {
          if (!uploadedFile) return;
          importExcel(uploadedFile, sheetName);
          setDisplayTitle((uploadedFile && uploadedFile.name) ? uploadedFile.name : 'Uploaded File')
        }}
        showConfig={showConfig}
      />
      <input ref={hiddenFileInputDisplay} className='hiddenInput' type="file" onChange={importExcel} />
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          width: '100%'
        }}
        id='fieldsetRounded'
      >
        <Box
          display='flex'
          flexDirection={'row'}
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          <Button className='helikaButtonClass' sx={{ width: 'auto', margin: 'auto', paddingLeft: 5, paddingRight: 5 }} variant='contained' size='small' onClick={handleClickDisplay}>
            Select File
          </Button>
        </Box>
        {
          uploadedFile &&
          <Paper
            sx={{ textAlign: 'center', textOverflow: "ellipsis", overflow: 'hidden', paddingRight: '1em', paddingLeft: '1em', marginTop: 1, padding: 1 }}
          >
            <Typography sx={{ height: 'fit-content' }} noWrap>
              Uploaded File: {uploadedFile?.name}
            </Typography>
            <Box
              display='flex'
              flexDirection={'row'}
              sx={{ marginTop: 1, width: '100%', justifyContent: 'center' }}
            >
              <Button className='helikaButtonClass' sx={{ margin: 'auto', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small' onClick={() => setShowExcel(true)}>
                Preview
              </Button>
              <Button className='helikaButtonClass' sx={{ margin: 'auto', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small' onClick={() => setUploadedFile(undefined)}>
                Remove
              </Button>
            </Box>
          </Paper>
        }
        <TextField
          id="standard-basic"
          value={newTag}
          onChange={(e) => {
            setNewTag(e.currentTarget.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && newTag.trim().length > 0) {
              setTagList(prevState => {
                if (!prevState) return [newTag?.trim()]
                return [...prevState].concat(newTag?.trim())
              });
              setNewTag('');
            }
          }}
          InputProps={{
            endAdornment:
              <Button
                sx={{ marginLeft: 2, whiteSpace: 'nowrap', borderRadius: '100vmax' }}
                variant='contained'
                disabled={newTag?.trim().length === 0}
                className='helikaButtonClass'
                onClick={() => {
                  if (newTag.trim().length > 0) {
                    setTagList(prevState => {
                      if (!prevState) return [newTag?.trim()]
                      return [...prevState].concat(newTag?.trim())
                    });
                    setNewTag('');
                  }
                }}
              >
                Add Tag
              </Button>
          }}
          placeholder='Tag'
          helperText={"Type in a new tag and press enter to add to the list".toUpperCase()}
          sx={{
            width: '100%',
            marginTop: 1,
            borderRadius: '3em'
          }}
        />
      </Box>
      <Box
        sx={{
          padding: 3,
          width: '100%'
        }}
      >
        TAGS:
        <Box
          display='flex'
          flexDirection={'row'}
          flexWrap={'wrap'}
        >
          {
            tagList.map((tag: string, index: number) => {
              return (
                <span
                  key={`${index}_${tag.substring(0, 10)}`}
                  className='gradientWrapper'
                >
                  <span
                    style={{ background: theme.palette.mode === 'dark' ? 'black' : 'white', borderRadius: '100vmax', textOverflow: "ellipsis", overflow: "hidden", maxWidth: '100%', display: 'flex', flexDirection: 'row', paddingTop: '0.3em', paddingBottom: 1, paddingLeft: '1em', paddingRight: 2, width: 'fit-content' }}
                  >
                    <Typography classes={{ noWrap: 'true' }} noWrap variant="body1" gutterBottom>
                      {tag}
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'} sx={{ paddingLeft: 1, paddingRight: '1em', height: 'fit-content' }}><CloseIcon onClick={() => { removeFromTagList(index) }
                    } sx={{ cursor: 'pointer', margin: 'auto', height: 'fit-content' }} /></Box>
                  </span>
                </span>
              );
            })
          }
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection={'row'}
        sx={{ marginBottom: 2, width: '100%', justifyContent: 'center' }}
      >
        <Button
          className={disabled ? 'helikaButtonClassDisabled' : 'helikaButtonClass'}
          sx={{ width: '15em', paddingLeft: 2, paddingRight: 2 }} variant='contained' size='small'
          onClick={() => confirmUpload(uploadedFile, tagList)}
          disabled={disabled}
        >
          Upload Config
        </Button>
      </Box>
    </Dialog>
  );
}
