import React from 'react'
import { Box } from '@mui/material'
import EditableCodeArea from './EditableCodeArea'
import { newColor } from 'src/consts/colors'

interface EditableAreasProps {
  triggerKey: string
  eventMap: string
  logic: string
  setTriggerKey: (newValue: string) => void
  setEventMap: (newValue: string) => void
  setLogic: (newValue: string) => void
}

const EditableAreas: React.FC<EditableAreasProps> = ({
  triggerKey,
  eventMap,
  logic,
  setTriggerKey,
  setEventMap,
  setLogic,
}) => {
  const isDataPresent = triggerKey || eventMap || logic

  return (
    <Box
      sx={{
        backgroundColor: newColor.charcoal,
        padding: '6px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        gap: isDataPresent ? '16px' : '8px', // Adjust gap when there's data
        height: '100%',
      }}
    >
      <EditableCodeArea
        label="Trigger Key"
        value={triggerKey}
        onChange={setTriggerKey}
      />
      <EditableCodeArea
        label="Event Map"
        value={eventMap}
        onChange={setEventMap}
      />
      <EditableCodeArea label="Logic" value={logic} onChange={setLogic} />
    </Box>
  )
}

export default EditableAreas
