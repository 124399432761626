const clear_keys = [
  'APPLY_CONFIG_ERROR',
  'hidden_announcements',
  'CONFIG_LIST_ENVIRONMENT_FILTER',
]

export function clearStorage() {
  clear_keys.forEach(item => {
    localStorage.removeItem(item)
  })
}