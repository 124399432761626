import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Divider,
  Avatar,
  keyframes,
} from '@mui/material'
import { newColor } from 'src/consts/colors'
import HelikaIcon from 'src/assets/Helika_Icon.svg' // Import the HelikaIcon

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`

const ChatBox: React.FC<{
  onUserInput: (
    message: string,
    addBotResponse: (response: string) => void,
  ) => void
}> = ({ onUserInput }) => {
  const [message, setMessage] = useState('')
  const [chatHistory, setChatHistory] = useState<
    { type: 'user' | 'bot' | 'typing'; text: string }[]
  >([])
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [loadingDots, setLoadingDots] = useState<string>('.')
  const messagesEndRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatHistory])

  // Function to handle the animated dots while waiting for the response
  useEffect(() => {
    if (isTyping) {
      const dotsInterval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 3 ? prev + '.' : '.'))
      }, 500)

      return () => clearInterval(dotsInterval)
    }
  }, [isTyping])

  const handleSend = () => {
    if (message.trim()) {
      setChatHistory((prev) => [
        ...prev,
        { type: 'user', text: message },
        { type: 'typing', text: '' },
      ])
      setMessage('')
      setIsTyping(true)

      onUserInput(message, (botResponse) => {
        setIsTyping(false)
        setChatHistory((prev) => [
          ...prev.slice(0, -1),
          { type: 'bot', text: botResponse },
        ])
      })
    }
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: newColor.midnight,
        borderRadius: '8px',
        padding: '16px',
        border: `1px solid ${newColor.darkGunmetal}`,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          backgroundColor: newColor.graphite,
          padding: '16px',
          borderRadius: '8px',
          border: `1px solid ${newColor.darkGunmetal}`,
        }}
      >
        {chatHistory.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: msg.type === 'user' ? 'flex-end' : 'flex-start',
              alignItems: 'flex-start', // Ensure the icon and text are aligned at the top
              mb: 1,
            }}
          >
            {msg.type === 'bot' && (
              <Avatar
                src={HelikaIcon}
                alt="Helika Bot"
                sx={{
                  width: 32,
                  height: 32,
                  mr: 1, // Margin to the right to separate the icon from the bubble
                  alignSelf: 'flex-start', // Ensure the icon stays at the top
                }}
              />
            )}

            <Box
              sx={{
                backgroundColor: newColor.tertiary,
                color: newColor.white,
                borderRadius: '20px',
                padding: '10px 15px',
                maxWidth: '70%',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'pre-wrap', // Ensure long text wraps into new lines
                wordBreak: 'break-word',
              }}
            >
              <Typography sx={{ color: newColor.white }}>
                {msg.type === 'typing' ? (
                  <>
                    <span style={{ animation: `${bounce} 1s infinite` }}>
                      {loadingDots}
                    </span>
                  </>
                ) : (
                  msg.text
                )}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      <Divider sx={{ backgroundColor: newColor.darkGunmetal, my: 2 }} />

      <Stack direction="column" spacing={1} alignItems="center">
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: '20px',
            backgroundColor: newColor.charcoal,
            padding: '0 10px',
            border: `1px solid ${newColor.darkGunmetal}`,
            width: '100%',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Describe your hook."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isTyping) {
                handleSend()
              }
            }}
            sx={{
              backgroundColor: newColor.charcoal,
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: newColor.dimGray,
                  borderRadius: '8px',
                },
                '&:hover fieldset': {
                  borderColor: newColor.darkSlate,
                },
                '&.Mui-focused fieldset': {
                  borderColor: newColor.darkSlate,
                },
                '& input': {
                  padding: '10px 12px',
                  border: 'none',
                  backgroundColor: 'transparent',
                },
              },
              '& .MuiInputBase-root': {
                '&::before, &::after': {
                  borderBottom: 'none',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },
            }}
            disabled={isTyping}
          />
        </Box>
        <Button
          onClick={handleSend}
          sx={{
            backgroundColor: newColor.jazzberryJam,
            color: newColor.white,
            padding: '12px 24px',
            fontSize: '1rem',
            borderRadius: '8px',
            border: `1px solid ${newColor.darkGunmetal}`,
            '&:hover': {
              backgroundColor: newColor.byzantium,
            },
            alignSelf: 'flex-end',
          }}
          disabled={isTyping}
        >
          SEND
        </Button>
      </Stack>
    </Stack>
  )
}

export default ChatBox
