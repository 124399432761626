import React, { useRef } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { getIcon } from '../../utils/string'
import { StyledSvg } from '../organisms/v2/tabs/styled'

export interface ICustomHeaderParams extends IHeaderParams {
  menuIcon: string
  theme: any
}

export const SupportHeaderComponent = (props: ICustomHeaderParams) => {
  const refButton = useRef(null)

  let readProps: any = Object.assign({}, props)

  function onMenuClicked() {
    if (!refButton?.current) return
    try {
      props?.showColumnMenu(refButton?.current!)
    } catch (e) {
      console.log('Error: Could not fin Context Menu', e)
    }
  }

  let menu = null
  if (readProps.enableMenu) {
    menu = (
      <div
        className="customHeaderMenuButton hidden-child"
        onClick={(e) => {
          e.stopPropagation()
          onMenuClicked()
        }}
        style={{ padding: '0.3em 0 0 0.5em', margin: 'auto' }}
      >
        <span>
          <span
            style={{ height: '0.5em', margin: '0' }}
            className="googeIconsMenuSized material-symbols-outlined"
            onClick={() => onMenuClicked()}
          >
            menu
          </span>
        </span>
      </div>
    )
  }

  let sort = null
  switch (readProps.column.sort) {
    case 'asc':
      sort = (
        <div className="customHeaderMenuButton datagrid-header-padding">
          <span>
            <span
              style={{ height: '0.5em', margin: '0' }}
              className="googeIconsMenuSized material-symbols-outlined"
            >
              arrow_upward
            </span>
          </span>
        </div>
      )
      break
    case 'desc':
      sort = (
        <div className="customHeaderMenuButton datagrid-header-padding">
          <span>
            <span
              style={{ height: '0.5em', margin: '0' }}
              className="googeIconsMenuSized material-symbols-outlined"
            >
              arrow_downward
            </span>
          </span>
        </div>
      )
      break
    default:
      sort = null
  }

  let icon = getIcon(
    props.displayName?.toLowerCase(),
    props.theme?.palette.mode,
  )

  return (
    <div
      style={{ margin: 'auto', width: 'fit-content' }}
      onClick={() => {
        switch (readProps.column?.sort) {
          case 'asc': {
            props.setSort('desc')
            break
          }
          case 'desc': {
            props.setSort(null)
            break
          }
          default: {
            props.setSort('asc')
          }
        }
      }}
    >
      <div
        ref={refButton}
        className="supportHeaderParent"
        style={{
          color: props.theme?.palette.mode === 'dark' ? 'white' : 'black',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          margin: 'auto',
          width: 'fit-content',
        }}
      >
        {sort}
        <StyledSvg sx={{ marginRight: '1em' }}>{icon}</StyledSvg>
        <div className="customHeaderLabel" style={{ margin: 'auto' }}>
          {props.displayName}
        </div>
        {menu}
      </div>
    </div>
  )
}
