const USER_METADATA_NAME = 'https://www.helika.io/user_metadata';
const APP_METADATA_NAME = 'https://www.helika.io/app_metadata';
const EMAIL_NAME = 'https://www.helika.io/email';
const ADD_COLLECTION_STATUSES = {
  NO_SUCH_COLLECTION: 'Collection not found',
  COLLECTION_ALREADY_ADDED: 'Collection already added',
  COLLECTION_ADDED: 'Collection Added',
};

const LINK_COLLECTION_STATUSES = {
  NO_SUCH_COLLECTION: 'Collection not found',
  NOT_TRACKED_COLLECTION: 'Collection not tracked',
  COLLECTION_LINKED: 'Collection linked',
  COLLECTION_ALREADY_LINKED: 'Collection already linked',
};

const LINK_TOKEN_STATUSES = {
  NO_SUCH_ORGANIZATION: 'Organization not found',
  TOKEN_LINKED: 'Token linked',
  TOKEN_ALREADY_LINKED: 'Token is already linked',
};

const CREATE_API_KEY_STATUSES = {
  INVALID_ORGANIZATION: 'Invalid organization',
  MAX_API_KEY_LIMIT_REACHED_FOR_ORG: 'Max Api Key limit reached for organization',
};

const SUPPORT_TICKET_STATUSES = {
  'new': 'New',
  'assigned': 'Assigned',
  'completed': 'Completed',
  'no action': 'No Action',
  'open': 'Open',
  'blocked': 'Blocked'
};

const SUPPORT_TICKET_STATUSE_COLORS = {
  'new': '#F8D247',
  'assigned': '#9654F0',
  'completed': '#58C1A4',
  'no action': '#888888',
  'open': '#9654F0',
  'blocked': '#EA335F'
}

const SUPPORT_PRIORITY = {
  urgent: "Urgent",
  high: "High",
  medium: "Medium",
  low: "Low",
};

const SUPPORT_PRIORITY_COLORS = {
  high: "#F8D247",
  medium: "#DC4CC1",
  low: "#58C1A4",
  urgent: "#EA335F",
};

const SUPPORT_TYPE = {
  bug: "Bug",
  report: "Report",
  feature: "Feature",
  other: "Other"
};

const PRODUCT_DESCRIPTION = {
  ACQUIRE: 'Acquire users with deep funnel attribution',
  GAME_MANAGEMENT: 'In-game events, sales and configuration',
  SUPPORT: 'Collect and react to user feedback',
  ANALYTICS: 'Monitor your game with data insights',
  OPTIMIZE: 'Let AI optimize your economy on the fly',
  ACCOUNT_MANAGEMENT: 'Manage your organization, users and rights',
  HELIKA_AI: 'Optimize your game on the fly with the power of AI',
  GAME_DEMO: 'Review the Demo Video'
}

const LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES = {
  SOCIAL_CONNECTION_IS_LINKED_TO_ORG: 'Social connection is linked to organization',
  SOCIAL_CONNECTION_IS_ALREADY_LINKED_TO_ORG:
    'Social connection is already linked to this organization in socials.organization_access',
};

const LINK_USER_STATUSES = {
  WRONG_ROLE_PROVIDED: 'Incorrect user role provided',
  NO_SUCH_USER: 'User is not found in public.users',
  USER_ASSOCIATED_TO_ORG: 'Success. User is associated to the organization',
};

const ADD_USER_STATUSES = {
  USER_EXISTS: 'User already exists in public.users',
  USER_CREATED_NO_ORG: 'User added to public.users with no organization identified',
  USER_CREATED_WITH_ORG: (orgName) => `User added to public.users with link to ${orgName} organization`,
};

const USER_ROLES = {
  viewer: 'viewer',
  admin: 'admin',
};

const RS_CHAIN_SCHEMAS = {
  ethereum: 'ethereum',
  polygon: 'polygon',
  nova: 'arbitrum_nova',
  arbitrum_one: 'arbitrum_one',
  base_main_net: 'base_main_net',
  avalanche: 'avalanche',
  bsc: 'bsc',
};

const RDS_CHAIN_SCHEMAS = {
  ethereum: 'public',
  polygon: 'polygon',
  nova: 'arbitrum_nova',
  arbitrum_one: 'arbitrum_one',
  base_main_net: 'base_main_net',
  avalanche: 'avalanche',
  bsc: 'bsc',
};

module.exports = {
  USER_METADATA_NAME,
  APP_METADATA_NAME,
  EMAIL_NAME,
  ADD_COLLECTION_STATUSES,
  LINK_COLLECTION_STATUSES,
  LINK_USER_STATUSES,
  ADD_USER_STATUSES,
  USER_ROLES,
  RS_CHAIN_SCHEMAS,
  RDS_CHAIN_SCHEMAS,
  LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES,
  SUPPORT_TICKET_STATUSES,
  SUPPORT_TICKET_STATUSE_COLORS,
  CREATE_API_KEY_STATUSES,
  SUPPORT_PRIORITY,
  SUPPORT_PRIORITY_COLORS,
  SUPPORT_TYPE,
  PRODUCT_DESCRIPTION,
  LINK_TOKEN_STATUSES
};
