import loadingSVG from 'src/assets/loading.svg'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, type DropResult } from '@hello-pangea/dnd'
import { produce } from 'immer'
import { Box } from '@mui/material'

import {
  ChildTab,
  DroppableTypeChild,
  DroppableTypeParent,
  ParentTab,
  TabData,
  TabLevel,
} from '../organisms/v2/tabs/type'
import ModuleTab from '../organisms/v2/tabs/ModuleTab'
import { useActionSave } from '../organisms/v2/tabs/ActionContext'
import EditTabModal from '../../components/popups/EditTabModal'
import DeleteTabModal from '../../components/popups/DeleteTabModal'
import OrganizationTabModal from '../../components/popups/OrganizationTabModal'
import { newColor } from '../../consts/colors'

const ActionButton: any = styled.button`
  width: 97px;
  height: 26px;
  background: linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%);
  border-radius: 17px;
  border: none;
  color: ${newColor.white};
  text-transform: uppercase;
  margin-right: 14px;
  opacity: ${(props: any) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
    background: linear-gradient(272deg, ${newColor.jazzberryJam} 1.91%, ${newColor.indigo} 98.09%);
  }
`

export default function TabManagement({
  tabsData,
  onSave,
  allowReorder,
  enableAddParentTab,
  enableAddTab,
  enableEditTab,
  enableDeleteTab,
  onDeleteTab,
  onUpdated,
  enableEditTabDefaultParams,
  orgs,
  parentTabs,
  selectedOrg
}: {
  tabsData: TabData
  onSave: any
  onUpdated?: () => void
  allowReorder?: boolean
  enableAddParentTab?: boolean
  enableAddTab?: boolean
  enableEditTab?: boolean
  enableDeleteTab?: boolean
  onDeleteTab?: any
  enableDeleteParentTab?: boolean
  enableEditTabDefaultParams?: boolean
  orgs?: any[]
  parentTabs?: any[]
  selectedOrg?: any
}) {
  // modals
  const [showDeleteTabModal, setShowDeleteTabModal] = useState<boolean>(false)
  const [showEditTabModal, setShowEditTabModal] = useState<boolean>(false)
  const [showEditParamsModal, setShowEditParamsModal] = useState<boolean>(false)
  const [selectedTabId, setSelectedTabId] = useState<number | undefined>(
    undefined,
  )
  const [selectedEditParamsTabId, setSelectedEditParamsTabId] = useState<
    number | undefined
  >(undefined)
  const [selectedDeleteTabId, setSelectedDeleteTabId] = useState<
    number | undefined
  >(undefined)

  const [tabData, setTabOrder] = useState<TabData>(tabsData)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { isLoading } = useActionSave()

  useEffect(() => {
    setTabOrder(tabsData)
  }, [tabsData])

  const handleReorder = (results: DropResult) => {
    if (allowReorder) setIsEdit(true)
    const { index: prioritySrc } = results.source
    const { droppableId: droppableIdDes, index: priorityDes } =
      results.destination || {}

    if (
      droppableIdDes === undefined ||
      prioritySrc === undefined ||
      priorityDes === prioritySrc
    ) {
      return
    }

    const droppableType = results.type as
      | DroppableTypeParent
      | DroppableTypeChild
    const [targetTabLevel, moduleTabId, parentTabId] = droppableType.split('-')

    const produceNewTabData = produce((draft: TabData) => {
      const newTabOrder = draft

      if (targetTabLevel === TabLevel.Parent) {
        const tabList = newTabOrder[moduleTabId]

        const sortedTabs = Object.values(tabList).sort(
          (a, b) => a.priority - b.priority,
        )

        const reOrderedTabs = reorder(
          sortedTabs,
          results.source.index,
          results.destination?.index!,
        )

        for (const index in reOrderedTabs) {
          const tabId = reOrderedTabs[index].id
          tabList[tabId].priority = Number(index) + 1
        }
      } else if (targetTabLevel === TabLevel.Child) {
        const childTabs = newTabOrder[moduleTabId][parentTabId].children
        const sortedTabs = Object.values(childTabs).sort(
          (a, b) => a.priority - b.priority,
        )

        const reOrderedTabs = reorder(
          sortedTabs,
          results.source.index,
          results.destination?.index!,
        )
        for (const index in reOrderedTabs) {
          const tabId = reOrderedTabs[index].id
          const tab = childTabs.filter((tab) => tab.id === tabId)[0]
          tab.priority = Number(index) + 1
        }
      }

      draft = newTabOrder
    })

    setTabOrder(produceNewTabData(tabData))
  }

  const submitNewTabOrder = async () => {
    await onSave({ tabs: tabData })
    setIsEdit(false)
  }

  const cancelChanges = () => {
    setTabOrder(tabsData)
    setIsEdit(false)
  }

  return (
    <div
      style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}
    >
      <DeleteTabModal
        warningText="Clicking confirm will delete the tab permanently"
        tabId={selectedDeleteTabId}
        onDeleteTab={onDeleteTab}
        open={showDeleteTabModal}
        setOpen={setShowDeleteTabModal}
      />
      <EditTabModal
        orgs={orgs}
        parentTabs={parentTabs}
        tabId={selectedTabId}
        onUpdated={onUpdated}
        open={showEditTabModal}
        setOpen={setShowEditTabModal}
        selectedOrg={selectedOrg}
      />
      <OrganizationTabModal
        open={showEditParamsModal}
        setOpen={setShowEditParamsModal}
        tabId={selectedEditParamsTabId}
        selectedOrg={selectedOrg}
      />
      <DragDropContext onDragEnd={handleReorder}>
        <ModuleTab
          tabData={tabData}
          enableAddParentTab={enableAddParentTab}
          enableAddTab={enableAddTab}
          enableEditTab={enableEditTab}
          enableDeleteTab={enableDeleteTab}
          onDeleteTab={(id: number) => {
            setSelectedDeleteTabId(id)
            setShowDeleteTabModal(true)
          }}
          onEditTab={(id: number) => {
            setSelectedTabId(id)
            setShowEditTabModal(true)
          }}
          onEditParams={(id: number) => {
            setSelectedEditParamsTabId(id)
            setShowEditParamsModal(true)
          }}
          enableEditTabDefaultParams={enableEditTabDefaultParams}
        />
      </DragDropContext>
      {isEdit && (
        <Box
          sx={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ActionButton disabled={isLoading} onClick={submitNewTabOrder}>
            {isLoading && (
              <img
                width="12px"
                className="animate-spin"
                src={loadingSVG}
                alt=""
              />
            )}{' '}
            Save
          </ActionButton>
          <ActionButton disabled={isLoading} onClick={cancelChanges}>
            Cancel
          </ActionButton>
        </Box>
      )}
    </div>
  )
}

function reorder(
  list: Array<ParentTab[string] | ChildTab>,
  startIndex: number,
  endIndex: number,
) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
