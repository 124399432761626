//imports

//contexts

//services

//components

//assets
import { useTheme } from "@mui/material";
import { StyledSvg } from "../organisms/v2/tabs/styled";
import { ReactComponent as ExpandIcon } from 'src/assets/drop_expand.svg'
import { ReactComponent as ExpandLessIcon } from 'src/assets/drop_less_expand.svg'
import { ReactComponent as RunIcon } from 'src/assets/run_icon.svg'
import SearchBar from "../SearchBar";
import _ from "lodash";
import { newColor } from "src/consts/colors";
import { useState } from "react";
import { sortArrayBy } from "src/utils/string";
//---------------------------------------------------------------------------------

export default function QuerySidebar({
  searchTerm,
  setSearchTerm,
  userQueries,
  orgQueries,
  selectedQuery,
  setSelectedQuery
}: {
  searchTerm: any,
  setSearchTerm: any,
  userQueries: any,
  orgQueries: any,
  selectedQuery: any,
  setSelectedQuery: any
}) {

  const theme: any = useTheme()

  const [userQueriesShow, setUserQueriesShow] = useState<boolean>(true);
  const [orgQueriesShow, setOrgQueriesShow] = useState<boolean>(true);

  async function queryClickHandler(query: any) {
    setSelectedQuery(query)
  }

  function populateQueryList(queryList: any) {
    if (_.isEmpty(queryList) || _.isNull(queryList)) return null
    return (
      sortArrayBy(queryList?.filter((query: any) => query?.name?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())), 'name')?.map((query: any) => {
        return (
          <div
            key={query?.id}
            onClick={() => queryClickHandler(query)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '0.5em',
              width: 'fit-content',
              maxWidth: '100%',
              paddingLeft: '1em',
              cursor: 'pointer',
              color: query.id === selectedQuery?.id ? 'linear-gradient(90deg, #9654F0 60%, #8BE1C9 100%)' : theme.palette.text.primary
            }}
            className={`${theme.palette.mode === 'dark' ? 'gradientTextHover' : ''} ${query.id === selectedQuery?.id ? 'gradientText2' : ''}`}
          >
            <StyledSvg
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <RunIcon
                style={{ margin: 'auto' }}
              />
            </StyledSvg>
            <div
              style={{ marginLeft: '0.6em', textAlign: 'left' }}
              className='truncate-ellipsis'
            >
              {query?.name}
            </div>
          </div>
        );
      })
    )
  }

  let canExpandIcon =
    <div style={{}}>
      <StyledSvg
        sx={{
          '& svg, & path': {
            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
          },
        }}
      >
        <ExpandIcon />
      </StyledSvg>
    </div>

  let cantExpandIcon =
    <div style={{}}>
      <StyledSvg
        sx={{
          '& svg, & path': {
            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
          },
        }}
      >
        <ExpandLessIcon />
      </StyledSvg>
    </div>

  return (
    <div
      id='exploreSidebar'
      style={{
        minHeight: '100%',
        height: '100%',
        width: '15em',
        overflow: 'auto',
        marginRight: '1em',
        borderRadius: '1em',
      }}
    >
      <div
        style={{
          height: '100%',
          flex: 1,
          borderRadius: '1em',
        }}
      >
        <div
          style={{
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'white',
            borderRadius: '1em',
            border: 'solid',
            borderColor: newColor.darkGunmetal,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            scrollbarGutter: 'stable both-sides',
            width: '15em',
          }}
        >
          <div
            style={{
              padding: '0.5em 0.5em 0 0.5em'
            }}
          >
            <SearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder='SEARCH'
              onEnter={(value: any) => { setSearchTerm(value) }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              borderRadius: '1em',
            }}
          >
            <div
              style={{
                maxHeight: 'calc(100%)',
                padding: '1em',
                overflowX: 'clip',
                overflowY: 'auto',
                height: '100%',
                scrollbarGutter: 'stable both-sides'
              }}
            >
              {  /* user queries */}
              <div
                style={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 900
                }}
                className='hoverHighlight'
                onClick={() => setUserQueriesShow((prevState: boolean) => !prevState)}
              >
                {
                  userQueriesShow
                    ? cantExpandIcon
                    : canExpandIcon
                }
                User Queries
              </div>
              <div className={`explorer-list-box ${userQueriesShow ? '' : 'closed'}`}>
                {
                  populateQueryList(userQueries)
                }
              </div>
              {  /* org queries */}
              <div
                style={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 900
                }}
                onClick={() => setOrgQueriesShow((prevState: boolean) => !prevState)}
                className='hoverHighlight'
              >
                {
                  orgQueriesShow
                    ? cantExpandIcon
                    : canExpandIcon
                }
                Org Queries
              </div>
              <div className={`explorer-list-box ${orgQueriesShow ? '' : 'closed'}`}>
                {
                  populateQueryList(orgQueries)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}