//module imports
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import _ from 'lodash'

//state
import { bindActionCreators } from 'redux'
import { actionCreators } from 'src/state'

//services
import { lowerCaseAndReplaceSpacesAndDashes } from 'src/utils/string'
import { DOMAIN_TYPES } from 'src/state/action-types'
import { useGetDrawer } from 'src/utils/drawer'

//style
import { useTheme } from '@mui/material'
import List from '@mui/material/List'
import { newColor } from 'src/consts/colors'

//constant
import { HEADER } from 'src/consts/sizes'

//components
import { DrawerList } from 'src/components/DrawerList'
import { ScrollBlock } from 'src/components/StyledSimpleComponents'

export const SubTabsScrollbox = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading } = useAuth0()
  const theme = useTheme()
  const USER_DATA = useSelector((state: any) => state.user)
  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)
  const isDrawerOpen = useGetDrawer()
  const dispatch = useDispatch()
  const {
    setAnalyticsState,
    setGameManagementState,
    setSupportState,
    setAccountManagementState,
  } = bindActionCreators(actionCreators, dispatch)

  let tabData: any = []
  let initialState: any = []
  let domain_tabs_place: any = undefined
  let setDataFunc: any = undefined

  //set domain values
  switch (DOMAIN) {
    case DOMAIN_TYPES.ANALYTICS:
      domain_tabs_place = 1
      setDataFunc = setAnalyticsState
      break
    case DOMAIN_TYPES.GAME_MANAGEMENT:
      domain_tabs_place = 2
      setDataFunc = setGameManagementState
      break
    case DOMAIN_TYPES.SUPPORT:
      domain_tabs_place = 3
      setDataFunc = setSupportState
      break
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
      domain_tabs_place = 4
      setDataFunc = setAccountManagementState
      break
  }

  //set initial tabs data for domain
  if (
    !_.isEmpty(USER_DATA) &&
    USER_DATA?.tabs &&
    domain_tabs_place?.toString() in USER_DATA?.tabs
  ) {
    tabData = Object.entries(USER_DATA?.tabs[domain_tabs_place]).map(
      (item) => item[1],
    )
    initialState = Object.fromEntries(
      tabData
        ? tabData
            ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
            ?.map((i: any) => [i.name, false])
        : [],
    )
  }

  const handleSubtabClick = (
    itemName: string,
    allClose?: boolean,
    linkClicked?: boolean,
  ) => {
    if (linkClicked) return
    if (!setDataFunc) return
    if (allClose) {
      setDataFunc({
        type: `SET_${DOMAIN}_DATA`,
        payload: {
          subTabs: initialState,
        },
      })
    } else {
      setDataFunc({
        type: `SET_${DOMAIN}_DATA`,
        payload: {
          subTabs: itemName,
        },
      })
    }
  }

  //get and store User Data and tabs from db
  useEffect(() => {
    function convertTabConfigToDrawerInfo(tab_config: any) {
      let parentTabs: any = []
      let configEntries = Object.entries(tab_config)
      if (configEntries.length === 0) return
      let config = configEntries.map((config_item: any) => config_item[1])
      if (!config || config.length === 0) return
      config
        ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
        .forEach((tab: any) => {
          let hasChildren = tab.children?.length > 0
          let mappedChildren = null
          let parentIcon = tab.icon
            ? tab.icon
            : hasChildren
              ? tab.children[0].icon
              : undefined
          if (hasChildren) {
            mappedChildren = tab.children
              ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
              .map((subTab: any) => {
                return {
                  name: subTab.name,
                  icon: subTab.icon,
                  link: `/${subTab.name}`,
                  priority: subTab.priority,
                  id: subTab.id,
                  iframe: subTab.iframe_link,
                }
              })
            parentTabs = parentTabs.concat({
              id: tab.id,
              name: tab.name,
              icon: parentIcon,
              subTabs: mappedChildren,
            })
          }
        })
      return parentTabs
    }

    function setTabDisplay(tabInfo: any) {
      let parentTabs: any = convertTabConfigToDrawerInfo(tabInfo)
      redirectFromBasePage(parentTabs)
      if (!setDataFunc) return
      setDataFunc({
        type: `SET_${DOMAIN}_DATA`,
        payload: { TAB_STATE: parentTabs },
      })
      sessionStorage.setItem('helika_tabs', JSON.stringify(tabInfo))
    }

    //if still loading auth, don't do anything yet
    if (isLoading || !isAuthenticated) return

    //set cached tabs
    let storedTabs = sessionStorage.getItem('helika_tabs')
    if (storedTabs !== null && !_.isEmpty(storedTabs)) {
      setTabDisplay(JSON.parse(storedTabs))
    }

    //if data available in user, use tabs config to repopulate drawer
    if (!USER_DATA.tabs || !(domain_tabs_place?.toString() in USER_DATA.tabs))
      return
    setTabDisplay(USER_DATA.tabs[domain_tabs_place])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, USER_DATA])

  function redirectFromBasePage(tabs: any) {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === `/${DOMAIN?.toString()?.toLowerCase()}` ||
      window.location.pathname === `/${DOMAIN?.toString()?.toLowerCase()}/`
    ) {
      if (!_.isEmpty(tabs)) {
        if (!_.isEmpty(tabs[0].subTabs)) {
          navigate(
            `/${DOMAIN?.toString()?.toLowerCase()}/${lowerCaseAndReplaceSpacesAndDashes(tabs[0].name)}/${lowerCaseAndReplaceSpacesAndDashes(tabs[0].subTabs[0].name)}`,
          )
        } else {
          navigate('/profile')
        }
      }
    }
  }

  if (
    isLoading ||
    !isAuthenticated ||
    _.isEmpty(tabData) ||
    !window.location.pathname.startsWith(`/${DOMAIN?.toLowerCase()}`)
  )
    return <div style={{ flex: 1 }} />

  return (
    <ScrollBlock
      style={{
        marginTop: `${HEADER.height}`,
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        background:
          theme.palette.mode === 'dark' ? newColor.midnight : newColor.cultured,
      }}
    >
      <List
        sx={{
          flexGrow: 1,
        }}
      >
        {tabData?.map(
          ({ name, icon, children, url_suffix }: any, index: number) => {
            return (
              <DrawerList
                key={name + '_' + index}
                name={name}
                icon={icon}
                subTabs={children}
                url={url_suffix}
                index={index}
                open={isDrawerOpen}
                handleDrawerClick={handleSubtabClick}
              />
            )
          },
        )}
      </List>
    </ScrollBlock>
  )
}
