import AnalyticsIcon from 'src/assets/icons/analytics_solid.svg'
import GameManagementIcon from 'src/assets/icons/game_management_solid.svg'
import OrganizationManagementIcon from 'src/assets/icons/org_management_solid.svg'
import SupportIcon from 'src/assets/icons/support_module_solid.svg'
import AccountManagementIcon from 'src/assets/account_management_icon.svg'

import { Box, Typography, useTheme } from '@mui/material'
import styled from 'styled-components'

import { TabData } from './type'
import { useModules } from '../useGetModules'
import ParentTabs from './ParentTab'
import { lightPalette, palette, newColor } from 'src/consts/colors'

const RootTabStyled: any = styled(Box)`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  width: 280px;
  padding: 8px 12px;
  border-radius: 10px;
  background: linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%);
  text-transform: uppercase;

  & img {
    width: 20px;
  }
`

export default function ModuleTab({ tabData }: { tabData: TabData }) {
  const theme = useTheme()
  const { modules } = useModules()
  const keys = Object.keys(tabData)

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'column', gap: '10px' }}>
      {keys?.map((moduleTabId) => {
        if (!modules) return null
        const module = modules.filter((m) => m.id === Number(moduleTabId))
        if (!module.length) return null

        return (
          <Box
            key={moduleTabId}
            sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              gap: '5px',
              padding: '10px 18px 5px',
              border: '1px solid',
              borderColor:
                theme.palette.mode === 'dark'
                  ? palette.primaryBorder
                  : lightPalette.primaryBorder,
              borderRadius: '10px',
            }}
          >
            <RootTabStyled>
              {getModuleIcon(module[0].name)}
              <Typography sx={{ color: newColor.white }}>{module[0].name}</Typography>
            </RootTabStyled>
            <ParentTabs moduleTabId={moduleTabId} tabData={tabData} />
          </Box>
        )
      })}
    </Box>
  )
}

enum ModuleTabName {
  Analytics = 'ANALYTICS',
  GameManagement = 'GAME MANAGEMENT',
  Support = 'SUPPORT',
  OrgManagement = 'ORGANIZATION MANAGEMENT',
  AccountManagement = 'ACCOUNT MANAGEMENT',
}

function getModuleIcon(moduleName: string) {
  let moduleIcon = <div style={{ flex: 1 }} />

  switch (moduleName) {
    case ModuleTabName.Analytics:
      moduleIcon = <img src={AnalyticsIcon} alt="Analytics" />
      break
    case ModuleTabName.GameManagement:
      moduleIcon = <img src={GameManagementIcon} alt="Game Management" />
      break
    case ModuleTabName.Support:
      moduleIcon = <img src={SupportIcon} alt="Support" />
      break
    case ModuleTabName.OrgManagement:
      moduleIcon = (
        <img src={OrganizationManagementIcon} alt="Organization Management" />
      )
      break
    case ModuleTabName.AccountManagement:
      moduleIcon = <img src={AccountManagementIcon} alt="Account Management" />
      break
  }
  return <>{moduleIcon}</>
}
