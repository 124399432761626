//imports
import React from "react"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';
import { StyledSvg } from "./organisms/v2/tabs/styled";
import * as ReactTooltip from 'react-tooltip';


//contexts

//services

//components
import { ReactComponent as SaveIcon } from 'src/assets/explore_save_icon.svg'
import { ReactComponent as CaratDownIcon } from 'src/assets/carat_down_icon.svg'
import { newColor } from "src/consts/colors";
//---------------------------------------------------------------------------------

function VisualDesignerSaveMenu({
  selectedVisual,
  updateVisualHandler,
  showSaveVisualModal,
  showDeleteVisualModal,
  selectedQuery
}: {
  selectedVisual: any,
  updateVisualHandler: any,
  showSaveVisualModal: any,
  showDeleteVisualModal: any,
  selectedQuery: any
}) {

  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function menuItemDiv(name: string) {
    return (
      <div style={{ fontSize: '0.8em', paddingBottom: '0.5em', paddingTop: '0.5em', textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
        {name.toUpperCase()}
      </div>
    );
  }

  return (
    <div
      style={{
        width: 'fit-content'
      }}
      data-tooltip-id="vizdesignersavemenu"
      data-tooltip-content={!selectedQuery?.id ? 'Create a new visual using a saved query before saving' : ''}
    >
      <ReactTooltip.Tooltip id="vizdesignersavemenu" place="bottom-start" />
      <Button
        style={{
          border: 'solid',
          borderWidth: '1px',
          borderColor: newColor.darkGunmetal,
          borderRadius: '0.3em',
          padding: '0.2em 0.5em 0.2em 0.5em',
          backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          color: theme.palette.text.primary,
          marginLeft: '0.5em',
          fontSize: '0.8em',
          fontWeight: '600',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!selectedQuery?.id}
      >
        <StyledSvg
          sx={{
            '& svg, & path': {
              fill: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary,
              stroke: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary
            },
          }}
        >
          <SaveIcon />
        </StyledSvg>
        <div
          style={{
            margin: '0.1em 0 0 0.5em',
            color: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary
          }}
        >
          SAVE
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 0 0 1em',
            padding: '0.5em 0 0.5em 0.5em',
            borderLeft: 'solid',
            borderColor: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary
          }}
        >
          <StyledSvg
            sx={{
              '& svg, & path': {
                fill: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary,
                stroke: !selectedQuery?.id ? newColor.gunMetal : theme.palette.text.primary
              },
            }}
          >
            <CaratDownIcon />
          </StyledSvg>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
        sx={
          {
            marginTop: '1em',
            marginLeft: '0.5em',
            "& .MuiPaper-root":
              { background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke, borderRadius: '0.5em', border: 'solid', borderColor: newColor.darkGunmetal },
            "& .MuiList-root":
              { background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke, borderWidth: '1px', borderRadius: '0.5em', borderColor: theme.palette.mode === 'dark' ? newColor.whiteSmoke : 'black', paddingLeft: '0.5em', paddingRight: '0.5em', color: theme.palette.mode === 'dark' ? 'white' : 'black' },
            "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
              borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#3D3D3D', width: '100%'
            },
            ".MuiButtonBase-root:hover": {
              background: theme.palette.mode === 'dark' ? 'black' : newColor.whiteSmoke,
              color: '#ea335f'
            },
          }
        }
      >
        <MenuItem
          disabled={!selectedVisual?.id}
          onClick={updateVisualHandler}
        >
          {menuItemDiv('SAVE')}
        </MenuItem>
        <MenuItem
          disabled={!selectedQuery?.id}
          onClick={() => showSaveVisualModal(true)}
        >
          {menuItemDiv('SAVE AS NEW')}
        </MenuItem>
        <MenuItem
          onClick={() => showDeleteVisualModal(true)}
          disabled={!selectedVisual?.id}
        >
          {menuItemDiv('DELETE')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default VisualDesignerSaveMenu;