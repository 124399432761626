import DialogTitle from '@mui/material/DialogTitle'
import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import ExploreIconHeader from '../../assets/explore_icon_wide.svg'
import { newColor } from 'src/consts/colors'
import QuerySidebar from '../Explore/QuerySidebar'
import { ReUsableCodeMirror } from '../codeMirror/ReUsableCodeMirror';
import DontSaveQueryModal from './DontSaveQueryModal'

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const SavedQueriesModal = (props: any) => {
  const { open, setOpen, setSelectedQuery, userQueries, orgQueries } = props
  const theme = useTheme()
  const [interiorSelectedQuery, setInteriorSelectedQuery] = useState<any>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [dontSaveQueryModalBoolean, setDontSaveQueryModalBoolean] = useState<boolean>(false)
  const [code, setCode] = useState('');

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (interiorSelectedQuery?.id) {
      setCode(interiorSelectedQuery.query)
    } else {
      setCode('')
    }
  }, [interiorSelectedQuery])

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          minWidth: 'min(80em,90vw)',
          minHeight: 'min(30em,75vh)'
        },
      }}
    >
      <DontSaveQueryModal
        open={dontSaveQueryModalBoolean}
        setOpen={setDontSaveQueryModalBoolean}
        openQueryHandler={() => {
          setSelectedQuery(interiorSelectedQuery)
          handleClose()
          setDontSaveQueryModalBoolean(false)
        }}
      />
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            width: '100%',
            maxWidth: '25em',
            margin: '30px auto 0 auto',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={ExploreIconHeader}
              alt="create_new_query"
              style={{ margin: '0 0.6em 0.2em 0', width: '1.2em' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              SAVED QUERIES
            </div>
          </DialogTitle>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: 'calc(100% - 15em)',
          margin: '0 1em 0 1em'
        }}
      >
        <QuerySidebar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          userQueries={userQueries}
          orgQueries={orgQueries}
          setSelectedQuery={setInteriorSelectedQuery}
          selectedQuery={interiorSelectedQuery}
        />
        <ReUsableCodeMirror
          code={code}
          customWidth={`calc(100% - 15em)`}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'end'}
        sx={{ padding: '0 2em 2em 2em' }}
      >
        <Button
          sx={{ width: '15em', marginLeft: '1em' }}
          onClick={() => {
            setDontSaveQueryModalBoolean(true)
          }}
          className="helikaButtonClass"
        >
          SELECT QUERY
        </Button>
      </Box>
    </Dialog>
  )
}

export default SavedQueriesModal
