//imports
import '../../App.css';
import React, { useContext, useEffect } from "react";
import { Auth0Context } from "../../contexts/Auth0Context";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../state";

import { Box } from '@mui/material';

//----------------------------------------------------------------------------------------------------------

export default function Logout() {

  const dispatch = useDispatch();
  const { setUserData, setNotifications, setAnalyticsState, setGameManagementState, setAccountManagementState, setSupportState } = bindActionCreators(actionCreators, dispatch);


  const sdk = useSelector((state: any) => state?.sdk)
  const { logoutAuth0 } = useContext(Auth0Context);

  async function clearAllData() {
    setUserData({
      type: 'CLEAR_DATA'
    });
    if (sdk) {
      sdk?.setUserDetails({})
    }
    setNotifications({
      type: 'CLEAR_DATA'
    });
    setAnalyticsState({
      type: 'CLEAR_DATA'
    });
    setGameManagementState({
      type: 'CLEAR_DATA'
    });
    setAccountManagementState({
      type: 'CLEAR_DATA'
    });
    setSupportState({
      type: 'CLEAR_DATA'
    });
  }

  async function cleanLogout() {
    await logoutAuth0(clearAllData);
  }

  useEffect(() => {
    cleanLogout();
  })

  return (
    <Box display={'flex'} flexDirection={'column'} style={{ height: '100vh' }} justifyContent={'center'}>
      Logging out...
    </Box>
  );
};