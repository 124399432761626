import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  FormControlLabel,
  Stack,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { Button, Colors, Textfield, ErrorBanner, Spacing } from 'helika-ui-sdk'
import UploadImageComponent from './UploadImageComponent'
import { editOffer } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { useSelector } from 'react-redux'
import _, { debounce } from 'lodash'
import { isStringEmpty, prependUrlProtocolIfNecessary } from 'src/utils/string'
import { isValidURL } from 'src/utils/validators'
import { Android12Switch } from './CreateOfferModal'
import CloseIcon from '@mui/icons-material/Close';
import { spacing } from 'helika-ui-sdk/dist/src/values/Spacing'
import ReusableSnackbar from 'src/pages/Modules/GameManagement/RedemptionCode/components/ReusableSnackbar'
import { lightPalette } from 'src/consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onEditOffer: any
  selectedOffer: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const EditOfferModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onEditOffer, selectedOffer } = props
  const theme = useTheme()
  const { getTokenIfNecessary } = useContext(Auth0Context);
  const { organization_id, id } = useSelector((state: any) => state.user)

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  const [image, setImage] = useState<any>();
  const [storedImageId, setStoredImageId] = useState<string | undefined>();
  const [missingDetails, setMissingDetails] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }
  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }
  const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function resetValues(selectedOffer: any) {
    if (_.isEmpty(selectedOffer)) return
    setTitle(selectedOffer.title)
    setDescription(selectedOffer.body)
    setUrl(selectedOffer.cta_url)
    setIsActive(selectedOffer.active)
    setStoredImageId(selectedOffer?.image)
    setImage(undefined)
  }

  useEffect(() => {
    resetValues(selectedOffer)
  }, [selectedOffer])

  const debouncedClickHandler = debounce(editOfferApi, 200, { maxWait: 500 })

  async function editOfferApi() {

    try {
      if (loading) return
      setLoading(true)

      try {
        isStringEmpty(title, 'Title required')
        isStringEmpty(url, 'Button URL required')
      } catch (e: any) {
        setMissingDetails(true)
        throw new Error(e)
      }
      if (!isValidURL(url)) {
        setLoading(false)
        setSnackbarMessage('Button URL is an invalid URL')
        setSnackbarVariant('error')
        setSnackbarOpen(true)
        return;
      }

      let offer: any = {
        id: selectedOffer.id,
        title: title,
        body: description,
        cta_url: prependUrlProtocolIfNecessary(url),
        start: null,
        organization_id: organization_id,
        creator: id,
        active: isActive,
        end: null,
        updated_at: new Date(),
        image: !_.isEmpty(storedImageId) ? selectedOffer.image : null,
      }

      await editOffer(getTokenIfNecessary, {
        file: image,
        offer: offer
      }).then((resp: any) => {
        if (!_.isEmpty(resp?.results?.offers)) {
          onEditOffer(resp?.results?.offers)
          setOpen(false)
          setLoading(false)
          setMissingDetails(false)
        } else {
          throw new Error('Could not edit offer')
        }
      })
    } catch (e: any) {
      setSnackbarMessage(e?.message)
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }

  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle open-sans-fontFam400"
      sx={{
        margin: 'auto',
        borderRadius: '1px !important',
        borderStyle: 'none',
        width: '420px',
        border: 'none',
        background: '#1A000000'
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? Colors.background.primary : lightPalette.bgPage,
          borderStyle: 'none',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '1px !important',
          minWidth: '420px',
          width: 'fit-content',
          boxShadow: '0px 20px 20px 0px #4D000000 !important',
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_lg}`,
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            lineHeight: '140%',
            fontWeight: 600,
          }}
        >
          Edit Offer
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto 0 auto 0',
            cursor: 'pointer',
            height: '32px',
            width: '32px'

          }}
          onClick={handleClose}
        >
          <CloseIcon
            sx={{
              fontSize: '24px',
              height: '24px',
              width: '24px',
              margin: 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.Spacing_md,
          padding: `${Spacing.Spacing_lg}`
        }}
      >
        <ErrorBanner
          show={missingDetails && (_.isEmpty(title) || _.isEmpty(url))}
          errorMessage='Fill out missing offer details.'
        />
        <Textfield
          darkMode={theme.palette.mode === 'dark'}
          label={'Title'}
          value={title}
          onChange={handleChangeTitle}
          hasError={(missingDetails && _.isEmpty(title?.trim()))}
          placeHolder='Enter title'
        />
        <Textfield
          darkMode={theme.palette.mode === 'dark'}
          label={'Button URL'}
          value={url}
          onChange={handleChangeUrl}
          hasError={(missingDetails && _.isEmpty(url?.trim()))}
          placeHolder='https://'
        />
        <Stack direction="column" gap={1}>
          <Box sx={{ fontSize: '0.9em' }}>Image (Optional)</Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <UploadImageComponent
              image={image}
              setImage={setImage}
              storedImageId={storedImageId}
              setStoredImageId={setStoredImageId}
              disabled={false}
            />
          </Box>
        </Stack>
        <Textfield
          darkMode={theme.palette.mode === 'dark'}
          label={'Description (Optional)'}
          value={description}
          onChange={handleChangeDescription}
          hasError={false}
          isTextBox={true}
          placeHolder='Enter description'
        />
        <Stack direction="row" gap={1}>
          <FormControlLabel
            control={
              <Android12Switch
                checked={isActive}
                onClick={() => {
                  setIsActive(!isActive)
                }}
              />}
            label={
              <Box
                sx={{
                  padding: 0,
                  fontFamily: "Open Sans",
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '140%', /* 19.6px */
                }}
              >
                Offer Status is {isActive ? 'Active' : 'Inactive'}
              </Box>}
          />
        </Stack>
      </Box >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: Spacing.Spacing_xxs,
          padding: `${Spacing.Spacing_sm} ${spacing.Spacing_lg}`
        }}
      >
        <Button
          type={theme.palette.mode === 'dark' ? 'Tertiary' : 'Primary'}
          handleClick={() => {
            resetValues(selectedOffer)
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type='Primary'
          disabled={loading}
          handleClick={debouncedClickHandler}
        >
          Save
        </Button>
      </Box>
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Dialog>
  )
}

export default EditOfferModal
