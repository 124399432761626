import DialogTitle from '@mui/material/DialogTitle'
import React, { useContext, useState } from 'react'

import {
  Box,
  styled as muiStyled,
  Button,
  useTheme,
  Grid,
  TextField,
  SvgIcon,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { ReactComponent as Announcement_Type_Icon_Red } from '../../assets/Announcement_Type_Icon_Red.svg'
import { ReactComponent as Announcement_Type_Icon_Green } from '../../assets/Announcement_Type_Icon_Green.svg'
import { ReactComponent as Announcement_Type_Icon_Yellow } from '../../assets/Announcement_Type_Icon_Yellow.svg'
import Announcement_Icon from '../../assets/Announcement_Icon.svg'
import { isStringEmpty } from '../../utils/string'
import Analytics from '../../assets/analytics_icon.svg'
import Analytics_Light from '../../assets/analytics_icon_light.svg'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { createMessageApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import { DateTimePicker } from '@mui/x-date-pickers'
import {
  FlightLandIcon,
  FlightLandIconHover,
  FlightLandIconLight,
} from '../../components/svgCustom/customIcon'
import { newColor } from '../../consts/colors'

const Input = muiStyled('input')(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  color: theme.palette.text.primary,
  padding: '4px 15px 4px 15px',
  fontSize: '14px',
  lineHeight: '17px',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

export interface SimpleDialogProps {
  onCreated?: any
  open: boolean
  setOpen: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const CreateNewAnnouncementModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onCreated } = props
  const theme = useTheme()
  const [loading, setLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [typeData, setTypeData] = useState<string>('')
  const { postWithAccessToken } = useContext(Auth0Context)
  const [dateValue, setDateValue] = useState<Dayjs | null>(null)
  const [hover, setHover] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  async function createNewAnnouncementQuery() {
    try {
      setLoading(true)

      isStringEmpty(typeData, 'Type required')
      isStringEmpty(title, 'Title required')
      isStringEmpty(message, 'Message required')

      if (title && message && dateValue && typeData) {
        setLoading(true)

        let params = {
          title: title,
          type: typeData,
          body: message,
          show: true,
          created_by: 0,
          expiry: dayjs(dateValue).format('YYYY-MM-DDTHH:mm:ss'),
        }

        await createMessageApi(postWithAccessToken, params)
          .then(() => {
            toast.success('Successful created Announcement Message.')
            setLoading(false)
          })
          .catch((err: any) => {
            toast.error(err?.message)
            console.log(err)
            setLoading(false)
          })
        onCreated()
        setTitle('')
        setTypeData('')
        setMessage('')
        setDateValue(null)
        setLoading(false)
      } else {
        toast.warning('Please enter New Tab Name and other fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
        <Box
          display="flex"
          flexDirection={'row'}
          className={
            theme.palette.mode === 'dark'
              ? 'circleBackground'
              : 'circleBackgroundLight'
          }
          sx={{
            justifyContent: 'center',
            minWidth: '20em',
            margin: '30px 40px 0 40px',
          }}
        >
          <DialogTitle
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } centertext`}
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={Announcement_Icon}
              alt="create_tab"
              style={{ margin: 'auto 0.3em auto 0' }}
            />{' '}
            <div style={{ lineHeight: '26px', fontSize: '23px' }}>
              CREATE NEW ANNOUNCEMENT
            </div>
          </DialogTitle>
        </Box>
        <Box
          display="flex"
          flexDirection={'column'}
          sx={{
            padding: '0 40px 0 58px',
            width: '100%',
            justifyContent: 'center',
            minWidth: '60vw',
          }}
          gap={'8px'}
        >
          <Typography
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              lineHeight: '22.5px',
            }}
          >
            <img
              src={theme.palette.mode === 'dark' ? Analytics : Analytics_Light}
              alt="APIs"
              width="21px"
              height="21px"
            />
            &nbsp;&nbsp;ANNOUNCEMENT INFORMATION
          </Typography>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TYPE:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Select
                className={
                  theme.palette.mode === 'dark'
                    ? 'inputFieldClass3'
                    : 'inputFieldClass3Light'
                }
                labelId="search-filter"
                defaultValue={''}
                size="small"
                onChange={(event: SelectChangeEvent) => {
                  setTypeData(event.target.value)
                }}
                sx={{
                  height: '2em',
                  width: '100%',
                  padding: '0',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
                value={typeData ? typeData.toString() : ''}
              >
                <MenuItem
                  value="urgent"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <SvgIcon component={Announcement_Type_Icon_Red} />
                  <span>URGENT</span>
                </MenuItem>
                <MenuItem
                  value="good"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <SvgIcon component={Announcement_Type_Icon_Green} />
                  <span>GOOD</span>
                </MenuItem>
                <MenuItem
                  value="notice"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <SvgIcon component={Announcement_Type_Icon_Yellow} />
                  <span>NOTICE</span>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                TITLE:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Input
                value={title}
                placeholder="ANNOUNCEMENT TITLE"
                onChange={(e) => {
                  setTitle(e.currentTarget.value)
                }}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'start', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                MESSAGE:
              </div>
            </Grid>
            <Grid width={'484px'}>
              <TextField
                className={
                  theme.palette.mode === 'dark'
                    ? 'textFieldClass'
                    : 'textFieldClassLight'
                }
                value={message}
                onChange={(e) => {
                  setMessage(e.currentTarget.value)
                }}
                size={'small'}
                multiline
                rows={4}
                placeholder="ANNOUNCEMENT MESSAGE"
              />
            </Grid>
          </Grid>
          <Grid
            columnSpacing={1}
            rowSpacing={2}
            style={{ margin: '0.5em 0 0 0' }}
            display={'flex'}
            alignContent={'center'}
            flexDirection={'row'}
            paddingLeft={'35px'}
          >
            <Grid
              style={{ display: 'flex', alignItems: 'center', width: '200px' }}
            >
              <div
                className="minimizeFontOnSmallWindow"
                style={{ fontSize: '14px', width: 'max-content' }}
              >
                EXPIRY DATE:
              </div>
            </Grid>
            <Grid style={{ width: '484px' }}>
              <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="dateField"
                    value={dateValue}
                    onChange={(newValue) => setDateValue(newValue)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '100px',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
                    }}
                    defaultValue={dayjs()}
                    slots={{
                      openPickerIcon: hover
                        ? FlightLandIcon
                        : theme.palette.mode === 'dark'
                          ? FlightLandIconHover
                          : FlightLandIconLight,
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {loading ? (
        <div className="w-full">
          <LoadingComponent />
        </div>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          sx={{ padding: '0 2em 2em 2em' }}
        >
          <Button
            sx={{ width: '15em' }}
            disabled={loading}
            onClick={createNewAnnouncementQuery}
            className="helikaButtonClass"
          >
            Save
          </Button>
          <Button
            sx={{ width: '15em', marginLeft: '1em' }}
            onClick={() => setOpen(false)}
            className="helikaButtonClass"
          >
            Cancel
          </Button>
        </Box>
      )}
    </Dialog>
  )
}

export default CreateNewAnnouncementModal
