import React, { FC, useMemo } from 'react'
import { Stack } from '@mui/material'

export interface INotificationSVG {
  on?: boolean
  hover?: boolean
  gradientColors?: string[]
  width?: number
}

const NotificationSVG: FC<INotificationSVG> = ({
  on = false,
  hover = false,
  gradientColors = ['#6926AD', '#EA335F'],
  width = 21,
}) => {
  const _gradientColors = useMemo(
    () => (hover ? gradientColors.reverse() : gradientColors),
    [hover],
  )

  const content = useMemo(
    () =>
      on ? (
        <>
          <path
            d="M19.0918 1.64478C18.4882 1.64478 17.9256 1.79822 17.4244 2.06419C16.34 2.6575 15.5933 3.81344 15.5933 5.13305C15.5933 6.45267 16.3298 7.60861 17.4244 8.20192C17.9154 8.46789 18.4882 8.62133 19.0918 8.62133C21.0149 8.62133 22.58 7.05621 22.58 5.13305C22.58 3.2099 21.0252 1.64478 19.0918 1.64478Z"
            fill="url(#grad1)"
          />
          <path
            d="M12.1152 24.0986C10.7956 24.0986 9.47601 24.0986 8.1564 24.0986C8.72925 25.7353 10.3762 26.8504 12.1152 26.8094C12.1255 26.8094 12.1357 26.8094 12.1459 26.8094C12.1562 26.8094 12.1562 26.8094 12.1664 26.8094C12.1766 26.8094 12.1868 26.8094 12.1971 26.8094C12.2073 26.8094 12.2175 26.8094 12.2278 26.8094C13.9668 26.8504 15.624 25.7353 16.1866 24.0986C14.8261 24.0986 13.4655 24.0986 12.1152 24.0986Z"
            fill="url(#grad1)"
          />
          <path
            d="M24.2885 21.6742C24.2578 21.4185 24.1146 21.1321 23.9305 20.9479C22.6211 19.6181 22.2528 20.017 21.9357 18.1757C21.8334 17.6029 21.813 17.0096 21.7925 16.4265C21.7516 14.9841 21.8743 11.1889 21.6595 9.1021C20.923 9.58289 20.0432 9.85909 19.0919 9.85909C16.4834 9.85909 14.3658 7.74157 14.3658 5.13304C14.3658 3.79296 14.9285 2.57565 15.8287 1.71636C14.6727 1.2458 13.4145 0.990066 12.1256 1.0003C7.778 0.969607 3.82939 3.99756 2.78598 8.23259C2.37679 9.88978 2.5507 14.7488 2.50978 16.4367C2.49955 17.0198 2.46886 17.6131 2.36656 18.1859C2.04945 20.0273 1.67096 19.6283 0.371802 20.9582C0.18767 21.1423 0.044456 21.4287 0.0137673 21.6845C-0.0782987 22.4721 0.484327 23.0654 1.28223 23.0654C5.02625 23.0654 8.24856 23.0654 11.9926 23.0654C12.0642 23.0654 12.1358 23.0654 12.2074 23.0654C12.2483 23.0654 12.279 23.0654 12.3199 23.0654C16.0639 23.0654 19.2863 23.0654 23.0303 23.0654C23.8282 23.0552 24.3908 22.4619 24.2885 21.6742Z"
            fill="url(#grad1)"
          />
        </>
      ) : (
        <>
          <path
            d="M12.1154 24.0986C10.7957 24.0986 9.47613 24.0986 8.15652 24.0986C8.72937 25.7353 10.3763 26.8503 12.1154 26.8094C12.1256 26.8094 12.1358 26.8094 12.146 26.8094C12.1563 26.8094 12.1563 26.8094 12.1665 26.8094C12.1767 26.8094 12.187 26.8094 12.1972 26.8094C12.2074 26.8094 12.2177 26.8094 12.2279 26.8094C13.9669 26.8503 15.6241 25.7353 16.1867 24.0986C14.8262 24.0986 13.4657 24.0986 12.1154 24.0986Z"
            fill="url(#grad1)"
          />
          <path
            d="M24.2849 21.6626C24.2542 21.4097 24.111 21.1264 23.9268 20.9443C22.6174 19.6291 22.2492 20.0236 21.932 18.2025C21.8297 17.636 21.8093 17.0492 21.7888 16.4725C21.7479 14.8133 21.9218 10.0076 21.5126 8.35854C20.4692 4.15992 16.5001 1.15512 12.1218 1.20571C7.77418 1.18547 3.82553 4.18015 2.7821 8.36866C2.37292 10.0076 2.54682 14.8133 2.5059 16.4826C2.49567 17.0593 2.46498 17.6461 2.36269 18.2127C2.04557 20.0338 1.66707 19.6392 0.3679 20.9544C0.183766 21.1365 0.0405502 21.4198 0.00986115 21.6727C-0.0822059 22.4518 0.480426 23.0386 1.27834 23.0386C5.0224 23.0386 8.24475 23.0386 11.9888 23.0386C12.0604 23.0386 12.132 23.0386 12.2036 23.0386C12.2445 23.0386 12.2752 23.0386 12.3162 23.0386C16.0602 23.0386 19.2826 23.0386 23.0266 23.0386C23.8245 23.0284 24.3872 22.4416 24.2849 21.6626Z"
            fill="url(#grad1)"
          />
        </>
      ),
    [on],
  )

  return (
    <Stack sx={{ width: `${width}px` }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 25 28"
        fill="none"
      >
        <defs>
          <linearGradient id="grad1" x1="100%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={_gradientColors[0]} stopOpacity={1} />
            <stop
              offset="100%"
              stopColor={_gradientColors[1]}
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        {content}
      </svg>
    </Stack>
  )
}

export default NotificationSVG
