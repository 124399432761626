import { Box, Typography, styled as muiStyled } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconDomainAccess } from 'src/assets/domain_access.svg'
import { useModules } from '../useGetModules'

//access
import { ReactComponent as AnalyticsIcon } from 'src/assets/icons/analytic_icon.svg'
import { ReactComponent as GameManagementIcon } from 'src/assets/icons/game_management_icon.svg'
import { ReactComponent as SupportIcon } from 'src/assets/icons/support_icon.svg'
import { ReactComponent as AccountManagementIcon } from 'src/assets/account_management_icon.svg'
import { StyledSvg } from '../tabManagement/styled'
import { lightPalette, palette, newColor } from 'src/consts/colors'

const Header: any = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`

const Label = muiStyled('span')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 400,
  marginLeft: '10px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
}))

const BoxDomain = muiStyled('div')(({ theme }) => ({
  borderRadius: '10px',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  padding: '0 0.875rem',
  marginBottom: '0.75rem',
}))

const LabelDomain: any = styled.div`
  display: flex;
  align-items: center;
`

const AccessLevel: any = styled.div`
  padding: 1.5rem 5.625rem;
  display: flex;
`

const DomainAccess = () => {
  const { modules } = useModules()

  const renderIconDomainAccess = (name: string) => {
    let iconDomainAccess = <div style={{ flex: 1 }} />

    switch (name) {
      case 'ANALYTICS':
        iconDomainAccess = (
          <StyledSvg>
            <AnalyticsIcon />
          </StyledSvg>
        )
        break
      case 'GAME MANAGEMENT':
        iconDomainAccess = (
          <StyledSvg>
            <GameManagementIcon />
          </StyledSvg>
        )
        break
      case 'SUPPORT':
        iconDomainAccess = (
          <StyledSvg>
            <SupportIcon />
          </StyledSvg>
        )
        break
      case 'ACCOUNT MANAGEMENT':
        iconDomainAccess = (
          <StyledSvg>
            <AccountManagementIcon width={'22px'} height={'22px'} />
          </StyledSvg>
        )
        break
    }
    return <>{iconDomainAccess}</>
  }

  return (
    <>
      <Header>
        <Box display="flex" alignItems="center">
          <StyledSvg>
            <IconDomainAccess />
          </StyledSvg>
          <Label>Domain Access</Label>
        </Box>
      </Header>
      {modules &&
        [...modules].slice(0, 4).map((item, index) => {
          return (
            <BoxDomain key={index}>
              <Box textAlign="end" style={{ height: '1em' }}></Box>
              <LabelDomain>
                {renderIconDomainAccess(item.name)}
                <Typography
                  sx={{
                    marginLeft: 1.5,
                    color: 'text.primary',
                    fontFamily: 'Paralucent Medium',
                    fontSize: '20px',
                  }}
                >
                  {item.name}
                </Typography>
              </LabelDomain>
              <AccessLevel>
                <Typography
                  sx={{
                    color: 'text.primary',
                    fontFamily: 'Paralucent Medium',
                    fontSize: '15px',
                  }}
                >
                  ACCESS LEVEL:
                </Typography>
                <span
                  style={{
                    marginLeft: '1rem',
                    textTransform: 'uppercase',
                    color: newColor.spanishGray,
                    fontSize: '15px',
                  }}
                >
                  {item.module_access_level}
                </span>
              </AccessLevel>
            </BoxDomain>
          )
        })}
    </>
  )
}

export default DomainAccess
