import React, { useRef, useEffect } from 'react'

import { basicSetup } from "codemirror";
import { EditorState } from '@codemirror/state'
import { EditorView } from '@codemirror/view'
import { coolGlow, tomorrow } from 'thememirror';
import { PostgreSQL, sql } from '@codemirror/lang-sql';
import {
  useTheme,
} from '@mui/material'

export function ReUsableCodeMirror(props: any) {
  const editor: any = useRef()
  const theme = useTheme()

  let extensions: any = [
    basicSetup,
    sql({
      dialect: PostgreSQL,
    }),
    theme.palette.mode === 'dark' ? coolGlow : tomorrow,
    EditorView.editable.of(false)
  ]

  useEffect(() => {
    const startState = EditorState.create({
      doc: props?.code,
      extensions: extensions,
    })

    const view = new EditorView({ state: startState, parent: editor.current })

    return () => {
      view.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, theme?.palette?.mode])

  return (

    <div
      id={`exploredivpopup popup`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        padding: '0 0.5em 0 0.5em',
        width: props.customWidth ? props.customWidth : '100%'
      }}
    >
      <div
        ref={editor}
        className={`popupCodemirror explorediv-${theme.palette.mode}`}
      />
    </div>
  )
}