import React, { useState, useCallback } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Box,
  useTheme,
  Checkbox as MuiCheckbox,
  Pagination,
} from '@mui/material'
import HookActionsMenu from './HookActionsMenu'
import FloatingActionBarComponent from './FloatingActionBarComponent'
import ReusableSnackbar from './ReusableSnackbar'
import {
  color_base,
  colors_semantic,
} from 'helika-ui-sdk/dist/src/values/Colors'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'
import { styled as muiStyled } from '@mui/material'
import { newColor } from 'src/consts/colors'

// Custom Checkbox styling for consistent look
const CustomCheckbox = muiStyled(MuiCheckbox)<{ darkMode: boolean }>(
  ({ darkMode }) => ({
    color: darkMode ? color_base.grey500 : color_base.grey400,
    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
      color: colors_semantic.background_int_primary,
    },
    '&.Mui-disabled': {
      color: darkMode
        ? colors_semantic.text_disabled
        : colors_semantic.text_placeholder,
    },
    '&:hover': {
      backgroundColor: darkMode
        ? colors_semantic.background_int_secondary_hover
        : color_base.grey400,
    },
  }),
)

// Table Header Cell styling to support dark and light modes with transparent background in light mode
const HeaderTableCell = muiStyled(TableCell)<{ darkMode: boolean }>(
  ({ darkMode }) => ({
    height: '60px',
    borderBottom: `1px solid ${colors_semantic.border_primary}`,
    padding: '0 16px',
    ...typography.label_regular,
    backgroundColor: darkMode
      ? colors_semantic.background_primary
      : 'transparent',
    color: darkMode ? newColor.white : color_base.black,
    fontFamily: 'Open Sans, sans-serif',
  }),
)

interface ReusableTableProps<T> {
  hooks: T[]
  pagination: {
    page: number
    limit: number
    count: number
  }
  headers: string[]
  renderRow: (hook: T) => React.ReactNode[]
  onChangePagination: ({ page, limit }: { page: number; limit: number }) => void
  goToEditHook: (hook: T) => void
  refreshTable: () => void
  onArchiveHook?: (hookId: number) => Promise<void>
  onBulkArchive?: (selectedIds: number[]) => Promise<void>
  showUpdateButton?: boolean
  handleUpdateUsageLimit?: (selectedIds: number[]) => void
  handleRowClick?: (hook: T) => void
}

const ReusableTable: React.FC<ReusableTableProps<any>> = ({
  hooks,
  pagination,
  headers,
  renderRow,
  onChangePagination,
  goToEditHook,
  refreshTable,
  onArchiveHook,
  onBulkArchive,
  showUpdateButton,
  handleUpdateUsageLimit,
  handleRowClick,
}) => {
  const [selectedHooks, setSelectedHooks] = useState<number[]>([])
  const [isBulkUpdating, setIsBulkUpdating] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const theme = useTheme()
  const themeMode = theme.palette.mode
  const darkMode = themeMode === 'dark'
  const selectedCount = selectedHooks.length

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = hooks.map((hook: any) => hook.id)
      setSelectedHooks(newSelecteds)
    } else {
      setSelectedHooks([])
    }
  }

  const handleSelectClick = (hookId: number) => {
    setSelectedHooks((prevSelected) => {
      const selectedIndex = prevSelected.indexOf(hookId)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = [...prevSelected, hookId]
      } else {
        newSelected = [
          ...prevSelected.slice(0, selectedIndex),
          ...prevSelected.slice(selectedIndex + 1),
        ]
      }
      return newSelected
    })
  }

  const isSelected = (hookId: number) => selectedHooks.includes(hookId)

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    onChangePagination({ page, limit: pagination.limit })
  }

  const handleBulkArchive = async () => {
    if (!selectedHooks.length) {
      setSnackbarOpen(true)
      setSnackbarMessage('No items selected for archiving.')
      setSnackbarVariant('error')
      return
    }

    setIsBulkUpdating(true)

    try {
      if (onBulkArchive) {
        await onBulkArchive(selectedHooks)
      }

      handleUnselectAll()
      refreshTable()
      setSnackbarMessage('Items archived successfully!')
      setSnackbarVariant('success')
    } catch (e: any) {
      console.error(e?.message || 'Failed to archive selected items.')
      setSnackbarMessage('Failed to archive selected items.')
      setSnackbarVariant('error')
    } finally {
      setIsBulkUpdating(false)
      setSnackbarOpen(true)
    }
  }

  const handleUnselectAll = useCallback(() => {
    setSelectedHooks([])
    setSnackbarOpen(false)
  }, [])

  const handleUpdateUsageLimitClick = () => {
    if (!selectedHooks.length) {
      setSnackbarOpen(true)
      setSnackbarMessage('No items selected for updating usage limit.')
      setSnackbarVariant('error')
      return
    }
    if (handleUpdateUsageLimit) {
      handleUpdateUsageLimit(selectedHooks)
    }
  }

  function CustomPagination() {
    const SelectCustom = muiStyled('select')(({ theme }) => ({
      width: '60px',
      borderRadius: '3px',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? colors_semantic.background_primary
          : 'white',
      border: '1px solid',
      borderColor: colors_semantic.border_primary,
      color: colors_semantic.text_primary,
      padding: '0 5px',
      '&:focus, &:hover': {
        outline: newColor.indigo,
        borderWidth: '1px',
        borderColor: newColor.gunmetalGray,
      },
    }))

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '15px 25px 0 0',
          gap: '8px',
          fontFamily: 'Open Sans, sans-serif',
        }}
      >
        <Pagination
          count={Math.ceil(pagination.count / pagination.limit)}
          page={pagination.page}
          onChange={handlePageChange}
          shape="rounded"
          sx={{
            ul: {
              '& .MuiPaginationItem-root': {
                backgroundColor: darkMode ? newColor.tertiary : 'white',
                fontFamily: 'Open Sans, sans-serif',
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: darkMode
                  ? newColor.gunmetalGray
                  : color_base.grey200,
              },
              '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                height: '32px !important',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'end',
                alignItems: 'center',
                borderRadius: '5px !important',
              },
            },
          }}
        />
      </Box>
    )
  }

  return (
    <Stack sx={{ position: 'relative' }}>
      <TableContainer sx={{ backgroundColor: 'transparent' }}>
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0' }}>
          <TableHead>
            <TableRow sx={{ height: '60px !important' }}>
              <TableCell
                padding="checkbox"
                sx={{
                  height: '60px',
                  borderBottom: `1px solid ${colors_semantic.border_primary}`,
                  padding: '0 16px',
                }}
              >
                <CustomCheckbox
                  darkMode={darkMode}
                  indeterminate={
                    selectedHooks.length > 0 &&
                    selectedHooks.length < hooks.length
                  }
                  checked={
                    hooks.length > 0 && selectedHooks.length === hooks.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {headers.map((header: string, index: number) => (
                <HeaderTableCell key={index} darkMode={darkMode}>
                  {header}
                </HeaderTableCell>
              ))}
              <TableCell
                align="right"
                sx={{
                  height: '60px',
                  borderBottom: `1px solid ${colors_semantic.border_primary}`,
                  padding: '0 16px',
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hooks.length > 0 ? (
              hooks.map((hook: any) => {
                const isItemSelected = isSelected(hook.id)
                return (
                  <TableRow
                    key={hook.id}
                    selected={isItemSelected}
                    sx={{
                      height: '60px !important',
                      cursor: handleRowClick ? 'pointer' : 'default',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: darkMode
                          ? colors_semantic.background_teriary
                          : color_base.grey200,
                      },
                      '& .MuiTableCell-root': {
                        height: '60px !important',
                        padding: '0 16px !important',
                        borderBottom: `1px solid ${colors_semantic.border_tertiary}`,
                        ...typography.paragraph_regular,
                        lineHeight: '140%',
                        textTransform: 'capitalize',
                      },
                    }}
                    onClick={(event) => {
                      if (
                        handleRowClick &&
                        !(event.target as HTMLElement).closest(
                          '.MuiCheckbox-root, .MuiIconButton-root, .status-column',
                        )
                      ) {
                        handleRowClick(hook)
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <CustomCheckbox
                        darkMode={darkMode}
                        checked={isItemSelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleSelectClick(hook.id)}
                      />
                    </TableCell>
                    {renderRow(hook)}
                    <TableCell align="right">
                      <Box onClick={(e) => e.stopPropagation()}>
                        <HookActionsMenu
                          hook={hook}
                          onEdit={goToEditHook}
                          onArchive={
                            onArchiveHook
                              ? async () => {
                                  await onArchiveHook(hook.id)
                                }
                              : async () => Promise.resolve()
                          }
                          refreshTable={refreshTable}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 2}
                  align="center"
                  sx={{
                    height: '60px !important',
                    ...typography.paragraph_regular,
                    lineHeight: '140%',
                  }}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {CustomPagination()}

      <FloatingActionBarComponent
        selectedCodesLength={selectedCount}
        handleBulkArchive={handleBulkArchive}
        handleUnselectAll={handleUnselectAll}
        isVisible={selectedCount > 0}
        isLoading={isBulkUpdating}
        showUpdateButton={showUpdateButton}
        handleUpdateUsageLimit={handleUpdateUsageLimitClick}
      />

      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Stack>
  )
}

export default ReusableTable
