import React from 'react'
import {
  Box,
  Typography,
  Stack,
  Chip,
  IconButton,
  Switch,
  Button,
  TextField,
} from '@mui/material'
import {
  AddCircleOutline as AddIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material'
import { newColor } from 'src/consts/colors'

interface HeaderSectionProps {
  hookTitle: string
  hookDescription: string
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  hookTitle,
  hookDescription,
}) => {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const [userLabels, setUserLabels] = React.useState<string[]>(['TASK', 'GAME'])
  const [isAddingLabel, setIsAddingLabel] = React.useState<boolean>(false)
  const [newLabel, setNewLabel] = React.useState<string>('')

  const handleAddLabel = () => {
    if (newLabel.trim()) {
      setUserLabels([...userLabels, newLabel.toUpperCase()])
      setNewLabel('')
      setIsAddingLabel(false)
    }
  }

  const handleDeleteLabel = (labelToDelete: string) => {
    setUserLabels((labels) => labels.filter((label) => label !== labelToDelete))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box>
        <Typography variant="h6" color={newColor.white}>
          {hookTitle}
        </Typography>
        <Typography variant="body2" color={newColor.gray}>
          {hookDescription}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {/* Label Management */}
        <Stack direction="row" alignItems="center" spacing={1}>
          {userLabels.map((label, index) => (
            <Chip
              key={index}
              label={label}
              onDelete={() => handleDeleteLabel(label)}
              sx={{
                backgroundColor: newColor.darkSlate,
                color: newColor.white,
                borderRadius: '16px',
                '& .MuiChip-deleteIcon': {
                  color: newColor.white,
                },
              }}
              deleteIcon={<CancelIcon />}
            />
          ))}

          {isAddingLabel ? (
            <TextField
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddLabel()
                }
              }}
              onBlur={() => setIsAddingLabel(false)}
              autoFocus
              sx={{
                '& .MuiInputBase-input': {
                  color: newColor.white,
                  backgroundColor: newColor.darkSlate,
                  borderRadius: '16px',
                  padding: '6px',
                },
              }}
              placeholder="Add Label"
            />
          ) : (
            <IconButton
              onClick={() => setIsAddingLabel(true)}
              sx={{ color: newColor.white }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Stack>

        <Switch
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: newColor.jazzberryJam,
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: newColor.jazzberryJam,
            },
          }}
        />

        <Button
          variant="contained"
          sx={{
            backgroundColor: isActive
              ? newColor.jazzberryJam
              : newColor.dimGray,
            color: newColor.white,
            padding: '8px 16px',
            textTransform: 'none',
            borderRadius: '50px',
            '&:hover': {
              backgroundColor: isActive
                ? newColor.byzantium
                : newColor.outerSpace,
            },
          }}
          disabled={!isActive}
        >
          PUBLISH
        </Button>
      </Box>
    </Box>
  )
}

export default HeaderSection
