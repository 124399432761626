import React, { useState, useContext, useEffect } from 'react'
import {
  AccordionDetails,
  Typography,
  styled as muiStyled,
  Button,
} from '@mui/material'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { Auth0Context } from '../../contexts/Auth0Context'
import loadingSVG from '../../assets/loading.svg'
import { PhylloSDK } from '../socialMediaManagement/phylloSDKInit'
import {
  getSocialMediaData,
  getSupportedPlatforms,
  deleteSocialSync,
  getPyhlloSdkToken,
} from '../../utils/api/queries'

interface Attribute {
  org_id: number
  type: string
  created_by: number
  updated_at: string
  account_id: string
  platform_username: string
  id: number
  created_by_email: string
}

interface Platforms {
  name: string
  id: string
  phyllo_id: string
  created_at: string
}

interface DateTimeOptions {
  day?: '2-digit' | 'numeric'
  month?: '2-digit' | 'numeric'
  year?: '2-digit' | 'numeric'
  hour?: '2-digit' | 'numeric'
  minute?: '2-digit' | 'numeric'
  second?: '2-digit' | 'numeric'
  fractionalSecondDigits?: number
}

export default function LinkSocialMedia(props: any) {
  const {
    getWithAccessToken,
    isLoading,
    isAuthenticated,
    deleteWithAccessToken,
    postWithAccessToken,
  } = useContext(Auth0Context)
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const [tiktokData, setTiktokData] = useState<Attribute[]>([])
  const [instagramData, setInstagramData] = useState<Attribute[]>([])
  const [instagramId, setInstagramId] = useState<string>('')
  const [tiktokId, setTiktokId] = useState<string>('')
  const [sdkConfig, setSdkConfig] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [tiktokDisplay, setTiktokDisplay] = useState(false)
  const [instagramDisplay, setInstagramDisplay] = useState(false)

  enum PlatformList {
    Tiktok = 'TIKTOK',
    Instagram = 'INSTAGRAM',
  }

  const options: DateTimeOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3, // Milliseconds
  }

  const getSocialData = async () => {
    setLoading(true)
    try {
      const data = await getSocialMediaData(getWithAccessToken)

      if (!(data && data.results)) return
      const tiktokDataArray: Attribute[] = data.results.filter(
        (item: Attribute) =>
          item.type === PlatformList.Tiktok && item.account_id,
      )
      const instagramDataArray: Attribute[] = data.results.filter(
        (item: Attribute) =>
          item.type === PlatformList.Instagram && item.account_id,
      )
      setTiktokData(tiktokDataArray)
      setInstagramData(instagramDataArray)
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      toast.error(e?.message)
    }
  }
  const getPlatforms = async () => {
    try {
      const data = await getSupportedPlatforms(getWithAccessToken)
      if (!(data && data.results)) return
      setLoading(false)
      const tiktokIdData: Platforms[] = data.results.filter(
        (item: Platforms) => item.name === 'Tiktok',
      )
      const instagramIdData: Platforms[] = data.results.filter(
        (item: Platforms) => item.name === 'Instagram',
      )
      // Set the state variables with the fetched data
      setTiktokId(tiktokIdData[0].phyllo_id)
      setInstagramId(instagramIdData[0].phyllo_id)
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      toast.error(e?.message)
    }
  }

  const getSdkToken = async () => {
    try {
      const data = await getPyhlloSdkToken(getWithAccessToken)
      if (!(data && data.result)) return
      setLoading(false)
      setSdkConfig(data.result)
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      toast.error(e?.message)
    }
  }

  const createHelikaEvent = async (
    subType: string,
    workPlatformId: string,
    workPlatformName: string,
    platformUsername: string,
    platformAccountId: string,
  ) => {
    if (!_.isEmpty(sdk)) {
      await sdk.createUserEvent([
        {
          game_id: 'helika_portal',
          event_type: 'integration_event',
          event: {
            event_sub_type: subType,
            work_platform_id: workPlatformId,
            work_platform_name: workPlatformName,
            platform_username: platformUsername,
            platform_account_id: platformAccountId,
            user_identifier: userData?.id,
            user_email: userData?.email,
            user_org_identifier: userData?.organization_id,
            user_org_name: userData?.organization_name,
          },
        },
      ])
    }
  }

  useEffect(() => {
    if (isLoading || !isAuthenticated) return
    setLoading(true)
    getSocialData()
    getPlatforms()
    getSdkToken()
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    setInstagramDisplay(instagramData && instagramData.length > 0)
    setTiktokDisplay(tiktokData && tiktokData.length > 0)
  }, [tiktokData, instagramData])

  const formatDateTime = (
    dateTimeString: string,
    options: DateTimeOptions,
  ): string => {
    const { fractionalSecondDigits, ...dateTimeOptions } = options
    return new Date(dateTimeString).toLocaleString(undefined, dateTimeOptions)
  }

  const removeAccount = async (platform: string, id?: number) => {
    setLoading(true)
    try {
      await deleteSocialSync(deleteWithAccessToken, id)
      toast.success(`${platform} account removed successfully`)
      setLoading(false)
      await getSocialData()
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      toast.error(`Failed to remove ${platform} account: ${e.message}`)
    }
  }

  const handleSubmitInstagram = async (platformId: string, id?: number) => {
    if (instagramData?.[0]?.updated_at) {
      await removeAccount('Instagram', id)
      createHelikaEvent(
        'account_disconnected',
        platformId,
        'INSTAGRAM',
        instagramData?.[0]?.platform_username,
        instagramData?.[0]?.account_id,
      )
    } else {
      PhylloSDK(
        platformId,
        sdkConfig,
        postWithAccessToken,
        getSocialData,
        createHelikaEvent,
      )
      setLoading(false)
    }
  }

  const handleSubmitTiktok = async (platformId: string, id?: number) => {
    if (tiktokData?.[0]?.updated_at) {
      await removeAccount('TikTok', id)
      createHelikaEvent(
        'account_disconnected',
        platformId,
        'TIKTOK',
        tiktokData?.[0]?.platform_username,
        tiktokData?.[0]?.account_id,
      )
    } else {
      PhylloSDK(
        platformId,
        sdkConfig,
        postWithAccessToken,
        getSocialData,
        createHelikaEvent,
      )
      setLoading(false)
    }
  }

  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <AccordionDetails style={{ textAlign: 'left' }}>
        <Typography style={{ marginBottom: '15px' }}>Instagram</Typography>
        {instagramDisplay && (
          <StyledTitle>
            {'@' +
              instagramData[0]?.platform_username +
              ' authorized on ' +
              formatDateTime(instagramData[0]?.updated_at, options) +
              ' by ' +
              instagramData[0]?.created_by_email}
          </StyledTitle>
        )}
        <Button
          className="helikaButtonClass"
          disabled={loading}
          onClick={() =>
            handleSubmitInstagram(instagramId, instagramData[0]?.id)
          }
        >
          {loading && (
            <img
              width="14px"
              style={{ marginRight: '2px' }}
              className="animate-spin"
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>{`${instagramData && instagramData[0]?.updated_at ? 'Remove' : 'Link Account'}`}</StyledTxt>
        </Button>
      </AccordionDetails>

      <AccordionDetails style={{ textAlign: 'left' }}>
        <Typography style={{ marginBottom: '15px' }}>Tiktok</Typography>
        {tiktokDisplay && (
          <StyledTitle>
            {'@' +
              tiktokData[0]?.platform_username +
              ' authorized on ' +
              formatDateTime(tiktokData[0]?.updated_at, options) +
              ' by ' +
              tiktokData[0]?.created_by_email}
          </StyledTitle>
        )}
        <Button
          className="helikaButtonClass"
          disabled={loading}
          onClick={() => handleSubmitTiktok(tiktokId, tiktokData[0]?.id)}
        >
          {loading && (
            <img
              width="14px"
              style={{ marginRight: '2px' }}
              className="animate-spin"
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>{`${tiktokData && tiktokData[0]?.updated_at ? 'Remove' : 'Link Account'}`}</StyledTxt>
        </Button>
      </AccordionDetails>
    </div>
  )
}
const StyledTxt = muiStyled(Typography)(() => ({
  color: 'white',
  fontSize: '0.875rem',
  padding: '3px',
}))

const StyledTitle = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  fontSize: '0.875rem',
  paddingBottom: '10px',
}))
