//module imports
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

//state
import { actionCreators } from 'src/state'
import { DOMAIN_TYPES } from 'src/state/action-types'

//services
import { useAuth0 } from '@auth0/auth0-react'

//components
import { DefaultDrawer } from './domainDrawers/DefaultDrawer'
import { BaseDrawer } from './BaseDrawer'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { getUserData } from 'src/utils/api/queries'
import { useColorMode } from 'src/App'

export const AuthenticatedSidedrawer = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()
  const { getTokenIfNecessary, getWithAccessToken, logoutAuth0 } =
    useContext(Auth0Context)
  const sdk = useSelector((state: any) => state.sdk)
  const userData = useSelector((state: any) => state.user)
  const domain = useSelector((state: any) => state.domain.DOMAIN)
  const dispatch = useDispatch()
  const { setUserData, setDomain, setNotifications } = bindActionCreators(
    actionCreators,
    dispatch,
  )
  const { toggleColorMode } = useColorMode()

  //get and store User Data and tabs from db
  useEffect(() => {
    async function getData() {
      try {
        let user_data = await getUserData(getTokenIfNecessary, logoutAuth0)
        if (!user_data) {
          return
        }
        setUserData({
          type: 'SET_USER_DATA',
          payload: user_data,
        })
        if (sdk) {
          sdk?.setUserDetails({
            user_id: user_data.id,
            email: user_data.email,
          })
        }
        toggleColorMode(
          user_data.attributes?.dark_theme === false ? 'light' : 'dark',
        )

        let params = {
          page_size: 10,
          page: 1,
        }
        let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/${user_data.id}`
        let response = await getWithAccessToken(url, params)
        const { results } = response
        if (results?.count > 0) {
          if (results?.notifications && !_.isEmpty(results?.notifications)) {
            setNotifications({
              type: 'SET_NOTIFICATIONS_DATA',
              payload: results?.notifications,
            })
          }
        }
      } catch (e: any) {
        toast.error(e?.message)
        console.log(e)
      }
    }

    if (isLoading || !isAuthenticated || !_.isEmpty(userData)) return
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated, isLoading, userData])

  //change domain based on url
  useEffect(() => {
    function changeDomainBasedOnURL() {
      if (
        window.location.pathname.startsWith('/analytics') &&
        domain !== DOMAIN_TYPES.ANALYTICS
      ) {
        setDomain({
          type: 'SET_DOMAIN',
          payload: DOMAIN_TYPES.ANALYTICS,
        })
      } else if (
        window.location.pathname.startsWith('/game_management') &&
        domain !== DOMAIN_TYPES.GAME_MANAGEMENT
      ) {
        setDomain({
          type: 'SET_DOMAIN',
          payload: DOMAIN_TYPES.GAME_MANAGEMENT,
        })
      } else if (
        window.location.pathname.startsWith('/support') &&
        domain !== DOMAIN_TYPES.SUPPORT
      ) {
        setDomain({
          type: 'SET_DOMAIN',
          payload: DOMAIN_TYPES.SUPPORT,
        })
      } else if (
        window.location.pathname.startsWith('/account_management') &&
        domain !== DOMAIN_TYPES.ACCOUNT_MANAGEMENT
      ) {
        setDomain({
          type: 'SET_DOMAIN',
          payload: DOMAIN_TYPES.ACCOUNT_MANAGEMENT,
        })
      }
    }

    changeDomainBasedOnURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, setDomain, window.location.pathname])

  switch (domain) {
    case DOMAIN_TYPES.GAME_MANAGEMENT:
    case DOMAIN_TYPES.ANALYTICS:
    case DOMAIN_TYPES.SUPPORT:
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
      return <BaseDrawer />
    default:
      return <DefaultDrawer />
  }
}
