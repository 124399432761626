import React, { ChangeEvent } from 'react'
import { TextField } from '@mui/material'
import styled from 'styled-components'
import { newColor } from 'src/consts/colors'

const StyledTextField = styled(TextField)`
  && {
    .MuiInputBase-root {
      background-color: #0d0d0d;
      color: white;
      border-radius: 10px;
      border: 1px solid ${newColor.tertiary};
      width: 54px;
      height: 33px;
    }
    .MuiInputBase-input {
      text-align: right;
      padding: 5px 10px;
      font-size: 15px;
      font-weight: 500;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`

export const NumericInput = ({
  value,
  onChange,
}: {
  value: number
  onChange?: (value: number) => void
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(Number(event.target.value))
  }

  return (
    <StyledTextField
      variant="outlined"
      value={value}
      onChange={handleChange}
      inputProps={{
        maxLength: 3,
        pattern: '[0-9]*',
        inputMode: 'numeric',
      }}
    />
  )
}

export default NumericInput
