import React, { FC, useState, useEffect, useContext } from 'react'
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
  IconButton,
  useTheme,
} from '@mui/material'
import {
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from '@mui/icons-material'
import { styled } from '@mui/system'
import { postNewCampaign, updateCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import ReusableSnackbar from './ReusableSnackbar'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { newColor, blackAndWhite } from 'src/consts/colors'

interface CampaignModalProps {
  open: boolean
  onClose: (refreshTable: boolean) => void
  isEdit: boolean
  campaignData?: {
    id: number
    name?: string
    startDate?: string
    endDate?: string
    status?: string
  }
  setTableLoading: (loading: boolean) => void
}

// Custom spinner styling
const Spinner = styled('div')<{ size?: number }>(({ size = 24 }) => ({
  width: size,
  height: size,
  border: `${size / 8}px solid rgba(255, 255, 255, 0.3)`,
  borderTop: `${size / 8}px solid white`,
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}))

// Define StatusDot for the color indicator
const StatusDot = styled('div')<{ color: string }>(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '6px',
}))

// Define status colors for each campaign status
const statusColors: { [key: string]: string } = {
  not_started: newColor.oceanBlue,
  active: newColor.green,
  finished: newColor.electricPurple,
  archived: newColor.jet,
}

// Define readable status labels for each campaign status
const statusLabels: { [key: string]: string } = {
  not_started: 'Not Started',
  active: 'Active',
  finished: 'Finished',
  archived: 'Archived',
}

const CampaignModal: FC<CampaignModalProps> = ({
  open,
  onClose,
  isEdit,
  campaignData,
  setTableLoading,
}) => {
  const { postWithAccessToken, putWithAccessToken } = useContext(Auth0Context)
  const theme = useTheme()
  const themeMode = theme.palette.mode

  const [name, setName] = useState<string>(campaignData?.name || '')
  const [startDate, setStartDate] = useState<string>(
    campaignData?.startDate || '',
  )
  const [endDate, setEndDate] = useState<string>(campaignData?.endDate || '')
  const [status, setStatus] = useState<string>(
    campaignData?.status || 'not_started',
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [nameError, setNameError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  useEffect(() => {
    if (isEdit && campaignData) {
      setName(campaignData.name || '')
      setStartDate(campaignData.startDate || '')
      setEndDate(campaignData.endDate || '')
      setStatus(campaignData.status || 'not_started')
    } else if (!isEdit) {
      resetForm()
    }
  }, [campaignData, isEdit])

  const resetForm = () => {
    setName('')
    setStartDate('')
    setEndDate('')
    setStatus('not_started')
    setNameError(false)
    setStartDateError(false)
    setEndDateError(false)
    setErrorMessage('')
  }

  const nameRegex = /^[A-Za-z0-9\s\-()[\]]+$/

  const validateForm = () => {
    const isNameValid = !!name.trim() && nameRegex.test(name)
    const isStartDateValid = !!startDate && dayjs(startDate).isValid()
    const isEndDateValid = !!endDate && dayjs(endDate).isValid()
    const isDateOrderValid =
      !startDate || !endDate || dayjs(startDate).isBefore(dayjs(endDate))

    setNameError(!isNameValid)
    setStartDateError(!isStartDateValid)
    setEndDateError(!isEndDateValid || !isDateOrderValid)

    if (!isDateOrderValid) {
      setErrorMessage('End date cannot be before the start date.')
    } else if (!isNameValid || !isStartDateValid || !isEndDateValid) {
      setErrorMessage('Fill out missing campaign details.')
    } else {
      setErrorMessage('')
    }

    return isNameValid && isStartDateValid && isEndDateValid && isDateOrderValid
  }

  const handleSave = () => {
    if (validateForm()) {
      const formattedStartDate = dayjs(startDate).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ',
      )
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')

      if (isEdit) {
        updateExistingCampaign(formattedStartDate, formattedEndDate)
      } else {
        postCampaign(formattedStartDate, formattedEndDate)
      }
    }
  }

  const postCampaign = async (
    formattedStartDate: string,
    formattedEndDate: string,
  ) => {
    const campaignPayload = {
      name,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      status,
    }

    try {
      setIsSaving(true)
      setTableLoading(true)
      await postNewCampaign(postWithAccessToken, campaignPayload)
      setTableLoading(false)
      setSnackbarMessage('Campaign created successfully!')
      setSnackbarVariant('success')
      setSnackbarOpen(true)
      handleModalClose(true)
    } catch (e: any) {
      const errorMessage =
        e?.message || 'An unexpected error occurred. Please try again.'
      setSnackbarMessage(errorMessage)
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setIsSaving(false)
      setTableLoading(false)
    }
  }

  const updateExistingCampaign = async (
    formattedStartDate: string,
    formattedEndDate: string,
  ) => {
    if (!campaignData || !campaignData.id) {
      setSnackbarMessage('Invalid campaign data. Cannot update.')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      return
    }

    const campaignPayload = {
      id: campaignData.id,
      name,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      status,
    }

    try {
      setIsSaving(true)
      setTableLoading(true)
      await updateCampaign(putWithAccessToken, campaignData.id, campaignPayload)
      setTableLoading(false)
      setSnackbarMessage('Campaign updated successfully!')
      setSnackbarVariant('success')
      setSnackbarOpen(true)
      handleModalClose(true)
    } catch (e: any) {
      setSnackbarMessage(e?.message || 'Failed to update campaign')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setIsSaving(false)
      setTableLoading(false)
    }
  }

  const handleModalClose = (success = false) => {
    resetForm()
    setIsSaving(false)
    onClose(success)
  }

  const handleFieldChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    value: string,
  ) => {
    if (setter === setStartDate || setter === setEndDate) {
      const isValidDate = dayjs(value).isValid()
      setError(!isValidDate)
      if (isValidDate) {
        setter(value)
      }
    } else {
      if (nameRegex.test(value) || value === '') {
        setter(value)
        if (value) {
          setError(false)
        }
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={() => handleModalClose(false)}
        scroll="paper"
        PaperProps={{
          sx: {
            width: '420px',
            maxHeight: '90vh',
            color: themeMode === 'dark' ? newColor.white : newColor.black,
            background:
              themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
            borderRadius: '8px',
            filter: 'drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.3))',
          },
        }}
      >
        {/* Modal header with title and close icon */}
        <Box
          sx={{
            padding: '16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background:
              themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
            borderRadius: '8px 8px 0 0',
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '20px',
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            {isEdit ? 'Edit Campaign' : 'Create Campaign'}
          </Typography>
          <IconButton
            onClick={() => handleModalClose(false)}
            sx={{
              color: themeMode === 'dark' ? newColor.white : newColor.black,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            padding: '24px',
            background:
              themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
          }}
        >
          {/* Error message display */}
          {(nameError || startDateError || endDateError) && (
            <Box
              sx={{
                marginBottom: 2,
                background:
                  themeMode === 'dark'
                    ? 'rgba(199, 58, 58, 0.1)'
                    : 'rgba(199, 58, 58, 0.2)',
                borderLeft: `3px solid ${newColor.error}`,
                borderRadius: '4px',
                padding: '12px 16px',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorIcon sx={{ color: newColor.error }} />
                <Typography
                  sx={{
                    color:
                      themeMode === 'dark' ? newColor.white : newColor.black,
                    fontFamily: 'Open Sans, sans-serif',
                  }}
                >
                  {errorMessage}
                </Typography>
              </Stack>
            </Box>
          )}

          {/* Form fields */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ marginBottom: '20px', paddingTop: '0px !important' }}
            >
              <Typography
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  fontFamily: 'Open Sans, sans-serif',
                  paddingBottom: '4px',
                }}
              >
                Name
              </Typography>
              <TextField
                value={name}
                onChange={(e) =>
                  handleFieldChange(setName, setNameError, e.target.value)
                }
                fullWidth
                placeholder="Campaign name"
                InputProps={{
                  sx: {
                    paddingRight: '12px',
                    height: '36px',
                    backgroundColor:
                      themeMode === 'dark'
                        ? newColor.gunmetalGray
                        : newColor.lightGray,
                    border: `1px solid ${
                      themeMode === 'dark'
                        ? blackAndWhite.grey600
                        : blackAndWhite.grey300
                    }`,
                    borderRadius: '4px',
                  },
                }}
                error={Boolean(nameError)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ marginBottom: '20px', paddingTop: '0px !important' }}
            >
              <Typography
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  fontFamily: 'Open Sans, sans-serif',
                  paddingBottom: '4px',
                }}
              >
                Start Date
              </Typography>
              <DateTimePicker
                value={dayjs(startDate)}
                onChange={(date) =>
                  handleFieldChange(
                    setStartDate,
                    setStartDateError,
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  )
                }
                format="YYYY-MM-DD hh:mm A"
                ampm
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      sx: {
                        paddingRight: '12px',
                        height: '36px',
                        backgroundColor:
                          themeMode === 'dark'
                            ? newColor.gunmetalGray
                            : newColor.lightGray,
                        border: `1px solid ${
                          themeMode === 'dark'
                            ? blackAndWhite.grey600
                            : blackAndWhite.grey300
                        }`,
                        borderRadius: '4px',
                      },
                    },
                    error: Boolean(startDateError),
                  },
                  popper: {
                    sx: {
                      '& .MuiPickersDay-root': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiPickersYear-yearButton': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiMenuItem-root': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiClock-amButton, & .MuiClock-pmButton': {
                        backgroundColor: newColor.jazzberryJam,
                        color: newColor.white,
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: newColor.jazzberryJam,
                        },
                      },
                      '& .MuiPickersClock-pointer': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ marginBottom: '20px', paddingTop: '0px !important' }}
            >
              <Typography
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  fontFamily: 'Open Sans, sans-serif',
                  paddingBottom: '4px',
                }}
              >
                End Date
              </Typography>
              <DateTimePicker
                minDate={dayjs(startDate)}
                componentsProps={{
                  popper: {
                    placement: 'top',
                  },
                }}
                views={['year', 'month', 'day', 'hours', 'minutes']}
                value={dayjs(endDate)}
                onChange={(date) =>
                  handleFieldChange(
                    setEndDate,
                    setEndDateError,
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  )
                }
                format="YYYY-MM-DD hh:mm A"
                ampm
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      sx: {
                        paddingRight: '12px',
                        height: '36px',
                        backgroundColor:
                          themeMode === 'dark'
                            ? newColor.gunmetalGray
                            : newColor.lightGray,
                        border: `1px solid ${
                          themeMode === 'dark'
                            ? blackAndWhite.grey600
                            : blackAndWhite.grey300
                        }`,
                        borderRadius: '4px',
                      },
                    },
                    error: Boolean(endDateError),
                  },
                  popper: {
                    sx: {
                      '& .MuiPickersDay-root': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiPickersYear-yearButton': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiMenuItem-root': {
                        '&.Mui-selected': {
                          backgroundColor: newColor.jazzberryJam,
                          color: newColor.white,
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: newColor.jazzberryJam,
                          },
                        },
                      },
                      '& .MuiClock-amButton, & .MuiClock-pmButton': {
                        backgroundColor: newColor.jazzberryJam,
                        color: newColor.white,
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: newColor.jazzberryJam,
                        },
                      },
                      '& .MuiPickersClock-pointer': {
                        backgroundColor: newColor.jazzberryJam,
                      },
                    },
                  },
                }}
              />
            </Grid>

            {/* Status dropdown */}
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
              <Typography
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                  fontFamily: 'Open Sans, sans-serif',
                  paddingBottom: '4px',
                }}
              >
                Status
              </Typography>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value as string)}
                fullWidth
                sx={{
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px',
                  height: '36px',
                  backgroundColor:
                    themeMode === 'dark' ? '#1C2025' : newColor.whiteSmoke,
                  border: '1px solid #434D5A',
                  borderRadius: '4px',
                  paddingLeft: '8px',
                  '& fieldset': {
                    border: 'none',
                  },
                  '.MuiSelect-select': {
                    paddingLeft: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                disabled={isSaving}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiList-root': {
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      },
                    },
                  },
                }}
              >
                {Object.keys(statusColors).map((statusKey) => (
                  <MenuItem key={statusKey} value={statusKey}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <StatusDot color={statusColors[statusKey]} />
                      <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                        {statusLabels[statusKey]}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>

        {/* Action buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '16px 24px',
            background:
              themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Button
            onClick={() => handleModalClose(false)}
            sx={{
              minWidth: '110px',
              height: '36px',
              color: themeMode === 'dark' ? newColor.white : newColor.black,
              backgroundColor: 'transparent',
              textTransform: 'none',
              border: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              marginRight: '8px',
            }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              minWidth: '110px',
              height: '36px',
              backgroundColor: newColor.jazzberryJam,
              color: newColor.white,
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textTransform: 'none',
            }}
            disabled={isSaving}
          >
            {isSaving ? (
              <Spinner size={20} />
            ) : (
              <Typography
                sx={{
                  color: 'var(--Color-Text-Inverse, #FFF)',
                  textAlign: 'center',
                  fontFamily: 'Open Sans',
                  fontSize: 'var(--Typography-Size-Label-Regular, 14px)',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '140%',
                  textTransform: 'none',
                }}
              >
                {isEdit ? 'Save' : 'Create'}
              </Typography>
            )}
          </Button>
        </Box>
      </Dialog>

      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </LocalizationProvider>
  )
}

export default CampaignModal
