//module imports
import React from 'react'
import { UnauthenticatedSidedrawer } from './UnauthenticatedSidedrawer'
import { AuthenticatedSidedrawer } from './AuthenticatedSidedrawer'
import { Route, Routes } from 'react-router-dom'

//state

//services

//style

//components
//assets

export const SideDrawer = () => {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div />
        }
      />
      <Route
        path="/game_demo"
        element={
          <div />
        }
      />
      <Route
        path="/email-unverified"
        element={
          <UnauthenticatedSidedrawer />
        }
      />
      <Route
        path="*"
        element={
          <AuthenticatedSidedrawer />
        }
      />
    </Routes>
  );
}
