// use-code-editor.ts
import { useEffect } from "react";
import onUpdate from "./on-update";
import useCodeMirror from "./use-codemirror";

export function useCodeEditor({ value, onChange, extensions }: { value: any, onChange: any, extensions: any }) {
  const { ref, view, keyAction } = useCodeMirror([onUpdate(onChange), ...extensions]);

  useEffect(() => {
    if (view) {
      const editorValue = view.state.doc.toString();

      if (value !== editorValue) {
        view.dispatch({
          changes: {
            from: 0,
            to: editorValue.length,
            insert: value || "",
          },
        });
      }
    }
  }, [value, view]);

  return { ref, view, keyAction };
}
