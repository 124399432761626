import { applyMiddleware, createStore, compose } from 'redux'
import reducers from '../reducers'
import thunk from 'redux-thunk'


// Adding react-redux chrome extension support for debuggin in local

let enhancer;
let composeFunction = compose(applyMiddleware(thunk))
if (process.env.REACT_APP_DEV_MODE !== 'PROD') {
  enhancer = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({})
      : (x: any) => x,
  );
  composeFunction = compose(
    applyMiddleware(thunk),
    enhancer,
  )
}

export const store = createStore(reducers, {}, composeFunction);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
