import React, { FC } from 'react'
import { Stack, Table, TableBody, TableRow, Typography } from '@mui/material'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query'
import LoadingComponent from 'src/components/LoadingComponent'
import HookItem from './HookItem'
import { Pagination } from '../Projects.styled'
// types
import { IHook } from 'src/types/engage'
// assets
import { newColor } from 'src/consts/colors'

export type DraggableTableProps = {
  hooks: IHook[]
  onChangeHooks: (hooks: IHook[]) => void
  loading: boolean
  pagination: {
    page: number
    limit: number
    count: number
  }
  onChangePagination: (pagination: { page: number; limit: number }) => void
  goToEditHook: (hook: IHook) => void
  fetchHooks: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<any, Error>>
}

const DraggableTable: FC<DraggableTableProps> = ({
  hooks,
  fetchHooks,
  onChangeHooks,
  loading,
  pagination,
  onChangePagination,
  goToEditHook,
}) => {
  const handleDragEnd = (result: DropResult): void => {
    if (!result.destination) return
    const tempData = Array.from(hooks)
    const [sourceData] = tempData.splice(result.source.index, 1)
    tempData.splice(result.destination.index, 0, sourceData)
    onChangeHooks(tempData)
  }

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    onChangePagination({ ...pagination, page: value })
  }

  return (
    <>
      {loading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: '300px',
          }}
        >
          <LoadingComponent />
        </Stack>
      ) : (
        <Stack
          direction="column"
          gap={2}
          sx={{ minWidth: '600px', overflowX: 'auto' }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: 'separate',
                borderSpacing: '0 10px',
              }}
              aria-label="hooks list table"
            >
              <Droppable droppableId="droppable-1">
                {(provided) => (
                  <TableBody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {hooks.map((hook, index) => (
                      <Draggable
                        key={hook.id}
                        draggableId={hook.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            key={hook.id}
                            sx={{
                              '& td, th': {
                                border: `1px solid ${newColor.darkGunmetal}`,
                                borderStyle: 'solid none',
                                background: newColor.charcoal,
                              },
                              '& th:first-of-type': {
                                borderLeftStyle: 'solid',
                                borderTopLeftRadius: '10px',
                                borderBottomLeftRadius: '10px',
                              },
                              '& td:last-child': {
                                borderRightStyle: 'solid',
                                borderTopRightRadius: '10px',
                                borderBottomRightRadius: '10px',
                              },
                              '& th': {
                                pl: '24px',
                                pr: '8px',
                                width: '38px',
                              },
                            }}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <HookItem
                              provided={provided}
                              hook={hook}
                              fetchHooks={fetchHooks}
                              onEditHook={goToEditHook}
                            />
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>

          {!pagination.count && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%' }}
            >
              <Typography>No Hooks</Typography>
            </Stack>
          )}

          {pagination.count > pagination.limit && (
            <Stack alignItems="center" sx={{ width: '100%' }}>
              <Pagination
                shape="rounded"
                count={Math.ceil(pagination.count / pagination.limit)}
                page={pagination.page}
                onChange={handleChangePagination}
              />
            </Stack>
          )}
        </Stack>
      )}
    </>
  )
}

export default DraggableTable
