import React from "react";
import { toast } from "react-toastify";
import * as ReactTooltip from 'react-tooltip';
import { AiFillCloseCircle } from "react-icons/ai";
import { Box, useTheme } from "@mui/material";
import { Colors } from "helika-ui-sdk";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { spacing } from "helika-ui-sdk/dist/src/values/Spacing";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import { newColor } from "src/consts/colors";


export const MAX_FILE_SIZE = 5000000
export const formatImageUrl = (id: string | null | undefined) => {
    if (!id) {
        return ""; // invalid url
    }
    return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` + id;
}

export default function UploadImageComponent({
    image,//the serch term to be used to filter assets,etc
    setImage,//function to change the search term
    storedImageId,
    setStoredImageId,
    disabled = false,
    hasClearButton = false,
}: {
    image: any,
    storedImageId?: string,
    setImage: React.Dispatch<React.SetStateAction<any>>,
    setStoredImageId?: React.Dispatch<React.SetStateAction<any>>,
    disabled?: boolean,
    hasClearButton?: boolean,
}) {

    const theme = useTheme()
    const hiddenFileInputDisplay = React.useRef<HTMLInputElement>(null);
    // const [storedImage, setStoredImage] = useState<any>();

    const handleClickDisplay = () => {
        if (disabled) return
        if (hiddenFileInputDisplay.current !== null) {
            hiddenFileInputDisplay.current.click();
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <ReactTooltip.Tooltip />
            <span className="hidden">
                <input
                    ref={hiddenFileInputDisplay}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            if (e.target.files[0].size > MAX_FILE_SIZE) {
                                toast.error('Image must be no greater than 5mb.');
                                return;
                            }
                            setImage(e.target.files[0]);
                            if (setStoredImageId) setStoredImageId(undefined);
                        }
                    }}
                    accept='.png,.jpg,.jpeg'
                />
            </span>
            {
                (image || storedImageId)
                    ?
                    <Box
                        sx={{
                            height: '84px',
                            width: '84px',
                            background: '#1C2025',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        {
                            hasClearButton && (image || storedImageId) && !disabled &&
                            <div
                                style={{
                                    display: 'flex',
                                    zIndex: 50,
                                    position: 'relative',
                                }}
                                className="flex flex-row justify-end translate-y-full"
                            >
                                <AiFillCloseCircle
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setImage(undefined)
                                        if (setStoredImageId) setStoredImageId(undefined)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto',
                                        color: 'red'
                                    }}
                                    size={'2em'}
                                />
                            </div>
                        }
                        <div
                            onClick={() => handleClickDisplay()}
                            style={{
                                height: '84px',
                                width: '84px',
                                background: Colors.background.tertiary,
                                borderRadius: '5px',
                                marginTop: (hasClearButton && (image || storedImageId) && !disabled) ? '-2em' : 0,
                                justifyContent: 'center',
                                zIndex: 10,
                                position: 'relative'
                            }}
                        >
                            <img
                                src={(image && image.size !== 0) ? URL.createObjectURL(image) : `${formatImageUrl(storedImageId)}`}
                                style={{
                                    borderRadius: '5px',
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',//contain
                                }}
                                alt='uploaded_image'
                            />
                        </div>
                    </Box>
                    :
                    <Box
                        onClick={handleClickDisplay}
                        style={{
                            height: '84px',
                            width: '84px',
                            background: theme.palette.mode === 'dark' ? newColor.gunmetalGray : color_base.white,
                            borderRadius: '5px',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <AddPhotoAlternateIcon
                            sx={{
                                margin: 'auto',
                                height: '24px',
                                width: '24px',
                                'svg': {
                                    height: '24px',
                                    width: '24px',
                                }
                            }}
                            fontSize="inherit"
                        />
                    </Box>
            }
            {
                (image || storedImageId)
                    ?
                    <Box
                        sx={{
                            height: '32px',
                            width: '32px',
                            margin: `auto auto auto ${spacing.Spacing_sm}`,
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setImage(undefined)
                            if (setStoredImageId) setStoredImageId(undefined)
                        }}
                    >
                        <DeleteOutlineIcon
                            sx={{
                                fontSize: '24px',
                                height: '24px',
                                width: '24px'
                            }}
                        />
                    </Box>
                    :
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'center',
                            padding: '1em',
                            color: theme.palette.mode === 'dark' ? colors_semantic.text_secondary : color_base.grey500,
                            fontSize: '12px',
                            lineHeight: '140%'
                        }}
                    >
                        <Box>Recommended size:</Box>
                        <Box>480x480</Box>
                    </Box>
            }
        </div>
    );
}