import React, { useState } from 'react'
import { Stack, useTheme } from '@mui/material'
import { newColor } from 'src/consts/colors'
import { RoundGradientWrapper } from './organisms/v2/sideDrawer/drawerLogo/DrawerLogo.styled'
// assets
import HelikaIconButton from 'src/assets/helikaIconButton.svg'
import HelikaIconButtonHover from 'src/assets/helikaIconButtonHover.svg'
import HelikaTextLogo from 'src/assets/text_logo.svg'

export interface LogoProps {
  onClick: () => void
}

const Logo: React.FC<LogoProps> = ({ onClick }) => {
    const theme = useTheme()
    const [isHovered, setIsHovered] = useState<boolean>(false)

    return (
        <RoundGradientWrapper>
            <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
                backgroundColor:
                theme.palette.mode === 'dark'
                    ? newColor.midnight
                    : newColor.cultured,
                p: '6px 10px',
                borderRadius: '50px',
            }}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            {isHovered ? (
                <img src={HelikaIconButtonHover} height="26px" alt="" />
            ) : (
                <img src={HelikaIconButton} height="26px" alt="" />
            )}
            <img src={HelikaTextLogo} height="12px" alt="" />
            </Stack>
        </RoundGradientWrapper>
    )
}

export default Logo;
