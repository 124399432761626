import React, {
  useCallback,
  useState,
  MouseEvent,
  useEffect,
  useContext,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
// MUI
import { Stack, Typography, useTheme, useMediaQuery } from '@mui/material'
// project-import
import { HEADER } from 'src/consts/sizes'
import { canAccessOnboardingOrAdminPanels, getTeamName } from 'src/utils/user'
import { useGetDrawer } from 'src/utils/drawer'
import { HelpIcon } from '../sideDrawer/HelpIcon'
import { AdminPanelIcon } from '../sideDrawer/AdminPanelIcon'
import Notification from './Notification'
import Logo from 'src/components/Logo'
import { Auth0Context } from 'src/contexts/Auth0Context'

// style
import { newColor } from 'src/consts/colors'
// assets
import profileIconHover from 'src/assets/profile_icon_hover.svg'
import AnnouncementModal from 'src/components/popups/AnnouncementModal'

const Header: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const downSM = useMediaQuery(theme.breakpoints.down('sm'))
  const isDrawerOpen = useGetDrawer()
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const user = useSelector((state: any) => state.user)

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [showAnnouncementModal, setShowAnnouncementModal] =
    useState<boolean>(false)
  const displayName = user.name ? user.name : user.email
  const { isLoading } = useContext(Auth0Context)

  const handleUserProfileClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()

      if (isAuthenticated) {
        navigate('/profile')
      } else {
        loginWithRedirect()
      }
    },
    [navigate, isAuthenticated, loginWithRedirect],
  )

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const expirationDuration = 1000 * 60 * 60 * 24 // 24 hours
      const prevVisited = Number(localStorage.getItem('visited'))
      const currentTime = new Date().getTime()
      const notVisited = prevVisited == undefined
      const prevVisitedExpired =
        prevVisited != undefined &&
        currentTime - prevVisited > expirationDuration
      if (notVisited || prevVisitedExpired) {
        setShowAnnouncementModal(true)
        localStorage.setItem('visited', String(currentTime))
      }
    }
  }, [isLoading, isAuthenticated])

  const renderAvatar = useCallback(
    (src: string) => (
      <img
        style={{
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          marginRight: '0.5em',
        }}
        src={src}
        alt=""
      />
    ),
    [user, isHovered],
  )

  return (
    <>
      <AnnouncementModal
        open={showAnnouncementModal}
        setOpen={setShowAnnouncementModal}
      />
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: `${HEADER.height}`,
          width: '100%',
          background:
            theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
          px: downSM ? 2 : 4,
          cursor: 'pointer',
          zIndex: 1299,
          position: 'fixed',
        }}
      >
        {/* helika logo */}
        <Stack direction="row" alignItems="center">
          <Logo onClick={() => setShowAnnouncementModal(true)} />
        </Stack>

        <Stack direction="row" alignItems="center" gap={2}>
          {/* right panel*/}
          <Stack direction="row" alignItems="center" gap={0.5}>
            {!(isDrawerOpen && downSM) && (
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                sx={{
                  '& a': {
                    display: 'flex',
                    alighItems: 'center',
                    justifyContent: 'center',
                  },
                }}
              >
                <HelpIcon />
                {canAccessOnboardingOrAdminPanels(user) && <AdminPanelIcon />}
              </Stack>
            )}
            <Notification />
          </Stack>

          {/* user profile panel*/}
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            onClick={handleUserProfileClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {user?.avatar
              ? renderAvatar(
                  `https://${process.env.REACT_APP_AMAZON_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/${user?.avatar}`,
                )
              : renderAvatar(profileIconHover)}

            <Stack
              direction="column"
              sx={isDrawerOpen && downSM ? { display: 'none' } : {}}
            >
              <Typography
                id="UserName"
                sx={{
                  maxWidth: '100px',
                  fontSize: '17px',
                }}
              >
                {user?.name || user?.email
                  ? displayName.length > 10
                    ? displayName.slice(0, 10) + '...'
                    : displayName
                  : ''}
              </Typography>
              <Typography
                sx={{
                  fontSize: '11px',
                  color: newColor.dimSlate,
                }}
              >
                {getTeamName(user)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default Header
