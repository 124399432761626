import { Box, styled as muiStyled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import ticket_detail_icon from '../../assets/support_ticket_detail_icon.svg'
import user_detail_icon from '../../assets/support_ticket_user_detail_icon.svg'
import custom_field_icon from '../../assets/support_ticket_custom_fields_icon.svg'
import { Typography, useTheme } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

const StyledBox = muiStyled('div')(({ theme }) => ({
  borderRight: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  maxWidth: '252px',
  padding: '1.5rem 1.75rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '3rem',
  height: '100%',
  overflow: 'auto',
}))

export interface TicketInformationProps {
  ticket: any
}

export default function TicketInformation(props: TicketInformationProps) {
  const { ticket } = props
  const theme = useTheme()
  const [created_at_date, setCreatedAtDate] = useState(
    moment().format('YYYY-MM-DD'),
  )
  const [created_at_time, setCreatedAtTime] = useState(
    moment().format('hh:mm:ss A'),
  )
  const [sla, setSla] = useState('')
  const [userName, setUserName] = useState('Not Provided')
  const [playerId, setUPlayerId] = useState('Not Provided')
  const [walletAddress, setWalletAddress] = useState('Not Provided')
  const [email, setEmail] = useState('Not Provided')
  useEffect(() => {
    if (_.isNil(ticket)) return
    if (ticket?.created_at) {
      const baseMoment = moment(ticket.created_at)
      setCreatedAtDate(baseMoment.format('YYYY-MM-DD'))
      setCreatedAtTime(baseMoment.format('hh:mm:ss A'))
    }
    calculateSLA()
    setUserName(ticket?.data?.user_details?.name || 'Not Provided')
    setUPlayerId(ticket?.data?.user_details?.player_id || 'Not Provided')
    setWalletAddress(ticket?.data?.user_details?.wallet_id || 'Not Provided')
    setEmail(ticket?.data?.user_details?.email || 'Not Provided')
  }, [ticket])

  const calculateSLA = () => {
    const givenDate = new Date(ticket.created_at)
    let currentDate = new Date(ticket.closed_at)
    if (_.isNil(ticket.closed_at)) currentDate = new Date()
    const differenceInMilliseconds = Number(currentDate) - Number(givenDate)
    const differenceInDays = Math.floor(
      differenceInMilliseconds / 1000 / 60 / 60 / 24,
    )
    const differenceInHours = Math.floor(
      (differenceInMilliseconds / 1000 / 60 / 60) % 24,
    )
    const differenceInMinutes = Math.floor(
      (differenceInMilliseconds / 1000 / 60) % 60,
    )

    setSla(
      differenceInDays === 0
        ? ` ${differenceInHours} h ${differenceInMinutes} m`
        : `${differenceInDays} d ${differenceInHours} h ${differenceInMinutes} m`,
    )
  }

  return (
    <StyledBox>
      <Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            maxWidth: '100%',
            height: 'fit-content',
          }}
        >
          <img
            src={ticket_detail_icon}
            alt="Logs Icon"
            style={{ margin: 'auto 0.625em auto 0', height: '13px' }}
          />
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: theme.palette.text.primary,
              margin: 'auto 0 auto 0',
              fontSize: '0.875em',
              fontWeight: 400,
            }}
          >
            TICKET DETAILS
          </Typography>
        </Box>
        <Box
          sx={{
            paddingLeft: '1.5rem',
            paddingTop: '0.875rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '19px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              TYPE:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              Bug
            </Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              SLA:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {sla}
            </Typography>
          </Box> */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              CREATED AT:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {created_at_date + ', ' + created_at_time}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              SESSION ID:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {ticket?.session_id}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            maxWidth: '100%',
            height: 'fit-content',
          }}
        >
          <img
            src={user_detail_icon}
            alt="Logs Icon"
            style={{ margin: 'auto 0.625em auto 0', height: '13px' }}
          />
          <Typography
            className="ellipsisWithWrap"
            sx={{
              color: theme.palette.text.primary,
              margin: 'auto 0 auto 0',
              fontSize: '0.875em',
              fontWeight: 400,
            }}
          >
            USER DETAILS
          </Typography>
        </Box>
        <Box
          sx={{
            paddingLeft: '1.5rem',
            paddingTop: '0.875rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '19px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              NAME:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {userName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              PLAYER ID:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {playerId}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              WALLET ADDRESS:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {walletAddress}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              EMAIL:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              {email}
            </Typography>
          </Box>
        </Box>
      </Box>
      {ticket?.data?.event_details?.custom && (
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{
              maxWidth: '100%',
              height: 'fit-content',
            }}
          >
            <img
              src={custom_field_icon}
              alt="Logs Icon"
              style={{ margin: 'auto 0.625em auto 0', height: '13px' }}
            />
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                margin: 'auto 0 auto 0',
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              CUSTOM FIELDS
            </Typography>
          </Box>
          <Box
            sx={{
              paddingLeft: '1.5rem',
              paddingTop: '0.875rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '19px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                className="ellipsisWithWrap"
                sx={{
                  color: newColor.smokyGray,
                  fontSize: '0.75em',
                  fontWeight: 400,
                }}
              >
                URL:
              </Typography>
              <Typography
                className="ellipsisWithWrap"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '0.875em',
                  fontWeight: 400,
                }}
              >
                {ticket?.data?.event_details?.custom?.url}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography
                className="ellipsisWithWrap"
                sx={{
                  color: newColor.smokyGray,
                  fontSize: '0.75em',
                  fontWeight: 400,
                }}
              >
                PRIORITY:
              </Typography>
              <Typography
                className="ellipsisWithWrap"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '0.875em',
                  fontWeight: 400,
                }}
              >
                {ticket?.data?.event_details?.custom?.priority}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {/*<Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: newColor.smokyGray,
                fontSize: '0.75em',
                fontWeight: 400,
              }}
            >
              CUSTOM 3:
            </Typography>
            <Typography
              className="ellipsisWithWrap"
              sx={{
                color: theme.palette.text.primary,
                fontSize: '0.875em',
                fontWeight: 400,
              }}
            >
              lorem ipsum
            </Typography>
          </Box>
        </Box> */}
    </StyledBox>
  )
}
