import React, { createContext, useContext, useState } from 'react'

const ActionSaveContext = createContext<{
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const useActionSave = () => {
  try {
    const context = useContext(ActionSaveContext)
    if (!context) {
      throw new Error('Context have to be put under ActionSaveProvider')
    }

    return context
  } catch (err) {
    console.log(err)
    return {
      isLoading: true,
      setIsLoading: () => (state: any) => state,
    }
  }
}

export default function ActionSaveProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <ActionSaveContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </ActionSaveContext.Provider>
  )
}
