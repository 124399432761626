const initialState = {
  DOMAIN: 'ANALYTICS'
};

export default function domainReducer(state = initialState, action){
  switch (action.type) {
    case "CLEAR_DATA":
      return initialState;
    case 'SET_DOMAIN':
      return {
        ...state,
        DOMAIN: action.payload
      };
    default:
      return state;
  }
};