const initialState = {};

export default function sdkReducer(state = initialState, action){
  switch (action.type) {
    case "UNSET_SDK":
      return initialState;
    case "SET_SDK":
      return action.payload;
    default:
      return state;
  }
};