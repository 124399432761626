import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'

import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
} from '@mui/material'
import { toast } from 'react-toastify'

import { LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES } from '../../consts/backend'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SocialOrganizationIcon from '../../assets/Social_Organization_Icon.svg'
import SocialIconDark from '../../assets/Social_Organization_Icon_dark.svg'
import loadingSVG from '../../assets/loading.svg'
import _ from 'lodash'
import { getSocialsApi } from '../../utils/api/queries'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
}

export default function AddSocialConnection({
  orgs,
  enableOrgDropdown,
}: {
  orgs: any
  enableOrgDropdown?: boolean
}) {
  const theme = useTheme()
  const { organization_id } = useSelector((state: any) => state.user)
  const {
    isAuthenticated,
    getWithAccessToken,
    postWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [socialId, setSocialId] = useState('')
  const [org, setOrg] = useState<Org | null>(null)
  const [platforms, setPlatforms] = useState<string[]>([])
  const [platform, setPlatform] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!orgs || orgs.length < 1) return

    if (enableOrgDropdown) {
      setOrg({
        label: orgs[0].label,
        value: orgs[0].value,
      })
    } else {
      const userOrg = orgs.find((org: any) => org.value === organization_id)
      setOrg(
        userOrg
          ? {
            label: userOrg.label,
            value: userOrg.value,
          }
          : null,
      )
    }
  }, [orgs, enableOrgDropdown, organization_id])

  const getSocials = useCallback(async () => {
    await getSocialsApi(getWithAccessToken)
      .then((res: any) => {
        if (!res.results || res.results.length === 0) return
        setPlatforms(() => {
          let uniquePlatforms: string[] = []
          res.results.forEach((platform: any) => {
            if (uniquePlatforms.indexOf(platform.platform) === -1) {
              uniquePlatforms.push(platform.platform)
            }
          })
          return uniquePlatforms
        })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken])

  useEffect(() => {
    if (
      isLoading ||
      !isAuthenticated ||
      _.isEmpty(orgs) ||
      !_.isEmpty(platforms)
    )
      return
    getSocials()
  }, [
    orgs.length,
    isLoading,
    isAuthenticated,
    orgs,
    platforms,
    getWithAccessToken,
    getSocials,
  ])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org: Org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
    }
  }

  const onChangeSocialId = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setSocialId(e?.target.value)
    }
  }

  const onChangeSocialPlatform = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setPlatform(e?.target.value)
    }
  }

  async function addSocialConnection() {
    if (socialId && org && platform) {
      setLoading(true)
      try {
        const data = await postWithAccessToken(
          `${process.env.REACT_APP_HELIKA_API_URL}/v1/organization-access/socials/add-conection-to-org`,
          {
            platform: platform,
            platform_id: socialId,
            organization_id: org.value,
          },
        )

        if (data.status === 'ok') {
          toast.success(
            `${socialId} ${platform} was succefully linked to organization ${org.label}`,
          )
          setSocialId('')
        } else if (
          data.status ===
          LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES.SOCIAL_CONNECTION_IS_ALREADY_LINKED_TO_ORG
        ) {
          toast.warning(
            `${LINK_SOCIAL_CONNECTION_TO_ORG_STATUSES.SOCIAL_CONNECTION_IS_ALREADY_LINKED_TO_ORG}`,
          )
        } else {
          toast.info(data.status)
        }
      } catch (error: any) {
        toast.error(error?.message)
      }
    } else {
      toast.info(
        `Please enter collection address and choose chain and organization`,
      )
    }
    setLoading(false)
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={
            theme.palette.mode === 'dark'
              ? SocialOrganizationIcon
              : SocialIconDark
          }
          alt="SocialOrganization"
        />
        <Typography>
          &nbsp;&nbsp;ADD SOCIAL CONNECTION TO ORGANIZATION
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <InputGroup>
          <Typography>Enter Social platform id:</Typography>
          <Input
            placeholder="social platform id"
            value={socialId}
            type="text"
            required
            onChange={onChangeSocialId}
          />
        </InputGroup>
        <InputGroup>
          <Typography>Choose Social Platform:</Typography>
          <SelectCategory
            required
            value={platform}
            onChange={onChangeSocialPlatform}
          >
            <option disabled value="">
              {' '}
              -- select an option --{' '}
            </option>
            {platforms.map((platform) => (
              <option key={platform} value={platform}>
                {platform}
              </option>
            ))}
          </SelectCategory>
        </InputGroup>
        {enableOrgDropdown && (
          <InputGroup>
            <Typography>Choose organization:</Typography>
            <SelectCategory value={org?.value} onChange={setSelectedOrg}>
              <option disabled value="">
                {' '}
                -- select an option --{' '}
              </option>
              {orgs
                ?.sort((a: any, b: any) =>
                  a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                )
                .map((org: Org) => (
                  <option key={org.value} value={org.value}>
                    {org.label}
                  </option>
                ))}
            </SelectCategory>
          </InputGroup>
        )}
        <Button
          onClick={addSocialConnection}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>ASSOCIATE SOCIALS TO ORG</StyledTxt>
        </Button>
      </AccordionDetails>
    </StyledAccordion>
  )
}
