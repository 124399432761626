//imports
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useIsDisabled } from 'src/utils/disableDemo'
import { newColor } from '../consts/colors'

//services

//components

//assets
//---------------------------------------------------------------------------------

function GameManagementActionsDropdown({
  options,
  optionValues,
  //isBooleanFunctions,
  disabled,
  width,
  hints,
  clickDrawer,
}: {
  options: any[]
  optionValues: any[]
  //isBooleanFunctions: boolean[],
  disabled?: boolean[]
  width?: string
  hints?: (string | undefined)[]
  clickDrawer?: any
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const isDisabled = useIsDisabled()

  const dropdownList = options.map((option: string, index: number) => (
    <MenuItem
      id="menuItemDomainDropdown"
      key={option + '_' + index}
      onClick={() => {
        if (optionValues[index]) {
          optionValues[index]()
        }
        setAnchorEl(null)
      }}
      //disabled={!(hasAccessToModule(option,USER_DATA))}
      sx={{ paddingY: '0.5em' }}
    >
      <div style={{ color: 'white' }}>{option}</div>
    </MenuItem>
  ))

  return (
    <div id="HelikaNameButtonDiv" style={{ margin: 'auto 0 0 0' }}>
      {isDisabled ? (
        <Button
          size="small"
          className="helikaButtonClassDisabled"
          sx={{
            marginTop: 'auto',
            marginLeft: '1em',
            width: '9em',
            cursor: 'not-allowed',
          }}
        >
          ACTIONS
        </Button>
      ) : (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => {
            e.stopPropagation()
            handleClick(e)
          }}
          size="small"
          className="helikaButtonClass"
          sx={{
            background: 'black',
            marginTop: 'auto',
            marginLeft: '1em',
            width: '9em',
          }}
        >
          ACTIONS
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
        onClick={(e) => {
          e.stopPropagation()
        }}
        sx={{
          '& .MuiList-root': {
            background: `'linear-gradient(to right top, ${newColor.jazzberryJam}, ${newColor.cosmicCobalt})`,
          },
          '& .MuiMenu-paper': {
            background: 'black',
            marginTop: '1em',
            marginLeft: '-2.5em',
          },
        }}
      >
        {dropdownList}
      </Menu>
    </div>
  )
}

export default GameManagementActionsDropdown
