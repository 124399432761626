
import { ChatController } from 'src/utils/chat/src';

const initialState = {
  chatController: new ChatController({
    showDateTime: true,
  }),
  isExpanded: true,
  isHistoryExpanded: false,
  content: {
    type: 'jsx',
    content: <div style={{ margin: 'auto', height: 'fit-content' }}>Try asking HelikaAI something!</div>//<img src={HelikaAIImage} alt='helika-ai' style={{ maxHeight: '100%', maxWidth: '100%' }} />
  },
  chat_messages: []
};

export default function llmReducer(state = initialState, action) {
  switch (action.type) {
    case "UNSET_CHAT":
      return initialState;
    case "SET_HISTORY_MINIMIZED":
      return Object.assign({}, state, { isHistoryExpanded: false });
    case "SET_HISTORY_EXPANDED":
      return Object.assign({}, state, { isHistoryExpanded: true });
    case "SET_MINIMIZED":
      return Object.assign({}, state, { isExpanded: false });
    case "SET_EXPANDED":
      return Object.assign({}, state, { isExpanded: true });
    case "SET_CONTENT":
      return Object.assign({}, state, { content: action.payload });
    case "SET_CHAT":
      return Object.assign({}, state, { chatController: action.payload });
    case "SET_CHAT_MESSAGES":
      return Object.assign({}, state, { chat_messages: action.payload });
    default:
      return state;
  }
};