import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Box, Button, styled as muiStyled, Skeleton } from '@mui/material'
import { Auth0Context } from '../../contexts/Auth0Context'
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
  isOrgAdmin,
} from '../../utils/user'
import {
  getOrgUsers as getOrgUsersAPI,
  updateUserModuleAccess as updateUserModuleAccessAPI,
} from '../../utils/api/queries'
import { ReactComponent as CreatorIcon } from 'src/assets/creator_icon_regular.svg'
import { ReactComponent as EmailIcon } from 'src/assets/Email_Icon.svg'
import { ReactComponent as AnalyticsIcon } from 'src/assets/analytics_icon.svg'
import { ReactComponent as Support } from 'src/assets/support_icon.svg'
import { ReactComponent as AccountManagementIcon } from 'src/assets/account_management_icon.svg'
import { ReactComponent as GameManagementIcon } from 'src/assets/game_management_icon.svg'
import { toast } from 'react-toastify'
import { newColor } from '../../consts/colors'

interface OrgUsers {
  email: string
  id: number
  module_access_level: any
  name?: string
  org_role?: string
}

const ListHeader = muiStyled(Box)(() => ({
  display: 'flex',
  gap: '5rem',
  justifyContent: 'center',
  borderRadius: '38px',
  background: `linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%)`,
  height: '33.019px',
  width: '97.5%',
  margin: 'auto',
  alignItems: 'center',
}))

const ListHeaderCell = muiStyled(Box)(() => ({
  color: newColor.white,
  display: 'flex',
  gap: '5px',
  width: '10%',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
}))

const ListRow = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '5rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '38px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  height: '46.924px',
  width: '97.5%',
  margin: '1rem auto auto',
}))

const UserCell = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  wordBreak: 'break-all',
  lineHeight: '127%',
  fontSize: '15px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.ebony : newColor.cultured,
  width: '10%',
  alignItems: 'center',
}))

// FIXME: need to extract this to its own component
const SelectStyled = muiStyled('select')(({ theme }) => ({
  width: '20vw',
  maxWidth: '315px',
  minWidth: '80px',
  height: '28px',
  borderRadius: '3px',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Paralucent Bold',
  lineHeight: '127%',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: newColor.darkGunmetal,
  // margin: '0.875rem 0',
  color: theme.palette.text.primary,
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const RolesDropdown = (props: any) => {
  const moduleRolesOptions = useSelector(
    (state: any) => state.moduleRoles.ROLES,
  )

  return (
    <SelectStyled
      {...props}
      sx={{ px: 1 }}
      onChange={(event) => props.onChange(props.module, props.userId, event)}
    >
      {moduleRolesOptions.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectStyled>
  )
}

const defaultRoles = {
  1: 'none',
  2: 'none',
  3: 'none',
  4: 'none',
}

interface MembersProps {
  orgModuleAccessLevel: any
  searchTerm: string
}

export default function Members({
  orgModuleAccessLevel,
  searchTerm,
}: MembersProps) {
  const userData = useSelector((state: any) => state.user)
  const [filteredUsers, setFilteredUsers] = useState<OrgUsers[]>([])

  const {
    getTokenIfNecessary,
    isLoading,
    isAuthenticated,
    patchWithAccessToken,
  } = useContext(Auth0Context)
  const [users, setUsers] = useState<OrgUsers[]>([])
  const [usersOrig, setUsersOrig] = useState<OrgUsers[]>([])
  const [loading, setLoading] = useState(true)

  const updateRoles = (
    module: number,
    id: number,
    e: ChangeEvent<HTMLSelectElement>,
  ) => {
    const usersCopy = _.cloneDeep(users)
    const index = _.findIndex(usersCopy, { id })
    if (index > -1) {
      const newUser = {
        ...usersCopy[index],
        module_access_level: {
          ...usersCopy[index].module_access_level,
          [module]: e.currentTarget.value,
        },
      }
      usersCopy.splice(index, 1, newUser)
    }

    setUsers(usersCopy)
  }

  const revertChanges = () => {
    setUsers(usersOrig)
  }

  const saveChanges = async () => {
    try {
      const nothingChanged = _.isEqual(users, usersOrig)
      if (nothingChanged) return

      const toUpdate = [] as any
      _.forEach(usersOrig, ({ module_access_level, id }) => {
        const foundInUser = _.find(users, { id })
        if (!_.isEqual(module_access_level, foundInUser?.module_access_level)) {
          toUpdate.push(foundInUser)
        }
      })

      setLoading(true)

      await updateUserModuleAccessAPI(
        patchWithAccessToken,
        toUpdate.reduce((acc: any, curr: any) => {
          acc[curr.id] = {
            ...defaultRoles,
            ...curr.module_access_level,
          }
          return acc
        }, {}),
      )

      toast.success('Successfully saved')

      getOrgUsers()
    } catch (err) {
      console.log(err)
    }
  }

  const getOrgUsers = async () => {
    try {
      const results = await getOrgUsersAPI(getTokenIfNecessary)
      if (!results) return
      const sorted = _.orderBy(results, 'created_at', 'desc')
      setUsers(sorted)
      setUsersOrig(_.cloneDeep(sorted))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  // search name & email
  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase()
    const filtered = users.filter(
      (user) =>
        user.name?.toLowerCase().includes(lowercasedSearchTerm) ||
        user.email.toLowerCase().includes(lowercasedSearchTerm),
    )
    setFilteredUsers(filtered)
  }, [users, searchTerm])

  //get organization API keys
  useEffect(() => {
    if (isLoading || !isAuthenticated || _.isEmpty(userData)) return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData) &&
      !isOrgAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getOrgUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, userData])

  return (
    <div style={{ width: '95%', margin: 'auto', overflowX: 'auto' }}>
      <div style={{ minWidth: '80em', width: '99%' }}>
        <ListHeader>
          <ListHeaderCell style={{ textAlign: 'left', justifyContent: 'left' }}>
            <CreatorIcon /> USER
          </ListHeaderCell>
          <ListHeaderCell style={{ textAlign: 'left', justifyContent: 'left' }}>
            <EmailIcon /> EMAIL
          </ListHeaderCell>
          <ListHeaderCell style={{ textAlign: 'left' }}>
            <AnalyticsIcon /> ANALYTICS
          </ListHeaderCell>
          <ListHeaderCell style={{ textAlign: 'left' }}>
            <GameManagementIcon />{' '}
            <span
              style={{
                lineHeight: '86%',
                textAlign: 'left',
                width: '60%',
                fontSize: '14px',
              }}
            >
              GAME MANAGEMENT
            </span>
          </ListHeaderCell>
          <ListHeaderCell style={{ textAlign: 'left' }}>
            <Support /> SUPPORT
          </ListHeaderCell>
          <ListHeaderCell style={{ textAlign: 'left' }}>
            <AccountManagementIcon />
            <span
              style={{
                lineHeight: '86%',
                textAlign: 'left',
                width: '60%',
                fontSize: '14px',
              }}
            >
              ACCOUNT MANAGEMENT
            </span>
          </ListHeaderCell>
        </ListHeader>
        {!loading ? (
          <Box
            sx={{
              maxHeight: '54vh',
              overflowY: 'scroll',
              width: users.length > 7 ? '101%' : '100%',
            }}
          >
            {filteredUsers.map(
              ({ email, id, module_access_level, name }: any) => {
                const analytics = module_access_level
                  ? module_access_level['1'] || 'none'
                  : 'none'
                const gameManagement = module_access_level
                  ? module_access_level['2'] || 'none'
                  : 'none'
                const support = module_access_level
                  ? module_access_level['3'] || 'none'
                  : 'none'
                const accountManagement = module_access_level
                  ? module_access_level['4'] || 'none'
                  : 'none'

                const checkOrgModuleLevelAccess = (module: any) => {
                  return (
                    orgModuleAccessLevel &&
                    orgModuleAccessLevel[module] &&
                    orgModuleAccessLevel[module] !== 'none'
                  )
                }
                return (
                  <ListRow key={id}>
                    <UserCell>{name}</UserCell>
                    <UserCell>{email}</UserCell>
                    <UserCell>
                      {checkOrgModuleLevelAccess(1) && (
                        <RolesDropdown
                          value={analytics}
                          module={1}
                          userId={id}
                          onChange={updateRoles}
                        />
                      )}
                    </UserCell>
                    <UserCell>
                      {checkOrgModuleLevelAccess(2) && (
                        <RolesDropdown
                          value={gameManagement}
                          module={2}
                          userId={id}
                          onChange={updateRoles}
                        />
                      )}
                    </UserCell>
                    <UserCell>
                      {checkOrgModuleLevelAccess(3) && (
                        <RolesDropdown
                          value={support}
                          module={3}
                          userId={id}
                          onChange={updateRoles}
                        />
                      )}
                    </UserCell>
                    <UserCell>
                      {checkOrgModuleLevelAccess(4) && (
                        <RolesDropdown
                          value={accountManagement}
                          module={4}
                          userId={id}
                          onChange={updateRoles}
                        />
                      )}
                    </UserCell>
                  </ListRow>
                )
              },
            )}
          </Box>
        ) : (
          <>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
          </>
        )}

        {!loading && (
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
          >
            <Button
              onClick={saveChanges}
              sx={{ height: '26px' }}
              variant="contained"
              size="small"
              className="helikaButtonClass"
            >
              SAVE
            </Button>
            <Button
              onClick={revertChanges}
              sx={{ height: '26px' }}
              variant="contained"
              size="small"
              className="helikaButtonClass"
            >
              CANCEL
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
