import React, { useCallback, useContext, useEffect, useState } from 'react'
import DeleteTabModal from '../../components/popups/DeleteTabModal'
import OrganizationTabModal from '../../components/popups/OrganizationTabModal'
import CreateNewTabModal from '../../components/popups/CreateNewTabModal'
import CreateParentTabModal from '../../components/popups/CreateParentTabModal'
import { Auth0Context } from '../../contexts/Auth0Context'
import { Box, Button } from '@mui/material'

import {
  getParentTabsApi,
  getModulesApi,
  getOrgList,
  getMessageApi,
} from '../../utils/api/queries'
import EditTabModal from '../../components/popups/EditTabModal'
import { isHelikaAdmin, isHelikaSuperAdmin } from '../../utils/user'
import { useSelector } from 'react-redux'
import CreateNewAnnouncementModal from '../../components/popups/CreateNewAnnouncementModal'
import EditAnnouncementModal from '../../components/popups/EditAnnouncementModal'
import DeleteKeyModal from '../../components/popups/DeleteKeyModal'

interface Org {
  label: string
  value: number
}
interface OrgData {
  name: string
  id: number
}

export default function StyleGuide() {
  const [showCreateParentTabModal, setShowCreateParentTabModal] =
    useState<boolean>(false)
  const [showCreateNewTabModal, setShowCreateNewTabModal] =
    useState<boolean>(false)
  const [showDeleteTabModal, setShowDeleteTabModal] = useState<boolean>(false)
  const [showOrganizationTabModal, setShowOrganizationTabModal] =
    useState<boolean>(false)
  const [showEditTabModal, setShowEditTabModal] = useState<boolean>(false)
  const [showCreateNewAnnouncementModal, setShowCreateNewAnnouncementModal] =
    useState<boolean>(false)
  const [showEditAnnouncementModal, setShowEditAnnouncementModal] =
    useState<boolean>(false)
  const [showDeleteKeyModal, setShowDeleteKeyModal] = useState<boolean>(false)
  const [, setShowInviteModal] = useState<boolean>(false)

  const { getWithAccessToken } = useContext(Auth0Context)
  const [parentTabs, setParentTabs] = useState<any[]>([])
  const [modulesList, setModulesList] = useState<any[]>([])
  const [messagesList, setMessagesList] = useState<any[]>([])
  const [orgs, setOrgs] = useState<Org[]>([])

  const userData = useSelector((state: any) => state.user)

  const getOrgs = useCallback(async () => {
    try {
      const data = await getOrgList(
        getWithAccessToken,
        (isHelikaAdmin(userData) || isHelikaSuperAdmin(userData)).toString(),
      )
      if (!data || !data.results) return
      let newOrgs = data.results.map((org: OrgData) => ({
        label: org.name,
        value: org.id,
      }))
      setOrgs(newOrgs)
    } catch (e) {
      console.log(e)
    }
  }, [getWithAccessToken, userData])

  const tabId = 4 // It's selected tab Id for add || edit || delete tab
  const page = 1 // In Pagination, Selected page number.
  const pageSize = 5 // In Pagination, Message Count in one page.

  const getParentTabsList = useCallback(() => {
    getParentTabsApi(getWithAccessToken)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setParentTabs(resp.results)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken])

  const getModulesList = useCallback(() => {
    getModulesApi(getWithAccessToken)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setModulesList(resp.results)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [getWithAccessToken])

  const getMessagesList = useCallback(() => {
    getMessageApi(getWithAccessToken, page, pageSize)
      .then((resp: any) => {
        if (!resp.results || resp.results.length === 0) return
        setMessagesList(resp.results)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [getWithAccessToken, page, pageSize])

  useEffect(() => {
    getParentTabsList()
    getModulesList()
    getOrgs()
    getMessagesList()
  }, [
    getWithAccessToken,
    getParentTabsList,
    getModulesList,
    getOrgs,
    getMessagesList,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingY: '48px',
        height: '100vh',
      }}
    >
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowCreateParentTabModal(true)}
        >
          Show CreateParentTabModal
        </Button>
        <CreateParentTabModal
          open={showCreateParentTabModal}
          setOpen={setShowCreateParentTabModal}
          modules={modulesList}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowCreateNewTabModal(true)}
        >
          Show CreateNewTabModal
        </Button>
        <CreateNewTabModal
          open={showCreateNewTabModal}
          setOpen={setShowCreateNewTabModal}
          parentTabs={parentTabs}

        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowDeleteTabModal(true)}
        >
          Show DeleteTabModal
        </Button>
        <DeleteTabModal
          tabId={undefined}
          onDeleteTab={() => { }}
          open={showDeleteTabModal}
          setOpen={setShowDeleteTabModal}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowOrganizationTabModal(true)}
        >
          Show OrganizationTabModal
        </Button>
        <OrganizationTabModal
          open={showOrganizationTabModal}
          setOpen={setShowOrganizationTabModal}
          tabId={tabId}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowEditTabModal(true)}
        >
          Show EditTabModal
        </Button>
        <EditTabModal
          orgs={orgs}
          parentTabs={parentTabs}
          tabId={5} // Assume that the id of the currently selected tab is 1.
          open={showEditTabModal}
          setOpen={setShowEditTabModal}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowCreateNewAnnouncementModal(true)}
        >
          Show CreateNewAnnouncementModal
        </Button>
        <CreateNewAnnouncementModal
          open={showCreateNewAnnouncementModal}
          setOpen={setShowCreateNewAnnouncementModal}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowEditAnnouncementModal(true)}
        >
          Show EditAnnouncementModal
        </Button>
        <EditAnnouncementModal
          open={showEditAnnouncementModal}
          messagesInfo={messagesList}
          setOpen={setShowEditAnnouncementModal}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowDeleteKeyModal(true)}
        >
          Show DeleteKeyModal
        </Button>
        <DeleteKeyModal
          keyId={undefined}
          onDeleteKey={() => { }}
          open={showDeleteKeyModal}
          setOpen={setShowDeleteKeyModal}
        />
      </Box>
      <Box>
        <Button
          className="helikaButtonClass"
          onClick={() => setShowInviteModal(true)}
        >
          Show InviteUsersModal
        </Button>
        {/* <InviteUsersModal
          orgs={orgs}
          open={showInviteModal}
          setOpen={setShowInviteModal}
          onClose={() => {}}
        /> */}
      </Box>
    </Box>
  )
}
