import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { styled } from '@mui/material/styles'
// assets
import { newColor } from 'src/consts/colors'
import LoadingSVG from 'src/assets/loading.svg'

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.malachite,
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '5px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: newColor.shamrock,
  },
}))

export type ReorganiseHooksButtonProps = {
  loading?: boolean
  onClick?: () => void
}

const ReorganiseHooksButton = ({
  loading,
  onClick,
}: ReorganiseHooksButtonProps) => {
  const theme = useTheme()
  const downMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledButton
      variant="contained"
      startIcon={
        loading ? (
          <img
            width="14px"
            className="animate-spin"
            style={{ marginRight: '4px' }}
            src={LoadingSVG}
            alt=""
          />
        ) : (
          <SaveIcon />
        )
      }
      onClick={onClick}
    >
      {downMD ? 'Save' : 'Save Reorganised Hooks'}
    </StyledButton>
  )
}

export default ReorganiseHooksButton
