//imports
import '../../App.css'

import { Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

//----------------------------------------------------------------------------------------------------------

export default function ErrorComponent() {

  return (
    <Box display={'flex'} flexDirection={'column'} style={{ height: '100%', margin: 'auto' }} justifyContent={'center'}>
      <ErrorIcon
        style={{ color: 'red', fontSize: '5em', margin: '0 auto 0.2em auto' }}
      />
      <div style={{ maxWidth: '20em', textAlign: 'center' }}>Oops! Something went wrong.<br />Try refreshing and trying again.</div>
    </Box>
  );
};