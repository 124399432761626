import React from 'react'
import { newColor } from '../consts/colors'
//assets

export default function AnimatedPing(props: any) {

  const { show } = props;

  if (!show) return null;

  return (
    <div
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        display: 'flex',
        paddingLeft: '0.5em'
      }}
    >
      <span
        style={{
          height: '10px',
          width: '10px',
          position: 'absolute',
          background: newColor.jazzberryJam,
          borderRadius: '100vmax',
          display: 'flex'
        }}
      />
      <span
        id={'animate-ping'}
        style={{
          position: 'relative',
          display: 'inline-flex',
          borderRadius: '100vmax',
          background: newColor.jazzberryJam,
          height: '10px',
          width: '10px',
        }}
        className='animate-ping'
      />
    </div>
  )
}
