import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { Auth0Context } from '../../contexts/Auth0Context'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Tab,
  Tabs,
} from '@mui/material'
import { getNameFromS3Key } from '../../utils/excels'
import { baseOptions } from '../../utils/agGrid'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import ApplyConfig from './ApplyConfig'
import Checkbox from '@mui/material/Checkbox'
import { useTheme } from '@mui/material'
import ErrorComponent from '../../pages/CommonPages/ErrorComponent'
import { getConfigSheetDiff } from '../../utils/api/queries'
import AnimatedPing from '../AnimatedPing'
import { useIsDisabled } from 'src/utils/disableDemo'
import { newColor } from '../../consts/colors'
import remove_button from '../../assets/remove_button.svg'
import { Textarea } from '../StyledSimpleComponents'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  columnDefs: any[]
  rowData: any[] | undefined
  onClose: () => void
  title?: string
  showConfig?: any
  config?: any
  draftDiff?: any
  sheetChangeOverride?: any
  isProcessingConfig?: any
  hasError?: boolean | undefined
}

export default function ExcelPopup(props: SimpleDialogProps) {
  const {
    open,
    setOpen,
    columnDefs,
    rowData,
    showConfig,
    config,
    draftDiff,
    sheetChangeOverride,
    onClose,
    isProcessingConfig,
    hasError,
  } = props
  const theme = useTheme()
  const { getTokenIfNecessary } = useContext(Auth0Context)

  const SHEET_MAPPINGS = useSelector(
    (state: any) => state.gameMgmtData.SHEET_MAPPINGS,
  )
  const [selectedSheet, setSelectedSheet] = useState<string | undefined>(
    SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0
      ? SHEET_MAPPINGS[0].sheet_name
      : '',
  )
  const [isLoading, setIsLoading] = useState<any>(true)
  const [secondaryData, setSecondaryData] = useState<any[]>()
  const [diffOptions, setDiffOptions] = useState<any>(baseOptions)
  const [displayRowData, setDisplayRowData] = useState<any>(rowData)
  const [diffOnly, setDiffOnly] = useState<boolean>(false)
  const [dbTableName, setDbTableName] = useState<any>()
  const [showApply, setShowApply] = useState<boolean>(false)
  const [rowsChanged, setRowsChanged] = useState<any>()
  const [sheetColumnDefs, setShowSheetColumnDefs] = useState<any>()
  const [dbColumnDefs, setShowDbColumnDefs] = useState<any>()
  const [showErrorField, setShowErrorField] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<any>()

  const [diffsDownloaded, setDiffsDownloaded] = useState<any>(false)
  const [downloadedDiffs, setDownloadedDiffs] = useState<any[]>([])

  const isDisabled = useIsDisabled()
  let newErrorMessage = localStorage.getItem('APPLY_CONFIG_ERROR')

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiffOnly(event.target.checked)
  }

  const checkAllDiffsDownloaded = useCallback(() => {
    if (_.isEmpty(downloadedDiffs)) return false
    return SHEET_MAPPINGS.every((mapping: any) =>
      downloadedDiffs.find((diff) => diff.sheet_name === mapping.sheet_name),
    )
  }, [SHEET_MAPPINGS, downloadedDiffs])

  const downloadDiffs = useCallback(() => {
    if (checkAllDiffsDownloaded() || !config?.id || !open) return
    SHEET_MAPPINGS.forEach(async (mapping: any) => {
      if (_.isEmpty(mapping?.sheet_name) || !config?.id) return
      let diff = await getConfigSheetDiff(
        getTokenIfNecessary,
        config,
        mapping.sheet_name,
      )
      if (!_.isEmpty(diff[0])) {
        setDownloadedDiffs((prevState) => {
          if (!prevState) return [diff[0]]
          return prevState.concat(diff[0])
        })
      }
    })
  }, [SHEET_MAPPINGS, checkAllDiffsDownloaded, config, getTokenIfNecessary, open])

  // Clears APPLY_CONFIG_ERROR on load
  useEffect(() => {
    localStorage.setItem('APPLY_CONFIG_ERROR', '')
    setShowErrorField(false)
  }, [])

  //reset downloaded diffs if displayed config changes
  useEffect(() => {
    setDownloadedDiffs([])
    setDiffsDownloaded(false)
  }, [config?.id])

  //get all diffs
  useEffect(() => {
    downloadDiffs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.id])

  useEffect(() => {
    if (!open) return
    setDiffsDownloaded(checkAllDiffsDownloaded())
  }, [config?.id, open, checkAllDiffsDownloaded])

  useEffect(() => {
    setErrorMessage(newErrorMessage)
    if (_.isEmpty(newErrorMessage)) {
      localStorage.setItem('APPLY_CONFIG_ERROR', '')
      setShowErrorField(false)
    } else {
      setShowErrorField(true)
    }
  }, [newErrorMessage])

  //set correct column name display
  useEffect(() => {
    if (!columnDefs || _.isEmpty(SHEET_MAPPINGS)) return
    let selectedSheetMapping = SHEET_MAPPINGS.find(
      (mapping: any) => mapping.sheet_name === selectedSheet,
    )

    if (!selectedSheetMapping) {
      setShowDbColumnDefs(columnDefs)
      setShowSheetColumnDefs(columnDefs)
    } else {
      let tempDbColumnDefs = columnDefs.map((columnDef: any) => {
        let tempDef = Object.assign({}, columnDef)
        let found_pair = selectedSheetMapping.column_aliases.find(
          (pair: any) => pair.sheet?.toString() === tempDef.field?.toString(),
        )
        if (!found_pair) return tempDef
        tempDef.headerName = found_pair.table
        return tempDef
      })
      let tempSheetDefs = columnDefs.map((columnDef: any) => {
        let tempDef = Object.assign({}, columnDef)
        let found_pair = selectedSheetMapping.column_aliases.find(
          (pair: any) => pair.sheet?.toString() === tempDef.field?.toString(),
        )
        if (!found_pair) return tempDef
        tempDef.headerName = found_pair.sheet
        return tempDef
      })
      setShowDbColumnDefs(tempDbColumnDefs)
      setShowSheetColumnDefs(tempSheetDefs)
    }
  }, [columnDefs, SHEET_MAPPINGS, selectedSheet])

  useEffect(() => {
    if (!diffOnly || !rowData || !draftDiff || !selectedSheet) {
      setDisplayRowData(rowData)
      return
    }

    let diffhere = draftDiff[0]

    if (!diffhere || !diffhere.diff || _.isEmpty(diffhere.diff)) {
      setDisplayRowData([])
      return
    }

    let stuff = diffhere.diff.map((item: any) =>
      Object.entries(item)[0][0]?.toString(),
    )
    setDisplayRowData(
      rowData.filter((row) =>
        stuff.includes(row[diffhere.key_alias.sheet]?.toString()),
      ),
    )
  }, [rowData, draftDiff, selectedSheet, diffOnly])

  useEffect(() => {
    if (!draftDiff || _.isEmpty(draftDiff)) return
    let diffhere = draftDiff[0]

    if (diffhere && diffhere?.diff) {
      setRowsChanged(diffhere.diff?.length)
    }
  }, [draftDiff])

  const handleClose = () => {
    if (SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) {
      setSelectedSheet(SHEET_MAPPINGS[0].sheet_name)
      if (sheetChangeOverride) {
        sheetChangeOverride(SHEET_MAPPINGS[0].sheet_name)
      }
    }
    setOpen(false)
    setIsLoading(true)
    setDiffOnly(false)
    localStorage.setItem('APPLY_CONFIG_ERROR', '')
    if (onClose) onClose()
  }

  // Optional - for accessing Grid's API
  const gridRefApplied: any = useRef<AgGridReact>(null) // Optional - for accessing Grid's API
  const gridRefDiff: any = useRef<AgGridReact>(null) // Optional - for accessing Grid's API

  // Each Column Definition results in one Column.
  // Example of consuming Grid Event
  const cellClickedListener = useCallback((event: any) => {
    //console.log('cellClicked', event);
  }, [])

  const selectSheet = (event: React.SyntheticEvent, sheet: string) => {
    setSelectedSheet(sheet)
    let sheet_mapping = SHEET_MAPPINGS?.find(
      (mapping: any) => mapping.sheet_name === sheet,
    )
    if (sheetChangeOverride) {
      sheetChangeOverride(sheet)
    } else {
      if (!config) return
      if (SHEET_MAPPINGS && !_.isEmpty(SHEET_MAPPINGS)) {
        let mapping = SHEET_MAPPINGS?.find(
          (mapping: any) => mapping.sheet_name === sheet,
        )
        if (mapping && mapping.table_name) {
          setDbTableName(mapping.table_name)
        } else {
          setDbTableName(undefined)
        }
      }
      showConfig(
        config,
        sheet,
        sheet_mapping?.column_aliases?.map((alias: any) => alias.sheet),
      )
    }
  }

  useEffect(() => {
    function refreshWithDiff(newDiff: any, newSheet: any) {
      if (!newDiff || !rowData || !newSheet) return
      setIsLoading(true)

      //get data for db table
      let newSecondaryData = [...rowData]
      let diffSheetCompare = newDiff[0]
      if (newDiff && newSheet && diffSheetCompare) {
        newSecondaryData = newSecondaryData?.map((secondaryObject: any) => {
          //check if data is in diff
          let diffObject = _.find(diffSheetCompare.diff, function (diff) {
            return secondaryObject[diffSheetCompare.key_alias.sheet] in diff
          })

          //if in diff, replace with diff value
          if (diffObject) {
            let reconstructedDiffObj: any = {}
            let values: any = Object.entries(diffObject)[0][1]
            diffSheetCompare?.column_aliases.forEach(
              (columnName: any, index: number) => {
                reconstructedDiffObj[columnName.sheet] = values[index]
              },
            )

            if (_.isEqual(reconstructedDiffObj, secondaryObject)) {
              //shouldn't happen
              return null
            } else {
              return reconstructedDiffObj
            }
          } else {
            //case not modified
            return secondaryObject
          }
        })
      }

      //get items that were removed
      let removed = diffSheetCompare.diff.map((diffObject: any) => {
        if (!diffObject) return null
        let key = Object.entries(diffObject)[0][0]

        let found = newSecondaryData
          .filter((item) => !_.isNull(item))
          .map((item) => item[diffSheetCompare.key_alias.sheet]?.toString())
          .includes(key)
        if (found) {
          return null
        } else {
          let newObj: any = {}
          diffSheetCompare?.column_aliases.forEach(
            (columnName: any, index: number) => {
              let values: any = Object.entries(diffObject)[0][1]
              newObj[columnName.sheet] = values[index]
            },
          )
          return newObj
        }
      })
      let removedFromDBTable = removed?.filter(
        (item: any) => item !== null && !_.find(rowData, item),
      )

      //if data was removed, add back to applied data
      let secondaryDataUpdate: any = []
      if (removedFromDBTable && removedFromDBTable.length > 0) {
        secondaryDataUpdate = newSecondaryData?.concat(removedFromDBTable)
        setDisplayRowData((prevState: any[]) =>
          prevState.concat(
            removedFromDBTable.map(() => {
              return {}
            }),
          ),
        )
      } else {
        secondaryDataUpdate = newSecondaryData
      }

      if (diffOnly) {
        let stuff = diffSheetCompare.diff.map((item: any) =>
          Object.entries(item)[0][0]?.toString(),
        )
        secondaryDataUpdate = secondaryDataUpdate.filter(
          (row: any) =>
            !row ||
            stuff.includes(row[diffSheetCompare.key_alias.sheet]?.toString()),
        )
      }

      setSecondaryData(secondaryDataUpdate)

      let draftAgGridOptions = Object.assign({}, baseOptions, {
        suppressFieldDotNotation: true,
        context: {
          config: config,
          selectedSheet: newSheet,
          diffSheet: newDiff[0],
        },
        getRowStyle: (params: any) => {
          let isInDiff = false

          //something was removed
          if (!params.data || _.isEmpty(params.data))
            return { background: newColor.cosmicCobalt }

          //check if object is in diff
          params?.context?.diffSheet?.diff.forEach((diffObject: any) => {
            let key = Object.entries(diffObject)[0][0]
            if (
              params.data[
                params?.context?.diffSheet.key_alias.sheet
              ]?.toString() === key
            ) {
              isInDiff = true
            }
          })

          if (isInDiff) return { background: newColor.cosmicCobalt }
        },
        onGridReady: (params: any) => {
          params?.columnApi?.autoSizeAllColumns(false)
        },
      })
      setDiffOptions(draftAgGridOptions)
      gridRefApplied?.current?.api?.refreshCells()
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }

    refreshWithDiff(draftDiff, selectedSheet)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftDiff, selectedSheet, config, rowData, diffOnly])

  useEffect(() => {
    if (SHEET_MAPPINGS && SHEET_MAPPINGS.length > 0) {
      setIsLoading(true)
      setSelectedSheet(SHEET_MAPPINGS[0].sheet_name)
      setDbTableName(SHEET_MAPPINGS[0].table_name)
    }
  }, [SHEET_MAPPINGS, config])

  const loadingDiv = (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LoadingComponent />
    </Box>
  )

  return (
    <Dialog
      disableRestoreFocus
      disableScrollLock
      fullScreen={true}
      sx={{ padding: 0, height: '90vh', width: '90vw', margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background:
            theme.palette.mode === 'dark'
              ? newColor.midnight
              : newColor.cultured,
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          overflow: 'auto',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        className={
          theme.palette.mode === 'dark'
            ? 'circleBackground'
            : 'circleBackgroundLight'
        }
        sx={{
          padding: '0.5em',
          margin: '1em 1em 0 1em',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            maxWidth: '75%',
          }}
        >
          <DialogTitle
            sx={{ width: '100%', height: 'fit-content', textAlign: 'start' }}
            className={`${theme.palette.mode === 'dark' ? 'gradientText' : 'whiteText'
              } leftTextNoWrap`}
          >
            {getNameFromS3Key(config)}
          </DialogTitle>
        </div>
        <FormControlLabel
          sx={{
            color: 'white',
            whiteSpace: 'no-wrap',
            minWidth: 'max-content',
          }}
          control={
            <Checkbox
              checked={diffOnly}
              onChange={handleChangeFilter}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Diff Filter"
        />
      </Box>

      <ApplyConfig
        config={config}
        open={showApply}
        setOpen={setShowApply}
        onClose={() => {
          setShowApply(false)
          //setDbDiscrepancies(undefined);
        }}
        columnDefs={columnDefs}
        rowData={rowData}
        loadedS3Objects={undefined}
        setLoadedS3Objects={undefined}
        draftDiff={draftDiff}
        dbDiscrepancies={undefined}
      />
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginRight={'1em'}
      >
        {hasError ? null : diffsDownloaded ? (
          <Tabs
            value={selectedSheet}
            onChange={selectSheet}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="sheets"
            sx={{
              fontWeight: 900,
              padding: '1em',
              borderRadius: '0.5em',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
              gap: '0.5em '
            }}
          >
            {SHEET_MAPPINGS?.map((sheetMapping: any) => {
              return (
                <Tab
                  sx={{
                    fontWeight: 900,
                    marginRight: 1,
                    borderRadius: '0.5em',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    background:
                      theme.palette.mode === 'dark' ? newColor.night : 'white',
                    padding: '0 1em 0 1em',
                  }}
                  key={sheetMapping.sheet_name}
                  label={sheetMapping.sheet_name}
                  value={sheetMapping.sheet_name}
                  iconPosition="end"
                  icon={
                    <AnimatedPing
                      show={_.find(downloadedDiffs, function (item) {
                        return (
                          item.sheet_name === sheetMapping.sheet_name &&
                          item.diff &&
                          !_.isEmpty(item.diff)
                        )
                      })}
                    />
                  }
                />
              )
            })}
          </Tabs>
        ) : (
          <div
            style={{
              fontSize: '1em',
              marginLeft: '1.5em',
              marginTop: '0.5em',
            }}
            className="loading"
          >
            {`Loading Config Sheets `}
          </div>
        )}
      </Box>
      {hasError ? (
        <ErrorComponent />
      ) : isLoading ||
        !draftDiff ||
        !secondaryData ||
        isProcessingConfig ||
        !diffsDownloaded ? (
        loadingDiv
      ) : (
        <Box display="flex" flexDirection={'column'} sx={{ height: '100%' }}>
          <Box display={'flex'} flexDirection={'row'}>
            <div
              style={{
                padding: '0 1em 0 1em',
                height: 'min-content',
                display: 'flex',
                flex: 1,
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '0.8em',
                  margin: 0,
                  padding: '0.5em 0.5em 0 0.5em',
                }}
              >{`Config Values ${rowsChanged && rowsChanged > 0 && rowData
                ? `(${rowsChanged} rows changed, ~${(displayRowData.length
                  ? (rowsChanged / displayRowData.length) * 100 > 100
                    ? 100
                    : (rowsChanged / displayRowData.length) * 100
                  : 100
                )?.toFixed(2)}% of config)`
                : ''
                }`}</p>
            </div>
            <div
              style={{
                padding: '0 1em 0 1em',
                height: 'min-content',
                display: 'flex',
                flex: 1,
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '0.8em',
                  margin: 0,
                  padding: '0.5em 0.5em 0 0.5em',
                }}
              >
                DB Values {dbTableName && `(Table Name: ${dbTableName})`}
              </p>
            </div>
          </Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              width: '88vw',
              margin: '1em auto 0 auto',
              overflow: 'hidden',
              minHeight: '50%',
              height: 'min-content',
              fontSize: '12px !important',
            }}
          >
            <div
              style={{ width: '100%', height: '100%' }}
              className={`smallExcel ${theme.palette.mode === 'light'
                ? 'ag-theme-balham'
                : 'ag-theme-balham-dark'
                }`}
            >
              <AgGridReact
                className="customAgGridHover"
                ref={gridRefApplied} // Ref for accessing Grid's API
                rowData={displayRowData} // Row Data for Rows
                columnDefs={sheetColumnDefs} // Column Defs for Columns
                context={{
                  config: config,
                  selectedSheet: selectedSheet,
                  diffSheet: draftDiff[0],
                }}
                onGridReady={(params) => {
                  params?.columnApi?.autoSizeAllColumns(false)
                }}
                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                gridOptions={diffOptions}
              />
            </div>

            <div
              style={{ width: '100%', height: '100%' }}
              className={`smallExcel ${theme.palette.mode === 'light'
                ? 'ag-theme-balham'
                : 'ag-theme-balham-dark'
                }`}
            >
              <AgGridReact
                className="customAgGridHover"
                ref={gridRefDiff} // Ref for accessing Grid's API
                rowData={secondaryData} // Row Data for Rows
                columnDefs={dbColumnDefs} // Column Defs for Columns
                context={{
                  config: config,
                  selectedSheet: selectedSheet,
                }}
                onGridReady={(params) => {
                  params?.columnApi?.autoSizeAllColumns(false)
                }}
                onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                gridOptions={baseOptions}
              />
            </div>
          </div>

          {showErrorField && (
            <Box
              sx={{
                padding: '0.5em',
                paddingTop: '2vh',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Textarea
                multiline
                autoFocus
                disabled
                sx={{
                  borderRadius: '1em',
                  '& fieldset': { border: 'none' },
                  boxShadow: 'none',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  marginTop: 'auto',
                  paddingLeft: '2em',
                  padding: '0.55em',
                  width: '100%',
                  marginRight: '1em',
                  height: 'auto',
                }}
                value={errorMessage}
              />
              <img
                src={remove_button}
                style={{ marginRight: '0.5em', cursor: 'pointer' }}
                alt=""
                onClick={() => {
                  localStorage.setItem('APPLY_CONFIG_ERROR', '')
                  setShowErrorField(false)
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              padding: '0.5em',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Button
              className="helikaButtonClass"
              style={{ padding: '0.3em 2em 0.3em 2em', margin: 'auto' }}
              onClick={() => setShowApply(true)}
              disabled={isDisabled}
            >
              APPLY CONFIG
            </Button>
          </Box>
        </Box>
      )}
    </Dialog>
  )
}
