const initialState = {
  MAIN_DRAWER_OPEN: false,
  TAB_STATE: [{
    name: 'Live Ops',
    icon: 'person',
    subTabs: [{
      name: 'Config',
      icon: 'person'
      }]
    },
    {
      name: 'Points and Rewards',
      icon: 'person',
      subTabs: [
        {
          name: 'Points',
          icon: 'person',
        },
      ],
    },
  ],
  SUBTABS_STATE: {},
  CONFIG_HISTORY: [],
  SHEET_MAPPINGS: [],
  CONFIG_LIST: [],
}

export default function drawerReducer(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case 'CLEAR_DATA':
      return initialState
    case 'SET_SHEET_MAPPINGS':
      return Object.assign({}, newState, { SHEET_MAPPINGS: action.payload });
    case 'OPEN_DRAWER':
      newState.MAIN_DRAWER_OPEN = true;
      return newState;
    case 'CLOSE_DRAWER':
      newState.MAIN_DRAWER_OPEN = false;
      return newState;
    case "SET_GAME_MANAGEMENT_DATA":
      if ('MAIN_DRAWER_OPEN' in action.payload) {
        newState.MAIN_DRAWER_OPEN = action.payload.MAIN_DRAWER_OPEN
      }
      if ('TAB_STATE' in action.payload) {
        newState.TAB_STATE = action.payload.TAB_STATE;
      }
      if ('subTabs' in action.payload) {
        let newSubtabState = state.SUBTABS_STATE;
        if (typeof action.payload.subTabs === 'object') {
          newSubtabState = action.payload.subTabs
        } else {
          newSubtabState[action.payload.subTabs] = !state.SUBTABS_STATE[action.payload.subTabs]
        }
        newState.SUBTABS_STATE = newSubtabState;
      }
      return newState;
    case "LOAD_MORE_CONFIG_LIST":
      let updated_config_list = newState.CONFIG_LIST?.concat(action.payload);
      return Object.assign({}, state, { CONFIG_LIST: updated_config_list });
    case "LOAD_MORE_CONFIG_HISTORY":
      let updated_config_history = newState.CONFIG_HISTORY?.concat(action.payload);
      return Object.assign({}, state, { CONFIG_HISTORY: updated_config_history });
    case "SET_CONFIG_LIST":
      newState.CONFIG_LIST = action.payload;
      return Object.assign({}, state, newState);
    case "SET_CONFIG_HISTORY":
      newState.CONFIG_HISTORY = action.payload;
      return Object.assign({}, state, newState);
    default:
      return state
  }
}
