export enum TabLevel {
  Module = '1',
  Parent = '2',
  Child = '3',
}

type TabId = number
export type DroppableTypeParent = `${TabLevel}-${TabId}`
export type DroppableTypeChild = `${TabLevel}-${TabId}-${TabId}`

type ParentTabIdAsKey = string

export type ChildTab = {
  id: number
  priority: number
  name: string
  icon: string
}

export type ParentTab = {
  [key: ParentTabIdAsKey]: {
    id: number
    children: Array<ChildTab>
    name: string
    priority: number
    icon: string
  }
}

type ModuleTypeId = string
export type TabData = {
  [key: ModuleTypeId]: ParentTab
}
