//imports
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Buffer } from 'buffer'
import { useSelector } from 'react-redux'
import _ from 'lodash-es'
import { toast } from 'react-toastify'
import '../../../App.css'

//context
import { Auth0Context } from '../../../contexts/Auth0Context'

//services
import { getExploreDashboardData } from 'src/utils/api/queries'
import getSigmaURL from '../../../utils/getSigmaURL'
import {
  lowerCaseAndReplaceSpacesAndDashes,
  replaceQuotes,
} from '../../../utils/string'

//components
import { Box, Tab, Tabs, useTheme } from '@mui/material'
import LoadingComponent from '../../../components/LoadingComponent'
import ErrorComponent from '../../CommonPages/ErrorComponent'
import { DashboardGridstack } from '../Explore/DashboardGridstack'

//----------------------------------------------------------------------------------------------------------

export default function Dashboards() {
  const theme = useTheme()
  const location = useLocation()
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const { user, getWithAccessToken, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [embedPath, setEmbedPath] = useState<any[]>()
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [dashboard, setDashboard] = useState<any>(<LoadingComponent />)
  const [currentTab, setCurrentTab] = useState<number>()
  const [dashboards, setDashboards] = useState<any[]>()
  window.Buffer = Buffer

  //custom dashboard info
  const [dashboardData, setDashboardData] = useState<any>()
  const [currentDashboardType, setCurrentDashboardType] = useState<string>('Sigma')
  const gridRef: any = useRef()

  const selectTab = (event: React.SyntheticEvent, tab: number) => {
    setCurrentTab(tab)
    let newTab = dashboards?.find((subTab: any) => subTab.id === tab)
    setCurrentDashboardType(newTab.type)
    if (newTab) {
      setPath(newTab)
    }
  }

  async function setPath(dashboard: any) {
    try {
      if (!dashboard) {
        throw new Error('Dashboard not found')
      }

      //add filters if in dashboard data
      let filters: any = []
      try {
        let default_params = userData.organization_dashboard_params
        if (default_params && default_params[dashboard.id]) {
          let default_params_specific = JSON.parse(default_params[dashboard.id])
          if (default_params_specific) {
            let params_array = Object.entries(default_params_specific)
            params_array.forEach((param_pair: any) => {
              if (!_.isEmpty(param_pair)) {
                filters = filters?.concat({
                  key: param_pair[0],
                  value: param_pair[1] ? param_pair[1] : null,
                })
              }
            })
          }
        }
      } catch (e) {
        console.log('error getting default params')
      }
      if (filters.length === 0) filters = null
      setEmbedPath([replaceQuotes(dashboard.iframe_link), filters])
      setIsLoadingData(false)
    } catch (e: any) {
      setHasError(true)
      toast.error(e?.message)
    }
  }

  //projectLoading
  useEffect(() => {
    setTimeout(function () {
      if (_.isEmpty(userData?.tabs)) setHasError(true)
    }, 15000)
  }, [userData])

  //set embed path for dashboard from id
  useEffect(() => {
    async function getDashboard() {
      try {
        setHasError(false)

        const path = location.pathname?.split('/')
        let parent_id: any

        let tabData: any = Object.entries(userData.tabs[1]).map(
          (item) => item[1],
        )
        if (_.isEmpty(tabData)) return

        let foundDrawer = null
        let foundSubTab = null

        foundDrawer = tabData?.find(
          (drawer: any) =>
            lowerCaseAndReplaceSpacesAndDashes(drawer.name) ===
            lowerCaseAndReplaceSpacesAndDashes(path[2]),
        )
        if (!_.isEmpty(foundDrawer) && !_.isEmpty(foundDrawer?.children)) {
          foundSubTab = foundDrawer.children.find(
            (subTab: any) =>
              replaceQuotes(lowerCaseAndReplaceSpacesAndDashes(subTab.name)) ===
              replaceQuotes(lowerCaseAndReplaceSpacesAndDashes(path[3])),
          )
          parent_id = foundSubTab.id
        }
        if (!parent_id) {
          console.log('no parent id found')
          return
        }
        let newDashboards = await getWithAccessToken(
          `${process.env.REACT_APP_HELIKA_API_URL}/v1/dashboards/parent/${parent_id}`,
        ).catch((e: any) => {
          console.log(e)
        })
        newDashboards = newDashboards?.results
        if (_.isEmpty(newDashboards)) {
          toast.error('Could not load dashboards')
          return
        }
        setCurrentDashboardType(newDashboards[0].type)
        setCurrentTab(newDashboards[0].id)
        setDashboards(newDashboards)
        setPath(newDashboards[0])

        if (_.isEmpty(sdk)) return
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'interaction_event',
            event: {
              event_sub_type: 'interaction_portal_tab_load',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
              parent_tab_identifier: foundDrawer?.id,
              parent_tab_name: foundDrawer?.name,
              tab_identifier: foundSubTab?.id,
              tab_name: foundSubTab?.name,
            },
          },
        ])
      } catch (e: any) {
        setHasError(true)
        toast.error(e?.message)
      }
    }

    if (
      isLoading ||
      !isAuthenticated ||
      _.isEmpty(userData) ||
      !userData.tabs ||
      !('1' in userData.tabs) ||
      !userData.tabs[1]
    )
      return
    getDashboard()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, userData, isLoading, isAuthenticated])

  useEffect(() => {
    async function sendSdkDashboardLoadEvent(info: any) {
      try {
        if (_.isEmpty(sdk) || _.isEmpty(info)) return
        await sdk.createUserEvent([
          {
            game_id: 'helika_portal',
            event_type: 'dashboard_load',
            event: info
          },
          {
            game_id: 'helika_portal',
            event_type: 'interaction_event',
            event: {
              event_sub_type: 'user_log_in',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
            },
          },
        ])
      } catch (e: any) {
        console.error(e.message)
      }
    }

    if (
      isLoading ||
      !isAuthenticated ||
      isLoadingData ||
      !embedPath ||
      _.isEmpty(userData)
    ) return

    let sdkInfoEventInfo = {};
    if (currentDashboardType === 'HELIKA') {
      //clear old grid elements if existing
      const grid = gridRef.current
      if (_.isNil(grid)) return
      grid.removeAll(false)
      setDashboard(null)
      //populate event info
      sdkInfoEventInfo = { embed_path: embedPath[0] }
    } else {
      //set latest sigma dashboard
      setDashboard(
        <iframe
          frameBorder="0"
          src={getSigmaURL(embedPath[0], userData, embedPath[1], theme)}
          title="embeded data"
        />,
      )
      //populate event info
      sdkInfoEventInfo = { embed_path: embedPath[0] }
    }
    sendSdkDashboardLoadEvent(sdkInfoEventInfo)
  }, [embedPath, isLoadingData, user, userData, isLoading, isAuthenticated, currentDashboardType, sdk, dashboardData, theme])

  useEffect(() => {
    async function getCustomDashInfo() {
      try {

        //check if custom dashboard
        let newTab = dashboards?.find((subTab: any) => subTab.id === currentTab)
        if (newTab?.type !== 'HELIKA') {
          return;
        }

        if (newTab.helika_dashboard_id) {
          let resp: any = await getExploreDashboardData(getWithAccessToken, {
            helika_dashboard_id: Number(newTab.helika_dashboard_id),
          })
          setDashboardData(resp);
        } else {
          throw new Error('Could not find custom dashboard')
        }
      } catch (e: any) {
        toast.error(e?.message)
      }
    }
    getCustomDashInfo()
  }, [currentTab, dashboards, getWithAccessToken])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      style={{ height: '100vh' }}
      justifyContent={'center'}
      width={'100%'}
    >
      {dashboards && currentTab && dashboards?.length > 1 && currentDashboardType !== 'HELIKA' && (
        <Tabs
          value={currentTab}
          onChange={selectTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ paddingLeft: 4, paddingRight: 4 }}
          className="sticky"
        >
          {dashboards?.map((subTab: any) => {
            return (
              <Tab
                key={subTab.name}
                label={subTab.name ? subTab.name : subTab.name}
                value={Number(subTab?.id)}
              />
            )
          })}
        </Tabs>
      )}
      {hasError && <ErrorComponent />}
      {dashboard}
      <DashboardGridstack
        dashboard_id={dashboardData?.data.id}
        items={dashboardData?.data?.config ? dashboardData?.data?.config?.filter((item: any) => !('visualization' in item && _.isEmpty(item.visualization))) : []}
        setItems={() => { }}
        visuals={dashboardData?.data?.config ? dashboardData?.data?.config?.filter((item: any) => !('visualization' in item && _.isEmpty(item.visualization)))?.map((item: any) => item.visualization) : []}
        queries={dashboardData?.data?.config ? dashboardData?.data?.config?.filter((item: any) => !('visualization' in item && _.isEmpty(item.visualization)))?.map((item: any) => { return { id: item.query_id, query: item.query } }) : []}
        gridRef={gridRef}
        isLoadingQueries={isLoadingData}
        inEditMode={false}
        show={currentDashboardType === 'HELIKA'}
      />
    </Box>
  )
}
