import { BarSeriesOption, EChartsOption, HeatmapSeriesOption, LineSeriesOption } from 'echarts';
import { newColor, palette } from '../consts/colors';


export const baseOptions: EChartsOption = {
  color: [palette.error, palette.bgPale, palette.font, palette.primary],
  backgroundColor: palette.bgLight,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
  dataZoom: [
    {
      type: 'inside',
      disabled: true,
    },
  ],
  legend: {
    right: '4%',
    icon: 'roundRect',
    textStyle: {
      color: palette.font,
    },
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  grid: {
    left: '5.5%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
  },
  yAxis: {
    type: 'value',
  },
  title: {
    left: 20,
    top: 10,
    textStyle: {
      color: palette.font,
      fontSize: 16,
      fontFamily: 'sans-serif',
      fontWeight: 325,
    },
  },
};

export const loadingOption: EChartsOption = {
  color: palette.error,
  textColor: palette.font,
  fontWeight: 'bold',
  fontSize: 16,
  text: 'loading',
  lineWidth: 3,
  spinnerRadius: 10,
  maskColor: palette.transparent,
  opacity: 0.7,
};

export const idleOption: EChartsOption = {
  textColor: palette.secondary,
  fontWeight: 'bold',
  fontSize: 16,
  text: 'Select collection to load data',
  lineWidth: 3,
  showSpinner: false,
  maskColor: palette.transparent,
};

export const lineOptions: LineSeriesOption = {
  type: 'line',
  emphasis: {
    focus: 'series',
  },
};

export const barOptions: BarSeriesOption = {
  type: 'bar',
  emphasis: {
    focus: 'series',
  },
};

export const areaChartOptions: LineSeriesOption = {
  type: 'line',
  areaStyle: {
    opacity: 0.7,
  },
};

export const barStackedOptions: BarSeriesOption = {
  ...barOptions,
  stack: 'total',
};

export const lineStackOptions: LineSeriesOption = {
  ...lineOptions,
  stack: 'total',
};

export const barSeriesOptions: BarSeriesOption = {
  type: 'bar',
  stack: 'stack',
  label: {
    show: true,
    formatter: (params) => String(params.value) + '%',
    position: 'top',
    fontSize: 16,
  },
};

export const heatmapSeriesOptions: HeatmapSeriesOption = {
  type: 'heatmap',
  label: {
    show: true,
    formatter: (params) =>
      params.seriesIndex === 0 ? String((params.data as number[])[2]) : `${(params.data as number[])[2]}%`,
  },
  emphasis: {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  itemStyle: {
    borderWidth: 1,
    borderType: 'solid',
    borderColor: palette.bg,
  },
};

export const disableLegendOptions = {
  legend: {
    show: false,
  },
};

export const heatmapOptions = (itemsNumber: number): EChartsOption => {
  return {
    grid: {
      bottom: 20,
      left: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        yAxisIndex: 0,
        zoomLock: true,
        brushSelect: false,
        width: 24,
        right: 16,
        start: 0,
        end: itemsNumber < 20 ? 100 : (20 * 100) / itemsNumber,
        textStyle: {
          color: palette.font,
        },
      },
    ],
  };
};

export const calcMinMaxForVisualMap = (data: [number, number, number][], offset: number, isMax?: boolean) => {
  let min = Infinity,
    max = 0;
  for (const item of data) {
    const value = item[2];
    max = Math.max(value, max);
    min = Math.min(value, min);
  }

  if (isMax) {
    return max + offset;
  } else {
    return min - offset > 0 ? min - offset : 0;
  }
};

export const defaultLineStack: any = {
  ...lineStackOptions,

  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: 'Email',
      type: 'line',
      stack: 'Total',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: 'Union Ads',
      type: 'line',
      stack: 'Total',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: 'Video Ads',
      type: 'line',
      stack: 'Total',
      data: [150, 232, 201, 154, 190, 330, 410]
    },
    {
      name: 'Direct',
      type: 'line',
      stack: 'Total',
      data: [320, 332, 301, 334, 390, 330, 320]
    },
    {
      name: 'Search Engine',
      type: 'line',
      stack: 'Total',
      data: [820, 932, 901, 934, 1290, 1330, 1320]
    }
  ],
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'axis'
  },
}

export const defaultBarChart: any = {
  ...barOptions,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ['bar'] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Temperature',
      min: 0,
      max: 70,
      interval: 5,
      // axisLabel: {
      //   formatter: '{value}'
      // }
    }
  ],
  series: [
    {
      name: 'Evaporation',
      type: 'bar',
      tooltip: {
        // valueFormatter: function (value: any) {
        //   return value + ' ml';
        // }
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 22.6, 11.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: 'Precipitation',
      type: 'bar',
      tooltip: {
        // valueFormatter: function (value: any) {
        //   return value + ' ml';
        // }
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 45.6, 16.2, 48.7, 18.8, 6.0, 2.3
      ]
    },
    {
      name: 'Temperature',
      type: 'bar',
      // yAxisIndex: 1,
      tooltip: {
        // valueFormatter: function (value: any) {
        //   return value + ' °C';
        // }
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    }
  ]
}

export const defaultMixedLineBarChart: any = {
  ...lineOptions,
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      magicType: { show: true, type: ['line', 'bar'] },
      restore: { show: true },
      saveAsImage: { show: true }
    }
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Precipitation',
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: '{value} ml'
      }
    },
    {
      type: 'value',
      name: 'Temperature',
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: '{value} °C'
      }
    }
  ],
  series: [
    {
      name: 'Evaporation',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value: any) {
          return value + ' ml';
        }
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: 'Temperature',
      type: 'line',
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value: any) {
          return value + ' °C';
        }
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    }
  ]
}

export const defaultFunnelChart: any = {
  ...baseOptions,
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}%'
  },
  toolbox: {
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {}
    }
  },
  legend: {
    data: ['Show', 'Click', 'Visit', 'Inquiry', 'Order']
  },
  series: [
    {
      name: 'Funnel',
      type: 'funnel',
      left: '10%',
      top: 60,
      bottom: 60,
      width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      gap: 2,
      label: {
        show: true,
        position: 'inside'
      },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid'
        }
      },
      itemStyle: {
        borderColor: newColor.white,
        borderWidth: 1
      },
      emphasis: {
        label: {
          fontSize: 20
        }
      },
      data: [
        { value: 60, name: 'Visit' },
        { value: 40, name: 'Inquiry' },
        { value: 20, name: 'Order' },
        { value: 80, name: 'Click' },
        { value: 100, name: 'Show' }
      ]
    }
  ]
}

export function simpleMovingAverage(data: any[], avgLength: number) {

  const getAverage = (array: any[]) => array.reduce((sum, currentValue) => sum + currentValue, 0) / array.length;

  var N = data.length;

  var moveMean = [];
  for (var x = 1; x <= N; x++) {
    if (x >= avgLength) {
      moveMean.push(getAverage(data.slice(x - avgLength, x)))
    } else {
      moveMean.push(undefined)
    }
  }
  return moveMean
}

export const height = '50%';
export const width = '50%';
