import React, { useEffect, useState } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  renderIconSubTabs,
  //renderIconTabs,
  stringToIcon,
} from '../utils/string'
import { Collapse } from '@mui/material'
import { DOMAIN_TYPES } from '../state/action-types'
import { lowerCaseAndReplaceSpacesAndDashes } from '../utils/string'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators } from '../state'
import { bindActionCreators } from 'redux'
import { useTheme } from '@mui/material'
import { newColor } from '../consts/colors'

export const DrawerList = ({
  name,
  icon,
  subTabs,
  url,
  index,
  open,
  handleDrawerClick,
}: {
  name: DOMAIN_TYPES
  icon: any
  subTabs?: any[]
  url?: string
  index: Number
  open: boolean
  handleDrawerClick: any
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname?.split('/')
  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const dispatch = useDispatch()
  const {
    setAnalyticsState,
    setGameManagementState,
    setAccountManagementState,
    setSupportState,
  } = bindActionCreators(actionCreators, dispatch)

  useEffect(() => {
    if (!open) {
      setIsExpanded(false)
    } else if(!index){
      setIsExpanded(true)
    }
  }, [open])

  async function handleTabClick_GAME_MANAGEMENT(
    e: any,
    type: string,
    tab?: any,
    url?: string,
  ) {
    switch (type) {
      case 'PARENT_TAB':
        e.stopPropagation()
        handleDrawerClick(name, undefined, false)
        if (url) {
          navigate(
            `/game_management/${lowerCaseAndReplaceSpacesAndDashes(
              name,
            )}/${lowerCaseAndReplaceSpacesAndDashes(url)}`,
          )
        }
        setGameManagementState({
          type: 'OPEN_DRAWER',
        })
        break
      case 'SUB_TAB':
        e.stopPropagation()
        handleDrawerClick(name, true, true)
        navigate(
          `/game_management/${lowerCaseAndReplaceSpacesAndDashes(
            name,
          )}/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`,
        )
        break
      case 'PUBLIC_TAB':
        setGameManagementState({
          type: 'CLOSE_DRAWER',
        })
        navigate(`/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`)
    }
  }

  async function handleTabClick_ANALYTICS(
    e: any,
    type: string,
    tab?: any,
    url?: string,
  ) {
    switch (type) {
      case 'PARENT_TAB':
        e.stopPropagation()
        handleDrawerClick(name, undefined, false)
        if (url) {
          navigate(
            `/analytics/${lowerCaseAndReplaceSpacesAndDashes(
              name,
            )}/${lowerCaseAndReplaceSpacesAndDashes(url)}`,
          )
        }
        setAnalyticsState({
          type: 'OPEN_DRAWER',
        })
        break
      case 'SUB_TAB':
        e.stopPropagation()
        handleDrawerClick(name, true, true)
        navigate(
          `/analytics/${lowerCaseAndReplaceSpacesAndDashes(
            name,
          )}/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`,
        )
        break
      case 'PUBLIC_TAB':
        setAnalyticsState({
          type: 'CLOSE_DRAWER',
        })
        navigate(`/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`)
    }
  }

  async function handleTabClick_ACCOUNT_MANAGEMENT(
    e: any,
    type: string,
    tab?: any,
    url?: string,
  ) {
    switch (type) {
      case 'PARENT_TAB':
        e.stopPropagation()
        handleDrawerClick(name, undefined, false)
        if (url) {
          navigate(
            `/account_management/${lowerCaseAndReplaceSpacesAndDashes(
              name,
            )}/${lowerCaseAndReplaceSpacesAndDashes(url)}`,
          )
        }
        setAccountManagementState({
          type: 'OPEN_DRAWER',
        })
        break
      case 'SUB_TAB':
        e.stopPropagation()
        handleDrawerClick(name, true, true)
        navigate(
          `/account_management/${lowerCaseAndReplaceSpacesAndDashes(
            name,
          )}/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`,
        )
        break
      case 'PUBLIC_TAB':
        setAccountManagementState({
          type: 'CLOSE_DRAWER',
        })
        navigate(`/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`)
    }
  }

  async function handleTabClick_ACCOUNT_SUPPORT(
    e: any,
    type: string,
    tab?: any,
    url?: string,
  ) {
    switch (type) {
      case 'PARENT_TAB':
        e.stopPropagation()
        handleDrawerClick(name, undefined, false)
        if (url) {
          navigate(
            `/support/${lowerCaseAndReplaceSpacesAndDashes(
              name,
            )}/${lowerCaseAndReplaceSpacesAndDashes(url)}`,
          )
        }
        setSupportState({
          type: 'OPEN_DRAWER',
        })
        break
      case 'SUB_TAB':
        e.stopPropagation()
        handleDrawerClick(name, true, true)
        navigate(
          `/support/${lowerCaseAndReplaceSpacesAndDashes(
            name,
          )}/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`,
        )
        break
      case 'PUBLIC_TAB':
        setSupportState({
          type: 'CLOSE_DRAWER',
        })
        navigate(`/${lowerCaseAndReplaceSpacesAndDashes(tab.name)}`)
    }
  }

  async function handleTabClick(e: any, type: string, tab?: any, url?: string) {
    switch (DOMAIN) {
      case DOMAIN_TYPES.SUPPORT:
        handleTabClick_ACCOUNT_SUPPORT(e, type, tab, url)
        break
      case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
        handleTabClick_ACCOUNT_MANAGEMENT(e, type, tab, url)
        break
      case DOMAIN_TYPES.GAME_MANAGEMENT:
        handleTabClick_GAME_MANAGEMENT(e, type, tab, url)
        break
      case DOMAIN_TYPES.ANALYTICS:
        handleTabClick_ANALYTICS(e, type, tab, url)
        break
      default:
        break
    }
  }

  if (subTabs) {
    return (
      <div>
        <ListItem
          key={name}
          disablePadding
          sx={{
            display: 'block',
            color: theme.palette.text.primary,
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={(e) => {
              handleTabClick(e, 'PARENT_TAB', null, url)
              setIsExpanded(!isExpanded)
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? '10px' : 0,
                justifyContent: 'center',
                width: '30px',
              }}
              onClick={() => setIsExpanded(false)}
            >
              {stringToIcon(
                icon ? icon : subTabs.find((tab) => tab.icon !== null)?.icon,
                undefined,
                '24',
              )}
            </ListItemIcon>
            {open && (
              <ListItemText
                primaryTypographyProps={{ fontSize: '13.122px' }}
                primary={name?.toUpperCase()}
                sx={{
                  color:
                    path[2] === lowerCaseAndReplaceSpacesAndDashes(name)
                      ? newColor.jazzberryJam
                      : theme.palette.text.primary,
                  opacity: open ? 1 : 0,
                  maxWidth: 'fit-content',
                  ':hover': {
                    color: newColor.jazzberryJam,
                  },
                }}
              />
            )}
            {open &&
              (subTabs && isExpanded ? (
                <ExpandLess onClick={() => setIsExpanded(false)} />
              ) : (
                <ExpandMore onClick={() => setIsExpanded(true)} />
              ))}
          </ListItemButton>
          {subTabs && (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              {subTabs?.map((subTab: any) => {
                return (
                  <ListItemButton
                    href={`/${DOMAIN?.toLowerCase()}/${lowerCaseAndReplaceSpacesAndDashes(
                      name,
                    )}/${lowerCaseAndReplaceSpacesAndDashes(subTab.name)}`}
                    key={subTab.name + '_' + index}
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      pl: '55px',
                      height: '25px',
                      mb: '5px',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      handleTabClick(e, 'SUB_TAB', subTab)
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '1em',
                        mr: '4px',
                      }}
                    >
                      {renderIconSubTabs(subTab.name, subTab.icon)}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '12px' }}
                      primary={
                        <p style={{ margin: '0' }}>
                          {subTab.name?.toUpperCase()}
                        </p>
                      }
                      sx={{
                        color:
                          path[2] ===
                            lowerCaseAndReplaceSpacesAndDashes(name) &&
                          path[3] ===
                            lowerCaseAndReplaceSpacesAndDashes(subTab.name)
                            ? newColor.jazzberryJam
                            : theme.palette.text.primary,
                        opacity: open ? 1 : 0,
                        maxWidth: 'fit-content',
                        ':hover': {
                          color: newColor.jazzberryJam,
                        },
                      }}
                    />
                  </ListItemButton>
                )
              })}
            </Collapse>
          )}
        </ListItem>
      </div>
    )
  } else {
    return (
      <ListItem key={name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            width: '100%',
          }}
          onClick={(e) => handleTabClick(e, 'PUBLIC_TAB', { name: name })}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? '1.5em' : '0',
              justifyContent: 'center',
            }}
          >
            {stringToIcon(icon)}
          </ListItemIcon>
          {open && (
            <ListItemText
              primary={name}
              sx={{
                opacity: open ? 1 : 0,
                display: open ? 'inline' : 'none',
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
    )
  }
}
