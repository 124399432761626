import { ChangeEvent, useContext, useState } from 'react'

import styled from 'styled-components'

import { Button } from '../../../atoms/buttons'
import { palette } from '../../../../consts/colors'
import { Wrapper } from '../../../../utils/muiStyledComponents'
import { RS_CHAIN_SCHEMAS } from '../../../../consts/backend'
import { Auth0Context } from '../../../../contexts/Auth0Context'
import { handleError } from '../../../../utils/api/errors'
import { addCollectionAPI } from '../../../../utils/api/queries'

const Title: any = styled.h3`
  text-align: center;
`

const Label: any = styled.label`
  text-align: center;
`

const Message: any = styled.h4`
  width: 400px;
  padding: 8px;
  text-align: center;
  color: ${palette.font};
`

const Error: any = styled(Message)`
  color: ${palette.error};
`

const Input: any = styled.input`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

const Select: any = styled.select`
  margin: 16px;
  padding: 8px;
  width: 500px;
`

export default function AddContract() {
  const { patchWithAccessToken } = useContext(Auth0Context)

  const [address, setAddress] = useState('')
  const [message, setMessage] = useState('')
  const [chain, setChain] = useState('')
  const [error, setError] = useState('')

  const onChangeAddress = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setAddress(e?.target.value)
      setMessage('')
    }
  }

  const onChangeChain = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      setChain(e?.target.value)
    }
  }

  const addCollection = async () => {
    if (address && chain) {
      try {
        setMessage(``)
        await addCollectionAPI(patchWithAccessToken, address, chain)
      } catch (error: any) {
        handleError(error, setError, setMessage)
      }
    } else {
      setMessage(`Please enter collection address and choose chain`)
    }
  }

  return (
    <Wrapper>
      <Title>Updates tracked_at to now() in CHAIN.nft_contracts</Title>
      <br />
      <Label>Enter contract address starting from 0x:</Label>
      <Input
        placeholder="Type contract starting with 0x"
        value={address}
        type="text"
        required
        onChange={onChangeAddress}
      />
      <Label>Choose chain:</Label>
      <Select required value={chain} onChange={onChangeChain}>
        <option disabled value="">
          {' '}
          -- select an option --{' '}
        </option>
        {Object.entries(RS_CHAIN_SCHEMAS).map(([chain, schema]) => (
          <option key={schema} value={schema}>
            {chain}
          </option>
        ))}
      </Select>
      <Button onClick={addCollection}>Add Collection</Button>
      <Message>{message}</Message>
      <Error>{error}</Error>
    </Wrapper>
  )
}
