import { PaletteMode } from '@mui/material'
import { palette, lightPalette } from '../consts/colors'

export const getDesignTokens = (mode: PaletteMode) => ({
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Paralucent Medium',
      },
    },
  },
  palette: {
    mode,
    ...(mode === 'dark'
      ? {
        palette: {
          mode: 'dark',
          background: {
            default: 'black',
            paper: palette.bgLight,
          },
          primary: {
            main: palette.primary,
            dark: palette.bgPage,
          },
          text: {
            primary: palette.primaryText,
          },
        },
      }
      : {
        palette: {
          mode: 'light',
          background: {
            default: 'white',
            paper: lightPalette.bgLight,
          },
          primary: {
            main: lightPalette.primary,
            dark: lightPalette.bgPage,
          },
          text: {
            primary: palette.primaryText,
          },
        },
      }),
  }
})
