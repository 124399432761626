import ProfileIconImg from 'src/assets/profile_icon.svg'

import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Auth0Context } from '../../contexts/Auth0Context'
import Information from 'src/components/organisms/v2/profile/information'
import DomainAccess from 'src/components/organisms/v2/profile/domainAccess'
import TabManagement from '../../components/organisms/v2/profile/tabManagement'
import ProfileEditToggle from 'src/components/organisms/v2/profile/ProfileEditToggle'
import ActionSaveProvider from 'src/components/organisms/v2/profile/tabManagement/ActionContext'
import {
  Box,
  FormControlLabel,
  Switch,
  styled as muiStyled,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import { useColorMode } from 'src/App'
import { lightPalette, palette, newColor } from 'src/consts/colors'
import { useIsEditing } from 'src/components/organisms/v2/profile/IsEditingContext'
import { patchUserAttributes } from '../../utils/api/queries'
import _ from 'lodash'
import PageContainer from 'src/components/atoms/v2/pageContainer'

const LoadingWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
`

const PageHeader: any = styled.div`
  border-radius: 38px;
  background: linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.875rem 0.875rem 0.75rem 1.75rem;
  text-transform: uppercase;
  justify-content: space-between;
  font-size: 20px;
`

const ProfileIcon: any = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 10px;
`

const ProfileCard = muiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? newColor.charcoal : newColor.white,
  borderRadius: '0.625rem',
  padding: '1.5rem',
  border: '1px solid',
  borderColor:
    theme.palette.mode === 'dark'
      ? palette.primaryBorder
      : lightPalette.primaryBorder,
  flex: 1,
}))

const TwoColumns: any = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  height: fit-content;
`


export const Android12Switch: any = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  "& .Mui-checked": {
    color: newColor.jazzberryJam
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '&:before': {
    left: 12,
  },
  '&:after': {
    right: 12,
  },
}));

export default function Profile() {
  const { toggleColorMode } = useColorMode()
  const theme = useTheme()
  const userData = useSelector((state: any) => state.user)
  const { error, isLoading, isAuthenticated, patchWithAccessToken } = useContext(Auth0Context)
  const { isEditing } = useIsEditing()

  const [isDarkMode, setIsDarkMode] = useState<boolean>(theme.palette.mode === 'dark');

  useEffect(() => {
    setIsDarkMode(theme.palette.mode === 'dark')
  }, [theme.palette.mode]);

  const Dot = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
      >
        <ellipse
          cx="3.69687"
          cy="3.87461"
          rx="3.12558"
          ry="3.12559"
          fill="white"
        />
      </svg>
    )
  }

  const handleLightMode = () => {
    setIsDarkMode(false);
    toggleColorMode('light');
    localStorage.setItem('theme', 'light');
    patchUserAttributes(patchWithAccessToken, { dark_theme: false })
  }

  const handleDarkMode = () => {
    setIsDarkMode(true);
    toggleColorMode('dark');
    localStorage.setItem('theme', 'dark');
    patchUserAttributes(patchWithAccessToken, { dark_theme: true })
  }

  if (isLoading || !isAuthenticated || _.isEmpty(userData)) {
    return <LoadingWrapper>Loading...</LoadingWrapper>
  }
  if (error) return <div>{error.message}</div>

  return (
    <PageContainer
      sx={{
        textAlign: 'initial',
        padding: '1.125rem 1rem 1.8125rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        minWidth: 'fit-content',
        background: theme.palette.mode === 'dark' ? newColor.black : newColor.cultured,
        width: '100%'
      }}
    >
      <PageHeader>
        <Box display="flex" alignItems="center" sx={{ color: 'white' }}>
          <ProfileIcon src={ProfileIconImg} alt="profile icon" />
          Profile
          {isEditing && (
            <>
              <Box alignItems="center" display="flex" px="0.5rem">
                <Dot />
              </Box>
              EDIT
            </>
          )}
        </Box>
        <FormControlLabel
          control={
            <Android12Switch
              checked={isDarkMode}
              onClick={() => {
                if (theme.palette.mode === 'dark') {
                  handleLightMode();
                } else {
                  handleDarkMode();
                }
              }}
            />}
          label={<div style={{ color: 'white' }}>DARK MODE</div>}
        />
      </PageHeader>
      <ProfileCard>
        <ProfileEditToggle>
          <Information userData={userData} />
        </ProfileEditToggle>
      </ProfileCard>
      <TwoColumns>
        <ProfileCard>
          <DomainAccess />
        </ProfileCard>
        <ProfileCard>
          <ProfileEditToggle>
            <ActionSaveProvider>
              <TabManagement />
            </ActionSaveProvider>
          </ProfileEditToggle>
        </ProfileCard>
      </TwoColumns>
    </PageContainer>
  )
}
