import { useSelector } from 'react-redux'
import { DOMAIN_TYPES } from '../state/action-types'
import { bindActionCreators } from 'redux'
import { actionCreators } from 'src/state'
import _ from 'lodash'

export const useGetDrawer = () => {

  const DOMAIN = useSelector((state: any) => state.domain.DOMAIN)

  //drawer is open booleans
  const ANALYTICS_IS_OPEN = useSelector(
    (state: any) => state.analyticsData.MAIN_DRAWER_OPEN,
  )
  const ACCOUNT_MANAGEMENT_IS_OPEN = useSelector(
    (state: any) => state.accountMgmtData.MAIN_DRAWER_OPEN,
  )
  const GAME_MANAGEMENT_IS_OPEN = useSelector(
    (state: any) => state.gameMgmtData.MAIN_DRAWER_OPEN,
  )
  const SUPPORT_IS_OPEN = useSelector(
    (state: any) => state.supportData.MAIN_DRAWER_OPEN,
  )

  switch (DOMAIN) {
    case DOMAIN_TYPES.ANALYTICS:
      return ANALYTICS_IS_OPEN
    case DOMAIN_TYPES.GAME_MANAGEMENT:
      return GAME_MANAGEMENT_IS_OPEN
    case DOMAIN_TYPES.SUPPORT:
      return SUPPORT_IS_OPEN
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
      return ACCOUNT_MANAGEMENT_IS_OPEN
  }
}

export function useGetInitialStateData(domain: string) {

  const ANALYTICS_DATA = useSelector((state: any) => state.analyticsData)
  const GAME_MANAGEMENT_DATA = useSelector((state: any) => state.gameMgmtData)
  const SUPPORT_DATA = useSelector((state: any) => state.supportData)
  const ACCOUNT_MANAGEMENT_DATA = useSelector((state: any) => state.accountMgmtData)

  let domain_data: any = {}

  switch (domain) {
    case DOMAIN_TYPES.ANALYTICS: {
      domain_data = ANALYTICS_DATA;
      break;
    }
    case DOMAIN_TYPES.GAME_MANAGEMENT: {
      domain_data = GAME_MANAGEMENT_DATA;
      break;
    }
    case DOMAIN_TYPES.SUPPORT: {
      domain_data = SUPPORT_DATA;
      break;
    }
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT: {
      domain_data = ACCOUNT_MANAGEMENT_DATA;
      break;
    }
    default: {
      return {};
    }
  }

  if (_.isEmpty(domain_data?.TAB_STATE)) return [];
  return Object.fromEntries(
    domain_data?.TAB_STATE
      ? domain_data.TAB_STATE?.map((i: any) => [i.name, false,])
      : []
    ,
  )

}

export async function setData(domain: DOMAIN_TYPES, payload: any, dispatch: any) {

  const {
    setAnalyticsState,
    setGameManagementState,
    setSupportState,
    setAccountManagementState
  } = bindActionCreators(actionCreators, dispatch)

  switch (domain) {
    case DOMAIN_TYPES.ANALYTICS:
      setAnalyticsState({
        type: 'SET_ANALYTICS_DATA',
        payload: payload,
      })
      break
    case DOMAIN_TYPES.GAME_MANAGEMENT:
      setGameManagementState({
        type: 'SET_GAME_MANAGEMENT_DATA',
        payload: payload,
      })
      break
    case DOMAIN_TYPES.SUPPORT:
      setSupportState({
        type: 'SET_SUPPORT_DATA',
        payload: payload,
      })
      break
    case DOMAIN_TYPES.ACCOUNT_MANAGEMENT:
      setAccountManagementState({
        type: 'SET_ACCOUNT_MANAGEMENT_DATA',
        payload: payload,
      })
      break
  }
}